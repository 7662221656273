// @ts-nocheck
import { H4, Tag } from "@blueprintjs/core"
import { Field } from "formik"

import { getDeepNestedValue } from "../../../../../../utils"
import { Col, Grid, Row } from "../../../../../components"
import { NumericInput, fakeSyntheticEvent } from "../../../../../components/formik"

/**
 * Geos tab in Stocks AddReport and EditReport
 */
const Geos = ({ geos, handleChange, reportData: { prevReport, prevYearReport } }) => {
  const getPrevReportValue = ({ geoSlug }) =>
    getDeepNestedValue(["geos", `slug:${geoSlug}`, "share"], prevReport)
  const getPrevYearReportValue = ({ geoSlug }) =>
    getDeepNestedValue(["geos", `slug:${geoSlug}`, "share"], prevYearReport)

  return (
    <Grid fluid>
      {geos.map((rgeo, index) => (
        <Row key={`geos.${index}.share`}>
          <Col xsOffset={2} xs={2}>
            <H4>
              {rgeo.name} <small>Share</small>
            </H4>
          </Col>
          <Col xs={2}>
            <Tag large fill minimal style={{ textAlign: "right" }}>
              {getPrevYearReportValue({ geoSlug: rgeo.slug })}
            </Tag>
          </Col>
          <Col xs={2}>
            <Tag large fill minimal style={{ textAlign: "right" }}>
              {getPrevReportValue({ geoSlug: rgeo.slug })}
            </Tag>
          </Col>
          <Col xs={3}>
            <Field
              name={`geos.${index}.share`}
              key={`geos.${index}.share`}
              style={{ textAlign: "right" }}
              component={NumericInput}
              onValueChange={(numVal, stringVal) =>
                handleChange(fakeSyntheticEvent(stringVal, `geos.${index}.share`))
              }
            />
          </Col>
        </Row>
      ))}
    </Grid>
  )
}

export default Geos
