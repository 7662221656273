// @ts-nocheck
import * as R from "ramda"

export const fetchFolders = R.compose(
  R.assocPath(["folders", "fetching"], true),
  R.dissocPath(["folders", "error"]),
)

export const fetchFoldersOk = (state, { payload: { folders } }) =>
  R.compose(
    R.assocPath(["folders", "fetching"], false),
    R.assocPath(["folders", "list"], folders),
  )(state)

export const fetchFoldersError = (state, { payload: { error } }) => ({
  ...state,
  folders: { ...state.folders, error, fetching: false },
})
