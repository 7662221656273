import styled from "@emotion/styled"
import { FlexContainer, FlexContainerProps } from "../../../../styled/flexbox.styled"
import { ShadowContainer } from "../../../../styled/layout.styled"

type IdeaCreateConvictionContainerProps = {
  hidden: boolean
}

export const IdeaCreateConvictionContainer = styled(ShadowContainer)(
  (props: IdeaCreateConvictionContainerProps) => ({
    padding: "15px 10px",
    display: props.hidden ? "none" : "flex",
  }),
)

type StarSelectAreaProps = {
  disabled: boolean
}

export const StarSelectArea = styled(FlexContainer)<StarSelectAreaProps & FlexContainerProps>(
  props => ({
    cursor: props.disabled ? "not-allowed" : "pointer",
    marginLeft: "15px",
  }),
)

export const SwitchContainer = styled(FlexContainer)({
  ".bp5-switch": {
    margin: 0,
  },
})
