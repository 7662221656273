import { InputGroup } from "@blueprintjs/core"
import { HighchartsReact } from "highcharts-react-official"
import Highcharts from "highcharts/highstock"
import { Dispatch, SetStateAction, useState } from "react"

import { FlexContainer } from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"
import { LargeText, MediumText } from "../../../styled/text.styled"
import { defaultOptions } from "../../_shared/highcharts"
import { WithLoading } from "../WithLoading"
import { NoChartContainer, QuickChartDialog } from "./Chart.styled"
import { createSerie } from "./createSerie"
import useBloombergData from "./useBloombergData"

// Import order is important ! Boost should be the last module included.
import Boost from "highcharts/modules/boost"

Boost(Highcharts) // Initialize the boost module

// HOC to add loading spinner to Chart
const ChartWithLoading = WithLoading(HighchartsReact)

interface IChartProps {
  isChartOpen: boolean
  setIsChartOpen: Dispatch<SetStateAction<boolean>>
}

const Chart = ({ isChartOpen, setIsChartOpen }: IChartProps) => {
  const [query, setQuery] = useState<string>("")

  const { bloombergData, isLoading, error } = useBloombergData({ query })

  const options = {
    rangeSelector: { ...defaultOptions.rangeSelector },
    chart: {
      type: "line",
      boost: {
        useGPUTranslations: true, // enables GPU acceleration for translations
      },
    },
    title: {
      text: "Stock price",
      style: {
        color: SCIENT_COLORS.blue5,
        fontSize: "20px",
        fontFamily: "sans-serif",
      },
    },
    navigator: {
      enabled: true,
    },
    xAxis: {
      ...defaultOptions.xAxis,
      scrollbar: {
        enabled: true,
      },
      title: {
        text: "Time",
        style: {
          color: SCIENT_COLORS.blue5,
          fontSize: "18px",
          fontFamily: "sans-serif",
        },
      },
    },
    yAxis: {
      opposite: false,
      title: {
        text: "Price",
        style: {
          color: SCIENT_COLORS.blue5,
          fontSize: "18px",
          fontFamily: "sans-serif",
        },
      },
    },
    series: [
      {
        name: "Price",
        data: createSerie(bloombergData),
        boostThreshold: 3000, // Activate boost for series larger than 3000 points
      },
    ],
    tooltip: {
      crosshairs: [true],
      headerFormat: "{point.x:%d-%m-%Y}",
      valueDecimals: 2,
    },
  }

  return (
    <QuickChartDialog
      className="bp5-dark"
      isOpen={isChartOpen}
      onClose={() => {
        setIsChartOpen(false)
        setQuery("")
      }}
      isCloseButtonShown={false}
      hasData={!!bloombergData}
      title={
        <FlexContainer alignItems="center" justifyContent="space-around">
          <InputGroup
            placeholder="Enter ticker's name..."
            onChange={input => setQuery(input.target.value)}
          />
          <LargeText>Quick chart</LargeText>
        </FlexContainer>
      }
    >
      {error ? (
        <NoChartContainer flexDirection="column" justifyContent="center" alignItems="center">
          <MediumText>Ticker not found or not enough character.</MediumText>
          <MediumText>Please try with more charaters or search for another ticker.</MediumText>
        </NoChartContainer>
      ) : query.length > 2 ? (
        <ChartWithLoading
          isLoading={isLoading}
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={options}
        />
      ) : (
        <NoChartContainer justifyContent="center" alignItems="center">
          <LargeText>Chart ticker's price</LargeText>
        </NoChartContainer>
      )}
    </QuickChartDialog>
  )
}

export default Chart
