// @ts-nocheck
import React from "react"
import * as R from "ramda"
import { Card } from "@blueprintjs/core"

import {
  FormikFloatInput,
  FloatArrayInput,
  FormikIntegerInput,
  FormikSwitch,
  FormikFreqChoice,
  FormikSelect,
  FormikTextInput,
} from "../../../../../../../../../../../components/formik/fields"
import { shouldDisplay, getParamChoices } from "../../../../utils"
import { ParamTitle } from "../../../../components"
import StockChoiceHelper from "./StockChoiceHelper"
import FieldChoiceHelper from "./FieldChoiceHelper"
import DsChoiceHelper from "./DsChoiceHelper"

const ParamComponent = ({ param, enhancedPath, values }) => {
  const { id, type, many, choices } = param

  switch (type) {
    case "SourceSelect":
    case "ValuationSourceSelect":
      return <StockChoiceHelper name={id} values={values} param={param} />
    case "DsSelect":
      return <DsChoiceHelper name={id} values={values} param={param} />
    case "FieldSelect":
      return (
        <FieldChoiceHelper name={id} values={values} param={param} enhancedPath={enhancedPath} />
      )
    case "period_freq":
      return (
        <>
          <ParamTitle param={param} />
          <FormikFreqChoice name={id} freqs={choices} />
        </>
      )
    case "BOOL":
      return (
        <>
          <ParamTitle param={param} />
          <FormikSwitch name={id} />
        </>
      )
    case "STRING": {
      const formatedChoices = getParamChoices(choices)
      return (
        <>
          <ParamTitle param={param} />
          {R.isEmpty(choices) ? (
            <FormikTextInput name={id} placeholder="Enter a string" />
          ) : (
            <FormikSelect name={id} options={formatedChoices} />
          )}
        </>
      )
    }
    case "NUMBER":
    case "FLOAT":
      return (
        <>
          <ParamTitle param={param} />
          {many ? <FloatArrayInput name={id} /> : <FormikFloatInput name={id} />}
        </>
      )
    case "INTEGER":
      return (
        <>
          <ParamTitle param={param} />
          <FormikIntegerInput name={id} />
        </>
      )
    default:
      throw new Error(`Unkown param type: ${type} for param: ${id}`)
  }
}

const ParamBlock = ({ param, values, enhancedPath }) => {
  const { show } = param

  if (shouldDisplay({ show, values })) {
    return (
      <Card>
        <ParamComponent param={param} enhancedPath={enhancedPath} values={values} />
      </Card>
    )
  }
  return null
}

export default ParamBlock
