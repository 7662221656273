// @ts-nocheck
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { assoc, compose, groupBy, head, isNil, map, pick, prop } from "ramda"
import { AgGridReact } from "ag-grid-react"

import dsForecastsDuck from "../../../../../../../state/modules/dsForecasts"
import { Loader } from "../../../../../../components"
import {
  nextEmptyEntries,
  defaultLength,
  createSource,
  createSequence,
  nthName,
  yearBeforeName,
} from "../../../utils"
import { NumberFormatter, DateFormatter } from "../../../../../../_shared/agFormatters"
import BottomButtons from "./BottomButtons"

const Manual = ({
  dataSet,
  index,
  dsforecast,
  pastEntries,
  periodicity,
  profile: { username, is_superuser },
}) => {
  const [gridApi, setGridApi] = useState({})
  const [, setGridColumnApi] = useState({})
  const [sequenceError, setSequenceError] = useState([])

  const dispatch = useDispatch()
  const updateForecast = params => dispatch(dsForecastsDuck.actions.updateForecast(params))

  const { name, dsfentries, error } = dsforecast
  const pct = dataSet.unit?.includes("pct")

  const isOwnerOrAdmin = is_superuser || dsforecast.owner === username

  if (!dsfentries || dsforecast.loadingEntries) {
    return (
      <div style={{ height: "160px" }}>
        <Loader title="Please wait..." />
      </div>
    )
  }

  if (error) {
    return <p>Error: {error}</p>
  }

  const emptyRows = nextEmptyEntries(
    compose(prop("name"), head, prop("results"))(pastEntries),
    defaultLength(periodicity),
  )
  const periodOpts = map(prop("name"), emptyRows)
  const filledRowsByName = groupBy(prop("name"), dsfentries)

  const rows = map(
    entry =>
      filledRowsByName[entry.name]
        ? assoc("value", filledRowsByName[entry.name][0].value, entry)
        : entry,
    emptyRows,
  )

  const colTitles = rows.map(entry => ({
    field: entry.name,
    cellRenderer: ({ rowIndex, value }) =>
      [0, 1].includes(rowIndex) ? DateFormatter({ value }) : NumberFormatter({ value, pct }),
    editable: ({ node: { id } }) => (id === "2" ? true : false),
    cellStyle: ({ value, node: { id }, column: { colId } }) =>
      id === "2" &&
      (isNil(filledRowsByName[colId]) || filledRowsByName[colId][0].value !== Number(value))
        ? { fontStyle: "italic", fontWeight: "bold", textAlign: "right" }
        : { fontStyle: "normal", fontWeight: "normal", textAlign: "right" },
  }))

  const columnDefs = [
    { headerName: "NEXT ENTRIES", field: "desc", pinned: "left", width: 150 },
    ...colTitles,
  ]

  const rowHeaders = [
    { name: "Start", field: "start" },
    { name: "End", field: "end" },
    { name: "Value", field: "value" },
  ]
  const rowData = rowHeaders.map(header => {
    const col = rows.reduce(
      (acc, entry) => {
        acc[entry.name] = entry[header.field]
        return acc
      },
      { desc: header.name },
    )
    return col
  })

  const options = {
    defaultColDef: {
      width: 100,
    },
    columnDefs,
    rowData,
  }

  const onGridReady = ({ api, columnApi }) => {
    setGridApi(api)
    setGridColumnApi(columnApi)
  }
  const updateRow = ({ start, end, method, amount }) => {
    const source = createSource(pastEntries, rows)
    const sequence = createSequence(start, end)

    if (method === "Const") {
      sequence.forEach(name => (source[name] = amount))
    } else if (method === "%Seq") {
      sequence.forEach(name => {
        const prevName = nthName(name, -1)
        const mult = (100 + amount) / 100
        source[name] = (source[prevName] * mult).toFixed(4)
        if (!source[prevName]) {
          setSequenceError(prevState => [...prevState, prevName])
        }
      })
    } else if (method === "%YoY") {
      sequence.forEach(name => {
        const prevName = yearBeforeName(name)
        const mult = (100 + amount) / 100
        if (!source[prevName]) {
          setSequenceError(prevState => [...prevState, prevName])
        }
        source[name] = (source[prevName] * mult).toFixed(4)
      })
    } else if (method === "MAV") {
      sequence.forEach(name => {
        let num = amount
        let acc = 0.0
        while (num > 0) {
          const prevName = nthName(name, -num)
          if (!source[prevName]) {
            setSequenceError(prevState => [...prevState, prevName])
          }
          acc += parseFloat(source[prevName])
          num -= 1
        }
        source[name] = (acc / amount).toFixed(4)
      })
    }
    const valueRow = gridApi.getRowNode("2")

    const editedValues = pick(sequence, source)
    valueRow.setData({ ...valueRow.data, ...editedValues })
  }

  const handleSubmit = () => {
    const startRow = gridApi.getRowNode("0").data
    const valueRow = gridApi.getRowNode("2").data
    const dsfentries = Object.entries(valueRow)
      .filter(([, value], index) => index >= 0 && !["", "Value"].includes(value))
      .map(([key, value]) => {
        const start = startRow[key]
        return { start, value }
      })
    const form = {
      dataset: dataSet.id,
      id: dsforecast.id,
      dsfentries,
    }

    updateForecast({
      index,
      form,
    })
  }

  return (
    <>
      <div
        className="ag-theme-balham-dark"
        style={{
          marginBottom: "8px",
          height: "140px",
          width: "100%",
        }}
      >
        <AgGridReact onGridReady={onGridReady} sizeColumnsToFit {...options} />
      </div>
      <BottomButtons
        isOwnerOrAdmin={isOwnerOrAdmin}
        handleSubmit={handleSubmit}
        updateRow={updateRow}
        name={name}
        periodOpts={periodOpts}
        sequenceError={sequenceError}
        setSequenceError={setSequenceError}
      />
    </>
  )
}

export default Manual
