import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const depColor = SCIENT_COLORS.violet5
export const indepColors = [
  SCIENT_COLORS.cerulean3,
  SCIENT_COLORS.forest3,
  SCIENT_COLORS.gold3,
  SCIENT_COLORS.turquoise3,
  SCIENT_COLORS.rose3,
  SCIENT_COLORS.lime3,
  SCIENT_COLORS.sepia3,
]

export const allColors = [depColor, ...indepColors]

export const predColors = [SCIENT_COLORS.indigo5, SCIENT_COLORS.indigo4, SCIENT_COLORS.indigo1]
