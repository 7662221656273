// @ts-nocheck
import * as R from "ramda"
import React from "react"
import { useSelector } from "react-redux"

import { Card } from "@blueprintjs/core"
import { Col, Grid, Row } from "../../../../components"

import { splitMineOthers } from "../../../../_shared/sharing"
import Buttons from "./Buttons"
import MyAnalyses from "./MyAnalyses"
import SharedAnalyses from "./SharedAnalyses"
import { useAuth } from "../../../../hooks"

const Folders = () => {
  const { profile } = useAuth()
  const analyses = useSelector(R.path(["analysis", "analyses", "list"]))
  const [mine, other] = splitMineOthers(profile.id)(analyses)

  return (
    <Grid>
      <Row>
        <Col sm={12} md={6}>
          <Buttons />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <Card>
            <MyAnalyses analyses={mine} profile={profile} />
          </Card>
        </Col>
        <Col xs={12} lg={6}>
          <Card>
            <SharedAnalyses analyses={other} />
          </Card>
        </Col>
      </Row>
    </Grid>
  )
}

export default Folders
