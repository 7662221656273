// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { Card, Icon, Tooltip } from "@blueprintjs/core"
import update from "immutability-helper"
import { isEmpty } from "ramda"
import { useDrop } from "react-dnd"

import { FlexContainer } from "../../../../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { MediumText } from "../../../../../../../../styled/text.styled"
import { randomid } from "../../../../../../../../utils"
import { getHeaderItems, useEnhancedPath } from "../../../utils"
import { cardCss } from "./HeaderBuilder.styled"
import { HeaderBuilderButtons } from "./HeaderBuilderButtons"
import { HeaderItem } from "./HeaderItem"
import itemTypes from "./itemTypes"

export const HeaderBuilder = ({ activeItem, values, setValues, st_sources, rf_sources }) => {
  const enhancedPath = useEnhancedPath(activeItem.path, st_sources, rf_sources)
  const headerItems =
    values.headerItems && !isEmpty(values.headerItems)
      ? values.headerItems
      : getHeaderItems(values.header, enhancedPath)

  // for react dnd
  const findItem = itemId => {
    const headerItem = headerItems.filter(e => e.itemId === itemId)[0]
    return {
      headerItem,
      index: headerItems.indexOf(headerItem),
    }
  }

  const moveItem = (itemId, atIndex) => {
    const { headerItem, index } = findItem(itemId)
    const newHeaderItems = update(headerItems, {
      $splice: [
        [index, 1],
        [atIndex, 0, headerItem], // move item from 1 to 0
      ],
    })

    const newValues = {
      ...values,
      headerItems: newHeaderItems,
    }
    return setValues(newValues)
  }
  const [, drop] = useDrop({ accept: itemTypes.BUTTON })
  // end dnd

  return (
    <div>
      <FlexContainer>
        <p>Header name</p>
        <Tooltip
          content={
            <MediumText color={SCIENT_COLORS.darkGray5}>
              Choose what you want to display in header row.
              <br />
              You can add programatics values like the name
              <br />
              of the primary source and/or free text.
            </MediumText>
          }
          placement="right"
          openOnTargetFocus={false}
          compact
        >
          <Icon className="sct-button-in-button" icon="info-sign" size={20} />
        </Tooltip>
      </FlexContainer>

      <Card css={cardCss}>
        <FlexContainer ref={drop} flexFlow="row wrap">
          {headerItems.map((headerItem, index) => (
            <HeaderItem
              key={randomid()}
              values={values}
              setValues={setValues}
              headerItems={headerItems}
              headerItem={headerItem}
              index={index}
              moveItem={moveItem}
              findItem={findItem}
            />
          ))}
        </FlexContainer>
      </Card>

      <HeaderBuilderButtons
        activeItem={activeItem}
        st_sources={st_sources}
        rf_sources={rf_sources}
        values={values}
        setValues={setValues}
        headerItems={headerItems}
      />
    </div>
  )
}
