// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { Button, ButtonGroup, Icon, Tooltip } from "@blueprintjs/core"
import { includes } from "ramda"

import { eyeIconCss, Li } from "./DisplayNode.styled"
import Inputs from "./Inputs"
import NodeInfos from "./NodeInfos"
import Outputs from "./Outputs"
import Param from "./Param"

const DisplayNode = ({ data, toggleEdit }) => {
  const { title, subtitle, inputs, outputs, params, style = {} } = data

  const showList = params?.map(param => param.show)
  // If all params have show=false, we don't want to display the editNode button
  const isEditable = params?.length > 0 && includes(true, showList)

  const hasOptionalParams = params?.some(param => param.optional)

  // Inside node, only mandatory params are displayed. User can see optionals params in tooltip.
  const FilteredParams = ({ optional }) =>
    params
      .filter(param => param.show && param.optional === optional)
      .map((param, index) => {
        return <Param key={index} param={param} />
      })

  return (
    <div className={data.locked ? "nodrag op-node" : "op-node"} style={style}>
      {outputs && <Outputs outputs={outputs} />}
      <ButtonGroup minimal={true}>
        {isEditable && <Button small icon="edit" onClick={toggleEdit} />}
        <Tooltip
          content={<NodeInfos data={data} />}
          placement="bottom"
          openOnTargetFocus={false}
          compact
        >
          <Button small icon="info-sign" />
        </Tooltip>
      </ButtonGroup>
      <h2>{title}</h2>
      {subtitle && (
        <h4>
          <em>{subtitle}</em>
        </h4>
      )}
      {params && (
        <ul>
          <FilteredParams optional={false} />
          {hasOptionalParams && (
            <Li optional>
              <Tooltip
                content={<FilteredParams optional={true} />}
                placement="bottom"
                openOnTargetFocus={false}
                compact
              >
                <div>
                  <b>Optional params</b>
                  <Icon icon="eye-open" css={eyeIconCss} />
                </div>
              </Tooltip>
            </Li>
          )}
        </ul>
      )}
      {inputs && <Inputs inputs={inputs} />}
    </div>
  )
}
export default DisplayNode
