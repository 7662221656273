// @ts-nocheck
import styled from "@emotion/styled"

export const ExactCheckboxStyled = styled.div({
  display: "flex",
  alignItems: "center",
  /**
   * Default Control from blueprint has a margin-bottom of 10px
   * We just want one vertically centered element
   */
  marginBottom: "-10px",
  padding: "0 5px",
  fontSize: "12px",
})
