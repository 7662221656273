// @ts-nocheck
import * as R from "ramda"

import { Button, ButtonGroup, Checkbox, MenuItem } from "@blueprintjs/core"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row, Suggest } from "."
import { clearOmniByName, searchOmniByName } from "../../state/modules/search"
import { ExactCheckboxStyled } from "./OmniSuggest.styled"

const defaultExclude = { st: false, rf: false, ds: false, dsf: false }

export const OmniSuggest = ({
  onSubmit,
  selectedItem,
  initialSelectedItem,
  inputValueRenderer = item => item.name,
  placeholder,
  exclude = {},
}) => {
  const [uncontrolledSelectedItem, setUncontrolledSelectedItem] = useState(initialSelectedItem)

  /**
   * Check if the component is being used in controlled or uncontrolled mode.
   */
  const isControlled = useMemo(() => {
    return selectedItem !== undefined
  }, [selectedItem])

  /**
   * Set the value selected when rendering,
   * useful for updating the form.
   */
  useEffect(() => {
    if (!uncontrolledSelectedItem && initialSelectedItem && !isControlled) {
      setUncontrolledSelectedItem(initialSelectedItem)
    }
  }, [initialSelectedItem, uncontrolledSelectedItem, isControlled])

  /**
   * When submiting we call the provided callback with the item.
   * If the component is used in uncontrolled mode we update the local
   * state.
   */
  const onItemSelect = item => {
    onSubmit(item)
    if (!isControlled) {
      setUncontrolledSelectedItem(item)
    }
  }

  const initialExclude = R.mergeDeepLeft(exclude, defaultExclude)
  const showButtons =
    R.compose(
      R.length,
      R.filter(e => !e),
      R.values,
    )(initialExclude) > 1

  const [rejectFilters, setRejectFilters] = useState(initialExclude)

  /**
   * State to bear exact matching when searching Omni by Name
   */
  const [exact, setExact] = useState(false)

  const updateFilters = key => {
    switch (key) {
      case "all":
        return setRejectFilters(R.mergeDeepLeft(initialExclude, defaultExclude))
      case "st":
        return setRejectFilters(
          R.mergeDeepRight(initialExclude, { st: false, rf: true, ds: true, dsf: true }),
        )
      case "rf":
        return setRejectFilters(
          R.mergeDeepRight(initialExclude, { rf: false, st: true, ds: true, dsf: true }),
        )
      case "ds":
        return setRejectFilters(
          R.mergeDeepRight(initialExclude, { ds: false, st: true, rf: true, dsf: true }),
        )
      case "dsf":
        return setRejectFilters(
          R.mergeDeepRight(initialExclude, { dsf: false, st: true, rf: true, ds: true }),
        )
      default:
        throw new Error("wrong filter")
    }
  }
  const allChecked =
    (initialExclude.st || !rejectFilters.st) &&
    (initialExclude.rf || !rejectFilters.rf) &&
    (initialExclude.ds || !rejectFilters.ds) &&
    (initialExclude.dsf || !rejectFilters.dsf)
      ? true
      : false

  const kinds = R.compose(
    R.map(([key]) => key),
    R.filter(([key, bool]) => !bool && key),
    R.toPairs,
  )(rejectFilters)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(clearOmniByName())
  }, [dispatch])

  const updateSearch = query =>
    !R.empty(query) && dispatch(searchOmniByName({ name: query, kinds, exact }))

  const results = useSelector(R.pathOr([], ["search", "omniByName", "results"]))
  const searching = useSelector(R.pathOr(null, ["search", "omniByName", "searching"]))

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null

    const { src_name, kind, id } = item
    return (
      <MenuItem
        active={modifiers.active}
        key={`${kind}.${id}`}
        text={src_name}
        label={kind}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }

  return (
    <>
      {showButtons && (
        <Row>
          <Col xs={2}>
            <ButtonGroup fill>
              <Button text="all" active={allChecked} onClick={() => updateFilters("all")} />
            </ButtonGroup>
          </Col>
          <Col xs={10}>
            <ButtonGroup fill>
              {!initialExclude.st && (
                <Button
                  text="stock"
                  active={!rejectFilters.st}
                  onClick={() => updateFilters("st")}
                  style={{ fontSize: "12px" }}
                />
              )}
              {!initialExclude.rf && (
                <Button
                  text="stock + forecast"
                  active={!rejectFilters.rf}
                  onClick={() => updateFilters("rf")}
                  style={{ fontSize: "12px" }}
                />
              )}
              {!initialExclude.ds && (
                <Button
                  text="ds"
                  active={!rejectFilters.ds}
                  onClick={() => updateFilters("ds")}
                  style={{ fontSize: "12px" }}
                />
              )}
              {!initialExclude.dsf && (
                <Button
                  text="ds + forecast"
                  active={!rejectFilters.dsf}
                  onClick={() => updateFilters("dsf")}
                  style={{ fontSize: "12px" }}
                />
              )}
              <ExactCheckboxStyled>
                <Checkbox label="exact" checked={exact} onChange={() => setExact(!exact)} />
              </ExactCheckboxStyled>
            </ButtonGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          <Suggest
            loading={searching}
            resetOnClose={true}
            resetOnSelect={true}
            items={results}
            onItemSelect={onItemSelect}
            onQueryChange={updateSearch}
            itemRenderer={itemRenderer}
            selectedItem={isControlled ? selectedItem : uncontrolledSelectedItem}
            fill
            inputProps={{ placeholder, large: true }}
            inputValueRenderer={inputValueRenderer}
          />
        </Col>
      </Row>
    </>
  )
}
