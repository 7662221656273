// @ts-nocheck
import * as R from "ramda"

export const shaveRFInstance = formulaTree => {
  return R.over(
    R.lensProp("nodes"),
    R.map(
      R.compose(
        R.ifElse(
          node => node.data.params,
          R.over(R.lensPath(["data", "params"]), R.map(R.omit(["description", "label", "show"]))),
          R.dissocPath(["data", "params"]),
        ),
        R.ifElse(
          node => node.data.outputs,
          R.over(
            R.lensPath(["data", "outputs"]),
            R.map(R.omit(["connectable", "description", "label", "style"])),
          ),
          R.dissocPath(["data", "outputs"]),
        ),
        R.ifElse(
          node => node.data.inputs,
          R.over(
            R.lensPath(["data", "inputs"]),
            R.map(R.omit(["connectable", "description", "label", "style"])),
          ),
          R.dissocPath(["data", "inputs"]),
        ),
        R.over(R.lensProp("data"), R.omit(["category", "key", "label", "locked", "show", "style"])),
        R.when(
          node => node.handleBounds.source,
          R.over(
            R.lensPath(["handleBounds", "source"]),
            R.map(R.omit(["height", "position", "width", "x", "y"])),
          ),
        ),
        R.when(
          node => node.handleBounds.target,
          R.over(
            R.lensPath(["handleBounds", "target"]),
            R.map(R.omit(["height", "position", "width", "x", "y"])),
          ),
        ),
        R.pick(["id", "type", "position", "handleBounds", "data"]),
      ),
    ),
    formulaTree,
  )
}
