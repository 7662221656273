// @ts-nocheck
import React from "react"
import { Grid } from "../../../../../../components"
import { slugToName } from "../../../../utils"
import { TitleRow, InputRow, ControlRow } from "../components"
import { groupValue, segValue } from "../sideUpdates"

const Values = ({ endField, handleChange, reportData: { prevReport, prevYearReport }, values }) => {
  return (
    <Grid fluid>
      <TitleRow title="Group" />
      <InputRow
        title="Group"
        basePath={[endField]}
        commentPath={[`${endField}_comment`]}
        values={values}
        prevReport={prevReport}
        prevYearReport={prevYearReport}
        handleChange={handleChange}
        sideUpdates={groupValue({ endField })}
      />
      {values.splits !== undefined &&
        values.splits.map(split => {
          const splitSlug = split.slug
          const expected = values[endField]
          const sumList = split.segs.map(seg => seg[endField])

          return (
            <div key={splitSlug}>
              <TitleRow title={slugToName(splitSlug)} />
              <ControlRow title={"Group check"} expected={expected} sumList={sumList} />
              {split.segs.map(seg => {
                const pathBase = ["splits", `slug:${splitSlug}`, "segs", `slug:${seg.slug}`]
                const path = [...pathBase, endField]
                return (
                  <InputRow
                    key={seg.slug}
                    title={slugToName(seg.slug)}
                    basePath={path}
                    commentPath={[...pathBase, `${path}_comment`]}
                    values={values}
                    prevReport={prevReport}
                    prevYearReport={prevYearReport}
                    handleChange={handleChange}
                    sideUpdates={segValue({ pathBase, endField })}
                  />
                )
              })}
            </div>
          )
        })}
    </Grid>
  )
}

export default Values
