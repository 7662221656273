// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import dsForecastsDuck from "../../."
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const {
  actions: { fetchForecasts, fetchForecastsOk, fetchForecastsError },
} = dsForecastsDuck

const fetchForecastsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchForecasts.type),
    mergeMap(action => {
      const { dataset, bySlug } = action.payload

      const url = bySlug
        ? `${REACT_APP_API_DOMAIN}/api/v1/datasets/dsforecasts/for/${dataset}/`
        : `${REACT_APP_API_DOMAIN}/api/v1/datasets/${dataset}/dsforecasts/?entries=1`

      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchForecastsOk(response)),
        catchError(error => of(fetchForecastsError(error.xhr.response))),
      )
    }),
  )

export default fetchForecastsEpic
