import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { SCIENT_COLORS } from "../../../../../../../../../styled/scientColors"
import { MediumText } from "../../../../../../../../../styled/text.styled"

export const h2Css = css({
  marginBottom: "24px",
})

export const Aside = styled.aside({
  display: "flex",
  flexDirection: "column",
  backgroundColor: SCIENT_COLORS.darkGray4,
})

export const CircleIcon = styled.div({
  height: "16px",
  width: "16px",
  border: "solid white",
  borderRadius: "50%",
})

export const DashedCircleIcon = styled.div({
  height: "16px",
  width: "16px",
  border: "dashed white",
  borderRadius: "50%",
})

export const Footer = styled.div({
  alignSelf: "end",
})

export const LegendText = styled(MediumText)({
  padding: "0px 0px 0px 8px",
  color: SCIENT_COLORS.lightGray2,
})

export const NodesContainer = styled.div({
  height: "100%",
  overflow: "auto",
})

export const Separation = styled.hr({
  margin: "20px 0",
})
