// @ts-nocheck
import * as R from "ramda"

const searchRForecastsReducers = {
  searchRForecasts: R.assocPath(["rForecasts", "searching"], true),
  searchRForecastsOk: (state, { payload: { results } }) =>
    R.over(
      R.lensProp("rForecasts"),
      R.compose(R.assoc("searching", false), R.assoc("results", results)),
      state,
    ),
  searchRForecastsError: (state, { payload: { error } }) =>
    R.set(R.lensProp("rForecasts"), { searching: false, error: error, results: [] }, state),
  clearRForecasts: state =>
    R.set(R.lensProp("rForecasts"), { searching: false, results: [] }, state),
}

export default searchRForecastsReducers
