// @ts-nocheck
import React from "react"
import { Button } from "@blueprintjs/core"

import stockDetailDuck from "../../../../../state/modules/stockDetail"

const AddReport = ({ stockSlug, dispatch }) => {
  const setAddSlug = () => dispatch(stockDetailDuck.actions.setAddSlug({ slug: stockSlug }))

  return (
    <Button
      data-cy="AddReport"
      icon="add"
      text="Add report"
      onClick={setAddSlug}
      style={{ margin: "28px auto 8px" }}
    />
  )
}

export default AddReport
