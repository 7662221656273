// @ts-nocheck
import React from "react"
import { assoc } from "ramda"
import { Classes, H5 } from "@blueprintjs/core"

import { ConnectedStockPathSelect3 } from "../../../../../../../../../components"
import { InsertLag } from "./InsertLag"

export const SelectField = ({ panelsState, setPanelsState, shared, DFState }) => {
  const df_id = panelsState.sourceId.slice(1)

  const dataframe = DFState[df_id]

  const appendPath = item => {
    setPanelsState(assoc("field", item.path, panelsState))
  }

  return (
    <>
      {(panelsState.sourceId.includes("st") || panelsState.sourceId.includes("rf")) && (
        <div
          className={Classes.DIALOG_BODY}
          style={{
            margin:
              panelsState.sourceId.includes("st") || panelsState.sourceId.includes("rf")
                ? "30px 120px"
                : "80px 120px",
          }}
        >
          <H5>Select field</H5>
          <ConnectedStockPathSelect3
            value={panelsState.field || ""}
            update={appendPath}
            shared={shared}
            dataframe={dataframe}
          />
        </div>
      )}
      <div className={Classes.DIALOG_BODY} style={{ margin: "30px 120px" }}>
        <H5>Insert Lag</H5>
        <InsertLag panelsState={panelsState} setPanelsState={setPanelsState} />
      </div>
    </>
  )
}
