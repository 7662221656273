import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import { useApi } from "../../../../hooks/useApi"

const useBooksQuery = ({ accountIds }: { accountIds?: number[] } = {}) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["tradingbooks", accountIds],
    () => scientApi.trading.getBooks({ accountIds }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false, // to avoid rerender when there's no books associated to an user
    },
  )

  return {
    books: useMemo(() => data || [], [data]),
    isLoading,
    error,
  }
}

export default useBooksQuery
