// @ts-nocheck
import {
  organicErase,
  organicSum,
  scopeErase,
  scopeSum,
  organicEraseQoq,
  organicSumQoq,
  scopeEraseQoq,
  scopeSumQoq,
} from "../sideUpdates"

export const createGrowthRows = ({
  endField,
  pathBase = [],
  values,
  prevYearReport,
  prevReport,
  handleChange,
}) => {
  const splitRows =
    endField === "sales"
      ? [
          {
            type: "title",
            props: {
              level: 3,
              title: `Organic ${endField} growth`,
            },
          },
          {
            type: "input",
            props: {
              title: "Organic total",
              basePath: [...pathBase, `${endField}_organic_growth`],
              commentPath: [...pathBase, `${endField}_organic_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicErase({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Price",
              basePath: [...pathBase, `${endField}_price_growth`],
              commentPath: [...pathBase, `${endField}_price_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Volume",
              basePath: [...pathBase, `${endField}_volume_growth`],
              commentPath: [...pathBase, `${endField}_volume_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Other",
              basePath: [...pathBase, `${endField}_other_organic_growth`],
              commentPath: [...pathBase, `${endField}_other_organic_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Other 2",
              basePath: [...pathBase, `${endField}_other_organic_growth_2`],
              commentPath: [...pathBase, `${endField}_other_organic_growth_2_comment`],
              values,
              prevReport,
              prevYearReport,
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Calender effect growth",
              basePath: [...pathBase, `${endField}_calendar_effect_growth`],
              commentPath: [...pathBase, `${endField}_calendar_effect_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              handleChange,
            },
          },
          {
            type: "title",
            props: {
              level: 3,
              title: `Scope ${endField} growth`,
            },
          },
          {
            type: "input",
            props: {
              title: "Scope total",
              basePath: [...pathBase, `${endField}_scope_growth`],
              commentPath: [...pathBase, `${endField}_scope_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeErase({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Acquisition",
              basePath: [...pathBase, `${endField}_acquisition_growth`],
              commentPath: [...pathBase, `${endField}_acquisition_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "FX",
              basePath: [...pathBase, `${endField}_fx_growth`],
              commentPath: [...pathBase, `${endField}_fx_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Other",
              basePath: [...pathBase, `${endField}_other_scope_growth`],
              commentPath: [...pathBase, `${endField}_other_scope_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "title",
            props: {
              level: 3,
              title: `Organic ${endField} growth qoq`,
            },
          },
          {
            type: "input",
            props: {
              title: "Organic total qoq",
              basePath: [...pathBase, `${endField}_organic_growth_qoq`],
              commentPath: [...pathBase, `${endField}_organic_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicEraseQoq({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Price qoq",
              basePath: [...pathBase, `${endField}_price_growth_qoq`],
              commentPath: [...pathBase, `${endField}_price_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSumQoq({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Volume qoq",
              basePath: [...pathBase, `${endField}_volume_growth_qoq`],
              commentPath: [...pathBase, `${endField}_volume_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSumQoq({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Other qoq",
              basePath: [...pathBase, `${endField}_other_organic_growth_qoq`],
              commentPath: [...pathBase, `${endField}_other_organic_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSumQoq({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "title",
            props: {
              level: 3,
              title: `Scope ${endField} growth qoq`,
            },
          },
          {
            type: "input",
            props: {
              title: "Scope total qoq",
              basePath: [...pathBase, `${endField}_scope_growth_qoq`],
              commentPath: [...pathBase, `${endField}_scope_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeEraseQoq({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Acquisition qoq",
              basePath: [...pathBase, `${endField}_acquisition_growth_qoq`],
              commentPath: [...pathBase, `${endField}_acquisition_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSumQoq({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "FX qoq",
              basePath: [...pathBase, `${endField}_fx_growth_qoq`],
              commentPath: [...pathBase, `${endField}_fx_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSumQoq({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Other qoq",
              basePath: [...pathBase, `${endField}_other_scope_growth_qoq`],
              commentPath: [...pathBase, `${endField}_other_scope_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSumQoq({ endField, pathBase })],
              handleChange,
            },
          },
        ]
      : [
          {
            type: "title",
            props: {
              level: 3,
              title: `Organic ${endField} growth`,
            },
          },
          {
            type: "input",
            props: {
              title: "Organic total",
              basePath: [...pathBase, `${endField}_organic_growth`],
              commentPath: [...pathBase, `${endField}_organic_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicErase({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Price",
              basePath: [...pathBase, `${endField}_price_growth`],
              commentPath: [...pathBase, `${endField}_price_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Volume",
              basePath: [...pathBase, `${endField}_volume_growth`],
              commentPath: [...pathBase, `${endField}_volume_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Other",
              basePath: [...pathBase, `${endField}_other_organic_growth`],
              commentPath: [...pathBase, `${endField}_other_organic_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [organicSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "title",
            props: {
              level: 3,
              title: `Scope ${endField} growth`,
            },
          },
          {
            type: "input",
            props: {
              title: "Scope total",
              basePath: [...pathBase, `${endField}_scope_growth`],
              commentPath: [...pathBase, `${endField}_scope_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeErase({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Acquisition",
              basePath: [...pathBase, `${endField}_acquisition_growth`],
              commentPath: [...pathBase, `${endField}_acquisition_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "FX",
              basePath: [...pathBase, `${endField}_fx_growth`],
              commentPath: [...pathBase, `${endField}_fx_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSum({ endField, pathBase })],
              handleChange,
            },
          },
          {
            type: "input",
            props: {
              title: "Other",
              basePath: [...pathBase, `${endField}_other_scope_growth`],
              commentPath: [...pathBase, `${endField}_other_scope_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              sideUpdates: [scopeSum({ endField, pathBase })],
              handleChange,
            },
          },
        ]
  return splitRows
}
