/** @jsxImportSource @emotion/react */

import { Button, H3, Icon, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import { Dispatch, SetStateAction, useEffect } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { useInfiniteMessages } from "../../hooks"
import { Idea, IdeaRevision } from "../../types/business"
import CreateMessage from "./CreateMessage"
import MessageBubble from "./MessageBubble"
import {
  CollapseButtonCss,
  ErrorContainer,
  errorTitleCss,
  FetchingText,
  MessagesListContainer,
  NoMessagesPlaceholder,
  NoMessagesTitle,
  SpinnerContainer,
} from "./MessagesList.styled"

interface IMessagesList {
  idea: Idea
  isCollapse: boolean
  setIsCollapse: Dispatch<SetStateAction<boolean>>
  selectedRevision: IdeaRevision
}

const MessagesList = ({ idea, isCollapse, setIsCollapse, selectedRevision }: IMessagesList) => {
  const { messages, fetchNextPage, hasNextPage, error, isFetching } = useInfiniteMessages({
    conversationId: selectedRevision.conversation.id,
  })

  useEffect(() => {
    setIsCollapse(true)
  }, [setIsCollapse])

  if (error) {
    return (
      <ErrorContainer flexDirection="row" justifyContent="center" alignItems="center">
        <Icon icon="warning-sign" size={60} color={SCIENT_COLORS.red3} />
        <FlexContainer flexDirection="column" alignItems="center">
          <H3 css={errorTitleCss}>An error occurred while fetching the message list.</H3>
          <H3 css={errorTitleCss}>Please refresh the page.</H3>
        </FlexContainer>
      </ErrorContainer>
    )
  }

  return (
    <>
      {messages?.length ? (
        <>
          <Button
            onClick={() => setIsCollapse(!isCollapse)}
            text={isCollapse ? "Extend message List" : "Reduce message list"}
            icon={isCollapse ? "caret-right" : "caret-down"}
            css={CollapseButtonCss}
          />
          <MessagesListContainer
            flexDirection="column-reverse"
            id="messages-box"
            isCollapse={isCollapse}
            empty={!messages.length}
          >
            <InfiniteScroll
              dataLength={messages.length}
              style={{ display: "flex", flexDirection: "column-reverse", height: "100%" }}
              loader={
                <SpinnerContainer justifyContent="center" flexGrow={1}>
                  <Spinner intent={Intent.SUCCESS} size={SpinnerSize.SMALL} />
                </SpinnerContainer>
              }
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              inverse={true}
              scrollableTarget="messages-box"
            >
              {messages?.length &&
                messages.map(message => {
                  return <MessageBubble message={message} key={message.id} />
                })}
            </InfiniteScroll>
          </MessagesListContainer>
        </>
      ) : isFetching ? (
        <SpinnerContainer alignItems="center" justifyContent="center">
          <Spinner intent={Intent.SUCCESS} size={30} />
          <FetchingText>Fetching messages...</FetchingText>
        </SpinnerContainer>
      ) : !idea.draft ? (
        <NoMessagesPlaceholder alignItems="center" justifyContent="center" gap="24px">
          <Icon icon="inbox" size={30} />
          <NoMessagesTitle>There is no message in this conversation.</NoMessagesTitle>
        </NoMessagesPlaceholder>
      ) : null}
      {!error && !idea.draft && <CreateMessage idea={idea} selectedRevision={selectedRevision} />}
    </>
  )
}

export default MessagesList
