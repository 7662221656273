// @ts-nocheck
import * as R from "ramda"
import { isArray, isObjectArray, isNumericArray } from "../../arrayUtils"
// import { pctFieldsSlugList } from "./pctFieldsSlugList"

// const formatPctFieldsToNull = stock =>
//   R.mapObjIndexed((value, key) => {
//     if (R.includes(key, pctFieldsSlugList(stock))) {
//       return null
//     }
//     if (isObjectArray(value)) {
//       return R.map(formatPctFieldsToNull(stock)(R.__), value)
//     }
//     if (R.equals("Object", R.type(value))) {
//       return formatPctFieldsToNull(stock)(value)
//     }
//     return value
//   })

// Fields with pct=true need to return 'null' (because N/A). It's done in getFormattedValue

const computeDiffPct = forecast => {
  const calculateDiff = anything => {
    if (typeof anything === "number") {
      return null
    }
    if (isArray(anything) && anything.length === 0) {
      return null
    }
    if (isNumericArray(anything) && anything.length === 1) {
      return null
    }
    if (isNumericArray(anything) && anything.length === 2) {
      return +((anything[1] - anything[0]) / Math.abs(anything[0])).toFixed(8)
    }
    if (isObjectArray(anything) && anything.length > 0) {
      return anything.map(el => R.map(e => calculateDiff(e), el))
    }
    return anything
  }

  const result = R.map(e => calculateDiff(e), forecast)
  return result
}
export default computeDiffPct
