// @ts-nocheck
import React, { useState } from "react"
import { Classes } from "@blueprintjs/core"
import { toLower } from "ramda"

import desksDuck from "../../../../state/modules/desks"
import { ButtonWithDialog, Suggest } from "../../../components"

export const AddExistingDesk = ({ allDesks, userDesks, dispatch }) => {
  const [filteredDecks, setFilteredDesks] = useState(allDesks)
  const handleQueryChange = query => {
    const results = allDesks.filter(desk => {
      const lowerQ = toLower(query)
      const nameIncludesQ = toLower(desk.name).includes(lowerQ)
      return desk.name.includes(lowerQ) || nameIncludesQ
    })
    setFilteredDesks(results)
  }

  const lastPos = userDesks.length

  const addExistingDesk = ({ id }) => {
    dispatch(desksDuck.actions.upsertUserDesks([...userDesks, { desk: id, pos: lastPos + 1 }]))
  }

  return (
    <>
      <ButtonWithDialog
        buttonProps={{
          icon: "plus",
          text: "Add existing desk",
          "data-cy": "VarTextResults",
          intent: "primary",
          fill: true,
        }}
        dialogProps={{
          title: "Add existing desk",
          style: { width: "554px" },
          intent: "primary",
        }}
      >
        {({ setDialog }) => {
          const handleSubmitExistingDesk = id => {
            addExistingDesk(id)
            setDialog(false)
          }
          return (
            <>
              <div className={Classes.DIALOG_BODY}>
                <Suggest
                  placeholder={"Choose existing desk"}
                  // loading={searching}
                  resetOnClose={true}
                  resetOnSelect={true}
                  items={filteredDecks}
                  onItemSelect={handleSubmitExistingDesk}
                  onQueryChange={handleQueryChange}
                  large={true}
                  fill
                ></Suggest>
              </div>
            </>
          )
        }}
      </ButtonWithDialog>
    </>
  )
}
