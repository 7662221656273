// @ts-nocheck
import * as R from "ramda"
import {
  shared_fields,
  field_fields,
  currency_fields,
  geo_fields,
  is_fields,
  bs_fields,
  cf_fields,
} from "../../../../../../../../../utils/fromBack/reports"
import { slugify } from "../../../../../../../../../utils/"
import { isObjectArray } from "../arrayUtils"

/**
 * Develops rfentries to the largest shape
 *
 * @param {Array of Strings} slugs
 * @param {Object} stock
 * @param {Object} forecast
 */

const shapeRFEntries = (slugs, stock, forecast) => {
  const baseStrName = forecast.str_name

  const sharedFields = R.reduce((acc, field) => R.assoc(field.name, null, acc), {}, shared_fields)
  const fieldFields = R.reduce((acc, field) => R.assoc(field.name, null, acc), {}, field_fields)
  const currencyFields = R.reduce(
    (acc, field) => R.assoc(field.name, null, acc),
    {},
    currency_fields,
  )
  const geoFields = R.reduce((acc, field) => R.assoc(field.name, null, acc), {}, geo_fields)
  const isFields = R.reduce((acc, field) => R.assoc(field.name, null, acc), {}, is_fields)
  const bsFields = R.reduce((acc, field) => R.assoc(field.name, null, acc), {}, bs_fields)
  const cfFields = R.reduce((acc, field) => R.assoc(field.name, null, acc), {}, cf_fields)

  const splits = yearPeriod =>
    R.compose(
      R.map(
        R.over(
          R.lensProp("segs"),
          R.map(
            R.compose(
              R.evolve({
                str_name: R.compose(
                  R.join(">"),
                  R.insertAll(0, [baseStrName, yearPeriod]),
                  R.tail,
                  R.split(">"),
                ),
              }),
              R.mergeDeepRight(sharedFields),
              R.pick(["name", "slug", "str_name"]),
            ),
          ),
        ),
      ),
      R.map(
        R.compose(
          R.evolve({
            str_name: R.compose(
              R.join(">"),
              R.insertAll(0, [baseStrName, yearPeriod]),
              R.tail,
              R.split(">"),
            ),
          }),
          R.pick(["name", "slug", "str_name", "segs"]),
        ),
      ),
      R.propOr({}, "splits"),
    )(stock)

  const fields = yearPeriod =>
    R.compose(
      R.map(
        R.compose(
          R.evolve({
            str_name: R.compose(
              R.join(">"),
              R.insertAll(0, [baseStrName, yearPeriod]),
              R.tail,
              R.split(">"),
            ),
          }),
          R.mergeDeepRight(fieldFields),
          R.pick(["name", "slug", "str_name"]),
        ),
      ),
      R.propOr({}, "fields"),
    )(stock)

  const currencies = yearPeriod =>
    R.compose(
      R.map(name => ({
        name,
        slug: slugify(name),
        str_name: `${baseStrName}>${yearPeriod}>${name}`,
        ...currencyFields,
      })),
      R.propOr({}, "currencies"),
    )(stock)

  const geos = yearPeriod =>
    R.compose(
      R.map(name => ({
        name,
        slug: slugify(name),
        str_name: `${baseStrName}>${yearPeriod}>${name}`,
        ...geoFields,
      })),
      R.propOr({}, "geos"),
    )(stock)

  return {
    ...forecast,
    rfentries: slugs.map(slug => {
      const year = parseInt(slug.slice(0, 4))
      const period = slug.slice(4).toUpperCase()
      const yearPeriod = slug.toUpperCase()
      const emptyRFEntry = {
        year,
        period,
        str_name: `${baseStrName}>${year}${period}`,
        slug,
        ...sharedFields,
        splits: splits(yearPeriod),
        fields: fields(yearPeriod),
        currencies: currencies(yearPeriod),
        geos: geos(yearPeriod),
        ...isFields,
        ...bsFields,
        ...cfFields,
      }
      const handleSameKeys = (key, left, right) => {
        if (isObjectArray(left) && isObjectArray(right)) {
          return left.map(l => {
            const r = R.find(R.propEq("slug", l.slug))(right) || {}
            return R.mergeDeepWithKey(handleSameKeys, l, r)
          })
        }
        return right
      }
      const rFEntryFromForecast = R.find(R.propEq("slug", slug))(
        R.propOr({}, "rfentries", forecast),
      )
      return R.mergeDeepWithKey(handleSameKeys, emptyRFEntry, rFEntryFromForecast || {})
    }),
  }
}

export default shapeRFEntries
