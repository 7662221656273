// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { Button, ButtonGroup, Icon, Tooltip } from "@blueprintjs/core"
import { isEmpty } from "ramda"
import { useState } from "react"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { DeleteAlert } from "../../../../components"
import { getItemByTempId, useEnhancedPath } from "../../../utils"
import { getRowTreeItemName } from "../utils"
import { itemButtonCss, moveIconCss, rowNameButtonCss } from "./RowTree.styled"

const RowTreeItem = ({
  rowTree,
  node,
  path,
  st_sources,
  rf_sources,
  activeItem,
  selectNode,
  removeNode,
  addSingleUnderParent,
  addGroupUnderParent,
  groupNotComplete,
  setEditRowItems,
  handleChartHideLine,
}) => {
  const [deleteAlert, setDeleteAlert] = useState(false)

  const enhancedPath = useEnhancedPath(path, st_sources, rf_sources)
  const niceName = getRowTreeItemName(node, path, enhancedPath)

  const isActiveItem = activeItem && activeItem.temp_id === node.temp_id

  const chartHideLine = node.frontOpts && node.frontOpts.chartHideLine

  // Need to know if a node has a parent and if this parent has more than one child
  // If parent has only one child, this child can't be removed
  const parentId = path.length > 1 && path[path.length - 2]
  const parent =
    parentId &&
    !parentId.includes("data") &&
    !parentId.includes("header") &&
    getItemByTempId(rowTree, parentId)

  const deleteMessage =
    parent && parent.children.length === 1
      ? "Can't delete: this item is the only child in the group. Add one child in group to delete this item or delete the group."
      : node.kind === "group"
      ? `Delete group${node.children.length ? " and all childrens inside" : ""}.`
      : !isEmpty(node.formula_tree)
      ? "Delete Data"
      : "Delete Header"

  const canNotDelete = parent && parent.children.length === 1

  // When editing a new group, others items are disabled until group is completed
  const isDisabled = activeItem && groupNotComplete

  return (
    <>
      <ButtonGroup fill>
        <Icon icon="move" size={20} css={moveIconCss} disabled={isDisabled} />
        <Button
          type="button"
          text={niceName}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            selectNode(node, path)
          }}
          fill
          large={isActiveItem}
          css={rowNameButtonCss(node, isActiveItem)}
          disabled={isActiveItem === false && isDisabled}
        />

        {node.children && (
          <>
            <Tooltip
              content="Add header"
              disabled={isDisabled}
              placement="bottom"
              openOnTargetFocus={false}
              compact
            >
              <Button
                type="button"
                text="H"
                css={itemButtonCss(SCIENT_COLORS.turquoise1, isActiveItem)}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  addSingleUnderParent(node, path, { type: "header" })
                }}
                disabled={isDisabled}
              />
            </Tooltip>

            <Tooltip
              content="Add data"
              disabled={isDisabled}
              placement="bottom"
              openOnTargetFocus={false}
              compact
            >
              <Button
                type="button"
                text="D"
                css={itemButtonCss(SCIENT_COLORS.cerulean1, isActiveItem)}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  addSingleUnderParent(node, path, { type: "data" })
                }}
                disabled={isDisabled}
              />
            </Tooltip>

            {enhancedPath && !enhancedPath.fields && !enhancedPath.segs && (
              <Tooltip
                content="Add group"
                disabled={isDisabled}
                placement="bottom"
                openOnTargetFocus={false}
                compact
              >
                <Button
                  type="button"
                  text="G"
                  css={itemButtonCss(SCIENT_COLORS.indigo1, isActiveItem)}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    addGroupUnderParent(node, path)
                  }}
                  disabled={isDisabled}
                />
              </Tooltip>
            )}
          </>
        )}

        {/* Display chart button only in Single Data */}
        {!node.children && !node.isHeader && (
          <Tooltip
            content={chartHideLine ? "Click to view in chart " : "Click to hide in chart"}
            disabled={isDisabled}
            placement="right"
            openOnTargetFocus={false}
            compact
          >
            <Button
              type="button"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                handleChartHideLine(node, path)
              }}
              active={chartHideLine}
              disabled={isActiveItem === false && isDisabled}
              css={itemButtonCss(
                chartHideLine ? SCIENT_COLORS.gray2 : SCIENT_COLORS.cerulean2,
                isActiveItem,
              )}
            >
              <Icon icon="chart" />
            </Button>
          </Tooltip>
        )}

        <Button
          type="button"
          icon="trash"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setDeleteAlert(path)
          }}
          disabled={isActiveItem === false && isDisabled}
        />
      </ButtonGroup>

      <DeleteAlert
        deleteAlert={deleteAlert}
        setDeleteAlert={setDeleteAlert}
        handleDelete={path => {
          setEditRowItems({ rowId: -1, show: true, add: false })
          removeNode(path)
          setDeleteAlert(false)
        }}
        deleteMessage={deleteMessage}
        canNotDelete={canNotDelete}
      />
    </>
  )
}
export default RowTreeItem
