// @ts-nocheck
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as R from "ramda"
import { DndProvider } from "react-dnd"
import Backend from "react-dnd-html5-backend"

import { searchOmniByIds } from "../../../../../../../state/modules/search"
import { fetchTransformOperations } from "../../../../../../../state/modules/transforms"
import sheetsDuck from "../../../../../../../state/modules/sheets"
import { ConfirmDialog, Loader } from "../../../../../../components"
import { formatRowTree, removeActiveKeyRecursively, addExpanded } from "../../utils"
import { getSourceIds } from "./utils"
import RowTree from "./RowTree"

const Content = ({ sheet, editRowItems, setEditRowItems }) => {
  // rowIdToEdit is used only to know which node to edit, when user clicks on contextMenu
  // rowId reference the index where is the node in the tree (ex: rowId=0|1 reference the second item in the fisrt group)
  const rowIdToEdit = editRowItems.rowId

  const enhancedRowTree = sheet.children
  const [rowTree, setRowTree] = useState(addExpanded(rowIdToEdit, formatRowTree(enhancedRowTree)))
  const [sourceIds, setSourceIds] = useState(getSourceIds(rowTree, sheet))
  const [openCancelDialog, setOpenCancelDialog] = useState(false)

  const handleClose = () => setEditRowItems({ show: false })

  const handelSubmit = () => {
    const children = removeActiveKeyRecursively(rowTree)
    const newChildren = formatRowTree(children, "dissoc")
    const values = { id: sheet.id, children: newChildren }
    dispatch(sheetsDuck.actions.updateSheet(values))
    handleClose()
  }

  const handleCancel = () => {
    setEditRowItems({ show: false })
    setOpenCancelDialog(false)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    sourceIds.length > 0 && dispatch(searchOmniByIds({ ids: sourceIds }))
    dispatch(fetchTransformOperations())
  }, [dispatch, sourceIds])

  const searching = useSelector(R.path(["search", "omniByIds", "searching"]))
  const fetching = useSelector(R.path(["transform", "fetching"]))

  if (searching || fetching) {
    return <Loader className="sct-modal-content" />
  }
  return (
    <>
      <DndProvider backend={Backend}>
        <RowTree
          rowTree={rowTree}
          setRowTree={setRowTree}
          handelSubmit={handelSubmit}
          openCancelDialog={openCancelDialog}
          setOpenCancelDialog={setOpenCancelDialog}
          st_sources={sheet.st_sources}
          rf_sources={sheet.rf_sources}
          sourceIds={sourceIds}
          setSourceIds={setSourceIds}
          rowIdToEdit={rowIdToEdit}
          setEditRowItems={setEditRowItems}
        />
      </DndProvider>
      <ConfirmDialog
        title="Exit without saving ?"
        dialogText="You will loose all unsaved changes."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        handleClick={handleCancel}
        confirmButtonText="Exit without saving"
      />
    </>
  )
}

export default Content
