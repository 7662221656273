import { AxiosInstance, AxiosResponse } from "axios"

export interface IDatasetsApi {
  dataSets: {
    detail: {
      update: (form: any) => Promise<AxiosResponse<any, any>>
    }
  }
  entries: {
    detail: {
      delete: (id: any) => Promise<AxiosResponse<any, any>>
    }
  }
  dsForecasts: {
    list: {
      create: (values: any) => Promise<AxiosResponse<any, any>>
    }
  }
}

/**
 * Api routes for /api/v1/datasets/
 */
const datasets = (axiosClient: AxiosInstance): IDatasetsApi => ({
  /**
   * PATCH /api/v1/datasets/{id}/
   */
  dataSets: {
    detail: {
      update: (form: any) => axiosClient.patch(`/datasets/${form.id}/`, form),
    },
  },
  /**
   * DELETE /api/v1/datasets/entries/${id}/
   */
  entries: {
    detail: {
      delete: (id: any) => axiosClient.delete(`/datasets/entries/${id}/`),
    },
  },
  /**
   * POST /datasets/${values.dataset}/dsforecasts/
   * @todo: Document what is values.dataset
   */
  dsForecasts: {
    list: {
      create: (values: any) => axiosClient.post(`/datasets/${values.dataset}/dsforecasts/`, values),
    },
  },
})

export default datasets
