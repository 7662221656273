import styled from "@emotion/styled"
import { SCIENT_COLORS } from "../../styled/scientColors"
import { MenuItem } from "@blueprintjs/core"

export const NavContainer = styled.div({
  gridArea: "nav",
  ".bp5-navbar": {
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important",
    border: "none",
  },
})

type IdeasDotCounterProps = {
  isInMenuItem?: boolean
}

export const IdeasDotCounter = styled.span<IdeasDotCounterProps>(props => ({
  width: "24px",
  lineHeight: "24px",
  borderRadius: "50%",
  textAlign: "center",
  marginBottom: props.isInMenuItem ? "5px" : "15px",
  marginLeft: props.isInMenuItem ? "5px" : "-7px",
  fontSize: "12px",
  backgroundColor: SCIENT_COLORS.orange4,
  cursor: "default",
}))

export const CustomMenuItem = styled(MenuItem)`
  &.bp5-active {
    background-color: rgba(
      143,
      153,
      168,
      0.3
    ) !important;
    color: white !important; 
  .bp5-icon {
    color: #abb3bf !important;
  }
`
