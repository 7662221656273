import { Dispatch, SetStateAction, useCallback, useState } from "react"
import Metrics from "./Metrics"
import { CollapsibleFlexContainer, YearPeriodsContainer } from "./YearPeriods.styled"
import { UpdatedMetric, Year } from "./types/business"

interface IYear {
  year: Year
  seenMetrics: string[]
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>
  selectedPeriod: string
  setSelectedPeriod: Dispatch<SetStateAction<string>>
  selectedMetric: string
  setSelectedMetric: Dispatch<SetStateAction<string>>
  upper_key: string
  setSelectedUpdate: Dispatch<SetStateAction<UpdatedMetric | null>>
}

const YearPeriods = ({
  year,
  seenMetrics,
  upper_key,
  setOpenChartDialog,
  selectedPeriod,
  setSelectedPeriod,
  selectedMetric,
  setSelectedMetric,
  setSelectedUpdate,
}: IYear) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const getPeriod = useCallback(() => {
    /** Always display the largest available time period as main period.
     *  If year.period===null, display first half_years period if exist.
     *  If no half_years, display the first quarter.
     */
    if (year.period) return year.period
    if (year.half_years.length > 0) return year.half_years[0].period
    else return year.quarters[0].period
  }, [year])

  const getUpdates = useCallback(() => {
    if (year.period && year.updates) return year.updates
    if (year.half_years.length > 0) return year.half_years[0].updates
    else return year.quarters[0].updates
  }, [year])

  const checkChildren = useCallback(() => {
    if (year.period && (year.quarters.length > 0 || year.half_years.length > 0)) return true
    if (
      !year.period &&
      (year.half_years.length > 1 || (year.half_years.length === 1 && year.quarters.length > 0))
    )
      return true
    if (!year.period && year.half_years.length === 0 && year.quarters.length > 1) return true
    else return false
  }, [year])

  return (
    <YearPeriodsContainer flexDirection="column">
      <Metrics
        period={getPeriod()}
        updates={getUpdates()}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isMain={true}
        hasChildren={checkChildren()}
        seenMetrics={seenMetrics}
        setOpenChartDialog={setOpenChartDialog}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        key={`${upper_key}-${year.period}`}
        upper_key={`${upper_key}-${year.period}`}
        setSelectedUpdate={setSelectedUpdate}
      />

      {/* In collapse we display most granular periods if exist.
          First we're looking if one of existing half_year or quarter period is used 
          outside the collaspe. 
          If yes, we don't display it in the collapse because this period is already displayed as main period.   
      */}
      <CollapsibleFlexContainer isCollapsed={isCollapsed} flexWrap="wrap">
        {year.half_years?.map((half_year, index) => {
          const hiddenPeriod = !year.period && year.half_years[0].period

          return (
            half_year.period !== hiddenPeriod && (
              <Metrics
                key={`${upper_key}-${year.period}-${half_year.period}`}
                upper_key={`${upper_key}-${year.period}-${half_year.period}`}
                period={half_year.period}
                updates={half_year.updates}
                metricsIsInCollapse={true}
                seenMetrics={seenMetrics}
                setOpenChartDialog={setOpenChartDialog}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
                setSelectedUpdate={setSelectedUpdate}
              />
            )
          )
        })}
        {year.quarters?.map((quarter, index) => {
          const hiddenPeriod =
            !year.period && year.half_years.length === 0 && year.quarters[0].period
          return (
            quarter.period !== hiddenPeriod && (
              <Metrics
                key={`${upper_key}-${year.period}-${quarter.period}`}
                upper_key={`${upper_key}-${year.period}-${quarter.period}`}
                period={quarter.period}
                updates={quarter.updates}
                metricsIsInCollapse={true}
                seenMetrics={seenMetrics}
                setOpenChartDialog={setOpenChartDialog}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
                setSelectedUpdate={setSelectedUpdate}
              />
            )
          )
        })}
      </CollapsibleFlexContainer>
    </YearPeriodsContainer>
  )
}

export default YearPeriods
