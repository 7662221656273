import { Classes, Icon, Popover } from "@blueprintjs/core"
import { MediumText } from "../../../../../styled/text.styled"
import { IdeaUser } from "../../types/business"
import { IdeaInfos } from "./IdeaHeader.styled"
import {
  ParticipantsPopoverContainer,
  ParticipantsPopoverTarget,
} from "./ParticipantsPopover.styled"

interface IParticipantsPopoverListProps {
  participants: IdeaUser[]
  user: Partial<IdeaUser>
}

const ParticipantsPopoverList = ({ participants, user }: IParticipantsPopoverListProps) => {
  return (
    <Popover
      interactionKind="hover-target"
      popoverClassName={Classes.POPOVER_MATCH_TARGET_WIDTH}
      placement="bottom-start"
      content={
        <ParticipantsPopoverContainer flexDirection="column">
          <MediumText fontWeight="bold">Participants:</MediumText>
          {participants.map(participant => {
            return (
              <MediumText key={`participant-popover-${participant.id}`}>
                &bull; {participant.username}
              </MediumText>
            )
          })}
        </ParticipantsPopoverContainer>
      }
      renderTarget={({ isOpen, ...targetProps }) => (
        <ParticipantsPopoverTarget {...targetProps}>
          <Icon icon="people" />
          <IdeaInfos bold>{user.username}</IdeaInfos>
        </ParticipantsPopoverTarget>
      )}
    />
  )
}

export default ParticipantsPopoverList
