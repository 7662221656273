/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { UseControllerProps, useController } from "react-hook-form"

import { appendSuffixToStringNumber } from "../../utils/transformNumber"
import { inputCss } from "../OrdersForm.styled"
import { FlexContainerFullHeight } from "./Components.styled"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"
import { WaveInputs } from "../types/form"

/**
 * Specific NumericInput where suffix '%' is add onBlur
 * It takes string value, add suffix '%'' and display it.
 * onChange, value is stored as string
 */

interface IPercentageInputProps extends UseControllerProps<WaveInputs> {
  index: number
}

export const PercentageInput = ({ index, control, name, rules }: IPercentageInputProps) => {
  const { field, fieldState } = useController({ control, name, rules })
  const [displayValue, setDisplayValue] = useState<string>(
    field.value ? `${(parseFloat(field.value) * 100).toFixed(2)}%` : "",
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim()
    if (/^\d+\.?(\d+)?%?$/.test(inputValue)) {
      setDisplayValue(inputValue)
      field.onChange((parseFloat(event.target.value) / 100).toString())
    } else if (inputValue === "") {
      setDisplayValue(inputValue)
      field.onChange(null)
    }
  }

  const handleBlur = () => {
    // Format the input value with '%' suffix when blurred
    setDisplayValue(appendSuffixToStringNumber(displayValue, "%"))
  }

  return (
    <FlexContainerFullHeight alignItems="center" justifyContent="center" alignContent="center">
      <input
        type="text"
        value={field.value ? displayValue : ""}
        onChange={handleChange}
        onBlur={handleBlur}
        css={inputCss(index)}
      />
      {fieldState.error && <ErrorIconWithTooltip errorMessage={fieldState.error.message} />}
    </FlexContainerFullHeight>
  )
}
