// @ts-nocheck
import {
  assocPath,
  assoc,
  compose,
  dissoc,
  dissocPath,
  lensPath,
  mergeDeepLeft,
  over,
  set,
} from "ramda"

// const listLens = lensProp("list")
// const detailLens = id => lensPath(["detail", id])

const monitorLens = id => lensPath(["data", id])
const monitorSheetsLens = id => lensPath(["data", id, "sheet_ids"])

export const fetchMonitorList = compose(assoc("ready", false), dissoc("error"))

export const fetchMonitorListOk = (state, { data }) => ({ data, ready: true })

export const fetchMonitorListError = (state, { error }) => ({ ...state, error, ready: true })

export const fetchMonitorDetail = (state, { id }) => {
  return over(monitorLens(id), compose(assoc("ready", false), dissoc("error")), state)
}

export const fetchMonitorDetailOk = (state, { id }) =>
  compose(over(lensPath(["data", id]), mergeDeepLeft(assoc("ready", true))))(state)

export const fetchMonitorDetailError = (state, { id, error }) =>
  over(lensPath(["data", id]), mergeDeepLeft({ error, ready: true }), state)

export const updateMonitor = (state, { id }) => {
  return over(monitorLens(id), compose(assoc("ready", false), dissoc("error")), state)
}
export const updateMonitorOk = (state, payload) => {
  return set(monitorLens(payload.id), { ready: true, ...payload }, state)
}
export const updateMonitorError = (state, payload) =>
  over(monitorLens(payload.id), compose(assoc("ready", true), assoc("error", payload.error)), state)

export const createMonitor = state => compose(assoc("ready", false), dissoc("error"))(state)

export const createMonitorOk = (state, payload) => {
  const monitorId = payload.id
  return compose(
    assocPath(["data", monitorId], { ready: true, ...payload }),
    assoc("ready", true),
  )(state)
}
export const createMonitorError = (state, error) =>
  compose(assoc("ready", true), assoc("error", error))(state)

export const deleteMonitor = (state, { id }) => set(lensPath(["data", id, "ready"]), false, state)
export const deleteMonitorOk = (state, id) => dissocPath(["data", id], state)

export const clearMonitorError = (state, { id }) => {
  return over(monitorLens(id), dissoc("error"), state)
}

export const setMonitorSheets = (state, { id, sheet_ids }) =>
  compose(set(monitorSheetsLens(id), sheet_ids), over(monitorLens(id), dissoc("error")))(state)

export const setActiveSheet = (state, { id, active_sheet_id }) =>
  compose(
    set(lensPath(["data", id, "active_sheet_id"]), active_sheet_id),
    over(monitorLens(id), dissoc("error")),
  )(state)

export const upsertMonitorOk = (state, { id, payload }) =>
  over(lensPath(["data"]), assoc(id, { ready: true, ...payload }), state)

export const upsertMonitorError = (state, payload) =>
  over(monitorLens(payload.id), compose(assoc("ready", true), assoc("error", payload.error)), state)
