import styled from "@emotion/styled"

interface FactoryIconProps {
  width?: string
  height?: string
  color?: string
}

const CyclicalySvgIcon = styled.svg<FactoryIconProps>(({ width, height, color }) => ({
  width: width || "28px",
  height: height || "28px",
  fill: color || "#FFFFFF",
}))

/**
 * Cyclicaly icon taken from www.svgrepo.com
 */
const CyclicalyIcon = ({ width, height, color }: FactoryIconProps) => {
  return (
    <CyclicalySvgIcon viewBox="0 0 24 24" width={width} height={height} color={color}>
      <g id="cycle">
        <g>
          <path d="M17.1,23.8L12.4,21l2.7-4.8l1.7,1l-1.7,3.1l3,1.8L17.1,23.8z M5.7,11L4,8L1.1,9.7L0.1,8l4.7-2.8L7.5,10L5.7,11z" />
        </g>
        <g>
          <polygon points="22,6 16.5,6 16.5,4 20,4 20,0.5 22,0.5 		" />
        </g>
        <g>
          <path
            d="M15.4,21.5l-0.4-2c4-0.9,6.9-4.5,6.9-8.6c0-0.6-0.1-1.3-0.2-1.9l2-0.4c0.2,0.8,0.3,1.6,0.3,2.3
			C24,16.1,20.4,20.5,15.4,21.5z"
          />
        </g>
        <g>
          <path
            d="M9.8,21.3C5.3,19.9,2.2,15.8,2.2,11c0-1.3,0.2-2.6,0.7-3.8l1.9,0.7c-0.4,1-0.6,2-0.6,3.1c0,3.9,2.5,7.2,6.1,8.4L9.8,21.3z
			"
          />
        </g>
        <g>
          <path d="M19.6,5c-1.7-1.9-4.1-3-6.6-3c-2.1,0-4.1,0.8-5.7,2.1L6,2.6C7.9,0.9,10.4,0,13,0c3.1,0,6,1.3,8.1,3.6L19.6,5z" />
        </g>
      </g>
    </CyclicalySvgIcon>
  )
}

export default CyclicalyIcon
