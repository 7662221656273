// @ts-nocheck
import Highcharts from "highcharts/highstock"

export const getHighchartsColor = index => Highcharts.getOptions().colors[index]

export const colorLinkBackground = (color, index) => {
  if (!color) {
    return {
      background: Highcharts.getOptions().colors[index + 1],
    }
  }
}

export const colorLinkText = (color, index) => {
  if (!color) {
    return {
      color: Highcharts.getOptions().colors[index + 1],
    }
  }
}
