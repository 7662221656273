// @ts-nocheck
import React from "react"
import { assoc, dissoc, has } from "ramda"
import { ButtonGroup, Button, Label } from "@blueprintjs/core"
import { Select } from "../../../../../components"

const StylingPalette = ({ styles = {}, setStyles }) => {
  const toggleStyle = styleId => {
    if (has(styleId, styles)) {
      return setStyles(dissoc(styleId, styles))
    } else {
      return setStyles(assoc(styleId, true), styles)
    }
  }
  const styleButtons = ["bold", "italic"]

  const colorButtons = [
    { id: "wht", icon: "symbol-square", active: undefined },
    { id: "blu", icon: "symbol-square", className: "sct-front-blue", active: "blue" },
    { id: "grn", icon: "symbol-square", className: "sct-front-green", active: "green" },
    { id: "orng", icon: "symbol-square", className: "sct-front-orange", active: "orange" },
    { id: "red", icon: "symbol-square", className: "sct-front-red", active: "red" },
    { id: "indg", icon: "symbol-square", className: "sct-front-indigo", active: "indigo" },
    { id: "trqz", icon: "symbol-square", className: "sct-front-turquoise", active: "turquoise" },
    { id: "spia", icon: "symbol-square", className: "sct-front-sepia", active: "sepia" },
  ]
  const handleSetColor = color => setStyles(assoc("frontColor", color), styles)

  const focusOpts = [
    { label: "No focus", value: "" },
    { label: "Focus 1", value: "1" },
    { label: "Focus 2", value: "2" },
    { label: "Focus 3", value: "3" },
    { label: "Focus 4", value: "4" },
  ]
  const handleSelectFocus = ({ target: { value } }) => setStyles(assoc("focus", value), styles)

  return (
    <div>
      <Label>
        Style
        <ButtonGroup style={{ margin: "8px" }}>
          {styleButtons.map(style => (
            <Button
              key={style}
              icon={style}
              active={styles[style]}
              onClick={() => toggleStyle(style)}
            />
          ))}
        </ButtonGroup>
      </Label>

      <Label>
        Text color
        <ButtonGroup style={{ margin: "8px" }}>
          {colorButtons.map(el => (
            <Button
              key={el.id}
              icon="symbol-square"
              className={el.className}
              onClick={() => handleSetColor(el.active)}
              active={el.active === styles.frontColor}
            />
          ))}
        </ButtonGroup>
      </Label>
      <Select
        selectProps={{
          options: focusOpts,
          value: styles.focus || "",
          onChange: handleSelectFocus,
        }}
      />
    </div>
  )
}

export default StylingPalette
