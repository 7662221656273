/** @jsxImportSource @emotion/react */
import { MenuItem } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Suggest } from "@blueprintjs/select"
import { SerializedStyles } from "@emotion/react"
import { Dispatch, SetStateAction } from "react"
import { UseControllerProps, UseFormSetValue, useController } from "react-hook-form"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { ExecutionType, ITickerItem } from "../types/common"
import { WaveInputs } from "../types/form"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"

interface ITickerSuggestProps extends UseControllerProps<WaveInputs> {
  tickerItems: ITickerItem[]
  setTickerItems: Dispatch<SetStateAction<ITickerItem[]>>
  customCss?: SerializedStyles
  trigger: () => void
  index: number
  setValue: UseFormSetValue<WaveInputs>
}

export const TickerSuggest = ({
  tickerItems,
  setTickerItems,
  control,
  name,
  rules,
  customCss,
  trigger,
  index,
  setValue,
}: ITickerSuggestProps) => {
  const { field, fieldState } = useController({ control, name, rules })

  const renderItem: ItemRenderer<ITickerItem> = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={item.ticker}
        onClick={handleClick}
        text={item.ticker.toUpperCase()}
        label={item.isNew ? "Created" : ""}
      />
    )
  }

  const filterTicker: ItemPredicate<ITickerItem> = (query, item, index, exactMatch) => {
    const normalizedTitle = item.ticker.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedTitle === normalizedQuery
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0
    }
  }

  // Function to handle the creation of a new item based on the query
  const createNewItemFromQuery = (query: string): ITickerItem => {
    return { ticker: query.toUpperCase(), isNew: true }
  }

  // Renderer for the "Create new item" option
  const createNewItemRenderer = (query: string, active: boolean) => {
    const isDuplicate = tickerItems.some(item => item.ticker.toLowerCase() === query.toLowerCase())
    if (!isDuplicate) {
      return (
        <MenuItem
          icon="add"
          text={`Manual Input "${query.toUpperCase()}"`}
          active={active}
          onClick={() => {
            const newItem = { ticker: query.toUpperCase(), isNew: true }
            setTickerItems([...tickerItems, newItem])
            field.onChange(query.toUpperCase()) // Pass the entire ticker object
            if (newItem.ticker.includes("INDEX")) {
              setValue(`orders.${index}.execution_type`, ExecutionType.FUTURE)
            }
            trigger()
          }}
          shouldDismissPopover={false}
        />
      )
    } else {
      // If the item is a duplicate, don't render the "Create new item" option
      return undefined
    }
  }

  const isTickerNew = tickerItems.find(item => item.ticker === field.value)?.isNew

  return (
    <FlexContainer alignItems="center">
      {tickerItems && (
        <Suggest
          items={tickerItems}
          itemRenderer={renderItem}
          onItemSelect={(item: ITickerItem) => {
            field.onChange(item.ticker)
            if (item.ticker.includes("INDEX")) {
              setValue(`orders.${index}.execution_type`, ExecutionType.FUTURE)
            }
            trigger()
          }}
          itemPredicate={filterTicker}
          inputValueRenderer={(item: ITickerItem) => item.ticker}
          selectedItem={tickerItems.find(item => item.ticker === field.value)}
          createNewItemFromQuery={createNewItemFromQuery}
          createNewItemRenderer={createNewItemRenderer}
          noResults={<MenuItem disabled text="No results." />}
          popoverProps={{ minimal: true }}
          inputProps={{
            style: { backgroundColor: isTickerNew ? SCIENT_COLORS.orange3 : "" },
          }}
          resetOnSelect={true}
          resetOnQuery={true}
          resetOnClose={true}
          css={customCss}
          fill
        />
      )}
      {fieldState.error && <ErrorIconWithTooltip errorMessage={fieldState.error.message} />}
    </FlexContainer>
  )
}
