import { QueryKey, useQueryClient } from "@tanstack/react-query"
import { isEqual } from "lodash"
import { useCallback } from "react"
import { IdeaOrdering, IdeaSorting } from "../../api/ideas"
import { useGlobalState } from "../../views/hooks/useGlobalState"
import { ConversationMessage, Idea } from "../../views/pages/Ideas/types/business"
import {
  useCloseIdeaTradeEvent,
  useNewIdeaEvent,
  useNewMessageEvent,
  useOpenIdeaTradeEvent,
  useUpdateIdeaEvent,
  useUpdateMessageEvent,
} from "./useEvents"
import { useAuth } from "../../views/hooks"

/**
 * Take the idea and the queryKey and return true if the
 * idea can belong in this query, return false otherwise
 *
 * We check orderBy for the trade open/close conditions
 * (E.g Note idea can't be created in the associated cache)
 *
 * We check the filters to be sure that the idea match.
 */
const ideaMatchQueryKey = (idea: Idea, queryKey: QueryKey) => {
  const orderBy = (queryKey[2] as { orderBy: IdeaOrdering; sortDirection: IdeaSorting }).orderBy
  const filters = queryKey[3] as {
    stock?: number
    sector?: number
    user?: number
    ideaType?: string
    tradeOpenOnly?: boolean
    tradeClosedOnly?: boolean
  }
  if (
    (orderBy === IdeaOrdering.TRADE_OPEN_DATE && !idea.trade?.opened_at) ||
    (orderBy === IdeaOrdering.TRADE_CLOSE_DATE && !idea.trade?.closed_at)
  ) {
    return false
  }
  if (
    filters.stock &&
    ![idea.trade?.long.instance.id, idea.trade?.short.instance.id].includes(filters.stock)
  ) {
    return false
  }
  if (
    filters.sector &&
    ![idea.trade?.long.instance.id, idea.trade?.short.instance.id].includes(filters.sector)
  ) {
    return false
  }
  if (filters.user && idea.user.id !== filters.user) {
    return false
  }
  if (
    (filters.tradeOpenOnly && !idea.trade?.opened_at) ||
    (idea.trade?.opened_at && idea.trade?.closed_at)
  ) {
    return false
  }
  if (filters.tradeClosedOnly && !idea.trade?.closed_at) {
    return false
  }
  if (orderBy === IdeaOrdering.TRADE_PERFORMANCE && !idea.trade?.main_perf) {
    return false
  }
  if (filters.ideaType && idea.idea_type !== filters.ideaType) {
    return false
  }
  return true
}

interface IIdeasLiveEvents {
  createIdea: (idea: Partial<Idea>, queryKey?: any, append?: boolean) => void
  updateMessage: (
    conversationId: number,
    messageId: number,
    payload: Partial<ConversationMessage>,
  ) => void

  createMessage: (conversationId: number, newMessage: ConversationMessage) => void
  getMessages: (conversationId: number) => ConversationMessage[]
  getIdeasQueryData: (queryKey?: any) => {
    queryKey: any
    ideas: Idea[]
    hasMoreIdeas: boolean
  }
  updateIdea: (ideaId: string | number, payload: Partial<Idea>) => void
}

/**
 * Handles all live events for ideas
 */
const useIdeasLiveEvents = ({
  createIdea,
  updateIdea,
  createMessage,
  updateMessage,
  getMessages,
  getIdeasQueryData,
}: IIdeasLiveEvents) => {
  const queryClient = useQueryClient()

  const { updateUnseenIdeasCount } = useGlobalState()
  const { profile } = useAuth()

  /**
   * On a new message event, we add the message in cache
   * and update the Idea associated
   */
  useNewMessageEvent(event => {
    const alreadyExists = getMessages(event.message.conversation_id).find(message => {
      return message.id === event.message.tmp_id || message.id === event.message.id
    })
    if (!alreadyExists) {
      createMessage(event.message.conversation_id, event.message)
      const { ideas } = getIdeasQueryData()
      const idea = ideas.find(idea => idea.id === event.idea.id)
      /**
       * If idea exists, it means that it is already fetched, we update
       * the conversation params
       */
      if (idea) {
        updateIdea(idea.id, event.idea)
      } else {
        /**
         * Idea was not loaded with pagination. We need to happen it to the local state
         * since we sort by last update
         */
        createIdeaInCache(event.idea)
      }
    }
  })

  /**
   * On a new message event, we update the message in cache
   * and update the Idea associated
   */
  useUpdateMessageEvent(event => {
    const messageToUpdate = getMessages(event.message.conversation_id).find(
      message => message.id === event.message.id,
    )
    if (messageToUpdate) {
      // Receiving a message from a live event, id is always a number
      // TODO: Properly type live event messages with number assertion on id
      updateMessage(event.message.conversation_id, event.message.id as number, event.message)
    }
  })

  const createIdeaInCache = useCallback(
    (idea: Idea) => {
      const { queryKey: activeQueryKey, ideas, hasMoreIdeas } = getIdeasQueryData()
      const defaultQueryKey = [
        "ideas",
        "public",
        { orderBy: "default", sortDirection: "DESC" },
        {
          stock: undefined,
          sector: undefined,
          user: undefined,
          ideaType: "",
          tradeOpenOnly: false,
          tradeClosedOnly: false,
        },
      ]
      let defaultIdeas
      if (!isEqual(activeQueryKey, defaultQueryKey)) {
        // If the default and active query keys are not the same
        // we also retrieve the default query ideas.
        const { ideas } = getIdeasQueryData(defaultQueryKey)
        defaultIdeas = ideas
      }
      const alreadyExistsInActive = ideas.find(ideaInCache => {
        return ideaInCache.id === idea.id
      })
      if (!alreadyExistsInActive) {
        const orderBy = activeQueryKey[2].orderBy
        const sortDirection = activeQueryKey[2].sortDirection
        if (ideaMatchQueryKey(idea, activeQueryKey)) {
          if (
            orderBy === IdeaOrdering.CREATION_DATE &&
            sortDirection === IdeaSorting.ASC &&
            !hasMoreIdeas
          ) {
            createIdea(idea, activeQueryKey, true)
          } else {
            createIdea(idea, activeQueryKey)
          }
        }
      }
      if (defaultIdeas) {
        const alreadyExistsInDefault = defaultIdeas.find(ideaInCache => {
          return ideaInCache.id === idea.id
        })
        if (!alreadyExistsInDefault) {
          // We also create any idea that we received in the default cache
          createIdea(idea, defaultQueryKey)
        }
      }
    },
    [createIdea, getIdeasQueryData],
  )

  /**
   * Handles idea.new event
   * On a new idea event, we update ideas in cache
   * and increment the unseen ideas count by 1
   */
  useNewIdeaEvent(event => {
    createIdeaInCache(event.idea)
    if (event.idea.user.id !== profile?.id) {
      updateUnseenIdeasCount && updateUnseenIdeasCount(1)
    }
  })

  /**
   * Handles idea.update event
   */
  useUpdateIdeaEvent(event => {
    const { ideas } = getIdeasQueryData()
    const ideaToUpdate = ideas.find(idea => {
      return idea.id === event.idea.id
    })
    if (ideaToUpdate) {
      updateIdea(ideaToUpdate.id, event.idea)
    } else {
      createIdeaInCache(event.idea)
    }
  })

  /**
   * Handles idea.trade.open event,
   * if the active cache is ordered by open trade
   * and the idea is not found we create it.
   */
  useOpenIdeaTradeEvent(event => {
    const { queryKey, ideas, hasMoreIdeas } = getIdeasQueryData()
    const ideaId = event.trade.idea_id
    const ideaToUpdate = ideas.find(idea => {
      return idea.id === ideaId
    })
    if (ideaToUpdate && ideaId) {
      updateIdea(ideaId, { trade: event.trade })
    } else {
      const orderBy = queryKey[2].orderBy
      const sortDirection = queryKey[2].sortDirection
      const tradeOpenOnly = queryKey[3].tradeOpenOnly
      if (
        tradeOpenOnly ||
        (orderBy === "trade_open_date" &&
          (sortDirection === "DESC" || (sortDirection === "ASC" && !hasMoreIdeas)))
      ) {
        // If we display onlyOpenTrade we need to refetch the ideas
        // If we are ordering by trade open date and the sort direction is DESC
        // or if the sort direction is ASC and there is no more ideas to fetch
        // we invalidate the cache to fetch the idea with the new trade.
        queryClient.invalidateQueries(queryKey)
      }
    }
  })

  /**
   * Handles idea.trade.close event,
   * if the active cache is ordered by close trade
   * and the idea is not found we create it.
   */
  useCloseIdeaTradeEvent(event => {
    const { queryKey, ideas, hasMoreIdeas } = getIdeasQueryData()
    const ideaToUpdate = ideas.find(idea => {
      return idea.id === event.trade.idea_id
    })
    if (ideaToUpdate) {
      updateIdea(ideaToUpdate.id, { trade: event.trade })
    } else {
      const orderBy = queryKey[2].orderBy
      const sortDirection = queryKey[2].sortDirection
      const tradeClosedOnly = queryKey[3].tradeClosedOnly
      if (
        tradeClosedOnly ||
        (orderBy === "trade_close_date" &&
          (sortDirection === "DESC" || (sortDirection === "ASC" && !hasMoreIdeas)))
      ) {
        // If we display onlyClosedTrade we need to refetch the ideas
        // If we are ordering by trade close date and the sort direction is DESC
        // or if the sort direction is ASC and there is no more ideas to fetch
        // we invalidate the cache to fetch the idea with the new trade.
        queryClient.invalidateQueries(queryKey)
      }
    }
  })
}

export default useIdeasLiveEvents
