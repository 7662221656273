// @ts-nocheck
import React from "react"
import { H3, H4, H5 } from "@blueprintjs/core"

import { Row, Col } from "../../../../../../components"

export const TitleRow = ({ title, level = 1 }) => {
  const Header = () => {
    switch (level) {
      case 2:
        return <H4>{title}</H4>
      case 3:
        return <H5>{title}</H5>
      default:
        return <H3>{title}</H3>
    }
  }
  return (
    <Row>
      <Col xsOffset={1} xs={10}>
        <Header />
        <hr />
      </Col>
    </Row>
  )
}
