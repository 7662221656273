// @ts-nocheck
import React from "react"

import { getDeepNestedValue } from "../../../../../../../utils"
import { Grid } from "../../../../../../components"
import { TitleRow, InputRow, ControlRow, ReadonlyRow } from "../components"
import { createGrowthRows } from "./utils"

const GroupGrowth = ({
  endField,
  handleChange,
  values,
  reportData: { prevReport, prevYearReport },
}) => {
  const groupRows =
    endField === "sales"
      ? [
          {
            type: "title",
            props: {
              title: `Group ${endField} growth`,
            },
          },
          {
            type: "readonly",
            props: {
              title: "Sales growth",
              basePath: [`${endField}_growth_readonly`],
              commentPath: [`${endField}_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              handleChange,
            },
          },
          {
            type: "readonly",
            props: {
              title: "Sales growth QoQ",
              basePath: [`${endField}_growth_qoq_readonly`],
              commentPath: [`${endField}_growth-qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              handleChange,
            },
          },
          {
            type: "control",
            props: {
              title: "Organic + Scope check",
              expected: getDeepNestedValue([`${endField}_growth_readonly`], values),
              sumList: [
                getDeepNestedValue([`${endField}_organic_growth`], values),
                getDeepNestedValue([`${endField}_scope_growth`], values),
              ],
            },
          },
          {
            type: "control",
            props: {
              title: "Organic qoq + Scope qoq check",
              expected: getDeepNestedValue([`${endField}_growth_qoq_readonly`], values),
              sumList: [
                getDeepNestedValue([`${endField}_organic_growth_qoq`], values),
                getDeepNestedValue([`${endField}_scope_growth_qoq`], values),
              ],
            },
          },
          ...createGrowthRows({ endField, values, prevReport, prevYearReport, handleChange }),
        ]
      : [
          {
            type: "title",
            props: {
              title: `Group ${endField} growth`,
            },
          },
          {
            type: "readonly",
            props: {
              title: "Orders growth",
              basePath: [`${endField}_growth_readonly`],
              commentPath: [`${endField}_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              handleChange,
            },
          },

          {
            type: "control",
            props: {
              title: "Organic + Scope check",
              expected: getDeepNestedValue([`${endField}_growth_readonly`], values),
              sumList: [
                getDeepNestedValue([`${endField}_organic_growth`], values),
                getDeepNestedValue([`${endField}_scope_growth`], values),
              ],
            },
          },

          ...createGrowthRows({ endField, values, prevReport, prevYearReport, handleChange }),
        ]

  return (
    <Grid fluid>
      {groupRows.map(({ type, props }, index) => {
        if (type === "title") {
          return <TitleRow key={index} {...props} />
        }
        if (type === "control") {
          return <ControlRow key={index} {...props} />
        }
        if (type === "readonly") {
          return <ReadonlyRow key={index} {...props} />
        }
        return <InputRow key={index} {...props} />
      })}
    </Grid>
  )
}

export default GroupGrowth
