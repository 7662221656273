// @ts-nocheck
import { NonIdealState, Button } from "@blueprintjs/core"
import * as R from "ramda"
import React, { useCallback, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import { fetchAnalyse } from "../../../../state/modules/analysis"

import { Grid, Loader, Row, Col } from "../../../components"
import Chart from "./Chart"
import Side from "./Side"

const Detail = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()

  const analyses = useSelector(R.path(["analysis", "analyses", "list"]))
  const analyse = R.find(R.propEq("id", parseInt(id)))(analyses)

  const handleFetchAnalyse = useCallback(() => dispatch(fetchAnalyse({ id })), [dispatch, id])

  useEffect(() => {
    handleFetchAnalyse()
  }, [handleFetchAnalyse])

  if (!analyse) {
    return (
      <NonIdealState
        icon="heart-broken"
        title={`No analyse can be found with id ${id}.`}
        description={
          <div>
            <p>
              Either it does not exist <br />
              or you don't have rights to view it.
            </p>
          </div>
        }
        action={<Button onClick={() => navigate("/analysis/")} text="Return to analyse list" />}
      />
    )
  }

  if (analyse.fetching || !analyse.grid) {
    return <Loader title="Loading analyse data..." />
  }

  return (
    <Grid>
      <Row>
        <Col xs={12} sm={8}>
          <Chart analyse={analyse} />
        </Col>
        <Col xs={12} sm={4}>
          <Side analyse={analyse} handleFetchAnalyse={handleFetchAnalyse} />
        </Col>
      </Row>
    </Grid>
  )
}

export default Detail
