// @ts-nocheck
import { Button, ButtonGroup, H3 } from "@blueprintjs/core"
import { utcToZonedTime } from "date-fns-tz"
import { includes } from "ramda"
import { useState } from "react"

import { formatDate } from "../../../../../../../_shared/formatDate"
import { Col, Grid, Row } from "../../../../../../../components"
import { dateToPeriod } from "../../../../utils/dateToPeriod"
import Charts from "./Charts"
import Table from "./Table"

const Results = ({ dataSet, dsforecast }) => {
  const data = JSON.parse(dsforecast.agg_output)
  const pct = dataSet.unit_str && includes("pct", dataSet.unit_str)

  /**
   * el[0] is Unix time in ms
   * This date is the end day of a period (year, quarter, month...)
   * In order to do operation on it with date fns, we need to convert it to a Date object,
   * which keep the same time as UTC (we don't want to have change in day when convert to local).
   * To achieve that, we use utcToZonedTime with "UTC" option from "date-fns-tz".
   */
  const entries = data.map(el => {
    return {
      ...dateToPeriod({
        end: utcToZonedTime(el[0], "UTC"),
        periodicity: dataSet.periodicity,
      }),
      value: el[1],
    }
  })

  const [table, setTable] = useState(false)

  return (
    <>
      <Grid fluid>
        <Row>
          <Col xs={2}>
            <H3>
              Results <small>(last update: {formatDate(dsforecast.last_agg_output)})</small>
            </H3>
          </Col>
          <Col xs={10}>
            <ButtonGroup>
              <Button
                text="Chart"
                active={!table}
                onClick={() => setTable(!table)}
                icon="timeline-line-chart"
              />
              <Button text="Table" active={table} onClick={() => setTable(!table)} icon="th" />
            </ButtonGroup>
          </Col>
        </Row>
        {table ? (
          <Table dataSet={dataSet} pct={pct} entries={entries} />
        ) : (
          <Charts dataSet={dataSet} dsforecast={dsforecast} data={data} pct={pct} />
        )}
      </Grid>
    </>
  )
}

export default Results
