// @ts-nocheck
import { Checkbox } from "@blueprintjs/core"
import { format } from "date-fns"
import { path, pathOr } from "ramda"
import { useSelector } from "react-redux"

import profileDuck from "../../../../../../state/modules/profile"
import { SCIENT_COLORS } from "../../../../../../styled/scientColors"
import { Col, Row } from "../../../../../components"

const ForecastCheckbox = ({ dispatch, forecast, stockSlug }) => {
  const { starred, excluded } = useSelector(path(["profile", "userstockconfigs", stockSlug]))
  const forecastSlug = forecast.slug
  const lastReportDate = useSelector(
    pathOr(null, ["stockDetail", stockSlug, "stockData", "last_report_date"]),
  )

  const forecastOptions = {
    label:
      forecast.company +
      "-" +
      (forecast.label === "Base" ? "" : `${forecast.label}-`) +
      (forecast.owner === "default" ? "" : `${forecast.owner}-`) +
      format(new Date(forecast.owner_updated_at), "dd/MM/yy HH:mm"),
    style:
      forecast.owner_updated_at < lastReportDate
        ? { color: SCIENT_COLORS.red3 }
        : forecast.owner_updated_at < forecast.source_updated_at
        ? { color: SCIENT_COLORS.orange3 }
        : null,
  }

  const handleStarred = () => {
    return dispatch(
      profileDuck.actions.stockToggleStarred({
        slug: stockSlug,
        starred,
        excluded,
        forecastSlug,
      }),
    )
  }

  const handleExcluded = () => {
    return dispatch(
      profileDuck.actions.stockToggleExcluded({
        slug: stockSlug,
        starred,
        excluded,
        forecastSlug,
      }),
    )
  }

  return (
    <Row center="xs" style={{ margin: "8px 0" }}>
      <Col xs={8}>
        <p style={forecastOptions.style}>{forecastOptions.label}</p>
      </Col>

      <Col xs={2}>
        <Checkbox checked={starred.includes(forecastSlug)} onChange={handleStarred} />
      </Col>
      <Col xs={2}>
        <Checkbox checked={excluded.includes(forecastSlug)} onChange={handleExcluded} />
      </Col>
    </Row>
  )
}

export default ForecastCheckbox
