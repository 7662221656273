// @ts-nocheck
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as R from "ramda"
import { AgGridReact } from "ag-grid-react"

import sheetsDuck from "../../../../../state/modules/sheets"

import { contextMenuMsc, rowClassRules, shapeColTitles, shapeRowData, shapeRows } from "./utils"
import { useGetWritePermission } from "../../utils"
import { useAuth } from "../../../../hooks"

const MscGrid = ({ desk, sheet, output, setEditRowItems, setEditColItems }) => {
  const { profile } = useAuth()
  const [gridApi, setGridApi] = useState({})
  const [, setGridColumnApi] = useState({})

  const dispatch = useDispatch()

  const { colTitles, rowData } = output

  const fieldList = useSelector(R.pathOr({}, ["shared", "fieldList"]))
  const factorList = useSelector(R.pathOr({}, ["shared", "fieldList", "factor_list"]))

  // Variables for permissions
  const deskOwnerId = desk.owner_id
  const userId = profile.id
  const hasWritePermission = useGetWritePermission(desk)
  // end Variables for permissions

  const rowDefs = sheet.rowDefs
  const flatRows = shapeRows(rowDefs.rows)

  const colDefs = sheet.colDefs
  const colsDisplayed = R.reject(e => e.hide === true, colDefs.cols)

  const target = colDefs.sort[0] && colDefs.sort[0].target
  const method = colDefs.sort[0] && colDefs.sort[0].method

  useEffect(() => {
    if (gridApi.setRowData) {
      gridApi.setRowData([...shapeRowData(rowData, flatRows)])
    }
  }, [gridApi, rowData, flatRows])

  const columnDefs = R.isEmpty(colsDisplayed)
    ? [{ headerName: "", field: "desc", pinned: "left", width: 300 }]
    : [
        { headerName: "", field: "desc", pinned: "left", width: 300 },
        ...shapeColTitles(colTitles, colsDisplayed, fieldList, factorList, target, method),
      ]

  const agOptions = {
    reactNext: true,
    defaultColDef: {
      // width: 120, // Now control by value in colDefs and defauft define in shapeColTitles
      menuTabs: [],
    },
    columnDefs,
    headerHeight: rowDefs.frontOpts.headerHeight ? rowDefs.frontOpts.headerHeight : 27,
    groupHeaderHeight: rowDefs.frontOpts.groupHeaderHeight
      ? rowDefs.frontOpts.groupHeaderHeight
      : 32,
    rowSelection: "single",
    suppressPropertyNamesCheck: true,
    getRowId: params => R.path(["data", "extra", "rowId"], params),
    localeText: {
      noRowsToShow:
        "No data to show: please click right to open context menu. Then edit rows and edit Column",
    },
  }

  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  // Actions to sort columns
  const setAscendingSort = colId => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        colDefs: {
          ...sheet.colDefs,
          sort: [{ target: colId, method: "asc" }],
        },
      }),
    )
  }
  const setDescendingSort = colId => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        colDefs: {
          ...sheet.colDefs,
          sort: [{ target: colId, method: "desc" }],
        },
      }),
    )
  }
  const setUnsort = () => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        colDefs: {
          ...sheet.colDefs,
          sort: [],
        },
      }),
    )
  }
  //

  return (
    <AgGridReact
      onGridReady={onGridReady}
      animateRows={true}
      scrollbarWidth={15}
      tooltipShowDelay={1}
      // onRowDragEnd={handleRowDragEnd}
      rowClassRules={rowClassRules}
      sizeColumnsToFit
      getContextMenuItems={
        (userId === deskOwnerId || hasWritePermission) &&
        (params =>
          contextMenuMsc({
            sheet,
            params,
            target,
            method,
            fieldList,
            factorList,
            setEditRow: setEditRowItems,
            setEditCol: setEditColItems,
            setAscendingSort: setAscendingSort,
            setDescendingSort: setDescendingSort,
            setUnsort: setUnsort,
          }))
      }
      overlayNoRowsTemplate={
        "<span style=\"padding: 10px; border: 2px solid #444; font-size: 1.4em\">No rows to show.</br>Right click to open context menu, then click on 'Edit rows' and 'Edit columns'.</span>"
      }
      {...agOptions}
    />
  )
}

export default MscGrid
