// @ts-nocheck
import { Spinner } from "@blueprintjs/core"
import { isEmpty } from "ramda"
import { connect } from "react-redux"
import { compose, lifecycle } from "recompose"

import dataSetSearchDuck from "../../../../state/modules/dataSetSearch"
import { NoResponseContainer } from "./DataSets.styled"
import Main from "./Main"
import Side from "./Side"

const DataSetsPage = ({ sidebar, response }) => (
  <>
    <div style={{ overflow: "hidden" }} className="content">
      {isEmpty(response) ? (
        <NoResponseContainer
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="12px"
        >
          <Spinner intent="success" />
          <p>Loading datasets…</p>
        </NoResponseContainer>
      ) : (
        <Main response={response} />
      )}
    </div>
    <div className={sidebar ? "side side-300" : "hide"}>
      <Side />
    </div>
  </>
)

const mapStateToProps = ({ ui, dataSetSearch }) => ({
  sidebar: ui.sidebar,
  response: dataSetSearch.response,
})

export default compose(
  connect(mapStateToProps, { ...dataSetSearchDuck.actions }),
  lifecycle({
    componentDidMount() {
      const searchParams = JSON.parse(localStorage.getItem("dataSetSearch"))
      this.props.searchDataSet({ ...searchParams, useLocalStorage: true })
    },
  }),
)(DataSetsPage)
