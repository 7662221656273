// @ts-nocheck
import { compose, pathOr, propEq } from "ramda"

const stylePath = ["data", "extra", "frontOpts", "styles"]
const errorPath = ["data", "extra", "error"]
export const rowClassRules = {
  "sct-focus1": compose(propEq("focus", "1"), pathOr({}, stylePath)),
  "sct-focus2": compose(propEq("focus", "2"), pathOr({}, stylePath)),
  "sct-focus3": compose(propEq("focus", "3"), pathOr({}, stylePath)),
  "sct-focus4": compose(propEq("focus", "4"), pathOr({}, stylePath)),
  "sct-italic": compose(propEq("italic", true), pathOr({}, stylePath)),
  "sct-bold": compose(propEq("bold", true), pathOr({}, stylePath)),
  "sct-underline": compose(propEq("underline", true), pathOr({}, stylePath)),
  "sct-empty-row": compose(propEq("emptyRow", true), pathOr({}, stylePath)),

  "sct-front-blue": compose(propEq("frontColor", "blue"), pathOr({}, stylePath)),
  "sct-front-green": compose(propEq("frontColor", "green"), pathOr({}, stylePath)),
  "sct-front-orange": compose(propEq("frontColor", "orange"), pathOr({}, stylePath)),
  "sct-front-red": compose(propEq("frontColor", "red"), pathOr({}, stylePath)),
  "sct-front-indigo": compose(propEq("frontColor", "indigo"), pathOr({}, stylePath)),
  "sct-front-turquoise": compose(propEq("frontColor", "turquoise"), pathOr({}, stylePath)),
  "sct-front-sepia": compose(propEq("frontColor", "sepia"), pathOr({}, stylePath)),

  "sct-row-error": pathOr(false, errorPath),
}

// Logic for cellClass
const focus = params => {
  switch (params.colDef.frontOpts.styles.focus) {
    case "1":
      return "sct-focus1"
    case "2":
      return "sct-focus2"
    case "3":
      return "sct-focus3"
    case "4":
      return "sct-focus4"
    default:
      return null
  }
}
const italic = params => {
  return params.colDef.frontOpts.styles.italic ? "sct-italic" : null
}
const bold = params => {
  return params.colDef.frontOpts.styles.bold ? "sct-bold" : null
}
const frontColor = params => {
  switch (params.colDef.frontOpts.styles.frontColor) {
    case "blue":
      return "sct-front-blue"
    case "green":
      return "sct-front-green"
    case "orange":
      return "sct-front-orange"
    case "red":
      return "sct-front-red"
    case "indigo":
      return "sct-front-indigo"
    case "turquoise":
      return "sct-front-turquoise"
    case "sepia":
      return "sct-front-sepia"
    default:
      return null
  }
}
export const cellClass = params => {
  return [focus(params), italic(params), bold(params), frontColor(params)]
}
