// @ts-nocheck
import { combineEpics } from "redux-observable"
import fetchDataSetEpic from "./fetchDataSetEpic"
import fetchDataSetOkEpic from "./fetchDataSetOkEpic"
import fetchDataSetsEpic from "./fetchDataSetsEpic"
import updateDataSetEpic from "./updateDataSetEpic"

const dataSetsEpics = combineEpics(
  fetchDataSetEpic,
  fetchDataSetOkEpic,
  fetchDataSetsEpic,
  updateDataSetEpic,
)

export default dataSetsEpics
