import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "./hooks"
import { SCIENT_ROUTES } from "./Routes"

/**
 * Wrapper around DOM in order to redirect to LOGIN
 * for routes that need Authentication
 */
const ProtectedRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { authenticated } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!authenticated) {
      navigate(SCIENT_ROUTES.LOGIN, { replace: true, state: { from: location } })
    }
  }, [authenticated, navigate, location])

  return children
}

export default ProtectedRoute
