// @ts-nocheck
import { Button, ButtonGroup, Tag, Tooltip } from "@blueprintjs/core"
import { compose, propEq, reject } from "ramda"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import desksDuck from "../../../../../state/modules/desks"
import { Col, Row } from "../../../../components"
import { trimStrName } from "../../utils"
import { updatePos } from "../../utils/updatePos"

const UserDeskToRemove = ({ userDesks, userDesk }) => {
  const deskId = userDesk.desk
  const deskName = trimStrName(userDesk.str_name)

  const url = useLocation().pathname
  const deskIdInUrl = /\d+/.exec(url)[0]

  const navigate = useNavigate()
  const redirect = () => navigate(`/desks/`)

  const dispatch = useDispatch()

  const removeFromUserDesk = () => {
    if (deskId === deskIdInUrl) {
      dispatch(
        desksDuck.actions.upsertUserDesks(
          compose(updatePos, reject(propEq("desk", deskId)))(userDesks),
        ),
      )
      setTimeout(() => {
        redirect()
      }, 1000)
    } else
      dispatch(
        desksDuck.actions.upsertUserDesks(
          compose(updatePos, reject(propEq("desk", deskId)))(userDesks),
        ),
      )
  }

  const handleClick = () => removeFromUserDesk()

  return (
    <Row style={{ margin: "1px 0" }}>
      <Col xs={10}>
        <Tag fill minimal large style={{ textAlign: "center" }}>
          {deskName}
        </Tag>
      </Col>
      <Col xs={2}>
        <ButtonGroup fill>
          <Tooltip content="Remove from visible desks" openOnTargetFocus={false} compact>
            <Button minimal intent="primary" icon="remove" onClick={handleClick} />
          </Tooltip>
          {/* Can't display desk owner name because info is missing. We don't have access to desk no shared. */}
          {/* <p style={{ margin: "auto", fontSize: "11px", textAlign: "center" }}>
            {`Owner: ${deskOwner}`}
          </p> */}
        </ButtonGroup>
      </Col>
    </Row>
  )
}

export default UserDeskToRemove
