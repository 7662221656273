import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const IdeaCreateHeaderContainer = styled(FlexContainer)({
  padding: "0 15px 10px 5px",
  overflow: "auto",
})

export const DraftSwitchContainer = styled(FlexContainer)({
  marginTop: "12px",
})

export const IdeaCreateHeaderButtons = styled(FlexContainer)({
  overflow: "auto",
})
