import {
  CellClassParams,
  CellStyleFunc,
  RowStyle,
  ValueFormatterFunc,
  ValueFormatterParams,
} from "ag-grid-community"

import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { OrderSide, PositionSide } from "../types"

export enum ScientRowStyle {
  HIGHLIGHTED_BLACK_BOLD = "highlightedBlackBold",
}

export enum ScientCellStyle {
  RED_AND_GREEN_BOLD = "redAndGreenBold",
  WHITE_BOLD = "whiteBold",
  WHITE = "white",
  POSITION_SIDE = "positionSide",
  ORDER_SIDE = "orderSide",
  MEDIUM_TEXT_CENTERED = "mediumTextCentered",
}

export enum ScientValueFormatter {
  PERCENTAGE = "percentage",
  INT_PERCENTAGE = "int_percentage",
  NUMBER_GB = "number_gb",
  INT_GB = "int_gb",
  TEXT = "text",
  TEXT_CAPITALIZED = "textCapitalized",
}

export enum AgGridFilter {
  AG_NUMBER = "agNumberColumnFilter",
  AG_SET = "agSetColumnFilter",
}

/**
 * Below is for cell styling
 */
const mediumTextCenteredStrategy = (params: CellClassParams) => {
  return { display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px" }
}

const redAndGreenStrategy = (params: CellClassParams) => {
  return params.value > 0
    ? { color: SCIENT_COLORS.forest3, fontWeight: "bold" }
    : params.value < 0
      ? { color: SCIENT_COLORS.vermilion4, fontWeight: "bold" }
      : undefined
}

const whiteBoldStrategy = (params: CellClassParams) => {
  return { color: SCIENT_COLORS.white, fontWeight: "bold" }
}

const whiteStrategy = (params: CellClassParams) => {
  return { color: SCIENT_COLORS.white }
}

/**
 * positionSideStrategy is specific to Trading
 */
const positionSideStrategy = (params: CellClassParams) => {
  switch (params.value) {
    case PositionSide.LONG:
      return {
        backgroundColor: SCIENT_COLORS.green3,
        color: SCIENT_COLORS.white,
        fontWeight: "bold",
      }
    case PositionSide.SHORT:
      return {
        backgroundColor: SCIENT_COLORS.gold3,
        color: SCIENT_COLORS.white,
        fontWeight: "bold",
      }
    default:
      return undefined
  }
}

/**
 * orderSideStrategy is specific to Trading
 */
const orderSideStrategy = (params: CellClassParams) => {
  switch (params.value) {
    case OrderSide.BUY:
    case OrderSide.BUY_COVER:
      return {
        backgroundColor: SCIENT_COLORS.green3,
        color: SCIENT_COLORS.white,
        fontWeight: "bold",
      }
    case OrderSide.SELL:
    case OrderSide.SELL_SHORT:
      return {
        backgroundColor: SCIENT_COLORS.gold3,
        color: SCIENT_COLORS.white,
        fontWeight: "bold",
      }
    default:
      return undefined
  }
}

export const columnsDefCellStyleMapping: { [key in ScientCellStyle]: CellStyleFunc } = {
  [ScientCellStyle.RED_AND_GREEN_BOLD]: redAndGreenStrategy,
  [ScientCellStyle.WHITE_BOLD]: whiteBoldStrategy,
  [ScientCellStyle.WHITE]: whiteStrategy,
  [ScientCellStyle.POSITION_SIDE]: positionSideStrategy,
  [ScientCellStyle.ORDER_SIDE]: orderSideStrategy,
  [ScientCellStyle.MEDIUM_TEXT_CENTERED]: mediumTextCenteredStrategy,
}

/**
 * Below is for value formatter
 */
const numberGBFormatter = (params: ValueFormatterParams) => {
  if (typeof params.value === "number") {
    return Intl.NumberFormat("en-GB").format(+params.value.toFixed(2))
  } else return "-"
}

const intGBFormatter = (params: ValueFormatterParams) => {
  if (typeof params.value === "number") {
    return Intl.NumberFormat("en-GB").format(+params.value.toFixed(0))
  } else return "-"
}

const percentageFormatter = (params: ValueFormatterParams) => {
  return typeof params.value === "number"
    ? `${Intl.NumberFormat("en-GB").format(+params.value.toFixed(2))} %`
    : "-"
}

const intPercentageFormatter = (params: ValueFormatterParams) => {
  return typeof params.value === "number"
    ? `${Intl.NumberFormat("en-GB").format(+params.value.toFixed(0))} %`
    : "-"
}

const identityFormatter = (params: ValueFormatterParams) => {
  return params.value
}

const textCapitalizedFormatter = (params: ValueFormatterParams) => {
  return params.value?.toUpperCase()
}

export const columnsDefValueFormatterMapping: {
  [key in ScientValueFormatter]: string | ValueFormatterFunc
} = {
  [ScientValueFormatter.NUMBER_GB]: numberGBFormatter,
  [ScientValueFormatter.PERCENTAGE]: percentageFormatter,
  [ScientValueFormatter.INT_PERCENTAGE]: intPercentageFormatter,
  [ScientValueFormatter.TEXT]: identityFormatter,
  [ScientValueFormatter.TEXT_CAPITALIZED]: textCapitalizedFormatter,
  [ScientValueFormatter.INT_GB]: intGBFormatter,
}

/**
 * Below is for row styling
 */

const highlightedBlackBoldStrategy = {
  backgroundColor: SCIENT_COLORS.darkGray1,
  fontWeight: "bold",
}

export const rowStyleMapping: { [key in ScientRowStyle]: RowStyle } = {
  [ScientRowStyle.HIGHLIGHTED_BLACK_BOLD]: highlightedBlackBoldStrategy,
}
