// @ts-nocheck
export const defaultOptions = {
  chart: {
    type: "line",
    height: (8 / 16) * 100 + "%", // 16:9 ratio
  },
  rangeSelector: {
    inputEnabled: false,
    allButtonsEnabled: true,
    buttons: [
      {
        type: "month",
        count: 6,
        text: "6M",
      },
      {
        type: "year",
        count: 1,
        text: "1Y",
      },
      {
        type: "year",
        count: 3,
        text: "3Y",
      },
      {
        type: "year",
        count: 5,
        text: "5Y",
      },
      {
        type: "all",
        text: "ALL",
      },
    ],
  },
  xAxis: {
    //  In a datetime axis, the numbers are Unix time in milliseconds
    type: "datetime",
    // In an ordinal axis, the points are equally spaced in the chart regardless
    // of the actual time or x distance between them. This means that missing data periods
    // (e.g. nights or weekends for a stock chart) will not take up space in the chart.
    // Having ordinal: false will show any gaps created by the gapSize setting proportionate to their duration.
    ordinal: false,
    dateTimeLabelFormats: {
      day: "%d-%m-%y",
      month: "%m-%y",
      year: "%y",
    },
  },
  plotOptions: {
    line: {
      marker: {
        enabled: true,
      },
    },
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  },
}
