import { useMemo } from "react"

import { formatValue } from "../../utils/formatValue"
import { PositionData, RebalancingColumn } from "../types"
import { Row } from "../../types"

interface IuseRebalancingDataProps {
  position: PositionData
  emptyRows: number
}

const rebalancingFormatValueOptions = (row: Row, col: RebalancingColumn) => ({
  value: row[col.field],
  type: col.valueFormatter,
  pctDecimal: 1,
  pctMinFractionDigits: 1,
  pctMaxFractionDigits: 1,
  floatMinFractionDigits: 1,
  floatMaxFractionDigits: 1,
})

/**
 * Custom hook which format Rebalancing rows data
 */

const useRebalancingTable = ({ position, emptyRows }: IuseRebalancingDataProps) => {
  const formattedRows = useMemo(() => {
    position.rows = position.rows.concat(new Array(emptyRows).fill(""))
    return position.rows.map(row => {
      const formattedRow: { [key: string]: string | undefined } = {}
      position.columns.forEach(col => {
        formattedRow[col.field] = formatValue(rebalancingFormatValueOptions(row, col))
      })
      return formattedRow
    })
  }, [emptyRows, position])

  const formattedPinnedBottomRows = useMemo(() => {
    return position.pinnedBottomRows.rowData.map(row => {
      const formattedPinnedBottomRow: { [key: string]: string | undefined } = {}
      position.columns.forEach(col => {
        formattedPinnedBottomRow[col.field] = formatValue(rebalancingFormatValueOptions(row, col))
      })
      return formattedPinnedBottomRow
    })
  }, [position])

  const firstColumn = useMemo(() => position.columns[0], [position.columns])
  const otherColumns = useMemo(
    () => position.columns.filter((_, index) => index !== 0),
    [position.columns],
  )

  return { formattedRows, formattedPinnedBottomRows, firstColumn, otherColumns }
}

export default useRebalancingTable
