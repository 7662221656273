// @ts-nocheck
import React from "react"
import { FormGroup, InputGroup, Callout } from "@blueprintjs/core"

export const TextInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  labelProps = null,
  onEnter = () => {
    // do nothing
  },
  onEsc = () => {
    // do nothing
  },
  ...props
}) => {
  const handleKeyUp = e => {
    if (e.key === "Enter") {
      onEnter()
    }
    if (e.key === "Escape") {
      onEsc()
    }
  }

  if (labelProps) {
    return (
      <FormGroup {...labelProps}>
        <InputGroup type="text" fill {...field} {...props} onKeyDown={handleKeyUp} />
        {touched[field.name] && errors[field.name] && (
          <Callout intent="primary" title={errors[field.name]} />
        )}
      </FormGroup>
    )
  }
  return (
    <>
      <InputGroup type="text" fill {...field} {...props} onKeyDown={handleKeyUp} />
      {touched[field.name] && errors[field.name] && (
        <Callout intent="primary" title={errors[field.name]} />
      )}
    </>
  )
}
