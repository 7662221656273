// @ts-nocheck
import { Button, Classes, Dialog, H4 } from "@blueprintjs/core"
import {
  append,
  compose,
  dissoc,
  keys,
  partition,
  path,
  pathOr,
  prop,
  propEq,
  remove,
  values,
} from "ramda"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import profileDuck from "../../../../../../../state/modules/profile"
import { SCIENT_COLORS } from "../../../../../../../styled/scientColors"
import { Col, Row } from "../../../../../../components"
import { getAllTabs } from "../../../../utils"
import TabItem from "./TabItem"

const TabList = ({ stockSlug }) => {
  const [tabToDelete, setTabToDelete] = useState(false)

  const profile = useSelector(prop("profile"))
  const { activeTab, availableTabs } = path(["userstockconfigs", stockSlug], profile)
  const forecastSlugs = keys(useSelector(pathOr({}, ["rForecasts", stockSlug, "forecasts"])))

  const allTabs =
    forecastSlugs.length < 2
      ? dissoc("consensus", getAllTabs(stockSlug, profile))
      : getAllTabs(stockSlug, profile)

  const [customs, defaults] = compose(partition(propEq("isCustom", true)), values)(allTabs)

  const dispatch = useDispatch()

  const updateAvailableTabs = availableTabs =>
    dispatch(
      profileDuck.actions.stockSetAvailableTabs({
        slug: stockSlug,
        availableTabs,
      }),
    )

  const addTab = tab => updateAvailableTabs(append(tab, availableTabs))
  const removeTab = index => {
    const newAvailableTabs = remove(index, 1, availableTabs)
    const removeTabId = availableTabs[index]
    if (removeTabId === activeTab) {
      const newActiveTab = availableTabs.length === 1 ? null : newAvailableTabs[0]
      dispatch(
        profileDuck.actions.stockSetActiveTab({
          slug: stockSlug,
          activeTab: newActiveTab,
        }),
      )
    }
    return updateAvailableTabs(newAvailableTabs)
  }

  const handleDelete = () => {
    dispatch(profileDuck.actions.stockDeleteTab({ slug: stockSlug, tabId: tabToDelete.id }))
    setTabToDelete(false)
  }

  return (
    <>
      <Row>
        <Col>
          <H4 style={{ color: SCIENT_COLORS.blue5 }}>Tabs Selection</H4>
        </Col>
      </Row>
      <Row>
        <Col xs>
          <p style={{ fontWeight: "bold" }}>Default Tabs</p>
          {defaults.map(tab => (
            <TabItem
              key={tab.id}
              stockSlug={stockSlug}
              availableTabs={availableTabs}
              addTab={addTab}
              removeTab={removeTab}
              tab={tab}
              setTabToDelete={setTabToDelete}
            />
          ))}
        </Col>
        <Col xs>
          <p style={{ fontWeight: "bold" }}>Custom Tabs</p>
          {customs.map(tab => (
            <TabItem
              key={tab.id}
              stockSlug={stockSlug}
              availableTabs={availableTabs}
              addTab={addTab}
              removeTab={removeTab}
              tab={tab}
              setTabToDelete={setTabToDelete}
            />
          ))}
        </Col>
      </Row>
      <Dialog
        title="Delete tab"
        className="bp5-dark"
        icon="delete"
        intent="danger"
        isOpen={tabToDelete}
        onClose={() => setTabToDelete(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Are you sure you want to delete tab <strong>{tabToDelete.title}</strong>?{" "}
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setTabToDelete(false)} text="Close" />
            <Button onClick={handleDelete} intent="danger" text="Delete" />
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default TabList
