/** @jsxImportSource @emotion/react */
import { Dialog } from "@blueprintjs/core"
import { getTime } from "date-fns"
import { Dispatch, SetStateAction, useCallback, useMemo } from "react"
import { FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { roundNumber } from "../../../_shared"
import { ModelUpdate, fromMetricString } from ".././types/business"
import { useChartHistoricalData } from "../hooks"
import { FieldType, UpdatedMetric } from "../types/business"
import ChartComponent from "./ChartComponent"
import { NocDialogCss } from "./NocChart.styled"
import { prepareChartSeries } from "./NocChartContent"
import NocChartMetadata from "./NocChartMetadata"

interface INocChartDialogProps {
  openChartDialog: boolean
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>
  availablePeriods: string[]
  selectedPeriod: string
  setSelectedPeriod: Dispatch<SetStateAction<string>>
  selectedMetric: string
  setSelectedMetric: Dispatch<SetStateAction<string>>
  selectedTicker: string
  selectedAnalyst: string | null
  selectedDatetime: string
  updatedMetric: UpdatedMetric | null
  card: ModelUpdate
  setSelectedUpdate: Dispatch<SetStateAction<UpdatedMetric | null>>
}

const ChartContainer = ({
  openChartDialog,
  setOpenChartDialog,
  availablePeriods,
  selectedPeriod,
  setSelectedPeriod,
  selectedMetric,
  setSelectedMetric,
  selectedTicker,
  selectedAnalyst,
  selectedDatetime,
  card,
  setSelectedUpdate,
  updatedMetric,
}: INocChartDialogProps) => {
  const getSelectedUpdate = useCallback((): UpdatedMetric => {
    const metricFieldType = fromMetricString(selectedMetric)
    let res: UpdatedMetric = Object()
    card.years.forEach((year, index) => {
      if (year.updates && year.period === selectedPeriod) res = year.updates[metricFieldType]
      year.half_years.forEach((half_year, index) => {
        if (half_year.updates && half_year.period === selectedPeriod)
          res = year.half_years[index].updates[metricFieldType]
      })
      year.quarters.forEach((quarter, index) => {
        if (quarter.updates && quarter.period === selectedPeriod)
          res = year.quarters[index].updates[metricFieldType]
      })
    })
    return res
  }, [card.years, selectedMetric, selectedPeriod])

  const current_update = useMemo(() => getSelectedUpdate(), [getSelectedUpdate])
  const update_id = current_update ? current_update.id : null
  const { chartData, isFetching } = useChartHistoricalData({ update_id })

  const getSeriesData = useCallback(() => {
    if (!chartData) return []

    // Fetching estimates data (timestamps & values)
    const estimateTimestamps = chartData.data.history.timestamps
    const estimateSerie: Array<Array<number>> = []
    estimateTimestamps.forEach((dataPoint, index) => {
      const tempUnixTs = getTime(new Date(dataPoint))
      const tempValue = [FieldType.PROFIT_MARGIN, FieldType.OSG].includes(current_update.metric)
        ? roundNumber(chartData.data.history.values[index] * 100)
        : roundNumber(chartData.data.history.values[index])
      estimateSerie.push([tempUnixTs, tempValue])
    })
    // Adding last estimate update if it does not already exist in the chart data already
    if (
      current_update?.current_value_at &&
      getTime(new Date(estimateTimestamps[estimateTimestamps.length - 1])) <
        getTime(new Date(current_update?.current_value_at))
    ) {
      estimateSerie.push([
        getTime(new Date(current_update?.current_value_at)),
        roundNumber(current_update?.current_value),
      ])
    }

    // Fetching CON data (timestamps & values)
    const conSerie: Array<Array<number>> = []
    const conTimestamps =
      chartData.data.con_history && chartData.data.con_history.timestamps.length > 0
        ? chartData.data.con_history.timestamps
        : null
    if (!conTimestamps)
      return [
        prepareChartSeries(card.company.name, estimateSerie, SCIENT_COLORS.green4, "rectangle", 1),
      ]
    else
      conTimestamps.forEach((conDataPoint, index) => {
        const tempUnixTs = getTime(new Date(conDataPoint))
        const tempValue = [FieldType.PROFIT_MARGIN, FieldType.OSG].includes(current_update.metric)
          ? roundNumber(chartData.data.con_history.values[index] * 100)
          : roundNumber(chartData.data.con_history.values[index])
        conSerie.push([tempUnixTs, tempValue])
      })
    // Adding last CON update if it does not already exist in the chart data already
    if (
      current_update?.consensus_data?.consensus_at &&
      getTime(new Date(conTimestamps[conTimestamps.length - 1])) <
        getTime(new Date(current_update?.consensus_data?.consensus_at))
    ) {
      conSerie.push([
        getTime(new Date(current_update?.consensus_data?.consensus_at)),
        roundNumber(current_update?.consensus_data?.consensus_value),
      ])
    }
    return [
      prepareChartSeries(card.company.name, estimateSerie, SCIENT_COLORS.green4, "rectangle", 1),
      prepareChartSeries("CON", conSerie, SCIENT_COLORS.vermilion4, "lineMarker", 2),
    ]
  }, [
    chartData,
    current_update?.consensus_data?.consensus_at,
    current_update?.consensus_data?.consensus_value,
    current_update?.current_value,
    current_update?.current_value_at,
    current_update.metric,
    card.company.name,
  ])

  return (
    <Dialog
      title="Historical Metric Updates Chart"
      onClose={() => setOpenChartDialog(false)}
      isOpen={openChartDialog}
      className="bp5-dark"
      css={NocDialogCss}
    >
      <FlexContainerAndItem flexGrow={1} flexDirection="column" alignItems="center" gap="60px">
        <NocChartMetadata
          selectedAnalyst={selectedAnalyst}
          selectedDatetime={selectedDatetime}
          current_update={current_update}
          setOpenChartDialog={setOpenChartDialog}
          setSelectedPeriod={setSelectedPeriod}
          selectedPeriod={selectedPeriod}
          setSelectedMetric={setSelectedMetric}
          setSelectedUpdate={setSelectedUpdate}
          availablePeriods={availablePeriods}
          selectedMetric={selectedMetric}
        />
        <ChartComponent
          isFetching={isFetching}
          selectedTicker={selectedTicker}
          selectedPeriod={selectedPeriod}
          selectedMetric={selectedMetric}
          seriesData={getSeriesData()}
        />
      </FlexContainerAndItem>
    </Dialog>
  )
}
export default ChartContainer
