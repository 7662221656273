// @ts-nocheck
import * as R from "ramda"
import * as recast from "recast"

export const getLoopParams = ({ activeItem, loopOnSegs }) => {
  // We are parsing loop_on with AST, and we retrive interesting elements
  const ast = recast.parse(activeItem.loop_on)

  // identifier retrives primary source if there's one and 'srcKind' (= splits or fields)
  const identifier = []
  recast.visit(ast, {
    visitIdentifier: function (path) {
      const name = path.value.name
      identifier.push(name)
      this.traverse(path)
    },
  })

  // literal retrives source (if not a primary source) and split
  const literal = []
  recast.visit(ast, {
    visitLiteral: function (path) {
      const value = path.value.value
      literal.push(value)
      this.traverse(path)
    },
  })

  // params takes values from literal and if need identifier
  let params = {}

  if (loopOnSegs === true) {
    params = {
      kind: "segs",
      slugs: literal.includes("slugs")
        ? `'slugs': [${R.compose(
            R.map(e => `'${e}'`),
            R.filter(e => e !== "slugs"),
          )(literal)}]`
        : literal.includes("active")
        ? "'active': 1"
        : literal.includes("all")
        ? "'all': 1"
        : "",
    }
  }

  const srcKind = identifier.length === 0 ? "" : identifier.includes("stsrc0") ? "primary" : "new"

  if (activeItem.loop_on.includes("fields")) {
    const slugs = literal.includes("slugs")
      ? `'slugs': [${R.compose(
          R.map(e => `'${e}'`),
          srcKind === "new" ? R.tail() : R.identity(),
          R.reject(e => e === "slugs"),
        )(literal)}]`
      : literal.includes("active")
      ? "'active': 1"
      : literal.includes("all")
      ? "'all': 1"
      : ""
    params = {
      srcKind,
      src: identifier.includes("stsrc0")
        ? identifier[1]
        : literal.length > 2 // if not true, so src is not defined
        ? `${literal[0]}`
        : "",
      kind: R.isEmpty(identifier)
        ? ""
        : identifier.includes("stsrc0")
        ? identifier[2]
        : identifier[1],
      slugs,
    }
  }

  if (activeItem.loop_on.includes("splits")) {
    const slugs = literal.includes("slugs")
      ? `'slugs': [${R.compose(
          R.map(e => `'${e}'`),
          R.filter(e => e === "main" || e === "secondary"),
        )(literal)}]`
      : literal.includes("active")
      ? "'active': 1"
      : literal.includes("all")
      ? "'all': 1"
      : ""

    params = {
      srcKind,
      src: identifier.includes("stsrc0")
        ? identifier[1]
        : literal.length > 2 // if not true, so src is not defined
        ? `${literal[0]}`
        : "",
      kind: R.isEmpty(identifier)
        ? ""
        : identifier.includes("stsrc0")
        ? identifier[2]
        : identifier[1],
      slugs,
    }
  }
  return params
}
