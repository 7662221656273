import { useApi } from "../../../../hooks/useApi"
import { useQuery } from "@tanstack/react-query"

const useComplianceRules = () => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["complianceRules"],
    scientApi.trading.getComplianceRules,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false, // Do not refetch when component mounts
      refetchOnWindowFocus: false, // Do not refetch when window is focused
      refetchOnReconnect: false, // Do not refetch on reconnect
      retry: false, // Do not retry on error
    },
  )

  return {
    complianceRules: data,
    isLoading,
    error,
  }
}

export default useComplianceRules
