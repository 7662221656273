import { Icon } from "@blueprintjs/core"
import {
  IdeaPerformanceMetricsContainer,
  MetricContainer,
  MetricLabel,
  PerfPercent,
  TradeIconContainer,
  TradeNumber,
} from "./IdeaPerformanceMetrics.styled"

type IdeaPerformanceMetricsProps = {
  label: string
  performance?: number
  numberOfTrades: number
  isGlobalAllTime?: boolean
}

const IdeaPerformanceMetrics = ({
  label,
  performance,
  numberOfTrades,
  isGlobalAllTime = false,
}: IdeaPerformanceMetricsProps) => {
  return (
    <IdeaPerformanceMetricsContainer gap="20px" alignItems="center">
      <MetricContainer flexGrow={1}>
        <MetricLabel isGlobalAllTime={isGlobalAllTime}>{label}</MetricLabel>
      </MetricContainer>
      <MetricContainer flexGrow={1} justifyContent="flex-end" isPerfPercent={true}>
        <PerfPercent
          isGlobalAllTime={isGlobalAllTime}
          isPositive={performance ? performance >= 0 : false}
        >
          {`${performance} %`}
        </PerfPercent>
      </MetricContainer>
      <MetricContainer flexGrow={1} alignItems="center" gap="5px">
        <MetricContainer flexDirection="row-reverse">
          <TradeNumber isGlobalAllTime={isGlobalAllTime}>{numberOfTrades}</TradeNumber>
        </MetricContainer>

        <TradeIconContainer flexDirection="row-reverse">
          <Icon icon="lightbulb" size={15} />
        </TradeIconContainer>
      </MetricContainer>
    </IdeaPerformanceMetricsContainer>
  )
}

export default IdeaPerformanceMetrics
