// @ts-nocheck
import * as R from "ramda"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useOutlet } from "react-router-dom"
import { fetchAnalyses, fetchFolders } from "../../../state/modules/analysis"
import { Loader } from "../../components"
import { useAuth } from "../../hooks"
import Folders from "./components/Folders"

const List = () => {
  const dispatch = useDispatch()
  const outlet = useOutlet()
  const { profile } = useAuth()

  const fetchingAnalyses = useSelector(R.path(["analysis", "analyses", "fetching"]))
  const fetchingFolders = useSelector(R.path(["analysis", "folders", "fetching"]))

  const fetching = fetchingAnalyses || fetchingFolders || !profile

  useEffect(() => {
    dispatch(fetchAnalyses())
    dispatch(fetchFolders())
  }, [dispatch])

  if (fetching) {
    return <Loader title="Fetching analyses..." />
  }

  return (
    outlet || (
      <div>
        <Folders />
      </div>
    )
  )
}

export default List
