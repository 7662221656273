import { Spinner } from "@blueprintjs/core"
import { Dispatch, SetStateAction } from "react"

import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { DataframeData, IAccountBookPairs, TradingBook } from "../types"
import { NoDataMessage } from "./Orders.styled"
import { SummaryContainer } from "./Summary.styled"
import LiveOrdersSwitch from "./components/LiveOrdersSwitch"
import SummaryGrid from "./components/SummaryGrid"

interface IPositionsProps {
  summary?: DataframeData
  books: TradingBook[]
  isLoading: boolean
  setSelectedFilters: Dispatch<SetStateAction<IAccountBookPairs>>
  selectedFilters: IAccountBookPairs
  setLiveOrders: Dispatch<SetStateAction<boolean>>
  isLiveOrders: boolean
}

const Summary = ({
  summary,
  books,
  isLoading,
  setSelectedFilters,
  selectedFilters,
  isLiveOrders,
  setLiveOrders,
}: IPositionsProps) => (
  <SummaryContainer
    alignItems="center"
    numberOfRows={summary?.rows.length}
    justifyContent="center"
    switchLabelColor={isLiveOrders ? SCIENT_COLORS.green5 : SCIENT_COLORS.orange5}
  >
    {summary ? (
      <SummaryGrid
        summary={summary}
        books={books}
        setSelectedFilters={setSelectedFilters}
        selectedFilters={selectedFilters}
        label={"Summary"}
      >
        <LiveOrdersSwitch setLiveOrders={setLiveOrders} isLiveOrders={isLiveOrders} />
      </SummaryGrid>
    ) : isLoading ? (
      <Spinner />
    ) : (
      <NoDataMessage>No portfolio summary available, please contact tech support</NoDataMessage>
    )}
  </SummaryContainer>
)

export default Summary
