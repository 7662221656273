import { ButtonGroup, Icon } from "@blueprintjs/core"
import { Control, Controller } from "react-hook-form"

import CyclicalyIcon from "../../../../icons/CyclicalyIcon"
import { capitalizeFirstLetter } from "../../../../utils"
import { CountrySelect } from "../components/CountrySelect"
import { CurrencySelect } from "../components/CurrencySelect"
import {
  CyclicalityButton,
  FormateMediumdText,
  RowContainer,
  SelectContainer,
} from "./StockFields.styled"
import { Cyclicality, TradingStock } from "./types"

interface IStockFieldsProps {
  control: Control<TradingStock>
}

const StockFields = ({ control }: IStockFieldsProps) => {
  return (
    <>
      <RowContainer alignItems="center" gap="25px">
        <Icon icon="globe" size={22} />
        <FormateMediumdText>Headquarter Country</FormateMediumdText>
        <SelectContainer>
          <CountrySelect name="headquarter_country" control={control} disabled />
        </SelectContainer>
      </RowContainer>

      <RowContainer alignItems="center" gap="25px">
        <Icon icon="dollar" size={22} />
        <FormateMediumdText>Reporting Currency</FormateMediumdText>
        <SelectContainer>
          <CurrencySelect name="reporting_currency" control={control} disabled />
        </SelectContainer>
      </RowContainer>

      <RowContainer alignItems="center" gap="25px">
        <CyclicalyIcon height="22px" width="22px" />
        <FormateMediumdText>Cyclicality</FormateMediumdText>
        <SelectContainer>
          <Controller
            control={control}
            name="cyclicality"
            render={({ field: { onChange, value }, fieldState: { isDirty } }) => (
              <ButtonGroup minimal fill>
                {Object.values(Cyclicality).map((el: string) => (
                  <CyclicalityButton
                    key={el}
                    text={capitalizeFirstLetter(el)}
                    active={value === el}
                    onClick={() => onChange(el)}
                    small
                    isDirty={isDirty}
                  />
                ))}
              </ButtonGroup>
            )}
          />
        </SelectContainer>
      </RowContainer>
    </>
  )
}

export default StockFields
