// @ts-nocheck
import { Button, Classes, ControlGroup, Dialog, Icon, Tag, Tooltip } from "@blueprintjs/core"
import * as R from "ramda"
import { memo, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"

import sheetsDuck from "../../../../../state/modules/sheets"

import { Col, Loader, Row } from "../../../../components"
import { EditSheet } from "../../Sheet/EditSheet"
import ItemTypes from "./ItemTypes"

const style = {
  border: "1px solid",
  borderColor: "rgba(255, 255, 255, 0.4)",
  borderRadius: "3px 3px",
  padding: "2px",
}

const handleStyle = {
  cursor: "move",
}

const SheetItem = ({ sheetId, findCard, moveCard, monitor, userId, deskOwnerId }) => {
  const [edit, setEdit] = useState(false)
  const toggleEdit = () => setEdit(!edit)

  //For dnd Card
  const originalIndex = findCard(sheetId).index

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, sheetId, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { sheetId: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveCard(droppedId, originalIndex)
      }
    },
  })

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => false,
    hover({ sheetId: draggedId }) {
      if (draggedId !== sheetId) {
        const { index: overIndex } = findCard(sheetId)
        moveCard(draggedId, overIndex)
      }
    },
  })

  const opacity = isDragging ? 0 : 1
  // End dnd Card

  const [sheetToDelete, setSheetToDelete] = useState(false)

  const sheet = useSelector(R.path(["sheets", "list", sheetId]))
  const sheetsReady = useSelector(R.path(["sheets", "ready"]))
  const monitorReady = monitor.ready

  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(sheetsDuck.actions.deleteSheet({ id: sheetId, monitor }))
  }

  if ((!sheetsReady && !monitorReady) || !sheet) {
    return <Loader style={{ position: "relative" }} />
  }

  return (
    <>
      <div ref={preview} style={{ ...style, opacity }} fill="true">
        <Row style={{ margin: "1px 0", textAlign: "center" }}>
          <div ref={node => drag(drop(node))} style={handleStyle}>
            <Icon
              icon="move"
              size={20}
              style={{ margin: "auto", padding: "5px", color: "#AAB6C1" }}
            />
          </div>

          {edit ? (
            <Col xs>
              <EditSheet sheet={sheet} toggleEdit={toggleEdit} dispatch={dispatch} />
            </Col>
          ) : (
            <>
              <Col xs={9}>
                <Tag fill minimal large>
                  {sheet.name}
                </Tag>
              </Col>
              <Col xs={2}>
                <ControlGroup fill>
                  <Tooltip content="Edit name" placement="bottom" openOnTargetFocus={false} compact>
                    <Button minimal icon="edit" onClick={toggleEdit} />
                  </Tooltip>
                  {/* Hide delete button if user is not the desk owner */}
                  {deskOwnerId === userId && (
                    <Tooltip
                      content="Delete sheet"
                      placement="bottom"
                      openOnTargetFocus={false}
                      compact
                    >
                      <Button minimal icon="trash" onClick={() => setSheetToDelete(true)} />
                    </Tooltip>
                  )}
                </ControlGroup>
              </Col>
            </>
          )}
        </Row>
      </div>

      <Dialog
        title="Delete sheet"
        className="bp5-dark"
        icon="delete"
        intent="danger"
        isOpen={sheetToDelete}
        onClose={() => setSheetToDelete(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Are you sure you want to delete sheet: <strong>{sheet.name}</strong>?
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setSheetToDelete(false)} text="Close" />
            <Button onClick={() => handleDelete(sheet.id)} intent="danger" text="Delete" />
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default memo(SheetItem)
