// @ts-nocheck
import React from "react"
import { useSelector } from "react-redux"
import { path } from "ramda"

import { Grid } from "../../../../../components"
import { InputRow, TitleRow, ControlRow, ReadonlyRow } from "./components"
import { createBSRows } from "./BSRows"

const BS = ({ handleChange, values, reportData: { prevReport, prevYearReport } }) => {
  const stockData = useSelector(path(["stockDetail", values.stock, "stockData"]))
  const groupRows = [
    ...createBSRows({ stockData, values, prevReport, prevYearReport, handleChange }),
  ]
  return (
    <Grid fluid>
      {groupRows.map(({ type, props }, index) => {
        if (type === "title") {
          return <TitleRow key={index} {...props} />
        }
        if (type === "control") {
          return <ControlRow key={index} {...props} />
        }
        if (type === "readonly") {
          return <ReadonlyRow key={index} {...props} />
        }
        return <InputRow key={index} {...props} />
      })}
    </Grid>
  )
}
export default BS
