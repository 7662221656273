// @ts-nocheck
import React from "react"
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import * as R from "ramda"
import { ButtonGroup } from "@blueprintjs/core"
import { getTime } from "date-fns"

import { defaultOptions } from "../../../../../_shared/highcharts"
import { formatData } from "../utils"
import { SharedHeader, Display } from "../../components"
import { FreqButtonNGTF } from "../components"
import { useGetSheet, useGetNGTFGrid } from "../../../utils"
import EditModal from "../Table/EditModal"
import { SctSheetHeaderContainer } from "../../../../../../styled/layout.styled"
import { SCIENT_COLORS } from "../../../../../../styled/scientColors"

const Chart = ({ monitor, sheetId, height, editRowItems, setEditRowItems }) => {
  const sheet = useGetSheet(sheetId)
  const { colTitles, rowData } = useGetNGTFGrid(sheetId)

  // Reject lines that are titles. Use index of rowDataWithoutTitle for color line in graph
  const rowDataWithoutTitle = R.compose(
    R.reject(row => !row.extra.avg), // Reject lines that are titles
  )(rowData)

  // Reject line where option chartHideLine is true
  const visibleRowData = R.compose(R.reject(row => row.extra.frontOpts.chartHideLine))(
    rowDataWithoutTitle,
  )

  // get start date of forecast for each serie, and put them in a list
  const startForecastDateList =
    visibleRowData &&
    visibleRowData.map(row => R.compose(R.head(), R.pathOr([], ["extra", "forecasts"]))(row))
  // Clean the list by removing identical date and reject undefined (when serie has no forecast)
  const cleanedStartForecastDateList =
    startForecastDateList &&
    R.compose(
      R.reject(e => e === undefined),
      R.uniq,
    )(startForecastDateList)
  // If there's only one start forecast date, we can display the plotLine, else if there is many start date,
  // plotline is not displayed and value for plotLine will be undefined.
  const startForecastDate =
    cleanedStartForecastDateList.length === 1
      ? /**
         * getTime returns Unix time in ms and Hightcharts required Unix time in ms.
         */
        getTime(new Date(R.find(R.propEq("field", cleanedStartForecastDateList[0]), colTitles).end))
      : undefined

  // Series and options are used by highchart to display graph
  const series =
    !R.isEmpty(visibleRowData) && formatData(colTitles, visibleRowData, rowDataWithoutTitle)

  const options = {
    ...defaultOptions,
    chart: {
      height: height - 82,
    },
    title: { text: sheet.name },
    xAxis: {
      type: "datetime",
      ordinal: false,
      dateTimeLabelFormats: {
        day: "%d-%m-%y",
        month: "%m-%y",
        year: "%y",
      },
      /**
       * plotLines is vertical axis to separate reports from forecasts.
       * It's an array of object, because we can have many plotLines (but here we want only one).
       * Plotline is not diplayed if there is many start forecast date.
       */
      plotLines: [
        {
          color: SCIENT_COLORS.gray4,
          width: 1.5,
          value: startForecastDate,
        },
      ],
    },
    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "top",
      layout: "vertical",
      x: 40,
      y: 0,
      floating: true,
      maxHeight: 250,
    },
    yAxis: R.concat(
      [{ opposite: false }], // main yAxis (to the left)
      visibleRowData.map(row => {
        // ownAxis (used only if yAxis has right index in series)
        const format = row.extra.frontOpts.pct ? "{value}%" : "{value}"

        const indexOfRow = R.findIndex(R.pathEq(["extra", "rowId"], row.extra.rowId))(
          rowDataWithoutTitle,
        )
        const indexForColor = Math.floor(indexOfRow % 10) // Math.floor retrives the 1st digit of indexOfRow.

        const color = row.extra.frontOpts.chartColor
          ? row.extra.frontOpts.chartColor
          : Highcharts.getOptions().colors[indexForColor]

        return {
          labels: {
            format,
            style: { color },
          },
          title: {
            style: { color },
          },
          opposite: true,
        }
      }),
    ),
    series,
  }

  return (
    <>
      <SctSheetHeaderContainer>
        <ButtonGroup>
          <SharedHeader monitor={monitor} sheet={sheet} setEditRowItems={setEditRowItems} />
          <Display sheet={sheet} />
          <ButtonGroup>
            <FreqButtonNGTF sheet={sheet} />
          </ButtonGroup>
        </ButtonGroup>
      </SctSheetHeaderContainer>

      <div
        className="ag-theme-balham-dark"
        style={{
          margin: "0 auto",
          height: "100%",
          width: "100%",
        }}
      >
        <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options} />
        <EditModal editRowItems={editRowItems} setEditRowItems={setEditRowItems} sheet={sheet} />
      </div>
    </>
  )
}

export default Chart
