// @ts-nocheck
import React from "react"
import { Dialog } from "@blueprintjs/core"

import RowContent from "./RowContent"

export const EditRow = ({ editRowItems, setEditRowItems, sheet }) => {
  const { show } = editRowItems
  const handleCancel = () => {
    setEditRowItems({ show: false })
  }
  return (
    <Dialog
      className="bp5-dark"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon="plus"
      title={`Edit ${sheet.name} row items`}
      intent="primary"
      isOpen={show}
      onClose={handleCancel}
      style={{ height: "100%", width: "550px" }}
    >
      <RowContent sheet={sheet} editRowItems={editRowItems} setEditRowItems={setEditRowItems} />
    </Dialog>
  )
}
