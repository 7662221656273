export enum ConfigDisplayTab {
  STOCK = "stock",
}

export enum Cyclicality {
  CYCLICAL = "cyclical",
  NEUTRAL = "neutral",
  DEFENSIVE = "defensive",
  UNKNOWN = "unknown",
}

export enum SectorLevel {
  L1 = 1,
  L2 = 2,
  L3 = 3,
}

export type Exchange = {
  id: number
  name: string
  mic: string
}

export type Currency = {
  id: number
  name: string
  code: string
}

export type Listing = {
  id: number
  instrument_content_type: number
  instrument_id: number
  exchange: Exchange
  bloomberg_code: string
  currency: Currency
  sedol: string
  bb_unique: string
}

export type TradingSector = {
  id: number | null
  name?: string | null
  level: SectorLevel
}

export type Country = {
  id: number
  name: string
  code: string
}

export type TradingStock = {
  id: number
  name: string
  bb_company: string
  headquarter_country: Country | null
  reporting_currency: Currency | null
  cyclicality: Cyclicality
  sectors: TradingSector[]
  listings: Listing[]
  created_at: string
  updated_at: string
}

export type StockUpdatePayload = {
  name: string
  headquarter_country_id: number | null
  reporting_currency_id: number | null
  cyclicality: Cyclicality
  sectors: Partial<TradingSector>[]
}
