import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer, flexContainerCss } from "../../../../styled/flexbox.styled"

export const IdeaCreateContainer = styled(FlexContainer)({
  height: "100%",
  width: "100%",
})

export const LeftContainer = styled(FlexContainer)({
  width: "70%",
  paddingRight: "5px",
})

export const RightContainer = styled.div`
  ${flexContainerCss}
  gap: 10px;
  width: 30%;
  overflow-y: scroll;
`

export const IdeaCreateLabel = styled.p({
  margin: "0",
})

export const ideaCreateFormCss = css({
  height: "100%",
  overflow: "hidden",

  /**
   * @todo Remove menu grid layout for flex
   */
  gridArea: "content",
  padding: "20px 15px 20px 15px",
})

export const IdeaSettingsInnerContainer = styled(FlexContainer)({
  marginTop: "10px",
  padding: "15px",
  gap: "10px",
  border: "1.5px solid #232e37",
  borderRadius: "5px",
})
