import { Dialog } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"

type NoChartContainerProps = {
  hasData: boolean
}

export const QuickChartDialog = styled(Dialog)<NoChartContainerProps>(props => ({
  width: !props.hasData ? "500px" : "80%",
  height: !props.hasData ? "300px" : "",
}))

export const NoChartContainer = styled(FlexContainer)({
  height: "100%",
})
