// @ts-nocheck
import { compose, filter } from "ramda"
import { formatSingleAndStr } from "./computation/formatSingleAndStr"
import computeAverage from "./computation/computeAverage"
import concatForecasts from "./computation/concatForecasts"

export const averageForecast = ({ sortedForecasts, noEntry, stock }) => {
  const activeForecasts = filter(
    forecast => forecast.starred || forecast.inThreshold,
    sortedForecasts,
  )
  const computeAverageForecast = compose(
    computeAverage,
    formatSingleAndStr("average"),
    concatForecasts,
  )(activeForecasts)

  const cleanAverageForecast =
    activeForecasts.length > 0
      ? {
          str_name: `${stock.bloomberg_code}>average`,
          slug: `${stock.slug}-average`,
          company: "Average",
          label: "Base",
          owner: "default",
          rfentries: computeAverageForecast.rfentries,
        }
      : {
          str_name: `${stock.bloomberg_code}>average`,
          slug: `${stock.slug}-average`,
          company: "Average > select at least one forecast",
          label: "Base",
          owner: "default",
          rfentries: noEntry,
        }
  return cleanAverageForecast
}
