import { Icon } from "@blueprintjs/core"
import { LargeText } from "../../../../../styled/text.styled"

interface INoAssociatedBookAccountProps {
  errorIcon?: boolean
  errorText: string
}

/**
 * Component to display a message when no book or no account are associated to a given user.
 * Customise errorText according.
 */

export const NoAssociatedBookAccount = ({
  errorIcon,
  errorText,
}: INoAssociatedBookAccountProps) => {
  return (
    <>
      {errorIcon && <Icon icon="error" size={40} />}
      <LargeText>{errorText}</LargeText>
      <LargeText>
        Please contact an administrator or email the dev team at&nbsp;
        <a href="mailto:dev@scient.io?subject=No book associated">dev@scient.io</a>
      </LargeText>
    </>
  )
}
