// @ts-nocheck
import React from "react"
import { propOr } from "ramda"

import sheetsDuck from "../../../../../state/modules/sheets"
import { Select } from "../../../../components"

export const TimeGranularity = ({ dispatch, sheet }) => {
  const granularity = propOr("ss", "time_granularity", sheet)

  const options = [
    { label: "Smart select", value: "ss" },
    { label: "Raw Select", value: "rs" },
  ]

  const handleSubmit = ({ target: { value } }) => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        time_granularity: value,
      }),
    )
  }

  return (
    <Select
      selectProps={{
        options: options,
        value: granularity,
        onChange: handleSubmit,
      }}
    />
  )
}
