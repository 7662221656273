// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import stockDetailDuck from "../"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchReports, fetchReportsOk, fetchReportsError },
} = stockDetailDuck

const fetchReportsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchReports.type),
    mergeMap(action => {
      const { slug } = action.payload

      const params = [`?stocks=${slug}`]
      const url = `${REACT_APP_API_DOMAIN}/api/v1/reporting/reports/${params.join("&")}`
      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchReportsOk({ slug, reportData: response })),
        catchError(error => {
          if (error.xhr) {
            return of(fetchReportsError({ slug, reportError: error.xhr.response }))
          }
          return of(fetchReportsError({ slug, reportError: JSON.stringify(error) }))
        }),
      )
    }),
  )

export default fetchReportsEpic
