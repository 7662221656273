// @ts-nocheck
// function to capture each element of a TF formula
import { randomid } from "../../../../../../utils"

export const getFormulaItems = formula => {
  const regex = /(\(|\)|[#|@][\w|\['\w|:\]|{\w,-\d+}]+|[-\d.]+|\+|\+|\*\*?|\/|-)/gi
  const formulaList = formula.length > 0 ? formula.match(regex) : []
  const formulaItems = formulaList.map(e => ({ item: e, itemId: randomid() })) // need to assoc each item with uniq id beacuse we can have several identical opérators in a formula
  return formulaItems
}
