// @ts-nocheck
import autodux from "autodux"

const uiDuck = autodux({
  slice: "ui",
  initial: {
    sidebar: true,
    modal: false,
  },
  actions: {
    showSidebar: state => ({ ...state, sidebar: true }),
    hideSidebar: state => ({ ...state, sidebar: false }),
    toggleSidebar: state => ({
      ...state,
      sidebar: !state.sidebar,
    }),
    showModal: state => ({ ...state, modal: true }),
    hideModal: state => ({ ...state, modal: false }),
    toggleModal: state => ({ ...state, modal: !state.modal }),
  },
})

export default uiDuck
