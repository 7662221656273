// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import * as R from "ramda"

import sheetsDuck from "../index"
import monitorsDuck from "../../monitors"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { createSheet, createSheetOk, createSheetError },
} = sheetsDuck

const createSheetEpic = (action$, _, { ajax, auth }) => {
  return action$.pipe(
    ofType(createSheet.type),
    mergeMap(({ payload: { sheet, monitor } }) => {
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/sheets/`,
        method: "POST",
        body: { ...sheet, monitor_id: monitor.id },
        crossDomain: true,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(({ response }) => {
          return [
            createSheetOk(response),
            monitorsDuck.actions.updateMonitor({
              ...monitor,
              sheet_ids: R.append(response.id, monitor.sheet_ids),
              active_sheet_id: response.id,
            }),
          ]
        }),
        catchError(error => {
          return of(createSheetError(error.xhr.response))
        }),
      )
    }),
  )
}

export default createSheetEpic
