// @ts-nocheck
import { Button, Callout, Card, H3, H4, Icon } from "@blueprintjs/core"
import { Formik } from "formik"
import * as R from "ramda"
import React from "react"

import {
  FormikFreqChoice,
  FormikSelect,
  FormikTextInput,
} from "../../../../../components/formik/fields"

import { useDispatch, useSelector } from "react-redux"
import { updateAnalyse } from "../../../../../../state/modules/analysis"
import { Col, Grid, Row } from "../../../../../components"
import { depColor } from "../../../components/colors"
import { cleanSourceForBackend } from "../../../utils"
import Independents from "./Independents"
import MainSettings from "./MainSettings"

const removeNulls = R.compose(
  R.reject(R.ifElse(R.is(String), R.F, R.either(R.isEmpty, R.isNil))),
  R.map(R.when(R.is(Object), v => removeNulls(v))),
)

const formatValuesForBackend = values =>
  R.compose(removeNulls, R.over(R.lensProp("independents"), R.map(cleanSourceForBackend)))(values)

const methodOpts = [
  { label: "Regressor", value: "reg" },
  { label: "Var", value: "var" },
]

const Builder = ({ analyse, handleAddExplained }) => {
  const dispatch = useDispatch()

  const fields = R.compose(
    R.map(R.prop("name")),
    R.filter(R.propEq("editable", true)),
  )(useSelector(R.path(["shared", "fieldList", "analysis", analyse.method])))

  const initialValues = R.compose(
    R.map(val => (val === null ? "" : val)),
    R.pick(["id", "name", "dependent", "independents", "method", ...fields]),
  )(analyse)

  return (
    <div>
      <Formik
        initialValues={initialValues}
        // validationSchema={LoginSchema}
        onSubmit={() => {
          // do nothing
        }}
      >
        {({ values }) => {
          const handleSave = () => {
            const analyse = formatValuesForBackend(values)
            dispatch(updateAnalyse({ analyse }))
          }
          const handleSaveRun = () => {
            const analyse = formatValuesForBackend(values)
            dispatch(updateAnalyse({ analyse: R.assoc("process", true, analyse) }))
          }

          return (
            <Grid>
              <Row>
                <Col xs={12}>
                  <Card>
                    <H3>Analyse settings</H3>
                    <FormikTextInput name="name" labelProps={{ label: "Name" }} />
                    <FormikSelect
                      name="method"
                      labelProps={{ label: "Method" }}
                      options={methodOpts}
                    />
                    <MainSettings method={analyse.method} />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card>
                    <H3>
                      <Icon size={24} color={depColor} icon="symbol-square" />
                      Explained variable settings
                    </H3>
                    <H4>({analyse?.dependent?.name})</H4>
                    {!values.dependent ? (
                      <>
                        <Callout intent="warning">
                          You need to specify an explained variable (Y) to be able to run this
                          analyse.
                        </Callout>
                        <Button
                          large
                          fill
                          onClick={handleAddExplained}
                          text="Add explained variable"
                        />
                      </>
                    ) : (
                      <FormikFreqChoice
                        name="dependent.freq"
                        labelProps={{ label: "Freq" }}
                        removeSingleQuotes
                      />
                    )}
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card>
                    <H3>Explaining variables</H3>
                    <Independents independents={values.independents} method={values.method} />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <Button
                    intent="primary"
                    large
                    fill
                    onClick={handleSave}
                    icon="floppy-disk"
                    text="Save"
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Button
                    intent="success"
                    large
                    fill
                    onClick={handleSaveRun}
                    icon="cog"
                    text="Save & run"
                    disabled={!analyse.dependent}
                  />
                </Col>
              </Row>
            </Grid>
          )
        }}
      </Formik>
    </div>
  )
}

export default Builder
