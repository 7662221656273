import { useCallback, useContext, useEffect } from "react"
import { WebsocketContext } from "../WebsocketProvider"
import { ScientWebsocketEvent } from "./useEvents"

/**
 * Calls handler when a websocket event that matches type is received
 */
export const useWebsocketEvent = <T = ScientWebsocketEvent>({
  handler,
  type,
}: {
  handler: (data: T) => void
  type: string
}) => {
  /**
   * Gets websocket connection from WebsocketContext
   */
  const ws = useContext(WebsocketContext)

  /**
   * Defines the onmessage handler for the websocket
   */
  const messageHandler = useCallback(
    (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data)

        /**
         * If the message received matches the type provided,
         * calls handler
         */
        if (data.type === type) {
          handler(data)
        }
      } catch (e) {
        console.error(e)
      }
    },
    [handler, type],
  )

  /**
   * Adds the onmessage handler on the websocket
   * When a message is received, messageHandler is called
   */
  useEffect(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.addEventListener("message", messageHandler)
    }

    return () => {
      if (ws) {
        ws.removeEventListener("message", messageHandler)
      }
    }
  }, [messageHandler, ws])
}
