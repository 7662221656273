// @ts-nocheck
import * as R from "ramda"

export const getRowDefByIndex = (rowId, rowTree) => {
  if (rowId === -1) {
    return null
  }
  // Identifie number in rowId and put them in a list
  const regex = /(\d+)/g
  const indexList = rowId.match(regex)

  // Add "children" between each element of the indexList
  // p is the accumulator (=previousValue), c is currentValue, i is currentIndex, a is the array on wich we called the reduce method
  const pathToReach = indexList.reduce((p, c, i, a) => {
    return i < a.length - 1 ? p.concat(c, "children") : p.concat(c)
  }, [])

  // result gives the rowDef wanted
  let path = []

  const result = pathToReach.reduce((obj, key) => {
    // Build the path
    path = key === "children" ? path : R.append(obj[key].temp_id, path)
    return obj && obj[key] !== "undefined" ? { ...obj[key], path } : undefined
  }, rowTree)
  return result
}
