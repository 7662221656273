import { Callout } from "@blueprintjs/core"
import { useField, useFormikContext } from "formik"

import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { SourceType } from "../types/business"
import FormikInput from "./FormikInput"
import FormikRadioLabel from "./FormikRadioLabel"
import { IdeaCreateLabel, IdeaSettingsInnerContainer } from "./IdeaCreate.styled"
import { IdeaCreateSourceContainer } from "./IdeaCreateSource.styled"

const IdeaCreateSource = () => {
  /**
   * Choices for the source type of the idea.
   * The value of the enum is the label displayed for
   * the radio button.
   * If we have an input value, an input field will be
   * displayed if the source type is selected.
   */
  const ideaSources = [
    {
      title: "Proprietary Thinking",
      value: SourceType.PROPRIETARY_THINKING,
      withSourceInput: false,
    },
    { title: "Corporate Access", value: SourceType.CORPORATE_ACCESS, withSourceInput: true },
    { title: "Sell Side", value: SourceType.SELL_SIDE, withSourceInput: true },
  ]

  const [sourceType] = useField("source_type")
  const [source] = useField("source")
  const { errors } = useFormikContext<Record<string, string>>()

  return (
    <IdeaCreateSourceContainer flexDirection="column" gap="5px">
      <IdeaCreateLabel>Source</IdeaCreateLabel>
      <IdeaSettingsInnerContainer flexDirection="column">
        {ideaSources.map(({ title, value }) => {
          return (
            <FormikRadioLabel
              gap="7px"
              key={value}
              name={sourceType.name}
              value={value}
              label={title}
            />
          )
        })}
        {ideaSources.find(ideaSource => ideaSource.value === sourceType.value)?.withSourceInput && (
          <FormikInput fieldName="source" />
        )}
        {errors && errors[source.name] && sourceType.value !== "proprietary_thinking" && (
          <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
            {errors[source.name]}
          </Callout>
        )}
      </IdeaSettingsInnerContainer>
      {errors && errors[sourceType.name] && (
        <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
          {`Source is a required field`}
        </Callout>
      )}
    </IdeaCreateSourceContainer>
  )
}

export default IdeaCreateSource
