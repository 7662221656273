// @ts-nocheck
import { format, utcToZonedTime } from "date-fns-tz"
import * as R from "ramda"
import { newFormatNumber } from "../../../../../_shared"

export * from "./addExpanded"
export * from "./classRules"
export * from "./compareObjects"
export * from "./contextMenu"
export * from "./formatData"
export * from "./formatDataForQuickChart"
export * from "./formatHeader"
export * from "./formatPathIntoObject"
export * from "./formatRowTree"
export * from "./getItem"
export * from "./getPrevloopSource"
export * from "./getRowDefByIndex"
export * from "./hooks"
export * from "./removeActiveKeyRecursively"
export * from "./stSrc"
export * from "./updateRowTree"
export * from "./yupSchemas"

export const getMilestones = colTitles => ({
  firstCol: R.tryCatch(R.compose(R.propOr(null, "field"), R.head), R.always(null))(colTitles),
  lastACol: R.tryCatch(
    R.compose(R.propOr(null, "field"), R.findLast(R.propEq("headerClass", "sct-header-a"))),
    R.always(null),
  )(colTitles),
  firstECol: R.tryCatch(
    R.compose(R.propOr(null, "field"), R.find(R.propEq("headerClass", "sct-header-e"))),
    R.always(null),
  )(colTitles),
  lastCol: R.tryCatch(R.compose(R.propOr(null, "field"), R.last), R.always(null))(colTitles),
})

// format rowData for aggrid
export const shapeRowData = rowData => {
  return [
    ...R.map(
      row =>
        R.compose(
          R.assoc("desc", row.extra.header),
          R.mapObjIndexed((el, key, object) => {
            if (key === "extra") return el

            return newFormatNumber(R.propOr("", "value", el), {
              minDigit: R.pathOr(2, ["extra", "frontOpts", "decimals", "min"], row),
              maxDigit: R.pathOr(2, ["extra", "frontOpts", "decimals", "max"], row),
              pct: R.pathOr(false, ["extra", "frontOpts", "pct"], row),
            })
          }),
        )(row),
      rowData,
    ),
  ]
}

// Background color for heatmap
const getBackground = ({ val, max, min, avg, heatmap }) => {
  if (heatmap === "avgGreen") {
    // Find if the biggest is between avg and min or avg and max
    const distanceToAvg = max - avg >= avg - min ? max - avg : avg - min
    const colorDensity = distanceToAvg === 0 ? 0 : (val - avg) / distanceToAvg
    switch (true) {
      case colorDensity >= 0:
        return `rgba(0, 100, 0, ${colorDensity})`
      case colorDensity < 0:
        return `rgba(100, 0, 0, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (avg).")
    }
  }
  if (heatmap === "avgRed") {
    // Find if the biggest is between avg and min or avg and max
    const distanceToAvg = max - avg >= avg - min ? max - avg : avg - min
    const colorDensity = distanceToAvg === 0 ? 0 : (val - avg) / distanceToAvg
    switch (true) {
      case colorDensity >= 0:
        return `rgba(100, 0, 0, ${colorDensity})`
      case colorDensity < 0:
        return `rgba(0, 100, 0, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (avg).")
    }
  }
  if (heatmap === "avgBlue") {
    const distanceToAvg = max - avg >= avg - min ? max - avg : avg - min
    const colorDensity = distanceToAvg === 0 ? 0 : (val - avg) / distanceToAvg
    switch (true) {
      case colorDensity >= 0:
        return `rgba(100, 0, 0, ${colorDensity})`
      case colorDensity < 0:
        return `rgba(0, 0, 100, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (avg).")
    }
  }
  if (heatmap === "zeroGreen") {
    const distanceToZero = Math.abs(max) >= Math.abs(min) ? Math.abs(max) : Math.abs(min)
    const colorDensity = distanceToZero === 0 ? 0 : val / distanceToZero
    switch (true) {
      case val >= 0:
        return `rgba(0, 100, 0, ${colorDensity})`
      case val < 0:
        return `rgba(100, 0, 0, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (zero).")
    }
  }
  if (heatmap === "zeroRed") {
    const distanceToZero = Math.abs(max) >= Math.abs(min) ? Math.abs(max) : Math.abs(min)
    const colorDensity = distanceToZero === 0 ? 0 : val / distanceToZero
    switch (true) {
      case val >= 0:
        return `rgba(100, 0, 0, ${colorDensity})`
      case val < 0:
        return `rgba(0, 100, 0, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (zero).")
    }
  }
  if (heatmap === "zeroBlue") {
    const distanceToZero = Math.abs(max) >= Math.abs(min) ? Math.abs(max) : Math.abs(min)
    const colorDensity = distanceToZero === 0 ? 0 : val / distanceToZero
    switch (true) {
      case val >= 0:
        return `rgba(100, 0, 0, ${colorDensity})`
      case val < 0:
        return `rgba(0, 0, 100, ${Math.abs(colorDensity)})`
      default:
        throw new Error("Problem with coloring (zero).")
    }
  }
}

export const formatCell = params => {
  const {
    value,
    data: {
      extra: {
        avg,
        max,
        min,
        frontOpts: { heatmap },
      },
    },
  } = params
  if (value) {
    const internationalVal = value.replace(/,/g, "")
    const val = Number.isNaN(internationalVal)
      ? null
      : internationalVal.includes("%")
      ? Number(internationalVal.replace("%", "")) / 100
      : Number(internationalVal)
    if (typeof val !== "number") return { textAlign: "right" }
    return {
      textAlign: "right",
      background: getBackground({ val, max, min, avg, heatmap }),
    }
  }
}

export const shapeColTitles = (colTitles, colType) => {
  return R.tryCatch(
    R.map(col =>
      R.compose(
        R.assoc(
          "headerName",
          colType === "date" // col.end is UTC time. We need to keep UTC in order to display the good date.
            ? // If not and we are in the USA for exemple, we get 30/12 instead of 31/12 as end of year.
              format(utcToZonedTime(new Date(col.end), "UTC"), "dd-MM-yy", {
                timeZone: "UTC",
              })
            : col.field,
        ),
        R.assoc("cellStyle", formatCell),
        R.assoc("cellClassRules", {
          "sct-forecast": ({
            colDef: { field },
            data: {
              extra: { forecasts },
            },
          }) => forecasts && forecasts.includes(field),
          "sct-overwritten-periods": ({
            colDef: { field },
            data: {
              extra: { overwritten_periods },
            },
          }) => overwritten_periods && overwritten_periods.includes(field),
        }),
        R.omit(["start", "end"]),
      )(col),
    ),
    R.always([]),
  )(colTitles)
}
