import { Spinner, SpinnerSize } from "@blueprintjs/core"
import { FieldType } from ".././types/business"
import NocChartContent from "./NocChartContent"

import { FlexContainerAndItem } from "../../../../styled/flexbox.styled"

interface IChartComponent {
  isFetching: boolean
  selectedTicker: string
  selectedPeriod: string
  selectedMetric: string
  seriesData: Array<object>
}

const ChartComponent = ({
  isFetching,
  selectedTicker,
  selectedPeriod,
  selectedMetric,
  seriesData,
}: IChartComponent) => {
  return (
    <FlexContainerAndItem flexGrow={1} justifyContent="center">
      {isFetching ? (
        <Spinner intent={"success"} size={SpinnerSize.STANDARD} />
      ) : (
        <NocChartContent
          series={seriesData}
          format={""}
          name={`Estimates: ${selectedTicker} - ${selectedPeriod}`}
          chartType={"line"}
          xLabel={"Time"}
          yLabel={
            [FieldType.PROFIT_MARGIN.valueOf(), FieldType.OSG.valueOf()].includes(selectedMetric)
              ? selectedMetric + " %"
              : selectedMetric
          }
        />
      )}
    </FlexContainerAndItem>
  )
}
export default ChartComponent
