// @ts-nocheck
import { Button, FormGroup, Popover, TextArea } from "@blueprintjs/core"

export const CommentPopover = ({
  field, // { name, value, onChange, onBlur }
  //form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  labelProps = null,
  ...props
}) => (
  <Popover placement="bottom-end">
    <Button intent={field.value ? "primary" : null} tabIndex={-1} icon="comment" />
    <div style={{ width: "300px" }}>
      {labelProps ? (
        <FormGroup {...labelProps}>
          <TextArea type="text" fill {...field} {...props} />
        </FormGroup>
      ) : (
        <TextArea type="text" fill {...field} {...props} />
      )}
      <Button intent="primary" fill className="bp5-popover-dismiss">
        Close
      </Button>
    </div>
  </Popover>
)
