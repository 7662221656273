// @ts-nocheck
import { combineEpics } from "redux-observable"

import verifyTokenEpic from "./verifyTokenEpic"
import logoutEpic from "./logoutEpic"
import fetchGroupListEpic from "./fetchGroupListEpic"
import fetchUserListEpic from "./fetchUserListEpic"

const authEpics = combineEpics(verifyTokenEpic, logoutEpic, fetchUserListEpic, fetchGroupListEpic)

export default authEpics
