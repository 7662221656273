// @ts-nocheck
export const grossProfit = {
  target: ["is_gross_profit"],
  add: [["sales"]],
  sub: [["is_reported_cogs"]],
  method: "sum",
}

export const ebitda = {
  target: ["is_ebitda"],
  add: [
    ["sales"],
    ["is_other_income_expense"],
    ["is_non_operating_income_above"],
    ["is_share_of_profit_from_associates_above"],
    ["is_depreciation"],
    ["is_amortization"],
  ],
  sub: [["is_reported_cogs"], ["is_s_g_and_a"], ["is_expensed_r_and_d"], ["is_d_a_reported_in_is"]],
  method: "sum",
}

export const ebita = {
  target: ["is_ebita"],
  add: [
    ["sales"],
    ["is_other_income_expense"],
    ["is_non_operating_income_above"],
    ["is_share_of_profit_from_associates_above"],
    ["is_amortization"],
  ],
  sub: [["is_s_g_and_a"], ["is_expensed_r_and_d"], ["is_reported_cogs"], ["is_d_a_reported_in_is"]],
  method: "sum",
}

export const ebitReported = {
  target: ["is_ebit_reported_readonly"],
  add: [
    ["sales"],
    ["is_other_income_expense"],
    ["is_non_operating_income_above"],
    ["is_share_of_profit_from_associates_above"],
  ],
  sub: [["is_reported_cogs"], ["is_s_g_and_a"], ["is_expensed_r_and_d"], ["is_d_a_reported_in_is"]],
  method: "sum",
  override: ["is_ebit_reported_override"],
}

export const profitBeforeTax = {
  target: ["is_profit_before_tax_readonly"],
  add: [
    ["sales"],
    ["is_other_income_expense"],
    ["is_non_operating_income_above"],
    ["is_share_of_profit_from_associates_above"],

    ["is_financial_income"],
    ["is_non_operating_income_below"],
    ["is_share_of_profit_from_associates_below"],
  ],
  sub: [["is_reported_cogs"], ["is_s_g_and_a"], ["is_expensed_r_and_d"], ["is_d_a_reported_in_is"]],
  method: "sum",
}

export const netProfitFromContinuingOperations = {
  target: ["is_net_profit_from_continuing_operations"],
  add: [
    ["sales"],
    ["is_other_income_expense"],
    ["is_non_operating_income_above"],
    ["is_share_of_profit_from_associates_above"],

    ["is_financial_income"],
    ["is_non_operating_income_below"],
    ["is_share_of_profit_from_associates_below"],
  ],
  sub: [
    ["is_reported_cogs"],
    ["is_s_g_and_a"],
    ["is_expensed_r_and_d"],
    ["is_d_a_reported_in_is"],

    ["is_tax_expense"],
    ["is_minority_interest"],
  ],
  method: "sum",
}

export const netProfitForThePeriod = {
  target: ["is_net_profit_for_the_period"],
  add: [
    ["sales"],
    ["is_other_income_expense"],
    ["is_non_operating_income_above"],
    ["is_share_of_profit_from_associates_above"],

    ["is_financial_income"],
    ["is_non_operating_income_below"],
    ["is_share_of_profit_from_associates_below"],

    ["is_net_profit_from_discontinued_operations"],
  ],
  sub: [
    ["is_reported_cogs"],
    ["is_s_g_and_a"],
    ["is_expensed_r_and_d"],
    ["is_d_a_reported_in_is"],

    ["is_tax_expense"],
    ["is_minority_interest"],
  ],
  method: "sum",
}
