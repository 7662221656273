import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const RisksContainer = styled(FlexContainer)({
  gridArea: "content",
  height: "100%",
  width: "100%",
  overflow: "hidden",
})

export const dividerCss = css({
  margin: "2px 0",
})
