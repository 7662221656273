// @ts-nocheck
import { path } from "ramda"
import React from "react"
import { useSelector } from "react-redux"

import { Grid } from "../../../../../components"
import { ControlRow, InputRow, ReadonlyRow, TitleRow } from "./components"
import { createISRows } from "./ISRows"

const IS = ({ values, handleChange, reportData: { prevReport, prevYearReport, stockParams } }) => {
  const stockData = useSelector(path(["stockDetail", values.stock, "stockData"]))
  const groupRows = [
    ...createISRows({ stockData, values, prevReport, prevYearReport, handleChange, stockParams }),
  ]
  return (
    <Grid fluid>
      {groupRows.map(({ type, props }, index) => {
        if (type === "title") {
          return <TitleRow key={index} {...props} />
        }
        if (type === "control") {
          return <ControlRow key={index} {...props} />
        }
        if (type === "readonly") {
          return <ReadonlyRow key={index} {...props} />
        }
        return <InputRow key={index} {...props} />
      })}
    </Grid>
  )
}

export default IS
