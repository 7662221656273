// @ts-nocheck
export const orders = {
  id: "orders",
  title: "Orders ",
  rowDefs: [
    {
      type: "title1",
      text: "Sales",
      styles: { focus: "1" },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "sales"],
          type: "data",
          text: "#{name}",
        },
      ],
    },
    {
      path: ["sales"],
      type: "data",
      text: "Group Sales",
      styles: { focus: "1" },
    },
    {
      path: ["sales_growth_readonly"],
      type: "data",
      text: "Group Sales Growth",
      pct: true,
      styles: { focus: "1", italic: true },
    },
    {
      path: ["sales_organic_growth"],
      type: "data",
      text: "Group Sales Organic Growth",
      pct: true,
      styles: { focus: "1", italic: true },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "sales_organic_growth"],
          type: "data",
          text: "#{name}",
          pct: true,
          styles: { italic: true },
        },
      ],
    },
    {
      type: "title1",
      text: "Orders",
      styles: { focus: "1" },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "orders"],
          type: "data",
          text: "#{name}",
        },
      ],
    },
    {
      path: ["orders"],
      type: "data",
      text: "Group Orders",
      styles: { focus: "1" },
    },
    {
      path: ["orders_growth_readonly"],
      type: "data",
      text: "Group Orders Growth",
      pct: true,
      styles: { focus: "1", italic: true },
    },
    {
      path: ["orders_organic_growth"],
      type: "data",
      text: "Group Orders Organic Growth",
      pct: true,
      styles: { focus: "1", italic: true },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "orders_organic_growth"],
          type: "data",
          text: "#{name}",
          pct: true,
          styles: { italic: true },
        },
      ],
    },
    {
      type: "title1",
      text: "Book to Bill",
      styles: { focus: "1" },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "book_to_bill_readonly"],
          type: "data",
          text: "#{name}",
          decimals: { min: 2, max: 2 },
        },
      ],
    },
    {
      path: ["book_to_bill_readonly"],
      type: "data",
      text: "Group Book to Bill",
      decimals: { min: 2, max: 2 },
      styles: { focus: "1" },
    },
    {
      type: "title1",
      text: "Backlog",
      styles: { focus: "1" },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "backlog"],
          type: "data",
          text: "#{name}",
        },
      ],
    },
    {
      path: ["backlog"],
      type: "data",
      text: "Group Backlog",
      styles: { focus: "1" },
    },
  ],
}
