// @ts-nocheck
import { Button, ButtonGroup, Card, H4 } from "@blueprintjs/core"
import * as R from "ramda"
import React, { useState } from "react"
// if react-sortable-tree do not work with other dnd try :
// import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree'
// See https://unpkg.com/browse/pearson-react-sortable-tree@0.0.4/README.md
// or https://github.com/frontend-collective/react-sortable-tree/issues/788
// or https://github.com/react-dnd/react-dnd/issues/186
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
  toggleExpandedForAll,
} from "@nosferatu500/react-sortable-tree"
import FileExplorerTheme from "@nosferatu500/theme-file-explorer"
import { shallowEqual } from "recompose"

import { randomid } from "../../../../../../../../utils"
import { getItemByTempId, getRowDefByIndex } from "../../../utils"
import FormulaBuilder from "../FormulaBuilder"
import { initialFormulaTreeData } from "../FormulaBuilder/utils"
import RowNodeDetail from "../RowNodeDetail"
import RowTreeButtons from "./Buttons"
import RowTreeItem from "./Item"
import {
  RowTreeButtonsContainer,
  RowTreeContainer,
  RowTreeOptionsContainer,
} from "./RowTree.styled"

const RowTree = ({
  st_sources,
  rf_sources,
  rowTree,
  setRowTree,
  handelSubmit,
  setOpenCancelDialog,
  sourceIds,
  setSourceIds,
  setEditRowItems,
  rowIdToEdit,
}) => {
  const [openFormulaBuilder, setOpenFormulaBuilder] = useState(false)

  const rowDefToEdit = rowIdToEdit && getRowDefByIndex(rowIdToEdit, rowTree)

  const [activeItem, setActiveItem] = useState(rowDefToEdit ? rowDefToEdit : null)

  const [changedActiveItem, setChangedActiveItem] = useState(rowDefToEdit ? rowDefToEdit : null)
  const changeInActiveItem = !shallowEqual(activeItem, changedActiveItem)

  const [groupNotComplete, setGroupNotComplete] = useState(false)

  const updateTreeData = treeData => {
    setRowTree(treeData)
  }

  const expandAndCollapse = expanded => {
    setRowTree(
      toggleExpandedForAll({
        treeData: rowTree,
        expanded,
      }),
    )
  }

  const selectNode = (node, path) => {
    setActiveItem({ ...node, path })
    setChangedActiveItem({ ...node, path })
  }

  const insertNewSingle = ({ type }) => {
    const temp_id = type + "|" + randomid()
    const path = [temp_id]
    const newNode = {
      temp_id,
      header: "",
      kind: "single",
      frontOpts: type === "data" ? { decimals: { max: 2, min: 2 } } : {},
      formula_tree: type === "data" ? initialFormulaTreeData : {},
      isHeader: type === "header" ? true : false,
    }
    setRowTree(
      () =>
        insertNode({
          treeData: rowTree,
          depth: 0,
          minimumTreeIndex: rowTree.length + 500, // add 500 to to have new node at the end of tree when tree is expanded.
          newNode,
          getNodeKey: ({ node }) => node.temp_id,
        }).treeData,
    )
    selectNode(newNode, path)
  }

  const insertNewGroup = () => {
    const temp_id = "newGroup" + "|" + randomid()
    const path = [temp_id]
    const newNode = {
      temp_id,
      children: [],
      kind: "group",
      loop_on: "",
      name: "",
    }
    setRowTree(
      () =>
        insertNode({
          treeData: rowTree,
          depth: 0,
          minimumTreeIndex: rowTree.length + 500, // add 500 to to have new node at the end of tree when tree is expanded.
          newNode,
          getNodeKey: ({ node }) => node.temp_id,
          // selectNode: (node, path),
        }).treeData,
    )
    setGroupNotComplete(true)
    selectNode(newNode, path)
  }

  // https://github.com/frontend-collective/react-sortable-tree/blob/9ee19b7f07a338935a565e502e8a838d6b79bef7/src/utils/tree-data-utils.js#L497-L511

  const addSingleUnderParent = (node, path, { type }) => {
    const temp_id = type + "|" + randomid()
    const newPath = [...path, temp_id] // path from parent +  temp_id of the new Single
    const newNode = {
      temp_id,
      header: "",
      kind: "single",
      frontOpts: type === "data" ? { decimals: { max: 2, min: 2 } } : {},
      formula_tree: type === "data" ? initialFormulaTreeData : {},
      isHeader: type === "header" ? true : false,
    }
    setRowTree(
      values =>
        addNodeUnderParent({
          treeData: rowTree,
          parentKey: path[path.length - 1],
          expandParent: true,
          getNodeKey: ({ node }) => node.temp_id,
          newNode,
          addAsFirstChild: values.addAsFirstChild,
        }).treeData,
    )
    selectNode(newNode, newPath)
  }

  const addGroupUnderParent = (node, path) => {
    const temp_id = "newGroup" + "|" + randomid()
    const newPath = [...path, temp_id] // path from parent +  temp_id of the new Single
    const newNode = {
      temp_id: "newGroup" + "|" + randomid(),
      children: [],
      kind: "group",
      loop_on: "",
      name: "",
    }
    setRowTree(
      values =>
        addNodeUnderParent({
          treeData: rowTree,
          parentKey: path[path.length - 1],
          expandParent: true,
          getNodeKey: ({ node }) => node.temp_id,
          newNode,
          addAsFirstChild: values.addAsFirstChild,
        }).treeData,
    )
    setGroupNotComplete(true)
    selectNode(newNode, newPath)
  }

  const removeNode = path => {
    setChangedActiveItem("")
    setRowTree(() =>
      removeNodeAtPath({
        treeData: rowTree,
        path: path,
        getNodeKey: ({ node }) => node.temp_id,
      }),
    )
    setGroupNotComplete(false)
  }

  const handleChartHideLine = (node, path) => {
    const updatedNode = R.over(
      R.lens(R.prop("frontOpts"), R.assoc("frontOpts")),
      R.assoc("chartHideLine", !node.frontOpts.chartHideLine), // chartHideLine are true or false
      node,
    )
    setRowTree(() =>
      changeNodeAtPath({
        treeData: rowTree,
        path,
        newNode: updatedNode,
        getNodeKey: ({ node }) => node.temp_id,
        ignoreCollapsed: true,
      }),
    )
  }

  //TODO ? : add search component

  // To work, formulaBuilder must be opened outside SortableTree
  if (openFormulaBuilder) {
    return (
      <FormulaBuilder
        activeItem={changedActiveItem}
        setActiveItem={setChangedActiveItem}
        setOpenFormulaBuilder={setOpenFormulaBuilder}
        st_sources={st_sources}
        rf_sources={rf_sources}
      />
    )
  }

  return (
    <RowTreeContainer>
      <RowTreeButtonsContainer flexDirection="column">
        <RowTreeButtons
          expandAndCollapse={expandAndCollapse}
          insertNewSingle={insertNewSingle}
          insertNewGroup={insertNewGroup}
          groupNotComplete={groupNotComplete}
        />
        <SortableTree
          treeData={rowTree}
          onChange={updateTreeData}
          getNodeKey={({ node }) => node.temp_id}
          canNodeHaveChildren={node => node.kind === "group"}
          canDrag={!groupNotComplete}
          canDrop={({ node, nextParent, prevPath }) => {
            const prevParentId = prevPath[prevPath.length - 2]
            const prevParent = prevParentId && getItemByTempId(rowTree, prevParentId)

            const nextParentId = nextParent ? nextParent.temp_id : undefined //  case where moving item to root is traited by undefined
            return (
              prevParentId === nextParentId || // allow move at same level
              ((node.temp_id.includes("newGroup") || node.temp_id.includes("group")) && // allow  moving newGroup or no looping Group
                nextParentId &&
                nextParentId.includes("group")) || // into no looping group
              (node.kind === "single" &&
                (R.isEmpty(node.header) || node.header === "[]") && // allow moving newHeader or newData
                (R.isEmpty(node.formula_tree) || node.formula_tree.nodes.length === 1) &&
                (!prevParent || // only if no prevParent
                  (prevParentId && // Or if prevParent
                    ((prevParentId.includes("group") && prevParent.children.length > 1) || // only if prevParent is a no looping or looping group and has at leat 2 children
                      (prevParentId.includes("fields") && prevParent.children.length > 1) ||
                      (prevParentId.includes("splits") && prevParent.children.length > 1) ||
                      (prevParentId.includes("segs") && prevParent.children.length > 1)))))
            )
          }}
          theme={FileExplorerTheme}
          maxDepth={5}
          generateNodeProps={({ node, path }) => {
            return {
              title: (
                <RowTreeItem
                  index={node.temp_id}
                  rowTree={rowTree}
                  node={node}
                  path={path}
                  activeItem={changedActiveItem}
                  st_sources={st_sources}
                  rf_sources={rf_sources}
                  selectNode={selectNode}
                  removeNode={removeNode}
                  addSingleUnderParent={addSingleUnderParent}
                  addGroupUnderParent={addGroupUnderParent}
                  changeInActiveItem={changeInActiveItem}
                  groupNotComplete={groupNotComplete}
                  setEditRowItems={setEditRowItems}
                  handleChartHideLine={handleChartHideLine}
                />
              ),
            }
          }}
        />
        <ButtonGroup fill large>
          <Button icon="undo" onClick={() => setOpenCancelDialog(true)} text="Discard and exit" />
          <Button
            type="submit"
            intent="primary"
            icon="floppy-disk"
            text="Save and exit"
            onClick={handelSubmit}
            disabled={groupNotComplete}
          />
        </ButtonGroup>
      </RowTreeButtonsContainer>
      <RowTreeOptionsContainer flexGrow={1}>
        <Card
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          {activeItem ? (
            <RowNodeDetail
              activeItem={changedActiveItem}
              setActiveItem={setChangedActiveItem}
              setOpenFormulaBuilder={setOpenFormulaBuilder}
              st_sources={st_sources}
              rf_sources={rf_sources}
              sourceIds={sourceIds}
              setSourceIds={setSourceIds}
              rowTree={rowTree}
              setRowTree={setRowTree}
              selectNode={selectNode}
              setGroupNotComplete={setGroupNotComplete}
            />
          ) : (
            <H4 style={{ margin: "auto" }}>Select a node on the left tree</H4>
          )}
        </Card>
      </RowTreeOptionsContainer>
    </RowTreeContainer>
  )
}

export default RowTree
