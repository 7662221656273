// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit"

import * as analyses from "./reducers/analyses"
import * as analyse from "./reducers/analyse"
import * as folders from "./reducers/folders"

export const initialState = {
  analyses: {
    fetching: true,
    list: [],
  },
  folders: {
    fetching: true,
    list: [],
  },
}

const analyseSlice = createSlice({
  name: "analyses",
  initialState,
  reducers: {
    ...analyses,
    ...analyse,
    ...folders,
  },
})

export const {
  createAnalyse,
  createAnalyseOk,
  createAnalyseError,

  deleteAnalyse,
  deleteAnalyseOk,
  deleteAnalyseError,

  fetchAnalyse,
  fetchAnalyseOk,
  fetchAnalyseError,

  updateAnalyse,
  updateAnalyseOk,
  updateAnalyseError,

  fetchAnalyses,
  fetchAnalysesOk,
  fetchAnalysesError,

  createFolder,
  createFolderOk,
  createFolderError,

  deleteFolder,
  deleteFolderOk,
  deleteFolderError,

  fetchFolders,
  fetchFoldersOk,
  fetchFoldersError,

  updateFolder,
  updateFolderOk,
  updateFolderError,

  updateFolders,
  updateFoldersOk,
  updateFoldersError,
} = analyseSlice.actions

export default analyseSlice.reducer
