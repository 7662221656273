import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {
  flexContainerCss,
  FlexContainerProps,
  flexItemCss,
  FlexItemProps,
} from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"
import { MediumText } from "../../../styled/text.styled"

type ItemContainerType = FlexItemProps & FlexContainerProps

export const MetricsContainer = styled.div<ItemContainerType>`
  margin-bottom: 2px;
  overflow: hidden;
  ${flexContainerCss}
  ${flexItemCss}
`

export const PeriodContainer = styled.div<ItemContainerType>`
  min-width: 120px;
  overflow: hidden;
  ${flexContainerCss}
  ${flexItemCss}
`

export const PeriodItem = styled(MediumText)({
  marginLeft: "8px",
  color: SCIENT_COLORS.blue5,
})

export const timeIconCss = (hasChildren: boolean | undefined) =>
  css({
    marginLeft: hasChildren ? 0 : "24px",
  })
export const blankIconCss = css({
  marginRight: "40px",
})
