/** @jsxImportSource @emotion/react */

import { Callout, InputGroup } from "@blueprintjs/core"
import { FieldInputProps, useFormikContext } from "formik"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { calloutLoginCss, loginInputCss } from "./LoginTextInput.styled"

interface ITextInput {
  field: FieldInputProps<string>
  type?: string
  placeholder: string
}

const TextInput = ({ field, type, placeholder }: ITextInput) => {
  const { touched, errors, handleChange } = useFormikContext<Record<string, string>>()

  return (
    <FlexContainer flexDirection="column">
      <InputGroup
        type={type}
        {...field}
        placeholder={placeholder}
        onChange={handleChange}
        css={loginInputCss}
      />
      {touched[field.name] && errors[field.name] && (
        <Callout intent="primary" css={calloutLoginCss}>
          {errors[field.name]}
        </Callout>
      )}
    </FlexContainer>
  )
}

export default TextInput
