// @ts-nocheck
import * as R from "ramda"

import { capitalizeFirstLetter } from "../../../../../../../../../utils/index"

/**
 * When a param of node has object choices not empty,
 * the value of each choice is located in the key name of each choice.
 *  ex: 
            choices = {
                        basic: "Description of basic method",
                        advance: "Description of advance method",
                      }
 * 
 
 * This function create an array of object with: 
 *      _ the value of each choice,
 *      _ the label which is composed by the value with first letter in upperCase
 *        follow up by the description of the choice in parenthesis. 
 */

export const getParamChoices = choices => {
  return R.compose(
    R.map(e => {
      const value = e[0]
      return {
        value,
        label: `${capitalizeFirstLetter(value)} (${e[1]})`,
      }
    }),
    R.toPairs(),
  )(choices)
}
