// @ts-nocheck
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useDrop } from "react-dnd"
import { path, prop } from "ramda"

import profileDuck from "../../../../../../state/modules/profile"
import ItemTypes from "./itemTypes"
import TabItem from "./TabItem"
import update from "immutability-helper"
import { getAllTabs } from "../../../utils"

const style = {
  display: "flex",
}

const TabContainer = ({ stockSlug, activeTab, handleChange }) => {
  const dispatch = useDispatch()

  const profile = useSelector(prop("profile"))
  const availableTabs = path(["userstockconfigs", stockSlug, "availableTabs"], profile)

  const allTabs = getAllTabs(stockSlug, profile)
  const tabs = availableTabs.map(slug => ({ id: slug, title: allTabs[slug].title }))

  const findTab = id => {
    const tab = tabs.filter(c => c.id === id)[0]
    return {
      tab,
      index: tabs.indexOf(tab),
    }
  }

  const moveTab = (id, atIndex) => {
    const { tab, index } = findTab(id)
    const newTabs = update(availableTabs, {
      $splice: [
        [index, 1],
        [atIndex, 0, tab.id], // move tab from 1 to 0
      ],
    })

    return dispatch(
      profileDuck.actions.stockSetAvailableTabs({ slug: stockSlug, availableTabs: newTabs }),
    )
  }
  const [, drop] = useDrop({ accept: ItemTypes.TAB })

  return (
    <div ref={drop} style={style}>
      {tabs.map(tab => (
        <TabItem
          key={tab.id}
          id={tab.id}
          text={tab.title}
          moveTab={moveTab}
          findTab={findTab}
          tab={tab}
          handleChange={handleChange}
          activeTab={activeTab}
        />
      ))}
    </div>
  )
}
export default TabContainer
