// @ts-nocheck
import React from "react"
import { Field } from "formik"
import { ControlGroup, Tag, H6 } from "@blueprintjs/core"

import { getDeepNestedValue, convertPath } from "../../../../../../../utils"
import { CommentPopover } from "../../../../../../components/formik"
import { Row, Col } from "../../../../../../components"
import { Prev } from "./Prev"

export const ReadonlyRow = props => {
  const {
    title,
    basePath,
    commentPath = null,
    values,
    prevReport,
    prevYearReport,
    handleChange,
    hideIfNoPrevValues = false,
  } = props
  const fieldR = convertPath(basePath, values, { flatten: true })

  const valueR = getDeepNestedValue(basePath, values)
  const valuePR = getDeepNestedValue(basePath, prevReport)
  const valuePYR = getDeepNestedValue(basePath, prevYearReport)

  if (hideIfNoPrevValues && !valuePYR && !valuePR) {
    return null
  }
  if (commentPath) {
    const commentPR = getDeepNestedValue(commentPath, prevReport)
    const commentPYR = getDeepNestedValue(commentPath, prevYearReport)
    return (
      <Row>
        <Col xsOffset={1} xs={3}>
          <H6>{title}</H6>
        </Col>
        <Col xs={2}>
          <Prev value={valuePYR} commentValue={commentPYR} />
        </Col>
        <Col xs={2}>
          <Prev value={valuePR} commentValue={commentPR} />
        </Col>
        <Col xs={3}>
          <ControlGroup>
            <Tag large fill minimal intent="primary" style={{ textAlign: "right" }}>
              {valueR}
            </Tag>
            <Field
              name={`${fieldR}_comment`}
              key={`${fieldR}_comment`}
              component={CommentPopover}
              onChange={handleChange}
              autoResize={false}
              cols={100}
              rows={10}
              style={{ resize: "none" }}
            />
          </ControlGroup>
        </Col>
      </Row>
    )
  }
  return (
    <Row>
      <Col xsOffset={1} xs={3}>
        <H6>{title}</H6>
      </Col>
      <Col xs={2}>
        <Tag large fill minimal style={{ textAlign: "right" }}>
          {valuePYR}
        </Tag>
      </Col>
      <Col xs={2}>
        <Tag large fill minimal style={{ textAlign: "right" }}>
          {valuePR}
        </Tag>
      </Col>
      <Col xs={3}>
        <Tag large fill minimal intent="primary" style={{ textAlign: "right" }}>
          {valueR}
        </Tag>
      </Col>
    </Row>
  )
}
