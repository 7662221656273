// @ts-nocheck
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, NonIdealState } from "@blueprintjs/core"
import generate from "project-name-generator"
import { pathOr } from "ramda"

import monitorsDuck from "../../../../state/modules/monitors"
import { Loader } from "../../../components"

const NoMonitor = ({ desk }) => {
  const monitorsReady = useSelector(pathOr(null, ["monitors", "ready"]))

  const dispatch = useDispatch()

  const handleAddMonitor = () => {
    dispatch(monitorsDuck.actions.createMonitor({ name: generate({ number: true }).dashed, desk }))
  }

  if (!monitorsReady) {
    return <Loader />
  }

  return (
    <NonIdealState
      icon={"search"}
      title={`You don't have any monitor in this desk.`}
      action={<Button icon="plus" text="Add Monitor" onClick={handleAddMonitor} intent="primary" />}
    />
  )
}

export default NoMonitor
