import styled from "@emotion/styled"
import { ShadowContainer } from "../../../../styled/layout.styled"

type CursorWrapperProps = {
  isTradeOpen: boolean
}

export const CursorWrapper = styled.div<CursorWrapperProps>(props => ({
  cursor: props.isTradeOpen ? "not-allowed" : "auto",
}))

export const IdeaCreateTypeContainer = styled(ShadowContainer)({
  padding: "10px",
})
