import { useQuery } from "@tanstack/react-query"
import { useApi } from "./useApi"

/**
 * React query hook to GET ideas stat
 */
export const useQueryIdeasStats = () => {
  const { scientApi, apiAuthenticated, api2FAVerified } = useApi()
  return useQuery(["ideasStats"], () => scientApi.ideas.getIdeasStats(), {
    staleTime: Infinity,
    enabled: apiAuthenticated && api2FAVerified,
  })
}
