// @ts-nocheck
import { useState } from "react"
import { useDispatch } from "react-redux"

import {
  Button,
  Card,
  Classes,
  Collapse,
  FormGroup,
  H4,
  Icon,
  Label,
  Popover,
  Tag,
  Tooltip,
} from "@blueprintjs/core"
import generate from "project-name-generator"
import { assoc, compose, omit } from "ramda"

import sheetsDuck from "../../../../../../state/modules/sheets"
import { FlexContainer } from "../../../../../../styled/flexbox.styled"
import { InputGroup } from "../../../../../components"
import { useAuth } from "../../../../../hooks/"
import { useGetWritePermission } from "../../../utils"
import { DeskSuggest } from "./DeskSuggest"
import { MonitorSuggest } from "./MonitorSuggest"

export const CloneSheet = ({ desk, monitor, sheet }) => {
  const { profile } = useAuth()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const tooglePopover = () => setIsPopoverOpen(!isPopoverOpen)

  const [openElsewhere, setOpenElsewhere] = useState(false)
  const toogleElsewhere = () => setOpenElsewhere(!openElsewhere)

  const [name, setName] = useState(generate({ number: true }).dashed)

  const [deskCible, setDeskCible] = useState("")
  const [monitorCible, setMonitorCible] = useState("")

  const sheetPayload = compose(
    assoc("monitor_id", deskCible ? monitorCible.id : monitor.id),
    assoc("name", name),
    assoc("pause_processing", true), // need to pause the clone sheet in order to lauch the processing output
    assoc("tf_freq", "M"), // unused by TF and MSC, only by NGTF, but requested by back end for all kind
    assoc("st_sources", []), // unused by TF and MSC, only by NGTF, but requested by back end for all kind
    omit(["id", "ready"]),
  )(sheet)

  const handleText = ({ target: { value } }) => setName(value)

  const dispatch = useDispatch()

  const handleClickCloneElsewhere = () => {
    setMonitorCible("")
    setDeskCible("")
    setOpenElsewhere(false)
  }

  const handleClickPopover = () => {
    tooglePopover()
    setMonitorCible("")
    setDeskCible("")
    setOpenElsewhere(false)
  }

  const handleSubmit = () => {
    dispatch(sheetsDuck.actions.createSheet({ sheet: sheetPayload, monitor }))
    setName(generate({ number: true }).dashed)
  }

  const handleSubmitElsewhere = () => {
    dispatch(sheetsDuck.actions.createSheet({ sheet: sheetPayload, monitor: monitorCible }))
    setMonitorCible("")
    setDeskCible("")
    setName(generate({ number: true }).dashed)
    tooglePopover()
    toogleElsewhere()
  }

  const onReset = () => {
    setDeskCible("")
    setMonitorCible("")
  }

  // Variables for permissions
  const userId = profile?.id
  const deskOwnerId = desk.owner_id
  const hasWritePermission = useGetWritePermission(desk)
  // end Variables for permissions

  return (
    <Popover
      isOpen={isPopoverOpen}
      content={
        <div style={{ width: "250px" }}>
          <FlexContainer justifyContent="space-between">
            <H4>Clone sheet</H4>
          </FlexContainer>
          <Label>
            Name
            <InputGroup
              inputProps={{
                placeholder: "New name...",
                value: name,
                onChange: handleText,
              }}
            />
          </Label>
          {(userId === deskOwnerId || hasWritePermission) && openElsewhere === false && (
            <Button
              text="Clone in active desk/monitor"
              onClick={handleSubmit}
              style={{ marginBottom: "12px" }}
              intent="primary"
              fill
            />
          )}
          <Button
            text="Clone elsewhere"
            onClick={openElsewhere ? handleClickCloneElsewhere : toogleElsewhere}
            icon={openElsewhere ? "caret-down" : "caret-right"}
            fill
            rightIcon={
              <Tooltip
                content="Cloning is allow in your own desks or in shared desks with write permission."
                placement="bottom"
                openOnTargetFocus={false}
                compact
              >
                <Icon icon="info-sign" style={{ marginLeft: "10px" }} />
              </Tooltip>
            }
          />

          <Collapse isOpen={openElsewhere}>
            <Card>
              {deskCible ? (
                <FormGroup label="Desk Cible">
                  <Tag minimal icon="desktop" onRemove={onReset}>
                    {deskCible.name}
                  </Tag>
                </FormGroup>
              ) : (
                <DeskSuggest deskCible={deskCible} setDeskCible={setDeskCible} />
              )}
            </Card>
            <Collapse isOpen={deskCible}>
              <Card>
                <Label>
                  Monitor cible
                  <MonitorSuggest
                    deskCible={deskCible}
                    monitorCible={monitorCible}
                    setMonitorCible={setMonitorCible}
                  />
                </Label>
              </Card>
            </Collapse>
            {monitorCible && (
              <Button fill intent="primary" text="Clone" onClick={handleSubmitElsewhere} />
            )}
          </Collapse>
        </div>
      }
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      placement="bottom-end"
    >
      <Tooltip content="Clone sheet" placement="bottom" openOnTargetFocus={false} compact>
        <Button icon="duplicate" active={isPopoverOpen} onClick={handleClickPopover} />
      </Tooltip>
    </Popover>
  )
}
