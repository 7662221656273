import { css, SerializedStyles } from "@emotion/react"
import styled from "@emotion/styled"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_AGGRID_COLORS, SCIENT_COLORS } from "../../../../styled/scientColors"
import { MediumText, SmallText } from "../../../../styled/text.styled"

export const inputCss = (index: number): SerializedStyles =>
  css({
    width: "100%",
    height: "100%",
    padding: "0 10px",
    border: "none",
    borderRadius: "0",
    backgroundColor:
      index % 2 === 0 //to have different one row out of two
        ? SCIENT_AGGRID_COLORS.defaultRowBackground
        : SCIENT_AGGRID_COLORS.altRowBackground,
  })

export const bp5InputCss = (index: number): SerializedStyles =>
  css({
    ".bp5-input": {
      boxShadow: "none",
      borderRadius: "0",
      backgroundColor:
        index % 2 === 0 //to have different one row out of two
          ? SCIENT_AGGRID_COLORS.defaultRowBackground
          : SCIENT_AGGRID_COLORS.altRowBackground,
    },
  })

export const InputContainer = styled(FlexContainer)({
  height: "100%",
})

export const BookText = styled(MediumText)({
  padding: "6px 0 0 4px",
  color: SCIENT_COLORS.gray4,
})

export const CellText = styled(SmallText)({
  padding: "8px 0 0 0",
  color: SCIENT_COLORS.gray4,
})
