// @ts-nocheck
import { Callout } from "@blueprintjs/core"
import { Field } from "formik"
import * as R from "ramda"
import { useSelector } from "react-redux"

import { SCIENT_COLORS } from "../../../../../../../../../../../../styled/scientColors"
import { OmniPathSelect } from "../../../../../../../../../../../components"
import { ParamTitle } from "../../../../components"

const FieldChoiceHelper = ({ name, values, param, enhancedPath }) => {
  let sourceId
  let sourceOrigin

  if (!values.source) {
    // treat case where we are in looping Group
    sourceId =
      enhancedPath.sourceId === "stsrc0"
        ? enhancedPath.primarySource.kind_id
        : enhancedPath.sourceId
    sourceOrigin = "loopingGroup"
  } else if (values.source === "stsrc0") {
    const { kind_id } = enhancedPath.primarySource
    sourceId = kind_id
    sourceOrigin = "primary"
  } else {
    sourceId = values.source.replace(/'/g, "")
  }

  const source = useSelector(R.pathOr({}, ["search", "omniByIds", "results", sourceId]))

  const title =
    sourceOrigin === "primary"
      ? `Select field for primary source (${source.verbose_name})`
      : sourceOrigin === "loopingGroup"
      ? `Select field to loop on the ${source.verbose_name} segments`
      : "Select field"

  return (
    <>
      <ParamTitle param={param} title={title} />
      <Field name={name}>
        {({ field: { value }, form: { touched, errors, setFieldValue } }) => {
          const handleSelect = item => {
            const { path } = item //path  used in formula
            setFieldValue(name, path)
          }
          return (
            <>
              <OmniPathSelect
                source={source}
                sourceOrigin={sourceOrigin}
                value={value || ""}
                handleSelect={handleSelect}
              />
              {touched[name] && errors[name] && (
                <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                  {errors[name]}
                </Callout>
              )}
            </>
          )
        }}
      </Field>
    </>
  )
}

export default FieldChoiceHelper
