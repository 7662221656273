import { format, isValid } from "date-fns"

export const formatDateTime = (string: string) => {
  const dateTime = new Date(string)
  if (isValid(dateTime)) {
    return format(new Date(string), "dd-MM-yy HH:mm")
  }
  return ""
}

export const formatDate = (string: string) => {
  const dateTime = new Date(string)
  if (isValid(dateTime)) {
    return format(dateTime, "dd/MM/yyyy")
  }
  return ""
}
