// @ts-nocheck
import React, { useState } from "react"
import { length, update } from "ramda"
import { Button, ButtonGroup, Switch, Classes } from "@blueprintjs/core"

import { Row, Col } from "../../../../../../../../../components"
import { getRange } from "../../../../../utils"

export const SelectPeriod = ({ cols, panelsState, setPanelsState }) => {
  const [byCol, setByCol] = useState(panelsState[1].includes("^") ? true : false)

  const regex = /(#)(\w+)/gi
  const colId = regex.exec(panelsState)[2]

  const col = cols.filter(col => col.colId === colId)[0]
  const { lowerPeriod, upperPeriod } = col
  const rangePeriods = getRange(lowerPeriod, upperPeriod)
  const rangeCols = getRange(1, length(rangePeriods))
  const range = byCol ? rangeCols : rangePeriods

  const appendPeriod = period => setPanelsState(update(1, period, panelsState))

  return (
    <div className={Classes.DIALOG_BODY} style={{ margin: "59px 160px" }}>
      {(col.kind === "stockField" || (col.kind === "factor" && !col.last)) && (
        <>
          <Row style={{ margin: "16px" }}>
            <Col xs={6}>
              <Switch
                label={byCol ? "by col" : "by period"}
                checked={byCol}
                onChange={() => setByCol(!byCol) & setPanelsState(update(1, "", panelsState))} // when change switch, it reset panelState's period
              />
            </Col>
          </Row>
        </>
      )}
      <Row style={{ margin: "16px" }}>
        <Col xs={4}>
          <ButtonGroup>
            {col.kind === "factor" && col.last && (
              <Button
                key={0}
                active={panelsState.includes("|0")}
                disabled={panelsState.includes("|0")}
                onClick={() => appendPeriod("|0")}
                text="Last reported"
                fill
              />
            )}
            {(col.kind === "stockField" || (col.kind === "factor" && !col.last)) &&
              range.map(
                r =>
                  r !== "" && (
                    <Button
                      key={r}
                      active={byCol ? panelsState.includes(`^${r}`) : panelsState.includes(`|${r}`)}
                      onClick={() => appendPeriod(byCol ? `^${r}` : `|${r}`)}
                      text={r}
                      large
                      fill
                    />
                  ),
              )}
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  )
}
