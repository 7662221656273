// @ts-nocheck
import { Icon, NonIdealState, OverlayToaster, Position, Spinner, Toast2 } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useGetOutput, useGetSheet } from "../utils"

import { pathOr } from "ramda"
import sheetsDuck from "../../../../state/modules/sheets"
import Consensus from "./Consensus"
import Msc from "./Msc"
import NextGenTimeFrame from "./NextGenTimeFrame"
import TimeFrame from "./TimeFrame"

const Sheet = ({ desk, sheetId, monitor, height }) => {
  // State for NGTF EditRow.
  // Use also for contextMenu to know which rowNode to open by adding rowId to state
  const [editRowItems, setEditRowItems] = useState({ show: false })

  const dispatch = useDispatch()

  const { fetchSheetDetail, cancelFetchSheetDetail } = sheetsDuck.actions

  useEffect(() => {
    dispatch(fetchSheetDetail({ id: sheetId }))
    return () => dispatch(cancelFetchSheetDetail({ id: sheetId }))
  }, [dispatch, fetchSheetDetail, sheetId, cancelFetchSheetDetail])

  const output = useGetOutput(sheetId)
  const sheet = useGetSheet(sheetId)

  const sheetError = sheet.error

  const fetchingDfForSheets = useSelector(pathOr(false, ["dataframes", "fetchingDfForSheets"]))
  const fetchingDfForSheet =
    sheet.kind === "ng-tf" || sheet.kind === "tf" ? fetchingDfForSheets[sheetId] : false

  const clearSheetError = () => dispatch(sheetsDuck.actions.clearSheetError({ id: sheet.id }))

  // Do not display loader when editRow is opened
  if ((sheet.ready === false || fetchingDfForSheet) && editRowItems.show !== true) {
    return (
      <div className="content">
        <div className="sct-full sct-loader">
          <div>
            <Spinner />
            <p>Fetching sheet detail... {sheet.ready && <Icon icon={"tick"} />}</p>
            <p>Fetching sources... {fetchingDfForSheet === false && <Icon icon={"tick"} />}</p>
          </div>
        </div>
      </div>
    )
  }

  if (output.error) {
    return (
      <div className="content">
        <NonIdealState
          icon="warning-sign"
          title="There was a problem with this sheet"
          description={`The server response is: ${output.error}`}
        />
      </div>
    )
  }

  if (output.errors) {
    return (
      <NonIdealState
        icon="warning-sign"
        title="Server couldn't output data for this sheet."
        description={
          <p>
            Please contact IT with the following error(s):
            <ul>
              {output.errors.map(error => (
                <li>{error}</li>
              ))}
            </ul>
          </p>
        }
      />
    )
  }

  return (
    <div style={{ height: height - 82, width: "100%", padding: "0 10px" }}>
      <OverlayToaster position={Position.RIGHT}>
        {sheetError && (
          <Toast2
            message={`${sheetError} Reload the page.`}
            intent={"danger"}
            onDismiss={() => clearSheetError()}
            timeout={4000}
          />
        )}
      </OverlayToaster>
      {sheet.kind === "tf" ? (
        <TimeFrame
          desk={desk}
          monitor={monitor}
          sheet={sheet}
          sheetId={sheetId}
          output={output}
          height={height}
        />
      ) : sheet.kind === "msc" ? (
        <Msc desk={desk} monitor={monitor} sheet={sheet} output={output} />
      ) : sheet.kind === "ng-tf" ? (
        <NextGenTimeFrame
          desk={desk}
          monitor={monitor}
          sheet={sheet}
          sheetId={sheetId}
          height={height}
          editRowItems={editRowItems}
          setEditRowItems={setEditRowItems}
        />
      ) : (
        <Consensus />
      )}
    </div>
  )
}

export default Sheet
