// @ts-nocheck
import { useSelector } from "react-redux"
import * as R from "ramda"

import { checkElementIsInArray } from "./checkElementIsInArray"
import { useGlobalState } from "../../../hooks/useGlobalState"

export const useGetDesk = deskId => useSelector(R.pathOr(null, ["desks", "desks", "data", deskId]))

export const useGetSheet = sheetId => useSelector(R.pathOr(null, ["sheets", "list", sheetId]))

// use for MSC and TF
export const useGetOutput = sheetId =>
  useSelector(R.pathOr({ colTitles: [], rowData: [] }, ["sheets", "outputs", sheetId]))

// use for NGTF
export const useGetNGTFGrid = sheetId =>
  useSelector(R.pathOr({ colTitles: [], rowData: [] }, ["sheets", "list", sheetId, "grid"]))

export const useGetMonitor = sheetId => {
  const sheet = useGetSheet(sheetId)
  return useSelector(R.pathOr(null, ["monitors", "data", sheet.monitor]))
}

export const useGetSheetDesk = sheetId => {
  const monitor = useGetMonitor(sheetId)
  return useSelector(R.pathOr(null, ["desks", "desks", "data", monitor.desk]))
}

export const useGetSheetSources = sheetId => {
  const desk = useGetSheetDesk(sheetId)
  return desk.sources
}

export const useGetPrimarySrcId = sheetId => {
  const sheet = useGetSheet(sheetId)
  const primarySrcId =
    sheet.st_sources.length > 0 && sheet.st_sources[0].kind + sheet.st_sources[0].id
  return primarySrcId
}

export const useGetUserGroupIds = () => {
  const { profile, groups: groupList } = useGlobalState()

  const userGroups = profile.groups
  const userGroupIds = R.compose(
    R.values,
    R.map(e => e.id),
    R.filter(e => R.includes(e.name, userGroups)),
  )(groupList)

  return userGroupIds
}

export const useGetDeskOwnerName = desk => {
  const { users: userList } = useGlobalState()
  const deskOwnerId = desk.owner_id
  const deskOwnerName = R.compose(
    R.head,
    R.values,
    R.map(e => e.username),
    R.filter(e => e.id === deskOwnerId),
  )(userList)

  return deskOwnerName
}

export const useGetWritePermission = desk => {
  const userGroupIds = useGetUserGroupIds()

  const deskWriteGroupIds = R.compose(
    R.values,
    R.map(e => e.group_id),
    R.filter(e => e.write === true),
  )(R.view(R.lensProp("groups"), desk))

  const hasWritePermission = checkElementIsInArray(userGroupIds, deskWriteGroupIds)

  return hasWritePermission
}
