import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../../hooks/useApi"
import { IAccountBookPairs } from "../../types"

const useTradingDataQuery = ({
  accountBookPairs,
  liveOrders,
}: {
  accountBookPairs: IAccountBookPairs
  liveOrders: boolean
}) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["tradingdatascient", accountBookPairs, liveOrders],
    () => scientApi.trading.getTradingData({ accountBookPairs, liveOrders }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )

  return {
    tradingData: data,
    isLoading,
    error,
  }
}

export default useTradingDataQuery
