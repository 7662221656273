// @ts-nocheck
import { has } from "ramda"

export const getActiveItem = tree => {
  let x = -1
  const recursion = object => {
    if (has("active", object)) {
      x = object
    }

    if (object.children) {
      object.children.forEach(child => recursion(child))
    }
  }
  // we transform tree (which is an array) in object with key children to help looping
  recursion({ children: tree })
  return x
}

export const getItemByTempId = (tree, tempId) => {
  let x = -1
  const recursion = object => {
    if (object.temp_id === tempId) {
      x = object
    }

    if (object.children) {
      object.children.forEach(child => recursion(child))
    }
  }
  // we transform tree (which is an array) in object with key children to help looping
  recursion({ children: tree })
  return x
}
