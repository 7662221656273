// @ts-nocheck
import React from "react"
import PropTypes from "prop-types"
import { ControlGroup, InputGroup } from "@blueprintjs/core"
import { Button } from "."

export const EditName = ({ value, change, submit, cancel }) => (
  <ControlGroup fill>
    <InputGroup value={value} onChange={change} onEnter={submit} />
    <Button icon="tick" onClick={submit} />
    <Button icon="small-cross" onClick={cancel} />
  </ControlGroup>
)

EditName.propTypes = {
  value: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
