// @ts-nocheck
import { Button } from "@blueprintjs/core"
import React from "react"
import { Grid, Row } from "../../../../../../components"
import { FieldArray } from "formik"
import Item from "./Item"

const newIndep = method => {
  const regFields = {
    min_period_shift: 1,
    max_period_shift: 3,
    forecast_periods: 3,
  }
  const varFields = {
    var_freq: "no adj",
  }

  const mainFields = {
    source: {},
    name: "",
    freq: "",
    path: "",
    own_y_axis: false,
  }

  return method === "reg" ? { ...mainFields, ...regFields } : { ...mainFields, ...varFields }
}

const Independents = ({ independents, method }) => (
  <FieldArray name="independents">
    {({ push, remove, swap }) => (
      <Grid>
        <Row>
          {independents.map((indep, index) => {
            return (
              <Item
                key={index}
                indep={indep}
                index={index}
                total={independents.length}
                method={method}
                swap={swap}
                remove={remove}
              />
            )
          })}
        </Row>
        <Row>
          <Button onClick={() => push(newIndep(method))} text="Add explaining variable" fill />
        </Row>
      </Grid>
    )}
  </FieldArray>
)

export default Independents
