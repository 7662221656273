import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import { useApi } from "../../../../hooks/useApi"

const useTradingAccountsQuery = ({ enabled }: { enabled: boolean }) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["tradingaccountsscient"],
    scientApi.trading.getTradingAccountsScient,
    {
      staleTime: Infinity,
      enabled,
      refetchOnWindowFocus: false,
    },
  )

  return {
    accounts: useMemo(() => data || [], [data]),
    isLoading,
    error,
  }
}

export default useTradingAccountsQuery
