import styled from "@emotion/styled"

interface StyleIconProps {
  width?: string
  height?: string
  margin?: number | string
  color?: string
}

const StyleSvgIcon = styled.svg<StyleIconProps>(({ width, height, margin, color }) => ({
  width: width || "25px",
  height: height || "25px",
  margin: margin || 0,
  fill: color || "#FFFFFF",
}))

/**
 * Style icon taken from www.svgrepo.com
 */
const StyleIcon = ({ width, height, margin, color }: StyleIconProps) => {
  return (
    <StyleSvgIcon viewBox="0 0 455 455" width={width} height={height} margin={margin} color={color}>
      <path
        d="M357.642,32.638l-66.814-15.896c0,0-18.622,32.784-63.328,32.784s-63.328-32.784-63.328-32.784L97.358,32.638L0,119.916
	l48.555,75.179l48.803-22.87v266.032h260.284V172.225l48.803,22.87L455,119.916L357.642,32.638z"
      />
    </StyleSvgIcon>
  )
}

export default StyleIcon
