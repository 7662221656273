import { URL } from "url"

/**
 * Handles websocket connection and reconnection with exponential backoff strategy
 */
class WebsocketClient {
  url: URL
  ws: WebSocket | null
  reconnectDelay: number
  retryStep: number
  retrying: boolean
  autoreconnect: boolean

  /**
   * First delay of reconnection in case of error
   * In milliseconds
   */
  INITIAL_RECONNECT_DELAY = 1000

  constructor(url: URL) {
    this.url = url
    this.ws = null
    this.setDefaultBackoffValues()
  }

  /**
   * Reinit local exponential backoff params
   */
  setDefaultBackoffValues() {
    this.reconnectDelay = this.INITIAL_RECONNECT_DELAY
    this.retryStep = 0
    this.retrying = false
    this.autoreconnect = true
  }

  /**
   * Set defined handlers of WebSocket to null,
   * hence letting garbage collection remove the handlers
   */
  clearHandlers(ws: WebSocket) {
    ws.onopen = null
    ws.onclose = null
    ws.onerror = null
  }

  /**
   * Instanciates WebSocket and handlers and initiates connection
   */
  connect({
    onOpen,
    onRetrySuccess,
  }: {
    onOpen: (ws: WebSocket) => void
    onRetrySuccess: () => void
  }) {
    /**
     * We first declare the socket, initiating the connection
     */
    const ws = new WebSocket(this.url)

    /**
     * If the connection open, we are connected
     * We set the exponential backoff params to default values
     * for the potential next reconnections
     */
    ws.onopen = () => {
      console.log(`[WS] - Connected to ${this.url}`)

      /**
       * Calls onRetrySuccess after a retry
       */
      if (this.retrying && !!onRetrySuccess) {
        onRetrySuccess()
      }
      /**
       * Calls onOpen callback
       */
      onOpen(ws)
      this.setDefaultBackoffValues()
    }

    /**
     * If the connection is closed, we retry the connection
     */
    ws.onclose = () => {
      console.log(`[WS] - Disconnected from ${this.url}`)
      /**
       * We make sure handlers are unregistered
       */
      this.clearHandlers(ws)

      if (this.autoreconnect) {
        /**
         * We shedule the next reconnection
         * Each reconnection are incrementaly sheduled following:
         * next_delay = actual_delay + 2^nb_retries
         */
        this.retrying = true
        setTimeout(() => {
          this.reconnectDelay += Math.pow(2, this.retryStep) * 1000
          this.retryStep += 1
          this.connect({ onOpen, onRetrySuccess })
        }, this.reconnectDelay)
        console.log(`[WS] - Retrying connection in ${this.reconnectDelay / 1000} seconds.`)
      } else {
        this.setDefaultBackoffValues()
      }
    }

    /**
     * On error, we properly close the websocket
     */
    ws.onerror = error => {
      console.error("[WS] - Websocket error", error)
      ws.close()
    }

    /**
     * Sets the actual websocket on the client
     */
    this.ws = ws
  }

  /**
   * Closes the connection
   */
  disconnect() {
    this.autoreconnect = false
    this.ws?.close()
  }

  /**
   * Sets URL of websocket
   */
  setUrl(url: URL) {
    this.url = url
  }
}

export default WebsocketClient
