// @ts-nocheck
import { mergeMap } from "rxjs/operators"
import { ofType } from "redux-observable"

import desksDuck from "../index"
import monitorsDuck from "../../monitors/index"

const {
  actions: { fetchAll, fetchDesks, fetchUserDesks },
} = desksDuck
const {
  actions: { fetchMonitorList },
} = monitorsDuck

const fetchAllEpic = action$ =>
  action$.pipe(
    ofType(fetchAll.type),
    mergeMap(() => {
      return [fetchDesks(), fetchUserDesks(), fetchMonitorList()]
    }),
  )
export default fetchAllEpic
