/**
 * Inits all handlers to handle live events for Ideas
 */
import React, { ReactNode, useCallback } from "react"
import { useQueryClient } from "@tanstack/react-query"
import useTradingLiveEvents from "../../../../../websocket/hooks/useTradingLiveEvents"
import { Wave } from "../types/wave"
import { WaveStatus } from "../types/common"

export interface ITradingCacheContext {
  updateWave: ((waveId: number, status: WaveStatus, execution_summary: string) => void) | undefined
  getWaves: ((waveId: number) => Wave[]) | undefined
}

export const TradingCacheContext = React.createContext<ITradingCacheContext>({
  updateWave: undefined,
  getWaves: undefined,
})

/**
 * Provider used to provide query cache update methods
 * to all children of the Provider
 */
export const TradingCacheProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient()

  /**
   * Updates a wave status in the cache with payload from live event
   */
  const updateWave = useCallback(
    (waveId: number, status: WaveStatus, execution_summary: string) => {
      queryClient.setQueryData([`waves`], (infiniteData: any) => {
        for (const page of infiniteData.pages) {
          let indexFound = 0
          const wave = page.data.find((wave: Wave, index: number) => {
            indexFound = index
            return wave.id === waveId
          })
          if (wave) {
            page.data[indexFound] = { ...wave, status, execution_summary }
            return { ...infiniteData }
          }
        }
        return infiniteData
      })
    },
    [queryClient],
  )

  /**
   * Get all waves in the cache
   */
  const getWaves = useCallback(() => {
    let waves: Wave[] = []
    const paginated: any = queryClient.getQueryData([`waves`])
    if (paginated) {
      for (const page of paginated.pages) {
        waves = [...page.data, ...waves]
      }
    }
    return waves
  }, [queryClient])

  useTradingLiveEvents({
    getWaves,
    updateWave,
  })

  return (
    <TradingCacheContext.Provider
      value={{
        getWaves,
        updateWave,
      }}
    >
      {children}
    </TradingCacheContext.Provider>
  )
}
