import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainerProps, FlexItemProps } from "../../../../../styled/flexbox.styled"
import { CardContainer } from "../../../../../styled/layout.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

type MessagesListContainerProps = {
  empty: boolean
  isCollapse: boolean
}

export const MessagesListContainer = styled(CardContainer)<
  MessagesListContainerProps & FlexContainerProps & FlexItemProps
>`
  overflow: auto;
  width: 100%;
  border-radius: ${props => (props.empty ? "5px 0" : "0")};
  min-height: 80px;
  height: ${props => (props.isCollapse && !props.empty ? "8%" : "75%")};
`

export const SpinnerContainer = styled(CardContainer)`
  height: 70px;
  width: 100%;
  border-radius: 5px;
`

export const ErrorContainer = styled(CardContainer)`
  height: 100px;
  width: 100%;
  border-radius: 5px;
  padding: 12px 0;
`

export const NoMessagesPlaceholder = styled(CardContainer)`
  width: 100%;
  margin: 0;
  padding: 10px 0 10px 0;
  border-radius: 5px;
`

export const FetchingText = styled.p`
  margin: 0 0 0 20px;
`

export const NoMessagesTitle = styled.p({
  margin: 0,
})

export const errorTitleCss = css({
  color: `${SCIENT_COLORS.red3} !important`,
  marginLeft: "36px",
})

export const CollapseButtonCss = css({
  "&:hover": {
    backgroundColor: `${SCIENT_COLORS.darkGray3} !important`,
  },
  boxShadow: "none !important",
  borderRadius: "5px 5px 0 0",
})
