// @ts-nocheck
import { getDeepNestedValue } from "../../../../../../utils"
import { slugToName } from "../../../utils/slugToName"
import { cfo, cfi, cff, opfcf } from "./sideUpdates/cf"

export const createCFRows = ({ values, prevYearReport, prevReport, handleChange }) => {
  const firstTable = [
    "cf_cash_taxes",
    "cf_d_a_delta_vs_p_l",
    "cf_gains_and_losses_from_investment_in_associate",
    "cf_gains_and_losses_on_fixed_assets_divestments",
    "cf_gains_and_losses_on_retirement_benefits",
    "cf_financial_income_losses_delta_vs_p_l",
    "cf_changes_in_taxes",
    "cf_share_based_expense",
    "cf_restructuring_income",
    "cf_other_items",
    "cf_changes_in_inventories",
    "cf_changes_in_receivables",
    "cf_changes_in_payables",
    "cf_other_changes_in_current_items_of_bs",
    "cf_wc_delta_bs_cf",
  ]
  const secondTable = [
    "cf_capex_tangibles",
    "cf_capex_intangibles",
    "capex_lease_assets",
    "cf_cash_outflows_for_financial_assets",
    "cf_cash_inflow_from_sale_of_tangible_assets",
    "cf_cash_inflow_from_sale_of_intangible_assets",
    "cf_cash_outflows_for_acquisitions",
    "cf_cash_inflow_from_sale_of_subsidiaries",
    "cf_interest_and_dividends",
  ]
  const thirdTable = [
    "cf_dividends_to_minorities",
    "cf_dividends",
    "cf_proceeds_issuance_of_shares",
    "cf_proceeds_from_borrowings",
    "cf_repayment_of_borrowings",
    "cf_interest_paid_and_other_financial_disbursement",
    "cf_net_cash_contributions_to_pensions",
    "cf_add_back_non_cash_pension_charge",
  ]

  return [
    {
      type: "title",
      props: {
        level: 1,
        title: "Cash Flow",
      },
    },
    {
      type: "readonly",
      props: {
        title: "Profit before tax",
        basePath: ["is_profit_before_tax_readonly"],
        commentPath: ["is_profit_before_tax_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },

    ...firstTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [cfo, opfcf],
        handleChange,
      },
    })),

    {
      type: "readonly",
      props: {
        title: "CF Operations (DB)",
        basePath: ["cf_cfo_readonly"],
        commentPath: ["cf_cfo_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    ...secondTable.map(el => {
      if (
        el === "capex_tangibles" ||
        el === "capex_intangibles" ||
        el === "capex_lease_assets" ||
        el === "cash_inflow_from_sale_of_tangible_assets" ||
        el === "cash_inflow_from_sale_of_intangible_assets"
      ) {
        return {
          type: "input",
          props: {
            title: slugToName(el),
            basePath: [el],
            commentPath: [`${el}_comment`],
            values,
            prevReport,
            prevYearReport,
            sideUpdates: [cfi, opfcf],
            handleChange,
          },
        }
      }
      return {
        type: "input",
        props: {
          title: slugToName(el),
          basePath: [el],
          commentPath: [`${el}_comment`],
          values,
          prevReport,
          prevYearReport,
          sideUpdates: [cfi],
          handleChange,
        },
      }
    }),

    {
      type: "readonly",
      props: {
        title: "CF Investing",
        basePath: ["cf_cfi_readonly"],
        commentPath: ["cf_cfi_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "OpFCF (Company Def.)",
        basePath: ["cf_opfcf_company_definition"],
        commentPath: ["cf_opfcf_company_definition_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "readonly",
      props: {
        title: "Op FCF compute",
        basePath: ["cf_opfcf_readonly"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Op FCF",
        basePath: ["cf_opfcf_override"],
        commentPath: ["cf_opfcf_override_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        placeholder: getDeepNestedValue(["cf_opfcf_readonly"], values),
        sideUpdates: [opfcf],
      },
    },
    ...thirdTable.map(el => {
      if (el === "dividends_to_minorities") {
        return {
          type: "input",
          props: {
            title: slugToName(el),
            basePath: [el],
            commentPath: [`${el}_comment`],
            values,
            prevReport,
            prevYearReport,
            sideUpdates: [cff, opfcf],
            handleChange,
          },
        }
      }
      return {
        type: "input",
        props: {
          title: slugToName(el),
          basePath: [el],
          commentPath: [`${el}_comment`],
          values,
          prevReport,
          prevYearReport,
          sideUpdates: [cff],
          handleChange,
        },
      }
    }),
    {
      type: "readonly",
      props: {
        title: "CF Financing",
        basePath: ["cf_cff_readonly"],
        commentPath: ["cf_cff_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "FX impact on Cash and Equivalents",
        basePath: ["cf_fx_impact_on_cash_and_equivalents"],
        commentPath: ["cf_fx_impact_on_cash_and_equivalents_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Other Changes",
        basePath: ["cf_other_changes"],
        commentPath: ["cf_other_changes_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },

    {
      type: "input",
      props: {
        title: "Cash at the beginning of the Year",
        basePath: ["cf_cash_at_the_beginning_of_the_year"],
        commentPath: ["cf_cash_at_the_beginning_of_the_year_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Restricted Cash",
        basePath: ["cf_restricted_cash"],
        commentPath: ["cf_restricted_cash_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Cash at the end of the Year REPORTED",
        basePath: ["cf_cash_at_the_end_of_the_year_reported"],
        commentPath: ["cf_cash_at_the_end_of_the_year_reported_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "control",
      props: {
        title: "Control : cash at end of year vs calc",
        expected: getDeepNestedValue(["cf_cash_at_the_end_of_the_year_reported"], values),
        sumList: [
          getDeepNestedValue(["cf_cfo_readonly"], values),
          getDeepNestedValue(["cf_cfi_readonly"], values),
          getDeepNestedValue(["cf_cff_readonly"], values),
          getDeepNestedValue(["cf_fx_impact_on_cash_and_equivalents"], values),
          getDeepNestedValue(["cf_other_changes"], values),
          getDeepNestedValue(["cf_cash_at_the_beginning_of_the_year"], values),
          getDeepNestedValue(["cf_restricted_cash"], values),
        ],
      },
    },
  ]
}
// const cashflowOrdered = [
//   "profit_before_tax", //Comes from Income statement
//   "cash_taxes",
//   "d_a_delta_vs_p_l",
//   "gains_and_losses_from_investment_in_associate",
//   "gains_and_losses_on_fixed_assets_divestments",
//   "gains_and_losses_on_retirement_benefits",
//   "financial_income_losses_delta_vs_p_l",
//   "changes_in_taxes",
//   "share_based_expense",
//   "restructuring_income",
//   "other_items",
//   "changes_in_inventories",
//   "changes_in_receivables",
//   "changes_in_payables",
//   "other_changes_in_current_items_of_bs",
//   "wc_delta_bs_cf",
//   "cfo", //compute
//   "capex_tangibles",
//   "capex_intangibles",
//   "capex_lease_assets", // new item
//   "cash_outflows_for_financial_assets",
//   "cash_inflow_from_sale_of_tangible_assets",
//   "cash_inflow_from_sale_of_intangible_assets",
//   "cash_outflows_for_acquisitions",
//   "cash_inflow_from_sale_of_subsidiaries",
//   "interest_and_dividends",
//   "cfi", //compute
//   "opfcf_company_definition",
//   "opfcf", //compute
//   "dividends_to_minorities",
//   "dividends",
//   "proceeds_issuance_of_shares",
//   "proceeds_from_borrowings",
//   "repayment_of_borrowings",
//   "interest_paid_and_other_financial_disbursement",
//   "net_cash_contributions_to_pensions",
//   "add_back_non_cash_pension_charge",
//   "cff", //compute
//   "fx_impact_on_cash_and_equivalents",
//   "other_changes",
//   "cash_at_the_beginning_of_the_year",
//   "restricted_cash",
//   "cash_at_the_end_of_the_year_reported",
