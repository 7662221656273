// @ts-nocheck
import { Button, ControlGroup, Divider, Drawer, H4, Tag } from "@blueprintjs/core"
import { includes, pathOr, reject, values } from "ramda"
import { useState } from "react"
import { DndProvider } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import { useSelector } from "react-redux"

import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { Callout, Col, Grid, Row } from "../../../components"
import { CreateNewDesk } from "../components"
import NoFavoriteDeskItem from "./NoFavoriteDeskItem"
import VisibleDesksContainer from "./VisibleDesksContainer"

const ManageDesks = ({ activeDeskId, isOpen, userDesks, setManageDesks }) => {
  const [dialog, setDialog] = useState(false)
  const toggleDialog = () => setDialog(!dialog)

  const allDesks = values(useSelector(pathOr([], ["desks", "desks", "data"])))

  const noFavoriteDesks = reject(
    e =>
      includes(
        e.id,
        userDesks.map(el => el.desk),
      ),
    allDesks,
  )

  const handleClose = () => {
    setManageDesks(false)
  }

  const style = color => {
    return {
      backgroundColor: SCIENT_COLORS.gray1,
      color: color,
      marginRight: "24px",
      marginLeft: "38px",
    }
  }
  const info = () => {
    return (
      <ControlGroup vertical={false}>
        <Tag style={style("white")}>My desk</Tag>
        <Tag style={style(SCIENT_COLORS.lightBlue1)}>Desk shared</Tag>
        <Tag style={style(SCIENT_COLORS.gray4)}>Desk read-only</Tag>
      </ControlGroup>
    )
  }
  return (
    <>
      <Drawer
        className="bp5-dark"
        icon="desktop"
        isOpen={isOpen}
        isCloseButtonShown={true}
        onClose={handleClose}
        title="Manage desks"
        size="1000px"
        hasBackdrop={false}
        position={"left"}
        // style={{ overflow: "hidden", height: "100%" }}
      >
        <Grid fluid>
          <CreateNewDesk isOpen={dialog} toggleDialog={toggleDialog} userDesks={userDesks} />
          <Row center="xs" middle="xs">
            <Col xs={6}>
              <Button
                icon="plus"
                text="Create new desk"
                onClick={toggleDialog}
                intent="primary"
                large
              />
            </Col>
            <Col xs={6}>
              <Callout title={"Desks colors"} body={info()} intent={"primary"} />
            </Col>
          </Row>
          <Divider />

          <Row>
            <Col xs>
              <Row center="xs" style={{ margin: "4px 0px 0px" }}>
                <Col xs={6}>
                  <H4>Favorite desks</H4>
                </Col>
              </Row>
              <DndProvider backend={HTML5Backend}>
                <VisibleDesksContainer
                  userDesks={userDesks}
                  activeDeskId={activeDeskId}
                  style={{ backgroundColor: "red" }}
                />
              </DndProvider>
            </Col>
            <Divider />

            <Col xs>
              <Row center="xs" style={{ margin: "4px 0px 0px" }}>
                <Col xs>
                  <H4>Desk library</H4>
                </Col>
              </Row>
              <div
                style={{
                  padding: "8px",
                  margin: "8px 0",
                }}
              >
                {noFavoriteDesks.map(noFavoriteDesk => (
                  <NoFavoriteDeskItem
                    key={noFavoriteDesk.id}
                    userDesks={userDesks}
                    noFavoriteDesk={noFavoriteDesk}
                    setManageDesks={setManageDesks}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Grid>
      </Drawer>
    </>
  )
}

export default ManageDesks
