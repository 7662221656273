// @ts-nocheck
import { getDeepNestedValue } from "../../../../../../utils"
import { slugToName } from "../../../utils/slugToName"
import {
  totalAssets,
  fixedAssets,
  currentAssets,
  totalEquityLiabilities,
  shareholdersEquity,
  fixedLiabilities,
  currentLiabilities,
  ntfa,
  evAdj,
  netDebt,
} from "./sideUpdates/bs"

export const createBSRows = ({ stockData, values, prevYearReport, prevReport, handleChange }) => {
  const firstTable = [
    "bs_tangible_assets",
    "bs_intangible_assets",
    "bs_operating_lease_assets",
    "bs_investment_in_associate",
    "bs_investment_in_other_affiliated_companies",
    "bs_non_current_financial_assets",
    "bs_deferred_tax_assets",
    "bs_other_non_current_assets",
  ]
  const secondTable = [
    "bs_cash",
    "bs_receivables",
    "bs_inventories",
    "bs_other_non_wc_current_assets",
    "bs_other_wc_current_assets",
  ]
  const thirdTable = [
    "bs_capital_shares",
    "bs_other_reserves",
    "bs_retained_earnings",
    "bs_minorities",
  ]
  const fourthTable = [
    "bs_non_current_leases",
    "bs_other_long_term_liabilities",
    "bs_pension_provisions",
    "bs_deferred_tax_liab",
    "bs_tax_liabilities",
    "bs_provisions",
  ]
  const fifthTable = [
    "bs_current_leases",
    "bs_accounts_payable_and_other_wc_current_liabilities",
    "bs_short_term_provisions",
    "bs_current_tax_liabilities",
    "bs_other_non_wc_current_liabilities",
  ]
  const sixthTable = [
    "bs_intangibles_with_indefinite_useful_life_gross",
    "bs_intangibles_with_indefinite_useful_life_net",
    "bs_impairment_intangibles_indefinite_useful_life",
    "bs_other_intangibles_gross",
    "bs_other_intangibles_net",
  ]
  const seventhTable = [
    "bs_associates_fairvalue",
    "bs_minorities_fairvalue",
    "bs_pensions_liab_fairvalue",
  ]
  const eighthTable = ["bs_bps", "bs_amortization_pct_bv", "bs_depreciation_pct_ntfa"]
  const ninthTable = ["bs_wcr_to_sales", "bs_fi_to_grossdebt", "bs_pct_cash_debtreduction"]

  const rowsBeforeFirstCheck = [
    {
      type: "title",
      props: {
        level: 1,
        title: "Balance sheet",
      },
    },
    {
      type: "title",
      props: {
        level: 2,
        title: "Assets",
      },
    },
    {
      type: "readonly",
      props: {
        title: "Fixed Assets",
        basePath: ["bs_fixed_assets"],
        commentPath: ["bs_fixed_assets_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    ...firstTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [fixedAssets, totalAssets, evAdj(stockData, values)],
        handleChange,
      },
    })),

    {
      type: "readonly",
      props: {
        title: "Current assets",
        basePath: ["bs_current_assets"],
        commentPath: ["bs_current_assets_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    ...secondTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [currentAssets, totalAssets, evAdj(stockData, values), netDebt],
        handleChange,
      },
    })),
    {
      type: "readonly",
      props: {
        title: "Total assets",
        basePath: ["bs_total_assets"],
        commentPath: ["bs_total_assets_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "title",
      props: {
        level: 2,
        title: "Equity and liabilities",
      },
    },
    {
      type: "readonly",
      props: {
        title: "Shareholders equity",
        basePath: ["bs_shareholders_equity"],
        commentPath: ["bs_shareholders_equity_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    ...thirdTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [shareholdersEquity, totalEquityLiabilities],
        handleChange,
      },
    })),
    {
      type: "readonly",
      props: {
        title: "Fixed liabilities",
        basePath: ["bs_fixed_liabilities"],
        commentPath: ["bs_fixed_liabilities_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Long Term Debt",
        basePath: ["bs_long_term_debt"],
        commentPath: ["bs_long_term_debt_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        sideUpdates: [fixedLiabilities, totalEquityLiabilities, netDebt],
      },
    },

    ...fourthTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [fixedLiabilities, totalEquityLiabilities, evAdj(stockData, values), netDebt],
        handleChange,
      },
    })),
    {
      type: "readonly",
      props: {
        title: "Current liabilities",
        basePath: ["bs_current_liabilities"],
        commentPath: ["bs_current_liabilities_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },

    {
      type: "input",
      props: {
        title: "Short term Borrowings",
        basePath: ["bs_short_term_borrowings"],
        commentPath: ["bs_short_term_borrowings_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        sideUpdates: [
          currentLiabilities,
          totalEquityLiabilities,
          evAdj(stockData, values),
          netDebt,
        ],
      },
    },

    ...fifthTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          currentLiabilities,
          totalEquityLiabilities,
          evAdj(stockData, values),
          netDebt,
        ],
        handleChange,
      },
    })),
    {
      type: "readonly",
      props: {
        title: "Total equity liabilities",
        basePath: ["bs_total_equity_liabilities"],
        commentPath: ["bs_total_equity_liabilities_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Number_employees",
        basePath: ["bs_number_employees"],
        commentPath: ["bs_number_employees_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Average salary",
        basePath: ["bs_avg_salary"],
        commentPath: ["bs_avg_salary_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    ...seventhTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        sideUpdates: [evAdj(stockData, values)],
      },
    })),
    {
      type: "readonly",
      props: {
        title: "Ev Adj Compute",
        basePath: ["bs_ev_adj_readonly"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Ev Adj",
        basePath: ["bs_ev_adj_override"],
        commentPath: ["bs_ev_adj_override_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        placeholder: getDeepNestedValue(["bs_ev_adj_readonly"], values),
        sideUpdates: [evAdj(stockData, values)],
      },
    },

    ...eighthTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    })),
    {
      type: "readonly",
      props: {
        title: "Net Debt Compute",
        basePath: ["bs_net_debt_readonly"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Net Debt",
        basePath: ["bs_net_debt_override"],
        commentPath: ["bs_net_debt_override_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        placeholder: getDeepNestedValue(["bs_net_debt_readonly"], values),
        sideUpdates: [netDebt],
      },
    },
    ...ninthTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    })),
    {
      type: "title",
      props: {
        level: 1,
        title: "Balance Sheet Value Drivers",
      },
    },
    {
      type: "input",
      props: {
        title: "Gross bv tangibles",
        basePath: ["bs_gross_bv_tangibles"],
        commentPath: ["bs_gross_bv_tangibles_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [ntfa],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Accumulated depreciation",
        basePath: ["bs_accumulated_depreciation"],
        commentPath: ["bs_accumulated_depreciation_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [ntfa],
        handleChange,
      },
    },
    {
      type: "readonly",
      props: {
        title: "Net Tangible Fixed Assets",
        basePath: ["bs_ntfa"],
        commentPath: ["bs_ntfa_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "control",
      props: {
        title: "Tangible check",
        expected: getDeepNestedValue(["bs_tangible_assets"], values),
        sumList: [
          getDeepNestedValue(["bs_gross_bv_tangibles"], values),
          getDeepNestedValue(["bs_accumulated_depreciation"], values),
        ],
      },
    },
  ]
  const rowsAfterFirstCheck = [
    {
      type: "input",
      props: {
        title: "Net GW",
        basePath: ["bs_goodwill_net"],
        commentPath: ["bs_goodwill_net_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Gross GW",
        basePath: ["bs_goodwill_gross"],
        commentPath: ["bs_goodwill_gross_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "GW write down",
        basePath: ["bs_impairment_to_goodwill_for_the_year"],
        commentPath: ["bs_impairment_to_goodwill_for_the_year_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    ...sixthTable.map(el => ({
      type: "input",
      props: {
        title: slugToName(el),
        basePath: [el],
        commentPath: [`${el}_comment`],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    })),

    {
      type: "control",
      props: {
        title: "Check Intangible",
        expected: getDeepNestedValue(["bs_intangible_assets"], values),
        sumList: [
          getDeepNestedValue(["bs_goodwill_net"], values),
          getDeepNestedValue(["bs_intangibles_with_indefinite_useful_life_net"], values),
          getDeepNestedValue(["bs_other_intangibles_net"], values),
        ],
      },
    },
  ]
  const rowsAfterSecondCheck = [
    {
      type: "input",
      props: {
        title: "Amortization expense other intangibles",
        basePath: ["bs_amortization_expense_other_intangibles"],
        commentPath: ["bs_amortization_expense_other_intangibles_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Working capital company definition",
        basePath: ["bs_working_capital_company_definition"],
        commentPath: ["bs_working_capital_company_definition_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
  ]
  const rowsQuarterly = [...rowsBeforeFirstCheck, ...rowsAfterFirstCheck, ...rowsAfterSecondCheck]
  const rowsFullYear = [
    ...rowsBeforeFirstCheck,
    // {
    //   type: "control",
    //   props: {
    //     title: "Check: ntfa vs tangible_asset",
    //     expected: getDeepNestedValue(["bs_tangible_assets"], values),
    //     sumList: [getDeepNestedValue(["bs_ntfa"], values)],
    //   },
    // },
    ...rowsAfterFirstCheck,
    // {
    //   type: "control",
    //   props: {
    //     title: "Check: (netGW+intangiblesBV+other_intangibles_net) vs intangible_asset",
    //     expected: getDeepNestedValue(["bs_intangible_assets"], values),
    //     sumList: [
    //       getDeepNestedValue(["bs_goodwill_net"], values),
    //       getDeepNestedValue(["bs_intangibles_with_indefinite_useful_life_net"], values),
    //       getDeepNestedValue(["bs_other_intangibles_net"], values),
    //     ],
    //   },
    // },
    ...rowsAfterSecondCheck,
  ]
  if (values.period === "FY") {
    return [...rowsFullYear]
  }
  return [...rowsQuarterly]
}

//Champs ci-dessous pas dans excel
// "associates_fairvalue",
// "minorities_fairvalue",
// "pensions_liab_fairvalue",
// "ev_adj",

// export const balancesheetOdered = [
//   "fixed_assets", //compute
//   "tangible_assets",
//   "intangible_assets",
//   "operating_lease_assets", // new item
//   "investment_in_associate",
//   "investment_in_other_affiliated_companies",
//   "non_current_financial_assets",
//   "deferred_tax_assets",
//   "other_non_current_assets",
//   "current_assets", //compute
//   "cash",
//   "receivables",
//   "inventories",
//   "other_non_wc_current_assets",
//   "other_wc_current_assets",
//   "total_assets", //compute
//   "shareholders_equity", //compute
//   "capital_shares",
//   "other_reserves",
//   "retained_earnings",
//   "minorities",
//   "fixed_liabilities", //compute
//   "long_term_debt",
//   "non_current_leases", // new item
//   "other_long_term_liabilities",
//   "pension_provisions",
//   "deferred_tax_liab",
//   "tax_liabilities",
//   "provisions",
//   "current_liabilities", //compute
//   "short_term_borrowings",
//   "current_leases", // new item
//   "accounts_payable_and_other_wc_current_liabilities",
//   "short_term_provisions",
//   "current_tax_liabilities",
//   "other_non_wc_current_liabilities",
//   "total_equity_liabilities", //compute
//   "number_employees",
//   "avg_salary",

// const balancesheetValueDrivers = [
//   "gross_bv_tangibles",
//   "accumulated_depreciation",
//   "ntfa", //compute
//   "goodwill_net",
//   "goodwill_gross",
//   "impairment_to_goodwill_for_the_year",
//   "intangibles_with_indefinite_useful_life_gross",
//   "intangibles_with_indefinite_useful_life_net",
//   "impairment_intangibles_indefinite_useful_life",
//   "other_intangibles_gross",
//   "other_intangibles_net",
//   "amortization_expense_other_intangibles",
//   "working_capital_company_definition",
