// @ts-nocheck
import React from "react"
import { Button, ButtonGroup, Tag } from "@blueprintjs/core"
import { Row, Col } from "../../../../components"

const diffMethod = ({ diffMethod, handleSetDiffMethod }) => {
  return (
    <>
      <Row center="xs" style={{ margin: "0" }}>
        <Col xs>
          <Tag minimal>Diff method</Tag>
        </Col>
      </Row>
      <Row center="xs" style={{ margin: "6px auto 8px" }}>
        <Col xs>
          <ButtonGroup style={{ width: "70%" }}>
            <Button
              onClick={() => handleSetDiffMethod("value")}
              active={diffMethod === "value"}
              text="value"
              fill
            />
            <Button
              onClick={() => handleSetDiffMethod("pct")}
              active={diffMethod === "pct"}
              text="pct"
              fill
            />
          </ButtonGroup>
        </Col>
      </Row>
    </>
  )
}

export default diffMethod
