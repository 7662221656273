import styled from "@emotion/styled"
import { FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { CardContainer, noSelect } from "../../../../../styled/layout.styled"

export const IdeaHeaderContainer = styled(CardContainer)`
  padding: 10px 20px 10px 20px;
  height: 40px;
  ${noSelect}
`

export const InfoContainer = styled(FlexContainerAndItem)({
  overflow: "auto",
  position: "relative",
})

export const ItemContainer = styled(FlexContainerAndItem)({
  overflow: "hidden",
})

export const StarsContainer = styled(FlexContainerAndItem)({
  margin: "0 35px 0 6px",
})

type IdeaInfosProps = {
  endLine?: boolean
  fontSize?: string
  bold?: boolean
}

export const IdeaInfos = styled.p<IdeaInfosProps>(props => ({
  margin: props.endLine ? "0 5px 0 6px" : "0 35px 0 6px",
  fontSize: props.fontSize ? props.fontSize : "15px",
  fontWeight: props.bold ? "bold" : "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}))
