// @ts-nocheck
import {
  addIndex,
  compose,
  concat,
  find,
  isEmpty,
  join,
  keys,
  map,
  pathOr,
  propEq,
  propOr,
  reduceRight,
  reject,
} from "ramda"
import { getFormulaItems, getNiceFormulaItem } from "./index"

const reduceRightIndexed = addIndex(reduceRight)

export const concatWithSeparator = lists =>
  compose(
    reduceRightIndexed((list, acc, index, newList) => {
      const realIndex = newList.length - index - 1
      return concat(newList.length > 1 && realIndex !== 0 ? ["separator", ...list] : list, acc)
    }, []),
    reject(isEmpty),
  )(lists)

export const contextMenu = ({
  params,
  setEdit,
  setDeleteAlert,
  dataframes,
  rowDefs,
  userId,
  deskOwnerId,
  hasWritePermission,
  setOpenQuickChart,
}) => {
  const { node } = params

  const error = pathOr(null, ["data", "extra", "error"], node)

  const rowIndex = propOr(-1, "rowIndex", node)

  const defaultItems = params.defaultItems || []

  // Find rowId in order to get rowDef associated
  const rowId = node && rowIndex >= 0 && node.data.extra.rowId
  const rowDef = rowId && find(propEq("rowId", rowId))(rowDefs)
  // generate formulaItems from formula
  const formulaItems = rowDef && getFormulaItems(rowDef.formula)

  const niceFormula =
    formulaItems &&
    compose(
      join(" "),
      map(e =>
        getNiceFormulaItem({
          item: e.item,
          dataframes,
          rowDefs,
        }),
      ),
    )(formulaItems)

  // Extract keys from data in order to be able to count elements in Data.
  const keyListInData = node && rowIndex >= 0 && keys(params.node.data)

  // desc is the title of the row
  const desc = pathOr("", ["data", "desc"], node) //node && node.data.extra.desc
  const rowMenuItems =
    node && rowIndex >= 0
      ? [
          error &&
            (userId === deskOwnerId || hasWritePermission) && {
              name: `ERROR ${error}`,
              icon: "<span icon='warning-sign' class='bp5-icon bp5-icon-warning-sign' />",
              action: () => setEdit({ rowIndex, show: true, add: false }),
              cssClasses: ["sct-row-error"],
            },
          error &&
            (userId === deskOwnerId || hasWritePermission) && {
              name: `Please edit this row and verify your formula.`,
              action: () => setEdit({ rowIndex, show: true, add: false }),
              cssClasses: ["sct-row-error"],
            },
          {
            name: `Formula of ${desc}`,
            tooltip: niceFormula,
            icon: "<span icon='eye-open' class='bp5-icon bp5-intent-primary bp5-icon-eye-open' />",
          },
          // If only 2 elements in data (are 'extra' and 'desc'0), they're no value so no need to display Quick Chart
          keyListInData.length > 2 && {
            name: `Quick chart`,
            action: () =>
              setOpenQuickChart({
                rowIndex,
                show: true,
                rowId: params.node.id,
                name: params.node.data.desc,
              }),
            icon: "<span icon='chart' class='bp5-icon bp5-intent-primary bp5-icon-chart' />",
          },
          (userId === deskOwnerId || hasWritePermission) && {
            name: "Edit row",
            action: () => setEdit({ rowIndex, show: true, add: false }),
            icon: "<span icon='edit' class='bp5-icon bp5-intent-primary bp5-icon-edit' />",
          },
          (userId === deskOwnerId || hasWritePermission) && {
            name: "Delete row",
            action: () => setDeleteAlert({ rowIndex, show: true, add: false }),
            icon: "<span icon='remove' class='bp5-icon bp5-intent-danger bp5-icon-remove' />",
          },
        ]
      : []

  const insertMenuItems = [
    (userId === deskOwnerId || hasWritePermission) && {
      name: "Add row",
      icon: "<span icon='add' class='bp5-icon bp5-intent-primary bp5-icon-add' />",
      action: () => setEdit({ rowIndex, show: true, add: true }),
    },
  ]

  return concatWithSeparator([rowMenuItems, insertMenuItems, defaultItems])
}
