// @ts-nocheck
import { prepend, append, compose, set, lensPath, remove } from "ramda"

export const stockSetConsensusFields = (state, { slug, consensusFields }) =>
  set(lensPath(["userstockconfigs", slug, "consensusFields"]), consensusFields, state)

export const stockSetThresholdDays = (state, { slug, value }) =>
  set(lensPath(["userstockconfigs", slug, "thresholdDays"]), value, state)

export const stockSetDiffMethod = (state, { slug, diffMethod }) =>
  set(lensPath(["userstockconfigs", slug, "diffMethod"]), diffMethod, state)

export const stockSetDiffDefender = (state, { slug, forecastSlug, diffPair }) => {
  const newDiffPair = compose(prepend(forecastSlug), remove(0, 1))(diffPair)
  return set(lensPath(["userstockconfigs", slug, "diffPair"]), newDiffPair, state)
}

export const stockSetDiffChallenger = (state, { slug, forecastSlug, diffPair }) => {
  const newDiffPair = compose(append(forecastSlug), remove(1, 1))(diffPair)
  return set(lensPath(["userstockconfigs", slug, "diffPair"]), newDiffPair, state)
}

export const stockToggleStarred = (state, { slug, starred, excluded, forecastSlug }) => {
  const isStarred = starred.length > 0 && starred.includes(forecastSlug)
  const isExcluded = excluded.length > 0 && excluded.includes(forecastSlug)

  const indexStar = isStarred ? starred.indexOf(forecastSlug) : null
  const newStarredRemove = isStarred ? remove(indexStar, 1, starred) : null

  const newStarredAdd = isExcluded ? append(forecastSlug, starred) : null
  const indexExcluded = isExcluded ? excluded.indexOf(forecastSlug) : null
  const newExcluded = isExcluded ? remove(indexExcluded, 1, excluded) : null

  if (isExcluded)
    return compose(
      set(lensPath(["userstockconfigs", slug, "excluded"]), newExcluded),
      set(lensPath(["userstockconfigs", slug, "starred"]), newStarredAdd),
    )(state)
  if (isStarred)
    return set(lensPath(["userstockconfigs", slug, "starred"]), newStarredRemove, state)

  return set(lensPath(["userstockconfigs", slug, "starred"]), [...starred, forecastSlug], state)
}

export const stockToggleExcluded = (state, { slug, starred, excluded, forecastSlug }) => {
  const isStarred = starred.length > 0 && starred.includes(forecastSlug)
  const isExcluded = excluded.length > 0 && excluded.includes(forecastSlug)

  const indexExcluded = isExcluded ? excluded.indexOf(forecastSlug) : null
  const newExcludedRemove = isExcluded ? remove(indexExcluded, 1, excluded) : null

  const newExcludedAdd = isStarred ? append(forecastSlug, excluded) : null
  const indexStarred = isStarred ? starred.indexOf(forecastSlug) : null
  const newStarred = isStarred ? remove(indexStarred, 1, starred) : null

  if (isStarred)
    return compose(
      set(lensPath(["userstockconfigs", slug, "starred"]), newStarred),
      set(lensPath(["userstockconfigs", slug, "excluded"]), newExcludedAdd),
    )(state)
  if (isExcluded)
    return set(lensPath(["userstockconfigs", slug, "excluded"]), newExcludedRemove, state)

  return set(lensPath(["userstockconfigs", slug, "excluded"]), [...excluded, forecastSlug], state)
}

export const stockSetQuarterAmount = (state, { slug, quarterAmount }) => {
  return set(lensPath(["userstockconfigs", slug, "quarterAmount"]), quarterAmount, state)
}

export const stockSetYearAmount = (state, { slug, yearAmount }) => {
  return set(lensPath(["userstockconfigs", slug, "yearAmount"]), yearAmount, state)
}
