// @ts-nocheck
/** @jsxImportSource @emotion/react */

import React, { useEffect } from "react"
import { includes, isEmpty } from "ramda"
import { Button, ButtonGroup, Card, Collapse, H3 } from "@blueprintjs/core"

import { useWindowDimensions } from "../../../../../../../../../hooks"
import { TransferList } from "../../../../../../../../../components"
import { useCustomFields } from "../../../utils/hooks"
import { h3Css, TextInfoStyled } from "./LoopBuilder.styled"

export const CustomFieldsChoice = ({ values, setValues, isCustomFieldsChoiceSelected }) => {
  const loopParams = values.loopParams

  const customFields = useCustomFields({
    sourceId: loopParams.src,
    srcKind: loopParams.srcKind,
    isCustomFieldsChoiceSelected,
    isGroupSegs: false,
  })

  /* If user switches between source : 
         _ customFields are updated and copy into notSelectedCustomFields.
         _ selectedCustomFields is reset to an empty array
   To avoid lauch of useEffect at first load, a if condition is added.
   So useEffect is lauched only when user click is changing source (changed of source resets
   selectedCustomFields and notSelectedCustomFields to empty array ) */

  useEffect(() => {
    if (
      isEmpty(values.loopParams.selectedCustomFields) &&
      isEmpty(values.loopParams.notSelectedCustomFields) &&
      !isEmpty(customFields)
    )
      setValues({
        ...values,
        loopParams: {
          ...values.loopParams,
          notSelectedCustomFields: customFields,
          selectedCustomFields: [],
          customFields,
        },
      })
  }, [customFields]) // Rerender only if customFields has changed. customFields is changing when source is changing

  const leftLabel = `Available field${loopParams.notSelectedCustomFields.length > 1 ? "s" : ""}`
  const rightLabel = `Chosen field${loopParams.selectedCustomFields.length > 1 ? "s" : ""}`
  const left = loopParams.notSelectedCustomFields
  const right = loopParams.selectedCustomFields

  const setSlugs = value => {
    const isValueAlreadySelected = loopParams.slugs && includes(value, loopParams.slugs)
    return setValues(
      isValueAlreadySelected
        ? { ...values }
        : {
            ...values,
            loopParams: {
              ...values.loopParams,
              slugs: value,
            },
          },
    )
  }

  const handleAllRight = () => {
    setValues({
      ...values,
      loopParams: {
        ...values.loopParams,
        notSelectedCustomFields: [],
        selectedCustomFields: right.concat(left),
      },
    })
  }

  const handleCheckedRight = (checked, setChecked, leftChecked, not) => {
    setValues({
      ...values,
      loopParams: {
        ...values.loopParams,
        notSelectedCustomFields: not(left, leftChecked),
        selectedCustomFields: right.concat(leftChecked),
      },
    })
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = (checked, setChecked, rightChecked, not) => {
    setValues({
      ...values,
      loopParams: {
        ...values.loopParams,
        notSelectedCustomFields: left.concat(rightChecked),
        selectedCustomFields: not(right, rightChecked),
      },
    })
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    setValues({
      ...values,
      loopParams: {
        ...values.loopParams,
        notSelectedCustomFields: left.concat(right),
        selectedCustomFields: [],
      },
    })
  }

  const { width } = useWindowDimensions()

  return (
    <>
      <Collapse isOpen={loopParams.kind === "fields"}>
        <H3 css={h3Css}>What custom fields do you want to loop on ?</H3>
        <ButtonGroup large fill>
          <Button
            active={loopParams.slugs && includes("all", loopParams.slugs)}
            onClick={() => setSlugs("'all': 1")}
            text="All"
          />
          <Button
            active={loopParams.slugs && includes("active", loopParams.slugs)}
            onClick={() => setSlugs("'active': 1")}
            text="Active"
          />
          <Button
            active={
              loopParams.kind === "fields" &&
              loopParams.slugs &&
              includes("slugs", loopParams.slugs)
            }
            onClick={() => setSlugs("'slugs': []")}
            text="Choose in list"
          />
        </ButtonGroup>
      </Collapse>
      {isCustomFieldsChoiceSelected &&
        (!isEmpty(loopParams.customFields) ? (
          <TransferList
            left={left}
            right={right}
            leftLabel={leftLabel}
            rightLabel={rightLabel}
            handleAllRight={handleAllRight}
            handleCheckedRight={handleCheckedRight}
            handleCheckedLeft={handleCheckedLeft}
            handleAllLeft={handleAllLeft}
            width={width}
          />
        ) : (
          <Card>
            <TextInfoStyled>
              No Custom Fields for the selected source or no source selected.
            </TextInfoStyled>
          </Card>
        ))}
    </>
  )
}
