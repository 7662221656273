// @ts-nocheck
import { H6 } from "@blueprintjs/core"
import { addYears, format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { Field } from "formik"
import { pathOr } from "ramda"

import { Col, Row } from "../../../../../components"
import {
  FormikDatePicker,
  HTMLSelect,
  NumericInput,
  fakeSyntheticEvent,
} from "../../../../../components/formik"
import { fields, slugToName } from "../../../utils"
import { PrevValue } from "./components"

const periodOpts = ["Q1", "Q2", "H1", "Q3", "Q4", "H2", "FY"]

const Dates = ({ handleChange, reportData: { prevReport, prevYearReport } }) => {
  return (
    <>
      {fields.main.map(el => {
        const isDate = ["start", "end", "release_date"].includes(el)

        return (
          <Row key={el}>
            <Col xsOffset={1} xs={3}>
              <H6> {slugToName(el)} </H6>
            </Col>
            <Col xs={2}>
              <PrevValue field={el} value={pathOr(null, [el], prevYearReport)} isDate={isDate} />
            </Col>
            <Col xs={2}>
              <PrevValue field={el} value={pathOr(null, [el], prevReport)} isDate={isDate} />
            </Col>

            <Col xs={3}>
              {isDate ? (
                /**
                 * Date are in UTC including release_date (to symplify)
                 * Improvement can be to have choice of hours for release_date, choice of local time...
                 */
                <Field
                  name={el}
                  key={el}
                  component={FormikDatePicker}
                  canClearSelection={false}
                  minDate={new Date(1970, 0, 1)}
                  maxDate={addYears(new Date(), 3)}
                  showActionsBar={true}
                  onError={() => handleChange(fakeSyntheticEvent("", el))}
                  onChange={date =>
                    handleChange(
                      fakeSyntheticEvent(
                        format(
                          utcToZonedTime(new Date(date), "UTC"),
                          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                        ),
                        el,
                      ),
                    )
                  }
                  className="sct-fullwidth"
                />
              ) : el === "year" ? (
                <Field
                  name={el}
                  key={el}
                  component={NumericInput}
                  min="1990"
                  max="2100"
                  onValueChange={value => handleChange(fakeSyntheticEvent(value, el))}
                />
              ) : (
                <Field name={el} key={el} component={HTMLSelect} options={periodOpts} />
              )}
            </Col>
          </Row>
        )
      })}
    </>
  )
}

export default Dates
