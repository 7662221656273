// @ts-nocheck
import React from "react"
import * as R from "ramda"
import { ButtonGroup, Button, Label, HTMLSelect } from "@blueprintjs/core"
import { Field } from "formik"

import { Grid, Row, Col } from "react-flexbox-grid"
import FormikHeatmap from "./FormikHeatmap"

const styleButtons = ["bold", "italic"]

const StyleButton = ({ field, form, style }) => {
  const styles = field.value || {}

  const toggleStyle = style => {
    if (R.has(style, styles)) {
      return form.setFieldValue(field.name, R.dissoc(style, styles))
    } else {
      return form.setFieldValue(field.name, R.assoc(style, true, styles))
    }
  }
  return (
    <Button
      key={field.name}
      active={R.propOr(false, style, styles)}
      onClick={() => toggleStyle(style)}
      text={style}
    />
  )
}

const colorButtons = [
  { id: "wht", icon: "symbol-square", color: undefined },
  { id: "blu", icon: "symbol-square", className: "sct-front-blue", color: "blue" },
  { id: "grn", icon: "symbol-square", className: "sct-front-green", color: "green" },
  { id: "orng", icon: "symbol-square", className: "sct-front-orange", color: "orange" },
  { id: "red", icon: "symbol-square", className: "sct-front-red", color: "red" },
  { id: "indg", icon: "symbol-square", className: "sct-front-indigo", color: "indigo" },
  { id: "trqz", icon: "symbol-square", className: "sct-front-turquoise", color: "turquoise" },
  { id: "spia", icon: "symbol-square", className: "sct-front-sepia", color: "sepia" },
]

const ColorButton = ({ field, form, el }) => {
  const handleSetColor = () => form.setFieldValue(field.name, el.color)

  return (
    <Button
      key={el.id}
      icon="symbol-square"
      className={el.className}
      onClick={handleSetColor}
      active={el.color === field.value}
      disabled={el.color === field.value}
    />
  )
}

const focusOpts = [
  { label: "No focus", value: "" },
  { label: "Focus 1", value: "1" },
  { label: "Focus 2", value: "2" },
  { label: "Focus 3", value: "3" },
  { label: "Focus 4", value: "4" },
]
const FocusSelect = ({ field, form }) => {
  const handleSelectFocus = ({ target: { value } }) => form.setFieldValue(field.name, value)
  return <HTMLSelect options={focusOpts} value={field.value || ""} onChange={handleSelectFocus} />
}

const StylingPalette = ({ index }) => {
  return (
    <Grid>
      <Row between="xs" style={{ paddingLeft: 0, paddingRight: "300px" }}>
        <Col xs={5} style={{ paddingLeft: 0 }}>
          <FormikHeatmap index={index} />
        </Col>
        <Col xs={5} style={{ paddingLeft: 0 }}>
          <Label>
            Focus
            <Field name={`rowDefs[${index}].frontOpts.styles.focus`} component={FocusSelect} />
          </Label>
        </Col>
      </Row>

      <Row between="xs" style={{ paddingLeft: 0, paddingRight: "300px" }}>
        <Col xs={5} style={{ paddingLeft: 0 }}>
          <Label>
            Style
            <ButtonGroup fill>
              {styleButtons.map(style => (
                <Field
                  key={style}
                  name={`rowDefs[${index}].frontOpts.styles`}
                  style={style}
                  component={StyleButton}
                />
              ))}
            </ButtonGroup>
          </Label>
        </Col>
        <Col xs={5} style={{ paddingLeft: 0 }}>
          <Label>
            Text
            <ButtonGroup fill>
              {colorButtons.map(el => (
                <Field
                  key={el.id}
                  name={`rowDefs[${index}].frontOpts.styles.frontColor`}
                  el={el}
                  component={ColorButton}
                />
              ))}
            </ButtonGroup>
          </Label>
        </Col>
      </Row>
    </Grid>
  )
}

export default StylingPalette
