// @ts-nocheck
import { Callout, FormGroup, InputGroup } from "@blueprintjs/core"
import { Field } from "formik"

import { SCIENT_COLORS } from "../../../../styled/scientColors"

// IN NGTF FormulaBuilder, errors are managed in ParamComponent
export const FormikFloatInput = ({ name, labelProps, ...props }) => {
  if (labelProps) {
    return (
      <Field name={name}>
        {({ field: { name, value, onChange }, form: { touched, errors } }) => {
          const handleChange = ({ target: { value } }) => {
            const allowed = /^-?[0-9]+\.?[0-9]*$/g
            if (value === "" || value === "-" || allowed.test(value)) {
              return onChange({ target: { name, value } })
            }
          }
          return (
            <FormGroup {...labelProps}>
              <InputGroup
                name={name}
                type="text"
                value={value}
                onChange={handleChange}
                fill
                {...props}
              />
              {touched[name] && errors[name] && (
                <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                  {errors[name]}
                </Callout>
              )}
            </FormGroup>
          )
        }}
      </Field>
    )
  }

  return (
    <Field name={name}>
      {({ field: { name, value, onChange }, form: { touched, errors } }) => {
        const handleChange = ({ target: { value } }) => {
          const allowed = /^-?[0-9]+\.?[0-9]*$/g
          if (value === "" || value === "-" || allowed.test(value)) {
            return onChange({ target: { name, value } })
          }
        }
        return (
          <>
            <InputGroup
              name={name}
              type="text"
              value={value}
              onChange={handleChange}
              fill
              {...props}
            />
            {touched[name] && errors[name] && (
              <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                {errors[name]}
              </Callout>
            )}
          </>
        )
      }}
    </Field>
  )
}

export const FloatArrayInput = ({ name, labelProps, ...props }) => {
  if (labelProps) {
    return (
      <Field name={name}>
        {({ field: { name, value, onChange }, form: { touched, errors } }) => {
          const handleChange = ({ target: { value } }) => {
            const allowed = /^[0-9. ,-]+$/g
            if (value === "" || value === "-" || allowed.test(value)) {
              return onChange({ target: { name, value } })
            }
          }
          return (
            <FormGroup {...labelProps}>
              <InputGroup
                name={name}
                type="text"
                value={value}
                onChange={handleChange}
                fill
                {...props}
              />
              {touched[name] && errors[name] && (
                <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                  {errors[name]}
                </Callout>
              )}
            </FormGroup>
          )
        }}
      </Field>
    )
  }

  return (
    <Field name={name}>
      {({ field: { name, value, onChange }, form: { touched, errors } }) => {
        const handleChange = ({ target: { value } }) => {
          const allowed = /^[0-9. ,-]+$/g
          if (value === "" || value === "-" || allowed.test(value)) {
            return onChange({ target: { name, value } })
          }
        }
        return (
          <>
            <InputGroup
              name={name}
              type="text"
              value={value}
              onChange={handleChange}
              fill
              {...props}
            />
            {touched[name] && errors[name] && (
              <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                {errors[name]}
              </Callout>
            )}
          </>
        )
      }}
    </Field>
  )
}
