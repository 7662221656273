// @ts-nocheck
import * as R from "ramda"

const pathToReach = indexList =>
  indexList.reduce((p, c, i, a) => {
    return i < a.length - 1 ? p.concat(c, "children") : p.concat(c)
  }, [])

const addExpandedToTarget = (acc, el, index, indexList) => {
  if (index === 0) {
    return R.assocPath([el, "expanded"], true, acc)
  }
  const sliced = R.slice(0, index + 1, indexList)
  const slicedWChildren = pathToReach(sliced)
  return R.over(R.lensPath(slicedWChildren), R.assoc("expanded", true), acc)
}

export const addExpanded = (rowId, rowTree) => {
  if (rowId === -1) {
    return rowTree
  }
  // Identifie number in rowId and put them in a list
  const regex = /(\d+)/g
  // remove the last element which is the single wanted and doesn't need to be expanded
  const indexList = R.init(R.map(e => parseInt(e), rowId.match(regex)))

  return indexList.reduce(addExpandedToTarget, rowTree)
}
