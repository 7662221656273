// @ts-nocheck
import React from "react"

import { Field } from "formik"
import { FormGroup, HTMLSelect } from "@blueprintjs/core"

export const FormikSelect = ({ name, labelProps, options }) => {
  return (
    <Field name={name}>
      {({ field: { name, value, onChange } }) => {
        const Select = () => (
          <HTMLSelect fill name={name} value={value} onChange={onChange} options={options} />
        )
        if (labelProps) {
          return (
            <FormGroup label={labelProps.label} labelFor="text-input">
              <Select />
            </FormGroup>
          )
        }
        return <Select />
      }}
    </Field>
  )
}

// export const Select = ({ noPadding, labelProps, selectProps: { value = "", ...rest } }) => {
//   const Wrapped = () => <HTMLSelect value={value || ""} {...rest} />

//   if (labelProps) {
//     return (
//       <FormGroup
//         // helperText="Helper text with details..."
//         // label="Label A"
//         // labelFor="text-input"
//         // labelInfo="(required)"
//         {...labelProps}
//       >
//         <Wrapped />
//       </FormGroup>
//     )
//   }
//   if (noPadding) {
//     return (
//       <div>
//         <Wrapped noPadding />
//       </div>
//     )
//   }

//   return (
//     <div className="sct-side-item">
//       <Wrapped />
//     </div>
//   )
// }
