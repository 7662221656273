// @ts-nocheck
import { Button, ButtonGroup, Dialog, Tooltip } from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"

import { formatDataForQuickChart } from "../utils"
import QuickChartContent from "./QuickChartContent"

const QuickChart = ({ openQuickChart, setOpenQuickChart, colTitles, rowData, rowDefs }) => {
  const [fullScreen, setFullScreen] = useState(false)
  const toggleFullScreen = () => setFullScreen(!fullScreen)

  const [chartType, setChartType] = useState("line")

  const { show, rowId, name } = openQuickChart
  const row = R.find(R.pathEq(["extra", "rowId"], rowId), rowData)

  const series = formatDataForQuickChart(colTitles, row, rowDefs, name)

  const format = R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.pct
    ? "{value}%"
    : "{value}"

  const handleCancel = () => {
    setOpenQuickChart({ show: false })
  }

  const style = fullScreen ? { width: "100vw", height: "90vh" } : { height: "100%", width: "50%" }

  return (
    <Dialog
      id="container"
      className="bp5-dark"
      icon="chart"
      title="Quick Chart"
      intent="primary"
      isOpen={show}
      onClose={handleCancel}
      style={style}
      enforceFocus={true}
    >
      <div className="bp5-dialog-header">
        <ButtonGroup minimal active={chartType}>
          <Button text="Line" icon="timeline-line-chart" onClick={() => setChartType("line")} />
          <Button text="Bar" icon="timeline-bar-chart" onClick={() => setChartType("column")} />
          <Button text="Area" icon="timeline-area-chart" onClick={() => setChartType("area")} />
        </ButtonGroup>

        <div style={{ marginLeft: "auto" }}>
          <Tooltip
            content={fullScreen ? "Hide full screen" : "Full screen"}
            placement="left"
            openOnTargetFocus={false}
            compact
          >
            <Button icon="fullscreen" onClick={toggleFullScreen} />
          </Tooltip>
        </div>
      </div>
      <div className="bp5-dialog-content">
        <QuickChartContent
          series={series}
          format={format}
          name={name}
          fullScreen={fullScreen}
          chartType={chartType}
        />
      </div>
    </Dialog>
  )
}

export default QuickChart
