import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { ShadowContainer } from "../../../../styled/layout.styled"

type IdeaCreateTextAreaContainerProps = {
  hidden: boolean
}

export const IdeaCreateTextAreaContainer = styled(ShadowContainer)(
  (props: IdeaCreateTextAreaContainerProps) => ({
    height: "100%",
    display: props.hidden ? "none" : "flex",
  }),
)

export const ideaCreateTextAreaCss = css({
  width: "100%",
  height: "100% !important",
  resize: "none",
  fontSize: "18px",
})
