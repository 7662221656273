// @ts-nocheck
import * as R from "ramda"

import { SCIENT_COLORS } from "../../../../../../../../../../styled/scientColors"
import { getRowTreeSingleName } from "../../../utils"

export const OperationCategory = {
  rowdef: 0,
  dataSelection: 1,
  dataManipulation: 2,
  resampling: 3,
  missingDataManagement: 4,
  math: 5,
  valuations: 6,
  dataDescription: 8,
  prediction: 9,
}

const SHIFT_PERIODS = {
  id: "shift_periods",
  title: "Shift Periods",
  description: "Number of period to shift on (>0 for lead, <0 for lag).",
  type: "INTEGER",
  show: true,
  default_value: 0,
  optional: true,
}

const SHIFT_FREQ = {
  id: "shift_freq",
  title: "Shift Freq",
  description: "Frequency of the shift to combine with the number of periods.",
  type: "period_freq",
  show: true,
  optional: true,
  default_value: "",
  choices: ["D", "W", "M", "Y"],
}

const getRowdefNode = ({ header, enhancedPath, formula_tree }) => {
  return {
    category: OperationCategory.rowdef,
    type: "rowdef",
    title: getRowTreeSingleName({ header, enhancedPath, formula_tree }),
    subtitle: "Output",
    description: "Transformed data that will be sent to the table rowdef.",
    show: false,
    locked: true,
    inputs: [
      {
        id: "input",
        title: "Output connector",
        description: "Where you connect the end of your tree of data transformations.",
        param_type: "TIMESERIES",
        connectable: true,
        many: false,
      },
    ],
    style: { background: SCIENT_COLORS.black },
  }
}
const absolutSourceNode = {
  category: OperationCategory.dataSelection,
  pos: 0,
  type: "select",
  title: "Stock timeSeries",
  description: "Use this node to add any stocks times series.",
  show: true,
  params: [
    {
      id: "source",
      title: "Source",
      description: "The source of time based data for stocks.",
      type: "SourceSelect",
      show: true,
      default_value: "",
      optional: false,
    },
    {
      id: "path",
      title: "Field",
      description: "The specific field you want to diplay for the selected source.",
      type: "FieldSelect",
      show: {
        how: "ALL",
        checks: [
          ["filled", "source"],
          ["source", "stock"],
        ],
      },
      default_value: "",
      optional: false,
    },
    SHIFT_PERIODS,
    SHIFT_FREQ,
  ],
  outputs: [
    {
      id: "output",
      param_type: "TIMESERIES",
      title: "Output",
      description: "Connect this to another transform or to the main output.",
      connectable: true,
      many: false,
    },
  ],
  style: { background: SCIENT_COLORS.orange1 },
}
const dsSourceNode = {
  category: OperationCategory.dataSelection,
  pos: 3,
  type: "selectds",
  title: "DataSet timeSeries",
  description: "Use this node to add any datasets, macro-economic and factors time series.",
  show: true,
  params: [
    {
      id: "source",
      title: "Source",
      description: "The source of time based data for dataSets.",
      type: "DsSelect",
      show: true,
      default_value: "",
      optional: false,
    },
    SHIFT_PERIODS,
    SHIFT_FREQ,
  ],
  outputs: [
    {
      id: "output",
      param_type: "TIMESERIES",
      title: "Output",
      description: "Connect this to another transform or to the main output.",
      connectable: true,
      many: false,
    },
  ],
  style: { background: SCIENT_COLORS.orange3 },
}

export const getStSourceNodes = ({ st_sources, rf_sources }) => {
  const dynamicParams = R.isEmpty(st_sources)
    ? {
        show: false,
        title: "Primary source timeSeries",
        subtitle: "",
      }
    : {
        show: true,
        title: !R.isEmpty(rf_sources)
          ? `Primary source (${rf_sources[0].name})`
          : `Primary source (${st_sources[0].name})`,
        subtitle: "TimeSeries",
      }

  return [
    {
      category: OperationCategory.dataSelection,
      pos: 1,
      description:
        "Use this node to add field based on primary source (segments fields are not exposed in primary source node).",
      type: "selectstsrc",
      params: [
        {
          id: "source",
          title: "Source",
          description: "The primary source",
          type: "SourceSelect",
          show: false,
          default_value: "stsrc0",
        },
        {
          id: "path",
          title: "Field",
          description:
            "Choose any field you want to display for the primary source (segments fields are not exposed in primary source node).",
          type: "FieldSelect",
          show: true,
          default_value: "",
          optional: false,
        },
        SHIFT_PERIODS,
        SHIFT_FREQ,
      ],
      outputs: [
        {
          id: "output",
          param_type: "TIMESERIES",
          title: "Output",
          description: "Connect this to another transform or to the main output.",
          connectable: true,
          many: false,
        },
      ],
      style: { background: SCIENT_COLORS.orange2 },
      ...dynamicParams,
    },
  ]
}

export const getEndloopNodes = ({ enhancedPath }) => {
  const { segs, fields, srcName } = enhancedPath

  if (!R.isNil(segs)) {
    return [
      {
        category: OperationCategory.dataSelection,
        pos: 2,
        show: true,
        title: `${srcName} segment timeSeries`,
        subtitle: "Dynamically generated segment time series",
        description: `Use this node to pull data from ${srcName} segments.`,
        type: "endloopseg",
        params: [
          {
            id: "path",
            title: "Field",
            description: "Choose any field you want to display for the looping source.",
            type: "FieldSelect",
            show: true,
            default_value: "",
            optional: false,
          },
          SHIFT_PERIODS,
          SHIFT_FREQ,
        ],
        outputs: [
          {
            id: "output",
            title: "Output",
            description: "Connect this to another transform or to the main output.",
            param_type: "TIMESERIES",
            connectable: true,
            many: false,
          },
        ],
        style: { background: SCIENT_COLORS.orange3 },
      },
    ]
  }

  if (!R.isNil(fields)) {
    return [
      {
        show: true,
        title: `${srcName} custom fields`,
        description: `Use this node to pull data from ${srcName} custom fields.`,
        category: 1,
        type: "endloopfield",
        outputs: [
          {
            id: "output",
            title: "Output",
            description: "Connect this to another transform or to the main output.",
            param_type: "TIMESERIES",
            connectable: true,
            many: false,
          },
        ],
        style: { background: SCIENT_COLORS.orange3 },
        params: [
          {
            id: "path",
            title: "Field",
            type: "FieldSelect",
            show: false,
            default_value: [],
            optional: false,
          },
        ],
      },
    ]
  }

  return []
}

const dskNodeDefs = ({ header, enhancedPath, formula_tree, st_sources, rf_sources }) => {
  return [
    getRowdefNode({ header, enhancedPath, formula_tree }),
    absolutSourceNode,
    dsSourceNode,
    ...getStSourceNodes({ st_sources, rf_sources }),
    ...getEndloopNodes({ enhancedPath, st_sources }),
  ]
}

export default dskNodeDefs
