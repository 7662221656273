// @ts-nocheck
import React, { useState, useEffect } from "react"
import { propEq, reject } from "ramda"
import * as R from "ramda"
import { Callout, H5, Tab, Tabs, Button, ButtonGroup } from "@blueprintjs/core"

import { mainTabs } from "./utils"
import { slugToName } from "../../utils"
import { NonFieldErrors } from "../../../../components/formik"
import { Grid, Row, Col } from "../../../../components"

const Header = ({
  tabId,
  setTabId,
  subTabId,
  setSubTabId,
  report,
  prevReport,
  prevYearReport,
  handleClose,
  isSubmitting,
  handleSubmit,
  status,
  dates,
}) => {
  const [preventTabNav, setpreventTabNav] = useState(true)
  useEffect(() => {
    if (
      dates.year < 1970 ||
      dates.year > 2100 ||
      dates.period === "" ||
      dates.start === "" ||
      dates.start === "Invalid dateT00:00Z" ||
      dates.end === "" ||
      dates.end === "Invalid dateT00:00Z" ||
      dates.release_date === "" ||
      dates.release_date === "Invalid dateT00:00Z"
    ) {
      setpreventTabNav(true)
    } else {
      setpreventTabNav(false)
    }
  }, [dates.year, dates.period, dates.start, dates.end, dates.release_date])

  const reportClean = R.compose(
    R.when(R.propEq("currencies", []), R.dissoc("currencies")),
    R.when(R.propEq("geos", []), R.dissoc("geos")),
    R.when(R.propEq("fields", []), R.dissoc("fields")),
  )(report)

  const isTabButNoData = x =>
    (propEq("id", "currencySplit", x) && !reportClean.currencies) ||
    (propEq("id", "geoSplit", x) && !reportClean.geos) ||
    (propEq("id", "customFields", x) && !reportClean.fields)

  const validTabs = reject(isTabButNoData, mainTabs)

  return (
    <Grid fluid>
      <Row>
        <Col xs={10}>
          <Tabs animate id="navbar" large onChange={setTabId} selectedTabId={tabId}>
            {validTabs.map(ti => (
              <Tab key={ti.id} {...ti} disabled={preventTabNav} />
            ))}
          </Tabs>
          {tabId.includes("shared") && (
            <Tabs animate id="subNavbar" onChange={setSubTabId} selectedTabId={subTabId}>
              <Tab id="default" title="Values" />
              <Tab disabled title="Growth components:" />
              <Tab id="growth;group" title="Group" />
              {report.splits !== undefined &&
                report.splits.map(split => (
                  <Tab
                    key={split.slug}
                    id={`growth;split;${split.slug}`}
                    title={slugToName(split.slug)}
                  />
                ))}
            </Tabs>
          )}
          {/* {tabId.includes("profit&backlog") && (
            <Tabs animate id="subNavbar" onChange={setSubTabId} selectedTabId={subTabId}>
              <Tab id="default" title="Profit" />
              <Tab id="backlog" title="Backlog" />
            </Tabs>
          )} */}
        </Col>
        <Col xs={2}>
          <ButtonGroup fill>
            <Button icon="chevron-left" text="Back" onClick={handleClose} />
            <Button
              intent="primary"
              rightIcon="floppy-disk"
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
              text={isSubmitting ? "Sending..." : "Save"}
            />
          </ButtonGroup>
        </Col>
      </Row>
      <Row style={{}}>
        <Col xs={12}>
          {preventTabNav && <Callout intent="warning" title="Please complete all date's fields" />}
          <NonFieldErrors status={status} />
        </Col>
      </Row>

      <Titles
        reportSlug={report.slug}
        prevReportSlug={prevReport.slug}
        prevYearReportSlug={prevYearReport.slug}
      />
    </Grid>
  )
}

export default Header

const Titles = ({ reportSlug, prevYearReportSlug, prevReportSlug }) => (
  <Row
    style={{
      textAlign: "right",
      paddingBottom: "8px",
      borderBottom: "1px solid rgba(0, 0, 50, 0.3)",
    }}
  >
    <Col xsOffset={4} xs={2}>
      <H5 style={{ marginRight: "8px" }}>{prevYearReportSlug.toUpperCase()}</H5>
    </Col>
    <Col xs={2}>
      <H5 style={{ marginRight: "8px" }}>{prevReportSlug.toUpperCase()}</H5>
    </Col>
    <Col xs={3}>
      <H5 style={{ marginRight: "8px" }}>{reportSlug.toUpperCase() || "New"}</H5>
    </Col>
  </Row>
)
