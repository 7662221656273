/** @jsxImportSource @emotion/react */
import { addHours, addMinutes, isAfter } from "date-fns"
import { UseFormWatch } from "react-hook-form"

import { FlexContainerFullHeight } from "./Components.styled"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"
import { WaveInputs } from "../types/form"
import { IBaseInputProps } from "../types/common"

interface ITimeInputProps extends IBaseInputProps {
  isEmptyRowState: boolean
  watch: UseFormWatch<WaveInputs>
  trigger?: () => void
}
/**
 * Very specific component to use with React Hook Form for target end time of an order.
 * User selects time in their local time zone and cannot select a time outside of the current day.
 */

export const TimeInput = ({
  name,
  register,
  customCss,
  error,
  watch,
  index,
  isEmptyRowState,
  trigger,
}: ITimeInputProps) => {
  /**
   * minTime and maxTime takes the time when the submit button is pressed
   * and not when the page is opened.
   */

  const minTime = addMinutes(new Date(), 5).toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  })

  // Logic to set the maximum time to 8 hours from the current time for the field target_end_datetime
  const maxTime = addHours(new Date(), 8)
  // Retrieve end time of current day
  const endOfCurrentDay = new Date()
  endOfCurrentDay.setHours(23, 59, 59)
  // check if maxTime is before end of day. If yes choose this maxTime else choose end of day time.
  const maxTimeWithinCurrentDay = isAfter(maxTime, new Date().setHours(23, 59, 59))
    ? endOfCurrentDay
    : maxTime
  // Format the maximum time as a string with the desired format
  const maxTimeInCurrentDay = maxTimeWithinCurrentDay.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  })
  return (
    <FlexContainerFullHeight alignItems="center" justifyContent="center" alignContent="center">
      <input
        type="time"
        {...register(name, {
          min: {
            value: minTime,
            message: "Target end should not be less than 5 minutes from wave submission time",
          },
          max: {
            value: maxTimeInCurrentDay,
            message:
              "Target end should not be more than 8 hours from the wave submission time and must be in the same day",
          },
          validate: value => {
            const timeInMarketValue = watch(`orders[${index}].time_in_market`)
            if (!isEmptyRowState) {
              if (value && timeInMarketValue) {
                // If time is filled and target is empty, return error message
                return "Target End should be empty if Time in Market is filled"
              }
              if (value && value < minTime) {
                return "Target end should not be less than 5 minutes from current time"
              }
              if (value && value > maxTimeInCurrentDay) {
                return "Target end should not be more than 8 hours from current time and not exeed the current day."
              }
            }
            // Validation passed
            return true
          },
          onChange: trigger,
        })}
        /**
         * Not allow some character used usually in numeric input
         */
        onKeyDown={event => {
          const forbiddenCharacters = ["e", "E", "+", "-"]
          if (forbiddenCharacters.includes(event.key)) event.preventDefault()
        }}
        css={customCss}
      />
      {error && <ErrorIconWithTooltip errorMessage={error.message} />}
    </FlexContainerFullHeight>
  )
}
