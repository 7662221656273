// @ts-nocheck
import { combineEpics } from "redux-observable"
import searchDSForecastsEpic from "./searchDSForecastsEpic"
import searchDataSetsEpic from "./searchDataSetsEpic"
import searchOmniByIdsEpic from "./searchOmniByIdsEpic"
import searchOmniByNameEpic from "./searchOmniByNameEpic"
import searchRForecastsEpic from "./searchRForecastsEpic"
import searchStocksEpic from "./searchStocksEpic"

const searchEpics = combineEpics(
  searchDataSetsEpic,
  searchDSForecastsEpic,
  searchRForecastsEpic,
  searchStocksEpic,
  searchOmniByIdsEpic,
  searchOmniByNameEpic,
)

export default searchEpics
