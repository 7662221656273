import { useQuery } from "@tanstack/react-query"
import { useApi } from "./useApi"

export const useQueryProfile = () => {
  const { scientApi, apiAuthenticated } = useApi()
  const query = useQuery({
    queryKey: ["profile"],
    queryFn: scientApi.profile.get,
    staleTime: Infinity,
    // Profile fetching does not require api2FAVerified
    enabled: apiAuthenticated,
  })

  /**
   * Patching isLoading to be false if fetchStatus is idle
   * cc https://github.com/TanStack/query/issues/3584
   */
  return { ...query, isLoading: query.isLoading && query.fetchStatus !== "idle" }
}
