// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import { fetchAnalyses, fetchAnalysesOk, fetchAnalysesError } from "../../index"
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const fetchAnalysesEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(fetchAnalyses.type),
    mergeMap(() => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/analysis/`
      return ajax({
        url,
        method: "GET",
      }).pipe(
        map(({ response }) => fetchAnalysesOk({ analyses: response.results })),
        catchError(error => of(fetchAnalysesError({ error: error.xhr.response }))),
      )
    }),
  )

export default fetchAnalysesEpic
