// @ts-nocheck
import autodux from "autodux"
import * as R from "ramda"

const authDuck = autodux({
  slice: "auth",
  initial: {
    username: "",
    password: "",
    loginCheck: false,
    errors: {},
    token: null,
    tokenCheck: false,
    tokenErrors: {},
    groupList: {},
    userList: {},
    user: null,
  },
  actions: {
    login: (state, user) => ({
      ...state,
      loginCheck: false,
      user,
    }),
    verifyToken: (state, token) => ({
      ...state,
      tokenCheck: true,
      token,
    }),
    verifyTokenOk: (state, user) => ({
      ...state,
      tokenCheck: false,
      user,
    }),
    verifyTokenError: (state, tokenError) => ({
      ...authDuck.initial,
      tokenCheck: false,
      token: null,
      tokenError,
    }),

    logout: state => ({
      ...state,
      loginOut: true,
    }),
    logoutOk: () => ({
      ...authDuck.initial,
      token: null,
      user: null,
    }),

    fetchGroupList: state => R.compose(R.assoc("fetchingGroups", true), R.dissoc("error"))(state),
    fetchGroupListOk: (state, { groupList }) => ({
      ...state,
      fetchingGroups: false,
      groupList,
    }),
    fetchGroupListError: (state, { groupError }) => ({
      ...state,
      fetchingGroups: false,
      groupError,
    }),

    fetchUserList: state => R.compose(R.assoc("fetchingUsers", true), R.dissoc("error"))(state),
    fetchUserListOk: (state, { userList }) => {
      return {
        ...state,
        fetchingUsers: false,
        userList,
      }
    },
    fetchUserListError: (state, { userError }) => ({
      ...state,
      fetchingUsers: false,
      userError,
    }),
  },
})

export default authDuck
