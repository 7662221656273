// @ts-nocheck
import React from "react"
import { useDispatch } from "react-redux"
import { pathOr } from "ramda"

import profileDuck from "../../../../../../state/modules/profile"
import { defaultInitial } from "."
import AddEditDialog from "./AddEditDialog"

export const EditDialog = ({ stockSlug, tabDef, editModal, setEditModal }) => {
  const dispatch = useDispatch()

  const row = pathOr(defaultInitial, ["rowDefs", editModal.rowIndex], tabDef)

  const handleClose = () => {
    setEditModal(false)
  }
  const handleSubmit = row => {
    dispatch(
      profileDuck.actions.editLine({
        slug: stockSlug,
        tabId: tabDef.id,
        index: editModal.rowIndex,
        row,
      }),
    )
    setEditModal(false)
  }

  return (
    <AddEditDialog
      kind="Edit"
      stockSlug={stockSlug}
      possibleData={editModal}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initial={row}
    />
  )
}
