// @ts-nocheck
import React from "react"

import Dates from "./Dates"
import Shared from "./Shared"
import Backlog from "./Backlog"
import Profit from "./Profit"
import CustomFields from "./CustomFields"
import Currencies from "./Currencies"
import Geos from "./Geos"
import BS from "./BS"
import CF from "./CF"
import IS from "./IS"

const TabContent = ({ tabId, subTabId, handleChange, values, reportData }) => {
  if (tabId.includes("shared")) {
    const [, endField] = tabId.split(";")
    return (
      <Shared
        endField={endField}
        subTabId={subTabId}
        values={values}
        handleChange={handleChange}
        reportData={reportData}
      />
    )
  }
  if (tabId === "backlog") {
    return <Backlog values={values} handleChange={handleChange} reportData={reportData} />
  }
  if (tabId === "profit") {
    return <Profit values={values} handleChange={handleChange} reportData={reportData} />
  }
  if (tabId === "customFields") {
    return <CustomFields values={values} reportData={reportData} handleChange={handleChange} />
  }

  if (tabId === "geoSplit") {
    return (
      <Geos geos={reportData.report.geos} handleChange={handleChange} reportData={reportData} />
    )
  }

  if (tabId === "currencySplit") {
    return (
      <Currencies
        currencies={reportData.report.currencies}
        handleChange={handleChange}
        reportData={reportData}
        values={values}
      />
    )
  }

  if (tabId === "bs") {
    return <BS handleChange={handleChange} values={values} reportData={reportData} />
  }

  if (tabId === "cf") {
    return <CF handleChange={handleChange} values={values} reportData={reportData} />
  }

  if (tabId === "is") {
    return <IS handleChange={handleChange} values={values} reportData={reportData} />
  }

  return <Dates handleChange={handleChange} reportData={reportData} />
}

export default TabContent
