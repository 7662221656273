// @ts-nocheck
import {
  Button,
  Classes,
  Dialog,
  Divider,
  H4,
  OverlayToaster,
  Position,
  Toast2,
} from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"
import { DndProvider } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import { useDispatch, useSelector } from "react-redux"

import monitorsDuck from "../../../../../state/modules/monitors"
import { Col, InputGroup, Row } from "../../../../components"
import { useGetDeskOwnerName } from "../../utils"
import { AddSheet } from "./AddSheet"
import SheetsContainer from "./SheetsContainer"

export const EditMonitor = ({
  isOpen,
  toggleMonitorPrefs,
  desk,
  monitor,
  userId,
  hasWritePermission,
}) => {
  const [dialog, setDialog] = useState(false)
  const toggleDialog = () => setDialog(!dialog)

  const [save, setSave] = useState(true) // state to know if Monitor name has been modified
  const [name, setName] = useState(monitor.name)

  const sheetList = useSelector(R.pathOr({}, ["monitors", "data", monitor.id, "sheet_ids"]))
  const deskOwnerId = desk.owner_id
  const deskOwnerName = useGetDeskOwnerName(desk)

  const clearMonitorError = () =>
    dispatch(monitorsDuck.actions.clearMonitorError({ id: monitor.id }))

  const handleText = ({ target: { value } }) => {
    setName(value)
    setSave(false)
  }
  const handleCancelText = () => {
    setName(monitor.name)
    setSave(true)
  }

  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(
      monitorsDuck.actions.updateMonitor({
        ...monitor,
        name: name,
      }),
    )
    setSave(true)
  }

  const handleClose = () => {
    toggleMonitorPrefs(monitor.id)
    setName(monitor.name)
    setSave(true)
  }

  return (
    <Dialog
      title="Edit monitor"
      className="bp5-dark"
      icon="cog"
      isOpen={isOpen}
      onClose={handleClose}
      style={{ paddingBottom: "8px" }}
    >
      <OverlayToaster position={Position.RIGHT}>
        {monitor.error && (
          <Toast2
            message={monitor.error}
            intent={"danger"}
            onDismiss={() => clearMonitorError()}
            timeout={4000}
          />
        )}
      </OverlayToaster>

      <div className={Classes.DIALOG_BODY} style={{ margin: "10px" }}>
        {userId === deskOwnerId || hasWritePermission ? (
          <>
            <Row style={{ margin: "8px 0" }}>
              <Col xs={8}>
                <InputGroup
                  inputProps={{
                    value: name,
                    onChange: handleText,
                    onEnter: handleSubmit,
                    onEsc: handleCancelText,
                    placeholder: "Enter new name",
                  }}
                />
              </Col>

              <Col xs={4}>
                <Button
                  fill
                  icon={save ? "small-tick" : "floppy-disk"}
                  text="Edit name"
                  intent={save ? null : "primary"}
                  onClick={handleSubmit}
                  disabled={save}
                />
              </Col>
            </Row>
            <Divider />

            <H4 style={{ padding: "8px 8px 0", margin: "0" }}>Sheets</H4>
            <DndProvider debugMode={true} backend={HTML5Backend}>
              <SheetsContainer
                desk={desk}
                monitor={monitor}
                sheetList={sheetList}
                userId={userId}
                deskOwnerId={deskOwnerId}
              />
            </DndProvider>

            <Row style={{ margin: "16px 0 8px" }}>
              <Col xs>
                <AddSheet monitor={monitor} isOpen={dialog} toggleDialog={toggleDialog} />
                <Button fill icon="plus" text="Add sheet" onClick={toggleDialog} intent="primary" />
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col xs>
              <p style={{ fontSize: "14px" }}>Edit is not allowed. You have read-only access.</p>
              <p style={{ fontSize: "14px" }}>Owner: {deskOwnerName}</p>
            </Col>
          </Row>
        )}
      </div>
    </Dialog>
  )
}
