// @ts-nocheck
import { Button, ControlGroup, Popover, Tag } from "@blueprintjs/core"

export const Prev = ({ commentValue, value }) => {
  return (
    <ControlGroup>
      <Popover placement="bottom-end">
        <Button
          intent={commentValue ? "primary" : null}
          tabIndex={-1}
          disabled={!commentValue}
          icon="comment"
          active={true}
        />
        {commentValue && (
          <div style={{ width: "300px" }}>
            <p>{commentValue}</p>
            <Button intent="primary" fill className="bp5-popover-dismiss">
              Close
            </Button>
          </div>
        )}
      </Popover>
      <Tag large fill minimal style={{ textAlign: "right" }}>
        {value}
      </Tag>
    </ControlGroup>
  )
}
