// @ts-nocheck
import * as R from "ramda"

export const fetchSheetList = R.compose(R.assoc("ready", false), R.dissoc("error"))

export const fetchSheetListOk = (state, list) => ({ list, ready: true })

export const fetchSheetListError = (state, { error }) => ({ ...state, error, ready: true })

export const fetchSheetDetail = (state, { id }) =>
  R.set(R.lensPath(["list", id, "ready"]), false, state)
export const fetchSheetDetailOk = (state, data) =>
  R.compose(
    R.over(R.lensProp("outputs"), R.assoc(data.id, data.output)),
    R.over(
      R.lensPath(["list", data.id]),
      R.mergeDeepLeft(R.compose(R.assoc("ready", true), R.dissoc("output"))(data)),
    ),
  )(state)
export const fetchSheetDetailError = (state, { id, error }) =>
  R.over(R.lensPath(["list", id]), R.mergeDeepLeft({ error, ready: true }), state)

export const updateSheet = (state, payload) =>
  R.over(
    R.lensPath(["list", payload.id]),
    R.compose(R.assoc("ready", false), R.dissoc("error")),
    state,
  )
export const updateSheetOk = (state, payload) =>
  R.compose(
    R.over(
      R.lensPath(["list", payload.id]),
      R.compose(R.assoc("ready", true), R.mergeDeepLeft(R.dissoc("output", payload))),
    ),
    R.set(R.lensPath(["outputs", payload.id]), payload.output),
  )(state)
export const updateSheetError = (state, { id, error }) =>
  R.over(
    R.lensPath(["list", id]),
    R.compose(R.assoc("ready", true), R.assoc("error", error)),
    state,
  )

export const createSheet = state => {
  return R.compose(R.dissoc("error"))(state)
}
export const createSheetOk = (state, payload) =>
  R.compose(R.assocPath(["list", payload.id], payload))(state)
export const createSheetError = (state, error) => R.compose(R.assoc("error", error))(state)

export const deleteSheet = (state, { id }) => R.set(R.lensPath(["list", id, "ready"]), false, state)
export const deleteSheetOk = (state, id) => R.dissocPath(["list", id], state)
export const deleteSheetError = (state, payload) =>
  R.compose(
    R.set(R.lensPath(["list", payload.id, "ready"]), true),
    R.assoc("error", payload.error),
  )(state)

export const clearSheetError = (state, { id }) => {
  return R.over(R.lensPath(["list", id]), R.dissoc("error"), state)
}

export const cancelFetchSheetDetail = state => state
