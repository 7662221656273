// @ts-nocheck
import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const LoopBuilderStyled = styled.div({
  marginTop: "24px",
})

export const HelperStyled = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  marginTop: "8px",
})

export const TextInfoStyled = styled.p({
  textAlign: "center",
})

export const h3Css = css({
  textAlign: "center",
  marginTop: "24px",
  marginBottom: "16px",
})

export const h5Css = css({
  margin: "0 10px",
})
export const collapseCss = css({
  marginTop: "32px",
})
