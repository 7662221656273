import { AxiosInstance, AxiosResponse } from "axios"

export interface IDesksApi {
  sheets: {
    detail: {
      update: ({ values }: any) => Promise<AxiosResponse<any, any>>
    }
  }
}

/**
 * Api routes for /api/v1/desks/
 */
const desks = (axiosClient: AxiosInstance): IDesksApi => ({
  /**
   * PATCH /api/v1/desks/sheets/{id}/
   */
  sheets: {
    detail: {
      update: ({ values }: any) => axiosClient.patch(`/desks/sheets/${values.id}/`, values),
    },
  },
})

export default desks
