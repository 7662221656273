/** @jsxImportSource @emotion/react */

import { Button } from "@blueprintjs/core"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { LargeText, MediumItalicText } from "../../../styled/text.styled"
import { OTPMachineState } from "../../AuthProvider"
import { ScientLogoNew } from "../../components/ScientLogo/ScientLogoNew"
import { useAuth } from "../../hooks"
import { loginSubmitButtonCss, TransitionContainer } from "./Login.styled"
import { useApi } from "../../hooks/useApi"
import { toaster } from "../../utils/createToaster"
import { useCallback } from "react"

const SuggestOTP = () => {
  const { setOtpMachineState } = useAuth()
  const { setApi2FAVerified } = useApi()

  const continueWithout2FASetup = useCallback(() => {
    if (process.env.REACT_APP_ENFORCE_TWO_FACTOR_AUTH !== "true") {
      setOtpMachineState && setOtpMachineState(OTPMachineState.OK)
      setApi2FAVerified && setApi2FAVerified(true)
    } else {
      toaster.show({
        message: "You must enable 2FA to continue.",
        intent: "danger",
      })
    }
  }, [setApi2FAVerified, setOtpMachineState])

  return (
    <TransitionContainer flexDirection="column" alignItems="center" gap="20px">
      <FlexContainer flexDirection="column" gap="15px" alignItems="center">
        <ScientLogoNew height="60px" />
        <LargeText color="#2e58a5">Two Factor Authentication</LargeText>
        <MediumItalicText color="#2e58a5">
          Important Update: To further secure your account, we will be making two-factor
          authentication (2FA) mandatory for all users by the end of July. You have the option to
          enable it now.
        </MediumItalicText>
        <MediumItalicText color="#2e58a5">
          Enabling 2FA adds an extra layer of security, ensuring that you're the only person who can
          access your account, even if someone knows your password. Feel free to continue logging in
          as usual until the enforcement date. We highly recommend setting up 2FA sooner to enjoy
          enhanced security for your account.
        </MediumItalicText>
      </FlexContainer>
      <FlexContainer gap="40px">
        <Button
          fill
          intent="success"
          rightIcon="lock"
          type="submit"
          css={loginSubmitButtonCss}
          onClick={() => setOtpMachineState && setOtpMachineState(OTPMachineState.SETUP_OTP)}
        >
          Set up 2FA
        </Button>
        <Button
          fill
          intent="primary"
          rightIcon="log-in"
          type="submit"
          css={loginSubmitButtonCss}
          onClick={() => continueWithout2FASetup()}
        >
          Continue
        </Button>
      </FlexContainer>
    </TransitionContainer>
  )
}

export default SuggestOTP
