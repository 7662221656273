// @ts-nocheck
import React from "react"
// import { Intent } from "@blueprintjs/core"
import { Cell } from "@blueprintjs/table"

import { formatDateTime } from "./formatDate"
import { formatNumber } from "."

const deepVal = (obj, myPath) => {
  return myPath.split(";").reduce((res, prop) => res[prop], obj)
}

export const dateRenderer = (rowIdx, path, list) => (
  <Cell>{formatDateTime(deepVal(list[rowIdx], path))}</Cell>
)

export const numStyle = { textAlign: "right" }

const colorCell = value => {
  const augmentedValue = Math.abs(value)
  switch (true) {
    case value > 0:
      return { ...numStyle, background: `rgba(0, 200, 50, ${augmentedValue})` }
    case value < 0:
      return { ...numStyle, background: `rgba(200, 25, 25, ${augmentedValue})` }
    default:
      return numStyle
  }
}

export const colorPctRenderer = (rowIdx, path, list) => {
  const value = deepVal(list[rowIdx], path)
  return <Cell style={colorCell(value)}>{formatNumber(value, true)}</Cell>
}

export const fieldRenderer = (rowIdx, path, list) => <Cell>{deepVal(list[rowIdx], path)}</Cell>

export const cw = {
  name: 650,
  chg: 100,
  date: 170,
}
