// @ts-nocheck
import { map } from "rxjs/operators"
import { ofType } from "redux-observable"

import dataSetsDuck from "../index"
import entriesDuck from "../../entries"

const {
  actions: { fetchDataSetOk },
} = dataSetsDuck

const {
  actions: { fetchEntries },
} = entriesDuck

const fetchDataSetEpic = action$ =>
  action$.pipe(
    ofType(fetchDataSetOk.type),
    map(({ payload }) => fetchEntries({ periodicity: payload.periodicity, dataSetId: payload.id })),
  )

export default fetchDataSetEpic
