/** @jsxImportSource @emotion/react */

import { Button } from "@blueprintjs/core"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { useAuth } from "../../hooks"
import { ResetLink } from "../PasswordReset/PasswordResetConfirm.styled"
import { loginSubmitButtonCss } from "./Login.styled"
import TextInput from "./LoginTextInput"

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("This field is required"),
  password: Yup.string()
    .min(8, "Too Short!")
    .max(64, "Too Long!")
    .required("This field is required"),
})

const LoginForm = () => {
  const { login, isLoadingLogin } = useAuth()

  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validationSchema={LoginSchema}
      onSubmit={async ({ username, password }) => {
        login && login({ username, password })
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Form style={{ width: "100%" }}>
        <FlexContainer flexDirection="column" gap="20px">
          <FlexContainer flexDirection="column" gap="10px">
            <Field name="username" component={TextInput} placeholder="Username" />
            <Field name="password" component={TextInput} placeholder="Password" type="password" />
          </FlexContainer>
          <Button
            fill
            intent="primary"
            rightIcon="log-in"
            type="submit"
            disabled={isLoadingLogin}
            css={loginSubmitButtonCss}
          >
            {isLoadingLogin ? "Loading..." : "Log in"}
          </Button>
          <FlexContainer flexDirection="column" gap="10px" alignItems="center">
            <ResetLink href="/reset-password/request">Forgot your password?</ResetLink>
          </FlexContainer>
        </FlexContainer>
      </Form>
    </Formik>
  )
}

export default LoginForm
