// @ts-nocheck
import { sales } from "./sales"
import { salesGrowth } from "./salesGrowth"
import { orders } from "./orders"

const consensus = { id: "consensus", title: "Consensus" }

export const userstockconfigDefaults = {
  ready: false,
  freq: "all",
  showEdit: false,
  showInactive: false,
  showEmpty: false,
  activeTab: "sales",
  availableTabs: ["sales", "salesGrowth", "orders", "consensus"],
  defaultTabs: { sales, salesGrowth, orders, consensus },

  consensusFields: "sales",
  diffMethod: "value",
  thresholdDays: 30,
  starred: [],
  excluded: [],
}
