import useInfiniteWaves from "./useInfiniteWaves"
import { AxiosError } from "axios"
import { useApi } from "../../../../hooks/useApi"
import { useMutation } from "@tanstack/react-query"
import { Wave } from "../types/wave"

const useCreateWaveMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (wave: Wave) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.trading.create, { onSuccess, onError })
}

export { useInfiniteWaves, useCreateWaveMutation }
