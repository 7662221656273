// @ts-nocheck
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Icon, Spinner } from "@blueprintjs/core"
import * as R from "ramda"

import desksDuck from "../../../state/modules/desks/index"
import monitorsDuck from "../../../state/modules/monitors"
import sheetsDuck from "../../../state/modules/sheets"
import { fetchFieldList } from "../../../state/modules/shared"
import UserDesks from "./UserDesks"
import RemoveUserDesksNotShared from "./UserDesks/RemoveUserDesksNotShared"
import { Outlet } from "react-router-dom"
import NoUserDesk from "./NoUserDesk"

const Desks = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(desksDuck.actions.fetchUserDesks())
    dispatch(desksDuck.actions.fetchDesks())
    dispatch(monitorsDuck.actions.fetchMonitorList())
    dispatch(sheetsDuck.actions.fetchSheetList())
    dispatch(fetchFieldList())
  }, [dispatch])

  const userDesks = useSelector(R.pathOr({}, ["desks", "userDesks"]))
  const userDesksReady = R.prop("ready", userDesks)
  const userDesksFetched = R.has("data", userDesks)

  const desks = useSelector(R.pathOr({}, ["desks", "desks"]))
  const desksReady = R.prop("ready", desks)
  const desksFetched = R.has("data", desks)

  const monitors = useSelector(R.propOr({}, ["monitors"]))
  const monitorsReady = R.prop("ready", monitors)
  const monitorsFetched = R.has("data", monitors)

  const sheets = useSelector(R.propOr({}, ["sheets"]))
  const sheetsReady = R.prop("ready", sheets)
  const sheetsFetched = R.has("list", sheets)

  const shared = useSelector(R.propOr({}, ["shared"]))
  const sharedReady = R.prop("ready", shared)
  const sharedFetched = R.has("list", shared)

  const Loader = () => (
    <div className="content">
      <div className="sct-full sct-loader">
        <div>
          <Spinner />
          <p>Fetching userDesks... {userDesksReady && <Icon icon={"tick"} />}</p>
          <p>Fetching desks... {desksReady && <Icon icon={"tick"} />}</p>
          <p>Fetching monitors... {monitorsReady && <Icon icon={"tick"} />}</p>
          <p>Fetching sheet list... {sheetsReady && <Icon icon={"tick"} />}</p>
          <p>Fetching field list... {sharedReady && <Icon icon={"tick"} />}</p>
        </div>
      </div>
    </div>
  )

  if (
    (!userDesksReady && !userDesksFetched) ||
    (!desksReady && !desksFetched) ||
    (!monitorsReady && !monitorsFetched) ||
    (!sheetsReady && !sheetsFetched) ||
    (!sharedReady && !sharedFetched)
  )
    return <Loader />

  const deskIdList = R.compose(
    R.values,
    R.map(desk => desk.id),
    R.prop("data"),
  )(desks)
  const desksIdInUserDesks = R.compose(
    R.values,
    R.map(userdesk => userdesk.desk),
    R.prop("data"),
  )(userDesks)
  const deskDiff = R.difference(desksIdInUserDesks, deskIdList)

  // Logic when there is a desk in UserDesks which is no more shared
  if (!R.isEmpty(deskDiff)) return <RemoveUserDesksNotShared />

  if (!userDesks.data.length) {
    return <NoUserDesk />
  }
  return (
    <>
      <div
        className="content"
        style={{
          margin: "0 auto",
          height: "100%",
          width: "100%",
        }}
      >
        <UserDesks />
        <Outlet />
      </div>
    </>
  )
}

export default Desks
