// @ts-nocheck
import { Callout, FormGroup } from "@blueprintjs/core"
import { DateInput3 } from "@blueprintjs/datetime2"
import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

export const FormikDatePicker = ({ labelProps = null, ...props }) => {
  if (labelProps) {
    return (
      <FormGroup {...labelProps}>
        <DateInputWithErrors {...props} />
      </FormGroup>
    )
  }
  return <DateInputWithErrors {...props} />
}

const DateInputWithErrors = ({
  field,
  form: { touched, errors, setTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <DateInput3
        {...field}
        {...props}
        dateFnsFormat="dd-MM-yyyy" // Mutually exclusive with the formatDate and parseDate props.
        placeholder="DD-MM-YYYY"
        value={field.value && format(utcToZonedTime(field.value, "UTC"), "yyyy-MM-dd")}
        inputProps={{
          onBlur: () => setTouched({ ...touched, [field.name]: true }),
        }}
      />
      {touched[field.name] && errors[field.name] && (
        <Callout intent="primary" title={errors[field.name]} />
      )}
    </>
  )
}
