/** @jsxImportSource @emotion/react */
import TinyMCEFullscreenIcon from "../../../../icons/TinuMCEFullScreenIcon"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { LargeText, SmallText } from "../../../../styled/text.styled"
import { HeaderContainer } from "../components/Header.styled"
import { PortfolioKeyMetrics } from "../components/PortfolioKeyMetrics"
import { DataframeData } from "../types"
import { SectorButtons } from "./components/SectorButtons"
import { SimpleRiskSelectorButton } from "./components/SimpleRiskSelectorButton"
import { RiskTypeInfo } from "./enums"
import { RiskType } from "./types"

interface IRisksHeaderProps {
  fullScreen: boolean
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>
  keyMetrics?: DataframeData
  isLoading?: boolean
}

const RisksHeader = ({ fullScreen, setFullScreen, keyMetrics, isLoading }: IRisksHeaderProps) => {
  const sectorRiskInfo = RiskTypeInfo[RiskType.SECTOR_LEVEL_1]
  const SectorIcon = sectorRiskInfo.iconComponent

  const sectorTypes = [RiskType.SECTOR_LEVEL_1, RiskType.SECTOR_LEVEL_2, RiskType.SECTOR_LEVEL_3]

  return (
    <HeaderContainer flexDirection="row-reverse" justifyContent="space-between" gap="20px">
      <PortfolioKeyMetrics keyMetrics={keyMetrics} isLoading={isLoading} />
      <FlexContainer flexDirection="column" justifyContent="space-between">
        <FlexContainer gap="50px">
          <FlexContainer flexDirection="column" alignItems="center">
            <SmallText>Sectors</SmallText>
            <SectorIcon {...sectorRiskInfo.iconProps} />
            <SectorButtons />
          </FlexContainer>

          {Object.entries(RiskTypeInfo)
            .filter(([key]) => !sectorTypes.includes(key as RiskType))
            .map(([key, { iconComponent: Icon, label, iconProps }]) => (
              <SimpleRiskSelectorButton
                key={key}
                icon={<Icon {...iconProps} />}
                label={label}
                href={`#${key}`}
              />
            ))}
        </FlexContainer>

        <FlexContainer alignItems="center" gap="10px">
          <LargeText fontWeight="bold">Exposures</LargeText>
          <TinyMCEFullscreenIcon
            onClick={() => setFullScreen(!fullScreen)}
            fullscreen={fullScreen}
            fill={SCIENT_COLORS.white}
          />
        </FlexContainer>
      </FlexContainer>
    </HeaderContainer>
  )
}
export default RisksHeader
