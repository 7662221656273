// @ts-nocheck
import React from "react"
import { Button, ButtonGroup, Tag } from "@blueprintjs/core"

import { Row, Col } from "../../../../components"

const ViewType = ({ freq, handleSetFreq }) => {
  return (
    <>
      <Row center="xs" style={{ margin: "0" }}>
        <Col xs>
          <Tag minimal>Freq</Tag>
        </Col>
      </Row>
      <Row center="xs" style={{ margin: "6px auto 8px" }}>
        <Col xs>
          <ButtonGroup>
            <Button onClick={() => handleSetFreq("all")} active={freq === "all"} text="ALL" />
            <Button onClick={() => handleSetFreq("fy")} active={freq === "fy"} text="FY" />
            <Button onClick={() => handleSetFreq("mix")} active={freq === "mix"} text="MIX" />
          </ButtonGroup>
        </Col>
      </Row>
    </>
  )
}

export default ViewType
