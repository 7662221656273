import React, { useCallback, useEffect, useState } from "react"
import { useAuth } from "../views/hooks"
import WebsocketClient from "./websocket"

/**
 * Context that retains the websocketClient
 */
export const WebsocketContext = React.createContext<WebSocket | null>(null)

/**
 * Provides access to WebsocketContext for children
 */
export const WebsocketProvider = ({
  children,
  onRetrySuccess,
}: {
  children: JSX.Element
  onRetrySuccess: () => void
}) => {
  const { accessToken, authenticated } = useAuth()

  const [url, setUrl] = useState<URL | null>(null)

  /**
   * We create a state to a new WebsocketClient
   */
  const [client, setClient] = useState<WebsocketClient | null>(null)

  /**
   * When access Token changes (refresh token),
   * url is updated
   */
  useEffect(() => {
    if (authenticated && accessToken) {
      const url = new URL(process.env.REACT_APP_WS_URL as string)
      url.searchParams.append("token", accessToken)
      setUrl(url)
    }
  }, [accessToken, authenticated])

  /**
   * If clients is already set and url has been updated,
   * we update the url inside WebsocketClient so that
   * last valid token will be used on next reconnection
   * if needed
   */
  useEffect(() => {
    if (url) {
      if (client) {
        client.setUrl(url)
      } else {
        setClient(new WebsocketClient(url))
      }
    }
  }, [client, setClient, url])

  /**
   * Local state for websocket on Provider
   * to fire React rerender on websocket value change
   */
  const [ws, setWs] = useState<WebSocket | null>(null)

  /**
   * When the WebsocketClient connects
   * we set the ws
   * Hence, firing a rerender on props
   * using the context
   */
  const onOpen = useCallback((ws: WebSocket) => {
    setWs(ws)
  }, [])

  /**
   * When the Component mounts,
   * we initiates the websocket connection
   */
  useEffect(() => {
    client?.connect({ onRetrySuccess, onOpen })

    return () => {
      /**
       * On unmount we disconnect the websocket
       */
      client?.disconnect()
    }
  }, [onOpen, onRetrySuccess, client])

  return <WebsocketContext.Provider value={ws}>{children}</WebsocketContext.Provider>
}
