import { useQueryClient } from "@tanstack/react-query"
import { Dispatch, SetStateAction, useState } from "react"

import { useTradingDataUpdated } from "../../../../websocket/hooks/useEvents"
import { IAccountBookPairs, PortfolioDisplayTab } from "../types"
import Orders from "./Orders"
import { PortfolioContainer } from "./Portfolio.styled"
import Positions from "./Positions"
import Summary from "./Summary"
import { NoAssociatedBookAccount } from "./components/NoAssociatedBookAccount"
import useBooksQuery from "./hooks/useBooksQuery"
import useTradingDataQuery from "./hooks/useTradingDataQuery"
import useTradingSummaryQuery from "./hooks/useTradingSummaryQuery"

type PositionsOrOrders = PortfolioDisplayTab.POSITIONS | PortfolioDisplayTab.ORDERS

interface IPortfolioProps {
  selectedFilters: IAccountBookPairs
  setSelectedFilters: Dispatch<SetStateAction<IAccountBookPairs>>
  displayTab: PositionsOrOrders
}

/**
 *  Portfolio component is used to render both tabs Positions and Orders,
 * depending of displayTab prop
 */
const Portfolio = ({ selectedFilters, setSelectedFilters, displayTab }: IPortfolioProps) => {
  const queryClient = useQueryClient()

  /**
   * Wether we query live orders or not
   */
  const [isLiveOrders, setLiveOrders] = useState(true)

  /**
   * Getting all books that user has access to
   */
  const { books, isLoading: isLoadingBooks, error: booksError } = useBooksQuery()

  /**
   * Getting accounts and books summary that user has access to
   */
  const { summary, isLoading: isLoadingSummary } = useTradingSummaryQuery({
    liveOrders: isLiveOrders,
  })

  /**
   * Get tradingData based on selectedFilters
   * By defaut with [], all positions and orders are loaded (following users permission)
   */
  const { tradingData, isLoading: isLoadingTradingData } = useTradingDataQuery({
    accountBookPairs: selectedFilters,
    liveOrders: isLiveOrders,
  })

  /**
   * Handles Portfolio Summary Live event
   */
  useTradingDataUpdated(() => {
    queryClient.invalidateQueries({ queryKey: ["tradingsummaryscient"] })
    queryClient.invalidateQueries({ queryKey: ["tradingdatascient"] })
    queryClient.invalidateQueries({ queryKey: ["riskExposures"] })
    queryClient.invalidateQueries({ queryKey: ["riskZoom"] })
  })

  /**
   * If books error, we know they're no book associated to the user profil.
   */
  return (
    <>
      {!booksError ? (
        <PortfolioContainer gap="10px" flexDirection="column" flexGrow={1}>
          <Summary
            summary={
              displayTab === PortfolioDisplayTab.ORDERS
                ? summary?.orders_summary
                : summary?.positions_summary
            }
            books={books}
            isLoading={isLoadingSummary || isLoadingBooks}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            isLiveOrders={isLiveOrders}
            setLiveOrders={setLiveOrders}
          />
          {displayTab === PortfolioDisplayTab.POSITIONS && (
            <Positions positions={tradingData?.positions} isLoading={isLoadingTradingData} />
          )}
          {displayTab === PortfolioDisplayTab.ORDERS && (
            <Orders orders={tradingData?.orders} isLoading={isLoadingTradingData} />
          )}
        </PortfolioContainer>
      ) : (
        <PortfolioContainer
          gap="14px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <NoAssociatedBookAccount
            errorIcon
            errorText="There is no book associated to your user account."
          />
        </PortfolioContainer>
      )}
    </>
  )
}

export default Portfolio
