// @ts-nocheck
import autodux from "autodux"

const lastReportsDuck = autodux({
  slice: "lastReports",
  initial: {
    fetching: false,
    error: false,
    reports: [],
  },
  actions: {
    fetchLastReports: state => ({
      ...state,
      fetching: true,
    }),
    fetchLastReportsOk: (state, reports) => ({
      ...state,
      fetching: false,
      reports,
    }),
    fetchLastReportsError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),
  },
})

export default lastReportsDuck
