// @ts-nocheck
import * as yup from "yup"

// Use for formula node params
export const dynamicYupSchema = params => {
  const schema = params?.reduce((schema, param) => {
    const { min, max, many, title, type, optional } = param

    // if min or max = 0, JS returns false so need bang(!) to have true
    const formatedMin = min === 0 ? !min : min
    const formatedMax = max === 0 ? !max : max

    const article = type === "INTEGER" ? "an" : "a"
    const numericHelpText =
      formatedMin && formatedMax
        ? `${title} must be ${article} ${type} greater than or equal to ${min} and less than or equal to ${max}`
        : formatedMin
        ? `${title} must be ${article} ${type} greater than or equal to ${min}`
        : formatedMax
        ? `${title} must be ${article} ${type} less than or equal to ${max}`
        : ""

    if (optional) {
      return ["NUMBER", "FLOAT", "INTEGER"].includes(type) && many === false
        ? {
            ...schema,
            [param.id]: yup
              .string()
              .matches(/(^-?[0-9]+(\.[0-9]+)+$)|(^-?[0-9]+$)/, `Must be ${type}`)
              .test("is-in-range", `${numericHelpText}`, value =>
                !value
                  ? schema
                  : !max && !min
                  ? schema
                  : max === undefined && min !== undefined
                  ? value >= min
                  : min === undefined && max !== undefined
                  ? value <= max
                  : value >= min && value <= max,
              ),
          }
        : ["NUMBER", "FLOAT"].includes(type) && many === true
        ? {
            ...schema,
            [param.id]: yup
              .string()
              .matches(
                /^(-?[0-9]+\.?[0-9]*, ?)*((-?[0-9]+\.[0-9]+)|(-?[0-9]+))$/,
                `Must be a numerical sequence of ${type} separate by comma. If you want you can add a space after comma.`,
              ),
          }
        : schema
    }
    return ["NUMBER", "FLOAT", "INTEGER"].includes(type) && many === false
      ? {
          ...schema,
          [param.id]: yup
            .string()
            .required(`${title} is required!`)
            .matches(/(^-?[0-9]+(\.[0-9]+)+$)|(^-?[0-9]+$)/, `Must be ${type}`)
            .test("is-in-range", `${numericHelpText}`, value =>
              !value
                ? schema
                : !max && !min
                ? schema
                : max === undefined && min !== undefined
                ? value >= min
                : min === undefined && max !== undefined
                ? value <= max
                : value >= min && value <= max,
            ),
        }
      : ["NUMBER", "FLOAT"].includes(type) && many === true
      ? {
          ...schema,
          [param.id]: yup
            .string()
            .required(`${title} is required!`)
            .matches(
              /^(-?[0-9]+\.?[0-9]*, ?)*((-?[0-9]+\.[0-9]+)|(-?[0-9]+))$/,
              `Must be a numerical sequence of ${type} separate by comma. If you want you can add a space after comma.`,
            ),
        }
      : { ...schema, [param.id]: yup.string().required(`${title} is required!`) }
  }, {})

  return yup.object().shape(schema)
}
