// @ts-nocheck
import React, { useState } from "react"
import { append, reject, indexOf, map } from "ramda"
import { Button, Card, Collapse, HTMLTable } from "@blueprintjs/core"

import { StockSuggest } from "../../../../../../../components"
import { StockItem } from "./StockItem"
import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"

export const StockRows = ({ card, values, setValues, index }) => {
  const [showAddStock, setShowAddStock] = useState(false)
  const toogleShowAddStock = () => setShowAddStock(!showAddStock)

  const kind = card.kind

  const handleSubmit = ({ id, str_name }) => {
    setValues({
      rowDefs: {
        ...values.rowDefs,
        rows: map(
          x =>
            indexOf(x, values.rowDefs.rows) === index
              ? {
                  ...x,
                  stocks: append(
                    { id, str_name, frontOpts: { titleOverride: "" } },
                    values.rowDefs.rows[index].stocks,
                  ),
                }
              : x,
          values.rowDefs.rows,
        ),
      },
    })
  }

  const handleDelete = stock => {
    setValues({
      rowDefs: {
        ...values.rowDefs,
        rows: map(
          x =>
            indexOf(x, values.rowDefs.rows) === index
              ? { ...x, stocks: reject(e => e.id === stock.id, values.rowDefs.rows[index].stocks) }
              : x,
          values.rowDefs.rows,
        ),
      },
    })
  }

  return (
    <>
      <HTMLTable striped compact style={{ width: "500px" }}>
        <thead>
          <tr>
            <th>Stock Rows</th>
          </tr>
        </thead>
        <tbody>
          {card.stocks.map((stock, stockIndex) => (
            <StockItem
              key={stock.id}
              index={index}
              stock={stock}
              stockIndex={stockIndex}
              handleDelete={handleDelete}
              kind={kind}
            />
          ))}
        </tbody>
      </HTMLTable>
      <Button
        fill
        // alignText="left"
        icon={showAddStock ? "caret-down" : "plus"}
        onClick={toogleShowAddStock}
      >
        {showAddStock ? "Hide" : ""} Add stock
      </Button>
      {card.stocks.length === 0 && (
        <p style={{ color: SCIENT_COLORS.orange3, marginLeft: "10px" }}> Add at least one stock!</p>
      )}
      <Collapse isOpen={showAddStock}>
        <Card>
          <StockSuggest
            placeholder="Add Stock"
            submit={({ id, str_name }) => {
              handleSubmit({ id, str_name })
            }}
          />
        </Card>
      </Collapse>
    </>
  )
}
