// @ts-nocheck
import React from "react"
import { useSelector } from "react-redux"
import { NumericInput, Tag } from "@blueprintjs/core"
import { path } from "ramda"

import { Row, Col } from "../../../../components"
import profileDuck from "../../../../../state/modules/profile"

const ThresholdDays = ({ stockSlug, dispatch }) => {
  const thresholdDays = useSelector(
    path(["profile", "userstockconfigs", stockSlug, "thresholdDays"]),
  )
  const handleChange = valueAsNumber =>
    dispatch(
      profileDuck.actions.stockSetThresholdDays({
        slug: stockSlug,
        value: valueAsNumber,
      }),
    )
  return (
    <>
      <Row center="xs" style={{ margin: "0" }}>
        <Col xs>
          <Tag minimal>ThresholdDays</Tag>
        </Col>
      </Row>
      <Row center="xs" style={{ margin: "6px auto 8px" }}>
        {/* <Col xs style={{ margin: "0 auto" }}> */}
        <NumericInput
          className="sct-fullwidth"
          value={thresholdDays}
          onValueChange={handleChange}
          min={0}
          buttonPosition={"right"}
          style={{ margin: "0 auto", width: "100px" }}
          // rightElement={<Tag minimal>Threshold</Tag>}
        />
        {/* </Col> */}
      </Row>
    </>
  )
}

export default ThresholdDays
