// @ts-nocheck
import React from "react"
import { Field } from "formik"
import { ControlGroup, Tag, H6 } from "@blueprintjs/core"

import { getDeepNestedValue, convertPath } from "../../../../../../../utils"
import { CommentPopover } from "../../../../../../components/formik"
import { Row, Col } from "../../../../../../components"
import { Prev } from "./Prev"
import { Input } from "./Input"

export const InputRow = props => {
  const {
    title,
    basePath,
    commentPath = null,
    values,
    prevReport,
    prevYearReport,
    handleChange,
    disabled,
    sideUpdates,
    placeholder,
    // defaultValue,
    // initialValue,
  } = props

  const fieldR = convertPath(basePath, values, { flatten: true })

  const fieldPR = convertPath(basePath, prevReport, { flatten: true })
  const valuePR = fieldPR.endsWith("_override")
    ? getDeepNestedValue(
        basePath.map(el => el.replace("override", "readonly")),
        prevReport,
      )
    : getDeepNestedValue(basePath, prevReport)

  const fieldPYR = convertPath(basePath, prevYearReport, { flatten: true })
  const valuePYR = fieldPYR.endsWith("_override")
    ? getDeepNestedValue(
        basePath.map(el => el.replace("override", "readonly")),
        prevYearReport,
      )
    : getDeepNestedValue(basePath, prevYearReport)

  if (disabled && !valuePYR && !valuePR) {
    return null
  }
  if (commentPath) {
    const commentPR = getDeepNestedValue(commentPath, prevReport)
    const commentPYR = getDeepNestedValue(commentPath, prevYearReport)
    return (
      <Row>
        <Col xsOffset={1} xs={3}>
          <H6>{title}</H6>
        </Col>
        <Col xs={2}>
          <Prev field={fieldPYR} value={valuePYR} commentValue={commentPYR} />
        </Col>
        <Col xs={2}>
          <Prev field={fieldPR} value={valuePR} commentValue={commentPR} />
        </Col>

        <Col xs={3}>
          <ControlGroup>
            <Input
              field={fieldR}
              placeholder={placeholder}
              handleChange={handleChange}
              sideUpdates={sideUpdates}
              prevYearReport={prevYearReport}
              prevReport={prevReport}
              disabled={disabled}
            />
            <Field
              name={`${fieldR}_comment`}
              key={`${fieldR}_comment`}
              component={CommentPopover}
              onChange={handleChange}
              autoResize={false}
              cols={100}
              rows={10}
              style={{ resize: "none" }}
            />
          </ControlGroup>
        </Col>
      </Row>
    )
  }
  return (
    <Row>
      <Col xsOffset={1} xs={3}>
        <H6>{title}</H6>
      </Col>
      <Col xs={2}>
        <Tag large fill minimal style={{ textAlign: "right" }}>
          {valuePYR}
        </Tag>
      </Col>
      <Col xs={2}>
        <Tag large fill minimal style={{ textAlign: "right" }}>
          {valuePR}
        </Tag>
      </Col>

      <Col xs={3}>
        <Input
          field={fieldR}
          sideUpdates={sideUpdates}
          handleChange={handleChange}
          disabled={disabled}
        />
      </Col>
    </Row>
  )
}
