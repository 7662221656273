import { Alignment, Switch } from "@blueprintjs/core"
import { Dispatch, SetStateAction } from "react"

interface ILiveOrdersSwitchProps {
  isLiveOrders: boolean
  setLiveOrders: Dispatch<SetStateAction<boolean>>
}

const LiveOrdersSwitch = ({ isLiveOrders, setLiveOrders }: ILiveOrdersSwitchProps) => {
  return (
    <Switch
      label={isLiveOrders ? "LIVE ORDERS" : "ALL ORDERS"}
      checked={isLiveOrders}
      onChange={() => {
        setLiveOrders(isLiveOrders => !isLiveOrders)
      }}
      alignIndicator={Alignment.RIGHT}
    />
  )
}

export default LiveOrdersSwitch
