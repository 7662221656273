// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import dsForecastsDuck from "../../."
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const {
  actions: { fetchAllForecasts, fetchAllForecastsOk, fetchAllForecastsError },
} = dsForecastsDuck

const fetchAllForecastsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchAllForecasts.type),
    switchMap(() => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/dsforecasts/?entries=1`
      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchAllForecastsOk(response)),
        catchError(error => of(fetchAllForecastsError(error.xhr.response))),
      )
    }),
  )

export default fetchAllForecastsEpic
