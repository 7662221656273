import { FieldError, UseFormRegister } from "react-hook-form"
import { SerializedStyles } from "@emotion/react"
import { WaveInputs } from "./form"

export enum OrdersColumn {
  BOOK = "Book",
  SYMBOL = "Ticker",
  SIDE = "Side",
  AMOUNT_USD = "USD",
  QUANTITY = "Quantity",
  ORDER_TYPE = "Type",
  LIMIT_PRICE = "Limit",
  EXECUTION_TYPE = "Execution",
  EXECUTION_CAP_PCT = "CAP",
  TIME_IN_MARKET = "Time in Market",
  TARGET_END_DATETIME = "Target End",
  EXECUTION_DETAILS = "Execution details",
}

export enum WaveStatus {
  CREATED = "Created",
  SENT = "Sent",
  RECEIVED = "Received",
  PASSED = "Passed",
  NOT_PASSED = "Not passed",
  CSV_UPLOAD_ERROR = "CSV Upload Error",
}

export enum Side {
  BUY = "buy",
  BUY_COVER = "buy_cover",
  SELL = "sell",
  SELL_SHORT = "sell_short",
}

export enum OrderType {
  MARKET = "market",
  LIMIT = "limit",
}

export enum ExecutionType {
  HERE = "here",
  VOL_INLINE = "vol_inline",
  VWAP = "vwap",
  TWAP = "twap",
  PAIR = "pair",
  PRE_MARKET = "pre_market",
  FUTURE = "future",
  STRICT_LIMIT = "strict_limit",
}
// Type to represent a ticker item in BookSuggest
export type BookItem = {
  value: number
  label: string
}

export type ITickerItem = {
  ticker: string
  isNew?: boolean // Flag to identify new items
}

export interface IBaseInputProps {
  name: string
  register: UseFormRegister<WaveInputs>
  customCss?: SerializedStyles
  error?: FieldError
  index?: number
}
