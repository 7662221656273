import { Field, useField, useFormikContext } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { RadioLabel } from "./FormikRadioLabel.styled"

interface IFormikRadioLabelProps {
  name: string
  value: string
  label: string
  gap: string
  disabled?: boolean
  enableAutoFocus?: boolean
}

const FormikRadioLabel = ({
  name,
  value,
  label,
  disabled = false,
  enableAutoFocus = false,
  gap = "3px",
}: IFormikRadioLabelProps) => {
  const { setFieldValue, handleChange } = useFormikContext()
  const [field] = useField(name)
  // We remove the id at the end of the name to put the sector name.
  const [sectorName] = useField(name.slice(0, -2).concat("name"))
  const [sectorId] = useField(name.slice(0, -2).concat("id"))
  const [checked, setChecked] = useState(false)

  /**
   * When we select the radio label,
   * we pass the name of the sector to display it directly when
   * creating or updating an idea.
   */
  useEffect(() => {
    if (checked) {
      setFieldValue(sectorName.name, label)
      setFieldValue(sectorId.name, value)
    }
  }, [checked, label, sectorId.name, sectorName.name, setFieldValue, value])

  const radioRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (enableAutoFocus && checked && radioRef.current) {
      radioRef.current.focus()
    }
  }, [checked, radioRef, enableAutoFocus])

  useEffect(() => {
    setChecked(value === field.value?.toString())
  }, [field.value, value])

  useEffect(() => {
    return () => {
      setFieldValue(field.name, undefined)
      setFieldValue(sectorName.name, undefined)
    }
  }, [setFieldValue, field.name, sectorName.name])

  return (
    <RadioLabel gap={gap} disabled={checked ? false : disabled}>
      <Field
        type="radio"
        name={name}
        value={value}
        checked={checked}
        innerRef={radioRef}
        onChange={handleChange}
        disabled={checked ? false : disabled}
      />
      {label}
    </RadioLabel>
  )
}

export default FormikRadioLabel
