// @ts-nocheck
import { Button, MenuItem, Spinner } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import { format } from "date-fns"
import * as R from "ramda"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { addSingleItem, searchRForecasts } from "../../../../../../state/modules/search"
import sheetsDuck from "../../../../../../state/modules/sheets"
import { SCIENT_COLORS } from "../../../../../../styled/scientColors"
import { selectStSrcKeys } from "../utils"

export const PrimaryForecast = ({ sheet }) => {
  const [fetch, setFetch] = useState(true)

  const stSrc = sheet.st_sources.length > 0 && sheet.st_sources[0]
  const lastReportDate = stSrc.last_report_date

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(searchRForecasts({ parent: stSrc.id }))
    setFetch(true)
  }, [dispatch, stSrc.id])

  const rForecasts = useSelector(R.path(["search", "rForecasts", "results"]))

  useEffect(() => {
    if (!R.isEmpty(rForecasts)) setFetch(false)
  }, [rForecasts])

  if (fetch) {
    return (
      <div style={{ marginLeft: "20px" }}>
        <Spinner size={25} />
      </div>
    )
  }

  // Will build ticker = stock ticker + forecast company
  const regex = /(.+>)/
  const ticker = forecast => regex.exec(forecast.src_name)[0] + forecast.company

  const options = R.compose(
    R.prepend({ label: "No Forecast", id: "none" }),
    R.map(forecast => ({
      label:
        forecast.company +
        "-" +
        (forecast.label === "Base" ? "" : `${forecast.label}-`) +
        (forecast.owner === "default" ? "" : `${forecast.owner}-`) +
        format(new Date(forecast.owner_updated_at), "dd/MM/yy HH:mm"),
      id: forecast.id,
      kind: forecast.kind,
      kind_id: forecast.kind_id,
      parent_id: forecast.parent_id,
      company: forecast.company,
      src_name: forecast.src_name,
      verbose_name: forecast.verbose_name,
      ticker: ticker(forecast),
      style:
        forecast.owner_updated_at < lastReportDate
          ? { color: SCIENT_COLORS.red3 }
          : forecast.owner_updated_at < forecast.source_updated_at
          ? { color: SCIENT_COLORS.orange3 }
          : null,
    })),
    R.sort((a, b) => new Date(b.owner_updated_at) - new Date(a.owner_updated_at)),
    R.values,
  )(rForecasts)

  const rfId = sheet.rf_sources.length > 0 && sheet.rf_sources[0].id
  const selectedForecast = R.find(R.propEq("id", rfId), options)

  const buttonText =
    options.length <= 0
      ? "No forecast"
      : selectedForecast
      ? `Forecast: ${selectedForecast.label}`
      : "Forecast: none selected"
  const buttonStyle = selectedForecast ? selectedForecast.style : null

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        text={item.label}
        onClick={handleClick}
        style={item.style}
      />
    )
  }

  const handleClickSelect = item => {
    dispatch(addSingleItem({ item }))
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        rf_sources: item.id === "none" ? [] : [selectStSrcKeys(item)],
      }),
    )
  }

  return (
    <Select
      className="sct-fullwidth"
      filterable={false}
      items={options}
      itemRenderer={itemRenderer}
      onItemSelect={handleClickSelect}
      disabled={options.length <= 0}
      popoverProps={{ position: "bottom" }}
    >
      <Button
        fill
        rightIcon="caret-down"
        text={buttonText}
        disabled={options.length <= 0}
        style={buttonStyle}
      />
    </Select>
  )
}
