// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import desksDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { updateDesk, updateDeskOk, updateDeskError, fetchUserDesks },
} = desksDuck

const updateDeskEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(updateDesk.type),
    mergeMap(action => {
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/desks/${action.payload.id}/`,
        method: "PATCH",
        body: action.payload,
        crossDomain: true,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(({ response }) => {
          return [updateDeskOk(response), fetchUserDesks()]
        }),
        catchError(error => {
          return of(updateDeskError({ id: action.payload.id, error: error.xhr.response.detail }))
        }),
      )
    }),
  )

export default updateDeskEpic
