// @ts-nocheck
import * as R from "ramda"
import { isArray, isObjectArray, isNumericArray } from "../../arrayUtils"
// import { pctFieldsSlugList } from "./pctFieldsSlugList"

// const formatPctFieldsToBasePoint = stock =>
//   R.mapObjIndexed((value, key) => {
//     if (R.includes(key, pctFieldsSlugList(stock)) && value !== null) {
//       return value * 10000
//     }
//     if (isObjectArray(value)) {
//       return R.map(formatPctFieldsToBasePoint(stock)(R.__), value)
//     }
//     if (R.equals("Object", R.type(value))) {
//       return formatPctFieldsToBasePoint(stock)(value)
//     }
//     return value
//   })

// Fields with pct=true are mulpily by 10000 in getFormatedValue (to display bps)

const computeDiff = forecast => {
  const calculateDiff = anything => {
    if (typeof anything === "number") {
      return null
    }
    if (isArray(anything) && anything.length === 0) {
      return null
    }
    if (isNumericArray(anything) && anything.length === 1) {
      return null
    }
    if (isNumericArray(anything) && anything.length === 2) {
      return +(anything[1] - anything[0]).toFixed(8)
    }
    if (isObjectArray(anything) && anything.length > 0) {
      return anything.map(el => R.map(e => calculateDiff(e), el))
    }
    return anything
  }

  const result = R.map(e => calculateDiff(e), forecast)
  // return formatPctFieldsToBasePoint(stock)(result)
  return result
}

export default computeDiff
