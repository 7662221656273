// @ts-nocheck
import autodux from "autodux"

const pingDuck = autodux({
  slice: "ping",
  initial: {
    checking: false,
    error: false,
  },
  actions: {
    ping: state => ({ ...state, checking: true }),
    pingOk: state => ({ ...state, checking: false }),
    pingError: state => ({ ...state, checking: false, error: true }),
    pingCancel: { checking: false, error: false },
  },
})

export default pingDuck
