import { AxiosInstance } from "axios"
import { ModelUpdate, IChartData } from "../views/pages/Noc/types/business"

/**
 * Api routes for /api/v1/noc/
 */
const NOC_PER_PAGE = 100

export interface INocApi {
  getInfiniteModelUpdates: ({
    pageParam,
    stockParam,
    userParam,
  }: {
    pageParam?: string
    stockParam?: number
    userParam?: number
  }) => Promise<{
    data: ModelUpdate[]
    next_date: string | null
  }>
  getChartHistoricalData: ({
    update_id,
    with_con,
  }: {
    update_id: number | null
    with_con?: boolean
  }) => Promise<{ data: IChartData }>
}

const noc = (axiosClient: AxiosInstance): INocApi => ({
  /**
   * GET /api/v1/noc/model-updates/
   */
  getInfiniteModelUpdates: async ({
    pageParam,
    stockParam,
    userParam,
  }: {
    pageParam?: string
    stockParam?: number
    userParam?: number
  }) => {
    const { data } = await axiosClient.get<{ data: ModelUpdate[]; next_date: string | null }>(
      "/noc/model-updates/",
      {
        params: {
          per_page: NOC_PER_PAGE,
          next_date: pageParam,
          stock: stockParam,
          analyst: userParam,
        },
      },
    )

    return data
  },

  /**
   * GET /api/v1/noc/model-updates/history/
   */
  getChartHistoricalData: async ({
    update_id,
    with_con,
  }: {
    update_id: number
    with_con?: boolean
  }) => {
    const { data } = await axiosClient.get<{ data: IChartData }>("/noc/model-updates/history/", {
      params: { update_id, with_con: true },
    })
    return data
  },
})

export default noc
