// @ts-nocheck
import { ButtonGroup } from "@blueprintjs/core"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts/highstock"
import * as R from "ramda"
import React from "react"

import { defaultOptions } from "../../../../../_shared/highcharts"
import { Display, FreqButton, SharedHeader } from "../../components"
import { LaunchProcessing } from "../../components/CloneSheet/LaunchProcessing"
import { formatData } from "../utils"
import ChartHeader from "./ChartHeader"

const Chart = ({ monitor, sheet, output, height, pauseProcessing }) => {
  const { name, rowDefs } = sheet
  const { colTitles, rowData } = output

  // need to take frontOpts and formula in rowDefs because not in rowData
  const visibleRowData = R.compose(
    R.reject(row => R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.chartHideLine), // Reject line where option chartHideLine is true

    R.reject(row => !R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).formula), // Reject lines that are titles
  )(rowData)

  const rowDataWithoutTitle = R.compose(
    R.reject(row => !R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).formula), // Reject lines that are titles
  )(rowData)

  const series = formatData(colTitles, visibleRowData, rowDataWithoutTitle, rowDefs)

  const options = {
    ...defaultOptions,
    chart: {
      type: "line",
      height: height - 82,
      // height: (8 / 16) * 100 + "%", // 16:9 ratio
    },
    title: { text: name },
    yAxis: R.concat(
      [{ opposite: false }], // main yAxis (to the left)
      visibleRowData.map(row => {
        // ownAxis (used only if yAxis has right index in series)
        const format = R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.pct
          ? "{value}%"
          : "{value}"

        const indexForColor = R.findIndex(R.pathEq(["extra", "rowId"], row.extra.rowId))(
          rowDataWithoutTitle,
        )

        const color = R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.chartColor
          ? R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.chartColor
          : Highcharts.getOptions().colors[indexForColor]

        return {
          labels: {
            format,
            style: { color },
          },
          title: {
            style: { color },
          },
          opposite: true,
        }
      }),
    ),
    series,
    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "top",
      layout: "vertical",
      x: 40,
      y: 0,
      floating: true,
      maxHeight: 250,
    },
  }

  return (
    <>
      <div className="sct-sheet-header-container">
        <ButtonGroup>
          <SharedHeader monitor={monitor} sheet={sheet} />
          <Display sheet={sheet} />
          {pauseProcessing === false && (
            <>
              <ChartHeader sheet={sheet} />
              <ButtonGroup style={{ marginLeft: "50px" }}>
                <FreqButton sheet={sheet} />
              </ButtonGroup>
            </>
          )}
        </ButtonGroup>
      </div>

      <div
        className="ag-theme-balham-dark"
        style={{
          margin: "0 auto",
          height: "100%",
          width: "100%",
        }}
      >
        {pauseProcessing === true ? (
          <LaunchProcessing sheet={sheet} /> // Use when cloning a sheet
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"stockChart"}
            options={options}
          />
        )}
      </div>
    </>
  )
}

export default Chart
