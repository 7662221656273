// @ts-nocheck
import * as R from "ramda"

import { placeEdges } from "./placeEdges"

export const nodeShape = {
  width: 160,
  height: 160,
}
export const handleBoundShape = {
  width: 6,
  height: 6,
}

export const inputConnectorStyle = {
  background: "#555",
}

export const outputConnectorStyle = {
  background: "#555",
}

const nodeHeight = nodeShape.height
const nodeWidth = nodeShape.width

const computeTargetX = -(handleBoundShape.width / 2)
const computeTargetY = (index, targetAmount) =>
  placeEdges(nodeHeight, index, targetAmount) - handleBoundShape.height / 2

const computeSourceX = nodeWidth - handleBoundShape.width / 2
const computeSourceY = (index, sourceAmount) =>
  placeEdges(nodeHeight, index, sourceAmount) - handleBoundShape.height / 2

const addNodeDefInfos = (nodeDef, nodeCatName, enhancedPath) => (node, edges) => {
  return R.mergeDeepLeft(
    {
      sourcePosition: "left",
      z: 0,
      ...nodeShape,
      data: {
        category: nodeCatName,
        title: nodeDef.title,
        subtitle: nodeDef.subtitle,
        description: nodeDef.description,
        show: !!nodeDef.show,
        locked: !!nodeDef.locked,
        style: nodeDef.style,
        key: node?.id,
        inputs: nodeDef.inputs
          ? nodeDef.inputs.map((inputDef, index) => {
              const top = placeEdges(nodeHeight, index, nodeDef.inputs.length)
              const connectable = R.find(R.propEq("target", node.id), edges) ? false : true
              return {
                ...inputDef,
                connectable,
                style: {
                  ...inputConnectorStyle,
                  top,
                },
              }
            })
          : null,
        outputs: nodeDef.outputs
          ? nodeDef.outputs.map((outputDef, index) => {
              const top = placeEdges(nodeHeight, index, nodeDef.outputs.length)
              const connectable = R.find(R.propEq("source", node.id), edges) ? false : true
              return {
                ...outputDef,
                connectable,
                style: { ...outputConnectorStyle, top },
              }
            })
          : null,
        params: nodeDef.params
          ? nodeDef.params.map((param, index) => {
              return {
                ...R.pick(
                  ["id", "description", "type", "title", "show", "optional", "many", "choices"],
                  param,
                ),
                value: node?.data.params[index]?.value || param.default_value,
              }
            })
          : null,
        enhancedPath,
      },
      handleBounds: {
        target: nodeDef.inputs
          ? nodeDef.inputs.map((inputDef, index) => ({
              id: node?.handleBounds.target[index].id,
              position: "left",
              ...handleBoundShape,
              x: computeTargetX,
              y: computeTargetY(index, nodeDef.inputs.length),
              optional: !!inputDef?.optional,
            }))
          : null,
        source: nodeDef.outputs
          ? nodeDef.outputs.map((outputDef, index) => ({
              id: node?.handleBounds.source[index].id,
              position: "right",
              ...handleBoundShape,
              x: computeSourceX,
              y: computeSourceY(index, nodeDef.outputs.length),
              optional: !!outputDef?.optional,
            }))
          : null,
      },
    },
    node,
  )
}
export const enhanceFormulaTree = ({ formulaTree, nodeDefs }) => {
  const { edges } = formulaTree

  return R.over(
    R.lensProp("nodes"),
    R.map(node => {
      const nodeDef = R.find(R.propEq("type", node.type))(nodeDefs.nodes)
      const enhancedPath = nodeDefs.enhancedPath
      const nodeCatName = R.compose(
        R.prop("name"),
        R.find(R.propEq("id", nodeDef.category)),
      )(nodeDefs.categories)

      return R.compose(addNodeDefInfos(nodeDef, nodeCatName, enhancedPath))(node, edges)
    }),
    formulaTree,
  )
}
