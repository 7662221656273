// @ts-nocheck
import * as R from "ramda"

export const updateFolders = (state, { payload: { folders } }) =>
  R.compose(
    R.assocPath(["folders", "fetching"], true),
    R.assocPath(["folders", "list"], folders),
    R.dissocPath(["folders", "error"]),
  )(state)

export const updateFoldersOk = (state, { payload: { folders } }) =>
  R.compose(R.assocPath(["folders", "list"], folders), R.dissocPath(["folders", "fetching"]))(state)

export const updateFoldersError = (state, { payload: { error } }) =>
  R.compose(R.assocPath(["folders", "error"], error), R.dissocPath(["folders", "fetching"]))(state)
