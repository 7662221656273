// @ts-nocheck
import React from "react"
import { useNavigate } from "react-router-dom"
import { OmniSuggest } from "../../../../components"

const SearchStock = ({ placeholder }) => {
  const navigate = useNavigate()
  const submit = ({ slug }) => navigate(`/stocks/${slug}`)

  return (
    <OmniSuggest
      onSubmit={submit}
      exclude={{ ds: true, dsf: true, rf: true }}
      placeholder={placeholder}
      inputValueRenderer={item => item.bloomberg_code}
    />
  )
}

export default SearchStock
