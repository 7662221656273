// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { Button, ButtonGroup } from "@blueprintjs/core"

// import SavedMenu from "./SavedMenu"
import { SCIENT_COLORS } from "../../../../../../../../../styled/scientColors"
import { backgroundCss, expandCollapseCss, insertButtonsCss } from "./Buttons.styled"

const RowTreeButtons = ({
  expandAndCollapse,
  insertNewSingle,
  insertNewGroup,
  groupNotComplete,
}) => {
  return (
    <div>
      <ButtonGroup css={expandCollapseCss}>
        <Button
          text=" Expand all"
          icon="expand-all"
          onClick={() => {
            expandAndCollapse(true)
          }}
          disabled={groupNotComplete}
          small
        />
        <Button
          text=" Collapse all"
          icon="collapse-all"
          onClick={() => {
            expandAndCollapse(false)
          }}
          disabled={groupNotComplete}
          small
        />
      </ButtonGroup>
      <ButtonGroup fill css={insertButtonsCss}>
        <Button
          onClick={() => insertNewSingle({ type: "header" })}
          text="Header"
          css={backgroundCss(SCIENT_COLORS.turquoise1)}
          disabled={groupNotComplete}
        />
        <Button
          onClick={() => insertNewSingle({ type: "data" })}
          text="Data"
          css={backgroundCss(SCIENT_COLORS.cerulean1)}
          disabled={groupNotComplete}
        />
        <Button
          onClick={insertNewGroup}
          text="Group"
          css={backgroundCss(SCIENT_COLORS.indigo1)}
          disabled={groupNotComplete}
        />
      </ButtonGroup>
    </div>
  )
}

export default RowTreeButtons
