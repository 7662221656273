import { format, parseISO } from "date-fns"
import { useMemo } from "react"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"
import { MediumText } from "../../../styled/text.styled"
import { roundNumber } from "../../_shared"
import { FieldType, UpdatedMetric } from "./types/business"

interface IUpdatedMetric {
  updatedMetric: UpdatedMetric
}

const getMetricDisplayValue = (
  metric: string,
  value: number | null,
  valueTimestamp: string | null,
  isBpsMetric: boolean,
) => {
  if (isBpsMetric) {
    return (
      <>
        {metric}
        {" Value: "}
        <strong>{roundNumber(value)}</strong>
        {"% at "}
        {valueTimestamp && format(parseISO(valueTimestamp), "dd-MM-yyyy")}
      </>
    )
  } else {
    return (
      <>
        {metric}
        {" Value: "}
        <strong>{roundNumber(value)}</strong>
        {" at "}
        {valueTimestamp && format(parseISO(valueTimestamp), "dd-MM-yyyy")}
      </>
    )
  }
}

export const TooltipContent = ({ updatedMetric }: IUpdatedMetric) => {
  const bpsMetric = useMemo(
    () => [FieldType.PROFIT_MARGIN, FieldType.OSG].includes(updatedMetric.metric),
    [updatedMetric.metric],
  )
  return (
    <FlexContainer flexDirection="column" gap="6px">
      {updatedMetric.value_change ? (
        <>
          <MediumText color={SCIENT_COLORS.darkGray5}>
            {"Current "}
            {getMetricDisplayValue(
              updatedMetric.metric,
              updatedMetric.current_value,
              updatedMetric.current_value_at,
              bpsMetric,
            )}
          </MediumText>
          <MediumText color={SCIENT_COLORS.darkGray5}>
            {"Previous "}
            {getMetricDisplayValue(
              updatedMetric.metric,
              updatedMetric.previous_value,
              updatedMetric.previous_value_at,
              bpsMetric,
            )}
          </MediumText>
        </>
      ) : (
        <MediumText color={SCIENT_COLORS.darkGray5}>Insignificant value change</MediumText>
      )}
    </FlexContainer>
  )
}

export const ConsensusTooltipContent = ({ updatedMetric }: IUpdatedMetric) => {
  const bpsMetric = useMemo(
    () => [FieldType.PROFIT_MARGIN, FieldType.OSG].includes(updatedMetric.metric),
    [updatedMetric.metric],
  )
  return (
    <FlexContainer flexDirection="column" gap="6px">
      {updatedMetric.value_change && updatedMetric.consensus_data ? (
        <>
          {updatedMetric.consensus_data.consensus_value ? (
            <>
              <MediumText color={SCIENT_COLORS.darkGray5}>
                {"Current CON "}
                {getMetricDisplayValue(
                  updatedMetric.metric,
                  updatedMetric.consensus_data.consensus_value,
                  updatedMetric.consensus_data.consensus_at,
                  bpsMetric,
                )}
              </MediumText>
            </>
          ) : (
            <MediumText color={SCIENT_COLORS.darkGray5}>
              {updatedMetric.consensus_data.err_msg}
            </MediumText>
          )}
        </>
      ) : (
        <>
          <MediumText color={SCIENT_COLORS.darkGray5}>No comparison available</MediumText>
        </>
      )}
    </FlexContainer>
  )
}
