import { getIn, useField, useFormikContext } from "formik"
import React from "react"
import { FormikTextInput } from "../../../components/formik/fields/FormikTextInput"
import { IdeaType } from "../types/business"
import { IdeaCreateSourceContainer } from "./IdeaCreateSource.styled"
import { InputContainer, TitleLabel } from "./IdeaCreateTitle.styled"
import { IIdeaCreateForm } from "./IdeaCreate"
import { Callout } from "@blueprintjs/core"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { FlexContainer } from "../../../../styled/flexbox.styled"

interface IIdeaCreateTitleProps {
  disabled: boolean
}

/**
 *  Users have to add a title to their ideas.
 * Title is mandatory for idea of type Note.
 * Title is optional for idea of type Trade.
 */
const IdeaCreateTitle = ({ disabled }: IIdeaCreateTitleProps) => {
  const [ideaType] = useField("idea_type")
  const placeholder = [IdeaType.TRADE, IdeaType.STRUCTURAL].includes(ideaType.value)
    ? "Optional Title"
    : "Enter a title"
  const { errors } = useFormikContext<Partial<IIdeaCreateForm>>()

  return (
    <IdeaCreateSourceContainer flexDirection="column">
      <FlexContainer gap="15px">
        <TitleLabel>Title</TitleLabel>
        <InputContainer alignItems="center" flexDirection="column">
          <FormikTextInput
            disabled={disabled}
            name="title"
            placeholder={placeholder}
            showErrors={false}
            large
          />
        </InputContainer>
      </FlexContainer>
      {getIn(errors, "title") && (
        <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
          {`Title is a required field`}
        </Callout>
      )}
    </IdeaCreateSourceContainer>
  )
}

export default IdeaCreateTitle
