import { css } from "@emotion/react"

export const loginInputCss = css({
  input: {
    backgroundColor: "white !important",
    color: "#2d333a !important",
    fontSize: "16px",
    padding: "18px 18px 18px 18px !important",
    width: "300px !important",
  },
})

export const tokenInputCss = css({
  input: {
    backgroundColor: "white !important",
    color: "#2d333a !important",
    fontSize: "16px",
    padding: "18px 18px 18px 18px !important",
    width: "200px !important",
    "::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "::-webkit-outer-spin-button": { "-webkit-appearance": "none", margin: 0 },
  },
})

export const calloutLoginCss = css({
  color: "#2e58a5",
  fontStyle: "italic",
})
