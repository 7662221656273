// @ts-nocheck
import React, { useState } from "react"
import { update } from "ramda"
import { Button, HTMLSelect, NumericInput, Classes } from "@blueprintjs/core"

const lagTypeOpts = [
  { label: "Choose lag type", value: "" },
  { label: "Days", value: "d" },
  { label: "Weeks", value: "w" },
  { label: "Months", value: "m" },
  { label: "Quarters", value: "q" },
  { label: "Years", value: "y" },
]

export const InsertLag = ({ panelsState, setPanelsState }) => {
  const [lagType, setLagType] = useState("")
  const [lagAmount, setLagAmount] = useState()

  const appendLag = () => setPanelsState(update(3, `{${lagType},${lagAmount}}`, panelsState))
  const removeLag = () => setPanelsState(update(3, "", panelsState))

  return (
    <div className={Classes.DIALOG_BODY} style={{ margin: "41px 140px" }}>
      <HTMLSelect
        options={lagTypeOpts}
        value={lagType}
        fill
        onChange={({ target: { value } }) => setLagType(value)}
      />
      <NumericInput
        min={-365}
        max={365}
        value={lagAmount}
        onValueChange={setLagAmount}
        placeholder="Enter lag amount"
        fill
      />
      <Button
        intent="primary"
        text={panelsState[3] === "" ? "Insert lag" : "Change lag"}
        fill
        onClick={appendLag}
        disabled={!lagAmount || !lagType}
      />
      <Button text="Remove lag" fill onClick={removeLag} disabled={panelsState[3] === ""} />
    </div>
  )
}
