// @ts-nocheck
import { Button, Card, H2, H4 } from "@blueprintjs/core"
import React from "react"

const Step2ChooseName = ({ dep, name, method, submit }) => (
  <div className="sct-fullwidth">
    <H2>Ready?</H2>
    <Card>
      <H4>
        <small>Analyse name:</small> {name}
      </H4>
      <H4>
        <small>Y</small>: {dep.name}
      </H4>
      <H4>
        <small>Method:</small> {method}
      </H4>
    </Card>
    <Button fill onClick={submit} intent="primary" text="Go to analyse builder" />
  </div>
)

export default Step2ChooseName
