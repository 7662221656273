/** @jsxImportSource @emotion/react */
import { MenuItem } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Suggest } from "@blueprintjs/select"
import { SerializedStyles } from "@emotion/react"
import { UseControllerProps, useController } from "react-hook-form"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import useBooksQuery from "../../Portfolio/hooks/useBooksQuery"
import { BookItem } from "../types/common"
import { SpinnerCenter } from "./Components.styled"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"
import { WaveInputs } from "../types/form"

interface IBookSuggestProps extends UseControllerProps<WaveInputs> {
  accountId: number
  customCss?: SerializedStyles
}

export const BookSuggest = ({ accountId, customCss, control, name, rules }: IBookSuggestProps) => {
  const { field, fieldState } = useController({ control, name, rules })

  /**
   * Getting all books that user has access to
   */
  const {
    books,
    isLoading: isLoadingBooks,
    error: booksError,
  } = useBooksQuery({ accountIds: [accountId] })

  //Create Suggest options
  const bookOptions = books.map(book => ({ value: book.id, label: book.code }))

  const renderItem: ItemRenderer<BookItem> = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={item.value}
        onClick={handleClick}
        text={item.label}
      />
    )
  }

  /**
   * Filter the books based on the query
   */
  const filterTicker: ItemPredicate<BookItem> = (query, item, index, exactMatch) => {
    const normalizedTitle = item.label.toLowerCase()
    const normalizedQuery = query.toLowerCase()

    if (exactMatch) {
      return normalizedTitle === normalizedQuery
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0
    }
  }

  if (isLoadingBooks) {
    return <SpinnerCenter size={15} />
  }

  return (
    <FlexContainer alignItems="center">
      {!booksError && (
        <Suggest
          fill
          items={bookOptions}
          itemRenderer={renderItem}
          onItemSelect={(item: BookItem) => field.onChange(item.value)}
          itemPredicate={filterTicker}
          inputValueRenderer={(item: BookItem) => item.label}
          selectedItem={bookOptions.find(book => book.value === field.value)}
          noResults={<MenuItem disabled text="No results." />}
          popoverProps={{ minimal: true }}
          resetOnSelect={true}
          resetOnQuery={true}
          resetOnClose={true}
          css={customCss}
        />
      )}
      {fieldState.error && <ErrorIconWithTooltip errorMessage={fieldState.error.message} />}
    </FlexContainer>
  )
}
