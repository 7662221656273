// @ts-nocheck
import React, { useState } from "react"
import { Button, Drawer } from "@blueprintjs/core"
import { Grid, Row, Col } from "react-flexbox-grid"

export const ButtonWithDrawer = ({ buttonProps, drawerProps, children }) => {
  const [drawer, setDrawer] = useState(false)
  return (
    <>
      <Button onClick={() => setDrawer(true)} {...buttonProps} />
      <Drawer
        className="bp5-dark"
        isOpen={drawer}
        isCloseButtonShown={true}
        onClose={() => setDrawer(false)}
        cancelButtonText="Cancel"
        {...drawerProps}
      >
        <Grid fluid>
          <Row>
            <Col xs={12}>{children}</Col>
          </Row>
        </Grid>
      </Drawer>
    </>
  )
}
