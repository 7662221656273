/** @jsxImportSource @emotion/react */

import { InputGroup } from "@blueprintjs/core"
import { Field, useField, useFormikContext } from "formik"
import { useEffect } from "react"
import { OmniSuggest } from "../../../components"
import { IdeaPosition, IdeaStock } from "../types/business"
import { IIdeaCreateForm } from "./IdeaCreate"
import { tradeOpenInputCss } from "./StockField.styled"

export interface IStockFieldProps {
  ideaPosition: IdeaPosition
  initialSelectedValue?: Partial<IdeaStock>
  isTradeOpen: boolean
}

const StockField = ({ ideaPosition, initialSelectedValue, isTradeOpen }: IStockFieldProps) => {
  const [idField] = useField(`trade.${ideaPosition}.instance.id`)
  /**
   * We need to add the bloombergCode to display it directly
   * in the idea inbox without having to wait for the value to
   * be sent by the backend.
   */
  const [bloombergCodeField] = useField(`trade.${ideaPosition}.instance.bloomberg_code`)
  const { setFieldValue } = useFormikContext<Partial<IIdeaCreateForm>>()

  /**
   * Remove the value when we don't render the stock field.
   * (Meaning that either long or short source is Stock)
   */
  useEffect(() => {
    setFieldValue(idField.name, initialSelectedValue?.id)
    return () => {
      setFieldValue(idField.name, undefined)
      setFieldValue(bloombergCodeField.name, undefined)
    }
  }, [idField.name, bloombergCodeField.name, setFieldValue, initialSelectedValue])

  return (
    <>
      {isTradeOpen ? (
        /** If trade has an open date, stock can't be modifie.
         * So only readOnly InputGroup displays.
         */
        <InputGroup
          defaultValue={initialSelectedValue?.bloomberg_code}
          readOnly={true}
          large
          css={tradeOpenInputCss}
          style={isTradeOpen && { pointerEvents: "none" }}
        />
      ) : (
        <Field name={idField.name}>
          {() => (
            // @ts-ignore
            <OmniSuggest
              onSubmit={(item: { bloomberg_code: string; id: number }) => {
                setFieldValue(bloombergCodeField.name, item.bloomberg_code)
                setFieldValue(idField.name, item.id)
              }}
              exclude={{ ds: true, dsf: true, rf: true }}
              initialSelectedItem={initialSelectedValue}
              inputValueRenderer={item => item.bloomberg_code}
            />
          )}
        </Field>
      )}
    </>
  )
}

export default StockField
