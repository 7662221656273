/** @jsxImportSource @emotion/react */
import { Button, Icon } from "@blueprintjs/core"
import * as React from "react"
import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from "react"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"
import MetricUpdate from "./MetricUpdate"
import {
  MetricsContainer,
  PeriodContainer,
  PeriodItem,
  blankIconCss,
  timeIconCss,
} from "./Metrics.styled"
import { UpdatedMetric, Updates } from "./types/business"

interface IMetrics {
  period: string
  updates: Updates | undefined
  isCollapsed?: boolean
  setIsCollapsed?: Dispatch<SetStateAction<boolean>>
  isMain?: boolean
  hasChildren?: boolean
  metricsIsInCollapse?: boolean
  seenMetrics: string[]
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>
  selectedPeriod: string
  setSelectedPeriod: Dispatch<SetStateAction<string>>
  selectedMetric: string
  setSelectedMetric: Dispatch<SetStateAction<string>>
  upper_key: string
  setSelectedUpdate: Dispatch<SetStateAction<UpdatedMetric | null>>
}

/**
 * hook to know if children are wrapping.
 */
const useWrapCheck = (ref: React.RefObject<HTMLElement>) => {
  const [isWrapping, setIsWrapping] = useState(false)

  useEffect(() => {
    const checkWrap = () => {
      if (!ref.current) {
        return
      }
      const lastItem = ref.current.lastElementChild
      if (lastItem) {
        setIsWrapping(
          lastItem.getBoundingClientRect().top > ref.current.getBoundingClientRect().top,
        )
      }
    }

    window.addEventListener("resize", checkWrap)
    checkWrap()

    return () => window.removeEventListener("resize", checkWrap)
  }, [ref])

  return isWrapping
}

/**
 * "isMain" refers to the less granular period available for a given year.
 * It is always displayed
 */
/**
 * "hasChildren" means main metric has more granular period(s)
 * They will be display in the collapse.
 */
const Metrics = ({
  period,
  updates,
  isCollapsed,
  setIsCollapsed,
  isMain,
  hasChildren,
  metricsIsInCollapse,
  seenMetrics,
  setOpenChartDialog,
  setSelectedPeriod,
  setSelectedMetric,
  setSelectedUpdate,
  upper_key,
}: IMetrics) => {
  /**
   * isContainerWrapping is used here to know if metrics names must display or not in the collapse.
   * If metrics are not wrapped, name are hidden, otherwise name are showed.
   */
  const containerRef = useRef<HTMLDivElement>(null)
  const isContainerWrapping = useWrapCheck(containerRef)

  return (
    <FlexContainer alignItems="flex-start">
      <PeriodContainer flexGrow={1} alignItems="center">
        {isMain ? (
          <>
            {hasChildren && (
              <Button small minimal onClick={() => setIsCollapsed && setIsCollapsed(!isCollapsed)}>
                <Icon icon={isCollapsed ? "caret-right" : "caret-down"} size={20} />
              </Button>
            )}
            <Icon icon="time" color={SCIENT_COLORS.gray3} css={timeIconCss(hasChildren)} />
          </>
        ) : (
          <>
            <Icon icon="blank" css={blankIconCss} />
          </>
        )}
        <PeriodItem>{period}</PeriodItem>
      </PeriodContainer>

      <MetricsContainer
        flexGrow={32}
        justifyContent="flex-start"
        flexWrap="wrap"
        ref={containerRef}
      >
        {updates &&
          Object.values(updates).map((updatedMetric: UpdatedMetric, index) => {
            return seenMetrics.includes(updatedMetric.metric) ? (
              <Fragment key={`${upper_key}-${index}`}>
                <MetricUpdate
                  updatedMetric={updatedMetric}
                  setOpenChartDialog={setOpenChartDialog}
                  setSelectedPeriod={setSelectedPeriod}
                  period={period}
                  setSelectedMetric={setSelectedMetric}
                  metricsIsInCollapse={metricsIsInCollapse}
                  wrapping={isContainerWrapping}
                  setSelectedUpdate={setSelectedUpdate}
                  inChartDialog={false}
                />
              </Fragment>
            ) : (
              <Fragment key={`${upper_key}-${index}`}></Fragment>
            )
          })}
      </MetricsContainer>
    </FlexContainer>
  )
}

export default Metrics
