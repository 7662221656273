import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const SectorPanelContainer = styled(FlexContainer)({
  width: "100%",
  overflow: "auto",
  maxHeight: "300px",
  padding: "5px",
})

export const RadioLabelContainer = styled(FlexContainer)({
  flexBasis: "33.333333%",
  fontSize: "12px",
  marginBottom: "5px",
  alignItems: "center",
  height: "28px",
})
