// @ts-nocheck
import { compose, join, map, take } from "ramda"
import { capitalizeFirstLetter } from "./capitalizeFirstLetter"

// The goal of this function is to expose a string of only the first x elements of an array of string.
// If stringList.length is more than numberOfElementToExpose, so 3 dots will be add a the end of the exposed string.

export const exposeFirstXStringFromList = (stringList, numberOfElementToExpose) => {
  const result = compose(
    join(", "),
    take(numberOfElementToExpose),
    map(e => capitalizeFirstLetter(e)),
  )(stringList)

  return stringList.length > numberOfElementToExpose ? `${result}...` : result
}
