// @ts-nocheck
import { debounceTime, map, switchMap } from "rxjs/operators"
import { searchOmniByName, searchOmniByNameOk } from "../index"

import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import { ofType } from "redux-observable"

const searchOmniByNameEpic = (action$, state$, { ajax, auth, scheduler }, debounceMs = 800) =>
  action$.pipe(
    ofType(searchOmniByName.type),
    debounceTime(debounceMs, scheduler),
    switchMap(action => {
      const { name, exact } = action.payload
      const kinds = action.payload.kinds.join(",")

      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/search/omni/?name=${name}&kinds=${kinds}&exact=${exact}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response: { results } }) => {
          return searchOmniByNameOk({ results })
        }),
      )
    }),
  )

export default searchOmniByNameEpic
