// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { Button, ButtonGroup, Collapse, H3, Icon, Tooltip } from "@blueprintjs/core"

import { SCIENT_COLORS } from "../../../../../../../../../../styled/scientColors"
import { MediumText } from "../../../../../../../../../../styled/text.styled"
import { h3Css, HelperStyled } from "./LoopBuilder.styled"

export const KindChoice = ({ values, setValues, activeItem }) => {
  const loopParams = values.loopParams

  const isGroupFields = loopParams.kind === "fields"
  const isGroupSplits = loopParams.kind === "splits"
  const hasChildren = activeItem.children.length > 0

  const setKindOfLoop = value => {
    // If hasChildren, we don't want to erase the slugs value when click on the kind of loop buttons.
    const slugs = hasChildren ? values.loopParams.slugs : ""
    const isSameKind = values.loopParams.kind && values.loopParams.kind === value
    const newValues = isSameKind
      ? { ...values }
      : {
          ...values,
          loopParams: {
            ...values.loopParams,
            kind: value,
            slugs,
          },
        }
    setValues(newValues)
  }

  return (
    // Opened when a source is chosen or if kind has already been filled (case where user want to change the source in an existing loop)
    <Collapse isOpen={loopParams.src || loopParams.kind}>
      <H3 css={h3Css}>What do you want to loop on ?</H3>
      <ButtonGroup fill large>
        <Button
          active={loopParams.kind === "splits"}
          disabled={isGroupFields && hasChildren}
          onClick={() => setKindOfLoop("splits")}
          text="Splits and Segs"
        />
        <Button
          active={loopParams.kind === "fields"}
          disabled={isGroupSplits && hasChildren}
          onClick={() => setKindOfLoop("fields")}
          text="Custom Fields"
        />
      </ButtonGroup>

      <HelperStyled>
        <Tooltip
          content={
            <MediumText color={SCIENT_COLORS.darkGray5}>
              With <b>Splits and Segs</b> you loop on splits where you can add for <br />
              example a header row then on segments where you must add rows.
            </MediumText>
          }
          placement="bottom"
          openOnTargetFocus={false}
          compact
        >
          <Icon icon="help" size={20} />
        </Tooltip>
        <Tooltip
          content={
            <MediumText color={SCIENT_COLORS.darkGray5}>
              With <b>Custom Fields</b> you loop on custom fields <br />
              where you can add for a header row and a data row.
            </MediumText>
          }
          placement="bottom"
          openOnTargetFocus={false}
          compact
        >
          <Icon icon="help" size={20} />
        </Tooltip>
      </HelperStyled>
    </Collapse>
  )
}
