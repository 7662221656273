// @ts-nocheck
import * as R from "ramda"
import { addIndex, compose, filter, map, prop, propEq, toLower } from "ramda"

import { formatNumber } from "../../../../../../_shared"

export const isDsOrDsf = source => source.path[0].includes("ds")

export const getDsIdDsfId = source => {
  if (source.path.includes("dsf")) {
    const [dsfId, dsId] = R.map(parseInt, source.path[0].replace("dsf", "").split("_"))
    return { dsfId, dsId }
  }
  return { dsId: parseInt(source.path[0].replace("ds", "")), dsfId: null }
}

const mapIndexed = addIndex(map)

export const getDSFLinks = mapIndexed((independent, index) => {
  const { dsfId, dsId } = getDsIdDsfId(independent)
  const baseOutput = {
    ref: index + 1,
    source: { id: dsId },
  }

  return dsfId
    ? { ...baseOutput, subsource: { id: dsfId }, forecast_periods: independent.forecast_periods }
    : baseOutput
})

export const getDsfFormula = (enhancedIndeps, intercept) => {
  const xStringArray = enhancedIndeps.map((indep, index) => {
    return `${indep.sign} ${indep.slope} * ${indep.forecastId ? "F" : "D"}${index + 1}{${toLower(
      indep.freq,
    )},-${indep.periodShift}}`
  })
  return `${intercept} ${xStringArray.join(" ")}`
}

export const formatIndep = (indep, rnk, index) => ({
  index: index + 1,
  name: indep.name,
  sign: indep[`${rnk}_slope`] >= 0 ? "+" : "-",
  slope: Math.abs(formatNumber(indep[`${rnk}_slope`], false, true, 0.000001)),
  periodShift: indep[`${rnk}_period_shift`],
  mav: indep[`${rnk}_mav`],
  color: indep.color,
})

export const enhanceIndep = ({ indep, index, rnk }) => {
  const periodShift = indep[`${rnk}_period_shift`]
  const mav = indep[`${rnk}_mav`]
  const nPeriods = formatPeriods(periodShift, mav)

  return {
    ...indep,
    index: index + 1,
    name: indep.name,
    sign: indep[`${rnk}_slope`] >= 0 ? "+" : "-",
    slope: Math.abs(formatNumber(indep[`${rnk}_slope`], false, true, 0.000001)),
    periodShift,
    nPeriods,
    mav,
  }
}

export const formatPeriods = (periodShift, mav) => {
  if (!periodShift) {
    return "(n)"
  }
  if (mav && mav > 1) {
    const periods = []
    for (let i = 0; i < mav; i++) {
      periods.push(`n - ${periodShift + i}`)
    }
    return `AVG(${periods.join(", ")})`
  }
  return `(n - ${periodShift})`
}

export const getxSLElems = (analyse, rnk, xDSLsLength) =>
  compose(
    mapIndexed((sl, index) => ({
      index: xDSLsLength + index + 1,
      sign: sl[`${rnk}_slope`] >= 0 ? "+" : "-",
      slope: Math.abs(formatNumber(sl[`${rnk}_slope`], false, true, 0.000001)),
      periodShift: sl[`${rnk}_period_shift`],
      mav: sl[`${rnk}_mav`],
      name: `${sl.stock.name} (${sl.path})`,
      color: sl.color,
    })),
    filter(propEq("kind", "x")),
    prop("stocklinks"),
  )(analyse)
