// @ts-nocheck
import * as R from "ramda"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { H3 } from "@blueprintjs/core"

import { Grid, Row, Button } from "../../../../components"
import Folder from "./Folder"

const MyAnalyses = ({ analyses, profile }) => {
  const navigate = useNavigate()

  const [openFolder, setOpenFolder] = useState(null)

  const folders = useSelector(R.path(["analysis", "folders", "list"]))

  const foldersPlusUnsorted = [...folders, { id: null, owner_id: profile.id, name: "Unsorted" }]

  const foldedAnalyses = R.groupBy(a => a.folder_id, analyses)

  return (
    <>
      <H3>My analyses</H3>
      {!R.isEmpty(analyses) ? (
        foldersPlusUnsorted.map(folder => (
          <Folder
            key={folder.id}
            analyses={foldedAnalyses[folder.id]}
            myGroups={profile.groups}
            allFolders={foldersPlusUnsorted}
            folder={folder}
            openFolder={openFolder}
            setOpenFolder={setOpenFolder}
          />
        ))
      ) : (
        <Grid style={{ marginTop: "20px" }}>
          <Row center="xs">
            <div>
              <p>
                <em>You don't have any analyse yet.</em>
              </p>
              <Button
                large
                onClick={() => navigate("/analysis/new")}
                icon="regression-chart"
                intent="primary"
                text="Create one"
              />
            </div>
          </Row>
        </Grid>
      )}
    </>
  )
}
export default MyAnalyses
