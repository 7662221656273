import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useApi } from "../../../hooks/useApi"
import { ConversationMessage } from "../types/business"

/**
 * React query hook to POST new Message
 */

const useMessageListMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (message: ConversationMessage) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.messages.create, { onSuccess, onError })
}

export default useMessageListMutation
