// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { Button, H3, H4 } from "@blueprintjs/core"
import { pick } from "ramda"
import { connect } from "react-redux"
import { compose, lifecycle, withHandlers } from "recompose"

import dataSetSearchDuck, { initialFilters } from "../../../../../state/modules/dataSetSearch"
import geosDuck from "../../../../../state/modules/geos"
import sectorsDuck from "../../../../../state/modules/sectors"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { noMarginCss } from "../../../../../styled/text.styled"
import returnSearchValue from "../../../../_shared/returnSearchValue"
import { InputGroup, Select, Switch } from "../../../../components"
import { withToggle } from "../../../../hooks"
import FilterWidget from "./FilterWidget"

const options = [
  { label: "Name ⬆︎", value: "name" },
  { label: "Name ⬇︎", value: "-name" },
  { label: "Last release ⬇︎", value: "-last_release" },
  { label: "Last value ⬇︎", value: "-last_value" },
  { label: "Last value ⬆︎", value: "last_value" },
  { label: "Sector 1 ⬆︎", value: "sector_1__name" },
  { label: "Sector 1 ⬇︎", value: "-sector_1__name" },
  { label: "WoW ⬇︎", value: "-chg_1w" },
  { label: "MoM ⬇︎", value: "-chg_1m" },
  { label: "YoY ⬇︎", value: "-chg_1y" },
  { label: "MA3M ⬇︎", value: "-ma3m_12m" },
]

const Sidebar = ({
  dataSetSearch,
  updateSearch,
  geos,
  sectors,
  searchDataSet,
  sort,
  checkboxToggle,
}) => {
  return (
    <FlexContainer flexDirection="column" gap="14px">
      <FlexContainer justifyContent="space-between" alignItems="center">
        <H3 css={noMarginCss}>Filter</H3>
        <Button
          icon="filter-remove"
          text="Clear all filters"
          onClick={() => searchDataSet(initialFilters)}
        />
      </FlexContainer>

      <InputGroup
        inputProps={{
          datacy: "SearchDataSet",
          loading: dataSetSearch.searching,
          value: dataSetSearch.search,
          onChange: updateSearch,
          placeholder: "Search by name or ticker",
          autoFocus: true,
          rightElement: dataSetSearch.search ? (
            <Button minimal icon="delete" onClick={() => searchDataSet({ search: "" })} />
          ) : null,
        }}
      />

      <FlexContainer flexDirection="column" gap="8px">
        <FilterWidget
          type="sectors"
          all={sectors}
          selected={dataSetSearch.sectors}
          fetch={searchDataSet}
        />
        <FilterWidget type="geos" all={geos} selected={dataSetSearch.geos} fetch={searchDataSet} />
      </FlexContainer>

      <FlexContainer flexDirection="column">
        <Switch
          datacy="EcoOnly"
          checked={dataSetSearch.eco_only}
          name="eco_only"
          label="Economic data only"
          onChange={checkboxToggle}
        />
        <Switch
          datacy="HideDaily"
          checked={dataSetSearch.hide_daily}
          name="hide_daily"
          label="Hide Daily"
          onChange={checkboxToggle}
        />
        <Switch
          datacy="HideBbg"
          checked={dataSetSearch.hide_bbg}
          name="hide_bbg"
          label="Hide Bloomberg"
          onChange={checkboxToggle}
        />
      </FlexContainer>

      <FlexContainer flexDirection="column">
        <H4>Sort</H4>
        <Select
          selectProps={{
            placeholder: "1st criteria",
            options: options,
            value: dataSetSearch.ordering["1"],
            onChange: ({ currentTarget: { value } }) => sort(1, value),
            fill: true,
          }}
        />
        <Select
          selectProps={{
            placeholder: "2nd criteria",
            options: options,
            value: dataSetSearch.ordering["2"],
            onChange: ({ currentTarget: { value } }) => sort(2, value),
            fill: true,
          }}
        />
        <Select
          selectProps={{
            placeholder: "3rd criteria",
            options: options,
            value: dataSetSearch.ordering["3"],
            onChange: ({ currentTarget: { value } }) => sort(3, value),
            fill: true,
          }}
        />
      </FlexContainer>
    </FlexContainer>
  )
}

const mapStateToProps = ({ dataSetSearch, geos, sectors }) => ({
  dataSetSearch: pick(
    ["searching", "search", "geos", "sectors", "eco_only", "hide_daily", "hide_bbg", "ordering"],
    dataSetSearch,
  ),
  geos: geos.geos,
  fetchingGeos: geos.fetching,
  sectors: sectors.sectors,
  fetchingSectors: sectors.fetching,
})

const mapDispatchToProps = {
  fetchGeos: geosDuck.actions.fetchGeos,
  fetchSectors: sectorsDuck.actions.fetchSectors,
  searchDataSet: dataSetSearchDuck.actions.searchDataSet,
  clearDataSetSearch: dataSetSearchDuck.actions.clearDataSetSearch,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchGeos("?levels=1,2,3")
      this.props.fetchSectors("?levels=1,2,3")
    },
  }),
  withHandlers({
    updateSearch: ({ searchDataSet }) => compose(searchDataSet, returnSearchValue),
    checkboxToggle:
      ({ searchDataSet }) =>
      ({ currentTarget: { name, checked } }) =>
        searchDataSet({ [name]: checked }),
    sort:
      ({ searchDataSet }) =>
      (id, value) =>
        searchDataSet({ ordering: { [id]: value } }),
  }),
  withToggle,
)(Sidebar)
