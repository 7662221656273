// @ts-nocheck
import autodux from "autodux"

import * as actions from "./actions"

const dataframesDuck = autodux({
  slice: "dataframes",
  initial: {
    fetchingDfForSheets: {},
    byDfId: {},
    searching: false,
    results: [],
  },
  actions,
})

export default dataframesDuck
