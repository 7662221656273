import { Button, Icon, Intent } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import * as DOMPurify from "dompurify"
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import TinyMCEFullscreenIcon from "../../../../../icons/TinuMCEFullScreenIcon"
import { FlexContainer, FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { FullScreenFlexContainer } from "../../../../../styled/fullscreen.styled"
import { MediumText } from "../../../../../styled/text.styled"
import { Idea, IdeaRevision, IdeaType } from "../../types/business"
import {
  IdeaContent,
  IdeaContentContainer,
  IdeaDetailsContainer,
  IdeaDetailsOptions,
} from "./IdeaDetails.styled"
import IdeaNoteInfos from "./IdeaNoteInfos"
import IdeaPositions from "./IdeaPositions"
import IdeaStructuralInfos from "./IdeaStructuralInfos"
import MessagesList from "./MessagesList"
import RevisionsList from "./RevisionsList"
import { SCIENT_ROUTES } from "../../../../Routes"
import { useGlobalState } from "../../../../hooks/useGlobalState"
import { useAuth } from "../../../../hooks"

interface IIdeaDetailsProps {
  idea: Idea
  setSelectedRevision: Dispatch<SetStateAction<IdeaRevision | undefined>>
  selectedRevision?: IdeaRevision
}

const IdeaDetails = ({ idea, selectedRevision, setSelectedRevision }: IIdeaDetailsProps) => {
  const [isCollapse, setIsCollapse] = useState(true)
  const [fullscreen, setFullscreen] = useState(false)
  const navigate = useNavigate()

  const { profile } = useAuth()

  const isNoteWithInfos = useMemo(() => {
    return (
      (idea.related_stocks.length > 0 || !!idea.meeting_date) && idea.idea_type === IdeaType.NOTE
    )
  }, [idea.related_stocks.length, idea.meeting_date, idea.idea_type])

  /**
   * On click fullscreen icon, we switch the boolean state
   */
  const clickFullscreen = useCallback(() => {
    setFullscreen(fullscreen => {
      return !fullscreen
    })
  }, [setFullscreen])

  return (
    <IdeaDetailsContainer flexGrow={1} flexDirection="column" justifyContent="space-between">
      {idea.idea_type === IdeaType.TRADE && <IdeaPositions idea={idea} />}
      {isNoteWithInfos && <IdeaNoteInfos idea={idea} />}
      {idea.idea_type === IdeaType.STRUCTURAL && !!selectedRevision && (
        <IdeaStructuralInfos idea={idea} selectedRevision={selectedRevision} />
      )}
      <FullScreenFlexContainer flexGrow={1} flexDirection="column" fullscreen={fullscreen}>
        <IdeaDetailsOptions
          flexDirection="row-reverse"
          withoutBorderTopRadius={isNoteWithInfos}
          alignItems="center"
          gap="30px"
        >
          <TinyMCEFullscreenIcon onClick={clickFullscreen} fullscreen={fullscreen} />
          {idea.idea_type === IdeaType.STRUCTURAL && profile?.id === idea.user.id && (
            <FlexContainerAndItem flexGrow={1}>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => navigate(`${SCIENT_ROUTES.IDEAS}/${idea.id}/revision/create`)}
              >
                <FlexContainer gap="10px" alignItems="center">
                  <MediumText fontWeight="bold">Create new revision</MediumText>
                  <Icon icon={IconNames.SEND_MESSAGE} size={12} />
                </FlexContainer>
              </Button>
            </FlexContainerAndItem>
          )}
          <FlexContainerAndItem flexGrow={profile?.id === idea.user.id ? undefined : 1}>
            <RevisionsList
              idea={idea}
              setSelectedRevision={setSelectedRevision}
              selectedRevision={selectedRevision}
              setRead
              displayConversationNotRead
            />
          </FlexContainerAndItem>
        </IdeaDetailsOptions>
        <IdeaContentContainer flexGrow={1} isCollapse={isCollapse} flexDirection="column">
          {!!selectedRevision && (
            <IdeaContent
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedRevision.content) }}
            />
          )}
        </IdeaContentContainer>
        {!fullscreen && !!selectedRevision && (
          <MessagesList
            idea={idea}
            isCollapse={isCollapse}
            setIsCollapse={setIsCollapse}
            selectedRevision={selectedRevision}
          />
        )}
      </FullScreenFlexContainer>
    </IdeaDetailsContainer>
  )
}

export default IdeaDetails
