// @ts-nocheck
import { datadogRum } from "@datadog/browser-rum"
import { getEnv } from "../utils/environment"

const initDatadogRum = () => {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.eu",
    service: "scient-analytics",
    env: getEnv(),
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 5,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  })

  datadogRum.startSessionReplayRecording()
}

export default initDatadogRum
