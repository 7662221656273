import { useInfiniteQuery } from "@tanstack/react-query"
import { useApi } from "../../../hooks/useApi"
import { ConversationMessage } from "../types/business"

interface IIfinititeMessages {
  conversationId: number | undefined
}

/**
 * React query hook to GET Messages paginated
 */
const useInfiniteMessages = ({ conversationId }: IIfinititeMessages) => {
  const { scientApi } = useApi()

  const { data, error, fetchNextPage, hasNextPage, status, isFetching, dataUpdatedAt } =
    useInfiniteQuery(
      [`messages-${conversationId}`, { conversationId }],
      ({ pageParam }) =>
        // We can safely force conversationId as sumber since we enable the query only if convId exists
        scientApi.ideas.messages.getInfiniteMessages(pageParam, conversationId as number),
      {
        getNextPageParam: (lastPage, pages) => {
          if (lastPage.next_cursor) {
            return lastPage.next_cursor
          }
          return undefined
        },
        // https://github.com/TanStack/query/issues/3065
        // TODO: once v5 is out, allow typing on select for infinite data
        // @ts-ignore
        select: data => {
          let messages: ConversationMessage[] = []
          for (const page of data.pages) {
            messages = [...messages, ...page.data]
          }
          return messages
        },
        staleTime: Infinity,
        enabled: !!conversationId,
      },
    )

  return {
    // @ts-ignore
    messages: data as ConversationMessage[] | undefined,
    fetchNextPage,
    hasNextPage,
    error,
    status,
    isFetching,
    dataUpdatedAt,
  }
}

export default useInfiniteMessages
