// @ts-nocheck
import { Button, H3 } from "@blueprintjs/core"
import { isEmpty } from "ramda"
import { useDispatch, useSelector } from "react-redux"
import { Col, Grid, Row } from "../../../../components"

import dsForecastsDuck from "../../../../../state/modules/dsForecasts"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { MediumText } from "../../../../../styled/text.styled"
import { HelpTooltip, Loader } from "../../../../components"
import Create from "./Create"
import Item from "./Item"

const ForecastList = ({ dataSet, pastEntries }) => {
  const dispatch = useDispatch()
  const fetchForecasts = params => dispatch(dsForecastsDuck.actions.fetchForecasts(params))

  const dsForecasts = useSelector(({ dsForecasts }) => dsForecasts.dsForecasts.results)
  const periodicity = useSelector(({ dataSets }) =>
    isEmpty(dataSets.dataSet) ? null : dataSets.dataSet.periodicity,
  )
  const profile = useSelector(({ profile }) => profile)

  if (!dsForecasts) return <Loader title="Loading forecasts" />

  return (
    <Grid>
      <Row>
        <Col xs={8}>
          <H3>
            Forecasts{" "}
            <Button onClick={() => fetchForecasts({ dataset: dataSet.id })} text="Refresh all " />
            <HelpTooltip
              content={
                <MediumText color={SCIENT_COLORS.darkGray5}>
                  Refresh all will update your forecasts and forecasts shared with you.
                  <br />
                  It will also close detail panels below.
                </MediumText>
              }
            />
          </H3>
        </Col>
        <Col xs={4}>
          <Create dataSet={dataSet} />
        </Col>
      </Row>
      {dsForecasts && (
        <div>
          {dsForecasts
            .sort((a, b) => {
              if (a.owner === profile.username && b.owner !== profile.username) return -1
              if (a.owner !== profile.username && b.owner === profile.username) return 1
              return 0
            })
            .map((dsforecast, index) => (
              <Item
                key={dsforecast.id}
                index={index}
                dataSet={dataSet}
                dsforecast={dsforecast}
                pastEntries={pastEntries}
                periodicity={periodicity}
                profile={profile}
              />
            ))}
        </div>
      )}
    </Grid>
  )
}

export default ForecastList
