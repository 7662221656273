import { Sector } from "../../../../api/world"
import FormikRadioLabel from "./FormikRadioLabel"
import { RadioLabelContainer, SectorPanelContainer } from "./SectorPanel.styled"

interface ISectorPanelProps {
  sectors: Sector[]
  name: string
  disabled: boolean
}

const SectorPanel = ({ sectors, name, disabled }: ISectorPanelProps) => {
  return (
    <SectorPanelContainer flexWrap="wrap">
      {sectors.map(sector => {
        return (
          <RadioLabelContainer key={sector.id} flexDirection="row" gap="5px">
            <FormikRadioLabel
              enableAutoFocus
              gap="3px"
              name={name}
              value={sector.id.toString()} // We need to convert id to string otherwise formik fail to select the radio correctly (https://stackoverflow.com/questions/63956208/formik-field-radio-button-is-clicked-and-value-got-updated-but-does-not-showing)
              label={sector.name}
              disabled={disabled}
            />
          </RadioLabelContainer>
        )
      })}
    </SectorPanelContainer>
  )
}

export default SectorPanel
