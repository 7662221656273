/** @jsxImportSource @emotion/react */

import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { formatISO } from "date-fns"
import * as DOMPurify from "dompurify"
import { Form, Formik } from "formik"
import { cloneDeep } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { SCIENT_ROUTES } from "../../../Routes"
import { useAuth, useIdeasCache } from "../../../hooks"
import { useGlobalState } from "../../../hooks/useGlobalState"
import { createToaster } from "../../../utils/createToaster"
import {
  useCreateIdeaMutation,
  useInfiniteIdeas,
  useNewRevisionMutation,
  useUpdateIdeaMutation,
} from "../hooks"
import useSearchIdeas from "../hooks/useSearchIdeas"
import {
  Idea,
  IdeaRevision,
  IdeaSector,
  IdeaStock,
  IdeaType,
  PositionType,
  SourceType,
} from "../types/business"
import {
  IdeaCreateContainer,
  LeftContainer,
  RightContainer,
  ideaCreateFormCss,
} from "./IdeaCreate.styled"
import IdeaCreateConviction from "./IdeaCreateConviction"
import ideaCreateFormSchema from "./IdeaCreateFormSchema"
import IdeaCreateHeader from "./IdeaCreateHeader"
import IdeaCreateMeetingDate from "./IdeaCreateMeetingDate"
import IdeaCreateParticipants from "./IdeaCreateParticipants"
import IdeaCreateRecommendation from "./IdeaCreateRecommendation"
import IdeaCreateRelatedStockOrSector from "./IdeaCreateRelatedStockOrSector"
import IdeaCreateSource from "./IdeaCreateSource"
import IdeaCreateTargetPrice from "./IdeaCreateTargetPrice"
import IdeaCreateTextArea from "./IdeaCreateTextArea"
import IdeaCreateTitle from "./IdeaCreateTitle"
import IdeaCreateType from "./IdeaCreateType"

export interface IIdeaCreateForm {
  title?: string
  source?: string
  source_type?: SourceType
  idea_type: IdeaType
  revision?: IdeaRevision
  revisions?: IdeaRevision[]
  trade?: {
    short: { instance: Partial<IdeaSector> | Partial<IdeaStock> }
    long: { instance: Partial<IdeaSector> | Partial<IdeaStock> }
  }
  draft: boolean
  related_stocks?: Partial<IdeaStock>[]
  related_sector?: Partial<IdeaSector>
  meeting_date: string | null
  participants?: number[]
}

const IdeaCreate = () => {
  const { profile } = useAuth()
  const queryClient = useQueryClient()

  /**
   * outletContext def (remove comment when we have TS)
   */
  const [{ setSelectedIdeaId, setSelectedInboxRevision }] = useOutletContext<
    [
      {
        setSelectedIdeaId: React.Dispatch<React.SetStateAction<number | undefined>>
        setSelectedInboxRevision: React.Dispatch<React.SetStateAction<IdeaRevision | undefined>>
      },
    ]
  >()
  const navigate = useNavigate()
  const {
    stockFilter,
    sectorFilter,
    userFilter,
    draftFilter,
    ideaTypeFilter,
    tradeOpenOnly,
    tradeClosedOnly,
    orderBy,
    sortDirection,
  } = useIdeasCache()

  /**
   * State to track when an image is uploading
   * for Disable Submit buttion state
   */
  const [isUploadingImage, setUploadingImage] = useState(false)

  /**
   * Selected revision for update
   */
  const [selectedRevision, setSelectedRevision] = useState<IdeaRevision | undefined>()

  /**
   * Create/Update/NewRevision Idea Mutations
   */
  const createIdeaMutation = useCreateIdeaMutation({
    onSuccess: (idea: Idea) => {
      /**
       * When we receive the answer from back, we need to refetch the Ideas
       * since the page changes and even if we would update the cache,
       * the infinite Hook used is not the same as the one used in the Ideas page
       * Hence not firing a rerender
       * In order to just refetch the first page, we remove the query from the cache
       */
      queryClient.removeQueries(["ideas"])
      setSelectedIdeaId(idea.id)

      /**
       * On creation there is always only one revision
       * we set it selected and navigate to the Idea page
       */
      setSelectedInboxRevision(idea.revisions[0])
      navigate(SCIENT_ROUTES.IDEAS, { state: { draft: idea.draft } })
    },
    onError: (error: AxiosError) => {
      createToaster({
        message: "An error occurred creating the Idea, please refresh the page and retry.",
        intent: "danger",
      })
    },
  })

  const updateIdeaMutation = useUpdateIdeaMutation({
    onSuccess: (idea: Idea) => {
      queryClient.removeQueries(["ideas"])
      setSelectedIdeaId(idea.id)
      setSelectedInboxRevision(
        idea.revisions.sort(
          (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        )[idea.revisions.length - 1],
      )
      /**
       * We pass draft in location to land on draft Inbox going back on Ideas dashboard
       */
      navigate(SCIENT_ROUTES.IDEAS, { state: { draft: idea.draft } })
    },
    onError: (error: AxiosError) => {
      /**
       * Create toaster instance and use show
       * since we are in an async loop (we want the toaster to show
       * whatever the page)
       */
      createToaster({
        message: "An error occurred updating the Idea, please refresh the page",
        intent: "danger",
      })
    },
  })

  const createNewRevisionMutation = useNewRevisionMutation({
    onSuccess: (revision: IdeaRevision) => {
      queryClient.removeQueries(["ideas"])
      setSelectedIdeaId(revision.idea_id)
      setSelectedInboxRevision(revision)
      navigate(SCIENT_ROUTES.IDEAS)
    },
    onError: (error: AxiosError) => {
      createToaster({
        message: "An error occurred creating the new revision, please refresh the page",
        intent: "danger",
      })
    },
  })

  /**
   * React router hooks to retrieve the ideaId in the url
   * and the state of draft.
   */
  const { ideaId } = useParams()

  /**
   * Check wether we are creating a new revision
   */
  const { pathname } = useLocation()
  const isRevisionCreateMode = useMemo(() => pathname.includes("revision/create"), [pathname])

  const { ideas } = useInfiniteIdeas({
    stockParam: stockFilter?.id,
    sectorParam: sectorFilter?.id,
    userParam: userFilter?.id,
    draftParam: draftFilter,
    ideaTypeParam: ideaTypeFilter,
    tradeOpenOnlyParam: tradeOpenOnly,
    tradeClosedOnlyParam: tradeClosedOnly,
    orderByParam: orderBy,
    sortDirectionParam: sortDirection,
  })
  const [ideaToUpdate, setideaToUpdate] = useState<Idea>()

  /**
   * We put draft from filter to prefill draft state if coming from draft view
   */
  const [initialValues, setInitialValues] = useState<Partial<IIdeaCreateForm>>({
    idea_type: IdeaType.TRADE,
    draft: draftFilter,
    related_stocks: [],
  })

  /**
   * Wether or not we fetch the idea if not present in the cache
   */
  const [searchQueryEnabled, setSearchQueryEnabled] = useState(false)

  /**
   * We use search hook to get the idea from the query params
   * if we have an ideaId in the url
   * There can be only one idea in the array
   */
  const { ideas: ideasFound } = useSearchIdeas({
    id: ideaId ? parseInt(ideaId) : undefined,
    stock: undefined,
    user: undefined,
    enabled: searchQueryEnabled && !!ideaId,
  })

  /**
   * Check if we have an 'ideaId' inside the url.
   * In that case we retrieve the idea to update in the ideas cached.
   */
  useEffect(() => {
    if (ideaId) {
      const idea = ideas?.find(idea => idea.id === parseInt(ideaId))
      if (idea) {
        idea.revisions.sort((a, b) => {
          return (
            (a.created_at ? new Date(a.created_at).getTime() : new Date().getTime()) -
            (b.created_at ? new Date(b.created_at).getTime() : new Date().getTime())
          )
        })

        /**
         * We clone Deep to be able to modify the object without modifying the cache
         */
        const ideaToUpdate = cloneDeep(idea)
        /**
         * Adding a new blank revision to the deep copied ideaToUpdate
         * if we are in revision create mode
         */
        ideaToUpdate.revisions = isRevisionCreateMode
          ? [...ideaToUpdate.revisions, { created_at: formatISO(new Date()) } as IdeaRevision]
          : ideaToUpdate.revisions
        setideaToUpdate(ideaToUpdate)
      } else {
        /**
         * In case the idea is not in the cache, we need to fetch it
         * We hence, enable the query to fetch the idea
         * What should be done in the future:
         * - Do not use hook useInfiniteQuery in Create
         * - Always fetch single idea from back on this new view
         * - Pass filters through query params
         */
        setSearchQueryEnabled(true)
      }
    }
  }, [ideaId, ideas, isRevisionCreateMode])

  /**
   * If we found and idea, we set the ideaToUpdate state
   */
  useEffect(() => {
    if (ideasFound && ideasFound.length > 0 && !ideaToUpdate) {
      const ideaToUpdate = cloneDeep(ideasFound[0])
      ideaToUpdate.revisions = isRevisionCreateMode
        ? [...ideaToUpdate.revisions, { created_at: formatISO(new Date()) } as IdeaRevision]
        : ideaToUpdate.revisions
      setideaToUpdate(ideaToUpdate)
    }
  }, [ideaToUpdate, ideasFound, isRevisionCreateMode])

  const tradeOpenedAt = ideaToUpdate?.trade?.opened_at

  /**
   * Use the values retrieved from the ideaToUpdate to feed the initial
   * values of the form.
   * Need to transform null value of title and source into empty string
   * to avoid warning about controled/uncontroled component
   */
  useEffect(() => {
    if (ideaToUpdate) {
      const initialValues: Partial<IIdeaCreateForm> = {}
      initialValues.source_type = ideaToUpdate.source_type ?? undefined
      initialValues.idea_type = ideaToUpdate.idea_type
      initialValues.revisions = ideaToUpdate.revisions
      initialValues.title = ideaToUpdate.title ? ideaToUpdate.title : undefined
      initialValues.source = ideaToUpdate.source ?? undefined
      if (ideaToUpdate.idea_type === IdeaType.TRADE) {
        initialValues.trade = {
          short: {
            instance: {
              id: ideaToUpdate?.trade?.short.instance.id,
              position_type: ideaToUpdate?.trade?.short.instance.position_type,
            },
          },
          long: {
            instance: {
              id: ideaToUpdate?.trade?.long.instance.id,
              position_type: ideaToUpdate?.trade?.long.instance.position_type,
            },
          },
        }
      }
      initialValues.draft = ideaToUpdate.draft
      initialValues.related_stocks = ideaToUpdate.related_stocks ?? []
      initialValues.related_sector = ideaToUpdate.related_sector ?? undefined
      initialValues.meeting_date = ideaToUpdate.meeting_date ?? undefined
      initialValues.participants = ideaToUpdate.participants?.map(participant => participant.id)
      setInitialValues(initialValues)
    }
  }, [ideaToUpdate, isRevisionCreateMode, selectedRevision])

  /**
   * Callback to submit Form
   */
  const submitForm = useCallback(
    ({
      revision,
      source,
      title,
      draft,
      participants,
      revisions,
      ...rest
    }: Partial<IIdeaCreateForm>) => {
      /**
       * Sanitize HTML
       */
      if (revision) {
        revision.content = DOMPurify.sanitize(revision.content)
      }
      if (revisions) {
        revisions.forEach(revision => {
          revision.content = DOMPurify.sanitize(revision.content)
        })
      }

      /**
       * Remove start and end white spaces from source and title
       * If source or title are empty, we send null instead of empty string
       */
      const cleanedSource = source ? source?.trim() : undefined
      const cleanedTitle = title ? title?.trim() : undefined

      if (ideaId) {
        if (isRevisionCreateMode && revisions) {
          /**
           * Create new revision case
           */
          createNewRevisionMutation.mutate({
            ideaId,
            revision: {
              ...revisions[revisions.length - 1],
            },
          })
        } else {
          /**
           * Update case
           */
          updateIdeaMutation.mutate({
            ideaId,
            values: {
              source: cleanedSource,
              title: cleanedTitle,
              draft,
              revisions,
              participants,
              ...rest,
            },
          })
        }
      } else {
        /**
         * Create case
         */

        const newIdea = {
          ...{ source, title, draft, revision, participants, ...rest },
          user: {
            username: profile?.username,
            id: profile?.id,
          },
        }

        // Launch HTTP query to create idea
        createIdeaMutation.mutate(newIdea)
      }
    },
    [
      createIdeaMutation,
      createNewRevisionMutation,
      ideaId,
      isRevisionCreateMode,
      profile?.id,
      profile?.username,
      updateIdeaMutation,
    ],
  )

  /**
   * Keep track of selected content field name as handling of callout error
   * for content is in Header
   */
  const [contentFieldName, setContentFieldName] = useState<string>(
    ideaToUpdate ? `revisions[${ideaToUpdate.revisions.length - 1}].content` : "revision.content",
  )

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={ideaCreateFormSchema(!!ideaToUpdate)}
        validateOnChange={false}
        onSubmit={submitForm}
        validateOnBlur={false}
      >
        {({ handleSubmit, values }) => {
          return (
            <>
              <Form css={ideaCreateFormCss} onSubmit={handleSubmit}>
                <IdeaCreateContainer>
                  <LeftContainer flexDirection="column">
                    <IdeaCreateHeader
                      isCreatingDraft={draftFilter}
                      submitDisabled={isUploadingImage}
                      selectedRevision={selectedRevision}
                      setSelectedRevision={setSelectedRevision}
                      idea={ideaToUpdate}
                      isLoading={createIdeaMutation.isLoading || updateIdeaMutation.isLoading}
                      contentFieldName={contentFieldName}
                    />
                    {ideaToUpdate ? (
                      ideaToUpdate.revisions.map((revision, index, revisions) => {
                        return (
                          <IdeaCreateTextArea
                            key={`revision-content-${revision.id}`}
                            setUploadingImage={setUploadingImage}
                            fieldName={`revisions[${revisions.indexOf(revision)}].content`}
                            hidden={selectedRevision?.id !== revision.id}
                            setContentFieldName={setContentFieldName}
                            disabled={isRevisionCreateMode ? index !== revisions.length - 1 : false}
                          />
                        )
                      })
                    ) : (
                      <IdeaCreateTextArea
                        setUploadingImage={setUploadingImage}
                        fieldName="revision.content"
                        hidden={false}
                        setContentFieldName={setContentFieldName}
                      />
                    )}
                  </LeftContainer>
                  <RightContainer flexDirection="column">
                    <IdeaCreateTitle disabled={isRevisionCreateMode} />
                    <IdeaCreateType
                      initialSeletedStockLong={
                        initialValues?.trade?.long?.instance.position_type === PositionType.STOCK
                          ? ideaToUpdate?.trade?.long.instance
                          : undefined
                      }
                      initialSeletedStockShort={
                        initialValues?.trade?.short?.instance.position_type === PositionType.STOCK
                          ? ideaToUpdate?.trade?.short.instance
                          : undefined
                      }
                      tradeOpenedAt={tradeOpenedAt}
                      disabled={!!ideaToUpdate}
                    />
                    {values.idea_type === IdeaType.STRUCTURAL && (
                      <IdeaCreateRelatedStockOrSector
                        disabled={ideaToUpdate?.idea_type === IdeaType.STRUCTURAL}
                      />
                    )}
                    {values.idea_type !== IdeaType.NOTE ? (
                      ideaToUpdate ? (
                        ideaToUpdate.revisions.map((revision, index, revisions) => {
                          return (
                            <IdeaCreateConviction
                              key={`revision-conviction-${revision.id}`}
                              fieldName={`revisions[${revisions.indexOf(revision)}].conviction`}
                              disabled={
                                isRevisionCreateMode
                                  ? index !== revisions.length - 1
                                  : [IdeaType.TRADE, IdeaType.STRUCTURAL].includes(
                                      ideaToUpdate?.idea_type,
                                    )
                              }
                              hidden={selectedRevision?.id !== revision.id}
                              index={index + 1}
                            />
                          )
                        })
                      ) : (
                        <IdeaCreateConviction fieldName="revision.conviction" disabled={false} />
                      )
                    ) : null}
                    {values.idea_type === IdeaType.STRUCTURAL && (
                      <>
                        {ideaToUpdate ? (
                          ideaToUpdate.revisions.map((revision, index, revisions) => {
                            return (
                              <IdeaCreateRecommendation
                                key={`revision-recommendation-${revision.id}`}
                                fieldName={`revisions[${revisions.indexOf(
                                  revision,
                                )}].recommendation`}
                                hidden={selectedRevision?.id !== revision.id}
                                disabled={
                                  isRevisionCreateMode ? index !== revisions.length - 1 : true
                                }
                                index={index + 1}
                              />
                            )
                          })
                        ) : (
                          <IdeaCreateRecommendation
                            hidden={false}
                            fieldName="revision.recommendation"
                          />
                        )}
                        {ideaToUpdate ? (
                          ideaToUpdate.revisions.map((revision, index, revisions) => {
                            return (
                              <IdeaCreateTargetPrice
                                key={`revision-target-price-${revision.id}`}
                                fieldName={`revisions[${revisions.indexOf(revision)}].target_price`}
                                hidden={selectedRevision?.id !== revision.id}
                                disabled={
                                  isRevisionCreateMode ? index !== revisions.length - 1 : true
                                }
                                index={index + 1}
                              />
                            )
                          })
                        ) : (
                          <IdeaCreateTargetPrice hidden={false} fieldName="revision.target_price" />
                        )}
                      </>
                    )}
                    {!!values.idea_type &&
                      ![IdeaType.STRUCTURAL, IdeaType.TRADE].includes(values.idea_type) && (
                        <IdeaCreateSource />
                      )}
                    {values.idea_type === IdeaType.NOTE &&
                      values.source_type !== SourceType.PROPRIETARY_THINKING && (
                        <>
                          <IdeaCreateMeetingDate />
                          <IdeaCreateParticipants />
                        </>
                      )}
                  </RightContainer>
                </IdeaCreateContainer>
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default IdeaCreate
