// @ts-nocheck
import { getTime, subDays } from "date-fns"
import { isEmpty, isNil } from "ramda"

export const minusOneDayData = data => {
  if (isNil(data) || isEmpty(data)) return null
  let newData = data
  if (typeof data === "string") {
    newData = JSON.parse(data)
  }
  return newData.map(point => {
    return [getTime(subDays(new Date(point[0]), 1)), point[1]]
  })
}
