// @ts-nocheck
import React from "react"
import { Dialog } from "@blueprintjs/core"

import Content from "./Content"

const EditModal = ({ editRowItems, setEditRowItems, sheet }) => {
  const { show } = editRowItems

  return (
    <Dialog
      className="bp5-dark "
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon="plus"
      title={`Edit ${sheet.name} rows`}
      intent="primary"
      isOpen={show}
      isCloseButtonShown={false}
      enforceFocus={false}
      style={{
        height: "100vh",
        width: "100vw",
        margin: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Content sheet={sheet} editRowItems={editRowItems} setEditRowItems={setEditRowItems} />
    </Dialog>
  )
}

export default EditModal
