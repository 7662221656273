export const defaultLength = (periodicity: string) => {
  switch (periodicity) {
    case "Y":
      return 5
    case "H":
      return 10
    case "Q":
      return 20
    case "BM":
      return 30
    case "W":
      return 200
    case "M":
    case "D":
    case "R":
      return 60
    default:
      throw new Error("In duration")
  }
}
