// @ts-nocheck
/** @jsxImportSource @emotion/react */

import React from "react"
import { includes } from "ramda"
import { Button, ButtonGroup, Collapse, H3 } from "@blueprintjs/core"

import { h3Css } from "./LoopBuilder.styled"

export const SplitChoice = ({ values, setValues }) => {
  const loopParams = values.loopParams

  const setSlugs = value => {
    const isValueAlreadySelected = loopParams.slugs && includes(value, loopParams.slugs)
    return setValues(
      isValueAlreadySelected
        ? { ...values }
        : { ...values, loopParams: { ...values.loopParams, slugs: value } },
    )
  }
  return (
    <Collapse isOpen={loopParams.kind === "splits"}>
      <H3 css={h3Css}>What split do you want to loop on ?</H3>
      <ButtonGroup large fill>
        <Button
          active={loopParams.slugs && includes("all", loopParams.slugs)}
          onClick={() => setSlugs("'all': 1")}
          text="All"
        />
        <Button
          active={loopParams.slugs && includes("main", loopParams.slugs)}
          onClick={() => setSlugs("'slugs': ['main']")}
          text="Main"
        />
        <Button
          active={loopParams.slugs && includes("secondary", loopParams.slugs)}
          onClick={() => setSlugs("'slugs': ['secondary']")}
          text="Secondary"
        />
      </ButtonGroup>
    </Collapse>
  )
}
