// @ts-nocheck
import analysisEpics from "./modules/analysis/epics"
import authEpics from "./modules/auth/epics"
import brokerStocksEpics from "./modules/brokerStocks/epics"
import { combineEpics } from "redux-observable"
import consensusEpics from "./modules/consensus/epics"
import dataSetEventsEpics from "./modules/dataSetEvents/epics"
import dataSetListsEpics from "./modules/dataSetLists/epics"
import dataSetSearchEpics from "./modules/dataSetSearch/epics"
import dataSetsEpics from "./modules/dataSets/epics"
import dataframesEpics from "./modules/dataframes/epics"
import desksEpics from "./modules/desks/epics"
import dsForecastsEpics from "./modules/dsForecasts/epics"
import entriesEpics from "./modules/entries/epics"
import geosEpics from "./modules/geos/epics"
import lastReportsEpics from "./modules/lastReports/epics"
import monitorsEpics from "./modules/monitors/epics"
import pingEpics from "./modules/ping/epics"
import profileEpics from "./modules/profile/epics"
import rForecastsEpics from "./modules/rForecasts/epics"
import sEstimatesEpics from "./modules/sEstimates/epics"
import scenariosEpics from "./modules/scenarios/epics"
import searchEpics from "./modules/search/epics"
import sectorsEpics from "./modules/sectors/epics"
import sharedEpics from "./modules/shared/epics"
import sheetsEpics from "./modules/sheets/epics"
import stockDetailEpics from "./modules/stockDetail/epics"
import tfrowdefsEpics from "./modules/tfrowdefs/epics"
import transformsEpics from "./modules/transforms/epics"

const rootEpic = combineEpics(
  authEpics,
  brokerStocksEpics,
  analysisEpics,
  consensusEpics,
  dataframesEpics,
  dataSetEventsEpics,
  dataSetListsEpics,
  dataSetSearchEpics,
  dataSetsEpics,
  desksEpics,
  entriesEpics,
  dsForecastsEpics,
  geosEpics,
  lastReportsEpics,
  monitorsEpics,
  pingEpics,
  profileEpics,
  rForecastsEpics,
  searchEpics,
  scenariosEpics,
  sectorsEpics,
  sEstimatesEpics,
  sharedEpics,
  sheetsEpics,
  stockDetailEpics,
  tfrowdefsEpics,
  transformsEpics,
)

export default rootEpic
