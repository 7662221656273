// @ts-nocheck
import { of, timer } from "rxjs"
import { mergeMap, race, map, catchError, takeUntil, delay } from "rxjs/operators"
import { ofType, combineEpics } from "redux-observable"

import pingDuck from "./index"
import { REACT_APP_API_DOMAIN } from "../../../config/constants"

const {
  actions: { ping, pingOk, pingError, pingCancel },
} = pingDuck

export const pingEpic = (action$, _, { ajax, scheduler }, delayMs = 3000, timerMs = 5000) =>
  action$.pipe(
    ofType(ping.type),
    mergeMap(() =>
      timer(0, timerMs, scheduler).pipe(
        race(
          of("timeout").pipe(
            delay(delayMs, scheduler),
            map(() => pingError("timeout")),
          ),
          ajax({
            url: `${REACT_APP_API_DOMAIN}/api/v1/world/world/`,
            method: "GET",
            crossDomain: true,
            responseType: "json",
          }).pipe(
            map(() => pingOk()),
            catchError(() => of(pingError())),
          ),
        ),
        takeUntil(action$.pipe(ofType(pingCancel.type))),
      ),
    ),
  )

const pingEpics = combineEpics(pingEpic)

export default pingEpics
