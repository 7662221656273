import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { CardList } from "@blueprintjs/core"

export const StockListingsContainer = styled(FlexContainer)({
  height: "100%",
  marginTop: "20px",
  overflow: "hidden",
})

export const HeaderContainer = styled(FlexContainer)({
  margin: "0 0 5px 10px",
})

export const StockListingsCardList = styled(CardList)({
  marginBottom: "10px",
})
