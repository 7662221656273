// @ts-nocheck
import { Button, NonIdealState } from "@blueprintjs/core"
import * as R from "ramda"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

import { Loader } from "../../components"
import { AddExistingDesk, CreateNewDesk } from "./components"
import { NoUserDeskStyledContainer } from "./NoUserDesk.styled"

const NoUserDesk = () => {
  const [dialog, setDialog] = useState(false)
  const toggleDialog = () => setDialog(!dialog)

  const dispatch = useDispatch()
  const allDesks = R.values(useSelector(R.pathOr([], ["desks", "desks", "data"])))
  const userDesks = useSelector(R.path(["desks", "userDesks", "data"]))
  const userDesksReady = useSelector(R.path(["desks", "userDesks", "ready"]))

  if (!userDesksReady) {
    return <Loader />
  }

  if (!R.isEmpty(userDesks)) {
    return <Navigate to={`/desks/${userDesks[0].desk}`} />
  }
  return (
    <NoUserDeskStyledContainer>
      <NonIdealState
        icon={"search"}
        title={`You don't have any favorite desk.`}
        action={
          <>
            <CreateNewDesk isOpen={dialog} toggleDialog={toggleDialog} userDesks={userDesks} />
            <p>
              <Button icon="plus" text="Create new desk" onClick={toggleDialog} intent="primary" />
            </p>
            {!R.isEmpty(allDesks) && (
              <p>
                <AddExistingDesk allDesks={allDesks} userDesks={userDesks} dispatch={dispatch} />
              </p>
            )}
          </>
        }
      />
    </NoUserDeskStyledContainer>
  )
}

export default NoUserDesk
