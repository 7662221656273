// @ts-nocheck
import React from "react"
import { H3, ButtonGroup, Button } from "@blueprintjs/core"
import { Grid, Row, Col } from "."

export const PageHeader = ({ title, subtitle, btnPropsList }) => (
  <Grid fluid>
    <Row>
      <Col xs={8}>
        <H3>{title}</H3>
        {subtitle && <p>{subtitle}</p>}
      </Col>
      <Col xs={4} style={{ textAlign: "right" }}>
        <ButtonGroup>
          {btnPropsList.map((buttonProps, index) => (
            <Button key={index} {...buttonProps} />
          ))}
        </ButtonGroup>
      </Col>
    </Row>
  </Grid>
)
