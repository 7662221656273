import { Alert, Button, Icon, Intent, Tooltip } from "@blueprintjs/core"
import { isEmpty } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { IconNames } from "@blueprintjs/icons"
import { IdeaOrdering, IdeaSorting } from "../../../../../api/ideas"
import { FlexContainer, FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { MediumText } from "../../../../../styled/text.styled"
import { SCIENT_ROUTES } from "../../../../Routes"
import { useAuth, useIdeasCache } from "../../../../hooks"
import { useGlobalState } from "../../../../hooks/useGlobalState"
import { Idea } from "../../types/business"
import InboxFilters from "./InboxFilters"
import { InboxHeaderContainer } from "./InboxHeader.styled"
import InboxOrderSortOptions from "./InboxOrderSortOptions"
import InboxTagCategories from "./InboxTagCategories"
import { usePublishDraftMutation } from "../../hooks"
import { AxiosError } from "axios"
import { createToaster } from "../../../../utils/createToaster"
import { useQueryClient } from "@tanstack/react-query"

interface IInboxHeaderProps {
  selectedIdea?: Idea
}

const InboxHeader = ({ selectedIdea }: IInboxHeaderProps) => {
  const {
    draftFilter,
    stockFilter,
    sectorFilter,
    userFilter,
    orderBy,
    sortDirection,
    setDraftFilter,
    setStockFilter,
    setSectorFilter,
    setUserFilter,
    setIdeaTypeFilter,
    setTradeOpenOnly,
    setTradeClosedOnly,
    setOrderBy,
    setSortDirection,
  } = useIdeasCache()
  const { profile } = useAuth()
  const userId = profile?.id
  const navigate = useNavigate()
  const [displayFilters, setDisplayFilters] = useState(false)
  const [displayOrderSortOptions, setDisplayOrderSortOptions] = useState(false)
  const [enableUpdate, setEnableUpdate] = useState(false)
  const queryClient = useQueryClient()

  /**
   * Enable the update if the user owns the idea.
   */
  useEffect(() => {
    if (selectedIdea && userId === selectedIdea.user.id) {
      setEnableUpdate(true)
    } else {
      setEnableUpdate(false)
    }
  }, [selectedIdea, enableUpdate, userId])

  const redirectTo = useCallback(
    (path: string) => {
      navigate(path, { state: { draft: draftFilter } })
    },
    [navigate, draftFilter],
  )

  const publishMutation = usePublishDraftMutation({
    onSuccess: (idea: Idea) => {
      queryClient.removeQueries(["ideas"])
      switchDraftAndPublicState()
    },
    onError: (error: AxiosError) => {
      createToaster({
        message: "An error occurred publishing the Idea, please refresh the page and retry.",
        intent: "danger",
      })
    },
  })

  const clearFilters = useCallback(() => {
    setStockFilter && setStockFilter(null)
    setUserFilter && setUserFilter(null)
    setSectorFilter && setSectorFilter(null)
    setTradeOpenOnly && setTradeOpenOnly(false)
    setTradeClosedOnly && setTradeClosedOnly(false)
    setIdeaTypeFilter && setIdeaTypeFilter(null)
  }, [
    setIdeaTypeFilter,
    setSectorFilter,
    setStockFilter,
    setTradeClosedOnly,
    setTradeOpenOnly,
    setUserFilter,
  ])

  const resetOrderByAndSorting = useCallback(() => {
    setOrderBy && setOrderBy(IdeaOrdering.DEFAULT)
    setSortDirection && setSortDirection(IdeaSorting.DESC)
  }, [setOrderBy, setSortDirection])

  /**
   * State for validation alert on draft publish
   */
  const [alertState, setAlertState] = useState({
    canEscapeKeyCancel: true,
    canOutsideClickCancel: false,
    isLoading: false,
    isOpen: false,
    isOpenError: false,
    willLoad: false,
  })

  /** Boolean to know if ideas are filtered */
  const ideasAreFiltered = !isEmpty(stockFilter) || !isEmpty(sectorFilter) || !isEmpty(userFilter)
  /** Boolean to know if ideas are not sorted/ordered using default settings */
  const sortOrderIdeasIsDefault = orderBy === "default" && sortDirection === "DESC"

  const switchDraftAndPublicState = useCallback(() => {
    clearFilters()
    resetOrderByAndSorting()
    setDisplayOrderSortOptions(false)
    setDraftFilter && setDraftFilter(!draftFilter)
  }, [clearFilters, draftFilter, resetOrderByAndSorting, setDraftFilter])

  return (
    <InboxHeaderContainer gap="10px" flexDirection="column">
      <FlexContainerAndItem gap="10px" flexWrap="wrap" flexGrow={1}>
        <Button
          intent="primary"
          large
          onClick={() => {
            clearFilters()
            resetOrderByAndSorting()
            redirectTo(`${SCIENT_ROUTES.IDEAS}/create`)
          }}
          icon="add"
        />
        <Button
          intent="primary"
          disabled={!enableUpdate}
          icon="edit"
          large
          onClick={() => {
            redirectTo(`${SCIENT_ROUTES.IDEAS}/${selectedIdea?.id}/update`)
          }}
        />
        <FlexContainerAndItem gap="10px" flexGrow={1} flexDirection="row-reverse">
          <Tooltip
            content="Switch between my drafts and public ideas"
            hoverOpenDelay={100}
            compact
            placement="bottom"
          >
            <Button
              intent={draftFilter ? "none" : "primary"}
              icon={draftFilter ? "archive" : "circle-arrow-up"}
              large
              onClick={() => switchDraftAndPublicState()}
            />
          </Tooltip>
          <Tooltip content="Filter Ideas By" hoverOpenDelay={100} compact placement="bottom">
            <Button
              large
              icon={<Icon icon="filter" intent={ideasAreFiltered ? "primary" : "none"} />}
              onClick={() => {
                setDisplayOrderSortOptions(false)
                setDisplayFilters(!displayFilters)
              }}
            />
          </Tooltip>
          {!draftFilter ? (
            <Tooltip content="Order Ideas By" hoverOpenDelay={100} compact placement="bottom">
              <Button
                large
                icon={<Icon icon="sort" intent={sortOrderIdeasIsDefault ? "none" : "primary"} />}
                onClick={() => {
                  setDisplayFilters(false)
                  setDisplayOrderSortOptions(!displayOrderSortOptions)
                }}
              />
            </Tooltip>
          ) : (
            <Button
              large
              intent={Intent.PRIMARY}
              onClick={() =>
                setAlertState(alertState => {
                  return { ...alertState, isOpen: true }
                })
              }
            >
              <FlexContainer alignItems="center" gap="5px">
                <MediumText fontWeight="bold">Publish</MediumText>
                <Icon icon={IconNames.SEND_MESSAGE} size={12} />
              </FlexContainer>
            </Button>
          )}
        </FlexContainerAndItem>
      </FlexContainerAndItem>
      {displayFilters && <InboxFilters />}
      {displayOrderSortOptions && <InboxOrderSortOptions />}
      {!draftFilter && <InboxTagCategories />}
      {selectedIdea && (
        <Alert
          {...alertState}
          cancelButtonText="Cancel"
          confirmButtonText="Publish"
          icon="lightbulb"
          intent={Intent.SUCCESS}
          onCancel={() =>
            setAlertState(alertState => {
              return { ...alertState, isOpen: false }
            })
          }
          onConfirm={() => {
            publishMutation.mutate(selectedIdea.id)
            setAlertState(alertState => {
              return { ...alertState, isOpen: false }
            })
          }}
        >
          <MediumText color="black">
            Are you sure you want to publish your draft Idea ? You will not be able to revert this
            and your Idea will become public for other users.
          </MediumText>
        </Alert>
      )}
    </InboxHeaderContainer>
  )
}

export default InboxHeader
