// @ts-nocheck
import * as R from "ramda"

export const createAnalyse = state => R.assocPath(["analyses", "fetching"], true, state)

export const createAnalyseOk = (state, { payload: { analyse } }) =>
  R.compose(
    R.assocPath(["analyses", "fetching"], false),
    R.over(R.lensPath(["analyses", "list"]), R.insert(0, R.assoc("created", true, analyse))),
  )(state)

export const createAnalyseError = (state, { payload: { error } }) =>
  R.compose(
    R.assocPath(["analyses", "fetching"], false),
    R.assocPath(["analyses", "error"], error),
  )(state)
