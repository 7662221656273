// @ts-nocheck
import * as R from "ramda"

import { MenuItem } from "@blueprintjs/core"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Suggest } from "."
import { clearOmniByName, searchOmniByName } from "../../state/modules/search"

const defaultExclude = { st: false, rf: false, ds: false, dsf: false }

/**
 * Light version of the OmniSuggest Component.
 * We return only the Suggest from blueprint.
 */
export const OmniSuggestLight = ({
  selectedItem,
  onItemSelect,
  inputValueRenderer = item => item.name,
  exclude = {},
  placeholder,
}) => {
  const initialExclude = R.mergeDeepLeft(exclude, defaultExclude)

  const kinds = R.compose(
    R.map(([key]) => key),
    R.filter(([key, bool]) => !bool && key),
    R.toPairs,
  )(initialExclude)

  const dispatch = useDispatch()
  useEffect(() => {
    return () => dispatch(clearOmniByName())
  }, [dispatch])

  const updateSearch = query =>
    !R.empty(query) && dispatch(searchOmniByName({ name: query, kinds }))

  const results = useSelector(R.pathOr([], ["search", "omniByName", "results"]))

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null

    const { src_name, kind, id } = item
    return (
      <MenuItem
        active={modifiers.active}
        key={`${kind}.${id}`}
        text={src_name}
        label={kind}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }

  return (
    <Suggest
      resetOnClose={true}
      resetOnSelect={true}
      items={results}
      onItemSelect={onItemSelect}
      onQueryChange={updateSearch}
      itemRenderer={itemRenderer}
      selectedItem={selectedItem}
      fill
      inputValueRenderer={inputValueRenderer}
      inputProps={{ placeholder }}
    />
  )
}
