// @ts-nocheck
import {
  Button,
  InputGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"
import { useDrag, useDrop } from "react-dnd"

import { getNiceFormulaItem } from "../../../../utils"
import ItemTypes from "./itemTypes"

const style = {
  padding: "2px",
}

const operators = ["+", "-", "*", "/", "**", "(", ")"]

export const FormulaItem = ({
  values,
  setValues,
  card,
  formulaItems,
  formulaItem,
  index,
  moveItem,
  findItem,
  fieldList,
  factorList,
  toggleShowPanels,
}) => {
  const cols = values.colDefs.cols
  const { item, itemId } = formulaItem

  // Logic for input
  const regexp = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/g // to control input: accept only number

  const [modifyInput, setModifyInput] = useState("")
  const modifyInputIsNumber = regexp.test(modifyInput)

  const onModify = e => {
    const { value } = e.target
    setModifyInput(value)
  }

  const modifySymbol = (value, index) =>
    setValues({
      colDefs: {
        ...values.colDefs,
        cols: R.map(
          col =>
            col.colId === card.colId
              ? {
                  ...col,
                  formulaItems: formulaItems.map((formulaItem, i) =>
                    i === index ? { item: value, itemId: formulaItem.itemId } : formulaItem,
                  ),
                }
              : col,
          cols,
        ),
      },
    })

  const deleteFomulaItem = index => {
    setValues({
      colDefs: {
        ...values.colDefs,
        cols: R.map(
          col =>
            col.colId === card.colId
              ? { ...col, formulaItems: formulaItems.filter((formulaItem, i) => i !== index) }
              : col,
          cols,
        ),
      },
    })
  }

  //For dnd
  const originalIndex = findItem(itemId).index
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.BUTTON, itemId, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    end: (dropResult, monitor) => {
      const { itemId: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveItem(droppedId, originalIndex)
      }
    },
  })
  const [, drop] = useDrop({
    accept: ItemTypes.BUTTON,
    canDrop: () => false,
    hover({ itemId: draggedId }) {
      if (draggedId !== itemId) {
        const { index: overIndex } = findItem(itemId)
        moveItem(draggedId, overIndex)
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  //End dnd

  return (
    <div ref={node => drag(drop(node))} style={{ ...style, opacity }} fill="true">
      <Popover
        content={
          <Menu>
            {(item.includes("#") || item.includes("@")) && (
              <MenuItem
                key={`edit${index}${item}`}
                text={`Edit ${getNiceFormulaItem({
                  item,
                  cols,
                  fieldList,
                  factorList,
                })}`}
                icon="edit"
                onClick={() => toggleShowPanels(item)}
              />
            )}
            <MenuItem
              key={`delete${index}${item}`}
              text={`Delete ${getNiceFormulaItem({
                item,
                cols,
                fieldList,
                factorList,
              })}`}
              icon="delete"
              onClick={() => deleteFomulaItem(index)}
            />
            {/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/gi.test(item) === true && ( // test if item is a number
              <MenuItem
                key={`modify${index}${item}`}
                text={`Modify ${item}`}
                icon="edit"
                children={
                  <InputGroup
                    type="numeric"
                    value={modifyInput}
                    placeholder="Enter number"
                    onChange={onModify}
                    rightElement={
                      <>
                        <Button
                          onClick={() => modifySymbol(modifyInput, index) && setModifyInput("")}
                          text={
                            modifyInputIsNumber === true || !modifyInput ? "Modify" : "Not a number"
                          }
                          intent={modifyInputIsNumber === true ? "primary" : "none"}
                          disabled={modifyInputIsNumber === false}
                        />
                      </>
                    }
                  />
                }
              />
            )}

            {/[+-/*()]/g.test(item) &&
              item.length < 3 && ( // to match only the operators
                <>
                  <MenuDivider />
                  {operators.map(op => (
                    <MenuItem
                      key={op}
                      text={op}
                      icon="blank"
                      onClick={() => modifySymbol(op, index)}
                    />
                  ))}
                </>
              )}
          </Menu>
        }
        position={Position.RIGHT_TOP}
      >
        <Button
          text={getNiceFormulaItem({
            item,
            cols,
            fieldList,
            factorList,
          })}
          intent={
            item.includes("#") ||
            item.includes("@") ||
            /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/gi.test(item) === true //test if item is a number
              ? "primary"
              : /[()]/g.test(item)
              ? "warning"
              : "none"
          }
          small
        />
      </Popover>
    </div>
  )
}
