// @ts-nocheck
import React, { useState } from "react"
import { Button, Checkbox } from "@blueprintjs/core"
import { Grid, Col, Row } from "../../../../../components"

const Sharing = ({ index, dataset, dsforecast, myGroups, updateForecast, setDialog }) => {
  const initialForm = { read_groups: {}, write_groups: {} }
  myGroups.forEach(group => {
    initialForm.read_groups[group] = dsforecast.read_groups.includes(group) ? true : false
    initialForm.write_groups[group] = dsforecast.write_groups.includes(group) ? true : false
  })
  const [form, setForm] = useState(initialForm)

  const updateForm = (type, group) =>
    type === "read"
      ? setForm({
          ...form,
          read_groups: { ...form.read_groups, [group]: !form.read_groups[group] },
        })
      : setForm({
          ...form,
          write_groups: { ...form.write_groups, [group]: !form.write_groups[group] },
        })

  const submit = () => {
    const read_groups = Object.entries(form.read_groups)
      .filter(([, value]) => value)
      .map(([key]) => key)
    const write_groups = Object.entries(form.write_groups)
      .filter(([, value]) => value)
      .map(([key]) => key)
    updateForecast({ index, form: { dataset, id: dsforecast.id, read_groups, write_groups } })
    return setDialog(false)
  }

  return (
    <Grid>
      <Row>
        <Col xs={6}>
          <h4>Read</h4>
          {myGroups.map(group => (
            <Checkbox
              key={group}
              checked={form.read_groups[group]}
              label={group}
              onClick={() => updateForm("read", group)}
            />
          ))}
        </Col>
        <Col xs={6}>
          <h4>Write</h4>
          {myGroups.map(group => (
            <Checkbox
              key={group}
              checked={form.write_groups[group]}
              label={group}
              onClick={() => updateForm("write", group)}
            />
          ))}
        </Col>
      </Row>
      <Row>
        <Button fill intent="primary" text="Save" onClick={submit} />
      </Row>
    </Grid>
  )
}

export default Sharing
