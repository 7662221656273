import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"
import { MediumText } from "../../../styled/text.styled"
import { FieldType } from "./types/business"

type MetricContainerProps = {
  isMetricComparedToCon: boolean
  inChartDialog: boolean
}
export const MetricContainer = styled(FlexContainer)<MetricContainerProps>(props => ({
  width: props.inChartDialog ? "400px" : props.isMetricComparedToCon ? "330px" : "170px",
}))

type NameContainerProps = {
  metricName: FieldType
  inChartDialog: boolean
}
export const NameContainer = styled(FlexContainer)<NameContainerProps>(props => ({
  width: props.inChartDialog
    ? "90px"
    : [FieldType.KEY_PROFIT, FieldType.PROFIT_MARGIN, FieldType.NET_INCOME].includes(
        props.metricName,
      )
    ? "90px"
    : "60px",
  marginLeft: !props.inChartDialog ? "10px" : 0,
  whiteSpace: "nowrap",
}))

export const PercentContainer = styled(FlexContainer)({
  width: "80px",
})

type SignifiantChangeInValueProps = {
  signifiantChangeInValue: boolean
}
export const MetricName = styled(MediumText)<SignifiantChangeInValueProps>(props => ({
  color: props.signifiantChangeInValue ? "" : SCIENT_COLORS.gray1,
}))

type ValueChangeProps = {
  valueChange: number | null
}
export const MetricPercent = styled(MediumText)<ValueChangeProps>(props => ({
  color:
    props.valueChange && props.valueChange > 0 ? SCIENT_COLORS.forest3 : SCIENT_COLORS.vermilion4,
}))
export const ConMetricPercent = styled(MediumText)<ValueChangeProps>(props => ({
  fontWeight: "bold",
  color:
    props.valueChange && props.valueChange > 0 ? SCIENT_COLORS.forest3 : SCIENT_COLORS.vermilion4,
}))

export const NoDataIcon = styled(MediumText)({
  color: SCIENT_COLORS.gray1,
})

type PipeSeparatorProps = {
  metricHasConsensusDiff: boolean
}
export const PipeSeparator = styled(MediumText)<PipeSeparatorProps>(props => ({
  color: props.metricHasConsensusDiff ? "" : SCIENT_COLORS.gray1,
}))

export const metricNameButtonCss = (signifiantChangeInValue: boolean) =>
  css({
    cursor: signifiantChangeInValue ? "" : "default !important",
  })
