// @ts-nocheck
import autodux from "autodux"

const dataSetListsDuck = autodux({
  slice: "dataSetLists",
  initial: {
    fetching: false,
    error: false,
    dataSetLists: [],
  },
  actions: {
    fetchDataSetLists: state => ({
      ...state,
      fetching: true,
    }),
    fetchDataSetListsOk: (state, dataSetLists) => ({
      ...state,
      fetching: false,
      dataSetLists,
    }),
    fetchDataSetListsError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),
  },
})

export default dataSetListsDuck
