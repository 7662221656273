import { H3 } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { FlexContainer, FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { ShadowContainer } from "../../../../styled/layout.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const InboxContainer = styled(ShadowContainer)({ overflow: "auto" })

export const SpinnerContainer = styled(FlexContainerAndItem)({
  overflow: "auto",
  padding: "10px 0 10px 0",
})

export const WaveListContainer = styled(FlexContainerAndItem)({
  width: "50%",
  maxWidth: "700px",
  overflowX: "hidden",
})

export const DetailContainer = styled.div({
  height: "450px",
  width: "50%",
  maxWidth: "1000px",
  padding: "20px",
  backgroundColor: SCIENT_COLORS.darkGray1,
  borderRadius: "10px",
})

export const StyledH3 = styled(H3)`
  margin: 0 auto 12px;
`

export const NoWaveListPlaceholder = styled(FlexContainer)({
  height: "100%",
})
