// @ts-nocheck

import { createSlice } from "@reduxjs/toolkit"
import reducers from "./reducers"

export const initialState = {
  fetching: true,
  results: [],
}

const tfrowdefsSlice = createSlice({
  name: "tfrowdefs",
  initialState,
  reducers,
})

export const { fetchTFRowDefs, fetchTFRowDefsOk, fetchTFRowDefsError } = tfrowdefsSlice.actions

export default tfrowdefsSlice.reducer
