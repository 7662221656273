// @ts-nocheck
import React from "react"
import { Switch as BP5Switch } from "@blueprintjs/core"

import { HelpTooltip } from "../."

export const Switch = ({
  field, // { name, value, onChange, onBlur }
  //form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  helpButton = null,
  ...props
}) => {
  if (helpButton) {
    const labelElement = (
      <>
        {label} <HelpTooltip marginSize={4} iconSize={12} content={helpButton} />
      </>
    )
    return <BP5Switch {...field} {...props} labelElement={labelElement} checked={field.value} />
  }
  return <BP5Switch {...field} {...props} label={label} checked={field.value} />
}
