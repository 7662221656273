// @ts-nocheck
import * as R from "ramda"

import { Button, ButtonGroup, MenuItem } from "@blueprintjs/core"
import { Col, Row } from "."
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Suggest } from "."
import dataframesDuck from "../../state/modules/dataframes"

export const formatItem = str_name => {
  const index = R.findLastIndex(R.equals(">"), str_name)
  const [name, sourceId] = R.splitAt(index, str_name)
  return { name, sourceId: R.join("", R.remove(0, 1, sourceId)) }
}

export const DataFrameSuggest = ({ submit, placeholder, large = true, sourceId = null }) => {
  const [filters, setFilters] = useState(
    sourceId
      ? {
          st: R.startsWith("st", sourceId) ? true : false,
          rf: R.startsWith("rf", sourceId) ? true : false,
          ds: R.startsWith("ds", sourceId) ? true : false,
          dsf: R.startsWith("dsf", sourceId) ? true : false,
        }
      : { st: true, rf: true, ds: true, dsf: true },
  )

  const isStock = ["st", "rf"].includes(sourceId)
  const isDataSet = ["ds", "dsf"].includes(sourceId)

  const updateFilters = key => {
    switch (key) {
      case "all":
        return setFilters({
          st: isStock ? true : false,
          rf: isStock ? true : false,
          ds: isDataSet ? true : false,
          dsf: isDataSet ? true : false,
        })
      case "st":
        return setFilters({ st: true, rf: false, ds: false, dsf: false })
      case "rf":
        return setFilters({ st: false, rf: true, ds: false, dsf: false })
      case "ds":
        return setFilters({ st: false, rf: false, ds: true, dsf: false })
      case "dsf":
        return setFilters({ st: false, rf: false, ds: false, dsf: true })
      default:
        throw new Error("wrong filter")
    }
  }
  const allChecked = filters.st && filters.rf && filters.ds && filters.dsf && true

  const formatKinds = () => {
    const kinds = []
    filters.st && kinds.push("st")
    filters.rf && kinds.push("rf")
    filters.ds && kinds.push("ds")
    filters.dsf && kinds.push("dsf")
    return kinds.join(",")
  }

  const dispatch = useDispatch()
  useEffect(() => {
    return () => dispatch(dataframesDuck.actions.clear())
  }, [dispatch])

  const updateSearch = query =>
    query !== "" &&
    dispatch(
      dataframesDuck.actions.search({
        name: query,
        kinds: formatKinds(),
      }),
    )

  const dataframeResult = useSelector(R.pathOr([], ["dataframes", "results"]))

  const searching = useSelector(R.pathOr(null, ["dataframes", "searching"]))

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null

    const { name, sourceId } = formatItem(item.str_name)
    const regex = /([a-z])+/g
    const kind = sourceId.match(regex)

    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        text={name}
        label={kind}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }
  return (
    <>
      <Row>
        <Col xs={12}>
          <Suggest
            placeholder={placeholder}
            loading={searching}
            resetOnClose={true}
            resetOnSelect={true}
            items={dataframeResult}
            onItemSelect={submit}
            onQueryChange={updateSearch}
            itemRenderer={itemRenderer}
            large={large}
            fill
          />
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <ButtonGroup fill>
            <Button
              text="all"
              active={allChecked}
              disabled={sourceId}
              onClick={() => updateFilters("all")}
            />
          </ButtonGroup>
        </Col>
        <Col xs={10}>
          <ButtonGroup fill>
            <Button
              text="stock"
              active={filters.st}
              disabled={sourceId && isDataSet}
              onClick={() => updateFilters("st")}
              style={{ fontSize: "12px" }}
            />
            <Button
              text="stock + forecast"
              active={filters.rf}
              onClick={() => updateFilters("rf")}
              disabled={sourceId && isDataSet}
              style={{ fontSize: "12px" }}
            />
            <Button
              text="ds"
              active={filters.ds}
              disabled={sourceId && isStock}
              onClick={() => updateFilters("ds")}
              style={{ fontSize: "12px" }}
            />
            <Button
              text="ds + forecast"
              active={filters.dsf}
              disabled={sourceId && isStock}
              onClick={() => updateFilters("dsf")}
              style={{ fontSize: "12px" }}
            />
          </ButtonGroup>
        </Col>
      </Row>
    </>
  )
}
