// @ts-nocheck
import React, { useState } from "react"
import { compose, last, prop } from "ramda"
import { AgGridReact } from "ag-grid-react"

import { NumberFormatter, DateFormatter } from "../../../../../../../_shared/agFormatters"

const Table = ({ dataSet, pct, entries }) => {
  const colTitles = entries.map(entry => ({
    field: entry.name,
    cellStyle: { textAlign: "right" },
  }))

  const columnDefs = [
    {
      headerName: dataSet.unit,
      field: "desc",
      pinned: "left",
      width: 150,
    },
    ...colTitles,
  ]
  const rowHeaders = ["start", "end", "value"]

  const rowData = rowHeaders.map((header, index) => {
    const col = entries.reduce(
      (acc, entry) => {
        acc[entry.name] = [0, 1].includes(index)
          ? DateFormatter({ value: entry[header] })
          : NumberFormatter({
              value: entry[header],
              pct,
            })
        return acc
      },
      { desc: header },
    )
    return col
  })

  const options = {
    defaultColDef: {
      width: 100,
    },
    rowSelection: "multiple",
    enableRangeSelection: "true",
    // rowMultiSelectWithClick: "true",
    // suppressContextMenu: "true",
    // allowContextMenuWithControlKey: "true",
    columnDefs,
    rowData,
  }

  const [, setGridApi] = useState({})
  const [, setGridColumnApi] = useState({})
  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const [wentToLastCol, setWentToLastCol] = useState(false)
  const onAnimationQueueEmpty = ({ api }) => {
    if (api && !wentToLastCol) {
      api.ensureColumnVisible(compose(prop("field"), last)(colTitles))
      setWentToLastCol(true)
    }
  }
  return (
    <div
      className="ag-theme-balham-dark"
      style={{
        marginBottom: "8px",
        height: "130px",
        width: "100%",
      }}
    >
      <AgGridReact
        onGridReady={onGridReady}
        onAnimationQueueEmpty={onAnimationQueueEmpty}
        sizeColumnsToFit
        {...options}
      />
    </div>
  )
}

export default Table
