/** @jsxImportSource @emotion/react */

import { css, keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"

export const LoginFullScreen = styled(FlexContainer)({
  background: "linear-gradient(to bottom, #000000 0%, #1e5799 99%)",
  filter:
    "progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#1e5799',GradientType=0 )",
})

export const LoginFormContainer = styled(FlexContainer)({
  background: "linear-gradient(180deg, rgba(226,226,226,1) 0%, rgba(162,162,162,1) 100%)",
  padding: "50px",
  borderRadius: "15px",
  maxWidth: "600px",
  textAlign: "justify",
})

export const loginSubmitButtonCss = css({
  padding: "12px",
  fontSize: "18px",
  whiteSpace: "nowrap",
})

export const ForgotYourPasswordCss = css({
  fontSize: "14px !important",
  color: "#137cbd !important",
})

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const TransitionContainer = styled(FlexContainer)({
  animation: `${fadeIn} 1s ease-out forwards`,
  background: "linear-gradient(180deg, rgba(226,226,226,1) 0%, rgba(162,162,162,1) 100%)",
  padding: "50px",
  borderRadius: "15px",
  maxWidth: "600px",
  textAlign: "justify",
})
