// @ts-nocheck
import React from "react"
import { concat, reduce } from "ramda"
import { getDeepNestedValue } from "../../../../../../../utils"
import { createGrowthRows } from "./utils"

import { slugToName } from "../../../../utils"
import { Grid } from "../../../../../../components"
import { TitleRow, InputRow, ControlRow, ReadonlyRow } from "../components"

const SplitGrowth = ({
  subTabId,
  endField,
  handleChange,
  values,
  reportData: { prevReport, prevYearReport },
}) => {
  const [, , splitSlug] = subTabId.split(";")
  const split = getDeepNestedValue(["splits", `slug:${splitSlug}`], values)
  const segmentRows = ({ rseg, splitSlug }) => {
    const segSlug = rseg.slug

    const pathBase = ["splits", `slug:${splitSlug}`, "segs", `slug:${segSlug}`]
    const path = [...pathBase, `${endField}_growth_readonly`]
    return endField === "sales"
      ? [
          {
            type: "title",
            props: {
              level: 2,
              title: `${slugToName(rseg.slug)}`,
            },
          },
          {
            type: "readonly",
            props: {
              title: `${rseg.name} ${endField} growth`,
              basePath: path,
              commentPath: [...pathBase, `${endField}_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              handleChange,
            },
          },
          {
            type: "readonly",
            props: {
              title: `${rseg.name} ${endField} growth QoQ`,
              basePath: [...pathBase, `${endField}_growth_qoq_readonly`],
              commentPath: [...pathBase, `${endField}_growth_qoq_comment`],
              values,
              prevReport,
              prevYearReport,
              handleChange,
            },
          },
          {
            type: "control",
            props: {
              title: "Organic + scope check",
              expected: getDeepNestedValue([...pathBase, `${endField}_growth_readonly`], values),
              sumList: [
                getDeepNestedValue([...pathBase, `${endField}_organic_growth`], values),
                getDeepNestedValue([...pathBase, `${endField}_scope_growth`], values),
              ],
            },
          },
          {
            type: "control",
            props: {
              title: "Organic qoq + scope qoq check",
              expected: getDeepNestedValue(
                [...pathBase, `${endField}_growth_qoq_readonly`],
                values,
              ),
              sumList: [
                getDeepNestedValue([...pathBase, `${endField}_organic_growth_qoq`], values),
                getDeepNestedValue([...pathBase, `${endField}_scope_growth_qoq`], values),
              ],
            },
          },

          ...createGrowthRows({
            endField,
            pathBase,
            values,
            prevReport,
            prevYearReport,
            handleChange,
          }),
        ]
      : [
          {
            type: "title",
            props: {
              level: 2,
              title: `${slugToName(rseg.slug)}`,
            },
          },
          {
            type: "readonly",
            props: {
              title: `${rseg.name} ${endField} growth`,
              basePath: path,
              commentPath: [...pathBase, `${endField}_growth_comment`],
              values,
              prevReport,
              prevYearReport,
              handleChange,
            },
          },
          {
            type: "control",
            props: {
              title: "Organic + scope check",
              expected: getDeepNestedValue([...pathBase, `${endField}_growth_readonly`], values),
              sumList: [
                getDeepNestedValue([...pathBase, `${endField}_organic_growth`], values),
                getDeepNestedValue([...pathBase, `${endField}_scope_growth`], values),
              ],
            },
          },
          ...createGrowthRows({
            endField,
            pathBase,
            values,
            prevReport,
            prevYearReport,
            handleChange,
          }),
        ]
  }

  const splitRows = ({ split }) => {
    const segRows = reduce(
      concat,
      [],
      // split.segments
      //   .filter(segment => segment.customsegment.active)
      //   .map(segment => segmentRows({ segment, splitSlug })),
      split.segs
        // .filter(seg => seg.active)
        .map(rseg => segmentRows({ rseg, splitSlug })),
    )
    return [
      {
        type: "title",
        props: {
          title: `${slugToName(split.slug)} ${endField} growth`,
        },
      },
      ...segRows,
    ]
  }

  return (
    <Grid fluid>
      {splitRows({ split }).map(({ type, props }, index) => {
        if (type === "title") {
          return <TitleRow key={index} {...props} />
        }
        if (type === "control") {
          return <ControlRow key={index} {...props} />
        }
        if (type === "readonly") {
          return <ReadonlyRow key={index} {...props} />
        }
        return <InputRow key={index} {...props} />
      })}
    </Grid>
  )
}

export default SplitGrowth
