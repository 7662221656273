// @ts-nocheck
import { formatDistanceStrict } from "date-fns"

const GenericResults = ({ analyse }) => {
  return (
    <div>
      <p>
        Last run started{" "}
        <strong>
          {analyse.processing
            ? formatDistanceStrict(new Date(analyse.processing), Date.now(), {
                addSuffix: true,
              })
            : "..."}
        </strong>
      </p>
      <p>
        {analyse.complete && (
          <span>
            Completed{" "}
            <strong>
              {formatDistanceStrict(new Date(analyse.complete), Date.now(), {
                addSuffix: true,
              })}
            </strong>
          </span>
        )}
      </p>
    </div>
  )
}

export default GenericResults
