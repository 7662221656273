import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../../hooks/useApi"
import { IAccountBookPairs } from "../../types"

const useRiskZoomQuery = ({
  accountBookPairs,
  type,
  category,
  enabled,
}: {
  accountBookPairs: IAccountBookPairs
  type: string
  category?: string
  enabled: boolean
}) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["riskZoom", accountBookPairs, { type, category }],
    () => scientApi.trading.getRiskZoom({ accountBookPairs, type, category }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled,
    },
  )

  return {
    riskZoom: data,
    isLoading,
    error,
  }
}

export default useRiskZoomQuery
