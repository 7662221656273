// @ts-nocheck
import { Button, ControlGroup, Tooltip } from "@blueprintjs/core"
import { useState } from "react"

import sheetDuck from "../../../../state/modules/sheets"
import { Col, InputGroup, Row } from "../../../components"

export const EditSheet = ({ monitorHeader, sheet, toggleEdit, dispatch }) => {
  const [hasChanged, setHasChanged] = useState(false) // state to know if sheet name has been modified (=== if user has enter something in InputGroup)
  const [name, setName] = useState(sheet.name)

  const handleText = ({ target: { value } }) => {
    setName(value)
    setHasChanged(true)
  }
  const handleSubmit = () => {
    dispatch(
      sheetDuck.actions.updateSheet({
        id: sheet.id,
        name,
      }),
    )
    setHasChanged(false)
    toggleEdit()
  }
  const handleCancel = () => {
    setName(sheet.name)
    setHasChanged(false)
    toggleEdit()
  }

  return (
    <Row style={{ margin: "0" }}>
      <Col xs={monitorHeader ? 9 : 10} style={{ marginLeft: "-7px" }}>
        <InputGroup
          fill
          style={{ padding: 0 }}
          inputProps={{
            value: name,
            onChange: handleText,
            onEnter: handleSubmit,
            onEsc: handleCancel,
          }}
        />
      </Col>
      <Col xs={2}>
        <ControlGroup fill>
          <Tooltip content="Undo" placement="bottom" openOnTargetFocus={false} compact>
            <Button minimal icon="undo" onClick={handleCancel} style={{ maxWidth: "30px" }} />
          </Tooltip>

          <Tooltip
            content="Save"
            placement="bottom"
            compact
            openOnTargetFocus={false}
            disabled={hasChanged === false}
          >
            <Button
              minimal
              intent="primary"
              icon="tick"
              onClick={handleSubmit}
              disabled={hasChanged === false}
              style={{ maxWidth: "30px" }}
            />
          </Tooltip>
        </ControlGroup>
      </Col>
    </Row>
  )
}
