// @ts-nocheck
import { last } from "ramda"
import { createSequence } from "."

export const createSource = (pastEntries, rows) => {
  const source = {}
  const firstEntry = last(pastEntries.results)
  const lastEntry = last(rows)

  pastEntries.results.map(entry => (source[entry.name] = entry.value))
  rows.map(entry => (source[entry.name] = entry.value))

  const sequencialNames = createSequence(firstEntry.name, lastEntry.name)

  sequencialNames.forEach((name, index) => {
    const prevName = sequencialNames[index - 1]
    if (!source[name]) {
      source[name] = source[prevName]
    }
  })

  return source
}
