// @ts-nocheck
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = ({ charts, dataSets }) => {
  try {
    const serializedState = JSON.stringify({ charts, dataSets })
    localStorage.setItem("state", serializedState)
  } catch (err) {
    console.log("Error: can't set item in localStorage")
  }
}
