import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { useApi } from "../../../hooks/useApi"

const useTradingCountries = () => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["trading_countries"],
    scientApi.trading.getTradingCountries,
    {
      retry: 1,
      staleTime: Infinity, // Static list, no need to refresh.
    },
  )

  return {
    countries: data,
    error: error as AxiosError,
    isLoading,
  }
}

export default useTradingCountries
