// @ts-nocheck
import { dissoc } from "ramda"

const dissocActive = object => {
  if (object.children) object.children = object.children.map(child => dissocActive(child))
  return dissoc("active", object)
}

export const removeActiveKeyRecursively = rowTree => {
  return rowTree.map(e => dissocActive(e))
}
