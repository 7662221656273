// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { Button, ButtonGroup, Card, Collapse, H3, H5 } from "@blueprintjs/core"
import * as R from "ramda"
import React from "react"
import { useDispatch } from "react-redux"

import { addSingleItem } from "../../../../../../../../../../state/modules/search"
import { OmniSuggest } from "../../../../../../../../../components"
import { h3Css, h5Css } from "./LoopBuilder.styled"

export const SourceChoice = ({ values, setValues, st_sources, rf_sources }) => {
  const loopParams = values.loopParams

  const primarySrcName = !R.isEmpty(rf_sources)
    ? rf_sources[0].name
    : !R.isEmpty(st_sources)
    ? st_sources[0].name
    : "Not selected"
  const primaryTicker = !R.isEmpty(rf_sources)
    ? rf_sources[0].ticker
    : !R.isEmpty(st_sources)
    ? st_sources[0].ticker
    : "Not selected"

  const dispatch = useDispatch()

  const setNewSource = () => {
    const isAlreadyNewSource = values.loopParams.src && values.loopParams.src !== "stsrc0"
    const newValues = isAlreadyNewSource
      ? { ...values }
      : {
          ...values,
          loopParams: {
            ...values.loopParams,
            srcKind: "new",
            src: "",
            srcName: "",
            srcTicker: "",
            notSelectedCustomFields: [],
            selectedCustomFields: [],
            customFields: [],
          },
        }
    setValues(newValues)
  }

  const handleSubmitNewSource = item => {
    const sourceId = item.kind_id
    const srcName = item.verbose_name
    const srcTicker = item.bloomberg_code

    setValues({
      ...values,
      loopParams: {
        ...values.loopParams,
        src: `${sourceId}`,
        srcKind: "new",
        srcName,
        srcTicker,
        notSelectedCustomFields: [],
        selectedCustomFields: [],
        customFields: [],
      },
    })
    dispatch(addSingleItem({ item })) // to fetch source in omniByIds
  }

  const setPrimarySource = () => {
    const isAlreadyPrimarySource = values.loopParams.src && values.loopParams.src === "stsrc0"
    const newValues = isAlreadyPrimarySource
      ? { ...values }
      : {
          ...values,
          loopParams: {
            ...values.loopParams,
            src: "stsrc0",
            srcKind: "primary",
            srcName: primarySrcName,
            srcTicker: primaryTicker,
            notSelectedCustomFields: [],
            selectedCustomFields: [],
            customFields: [],
          },
        }

    setValues(newValues)
  }

  return (
    <>
      <H3 css={h3Css}>What source do you want to loop on ?</H3>
      <ButtonGroup large fill>
        {st_sources.length > 0 && (
          <Button
            active={loopParams.srcKind === "primary"}
            onClick={setPrimarySource}
            text={`Primary source: ${primarySrcName}`}
          />
        )}

        <Button active={loopParams.srcKind === "new"} onClick={setNewSource} text="Any stock" />
      </ButtonGroup>

      <Collapse isOpen={loopParams.srcKind === "new"}>
        <Card>
          {loopParams.srcKind === "new" && (
            <OmniSuggest
              onSubmit={handleSubmitNewSource}
              placeholder={loopParams.srcName ? loopParams.srcName : "Search..."}
              exclude={{ ds: true, dsf: true }}
            />
          )}
          <H5 css={h5Css}>{`Selected source: ${loopParams.srcName}`}</H5>
        </Card>
      </Collapse>
    </>
  )
}
