// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit"

import * as reducers from "./reducers"

const sharedSlice = createSlice({
  name: "shared",
  initialState: {},
  reducers,
})

export const { fetchFieldList, fetchFieldListOk, fetchFieldListError } = sharedSlice.actions

export default sharedSlice.reducer
