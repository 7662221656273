import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

type ExposuresContainerProps = {
  fullScreen: boolean
}

export const ExposuresContainer = styled(FlexContainer)<ExposuresContainerProps>(props => ({
  height: "100%",
  width: "100%",
  padding: props.fullScreen ? 0 : "5px 20px 10px",
  position: props.fullScreen ? "absolute" : "relative",
  top: props.fullScreen ? 0 : undefined,
  left: props.fullScreen ? 0 : undefined,
  zIndex: props.fullScreen ? 10 : undefined,
  overflow: "hidden",
  backgroundColor: props.fullScreen ? SCIENT_COLORS.darkGray2 : "",
}))

export const ExposuresTablesContainer = styled(FlexContainer)<ExposuresContainerProps>(props => ({
  overflow: "auto",
}))

export const FullScreenHeader = styled(FlexContainer)({
  height: "24px",
  margin: "3px 10px",
})

export const fullScreenDividerCss = css({
  margin: 0,
})
