// @ts-nocheck
import * as R from "ramda"

const fetchTransformOperationsReducers = {
  fetchTransformOperations: R.compose(R.assoc("fetching", true), R.dissoc("error")),

  fetchTransformOperationsOk: (state, response) =>
    R.compose(R.assoc("fetching", false), R.assoc("results", response.payload))(state),

  fetchTransformOperationsError: (state, error) =>
    R.compose(R.assoc("fetching", false), R.assoc("error", error.payload))(state),
}

export default fetchTransformOperationsReducers
