// @ts-nocheck
import * as R from "ramda"

import dskNodeDefs from "./dskNodeDefs"
import { OperationCategory } from "./dskNodeDefs"
import { SCIENT_COLORS } from "../../../../../../../../../../styled/scientColors"

export const getNodeDefs = ({
  header = null,
  enhancedPath = {},
  formula_tree,
  st_sources = [],
  rf_sources = [],
  operations,
  categories,
}) => {
  const deskNodes = dskNodeDefs({ header, enhancedPath, formula_tree, st_sources, rf_sources })

  const operationsToDisplay = R.compose(
    R.map(op =>
      R.over(
        R.lensProp("params"),
        R.map(param =>
          R.compose(
            op.category === OperationCategory.valuations && param.name === "stock_path"
              ? R.compose(
                  R.assoc("type", "ValuationSourceSelect"),
                  R.assoc("id", param.name),
                  R.assoc("show", param.show ? param.show : true),
                )
              : R.compose(
                  R.assoc(
                    "type",
                    ["period_freq"].includes(param.name) ? param.name : param.param_type,
                  ),
                  R.assoc("id", param.name),
                  R.assoc("show", param.show ? param.show : true),
                ),
          )(param),
        ),
        op,
      ),
    ),

    R.map(op =>
      R.over(
        R.lensProp("outputs"),
        R.map(output =>
          R.compose(R.assoc("id", output.name), R.assoc("connectable", true))(output),
        ),
        op,
      ),
    ),
    R.map(op =>
      R.over(
        R.lensProp("inputs"),
        R.map(input => R.compose(R.assoc("id", input.name), R.assoc("connectable", true))(input)),
        op,
      ),
    ),
    R.map(op =>
      R.compose(
        R.assoc("style", { background: SCIENT_COLORS.blue1 }),
        R.assoc("type", op.name),
        ["stitch_df", "overwrite_values"].includes(op.name) //  stitch_df and overwrite_values are hide for now
          ? R.assoc("show", false)
          : R.assoc("show", true),
      )(op),
    ),

    R.filter(op =>
      [
        OperationCategory.dataManipulation,
        OperationCategory.math,
        OperationCategory.valuations,
        OperationCategory.dataDescription,
        OperationCategory.prediction,
      ].includes(op.category),
    ),
  )(operations)

  // Will add Outputs node  and assoc key 'show' to categories
  // For now, Prediction and Valuations are hidden for production.
  const enhancedCategories = R.compose(
    R.prepend({ id: 0, name: "outputs", title: "Outputs", description: "", show: false }),
    R.map(cat =>
      [
        OperationCategory.dataSelection,
        OperationCategory.dataManipulation,
        OperationCategory.math,
        OperationCategory.valuations,
        OperationCategory.dataDescription,
        process.env.NODE_ENV !== "production" && OperationCategory.prediction,
      ].includes(cat.id)
        ? R.assoc("show", true, cat)
        : R.assoc("show", false, cat),
    ),
  )(categories)

  return {
    categories: R.map(cat => {
      const nodes =
        cat.id === 1
          ? R.compose(
              R.sortBy(R.prop("pos")),
              R.filter(node => node.category === cat.id),
            )(deskNodes)
          : R.filter(node => node.category === cat.id, operationsToDisplay)

      return R.assoc("nodes", nodes, cat)
    }, enhancedCategories),
    nodes: R.compose(R.flatten(), R.append(operationsToDisplay))(deskNodes),
    enhancedPath,
  }
}
