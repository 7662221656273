// @ts-nocheck
import { TextArea as BP5TextArea, Callout, FormGroup } from "@blueprintjs/core"
import { Field } from "formik"

import { SCIENT_COLORS } from "../../../../styled/scientColors"

const TextArea = ({ name, value, onChange, touched, errors, handleKeyUp }) => (
  <>
    <BP5TextArea
      name={name}
      type="text"
      fill
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyUp}
    />
    {touched[name] && errors[name] && (
      <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
        {errors[name]}
      </Callout>
    )}
  </>
)

export const FormikTextArea = ({
  name,
  labelProps = null,
  onEnter = () => {
    // do nothing
  },
  onEsc = () => {
    // do nothing
  },
  ...props
}) => {
  const handleKeyUp = e => {
    if (e.key === "Enter") {
      onEnter()
    }
    if (e.key === "Escape") {
      onEsc()
    }
  }

  return (
    <Field name={name}>
      {({ field: { name, value, onChange }, form: { touched, errors } }) => {
        if (labelProps) {
          return (
            <FormGroup {...labelProps}>
              <TextArea
                name={name}
                value={value}
                onChange={onChange}
                touched={touched}
                errors={errors}
                handleKeyUp={handleKeyUp}
              />
            </FormGroup>
          )
        }

        return (
          <TextArea
            name={name}
            value={value}
            onChange={onChange}
            touched={touched}
            errors={errors}
            handleKeyUp={handleKeyUp}
          />
        )
      }}
    </Field>
  )
}
