// @ts-nocheck
import React from "react"
import { Field } from "formik"

import { TextArea, NumericInput, fakeSyntheticEvent } from "../../../../../../components/formik"

export const Input = ({ field, handleChange, ...props }) => {
  if (field.endsWith("_comment")) {
    return (
      <Field
        name={field}
        key={field}
        component={TextArea}
        onChange={handleChange}
        // placeHolder={"Enter a comment"}
      />
    )
  }
  return (
    <Field
      name={field}
      key={field}
      component={NumericInput}
      onValueChange={(numVal, stringVal) => handleChange(fakeSyntheticEvent(stringVal, field))}
      style={{ textAlign: "right" }}
      {...props}
    />
  )
}
