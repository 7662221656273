import { AgGridFilter, ScientCellStyle, ScientValueFormatter } from "./components/agGridMapping"

export enum PositionSide {
  LONG = "Long",
  SHORT = "Short",
}

export enum OrderSide {
  BUY = "Buy",
  SELL = "Sell",
  BUY_COVER = "Buy Cover",
  SELL_SHORT = "Sell Short",
}

export enum ProviderType {
  BEACON = "beacon",
  ENFUSION = "enfusion",
}

export enum PortfolioDisplayTab {
  POSITIONS = "positions",
  ORDERS = "orders",
  RISKS = "risks",
  TRADING = "trading",
  REBALANCING = "rebalancing",
}

export type ScientTradingAccount = {
  id: number
  name: string
}

export type TradingBook = {
  id: number
  code: string
  trading_account_scients: ScientTradingAccount[]
}

export type Column = {
  field: string
  filter: AgGridFilter | boolean
  valueFormatter: ScientValueFormatter
  cellStyle: ScientCellStyle
  hide?: boolean
}

export type RowStyle = {
  rowIndex: number
  rowStyle: string
}
export type Row = Record<string, string | number | null>

export type PinnedBottomRows = {
  rowStyles: RowStyle[]
  rowData: Row[]
}

export type DataframeData = {
  rows: Row[]
  pinnedBottomRows: PinnedBottomRows | null
  columns: Column[]
  last_updated_at?: string | null
}

export type IAccountBookPairs = {
  account_id: number
  book_ids?: number[]
}[]
