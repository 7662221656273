// @ts-nocheck
import { Button, ButtonGroup, Icon, Tooltip } from "@blueprintjs/core"
import * as R from "ramda"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "../../../hooks"

import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { checkElementIsInArray, useGetUserGroupIds } from "../utils"
import EditActiveDesk from "./EditActiveDesk"
import ManageDesks from "./ManageDesks"

const UserDesks = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { profile } = useAuth()
  const [manageDesks, setManageDesks] = useState(false)

  const [editActiveDesk, setEditActiveDesk] = useState(false)
  const toggleEditActiveDesk = () => setEditActiveDesk(!editActiveDesk)

  const desks = R.values(useSelector(R.pathOr([], ["desks", "desks", "data"])))

  // userDesks are the user's favorite desks
  const userDesks = R.values(useSelector(R.pathOr([], ["desks", "userDesks", "data"])))

  const activeDeskId = params.deskId ? parseInt(params.deskId) : null

  // Variables for active desk which is not in favorite(=userdDsks) in order to display deskName in desk bar
  const deskIdInUserdesks = R.map(e => e.desk, userDesks)
  const notInFavoriteDesk = !R.includes(activeDeskId, deskIdInUserdesks)
  const deskName = R.compose(R.prop("name"), R.find(R.propEq("id", activeDeskId)))(desks)
  // End

  const userId = profile.id
  const userGroupIds = useGetUserGroupIds()

  useEffect(() => {
    /**
     * If there are no activeDesk,
     * we set the first one as active
     */
    if (userDesks?.length && !activeDeskId) {
      /**
       * Navigating to this route will set the Outlet for Desks
       */
      navigate(`/desks/${userDesks[0].desk}`)
    }
  }, [activeDeskId, userDesks, navigate])

  return (
    <>
      <ManageDesks
        isOpen={manageDesks}
        userDesks={userDesks}
        activeDeskId={activeDeskId}
        setManageDesks={setManageDesks}
      />

      <div id="deskBar" style={{ padding: "8px", overflowX: "auto" }}>
        <ButtonGroup id="deskBar">
          <Tooltip content="Manage desks" placement="bottom" openOnTargetFocus={false} compact>
            <Button
              minimal
              fill
              onClick={() => setManageDesks(true)}
              style={{ height: "30px", width: "30px" }}
            >
              <Icon icon="desktop" color={SCIENT_COLORS.lightGray5} />
            </Button>
          </Tooltip>

          {notInFavoriteDesk && (
            <Button
              text={deskName}
              active={true}
              style={{ color: SCIENT_COLORS.blue3, marginRight: "50px" }}
            />
          )}

          {!R.isEmpty(userDesks) &&
            R.map(userDesk => {
              const active = userDesk.desk === activeDeskId

              const desk = R.find(R.propEq("id", userDesk.desk))(desks)
              const deskOwnerId = desk.owner_id

              const deskWriteGroupIds = R.compose(
                R.values,
                R.map(e => e.group_id),
                R.filter(e => e.write === true),
              )(R.view(R.lensProp("groups"), desk))
              const hasWritePermission = checkElementIsInArray(userGroupIds, deskWriteGroupIds)

              const style = () => {
                if (userId === deskOwnerId) {
                  return { textAlign: "center" }
                }
                if (hasWritePermission) {
                  return { textAlign: "center", color: SCIENT_COLORS.lightBlue1 }
                }
                return { textAlign: "center", color: SCIENT_COLORS.gray4 }
              }

              return active ? (
                <React.Fragment key={userDesk.id}>
                  <EditActiveDesk
                    isOpen={editActiveDesk}
                    toggleEditActiveDesk={toggleEditActiveDesk}
                    userDesks={userDesks}
                    userDesk={userDesk}
                    activeDeskId={activeDeskId}
                    hasWritePermission={hasWritePermission}
                    userId={userId}
                  />

                  <Button
                    key={userDesk.id}
                    onClick={() => navigate(`/desks/${userDesk.desk}/`)}
                    active={active}
                    style={style()}
                  >
                    {R.last(userDesk.str_name.match(/(.+)(>)(.+)/))}
                    <Icon
                      className="sct-button-in-button"
                      onClick={toggleEditActiveDesk}
                      icon="cog"
                    />
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  key={userDesk.id}
                  onClick={() => navigate(`/desks/${userDesk.desk}/`)}
                  active={active}
                  text={R.last(userDesk.str_name.match(/(.+)(>)(.+)/))}
                  style={style()}
                />
              )
            }, userDesks)}
        </ButtonGroup>
      </div>
    </>
  )
}

export default UserDesks
