// @ts-nocheck
import { ButtonGroup, Classes, FormGroup, H5, MenuItem, Popover, Tooltip } from "@blueprintjs/core"
import { DateInput3 } from "@blueprintjs/datetime2"
import { addYears } from "date-fns"
import * as R from "ramda"
import { useState } from "react"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../../state/modules/sheets"
import { Button, Suggest } from "../../../../../components"
import { useGetOutput } from "../../../utils"

const TableHeader = ({ sheet, handleGoto, milestones, colType, toggleColType }) => {
  const output = useGetOutput(sheet.id)
  const periods = R.compose(R.map(R.prop("field")), R.prop("colTitles"))(output)

  const [results, setResults] = useState(periods)

  const [lowerbound, setLowerbound] = useState(sheet.colDefs.lowerbound)
  const [upperbound, setUpperbound] = useState(sheet.colDefs.upperbound)

  const hasBound = (lowerbound || upperbound) && true

  const dispatch = useDispatch()

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item}
        text={item}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }

  const handleGotoSubmit = period => {
    handleGoto(period)
  }
  const handleQueryChange = query => {
    if (query === "") {
      setResults(periods)
    }
    const selection = periods.filter(period => period.includes(query))
    setResults(selection)
  }

  const handleTrimSubmit = () => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        colDefs: {
          ...sheet.colDefs,

          lowerbound: lowerbound,
          upperbound: upperbound,
        },
      }),
    )
  }

  return (
    <ButtonGroup
      style={{
        margin: "0 0 0 10px",
      }}
    >
      <Tooltip content="Column title type" placement="bottom" openOnTargetFocus={false} compact>
        <Button onClick={toggleColType} icon={colType === "name" ? "array-string" : "array-date"} />
      </Tooltip>

      <Popover
        content={
          <div style={{ width: "300px" }}>
            <H5>Jump to period</H5>
            <br />
            <Suggest
              fill
              placeholder="Type a period..."
              resetOnClose={true}
              resetOnSelect={true}
              items={results}
              onItemSelect={handleGotoSubmit}
              onQueryChange={handleQueryChange}
              itemRenderer={itemRenderer}
            />
            <br />
            <ButtonGroup fill>
              <Button icon="fast-backward" onClick={() => handleGoto(milestones.firstCol)} />
              <Button
                icon="step-forward"
                text="A"
                onClick={() => handleGoto(milestones.lastACol)}
              />
              <Button
                icon="step-backward"
                text="E"
                onClick={() => handleGoto(milestones.firstECol)}
              />
              <Button icon="fast-forward" onClick={() => handleGoto(milestones.lastCol)} />
            </ButtonGroup>
          </div>
        }
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        placement="bottom-end"
      >
        <Tooltip content="Jump to period" placement="bottom" openOnTargetFocus={false} compact>
          <Button icon="time" />
        </Tooltip>
      </Popover>

      <Popover
        content={
          <div style={{ width: "300px" }}>
            <H5>Trim</H5>
            <FormGroup fill label="From" labelFor="date-picker-start" labelInfo="(optional)">
              <DateInput3
                id="date-picker-start"
                fill
                showActionsBar
                dateFnsFormat="dd-MM-yyyy" // Mutually exclusive with the formatDate and parseDate props.
                maxDate={addYears(new Date(), 3)}
                placeholder="DD-MM-YYYY"
                value={lowerbound && lowerbound}
                onChange={date => setLowerbound(date)}
              />
            </FormGroup>
            <FormGroup fill label="To" labelFor="date-picker-end" labelInfo="(optional)">
              <DateInput3
                id="date-picker-end"
                fill
                showActionsBar
                dateFnsFormat="dd-MM-yyyy" // Mutually exclusive with the formatDate and parseDate props.
                maxDate={addYears(new Date(), 15)}
                placeholder="DD-MM-YYYY"
                value={upperbound && upperbound}
                onChange={date => setUpperbound(date)}
              />
            </FormGroup>
            <Button fill intent="primary" text="Submit" onClick={handleTrimSubmit} />
          </div>
        }
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        placement="bottom-end"
      >
        <Tooltip content="Trim" placement="bottom" openOnTargetFocus={false} compact>
          <Button intent={hasBound ? "primary" : undefined} icon="array-timestamp" />
        </Tooltip>
      </Popover>
    </ButtonGroup>
  )
}
export default TableHeader
