// @ts-nocheck
import {
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Label,
  NumericInput,
  Switch,
  Tag,
  TextArea,
  Tooltip,
} from "@blueprintjs/core"
import { Field } from "formik"
import React from "react"

export const resolveError = (form, field, defaultValue = null) =>
  field.name
    // .split(/[\.\[\]\'\"]/)
    .split(/[.[\]'"]/)
    .filter(p => p)
    .reduce((o, p) => (o ? o[p] : defaultValue), form.errors)

export const FormikTextInput = ({ field, labelProps, inputProps }) => {
  return (
    <FormGroup
      // helperText="Helper text with details..."
      labelFor="text-input"
      {...labelProps}
    >
      <InputGroup {...field} {...inputProps} />
    </FormGroup>
  )
}

export const FormikTextArea = ({ field, inputProps, labelProps }) => {
  return (
    <FormGroup labelFor="text-input" {...labelProps}>
      <TextArea {...field} {...inputProps} />
    </FormGroup>
  )
}

export const FormikSwitch = ({ field, inputProps, labelProps }) => {
  return <Switch checked={field.value} {...field} {...labelProps} {...inputProps} />
}

export const FormikSelect = ({ field, inputProps }) => {
  return <HTMLSelect value={field.value} fill {...field} {...inputProps} />
}

export const fakeSyntheticEvent = (value, id) => ({
  persist: () => {
    // do nothing.
  },
  target: {
    type: "change",
    id,
    name: id,
    value,
  },
})

export const FormikDecimalInput = ({ field, form, inputProps }) => {
  const handleValueChange = value => form.setFieldValue(field.name, value)
  const error = resolveError(form, field)
  return (
    <>
      <Tooltip
        isOpen={error ? true : false}
        content={<Tag>{error ? error.replace(field.name, "") : "..."}</Tag>}
        intent="warning"
        openOnTargetFocus={false}
      >
        <NumericInput fill {...field} {...inputProps} onValueChange={handleValueChange} />
      </Tooltip>
    </>
  )
}

export const Decimals = ({ index }) => {
  return (
    <Label>
      Decimals (min/max)
      <ControlGroup fill>
        <Field
          name={`rowDefs.rows[${index}].frontOpts.decimals.min`}
          component={FormikDecimalInput}
        />
        <Field
          name={`rowDefs.rows[${index}].frontOpts.decimals.max`}
          component={FormikDecimalInput}
        />
      </ControlGroup>
    </Label>
  )
}
