// @ts-nocheck
import autodux from "autodux"
import { lensPath, mergeDeepLeft, over } from "ramda"

const entryLens = idx => lensPath(["entries", "results", idx])

const entriesDuck = autodux({
  slice: "entries",
  initial: {
    fetching: true,
    error: false,
    entries: [],
    entry: {},
  },
  actions: {
    fetchEntries: (state, payload) => ({ ...state, ...payload, fetching: true }),
    fetchEntriesOk: (state, entries) => ({
      ...state,
      fetching: false,
      entries,
    }),
    fetchEntriesError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),

    updateEntry: (state, { idx, form }) =>
      over(
        entryLens(idx),
        mergeDeepLeft({
          loading: true,
          ...form,
        }),
      )(state),
    updateEntryOk: (state, { idx, data }) =>
      over(
        lensPath(["entries", "results", idx]),
        mergeDeepLeft({ loading: false, error: false, ...data }),
      )(state),
    updateEntryError: (state, { idx, error }) =>
      over(lensPath(["entries", "results", idx]), mergeDeepLeft({ loading: false, error }))(state),
  },
})

export default entriesDuck
