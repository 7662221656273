// @ts-nocheck
import { startOfDay, subDays } from "date-fns"
import * as R from "ramda"

export const sortForecasts = ({ thresholdDays, starred, excluded, forecasts }) => {
  const thresholdDate = startOfDay(subDays(new Date(), thresholdDays))

  const fromObjToSortedList = x => {
    const output = []
    if (x.starred) output.push(x.starred)
    if (x.inThreshold) output.push(x.inThreshold)
    if (x.outThreshold) output.push(x.outThreshold)
    if (x.excluded) output.push(x.excluded)
    return output
  }

  return R.compose(
    R.flatten,
    fromObjToSortedList,
    R.mapObjIndexed((value, key) => {
      if (key === "starred") {
        return value.map(R.assoc("starred", true))
      }
      if (key === "excluded") {
        return value.map(R.assoc("excluded", true))
      }
      if (key === "inThreshold") {
        return value.map(R.assoc("inThreshold", true))
      }
      return value.map(R.assoc("outThreshold", true))
    }),
    R.groupBy(forecast => {
      if (R.includes(forecast.slug, starred)) {
        return "starred"
      }
      if (R.includes(forecast.slug, excluded)) {
        return "excluded"
      }
      if (new Date(forecast.owner_updated_at) > thresholdDate) {
        return "inThreshold"
      }
      if (new Date(forecast.owner_updated_at) <= thresholdDate) {
        return "outThreshold"
      }
    }),
    R.sort((a, b) => new Date(b.owner_updated_at) - new Date(a.owner_updated_at)),
    R.values,
  )(forecasts)
}
