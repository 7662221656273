import { Callout, OverlayToaster, Position, Toast2 } from "@blueprintjs/core"
import { getIn, useField, useFormikContext } from "formik"
import { Fragment, useEffect, useState } from "react"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { capitalizeFirstLetter } from "../../../../utils"
import useSectors from "../hooks/useSectors"
import { IdeaPosition, IdeaStock, PositionType } from "../types/business"
import { IIdeaCreateForm } from "./IdeaCreate"
import { IdeaCreateLabel, IdeaSettingsInnerContainer } from "./IdeaCreate.styled"
import SectorTabs from "./SectorTabs"
import StockField from "./StockField"
import ToggleButtonGroup from "./ToggleButtonGroup"

interface IIdeaCreateTradeSettings {
  initialSeletedStockLong?: Partial<IdeaStock>
  initialSeletedStockShort?: Partial<IdeaStock>
  isTradeOpen: boolean
}

const IdeaCreateTradeSettings = ({
  initialSeletedStockLong,
  initialSeletedStockShort,
  isTradeOpen,
}: IIdeaCreateTradeSettings) => {
  const [longType] = useField("trade.long.instance.position_type")
  const [shortType] = useField("trade.short.instance.position_type")

  const { sectors, error } = useSectors()
  const [showSectorError, setShowSectorError] = useState(false)

  const { errors } = useFormikContext<Partial<IIdeaCreateForm>>()

  /**
   * Effect to display the toast error if the getSectors
   * query fail.
   */
  useEffect(() => {
    if (error) setShowSectorError(true)
  }, [error])

  return (
    <IdeaSettingsInnerContainer flexDirection="column">
      {Object.values(IdeaPosition).map(position => {
        /** Get the correct type field for each position. */
        const positionTypeField = position === IdeaPosition.LONG ? longType : shortType
        /** Recreate nested fieldname position.id for formik. */
        const positionIdFieldName = `trade.${position}.instance.id`
        return (
          <Fragment key={position}>
            <FlexContainer flexDirection="row" gap="10px" alignItems="center">
              <IdeaCreateLabel>{capitalizeFirstLetter(position)}</IdeaCreateLabel>
              <ToggleButtonGroup
                items={PositionType}
                fieldName={positionTypeField.name}
                disabled={isTradeOpen}
              />
            </FlexContainer>
            {positionTypeField.value === PositionType.STOCK && (
              <StockField
                ideaPosition={position}
                initialSelectedValue={
                  position === IdeaPosition.LONG
                    ? initialSeletedStockLong
                    : initialSeletedStockShort
                }
                isTradeOpen={isTradeOpen}
              />
            )}
            {positionTypeField.value === PositionType.SECTOR && sectors?.length && (
              <SectorTabs
                sectors={sectors}
                fieldName={positionIdFieldName}
                disabled={isTradeOpen}
              />
            )}
            {getIn(errors, `trade.${position}.instance`) && (
              <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                {`${capitalizeFirstLetter(position)} is a required field`}
              </Callout>
            )}
            <OverlayToaster position={Position.TOP_RIGHT}>
              {showSectorError && (
                <Toast2
                  message="Unable to get sectors, please try to refresh the page."
                  intent="danger"
                  onDismiss={() => setShowSectorError(false)}
                />
              )}
            </OverlayToaster>
          </Fragment>
        )
      })}
    </IdeaSettingsInnerContainer>
  )
}

export default IdeaCreateTradeSettings
