// @ts-nocheck
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export const iconCss = css({
  marginLeft: "8px",
})

export const singleTitleTypeCss = css({
  textAlign: "center",
  marginBottom: "24px",
})

export const LabelTitleStyled = styled.p({
  margin: "4px 0 0 20px",
})
