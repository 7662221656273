// @ts-nocheck
import { getTime } from "date-fns"
import { compose, includes, isEmpty, isNil, last, reverse } from "ramda"

import { formatNumber } from "../../../../_shared"
import {
  defaultOptions,
  minusOneDayData,
  multiplyBy100,
  removePointsBefore,
} from "../../../../_shared/highcharts"

const options = (entries, dataSet, dsForecasts) => {
  const pct = dataSet.unit_str && includes("pct", dataSet.unit_str)
  const data = reverse(
    entries.results.map(e => [getTime(new Date(e.end)), pct && e.value ? e.value * 100 : e.value]),
  )
  const lastReportedPoint = last(data)

  const forecastSeries = !isEmpty(dsForecasts.results)
    ? dsForecasts.results
        .map(f => {
          if (f.aggregated) {
            return {
              name: f.name,
              data: compose(
                minusOneDayData,
                removePointsBefore(lastReportedPoint),
                multiplyBy100(pct),
              )(f.agg_output),
            }
          }
          if (!isNil(f.dsfentries)) {
            const dsfentries = reverse(
              f.dsfentries.map(e => [
                getTime(new Date(e.end)),
                pct && e.value ? e.value * 100 : e.value,
              ]),
            )

            const dsfentriesWithFirst = removePointsBefore(lastReportedPoint, [
              lastReportedPoint,
              ...dsfentries,
            ])

            return {
              name: f.name,
              data: minusOneDayData(dsfentriesWithFirst),
            }
          }
          return {}
        })
        .filter(f => !isEmpty(f))
    : []

  return {
    ...defaultOptions,
    title: null,
    yAxis: {
      title: {
        text: dataSet.unit,
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value, pct, false)
        },
      },
    },
    tooltip: {
      crosshairs: [true],
      headerFormat: "{point.x:%d-%m-%Y}",
      pointFormatter: function () {
        return "<p>" + this.series.name + ": " + formatNumber(this.y, pct, false) + "</p>"
      },
      valueDecimals: 2,
    },
    series: [
      {
        name: dataSet.name,
        data: minusOneDayData(data),
      },
      ...forecastSeries,
    ],
    legend: {
      enabled: true,
      floating: true,
      layout: "horizontal",
      align: "right",
      borderWidth: 0,
      verticalAlign: "top",
      x: -10,
    },
  }
}

export default options
