// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import { indexBy, prop } from "ramda"

import authDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchUserList, fetchUserListOk, fetchUserListError },
} = authDuck

const fetchUserListEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchUserList.type),
    mergeMap(() => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/users/`
      return ajax({
        method: "GET",
        url,
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          // we want to keep only 'id' and 'username' from response
          const cleanResult = response.results.map(user => ({
            id: user.id,
            username: user.username,
          }))
          const shapeUserList = indexBy(prop("id"), cleanResult)
          return fetchUserListOk({ userList: shapeUserList })
        }),
        catchError(error => of(fetchUserListError(error.xhr.response))),
      )
    }),
  )

export default fetchUserListEpic
