// @ts-nocheck
import { assoc } from "ramda"
import { of } from "rxjs"
import { mergeMap, map, catchError, withLatestFrom } from "rxjs/operators"
import { ofType } from "redux-observable"

import dataframesDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import shapeDataframes from "./shapeDataframes"

const {
  actions: { fetchAdditionalDataframe, fetchAdditionalDataframeOk, fetchAdditionalDataframeError },
} = dataframesDuck

const fetchAdditionalDataframeEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchAdditionalDataframe.type),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { sheetId, newDfId } = action.payload
      const dataframes = state.dataframes
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/dataframes/search/?ids=${newDfId}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          const shapeNewDf = shapeDataframes(response)
          const newByDfId = assoc(newDfId, shapeNewDf[newDfId], dataframes.byDfId)

          return fetchAdditionalDataframeOk({ sheetId, newDf: newByDfId })
        }),
        catchError(error => of(fetchAdditionalDataframeError(error.xhr.response))),
      )
    }),
  )

export default fetchAdditionalDataframeEpic
