// @ts-nocheck
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import { concat } from "ramda"

import { defaultOptions } from "../../../../_shared/highcharts"
import formatSeries from "./formatSeries"
import { allColors } from "../../components/colors"

const yAxis = (row, index) => {
  const color = allColors[index]
  const format = row.extra.frontOpts.pct ? "{value}%" : "{value}"

  return {
    labels: {
      format,
      style: { color },
    },
    title: {
      style: { color },
    },
    opposite: row.extra.frontOpts.chart.own_y_axis,
  }
}

const Chart = ({ analyse }) => {
  const { rowData } = analyse.grid

  const options = {
    ...defaultOptions,
    title: { text: analyse.name },
    yAxis: concat([{ opposite: false }], rowData.map(yAxis)),
    series: formatSeries(analyse),
  }

  return (
    <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options} />
  )
}

export default Chart
