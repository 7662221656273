// @ts-nocheck
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  append,
  compose,
  filter,
  find,
  isNil,
  map,
  not,
  prepend,
  prop,
  propEq,
  update,
} from "ramda"
import { Classes, Dialog, H3, FormGroup } from "@blueprintjs/core"

import dsForecastsDuck from "../../../../../../../state/modules/dsForecasts"
import { removeNulls } from "../../../../../../../utils"
import { Button, DataSetSelect, Select, InputGroup, Loader } from "../../../../../../components"

const DataSetModal = ({ modal, setModal, dsforecast, index }) => {
  const dispatch = useDispatch()

  const existingLink = not(isNil(dsforecast.dsflinks[modal.index]))
  const dsflink = existingLink
    ? dsforecast.dsflinks[modal.index]
    : {
        source: { id: null },
        subsource: { id: null },
      }
  const [source, setSource] = useState(dsflink.source)
  const [subsource, setSubsource] = useState(dsflink.subsource)
  const [ref, setRef] = useState(dsflink.ref || "")
  const [forecast_periods, setForecastPeriods] = useState(dsflink.forecast_periods || "")
  const allForecasts = useSelector(({ dsForecasts }) => dsForecasts.all.results)

  if (!allForecasts) return <Loader />

  const relatedForecasts = compose(filter(propEq("dataset", source.id)))(allForecasts)

  const dsfOpts = compose(
    prepend({ value: "", label: "No forecast" }),
    map(f => ({ value: f.id, label: `${f.name}-${f.owner}` })),
  )(relatedForecasts)

  const handleSubmit = () => {
    const dsflink = isNil(prop("id", subsource))
      ? { source, ref }
      : { source, ref, subsource, forecast_periods }

    const dsflinks = existingLink
      ? update(modal.index, dsflink, removeNulls(dsforecast.dsflinks))
      : append(dsflink, removeNulls(dsforecast.dsflinks))
    const form = { id: dsforecast.id, dataset: dsforecast.dataset, dsflinks }
    dispatch(
      dsForecastsDuck.actions.updateForecast({
        index,
        form,
      }),
    )
  }

  return (
    <Dialog
      className="bp5-dark"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      icon="diagram-tree"
      title="Edit source"
      intent="primary"
      isOpen={modal}
      onClose={() => setModal(null)}
      enforceFocus={false}
    >
      <div data-cy="DSFLinkModal" className={Classes.DIALOG_BODY}>
        <H3 style={{ textAlign: "center" }}>Edit source</H3>
        <FormGroup label="DataSet" labelInfo="required">
          <DataSetSelect
            dropDown
            placeholder="Choose DataSet"
            submit={dataset => setSource(dataset)}
            selection={source.name}
          />
        </FormGroup>
        <InputGroup
          labelProps={{ label: "Ref", labelInfo: "required" }}
          inputProps={{
            "data-cy": "DSFLinkRef",
            value: ref,
            onChange: ({ target: { value } }) => setRef(value),
          }}
        />
        <FormGroup label="Forecast">
          <Select
            data-cy="DSFLinkForecast"
            selectProps={{
              fill: true,
              options: dsfOpts,
              value: subsource ? subsource.id : "",
              onChange: ({ target: { value } }) =>
                setSubsource(find(propEq("id", parseInt(value)))(relatedForecasts)),
            }}
          />
        </FormGroup>
        {subsource && (
          <InputGroup
            labelProps={{ label: "Forecast periods", labelInfo: "required" }}
            inputProps={{
              "data-cy": "DSFLinkForecastPeriods",
              value: forecast_periods,
              onChange: ({ target: { value } }) => setForecastPeriods(value),
            }}
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="floppy-disk" intent="primary" text="Save" onClick={handleSubmit} />
        </div>
      </div>
    </Dialog>
  )
}
export default DataSetModal
