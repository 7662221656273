import { AxiosInstance, AxiosResponse } from "axios"

export interface IMonitorsApi {
  monitors: {
    list: {
      create: (values: any) => Promise<AxiosResponse<any, any>>
    }
  }
}

/**
 * Api routes for /api/v1/monitors/
 */
const monitors = (axiosClient: AxiosInstance) => ({
  /**
   * POST /api/v1/monitors/
   */
  monitors: {
    list: {
      create: (values: any) => axiosClient.post(`/monitors/`, values),
    },
  },
})

export default monitors
