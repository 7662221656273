// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit"
import searchDSForecastsReducers from "./reducers/searchDSForecasts"
import searchDataSetsReducers from "./reducers/searchDataSets"
import searchOmniByIdsReducers from "./reducers/searchOmniByIds"
import searchOmniByNameReducers from "./reducers/searchOmniByName"
import searchRForecastsReducers from "./reducers/searchRForecasts"
import searchStocksReducers from "./reducers/searchStocks"

export const initialState = {
  dataSets: { searching: false, results: [] },
  dSForecasts: { searching: false, results: [] },
  rForecasts: { searching: false, results: [] },
  stocks: { searching: false, results: [] },
  omniByIds: { searching: false, results: {} },
  omniByName: { searching: false, results: [] },
}

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    ...searchStocksReducers,
    ...searchRForecastsReducers,
    ...searchDataSetsReducers,
    ...searchDSForecastsReducers,
    ...searchOmniByIdsReducers,
    ...searchOmniByNameReducers,
  },
})

export const {
  searchDataSets,
  searchDataSetsOk,
  searchDataSetsError,
  clearDataSets,

  searchDSForecasts,
  searchDSForecastsOk,
  searchDSForecastsError,
  clearDSForecasts,

  searchRForecasts,
  searchRForecastsOk,
  searchRForecastsError,
  clearRForecasts,

  searchStocks,
  searchStocksOk,
  searchStocksError,
  clearStocks,

  searchOmniByIds,
  searchOmniByIdsOk,
  searchOmniByIdsError,
  clearOmniByIds,

  searchOmniByName,
  searchOmniByNameOk,
  searchOmniByNameError,
  clearOmniByName,
  addSingleItem,
} = searchSlice.actions

export default searchSlice.reducer
