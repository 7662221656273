// @ts-nocheck
import { useMutation } from "@tanstack/react-query"
import { useApi } from "./useApi"

export const useLoginMutation = ({ onSuccess, onError }) => {
  const { scientApi } = useApi()

  return useMutation(scientApi.auth.login, { onSuccess, onError })
}

export const useVerifyTokenMutation = ({ onSuccess, onError }) => {
  const { scientApi } = useApi()

  return useMutation(scientApi.auth.verify, { onSuccess, onError })
}

export const useRefreshTokenMutation = ({ onSuccess, onError }) => {
  const { scientApi } = useApi()

  return useMutation(scientApi.auth.refresh, { onSuccess, onError })
}

export const useResetPasswordConfirmMutation = ({ onSuccess, onError }) => {
  const { scientApi } = useApi()

  return useMutation(scientApi.auth.passwordResetConfirm, { onSuccess, onError })
}
