/**
 * Here are the colors comming from BluePrint v5 used in Scient.
 * https://blueprintjs.com/docs/#core/colors.color-schemes
 * All gray scale are listed.
 * Only core colors used until now are listed.
 * Some extended colors are also used.
 * If need others blueprint colors can be add in this list.
 *
 * Blueprint components can also be colorized using the 'intent' props.
 *   Each core color is mapped to what we call a visual intent. We use intents to convey the status of UI elements:
 *        Blue (intent: primary) elevates elements from the typical gray scale UI frame => blue3: #2d72d2
 *        Green (intent: success) indicates successful operation => green3: #238551
 *        Orange (intent: warning) indicates warnings and intermediate states => orange5: #fbb360
 *        Red (intent: danger) indicates errors and potentially destructive operations => red3: #cd4246
 *
 */

export const SCIENT_COLORS = {
  // Gray scale
  black: "#111418",
  darkGray1: "#1C2127",
  darkGray2: "#252A31",
  darkGray3: "#2F343C",
  darkGray4: "#383E47",
  darkGray5: "#404854", // use for Tooltip text
  gray1: "#5F6B7C",
  gray2: "#738091",
  gray3: "#8F99A8",
  gray4: "#ABB3BF",
  gray5: "#C5CBD3",
  lightGray1: "#D3D8DE",
  lightGray2: "#DCE0E5",
  lightGray3: "#E5E8EB",
  lightGray4: "#EDEFF2",
  lightGray5: "#F6F7F9",

  //Core colors
  blue1: "#184A90",
  blue2: "#215DB0",
  blue3: "#2D72D2",
  blue4: "#4C90F0",
  blue5: "#8ABBFF",
  blue6: "#106BA3",
  green1: "#195133",
  green2: "#1C5A39",
  green3: "#206440",
  green4: "#287d50",
  green5: "#72CA9B",
  green6: "#35CD42",
  orange1: "#77450D",
  orange2: "#935610",
  orange3: "#C87619",
  orange4: "#EC9A3C",
  orange5: "#FBB360",
  red1: "#c83e42",
  red3: "#CD4246",
  red4: "#E76A6E",
  red5: "#FA999C",

  //Extended colors
  vermilion3: "#D33D17",
  vermilion4: "#EB6847",
  rose3: "#DB2C6F",
  violet5: "#D69FD6",
  indigo1: "#5642A6",
  indigo4: "#9881F3",
  indigo5: "#BDADFF",
  cerulean1: "#0C5174",
  cerulean2: "#0F6894",
  cerulean3: "#147EB3",
  cerulean5: "#68C1EE",
  turquoise1: "#004D46",
  turquoise3: "#00A396",
  turquoise5: "#7AE1D8",
  forest3: "#29A634",
  lime3: "#8EB125",
  gold3: "#D1980B",
  sepia3: "#946638",
  sepia5: "#D0B090",

  //Color schemes
  lightBlue1: "#91ACE5",
  lightBlue2: "#98a3ab",
  lightBlue3: "#727a80",
  lightBlue4: "#4c5155",

  // white
  white: "#FFFFFF",
  darkWhite: "#CCCCCC",

  // Button greens
  greenDefault: "#1C5A39",
  greenHover: "#326a4c",
  greenSelected: "#133e27",
  greenHoverSelected: "#16482d",

  // Button Blue
  blueSelected: "#0f2f5c",
  blueHoverSelected: "#133B73",
  blueDefault: "#2a4e81",
  blueHover: "#42628f",
}

/**
 * Colors taken from the Aggrid theme balham dark
 */
export const SCIENT_AGGRID_COLORS = {
  headerBackground: "#161818",
  headerFont: "rgba(245, 245, 245, 0.64)",
  defaultRowBackground: "#2d3436",
  altRowBackground: "#262c2e",
  borderColor: "#5c5c5c",
}
