// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError, withLatestFrom } from "rxjs/operators"
import { ofType } from "redux-observable"

import scenariosDuck from "../index"
import authDuck from "../../auth"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchDataSets, fetchDataSetsOk, fetchDataSetsError },
} = scenariosDuck

const fetchDataSetsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchDataSets.type),
    withLatestFrom(state$),
    mergeMap(([, { dataSets }]) => {
      let url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/`
      const params = ["last_entries=ok"]
      if (dataSets.page) params.push(`page=${dataSets.page}`)
      if (dataSets.geo_id) params.push(`geo_id=${dataSets.geo_id}`)
      if (dataSets.sector_id) params.push(`sector_id=${dataSets.sector_id}`)
      url += `?${params.join("&")}`
      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchDataSetsOk(response)),
        catchError(error => {
          if (error.response.detail === "Signature has expired.") {
            auth.deleteToken()
            return of(authDuck.actions.verifyTokenError(error.response.detail))
          }
          return of(fetchDataSetsError(error.xhr.response))
        }),
      )
    }),
  )

export default fetchDataSetsEpic
