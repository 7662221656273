import styled from "@emotion/styled"

interface AgGridContainerProps {
  width?: string
}

export const AgGridContainer = styled.div<AgGridContainerProps>`
  height: 72px;
  width: ${props => `${props.width}px`};
  margin-top: 18px;
  .ag-body-viewport.ag-layout-normal {
    overflow-y: hidden;
  }
`
