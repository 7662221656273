// @ts-nocheck
import { withFormik } from "formik"
import { compose, pathOr } from "ramda"

import stockDetailDuck from "../../../../state/modules/stockDetail"
import { handleError } from "../../../components/formik"
import { recursiveNullToEmptyStr } from "../../../utils"
import { cleanReport, reportSchema } from "../utils"
import Form from "./Form"

const EditForm = withFormik({
  mapPropsToValues: props => {
    const output = compose(recursiveNullToEmptyStr, pathOr({}, ["reportData", "report"]))(props)
    return output
  },
  validationSchema: reportSchema,
  handleSubmit: (values, { props, status, setStatus, setSubmitting }) => {
    const { dispatch, handleClose } = props
    const cleanValues = cleanReport(values)
    setSubmitting(true)
    props.scientApi.reporting.reports.detail
      .update({
        values: cleanValues,
      })
      .then(response => {
        setSubmitting(false)
        dispatch(
          stockDetailDuck.actions.updateReport({
            values: response.data,
          }),
        )
        handleClose()
      })
      .catch(e => handleError(setSubmitting, status, setStatus, e))
  },
})(Form)

export default EditForm
