// @ts-nocheck
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import {
  FlexContainer,
  flexContainerCss,
  FlexItem,
  flexItemCss,
} from "../../../../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"

/**
 * This Container is both a parent and flex child
 * We need to use this annotation to inherit from multiple css
 * instances
 */
export const RowTreeContainer = styled.div`
  ${flexContainerCss}
  ${flexItemCss}
  overflow: auto;
  height: 100%;
  padding-top: 12px;
  padding-bottom: 6px;
`

export const RowTreeButtonsContainer = styled(FlexContainer)({
  width: "50%",
  padding: "0 6px",
})

export const RowTreeOptionsContainer = styled(FlexItem)({
  padding: "0 6px",
})

export const moveIconCss = css({
  margin: "auto",
  paddingRight: "6px",
  color: "#AAB6C1",
})

export const rowNameButtonCss = (node, isActiveItem) =>
  css({
    backgroundColor:
      node.kind === "group"
        ? `${SCIENT_COLORS.indigo1} !important`
        : node.kind === "single" && node.isHeader
        ? `${SCIENT_COLORS.turquoise1} !important`
        : `${SCIENT_COLORS.cerulean1} !important`,
    color: isActiveItem && "#99faa1 !important",
    fontWeight: isActiveItem && "bold",

    // Override CSS of blueprint Button in order to have ellipsis in Button which diplay row's name.
    ".bp5-button-text": {
      textOverflow: "ellipsis" /* enables ellipsis */,
      whiteSpace: "nowrap" /* keeps the text in a single line */,
      overflow: "hidden" /* keeps the element from overflowing its parent */,
    },
  })

export const itemButtonCss = (color, isActiveItem) =>
  css({
    backgroundColor: `${color} !important`,
    height: isActiveItem && "40px",
  })
