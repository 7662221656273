// @ts-nocheck
import { compose, flatten, map, reject, uniq } from "ramda"
import { getFormulaItems } from "./getFormulaItems"

export const getTFSources = rowDefs => {
  const formulaItems = rowDefs.map(row => getFormulaItems(row.formula))
  const regex = /(#)(\w+)/gi
  const reg = /\w+/gi
  const sourceList = compose(
    flatten(),
    map(e => e.match(reg)),
    uniq(),
    reject(e => e === null),
    flatten(),
    map(e => e.map(i => i.item.match(regex))),
  )(formulaItems)
  return sourceList
}
