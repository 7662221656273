import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const HeaderContainer = styled(FlexContainer)({
  height: "140px",
  padding: "10px 25px 0 25px",
})

export const CyclicalyButton = styled.a({
  ".bp5-icon-minus": { opacity: 0 },
  "&:hover": {
    ".bp5-icon-minus": { opacity: 1 },
  },
})

export const minusIconCss = css({
  color: "white !important",
  marginTop: "-5px",
})
