// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import { indexBy, prop } from "ramda"

import authDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchGroupList, fetchGroupListOk, fetchGroupListError },
} = authDuck

const fetchGroupListEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchGroupList.type),
    mergeMap(() => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/groups/`
      return ajax({
        method: "GET",
        url,
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          const shapeGroupList = indexBy(prop("id"), prop("results", response))
          return fetchGroupListOk({ groupList: shapeGroupList })
        }),
        catchError(error => of(fetchGroupListError(error.xhr.response))),
      )
    }),
  )

export default fetchGroupListEpic
