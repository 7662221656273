// @ts-nocheck
import React from "react"

import { Grid } from "../../../../../components"
import { InputRow, TitleRow, ControlRow, ReadonlyRow } from "./components"
import { createCFRows } from "./CFRows"

const CF = ({ handleChange, values, reportData: { prevReport, prevYearReport } }) => {
  const groupRows = [...createCFRows({ values, prevReport, prevYearReport, handleChange })]
  return (
    <Grid fluid>
      {groupRows.map(({ type, props }, index) => {
        if (type === "title") {
          return <TitleRow key={index} {...props} />
        }
        if (type === "control") {
          return <ControlRow key={index} {...props} />
        }
        if (type === "readonly") {
          return <ReadonlyRow key={index} {...props} />
        }
        return <InputRow key={index} {...props} />
      })}
    </Grid>
  )
}

export default CF
