import styled from "@emotion/styled"
import { FlexContainer, FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { ShadowContainer } from "../../../../../styled/layout.styled"

export const IdeasFiltersContainer = styled(ShadowContainer)({
  padding: "10px 10px 10px 10px",
})

export const IdeasFilterLabel = styled.p({
  width: "60px",
})

export const FilterSearchInputContainer = styled(FlexContainerAndItem)({})

export const RelativeFilterIconContainer = styled(FlexContainer)({
  position: "relative",
})

export const AbsoluteFilterIconContainer = styled(FlexContainer)({
  position: "absolute",
  right: "-5px",
  top: "10px",
})
