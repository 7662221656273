// @ts-nocheck
import React from "react"
import { Callout } from "@blueprintjs/core"

export const NonFieldErrors = ({ status }) => {
  if (status && status.errors && status.errors.length > 0) {
    return (
      <Callout intent="warning">
        {status.errors.map((error, index) => (
          <p key={index}>{error}</p>
        ))}
      </Callout>
    )
  }
  return null
}
