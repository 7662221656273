// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import { SCIENT_COLORS } from "../styled/scientColors"

export const SortLogoDesc = ({ width, height }) => {
  const iconColorCss = css`
    fill: ${SCIENT_COLORS.gray4};
  `
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      css={iconColorCss}
    >
      <path d="M22.5,20H11.5c-.829,0-1.5,.672-1.5,1.5s.671,1.5,1.5,1.5h11c.829,0,1.5-.672,1.5-1.5s-.671-1.5-1.5-1.5Z" />
      <path d="M10,15.5c0,.828,.671,1.5,1.5,1.5h7c.829,0,1.5-.672,1.5-1.5s-.671-1.5-1.5-1.5h-7c-.829,0-1.5,.672-1.5,1.5Z" />
      <path d="M11.5,11h3c.829,0,1.5-.672,1.5-1.5s-.671-1.5-1.5-1.5h-3c-.829,0-1.5,.672-1.5,1.5s.671,1.5,1.5,1.5Z" />
      <path d="M9.752,3.879L6.166,.293c-.391-.391-1.024-.391-1.414,0L1.166,3.879c-.39,.391-.39,1.024,0,1.414,.187,.187,.442,.293,.707,.293h2.127V24.729c0,.828,.671,1.5,1.5,1.5s1.5-.672,1.5-1.5V5.586h2.045c.552,0,1-.448,1-1,0-.265-.105-.519-.293-.707Z" />
    </svg>
  )
}

export const SortLogoAsc = ({ width, height }) => {
  const iconColorCss = css`
    fill: ${SCIENT_COLORS.gray4};
  `
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      css={iconColorCss}
    >
      <path d="M22.5,1H11.5c-.829,0-1.5,.672-1.5,1.5s.671,1.5,1.5,1.5h11c.829,0,1.5-.672,1.5-1.5s-.671-1.5-1.5-1.5Z" />
      <path d="M18.5,7h-7c-.829,0-1.5,.672-1.5,1.5s.671,1.5,1.5,1.5h7c.829,0,1.5-.672,1.5-1.5s-.671-1.5-1.5-1.5Z" />
      <path d="M14.5,13h-3c-.829,0-1.5,.672-1.5,1.5s.671,1.5,1.5,1.5h3c.829,0,1.5-.672,1.5-1.5s-.671-1.5-1.5-1.5Z" />
      <path d="M9.172,18.414h-2.172V1.5c0-.828-.671-1.5-1.5-1.5s-1.5,.672-1.5,1.5V18.414H2c-.552,0-1,.448-1,1,0,.265,.105,.519,.293,.707l3.586,3.586c.391,.39,1.024,.39,1.414,0l3.586-3.586c.39-.391,.39-1.024,0-1.414-.187-.187-.442-.293-.707-.293Z" />
    </svg>
  )
}
