import { Button, Icon, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useQueryClient,
} from "@tanstack/react-query"
import { isEmpty } from "ramda"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, Outlet, useLocation, useOutlet } from "react-router-dom"
import { SCIENT_ROUTES } from "../../Routes"
import { useIdeasCache } from "../../hooks"
import { useApi } from "../../hooks/useApi"
import {
  IdeasContainer,
  LeftContainer,
  NoIdeasPlaceholder,
  NoIdeasTitle,
  RightContainer,
} from "./Ideas.styled"
import IdeaDetails from "./List/Content/IdeaDetails"
import IdeaHeader from "./List/Content/IdeaHeader"
import Inbox from "./List/Inbox/Inbox"
import { SpinnerContainer } from "./List/Inbox/Inbox.styled"
import InboxHeader from "./List/Inbox/InboxHeader"
import { useInfiniteIdeas } from "./hooks"
import { Idea, IdeaRevision } from "./types/business"

const Ideas = () => {
  const queryClient = useQueryClient()
  const {
    stockFilter,
    sectorFilter,
    userFilter,
    draftFilter,
    ideaTypeFilter,
    tradeOpenOnly,
    tradeClosedOnly,
    orderBy,
    sortDirection,
    setDraftFilter,
  } = useIdeasCache()

  /**
   * State to store the selected Idea and revision
   */
  const [selectedIdeaId, setSelectedIdeaId] = useState<number | undefined>(undefined)
  const [selectedRevision, setSelectedRevision] = useState<IdeaRevision | undefined>(undefined)

  /**
   * State to know if the ideas page was successfully loaded for the first time.
   * Useful to prevent re-rendering the all page when fetching new ideas when
   * sorting, ordering or filtering.
   */
  const [firstLoadingComplete, setFirstLoadingComplete] = useState(false)

  const { scientApi } = useApi()

  /**
   * Prefecth query for all sectors that we need in filters and the create view.
   */
  const prefetchSectors = useCallback(async () => {
    await queryClient.prefetchQuery(["sectors"], scientApi.world.getSectors)
  }, [queryClient, scientApi])
  /**
   * Prefecth query for all users that we need in filters and the create view.
   */
  const prefetchUsers = useCallback(async () => {
    await queryClient.prefetchQuery(["users"], scientApi.users.get)
  }, [queryClient, scientApi])
  /**
   * Prefecth all draft ideas.
   */
  const prefetchDrafts = useCallback(async () => {
    await queryClient.prefetchInfiniteQuery(
      [
        "ideas",
        "draft",
        { orderBy: "default", sortDirection: "DESC" },
        { stock: undefined, sector: undefined, user: undefined },
      ],
      () =>
        scientApi.ideas.getInfiniteIdeas({
          pageParam: undefined,
          stockParam: undefined,
          sectorParam: undefined,
          userParam: undefined,
          draftParam: true,
          ideaTypeParam: null,
        }),
    )
  }, [queryClient, scientApi])

  useEffect(() => {
    prefetchSectors()
    prefetchUsers()
    prefetchDrafts()
  }, [prefetchDrafts, prefetchUsers, prefetchSectors])

  /**
   * Every time we change the ideas list,
   * we unselect the idea.
   */
  useEffect(() => {
    setSelectedIdeaId(undefined)
    setSelectedRevision(undefined)
  }, [
    stockFilter,
    sectorFilter,
    userFilter,
    draftFilter,
    ideaTypeFilter,
    tradeOpenOnly,
    tradeClosedOnly,
    orderBy,
    sortDirection,
    queryClient,
  ])

  const { state: locationState } = useLocation()
  /**
   * If when navigating to the page we retrieve a draft state,
   * we set the filter.
   * This allow us to directly land in the correct ideas inbox if
   * we created or updated a draft idea.
   */
  useEffect(() => {
    if (locationState && locationState?.draft !== undefined && setDraftFilter) {
      setDraftFilter(locationState.draft)
    }
  }, [locationState, setDraftFilter])

  /**
   * Infinite hook to GET ideas
   * ideas and messages react query cache are available in IdeaCacheContext
   */
  const { ideas, fetchNextPage, hasNextPage, isFetching } = useInfiniteIdeas({
    stockParam: stockFilter?.id,
    sectorParam: sectorFilter?.id,
    userParam: userFilter?.id,
    draftParam: draftFilter,
    ideaTypeParam: ideaTypeFilter,
    tradeOpenOnlyParam: tradeOpenOnly,
    tradeClosedOnlyParam: tradeClosedOnly,
    orderByParam: orderBy,
    sortDirectionParam: sortDirection,
  })

  const selectedIdea = useMemo(
    () => ideas?.find(idea => idea.id === selectedIdeaId),
    [ideas, selectedIdeaId],
  )

  /**
   * Effect to set the firstLoadingComplete when we succesfully received
   * the first page of ideas from the back.
   * From this point we never reload the all ideas page if we change any filters.
   */
  useEffect(() => {
    if (ideas?.length && !firstLoadingComplete) {
      setFirstLoadingComplete(true)
    }
  }, [ideas?.length, firstLoadingComplete])

  /**
   * If we have some ideas and that no ideas are selected,
   * we set the first one as selected by default
   */
  useEffect(() => {
    if (!selectedIdeaId && !!ideas?.length) {
      setSelectedIdeaId(ideas[0].id)
    }
  }, [ideas, selectedIdeaId])

  /**
   * Outlet is either create or update view
   * If outlet is present, we display it
   */
  const outlet = useOutlet()

  /**
   * If there is an outlet, it takes precedence since we dont display
   * edit or create inside the main view
   * We also create an outlet context: https://reactrouter.com/en/main/hooks/use-outlet-context
   * in order to pass down to Child routes props needed
   * @todo: Remove outletContext when doing deep linking i.e. props in url
   */
  const [outletContext, setOutletContext] = useState({
    setSelectedIdeaId,
    setSelectedInboxRevision: setSelectedRevision,
  })

  /**
   * Whenever one of the outlet context dependencies change,
   * we update it
   */
  useEffect(() => {
    setOutletContext({
      setSelectedIdeaId,
      setSelectedInboxRevision: setSelectedRevision,
    })
  }, [setSelectedIdeaId, setSelectedRevision])

  if (outlet) {
    return <Outlet context={[outletContext]} />
  }

  /**
   * Display a placeholder if there's no Idea yet.
   */
  if ((!isFetching || isEmpty(ideas)) && !firstLoadingComplete) {
    return (
      <IdeasContainer>
        <NoIdeasPlaceholder
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="25px"
        >
          <Icon icon="inbox" size={100} />
          <NoIdeasTitle>There are no Idea yet</NoIdeasTitle>
          <Link to={`${SCIENT_ROUTES.IDEAS}/create`}>
            <Button text="  Create first Idea" intent="primary" large />
          </Link>
        </NoIdeasPlaceholder>
      </IdeasContainer>
    )
  }

  return (
    <IdeasContainer>
      {firstLoadingComplete ? (
        <>
          <LeftContainer flexDirection="column">
            <InboxHeader selectedIdea={selectedIdea} />
            <Inbox
              ideas={ideas}
              selectedIdea={selectedIdea}
              setSelectedIdeaId={setSelectedIdeaId}
              fetchNextPage={
                fetchNextPage as (
                  options?: FetchNextPageOptions | undefined,
                ) => Promise<InfiniteQueryObserverResult<Idea[], unknown>>
              }
              hasNextPage={!!hasNextPage}
              isFetchingIdeas={isFetching}
              setSelectedRevision={setSelectedRevision}
            />
          </LeftContainer>
          <RightContainer flexDirection="column" flexGrow={1} gap="10px">
            {selectedIdea ? (
              <>
                <IdeaHeader idea={selectedIdea} selectedRevision={selectedRevision} />
                <IdeaDetails
                  idea={selectedIdea}
                  setSelectedRevision={setSelectedRevision}
                  selectedRevision={selectedRevision}
                />
              </>
            ) : !isFetching ? (
              <NoIdeasPlaceholder
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap="25px"
              >
                <Icon icon="zoom-out" size={50} />
                <NoIdeasTitle>{"No Idea To Display"}</NoIdeasTitle>
              </NoIdeasPlaceholder>
            ) : (
              <SpinnerContainer justifyContent="center" alignItems="center" flexGrow={1}>
                <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
              </SpinnerContainer>
            )}
          </RightContainer>
        </>
      ) : (
        <SpinnerContainer justifyContent="center" alignItems="center" flexGrow={1}>
          <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
        </SpinnerContainer>
      )}
    </IdeasContainer>
  )
}

export default Ideas
