// @ts-nocheck
import { combineEpics } from "redux-observable"

import fetchMonitorListEpic from "./fetchMonitorListEpic"
import createMonitorEpic from "./createMonitorEpic"
import updateMonitorEpic from "./updateMonitorEpic"
import deleteMonitorEpic from "./deleteMonitorEpic"
import upsertMonitorEpic from "./upsertMonitorEpic"

const sheetEpics = combineEpics(
  fetchMonitorListEpic,
  createMonitorEpic,
  updateMonitorEpic,
  deleteMonitorEpic,
  upsertMonitorEpic,
)

export default sheetEpics
