// @ts-nocheck
import React from "react"
import { useDispatch } from "react-redux"
import { Alert } from "@blueprintjs/core"

import profileDuck from "../../../../../../state/modules/profile"

export const CannotDragAlert = ({ stockSlug, cannotDrag, setCannotDrag }) => {
  const dispatch = useDispatch()

  const handleCancel = () => setCannotDrag(false)
  const handleShowEmpty = () => {
    dispatch(profileDuck.actions.stockToggleEmpty({ slug: stockSlug }))
    setCannotDrag(false)
  }

  return (
    <Alert
      className="bp5-dark"
      canEscapeKeyCancel={true}
      canOutsideClickCancel={true}
      intent="primary"
      isOpen={cannotDrag}
      onCancel={handleCancel}
      cancelButtonText="Back"
      confirmButtonText="Show empty rows"
      onConfirm={handleShowEmpty}
    >
      You cannot drag if empty rows are hidden.
    </Alert>
  )
}
