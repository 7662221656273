import { useInfiniteQuery } from "@tanstack/react-query"
import { useApi } from "../../../hooks/useApi"
import { ModelUpdate } from "../types/business"

export interface IInfiniteModelUpdates {
  stockParam?: number
  userParam?: number
}
/**
 * React query hook to GET model_updates paginated
 */

const useInfiniteModelUpdates = ({ stockParam, userParam }: IInfiniteModelUpdates) => {
  const { scientApi } = useApi()

  const { data, error, fetchNextPage, hasNextPage, status, isFetching, dataUpdatedAt } =
    useInfiniteQuery<{
      data: ModelUpdate[]
      next_date: string | null
    }>(
      ["noc", "modelUpdates", { stock: stockParam, analyst: userParam }],
      ({ pageParam }) =>
        scientApi.noc.getInfiniteModelUpdates({
          pageParam,
          stockParam: stockParam,
          userParam: userParam,
        }),
      {
        getNextPageParam: (lastPage, pages) => {
          if (lastPage.next_date) {
            return lastPage.next_date
          }
          return undefined
        },
        // https://github.com/TanStack/query/issues/3065
        // TODO: once v5 is out, allow typing on select for infinite data
        // @ts-ignore
        select: data => {
          let modelUpdates: ModelUpdate[] = []
          for (const page of data.pages) {
            modelUpdates = [...modelUpdates, ...page.data]
          }
          return modelUpdates
        },
        staleTime: Infinity,
      },
    )

  return {
    modelUpdates: data as ModelUpdate[] | undefined,
    fetchNextPage,
    hasNextPage,
    error,
    status,
    isFetching,
    dataUpdatedAt,
  }
}

export default useInfiniteModelUpdates
