// @ts-nocheck
import React from "react"
import { connect } from "react-redux"
import { compose, lifecycle, withHandlers } from "recompose"
import { pick } from "ramda"
import { Button, MenuItem } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import dataSetSearchDuck from "../../state/modules/dataSetSearch"

import { Suggest } from "."

const DumbDataSetSelect = ({
  dropDown = false,
  placeholder,
  updateSearch,
  searching,
  response,
  submit,
  selection = undefined,
}) => {
  const results = response.results ? response.results : []

  if (dropDown) {
    const itemRenderer = (ds, { handleClick }) => (
      <MenuItem key={ds.id} onClick={handleClick} text={ds.name} />
    )

    return (
      <Select
        className="sct-fullwidth"
        items={results}
        itemRenderer={itemRenderer}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={submit}
        onQueryChange={updateSearch}
      >
        <Button
          data-cy="DataSetSelect"
          fill
          rightIcon="caret-down"
          text={selection ? selection : "(No selection)"}
        />
      </Select>
    )
  }
  return (
    <Suggest
      placeholder={placeholder}
      loading={searching}
      resetOnClose={true}
      resetOnSelect={true}
      items={results}
      onItemSelect={submit}
      onQueryChange={updateSearch}
    />
  )
}

const mapStateToProps = ({ dataSetSearch: { searching, response } }) => ({
  searching,
  response,
})

export const DataSetSelect = compose(
  connect(
    mapStateToProps,
    pick(["searchDataSet", "clearDataSetSearch"], dataSetSearchDuck.actions),
  ),
  lifecycle({
    componentDidMount() {
      this.props.clearDataSetSearch()
    },
  }),
  withHandlers({
    updateSearch:
      ({ searchDataSet }) =>
      query =>
        searchDataSet({ hide_daily: false, eco_only: false, search: query }),
  }),
)(DumbDataSetSelect)
