// @ts-nocheck
import * as R from "ramda"

import { formatPathIntoObject } from "./index"
import { randomid } from "../../../../../../utils"

// This function take the string header, identifie each element and put it in an array of object
export const getHeaderItems = (header, enhancedPath) => {
  let headerItems = []

  // Regex to identify element referrings to source and to parents and grandparents
  const regexSourceName = /source\('name'\)/g
  const regexBloombergCode = /source\('ticker'\)/g
  const regexParent = /above\('\w+'\)/g
  const regexGrandParent = /above\('\w+', 1\)/g

  //Regex to extract each element of header
  const regexHeader =
    /(source\('name'\))|(source\('ticker'\))|(above\('\w+', 1\))|(above\('\w+'\))|((\w+\s?)+)/g
  const headerElements = header !== "[]" && header.length > 0 ? header.match(regexHeader) : []

  headerElements.forEach(e => {
    if (regexSourceName.test(e)) {
      headerItems = R.append(
        { item: "<sourceName>", text: "Primary name", type: "dynamic", itemId: randomid() },
        headerItems,
      )
    } else if (regexBloombergCode.test(e)) {
      headerItems = R.append(
        { item: "<ticker>", text: "Primary ticker", type: "dynamic", itemId: randomid() },
        headerItems,
      )
    } else if (regexParent.test(e)) {
      if (enhancedPath.splits && !enhancedPath.segs) {
        headerItems = R.append(
          { item: "<splitName>", text: "Split name", type: "dynamic", itemId: randomid() },
          headerItems,
        )
      }
      if (enhancedPath.segs) {
        headerItems = R.append(
          { item: "<segName>", text: "Segment name", type: "dynamic", itemId: randomid() },
          headerItems,
        )
      }
      if (enhancedPath.fields) {
        headerItems = R.append(
          { item: "<fieldName>", text: "Field name", type: "dynamic", itemId: randomid() },
          headerItems,
        )
      }
    } else if (regexGrandParent.test(e)) {
      headerItems = R.append(
        { item: "<splitName>", text: "Split name", type: "dynamic", itemId: randomid() },
        headerItems,
      )
    } else {
      headerItems = R.append({ item: e, text: e, type: "static", itemId: randomid() }, headerItems)
    }
  })

  return headerItems
}

// This function  generates header formated for back-end from headerItems composed by user
export const generateHeaderForBackEnd = (headerItems, path) => {
  let header = ""

  const niceHeaderElements = !R.isEmpty(headerItems) ? headerItems.map(e => e.item) : []

  const enhancedPath = formatPathIntoObject(path)
  // we wants to know if the first ancestor of a node is a Splits
  const hasSplitParent = enhancedPath.splits && !enhancedPath.segs

  niceHeaderElements.map(e =>
    e.includes("<splitName>") && hasSplitParent
      ? (header = header + "above('name'),' ',") // leave space after to have space in grid header
      : e.includes("<splitName>")
      ? (header = header + "above('name', 1),' ',")
      : e.includes("<segName>") || e.includes("<fieldName>")
      ? (header = header + "above('name'),' ',")
      : e.includes("<sourceName>")
      ? (header = header + "source('name'),' ',")
      : e.includes("<ticker>")
      ? (header = header + "source('ticker'),' ',")
      : (header = header + `'${e}',' ',`),
  )

  header = `[${header}]`
  return header
}
