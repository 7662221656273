// @ts-nocheck
import React from "react"
import { FormGroup, NumericInput, Spinner } from "@blueprintjs/core"

export const NumericGroup = ({ labelProps, inputProps: { loading, fill = true, ...rest } }) => {
  if (labelProps) {
    return (
      <FormGroup
        // helperText="Helper text with details..."
        // label="Label A"
        // labelFor="text-input"
        // labelInfo="(required)"
        {...labelProps}
      >
        <NumericInput
          style={{ textAlign: "right" }}
          rightElement={loading ? <Spinner size="18" /> : null}
          // autoFocus={autoFocus}
          fill={fill}
          {...rest}
        />
      </FormGroup>
    )
  }

  return (
    <div className="sct-side-item">
      <NumericInput
        rightElement={loading ? <Spinner size="18" /> : null}
        // autoFocus={autoFocus}
        {...rest}
      />
    </div>
  )
}
