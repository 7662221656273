// @ts-nocheck
import {
  ControlGroup,
  FormGroup,
  InputGroup,
  Label,
  NumericInput,
  Switch,
  TextArea,
} from "@blueprintjs/core"
import { Field } from "formik"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"

export const resolveError = (form, field, defaultValue = null) =>
  field.name
    .split(/[.[\]'"]/)
    .filter(p => p)
    .reduce((o, p) => (o ? o[p] : defaultValue), form.errors)

export const FormikTextInput = ({ field, form, labelProps, inputProps }) => {
  return (
    <FormGroup
      // helperText="Helper text with details..."
      labelFor="text-input"
      {...labelProps}
      style={{ margin: 0 }}
    >
      <InputGroup {...field} {...inputProps} />
    </FormGroup>
  )
}

export const FormikTextArea = ({ field, form, inputProps, labelProps }) => {
  return (
    <FormGroup labelFor="text-input" {...labelProps}>
      <TextArea {...field} {...inputProps} />
    </FormGroup>
  )
}

export const FormikSwitch = ({ field, form, inputProps, labelProps }) => {
  return (
    <Switch
      checked={field.value}
      style={{ marginTop: "30px" }}
      {...field}
      {...labelProps}
      {...inputProps}
    />
  )
}

export const fakeSyntheticEvent = (value, id) => ({
  persist: () => {
    // do nothing.
  },
  target: {
    type: "change",
    id,
    name: id,
    value,
  },
})

export const FormikDecimalInput = ({ field, form, inputProps }) => {
  const handleValueChange = value => form.setFieldValue(field.name, value)
  const error = resolveError(form, field)
  return (
    <>
      <NumericInput min={0} onValueChange={handleValueChange} {...field} {...inputProps} />
      {error && <p style={{ color: SCIENT_COLORS.orange3 }}>{error.replace(field.name, "")}</p>}
    </>
  )
}
export const Decimals = ({ index }) => {
  return (
    <ControlGroup style={{ margin: "28px 8px 8px 0", paddingRight: "240px" }} fill>
      <Label>
        Min decimals
        <Field name={`rowDefs[${index}].frontOpts.decimals.min`} component={FormikDecimalInput} />
      </Label>
      <Label>
        Max decimals
        <Field name={`rowDefs[${index}].frontOpts.decimals.max`} component={FormikDecimalInput} />
      </Label>
      <Field
        name={`rowDefs[${index}].frontOpts.pct`}
        labelProps={{ label: "In percent" }}
        component={FormikSwitch}
      />
    </ControlGroup>
  )
}
