// @ts-nocheck
import { curry, isEmpty, isNil } from "ramda"

export const multiplyBy100 = curry((pct, data) => {
  if (isNil(data) || isEmpty(data)) return null

  if (!pct) {
    return data
  }

  let newData = data
  if (typeof data === "string") {
    newData = JSON.parse(data)
  }

  return newData.map(([timeStamp, value]) => [timeStamp, Math.round(value * 10000) / 100])
})
