// @ts-nocheck
import { lensPath, mergeDeepRight, over } from "ramda"

export const fetchProfile = () => ({
  fetching: true,
})

export const fetchProfileOk = (state, profile) => ({
  fetching: false,
  ...profile,
})

export const fetchProfileError = (state, fetchError) => ({
  ...state,
  fetching: false,
  fetchError,
})

export const upsertUserStockConfigOk = (state, { slug, data }) =>
  over(
    lensPath(["userstockconfigs", slug]),
    mergeDeepRight({ ...data, activeTab: state.userstockconfigs[slug].activeTab }),
    state,
  )

export const upsertUserStockConfigError = state => state
