// @ts-nocheck
import { Button, NonIdealState } from "@blueprintjs/core"
import * as R from "ramda"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import "react-grid-layout/css/styles.css"

import desksDuck from "../../../../state/modules/desks"
import { Loader } from "../../../components"
import Desk from "./Desk"
import { SCIENT_ROUTES } from "../../../Routes"

const DeskLoader = () => {
  const navigate = useNavigate()
  const [faultyLayout, setFaultyLayout] = useState(true)
  const { deskId } = useParams()

  const desk = useSelector(R.pathOr(null, ["desks", "desks", "data", deskId]))

  const deskReady = R.propOr(null, "ready", desk)
  const userDesksReady = useSelector(R.pathOr(null, ["desks", "userDesks", "ready"]))

  const monitorsReady = useSelector(R.pathOr(null, ["monitors", "ready"]))

  const dispatch = useDispatch()

  useEffect(() => {
    if (desk && desk.layout.length !== desk.monitor_ids.length) {
      setFaultyLayout(true)
      const newLayout = desk.monitor_ids.map((monitorId, index) => ({
        i: String(monitorId),
        x: 0,
        y: index * 6,
        w: 12,
        h: 6,
      }))
      dispatch(desksDuck.actions.updateDesk({ id: desk.id, layout: newLayout }))
    } else {
      setFaultyLayout(false)
    }
  }, [dispatch, desk])

  if (!desk) {
    return (
      <NonIdealState icon={"issue"} title={"Desk not found or permission denied"}>
        <p>Click to be redirected</p>
        <Button text={"Redirect"} onClick={() => navigate(SCIENT_ROUTES.DESKS)} large />
      </NonIdealState>
    )
  }

  if ((!deskReady && !monitorsReady && !userDesksReady) || faultyLayout) {
    return <Loader />
  }

  return <Desk desk={desk} />
}

export default DeskLoader
