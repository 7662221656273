// @ts-nocheck
import * as yup from "yup"

export const MscRowDefsSchema = yup.object().shape({
  rowDefs: yup.object().shape({
    rows: yup.array().of(
      yup.object().shape({
        kind: yup.string().required(),
        rowId: yup.string().required(),
        stocks: yup.array().when("kind", {
          is: "stockRows",
          then: () =>
            yup
              .array()
              .min(1, "At least one item must be added")
              .of(
                yup
                  .object()

                  .shape({
                    id: yup.number().required(),
                    str_name: yup.string().required(),
                    frontOpts: yup.object().shape({
                      titleOverride: yup.string(),
                      styles: yup.object().shape({
                        bold: yup.boolean(),
                        italic: yup.boolean(),
                        focus: yup.string(),
                        frontColor: yup.string(),
                      }),
                    }),
                  }),
              ),
        }),
        method: yup.string().when("kind", {
          is: "aggregate",
          then: () => yup.string().required(),
        }),
        sources: yup.array().when("kind", {
          is: "aggregate",
          then: () =>
            yup
              .array()
              .of(yup.string().min(1, "At least one box must be ticked"))
              .required("Required"),
        }),
        frontOpts: yup.object().shape({
          title: yup.string(),
          titleOverride: yup.string(),
          styles: yup.object().shape({
            bold: yup.boolean(),
            italic: yup.boolean(),
            focus: yup.string(),
            frontColor: yup.string(),
          }),
        }),
      }),
    ),
  }),
})

export const MscColDefsSchema = yup.object().shape({
  colDefs: yup.object().shape({
    cols: yup.array().of(
      yup.object().shape({
        kind: yup.string().required(),
        colId: yup.string().required(),
        // agg: yup.boolean(),
        freq: yup.string(),

        lowerPeriod: yup
          .number()
          .when("kind", {
            is: "stockField",
            then: () => yup.number().required().integer(),
          })
          .when("kind", {
            is: "factor",
            then: () => yup.number().required().max(0).integer(),
          }),
        upperPeriod: yup
          .number()
          .when("kind", {
            is: "stockField",
            then: () => yup.number().required().min(yup.ref("lowerPeriod")).integer(),
          })
          .when("kind", {
            is: "factor",
            then: () => yup.number().required().min(yup.ref("lowerPeriod")).max(0).integer(),
          }),

        path: yup
          .string()
          .when("kind", {
            is: "stockField",
            then: () => yup.string().required("Field is required"),
          })
          .when("kind", {
            is: "stockParam",
            then: () => yup.string().required(),
          }),
        label: yup.string().when("kind", {
          is: "factor",
          then: () => yup.string().required("Field is required"),
        }),
        formulaItems: yup.array().when("kind", {
          is: "formula",
          then: () =>
            yup
              .array()
              .min(1, "Formula is empty")
              .of(
                yup.object().shape({
                  item: yup.string(),
                  itemId: yup.string(),
                }),
              )
              .required("Required"),
        }),

        frontOpts: yup
          .object()
          .when("kind", {
            is: "title",
            then: () =>
              yup.object().shape({
                title: yup.string(),
                styles: yup.object().shape({
                  bold: yup.boolean(),
                  italic: yup.boolean(),
                  focus: yup.string(),
                  frontColor: yup.string(),
                }),
              }),
          })
          .when("kind", {
            is: kind => kind === "factor" || kind === "stockField",
            then: () =>
              yup.object().shape({
                titleOverride: yup.string(),
                pct: yup.boolean(),
                styles: yup.object().shape({
                  bold: yup.boolean(),
                  italic: yup.boolean(),
                  focus: yup.string(),
                  frontColor: yup.string(),
                }),
                decimals: yup.object().shape({
                  min: yup.number().required().min(0).integer(),
                  max: yup.number().required().min(yup.ref("min")).integer(),
                }),
              }),
          })
          .when("kind", {
            is: "formula",
            then: () =>
              yup.object().shape({
                title: yup.string().required("Formula title is required"),
                pct: yup.boolean(),
                styles: yup.object().shape({
                  bold: yup.boolean(),
                  italic: yup.boolean(),
                  focus: yup.string(),
                  frontColor: yup.string(),
                }),
                decimals: yup.object().shape({
                  min: yup.number().required().min(0).integer(),
                  max: yup.number().required().min(yup.ref("min")).integer(),
                }),
              }),
          }),
      }),
    ),
  }),
})
