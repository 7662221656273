// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import desksDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
// import { assoc, compose } from "ramda"

const {
  actions: { fetchUserDesks, fetchUserDesksOk, fetchUserDesksError },
} = desksDuck

const fetchUserDesksEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchUserDesks.type),
    mergeMap(() =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/userdesks/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          return fetchUserDesksOk({ data: response.results })
        }),
        catchError(error => {
          return of(fetchUserDesksError(error.xhr.response))
        }),
      ),
    ),
  )

export default fetchUserDesksEpic
