// @ts-nocheck
export const fields = {
  main: ["year", "period", "start", "end", "release_date"],
  shared: [
    "sales",
    "sales_growth_readonly", // (sales - sales year ago) / sales year ago
    "sales_organic_growth",
    "sales_price_growth",
    "sales_volume_growth",
    "sales_other_organic_growth",
    "sales_other_organic_growth_2",
    "sales_calendar_effect_growth",
    "sales_scope_growth",
    "sales_acquisition_growth",
    "sales_fx_growth",
    "sales_other_scope_growth",
    "sales_other_scope_growth_qoq",
    "sales_growth_qoq_readonly", // (sales - sales quarter ago) / sales quarter ago
    "sales_organic_growth_qoq",
    "sales_volume_growth_qoq",
    "sales_price_growth_qoq",
    "sales_other_organic_growth_qoq",
    "sales_scope_growth_qoq",
    "sales_acquisition_growth_qoq",
    "sales_other_scope_growth_qoq",
    "sales_fx_growth_qoq",

    "orders",
    "orders_growth_readonly", // (orders - orders year ago) / orders year ago
    "orders_price_growth",
    "orders_volume_growth",
    "orders_other_organic_growth",
    "orders_organic_growth",
    "orders_acquisition_growth",
    "orders_fx_growth",
    "orders_other_scope_growth",
    "orders_scope_growth",

    "profit",
    "profit_growth_readonly", // (profit - profit year ago) / profit year ago
    "profit_margin_readonly",
    "profit_margin_override",
    //.. get profit_margin or if null get profit_margin_readonly; it is to
    // allow to user profit_maring computer but override that if needed
    // "profit_margin_readonly", //  (profit / sales)

    "backlog",
    "backlog_growth_readonly", // (backlog - backlog year ago) / backlog ago
  ],
  incomestatement: [
    "is_ppa_amortization",
    "is_walkdown_customsegment_to_reported_ebit",
    "is_exceptionals",
    "is_ebit_from_customsegments_readonly", //  was computed in b_estimates, scenarios
    //  profit from all segments +
    //  if stock.ppa_in_segments 0 else ppa_amortization +
    //  walkdown_customsegment_to_reported_ebit +
    //  if stock.restructuring_in_segments 0 else restructuring_costs +
    //  if stock.exceptionals_in_segments 0 else exceptionals
    "is_reported_cogs",
    "is_gross_profit", // profit / is_reported_cogs   //  was computed in b_estimates, scenarios
    "is_s_g_and_a",
    "is_expensed_r_and_d",
    "is_other_income_expense",
    "is_non_operating_income_above",
    "is_non_operating_income_below",
    "is_share_of_profit_from_associates_above",
    "is_share_of_profit_from_associates_below",
    "is_depreciation",
    "is_amortization",
    "is_ebitda",
    "is_ebita",
    "is_ebit_reported_readonly",
    "is_ebit_reported_override",
    "is_financial_income",
    "is_profit_before_tax_readonly",
    "is_tax_expense",
    "is_minority_interest",
    "is_net_profit_from_continuing_operations",
    "is_dividend",
    "is_net_profit_from_discontinued_operations",
    "is_net_profit_for_the_period",
    "is_average_shares_out",
    "is_average_fully_diluted_out",
    "is_diluted_eps_adjusted",
    "is_diluted_eps_reported",
    "is_d_a_reported_in_is",
    "is_restructuring_costs",
    "is_dps",
    "is_roce",
    //  from reports
    //        "is_ebit_reported_override", // TODO check that(i think it is not used, and in new world it could be replaces by is_ebit_reported / readonly
    "is_share_buyback", //  from reports, scenarios
    "is_share_issuance", //  from reports, scenarios
    //  from scenarios
    "is_buyback_price",
    "is_issuance_price",
    "is_payout_ratio",
    "is_tax_rate",
    "is_depreciation_as_ola_n_1",
    "is_operating_lease_depreciation",
    "is_lease_interest_to_liabilities",
    "is_lease_interest",
    "is_ppe_depreciation",
    "is_gross_profit",
  ],
  balancesheet: [
    "bs_working_capital_company_definition",
    "bs_gross_bv_tangibles",
    "bs_accumulated_depreciation",
    "bs_ntfa",
    "bs_intangibles_with_indefinite_useful_life_gross",
    "bs_intangibles_with_indefinite_useful_life_net",
    "bs_impairment_intangibles_indefinite_useful_life",
    "bs_goodwill_gross",
    "bs_goodwill_net",
    "bs_impairment_to_goodwill_for_the_year",
    "bs_other_intangibles_gross",
    "bs_other_intangibles_net",
    "bs_amortization_expense_other_intangibles",
    "bs_tangible_assets",
    "bs_intangible_assets",
    "bs_investment_in_associate",
    "bs_investment_in_other_affiliated_companies",
    "bs_non_current_financial_assets",
    "bs_deferred_tax_assets",
    "bs_other_non_current_assets",
    "bs_fixed_assets",
    "bs_cash",
    "bs_receivables",
    "bs_inventories",
    "bs_other_non_wc_current_assets",
    "bs_other_wc_current_assets",
    "bs_current_assets",
    "bs_total_assets",
    "bs_capital_shares",
    "bs_other_reserves",
    "bs_retained_earnings",
    "bs_minorities",
    "bs_shareholders_equity",
    "bs_long_term_debt",
    "bs_non_current_leases",
    "bs_other_long_term_liabilities",
    "bs_pension_provisions",
    "bs_deferred_tax_liab",
    "bs_tax_liabilities",
    "bs_provisions",
    "bs_fixed_liabilities",
    "bs_short_term_borrowings",
    "bs_current_leases",
    "bs_accounts_payable_and_other_wc_current_liabilities",
    "bs_short_term_provisions",
    "bs_current_tax_liabilities",
    "bs_other_non_wc_current_liabilities",
    "bs_current_liabilities",
    "bs_total_equity_liabilities",
    "bs_number_employees",
    "bs_avg_salary",
    "bs_associates_fairvalue",
    "bs_minorities_fairvalue",
    "bs_pensions_liab_fairvalue",
    "bs_ev_adj_readonly",
    "bs_ev_adj_override",
    "bs_bps",
    //  from scenarios
    "bs_amortization_pct_bv",
    "bs_depreciation_pct_ntfa",
    "bs_net_debt_readonly",
    "bs_net_debt_override",
    "bs_wcr_to_sales",
    "bs_fi_to_grossdebt",
    "bs_pct_cash_debtreduction",
    "bs_operating_lease_assets",
  ],
  cashflow: [
    "cf_cash_taxes",
    "cf_d_a_delta_vs_p_l",
    "cf_gains_and_losses_from_investment_in_associate",
    "cf_gains_and_losses_on_fixed_assets_divestments",
    "cf_gains_and_losses_on_retirement_benefits",
    "cf_financial_income_losses_delta_vs_p_l",
    "cf_changes_in_taxes",
    "cf_share_based_expense",
    "cf_restructuring_income",
    "cf_other_items",
    "cf_changes_in_inventories",
    "cf_changes_in_receivables",
    "cf_changes_in_payables",
    "cf_other_changes_in_current_items_of_bs",
    "cf_capex_tangibles",
    "cf_capex_intangibles",
    "cf_cash_outflows_for_financial_assets",
    "cf_cash_outflows_for_acquisitions",
    "cf_cash_inflow_from_sale_of_tangible_assets",
    "cf_cash_inflow_from_sale_of_intangible_assets",
    "cf_cash_inflow_from_sale_of_subsidiaries",
    "cf_interest_and_dividends",

    "cf_cfi_readonly",

    "cf_dividends_to_minorities",
    "cf_dividends",
    "cf_proceeds_issuance_of_shares",
    "cf_proceeds_from_borrowings",
    "cf_repayment_of_borrowings",
    "cf_interest_paid_and_other_financial_disbursement",
    "cf_net_cash_contributions_to_pensions",
    "cf_add_back_non_cash_pension_charge",

    "cf_cff_readonly",
    "cf_opfcf_company_definition",
    "cf_fx_impact_on_cash_and_equivalents",
    "cf_other_changes",
    "cf_cash_at_the_beginning_of_the_year",
    "cf_restricted_cash",
    "cf_cash_at_the_end_of_the_year_reported",
    "cf_wc_delta_bs_cf",
    "cf_cfo_readonly",
    "cf_opfcf_readonly",
    "cf_opfcf_override",
    "cf_intangible_capex_to_sales",
    "cf_tangible_capex_to_sales",
    "cf_leases_to_sales",
    "cf_capex_lease_assets",
  ],
  currency: ["sales_exposure", "cost_exposure"],
  // profit_beta,
  //  (self.sales_exposure * self.rfentry.sales
  //   - self.cost_exposure * self.rfentry.sales
  //   * (Decimal(1.0) - self.rfentry..profit_margin))
  //  / self.rfentry.profit]

  // geo_fields: ["share"]
  customField: ["value"],
  //"kpi"
}

// export const fields = {
//   main,
//   shared,
//   incomestatement,
//   balancesheet,
//   cashflow,
//   //cashflowBase,
//   currency,
//   customField,
// }
