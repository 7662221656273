// @ts-nocheck
import { Button, Card, Collapse, Label, MenuItem } from "@blueprintjs/core"
import { Field } from "formik"
import * as R from "ramda"
import { useState } from "react"
import { useSelector } from "react-redux"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { Col, Row } from "../../../../../../../components"
import { Suggest } from "../../../../../../../components/index"
import { Agg, Decimals, FormikSwitch, FormikTextInput, TimeChoice } from "../FormikFields"
import FormikStyling from "../FormikStyling"

export const Factor = ({ values, setValues, showOptions, toogleShowOptions, index }) => {
  const factorList = useSelector(R.pathOr({}, ["shared", "fieldList", "factor_list"]))
  const [results, setResults] = useState(factorList)

  const { kind, label, last } = values.colDefs.cols[index]

  const text = label ? R.find(R.propEq("key", label))(factorList)["text"] : "Choose label"

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.key}
        text={item.text}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }

  const updateSearch = query => {
    if (query === "") {
      setResults(factorList)
    }
    const selection = factorList.filter(
      factor => factor.key.includes(query.toLowerCase()) || factor.key.includes(query),
    )
    setResults(selection)
  }

  const handleSubmit = value => {
    const newValues = {
      colDefs: {
        ...values.colDefs,
        cols: R.map(
          x => (R.indexOf(x, values.colDefs.cols) === index ? { ...x, label: value.key } : x),
          values.colDefs.cols,
        ),
      },
    }
    return setValues(newValues)
  }

  return (
    <Card>
      <Row middle="xs">
        <Col xs={4}>
          <Label>
            Label
            <Suggest
              placeholder={text}
              resetOnClose={true}
              resetOnSelect={true}
              items={results}
              onItemSelect={handleSubmit}
              onQueryChange={updateSearch}
              itemRenderer={itemRenderer}
            />
            {label === "" && <p style={{ color: SCIENT_COLORS.orange3 }}>Field is required!</p>}
          </Label>
        </Col>
        <Col xs={4}>
          <Field
            name={`colDefs.cols[${index}].last`}
            component={FormikSwitch}
            labelProps={{ label: "Last reported" }}
            inputProps={{ checked: last === true }}
          />
        </Col>
        <Col xs={4}>
          <Agg index={index} />
        </Col>
      </Row>

      <TimeChoice index={index} kind={kind} last={last} />

      <Button
        fill
        alignText="left"
        minimal
        icon={showOptions ? "caret-down" : "caret-right"}
        onClick={toogleShowOptions}
      >
        {showOptions ? "Hide" : ""} Options
      </Button>
      <Collapse isOpen={showOptions}>
        <Card>
          <Field
            name={`colDefs.cols[${index}].frontOpts.titleOverride`}
            component={FormikTextInput}
            labelProps={{ label: "Title override", style: { padding: "0 180px 0 8px" } }}
            inputProps={{ placeholder: "Enter Title" }}
            helperText="Enter title or leave empty."
          />
          <Decimals index={index} />
          <FormikStyling index={index} />
        </Card>
      </Collapse>
    </Card>
  )
}
