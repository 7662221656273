// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit"
import fetchTransformOperationsReducers from "./reducers"

export const initialState = { fetching: false, results: {} }

const transformSlice = createSlice({
  name: "transform",
  initialState,
  reducers: { ...fetchTransformOperationsReducers },
})

export const {
  fetchTransformOperations,
  fetchTransformOperationsOk,
  fetchTransformOperationsError,
} = transformSlice.actions

export default transformSlice.reducer
