// @ts-nocheck
/** @jsxImportSource @emotion/react */

import React, { useEffect } from "react"
import { Button, H1 } from "@blueprintjs/core"
import { Form, Formik, useFormikContext } from "formik"
import { Grid, Row, Col } from "react-flexbox-grid"

import { MainSettings } from "./MainSettings"
import { CellsSettings } from "./CellsSettings"
import { ChartSettings } from "./ChartSettings"
import { generateHeaderForBackEnd, updateRowTree } from "../../../../utils"
import { singleTitleTypeCss } from "./Single.styled"

const style = { marginTop: "16px" }

const AutoSubmitForm = () => {
  const { values, submitForm } = useFormikContext()

  useEffect(() => {
    if (values) {
      submitForm(values)
    }
  }, [values, submitForm])
  return null
}

export const Single = ({
  activeItem,
  setActiveItem,
  setOpenFormulaBuilder,
  st_sources,
  rf_sources,
  rowTree,
  setRowTree,
}) => {
  useEffect(() => {
    const newRowTree = updateRowTree({ rowTree, activeItem })
    setRowTree(newRowTree)
  }, [activeItem, setRowTree]) // Do not add rowTree dependencie otherwise we get an infinite loop

  return (
    <div className="sct-item-fields">
      <H1 css={singleTitleTypeCss}>{activeItem.isHeader ? "Header" : "Data"}</H1>
      {!activeItem.isHeader && (
        <Button
          text="Open Formula builder"
          onClick={() => setOpenFormulaBuilder(true)}
          intent="primary"
          style={{ margin: "16px 0" }}
          large
          fill
        />
      )}
      <br />

      <Formik
        enableReinitialize={true}
        initialValues={activeItem}
        onSubmit={rawValues => {
          const header = rawValues.headerItems
            ? generateHeaderForBackEnd(rawValues.headerItems, activeItem.path)
            : rawValues.header

          setActiveItem(header ? { ...rawValues, header } : rawValues)
        }}
      >
        {({ values, setValues }) => {
          return (
            <Form>
              <Grid fluid>
                <Row>
                  <Col xs>
                    <MainSettings
                      activeItem={activeItem}
                      st_sources={st_sources}
                      rf_sources={rf_sources}
                      values={values}
                      setValues={setValues}
                    />
                  </Col>
                </Row>

                {!activeItem.isHeader && (
                  <Row middle="xs" style={style}>
                    <Col xs={12} xl={6}>
                      <CellsSettings />
                    </Col>

                    <Col xs={12} xl={6}>
                      <ChartSettings />
                    </Col>
                  </Row>
                )}

                <AutoSubmitForm />
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
