// @ts-nocheck
import { Button, Classes, Dialog, HTMLSelect, Label } from "@blueprintjs/core"
import generate from "project-name-generator"
import { useState } from "react"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../state/modules/sheets"

import { InputGroup } from "../../../../components"
import { useAuth } from "../../../../hooks/"

export const AddSheet = ({ monitor, isOpen, toggleDialog }) => {
  const { profile } = useAuth()

  const [name, setName] = useState(generate({ number: true }).dashed)
  const [kind, setKind] = useState("tf")

  const sheetPayload =
    kind === "ng-tf"
      ? {
          monitor_id: monitor.id,
          kind,
          name: name,
          tf_freq: "Q",
          children: [],
        }
      : kind === "msc"
        ? {
            monitor_id: monitor.id,
            kind,
            name: name,
            colDefs: { cols: [], sort: [] },
            rowDefs: {
              forecast: {
                company: "hpr",
                owner: "default",
                label: "base",
              },
              rows: [],
              frontOpts: { groupHeaderHeight: 32, headerHeight: 27 },
            },
          }
        : kind === "tf"
          ? {
              monitor_id: monitor.id,
              kind,
              name: name,
              colDefs: { freq: "m" },
              rowDefs: [],
            }
          : {
              //kind = "cs"
              monitor_id: monitor.id,
              kind,
              name: name,
            }

  const handleText = ({ target: { value } }) => setName(value)

  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(sheetsDuck.actions.createSheet({ sheet: sheetPayload, monitor }))
    setName(generate({ number: true }).dashed)
    toggleDialog()
  }

  const handleCancel = () => {
    setName(generate({ number: true }).dashed)
    toggleDialog()
  }

  const handleChangeKind = ({ currentTarget: { value } }) => setKind(value)

  // Used during dev to block temporary ng-tf choice only for users of group "ngtf-test"
  const userGroupList = profile.groups

  const kindOpts = userGroupList.includes("ngtf-test")
    ? [
        { label: "NextGen Timeframe", value: "ng-tf" },
        { label: "Multi stock col", value: "msc" },
        { label: "Timeframe Old", value: "tf" },
      ]
    : [
        { label: "Timeframe", value: "tf" },
        { label: "Multi stock col", value: "msc" },
      ]

  return (
    <Dialog
      className="bp5-dark"
      title="Add sheet"
      icon="plus"
      isOpen={isOpen}
      onClose={handleCancel}
    >
      <div className={Classes.DIALOG_BODY}>
        Add new sheet
        <div>
          <br />
          <Label>
            Kind
            <HTMLSelect options={kindOpts} value={kind} onChange={handleChangeKind} />
          </Label>
          <Label>
            Name
            <InputGroup
              inputProps={{
                placeholder: "New name...",
                value: name,
                onChange: handleText,
                onEnter: handleSubmit,
                onEsc: handleCancel,
              }}
            />
          </Label>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" name="Cancel" onClick={handleCancel} />
          <Button intent="primary" icon="floppy-disk" name="Add new sheet" onClick={handleSubmit} />
        </div>
      </div>
    </Dialog>
  )
}
