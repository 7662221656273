import { ModelUpdatedEvent } from "ag-grid-community"
import { useCallback, useEffect, useMemo, useState } from "react"

import {
  columnsDefCellStyleMapping,
  columnsDefValueFormatterMapping,
} from "../../../components/agGridMapping"
import { useAgGrid } from "../../../components/hooks/useAgGrid"
import { IGridProps } from "../Grid"

/**
 * Custom hook to handle ag grid tree data from scient backend
 */
export const useAgGridTreeData = ({ columns, rows }: IGridProps) => {
  const { gridRef, sizeColumnsToFit, defaultColDef } = useAgGrid({ columns })

  /**
   * Handles internal state of expanded nodes
   * to handle refresh of expanded nodes when data is updated
   */
  const [expandedParents, setExpandedParents] = useState<string[]>([])

  /**
   * Memoized columns
   * "Account", "Book" are not included because they are grouped into path in order to build the treeData.
   */
  const columnsData = useMemo(() => {
    return columns
      ?.filter(col => !["Account", "Book"].includes(col.field))
      .map(column => {
        return {
          field: column.field,
          filter: column.filter,
          valueFormatter: columnsDefValueFormatterMapping[column.valueFormatter],
          cellStyle: columnsDefCellStyleMapping[column.cellStyle],
          hide: column.hide,
        }
      })
  }, [columns])

  /**
   * Handles events when a node is expanded in ag grid internal state
   * to match local component state
   */
  const onModelUpdated = useCallback(
    (event: ModelUpdatedEvent) => {
      event.api.forEachNode(node => {
        if (node.expanded) {
          setExpandedParents(expandedParents => {
            return !expandedParents.includes(node.data["Account"])
              ? [...expandedParents, node.data["Account"]]
              : expandedParents
          })
        } else {
          if (expandedParents.includes(node.data["Account"]) && node.level === 0) {
            setExpandedParents(expandedParents => {
              return [...expandedParents.filter(name => name !== node.data["Account"])]
            })
          }
        }
      })
    },
    [expandedParents],
  )

  /**
   * When rowsData is updated, we need to expand nodes that were expanded before
   */
  useEffect(() => {
    if (rows) {
      gridRef.current?.api?.forEachNode(node => {
        if (node.level === 0 && expandedParents.includes(node.data["Account"])) {
          node.expanded = true
        }
      })
      gridRef.current?.api?.onGroupExpandedOrCollapsed()
    }
  }, [expandedParents, gridRef, rows])

  return { sizeColumnsToFit, columnsData, defaultColDef, gridRef, onModelUpdated }
}
