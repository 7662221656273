import styled from "@emotion/styled"
import { noSelect } from "../../../../styled/layout.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

type RadioLabelProps = {
  gap: string
  disabled: boolean
}

export const RadioLabel = styled.label<RadioLabelProps>`
  display: flex;
  align-items: center;
  gap: ${props => props.gap};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  input {
    margin: 0;
    accent-color: ${SCIENT_COLORS.blue5};
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    outline: none;
  }
  ${noSelect}
`
