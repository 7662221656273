import styled from "@emotion/styled"
import { CardContainer, noSelect } from "../../../../../styled/layout.styled"

export const IdeaInfosContainer = styled(CardContainer)`
  border-bottom: 1.5px solid #232e37;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0px 20px 0px 20px;
  min-height: 46px;
  ${noSelect}
  .bp5-divider {
    height: 100%;
  }
`

export const Separator = styled.span({
  margin: "0",
})
