// @ts-nocheck
// function to capture each element of a formula
import { randomid } from "../../../../../../utils"

export const getFormulaItems = formula => {
  if (!formula) {
    /**
     * If there is no formula we return empty,
     * even if this must not happen because Yup blocks validation.
     */
    return []
  }
  const regex = /(\(|\)|#[\w^|{\w,-\d+}]+|@[\w^|}]+|[-\d.]+|\+|\*\*?|\/|-)/gi
  const formulaList = formula.match(regex)
  const formulaItems = formulaList.map(e => ({ item: e, itemId: randomid() })) // need to assoc each item with uniq id beacuse we can have several identical opérators in a formula
  return formulaItems
}
