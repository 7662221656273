// @ts-nocheck
import { H5, Icon, Tooltip } from "@blueprintjs/core"
import * as R from "ramda"

import { SCIENT_COLORS } from "../../../../../../styled/scientColors"

export const ErrorValueRenderer = props => {
  const header = R.propOr("", "value", props)
  const errors = R.pathOr([], ["node", "data", "extra", "errors"], props)

  const errorLevel = errors.filter(error => error.level === "ERROR")
  const warningLevel = errors.filter(error => error.level === "WARNING")

  const style = {
    color: !R.isEmpty(errorLevel)
      ? SCIENT_COLORS.red3
      : !R.isEmpty(warningLevel)
      ? SCIENT_COLORS.orange3
      : SCIENT_COLORS.blue3,
  }

  const tooltipContent = (
    <>
      {!R.isEmpty(errorLevel) && (
        <>
          <H5 style={{ color: SCIENT_COLORS, marginBottom: 0 }}>Errors</H5>
          <ul>
            {errorLevel.map((e, index) => (
              <li key={index}>
                {e.error_type}: {e.message}
              </li>
            ))}
          </ul>
        </>
      )}
      {!R.isEmpty(warningLevel) && (
        <>
          <H5 style={{ color: SCIENT_COLORS.orange3, marginBottom: 0 }}>Warning</H5>
          <ul>
            {warningLevel.map((e, index) => (
              <li key={index}>
                {e.error_type}: {e.message}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )

  return (
    <span>
      <span>{header}</span>&nbsp;
      <div style={{ display: "flex", float: "right" }}>
        {!R.isEmpty(errors) && (
          <Tooltip content={tooltipContent} placement="bottom" openOnTargetFocus={false} compact>
            <Icon icon="dot" style={style} />
          </Tooltip>
        )}
      </div>
    </span>
  )
}
