// @ts-nocheck
import { catchError, mergeMap } from "rxjs/operators"

import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import { of } from "rxjs"
import { ofType } from "redux-observable"
import profileDuck from "../index"

const {
  actions: { updateSheet, updateSheetOk, updateSheetError },
} = profileDuck

const updateSheetEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(updateSheet.type),
    mergeMap(action => {
      const id = action.payload.id
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/sheets/${id}/`,
        method: "PATCH",
        body: action.payload,
        crossDomain: true,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(({ response }) => [updateSheetOk(response)]),
        catchError(error => {
          return of(updateSheetError({ id: action.payload.id, error: error.xhr.response.detail }))
        }),
      )
    }),
  )

export default updateSheetEpic
