// @ts-nocheck
import { mergeMap, map, debounceTime, withLatestFrom } from "rxjs/operators"
import { ofType, combineEpics } from "redux-observable"
// import { isEmpty } from "ramda"

import dataSetEventsDuck from "./index"
import { REACT_APP_API_DOMAIN } from "../../../config/constants"

const {
  actions: { searchDSEvents, searchDSEventsOk },
} = dataSetEventsDuck

export const searchDSEventsEpic = (action$, state$, { ajax, auth, scheduler }, debounceMs = 800) =>
  action$.pipe(
    ofType(searchDSEvents.type),
    withLatestFrom(state$),
    debounceTime(debounceMs, scheduler),
    mergeMap(([, state]) => {
      const { search, completed, begin, stop, from, dsCategory, hide_bbg, hide_agg } =
        state.dataSetEvents
      localStorage.setItem(
        "eventSearch",
        JSON.stringify({ search, completed, begin, stop, from, dsCategory, hide_bbg, hide_agg }),
      )
      const params = [`?search=${search}&page_size=2000`]
      if (completed) {
        params.push(`completed=${completed}`)
      }
      if (begin) {
        params.push(`begin=${begin}`)
      }
      if (stop) {
        params.push(`stop=${stop}`)
      }
      if (from) {
        params.push(`from=${from}`)
      }
      if (dsCategory) {
        params.push(`ds_category=${dsCategory}`)
      }
      if (hide_bbg) {
        params.push(`hide_bbg=1`)
      }
      if (hide_agg) {
        params.push(`hide_agg=1`)
      }
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/events/dataset/${params.join("&")}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(map(({ response }) => searchDSEventsOk(response)))
    }),
  )

const dataSetEventsEpics = combineEpics(searchDSEventsEpic)

export default dataSetEventsEpics
