// @ts-nocheck
import { debounceTime, map, switchMap } from "rxjs/operators"
import { searchDataSets, searchDataSetsOk } from "../index"

import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import { ofType } from "redux-observable"

// import { isEmpty } from "ramda"

const searchDataSetsEpic = (action$, state$, { ajax, auth, scheduler }, debounceMs = 800) =>
  action$.pipe(
    ofType(searchDataSets.type),
    debounceTime(debounceMs, scheduler),
    // .filter(action => action.payload.length > 2)
    switchMap(action => {
      const { search } = action.payload

      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/search/datasets/?search=${search}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(map(({ response }) => searchDataSetsOk({ results: response.results })))
    }),
  )

export default searchDataSetsEpic
