import styled from "@emotion/styled"
import {
  FlexContainer,
  FlexContainerProps,
  FlexItemProps,
  flexItemCss,
  FlexContainerAndItem,
} from "../../../../../styled/flexbox.styled"
import { CardContainer } from "../../../../../styled/layout.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

export const IdeaDetailsContainer = styled(FlexContainer)`
  ${flexItemCss};
  overflow: hidden;
`

type IdeaContentContainerProps = {
  isCollapse: boolean
}

export const IdeaContentContainer = styled(FlexContainerAndItem)<
  IdeaContentContainerProps & FlexContainerProps & FlexItemProps
>`
  overflow: auto;
  min-height: 25%;
  height: ${props => (props.isCollapse ? "92%" : "25%")};
  padding: 15px;
  margin-bottom: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: black;
  background-color: #ffffff;
`

export const IdeaContent = styled.p({
  margin: 0,
  fontSize: "16px",
  zIndex: 1,
})

type IdeaDetailsOptionsProps = {
  withoutBorderTopRadius: boolean
}

export const IdeaDetailsOptions = styled(CardContainer)<IdeaDetailsOptionsProps>(props => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: props.withoutBorderTopRadius ? 0 : undefined,
  borderTopRightRadius: props.withoutBorderTopRadius ? 0 : undefined,
  backgroundColor: SCIENT_COLORS.gray4,
  padding: "5px",
}))
