// @ts-nocheck
import { concat, remove } from "ramda"

export const getConsensusSlugs = (year, nextPeriod, quarterAmount, yearAmount) => {
  const quarterSlugs =
    nextPeriod === "q1"
      ? [`${year}q1`, `${year}q2`, `${year}q3`, `${year}q4`]
      : nextPeriod === "q2"
      ? [`${year}q2`, `${year}q3`, `${year}q4`, `${parseInt(year) + 1}q1`]
      : nextPeriod === "q3"
      ? [`${year}q3`, `${year}q4`, `${parseInt(year) + 1}q1`, `${parseInt(year) + 1}q2`]
      : [
          `${year}q4`,
          `${parseInt(year) + 1}q1`,
          `${parseInt(year) + 1}q2`,
          `${parseInt(year) + 1}q3`,
        ]

  const cleanQuarterSlugs =
    quarterAmount === 1
      ? remove(1, 3, quarterSlugs)
      : quarterAmount === 2
      ? remove(2, 2, quarterSlugs)
      : quarterAmount === 3
      ? remove(3, 1, quarterSlugs)
      : quarterSlugs

  const yearSlugs = [
    `${year}fy`,
    `${parseInt(year) + 1}fy`,
    `${parseInt(year) + 2}fy`,
    `${parseInt(year) + 3}fy`,
    `${parseInt(year) + 4}fy`,
  ]

  const cleanYearSlugs =
    yearAmount === 1
      ? remove(2, 3, yearSlugs)
      : yearAmount === 2
      ? remove(3, 2, yearSlugs)
      : yearAmount === 3
      ? remove(4, 1, yearSlugs)
      : yearSlugs

  return concat(cleanQuarterSlugs, cleanYearSlugs)
}
