import styled from "@emotion/styled"

import { FlexContainer, FlexContainerProps } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { MediumText } from "../../../../styled/text.styled"

type WaveCardContainerProps = {
  failure?: boolean
  selected: boolean
}

export const WaveCardContainer = styled(FlexContainer)<FlexContainerProps & WaveCardContainerProps>`
  padding: 5px 10px;
  border-bottom: 1.5px solid #232e37;
  cursor: pointer;
  &:hover {
    background-color: ${props => (props.selected ? SCIENT_COLORS.blue1 : SCIENT_COLORS.darkGray3)};
  }
  background-color: ${props => (props.selected ? SCIENT_COLORS.blue3 : SCIENT_COLORS.darkGray4)};
`

export const ContentContainer = styled(FlexContainer)({
  width: "100%",
  overflowX: "hidden",
})

export const TickerListText = styled(MediumText)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
})

export const LeftContainer = styled(FlexContainer)({
  minWidth: "100px",
})

export const RightContainer = styled(FlexContainer)({
  minWidth: "60px",
})
