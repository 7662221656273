// @ts-nocheck
import { Button, ButtonGroup, Callout, Dialog } from "@blueprintjs/core"
import { Form, Formik } from "formik"
import * as R from "ramda"
import { useReactFlow } from "react-flow-renderer"

import { SCIENT_COLORS } from "../../../../../../../../../../../styled/scientColors"
import {
  dynamicYupSchema,
  getParamsValues,
  isFilledOnlyOneInList,
  validateParamLinkOneInList,
} from "../../../utils"
import Param from "./Param"
import { updateParams } from "./updateParams"

export const EditNode = ({ id, edit, setEdit, data }) => {
  const { params, parameter_links, enhancedPath } = data

  const reactFlowInstance = useReactFlow()

  const initialValues = getParamsValues(params)

  const setNodes = useReactFlow().setNodes

  const handleSubmit = (rawValues, { setSubmitting }) => {
    const newParams = updateParams(params, rawValues)

    setSubmitting(true)
    setNodes(oldNodes => {
      const nodeToUpdate = R.find(R.propEq("id", id), oldNodes)
      const updatedNode = { ...nodeToUpdate, data: { ...nodeToUpdate.data, params: newParams } }
      const newNode = R.compose(
        R.append(updatedNode),
        R.reject(e => e.id === id),
      )(oldNodes)
      return newNode
    })
    setEdit(false)
    setSubmitting(false)
  }

  // Logic for Cancel button
  // There is two kind of mandatory params :
  //    _ params with optional key false
  //    _ params with optional key true that are list in parameter_links ONE_IN_LIST
  const isEmptyNotOptionalParams = params.some(
    param => param.optional === false && R.isEmpty(param.value),
  )

  let mandatoryParamsNotAlreadyFilled

  const paramLinkOneInList =
    parameter_links &&
    !R.isEmpty(parameter_links) &&
    R.compose(
      R.head, // There's always only one ParamLinkType of type ONE_IN_LIST
      R.filter(e => e.link_type === "ParamLinkType.ONE_IN_LIST"),
    )(parameter_links)

  if (paramLinkOneInList) {
    const isOnlyOneFilled = isFilledOnlyOneInList({
      paramValues: initialValues,
      paramLinkOneInList,
    }) // if return false, we know that params has not been already filled
    mandatoryParamsNotAlreadyFilled =
      isOnlyOneFilled === false || isEmptyNotOptionalParams === true ? true : false
  } else {
    mandatoryParamsNotAlreadyFilled = isEmptyNotOptionalParams
  }

  // Cancel editNode is only for new node with mandatory parameter(s)
  // Cancel button will remove node from canvas
  const deleteNodeById = () => {
    reactFlowInstance.setNodes(nds => nds.filter(node => node.id !== id))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={dynamicYupSchema(params)}
      validate={values => validateParamLinkOneInList({ paramValues: values, paramLinkOneInList })}
      onSubmit={handleSubmit}
    >
      {({ values, errors }) => {
        return (
          <Dialog
            className="bp5-dark sct-edit-flow-modal "
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            icon="edit"
            title={`Edit node`}
            intent="primary"
            isOpen={edit}
            isCloseButtonShown={false}
            enforceFocus={false}
          >
            <Form>
              {params.map((param, index) => (
                <Param key={index} param={param} values={values} enhancedPath={enhancedPath} />
              ))}
              {errors.ONE_IN_LIST && (
                <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                  {errors.ONE_IN_LIST}
                </Callout>
              )}
              <ButtonGroup fill large>
                {mandatoryParamsNotAlreadyFilled && (
                  <Button icon="remove" text="Remove node" onClick={() => deleteNodeById()} />
                )}
                <Button type="submit" intent="primary" icon="tick" text="Validate" />
              </ButtonGroup>
            </Form>
          </Dialog>
        )
      }}
    </Formik>
  )
}
