// @ts-nocheck
export const isArray = el => Array.isArray(el)

export const isObjectArray = anything => {
  if (!isArray(anything)) {
    return false
  }
  let answer = true
  for (const el of anything) {
    if (typeof el === "string" || typeof el === "number" || typeof el === "boolean") {
      answer = false
      break
    } else {
      break
    }
  }
  return answer
}

export const isNumericArray = anything => {
  if (!isArray(anything)) {
    return false
  }
  let response = true
  for (const el of anything) {
    if (typeof el === "string" || typeof el === "object" || typeof el === "boolean") {
      response = false
      break
    } else {
      break
    }
  }
  return response
}

export const isStringArray = anything => {
  if (!isArray(anything)) {
    return false
  }
  let response = true
  for (const el of anything) {
    if (typeof el === "number" || typeof el === "object" || typeof el === "boolean") {
      response = false
      break
    } else {
      break
    }
  }
  return response
}
