import { Tag } from "@blueprintjs/core"
import { useFormikContext } from "formik"
import { useCallback, useMemo, useState } from "react"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { OmniSuggest } from "../../../components"
import { IdeaStock, IdeaType } from "../types/business"
import { IIdeaCreateForm } from "./IdeaCreate"
import { IdeaSettingsInnerContainer } from "./IdeaCreate.styled"

interface IIdeaCreateRelatedStocksSettingsProps {
  disabled: boolean
}

const IdeaCreateRelatedStocksSettings = ({ disabled }: IIdeaCreateRelatedStocksSettingsProps) => {
  const { setFieldValue, values } = useFormikContext<IIdeaCreateForm>()

  /**
   * State of the suggest, we use it in controlled mode to explicitly
   * reset after tag creation.
   */
  const [selectedStock, setSelectedStock] = useState<Partial<IdeaStock> | null>()

  /**
   * Adds a stock to the list if it doesn't already exist.
   */
  const handleStockSelect = useCallback(
    (stock: Partial<IdeaStock>) => {
      const stockExists = values.related_stocks?.some(
        existingStock => existingStock.id === stock.id,
      )
      if (!stockExists && values.related_stocks) {
        const updatedStocks = [...values.related_stocks, stock]
        setFieldValue("related_stocks", updatedStocks)
      }
      // Then we always reset the suggest after selection
      setSelectedStock(null)
    },
    [values.related_stocks, setFieldValue],
  )

  /**
   * Removes a stock from the list based on its ID.
   */
  const handleTagRemove = useCallback(
    (stockId?: number) => {
      const updatedStocks = values.related_stocks?.filter(stock => stock.id !== stockId)
      setFieldValue("related_stocks", updatedStocks)
    },
    [values.related_stocks, setFieldValue],
  )

  const shouldDisplaySuggest = useMemo(() => {
    let shouldDisplaySuggest = false
    if (values.related_stocks) {
      shouldDisplaySuggest =
        (values.idea_type === IdeaType.STRUCTURAL && values.related_stocks.length < 1) ||
        values.idea_type === IdeaType.NOTE
    }
    return shouldDisplaySuggest
  }, [values.idea_type, values.related_stocks])

  return (
    <IdeaSettingsInnerContainer flexDirection="column">
      {shouldDisplaySuggest && (
        <OmniSuggest
          onSubmit={(stock: { bloomberg_code: string; id: number }) => {
            handleStockSelect(stock)
          }}
          placeholder={"Link a Stock" + (values.idea_type === IdeaType.NOTE ? " (Optional)" : "")}
          exclude={{ ds: true, dsf: true, rf: true }}
          initialSelectedItem={undefined}
          selectedItem={selectedStock}
          inputValueRenderer={item => item.bloomberg_code}
        />
      )}
      <FlexContainer flexWrap="wrap" flexDirection="row" gap="10px">
        {values.related_stocks?.map(stock => (
          <Tag key={stock.id} onRemove={disabled ? undefined : () => handleTagRemove(stock.id)}>
            {stock.bloomberg_code}
          </Tag>
        ))}
      </FlexContainer>
    </IdeaSettingsInnerContainer>
  )
}

export default IdeaCreateRelatedStocksSettings
