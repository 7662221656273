// @ts-nocheck
import * as R from "ramda"

export const cleanSourceForBackend = source => {
  if (R.has("source", source)) {
    return R.compose(
      R.dissoc("source"),
      R.assoc("path", JSON.parse(source.path.replace(/'/g, '"'))),
    )(source)
  }
  return source
}
