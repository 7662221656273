// @ts-nocheck
import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ButtonGroup, Button, Icon, Classes, Spinner } from "@blueprintjs/core"

import { ButtonWithAlert, ButtonWithDialog, SharingButton } from "../../../../components"
import { updateAnalyse, deleteAnalyse } from "../../../../../state/modules/analysis"

const Row = ({ analyse, allFolders }) => {
  const dispatch = useDispatch()
  const { id, name, owner } = analyse
  const navigate = useNavigate()

  const deleteAction = () => {
    dispatch(deleteAnalyse({ id }))
  }

  const moveToFolder = (folder_id, setDialog) => {
    dispatch(updateAnalyse({ analyse: { id: analyse.id, folder_id } }))
    return setDialog(false)
  }

  const handleUpdateGroups = groups => {
    dispatch(updateAnalyse({ analyse: { id: analyse.id, groups } }))
  }

  return (
    <tr>
      <td>
        {analyse.fetching ? (
          <Spinner />
        ) : (
          <>
            <span>
              <strong>{name}</strong> [{analyse.method}] <Icon icon="user" /> {owner}
            </span>
            <ButtonGroup style={{ float: "right" }} size="tiny">
              <Button
                intent="primary"
                icon="eye-open"
                onClick={() => navigate(`/analysis/${id}/`)}
              />
              {updateAnalyse && (
                <ButtonWithDialog
                  buttonProps={{
                    icon: "add-to-folder",
                  }}
                  dialogProps={{
                    title: "Move to folder",
                  }}
                >
                  {({ setDialog }) => (
                    <div className={Classes.DIALOG_BODY}>
                      {allFolders?.map(({ id, name }) => (
                        <Button key={id} text={name} onClick={() => moveToFolder(id, setDialog)} />
                      ))}
                    </div>
                  )}
                </ButtonWithDialog>
              )}
              {updateAnalyse && <SharingButton instance={analyse} onSubmit={handleUpdateGroups} />}
              {deleteAnalyse && (
                <ButtonWithAlert
                  buttonProps={{
                    intent: "danger",
                    icon: "trash",
                  }}
                  alertProps={{
                    confirmButtonText: `Yes, delete ${name}`,
                    onConfirm: deleteAction,
                  }}
                >
                  Do you want to delete this analyse?
                </ButtonWithAlert>
              )}
            </ButtonGroup>
          </>
        )}
      </td>
    </tr>
  )
}

export default Row
