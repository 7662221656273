import { Button, Tooltip } from "@blueprintjs/core"
import { IconName, IconNames } from "@blueprintjs/icons"

import { IdeaOrdering, IdeaSorting } from "../../../../../api/ideas"
import { SortLogoAsc, SortLogoDesc } from "../../../../../icons/SortLogo"
import { FlexContainer, FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { useIdeasCache } from "../../../../hooks"
import { OrderSortOptionsContainer } from "./InboxOrderSortOptions.styled"

/**
 * List all the options available oderByOptions.
 */
const ORDER_BY_OPTIONS: { name: IdeaOrdering; icon: IconName; tooltipContent: string }[] = [
  {
    name: IdeaOrdering.DEFAULT,
    icon: IconNames.COMMENT,
    tooltipContent: "Order ideas by latest update on conversation or Idea",
  },
  {
    name: IdeaOrdering.CREATION_DATE,
    icon: IconNames.TIME,
    tooltipContent: "Order ideas by date of creation",
  },
  {
    name: IdeaOrdering.TRADE_OPEN_DATE,
    icon: IconNames.SERIES_ADD,
    tooltipContent: "Order ideas by open trade date",
  },
  {
    name: IdeaOrdering.TRADE_CLOSE_DATE,
    icon: IconNames.SERIES_DERIVED,
    tooltipContent: "Order ideas by close trade date",
  },
  {
    name: IdeaOrdering.TRADE_PERFORMANCE,
    icon: "dashboard",
    tooltipContent: "Order ideas by trade performance",
  },
]

/**
 * List the two possible sorting directions.
 */
const SORT_OPTIONS = [
  {
    name: IdeaSorting.DESC,
    icon: <SortLogoDesc height={"15px"} width={"15px"} />,
    tooltipContent: "Newest on top",
  },
  {
    name: IdeaSorting.ASC,
    icon: <SortLogoAsc height={"15px"} width={"15px"} />,
    tooltipContent: "Oldest on top",
  },
]

const InboxOrderSortOptions = () => {
  const { orderBy, setOrderBy, sortDirection, setSortDirection } = useIdeasCache()
  return (
    <OrderSortOptionsContainer gap="10px" flexDirection="row" flexWrap="wrap">
      <FlexContainerAndItem gap="10px" flexGrow={1}>
        {ORDER_BY_OPTIONS.map(option => {
          return (
            <Tooltip key={option.name} content={option.tooltipContent} compact placement="bottom">
              <Button
                icon={option.icon}
                active={orderBy === option.name}
                onClick={() => setOrderBy && setOrderBy(option.name)}
              />
            </Tooltip>
          )
        })}
      </FlexContainerAndItem>
      <FlexContainer gap="10px" justifyContent="space-between">
        {SORT_OPTIONS.map(option => {
          return (
            <Tooltip key={option.name} content={option.tooltipContent} compact placement="bottom">
              <Button
                icon={option.icon}
                active={sortDirection === option.name}
                onClick={() => setSortDirection && setSortDirection(option.name)}
              />
            </Tooltip>
          )
        })}
      </FlexContainer>
    </OrderSortOptionsContainer>
  )
}

export default InboxOrderSortOptions
