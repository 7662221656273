// @ts-nocheck
import * as R from "ramda"
import { getDeepNestedValue } from "../."

const convertTitle = (header, obj) => {
  if (header.includes("@{defaultProfitMetric}")) {
    return header.replace(/@\{(.+)\}/g, () => obj["default_profit_metric"])
  }
  if (header === "PPA Amortization" && obj["ppa_in_segments"]) {
    return `ow ${header}`
  }
  if (header === "Restructuring costs" && obj["restructuring_in_segments"]) {
    return `ow ${header}`
  }
  if (header === "Exceptionals" && obj["exceptionals_in_segments"]) {
    return `ow ${header}`
  }
  return header.replace(/#\{(.+)\}/g, (match, string) => obj[string])
}

const checkEmpty = (path, entries) => {
  return (
    entries.filter(entry => {
      const value = getDeepNestedValue(path, entry)
      return value === null
    }).length === entries.length
  )
}

const convertPath = (path, obj) => {
  return path.map(step => step.replace(/#\{(.+)\}/g, (match, string) => obj[string]))
}

const createRow = ({ row, obj, basePath = [], options }) => {
  if (row.type === "forEach") {
    const { path, rowDefs } = row
    const nodes = getDeepNestedValue(path, obj)
    return nodes
      ? nodes
          .filter(node =>
            row.filter && !options.showInactive
              ? getDeepNestedValue(row.filter.path, node) === row.filter.value
              : true,
          )
          .map((node, index) => {
            return rowDefs.map(rowDef =>
              createRow({
                row: rowDef,
                obj: node,
                basePath: path,
                options,
              }),
            )
          })
      : []
  }
  const out = {
    styles: {},
    ...row,
    path: row.type === "data" ? [...basePath, ...convertPath(row.path, obj)] : null,
    text: convertTitle(row.text, obj),
  }

  return out
}

export const createStockRows = ({
  rowDefs,
  entries,
  stock,
  options = { showInactive: false, showEmpty: false },
}) => {
  const allRows = R.flatten(rowDefs.map(row => createRow({ row, obj: stock, options })))
  return allRows.filter(row =>
    !options.showEmpty && row.path ? checkEmpty(row.path, entries) === false : true,
  )
}
