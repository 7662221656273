import styled from "@emotion/styled"
import { FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { ShadowContainer } from "../../../../../styled/layout.styled"

export const InboxContainer = styled(ShadowContainer)({ overflow: "auto" })

export const SpinnerContainer = styled(FlexContainerAndItem)({
  overflow: "auto",
  padding: "10px 0 10px 0",
})
