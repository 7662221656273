// @ts-nocheck
import * as R from "ramda"

import { useSelector } from "react-redux"

// sharedFields are custom Fields shared by at least x stocks.
// x is a number defined in back-end at "shared/views/fields.py" in "id__count__gte"
export const useSharedFields = () => {
  return useSelector(R.pathOr([], ["shared", "fieldList", "field_fields"]))
}
