import styled from "@emotion/styled"
import { FlexContainer, FlexContainerAndItem } from "../../../styled/flexbox.styled"

export const IdeasContainer = styled(FlexContainer)({
  height: "100%",
  width: "100%",

  /**
   * @todo Remove menu grid layout for flex
   */
  gridArea: "content",
  padding: "20px 15px 20px 15px",
  overflow: "auto",
})

export const LeftContainer = styled(FlexContainer)({
  width: "20%",
  paddingRight: "5px",
})

export const RightContainer = styled(FlexContainerAndItem)({
  paddingLeft: "5px",
  width: "80%",
})

export const NoIdeasPlaceholder = styled(FlexContainer)({
  height: "100%",
  width: "100%",
})

export const NoIdeasTitle = styled.p({
  margin: 0,
})
