// @ts-nocheck
import { assoc, compose, dissoc, dissocPath, lensPath, lensProp, over, set } from "ramda"

/* DESKS */
const desksLens = lensProp("desks")
const deskLens = id => lensPath(["desks", "data", id])
const deskReadyLens = id => lensPath(["desks", "data", id, "ready"])

export const fetchDesks = over(desksLens, compose(assoc("ready", false), dissoc("error")))
export const fetchDesksOk = (state, { data }) =>
  over(desksLens, compose(assoc("ready", true), assoc("data", data)), state)
export const fetchDesksError = (state, { error }) =>
  over(desksLens, compose(dissoc("data"), assoc("ready", true), assoc("error", error)), state)

export const fetchAll = state => state

// export const upsertDesk = (state, payload) =>
//   set(deskLens(payload.id), { ready: true, ...payload }, state)

export const updateDesk = (state, { id }) => {
  return over(deskLens(id), compose(assoc("ready", false), dissoc("error")), state)
}
export const updateDeskOk = (state, payload) => {
  return set(deskLens(payload.id), { ready: true, ...payload }, state)
}
export const updateDeskError = (state, payload) =>
  over(deskLens(payload.id), compose(assoc("ready", true), assoc("error", payload.error)), state)

export const createDesk = over(desksLens, compose(assoc("ready", false), dissoc("error")))
export const createDeskOk = (state, payload) =>
  compose(set(deskLens(payload.id), payload), over(desksLens, assoc("ready", true)))(state)

export const createDeskError = (state, error) =>
  over(desksLens, compose(assoc("ready", true), assoc("error", error)), state)

export const deleteDesk = (state, { id }) => set(deskReadyLens(id), false, state)
export const deleteDeskOk = (state, { id }) => dissocPath(["desks", "data", id], state)

/* USERDESKS */
const userDesksLens = lensProp("userDesks")

export const fetchUserDesks = over(userDesksLens, compose(assoc("ready", false), dissoc("error")))
export const fetchUserDesksOk = (state, { data }) =>
  over(userDesksLens, compose(assoc("ready", true), assoc("data", data)), state)
export const fetchUserDesksError = (state, { error }) =>
  over(userDesksLens, compose(dissoc("data"), assoc("ready", true), assoc("error", error)), state)

export const upsertUserDesks = over(userDesksLens, compose(assoc("ready", false), dissoc("error")))
export const upsertUserDesksOk = (state, payload) =>
  over(userDesksLens, compose(assoc("ready", true), assoc("data", payload)), state)
export const upsertUserDesksError = (state, error) =>
  over(userDesksLens, compose(assoc("ready", true), assoc("error", error)), state)

export const setUserDesks = (state, { userDesks }) =>
  compose(
    set(lensPath(["userDesks", "data"]), userDesks),
    over(userDesksLens, dissoc("error")),
  )(state)
export const setUserDesksOk = (state, { payload }) =>
  over(userDesksLens, assoc("data", payload), state)
export const setUserDesksError = (state, error) => over(userDesksLens, assoc("error", error), state)

export const clearDeskError = (state, { id }) => {
  return over(deskLens(id), dissoc("error"), state)
}
