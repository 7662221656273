// @ts-nocheck
export const mainTabs = [
  { id: "dates", title: "Dates" },
  { id: "shared;sales", title: "Sales" },
  { id: "shared;orders", title: "Orders" },
  { id: "backlog", title: "Backlog" },
  { id: "profit", title: "Profit" },
  { id: "customFields", title: "Custom Fields" },
  { id: "currencySplit", title: "Currencies" },
  { id: "geoSplit", title: "Geos" },
  { id: "is", title: "IS" },
  { id: "bs", title: "BS" },
  { id: "cf", title: "CF" },
]
