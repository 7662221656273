// @ts-nocheck
export const salesGrowth = {
  id: "salesGrowth",
  title: "Sales Growth",
  rowDefs: [
    {
      type: "title1",
      text: "Sales",
      styles: { focus: "1" },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "sales"],
          type: "data",
          text: "#{name}",
        },
      ],
    },
    {
      path: ["sales"],
      type: "data",
      text: "Group sales",
      styles: { focus: "1" },
    },
    {
      path: ["sales_growth_readonly"],
      type: "data",
      text: "Group Sales Growth",
      pct: true,
      styles: { focus: "1", italic: true },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "sales_growth_readonly"],
          type: "data",
          text: "#{name}",
          pct: true,
          styles: { italic: true },
        },
      ],
    },
    {
      type: "title1",
      text: "Sales growth Components", //(field name to take from stock object)
      styles: { focus: "1" },
    },

    {
      path: ["sales_growth_readonly"],
      type: "data",
      text: "Group",
      pct: true,
      styles: { focus: "2" },
    },
    {
      path: ["sales_price_growth"],
      type: "data",
      text: "Price",
      pct: true,
      styles: { italic: true },
    },
    {
      path: ["sales_volume_growth"],
      type: "data",
      text: "Volume",
      pct: true,
      styles: { italic: true },
    },
    {
      path: ["sales_other_organic_growth"],
      type: "data",
      text: "Other Organic",
      pct: true,
      styles: { italic: true },
    },
    {
      path: ["sales_organic_growth"],
      type: "data",
      text: "Organic total",
      pct: true,
      styles: { italic: true, focus: "4" },
    },
    {
      path: ["sales_acquisition_growth"],
      type: "data",
      text: "Acq",
      pct: true,
      styles: { italic: true },
    },
    {
      path: ["sales_fx_growth"],
      type: "data",
      text: "FX",
      pct: true,
      styles: { italic: true },
    },
    {
      path: ["sales_scope_growth"],
      type: "data",
      text: "Scope total",
      pct: true,
      styles: { italic: true, focus: "4" },
    },

    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "sales_growth_readonly"],
          type: "data",
          text: "#{name}",
          styles: { focus: "2" },
          pct: true,
        },

        {
          path: ["slug:#{slug}", "sales_price_growth"],
          type: "data",
          text: "Price",
          pct: true,
          styles: { italic: true },
        },
        {
          path: ["slug:#{slug}", "sales_volume_growth"],
          type: "data",
          text: "Volume",
          pct: true,
          styles: { italic: true },
        },
        {
          path: ["slug:#{slug}", "sales_other_organic_growth"],
          type: "data",
          text: "Other Organic",
          pct: true,
          styles: { italic: true },
        },
        {
          path: ["slug:#{slug}", "sales_organic_growth"],
          type: "data",
          text: "Organic total",
          pct: true,
          styles: { italic: true, focus: "4" },
        },

        {
          path: ["slug:#{slug}", "sales_aquisition_growth"],
          type: "data",
          text: "Acq",
          pct: true,
          styles: { italic: true },
        },
        {
          path: ["slug:#{slug}", "sales_fx_growth"],
          type: "data",
          text: "FX",
          pct: true,
          styles: { italic: true },
        },
        {
          path: ["slug:#{slug}", "sales_scope_growth"],
          type: "data",
          text: "Scope total",
          styles: { italic: true, focus: "4" },
          pct: true,
        },
      ],
    },

    {
      type: "title1",
      text: "@{defaultProfitMetric}", //(field name to take from stock object)
      styles: { focus: "1" },
    },

    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "profit"],
          type: "data",
          text: "#{name}",
        },
      ],
    },
    {
      path: ["is_ppa_amortization"],
      type: "data",
      text: "PPA Amortization",
      styles: { frontColor: "orange" },
    },
    {
      path: ["is_restructuring_costs"],
      type: "data",
      text: "Restructuring costs",
      styles: { frontColor: "orange" },
    },
    {
      path: ["is_walkdown_customsegment_to_reported_ebit"],
      type: "data",
      text: "Other Adj. to reported EBIT (pre exceptionals)",
      styles: { frontColor: "orange" },
    },
    {
      path: ["is_exceptionals"],
      type: "data",
      text: "Exceptionals",
      styles: { frontColor: "orange" },
    },
    {
      path: ["profit"],
      type: "data",
      text: "Group @{defaultProfitMetric}",
      styles: { focus: "1" },
    },
    {
      path: ["profit_margin_readonly"],
      type: "data",
      text: "Group Margin",
      pct: true,
      styles: { italic: true, focus: "3" },
    },
    {
      path: ["splits", "slug:main", "segs"],
      type: "forEach",
      filter: {
        path: ["active", "upper"],
        value: null,
      },
      rowDefs: [
        {
          path: ["slug:#{slug}", "profit_margin_readonly"],
          type: "data",
          text: "#{name}",
          pct: true,
          styles: { italic: true },
        },
      ],
    },
  ],
}
