// @ts-nocheck
import React from "react"
import { connect } from "react-redux"
import { compose } from "recompose"
import { pick, prop } from "ramda"
import { H3 } from "@blueprintjs/core"

import dataSetsDuck from "../../../../../state/modules/dataSets"
import Header from "../Header"
import Form from "./Form"

const Detail = props => {
  return (
    <>
      <Header dataSet={props.dataSet} />
      {props.dataSet.aggregated ? (
        <H3 style={{ textAlign: "center" }}>You cannot edit an aggregated dataset.</H3>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Form {...props} />
        </div>
      )}
    </>
  )
}

export default compose(
  connect(compose(pick(["dataSet", "fetchingDetail"]), prop("dataSets")), dataSetsDuck.actions),
)(Detail)
