import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts/highstock"

import noDataToDisplay from "highcharts/modules/no-data-to-display"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { defaultOptions } from "../../../_shared/highcharts"

interface IHighchartSeries {
  series: Array<object>
  format: string
  name: string
  chartType: string
  xLabel: string
  yLabel: string
}

export const prepareChartSeries = (
  label: string,
  data: Array<Array<number>>,
  color: string,
  legendSymbol: string,
  legendIndex: number,
) => {
  return {
    name: label,
    pct: false,
    data: data,
    dashStyle: "solid",
    color: color,
    legendSymbol: legendSymbol,
    legendIndex: legendIndex,
    showInLegend: true,
    tooltip: {
      headerFormat: "{point.x:%d-%m-%Y}",
      pointFormat: "{series.name}: {point.y}",
    },
  }
}

const NocChartContent = ({ series, format, name, chartType, xLabel, yLabel }: IHighchartSeries) => {
  noDataToDisplay(Highcharts)
  const options = {
    rangeSelector: {
      enabled: false,
    },
    chart: {
      type: chartType,
      width: 900,
      height: 450,
      borderRadius: 20,
      borderWidth: 0.5,
      borderColor: SCIENT_COLORS.lightGray3,
      zoomType: "Xy",
      zooming: {
        resetButton: {
          position: {
            align: "right",
            verticalAlign: "top",
          },
        },
      },
      resetZoomButton: {
        theme: {
          fill: "white",
          stroke: "silver",
          style: { fontSize: "12px", fontFamily: "sans-serif" },
          r: 0,
          states: {
            hover: {
              fill: "#41739D",
              style: {
                color: "white",
              },
            },
          },
        },
      },
    },
    title: {
      text: name,
      style: {
        color: "#8ABBFF",
        fontSize: "20px",
        fontFamily: "sans-serif",
      },
    },
    navigator: {
      enabled: true,
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      ...defaultOptions.xAxis,
      scrollbar: {
        enabled: true,
      },
      title: {
        text: xLabel,
        style: {
          color: "#8ABBFF",
          fontSize: "18px",
          fontFamily: "sans-serif",
        },
      },
    },
    yAxis: {
      opposite: false,
      labels: {
        format,
      },
      title: {
        text: yLabel,
        style: {
          color: "#8ABBFF",
          fontSize: "18px",
          fontFamily: "sans-serif",
        },
      },
    },
    series,
    legend: { enabled: true, align: "right", verticalAlign: "top" },
    lang: {
      noData: "No data to show for this period & metric",
    },
    noData: {
      style: {
        fontStyle: "italic",
        fontSize: "18px",
        color: SCIENT_COLORS.lightGray3,
        fontFamily: "sans-serif",
      },
    },
  }
  return (
    <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={options} />
  )
}

export default NocChartContent
