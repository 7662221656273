/** @jsxImportSource @emotion/react */
import { Validate } from "react-hook-form"

import { FlexContainerFullHeight } from "./Components.styled"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"
import { IBaseInputProps } from "../types/common"
import { WaveInputs } from "../types/form"

export interface IFloatInputProps<TFieldValues> extends IBaseInputProps {
  min?: {
    value: number
    message: string
  }
  max?: {
    value: number
    message: string
  }
  minOfInput?: number
  decimalPrecision?: number
  validate?: Validate<number, TFieldValues> | Record<string, Validate<number, TFieldValues>>
}

/**
 * Component to use with React Hook Form for float input
 */

export const FloatInput = ({
  name,
  register,
  min,
  max,
  minOfInput,
  decimalPrecision = 2,
  customCss,
  error,
  validate,
}: IFloatInputProps<WaveInputs>) => {
  return (
    <FlexContainerFullHeight alignItems="center" justifyContent="center" alignContent="center">
      <input
        type="number"
        {...register(name, {
          min: min,
          max: max,
          validate: validate,
          /**
           * Set value in the form state with numeric value or null if value is NaN
           */
          setValueAs: (value: string) => {
            const parsedValue = parseFloat(value)
            return isNaN(parsedValue) ? null : parsedValue
          },
        })}
        /**
         * Not allow some character used usually in numeric input
         */
        onKeyDown={event => {
          const forbiddenCharacters = ["e", "E", "+", "-"]
          if (forbiddenCharacters.includes(event.key)) event.preventDefault()
        }}
        step={1 / Math.pow(10, decimalPrecision)}
        min={minOfInput}
        css={customCss}
      />
      {error && <ErrorIconWithTooltip errorMessage={error.message} />}
    </FlexContainerFullHeight>
  )
}
