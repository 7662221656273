import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../../hooks/useApi"

interface ITradingSummaryHook {
  liveOrders: boolean
}

const useTradingSummaryQuery = ({ liveOrders }: ITradingSummaryHook) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["tradingsummaryscient", liveOrders],
    () => scientApi.trading.getTradingSummary({ liveOrders }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )

  return {
    summary: data,
    isLoading,
    error,
  }
}

export default useTradingSummaryQuery
