// @ts-nocheck
import React, { useState } from "react"
import { Button, ButtonGroup, InputGroup } from "@blueprintjs/core"
import * as R from "ramda"

import { randomid } from "../../../../../../../../../utils"

const operators = ["+", "-", "*", "/", "**", "(", ")"]

export const FormulaButtons = ({
  values,
  setValues,
  card,
  cols,
  formulaItems,
  toggleShowPanels,
}) => {
  // Logic for input
  const regexp = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/g // to control input: accept only number

  const [input, setInput] = useState("")
  const inputIsNumber = regexp.test(input)

  const onChange = e => {
    const { value } = e.target
    setInput(value)
  }
  const addSymbol = value =>
    setValues({
      colDefs: {
        ...values.colDefs,
        cols: R.map(
          col =>
            col.colId === card.colId
              ? { ...col, formulaItems: [...formulaItems, { item: value, itemId: randomid() }] }
              : col,
          cols,
        ),
      },
    })
  return (
    <ButtonGroup>
      <Button text="Add item" onClick={() => toggleShowPanels("new")} />
      {operators.map(op => (
        <Button key={`button${op}`} text={op} onClick={() => addSymbol(op)} />
      ))}
      <InputGroup
        type="numeric"
        value={input}
        placeholder="Enter number"
        onChange={onChange}
        rightElement={
          <>
            <Button
              onClick={() => addSymbol(input) && setInput("")}
              text={inputIsNumber === true || !input ? "Add number" : "Not a number"}
              intent={inputIsNumber === true ? "primary" : "none"}
              disabled={inputIsNumber === false}
            />
          </>
        }
      />
    </ButtonGroup>
  )
}
