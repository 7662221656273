// @ts-nocheck
import { compose, equals, identity, intersection, length, lt, partition, prop } from "ramda"

export const splitMineOthers = userId => {
  return partition(compose(equals(userId), prop("owner_id")))
}

const sharedWithMe = profile =>
  profile
    ? compose(lt(0), length, intersection(profile && profile.groups), prop("read_groups"))
    : identity(false)

export const splitSharedAdmin = profile => partition(sharedWithMe(profile))
