// @ts-nocheck
import React from "react"
import * as R from "ramda"
import { Button, Classes, Dialog, HTMLSelect, Label } from "@blueprintjs/core"

export const ColKindDialog = ({ values, setValues, showColKind, setShowColKind, initialCol }) => {
  const kinds = [
    { label: "Separation Column", value: "separationCol" },
    { label: "Stock field", value: "stockField" },
    { label: "Stock param", value: "stockParam" },
    { label: "Factor", value: "factor" },
    { label: "Formula", value: "formula" },
  ]
  const handleSelectKind = ({ target: { value } }) => setShowColKind(value)

  const handleAddItem = () => {
    const newItem = initialCol(showColKind)
    const newValues = {
      colDefs: {
        ...values.colDefs,
        cols: [...R.map(R.dissoc("active"), values.colDefs.cols), newItem],
      },
    }
    return setValues(newValues) && setShowColKind(false)
  }

  return (
    <Dialog
      className="bp5-dark"
      title="Add item"
      icon="plus"
      isOpen={showColKind}
      onClose={() => setShowColKind(false)}
    >
      <div className={Classes.DIALOG_BODY}>
        <div>
          <br />
          <Label>
            Kind
            <HTMLSelect options={kinds} value={showColKind} onChange={handleSelectKind} />
          </Label>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {/* <Button icon="cross" name="Cancel" onClick={toggleShowItemType} /> */}
          <Button intent="primary" icon="plus" name="Add" text="Add" onClick={handleAddItem} />
        </div>
      </div>
    </Dialog>
  )
}
