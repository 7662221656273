// @ts-nocheck
export const initialFormulaTreeData = {
  nodes: [
    {
      id: "rowdef",
      type: "rowdef",
      position: { x: 300, y: 0 },
      handleBounds: {
        target: [
          {
            id: "|0|TIMESERIES",
          },
        ],
      },
      data: {
        inputs: [{ id: "input", type: "TIMESERIES" }],
      },
    },
  ],
  edges: [],
  viewport: { x: 56, y: 335, zoom: 2 },
}
