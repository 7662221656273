// @ts-nocheck
import { curry, isEmpty, isNil, last, propEq } from "ramda"

export * from "./classRules"
export * from "./getContextMenuItems"
export * from "./getFormattedValue"

const onlyFY = propEq("period", "FY")

const filterMix = curry(
  (yearsToDevelop, entry) => yearsToDevelop.includes(entry.year) || entry.period === "FY",
)

export const mergeAndFilterByFreq = curry((stock, freq, entries) => {
  const { reports, rFEntries } = entries

  // return empty array if both no report and no forecast
  if ((isEmpty(reports) || isNil(reports)) && (isEmpty(rFEntries) || isNil(rFEntries))) return []

  // Display estimates data even if they're no report data
  // We keep estimate data since last_bbg_report year and period included
  if (isEmpty(reports) || isNil(reports)) {
    const { last_bbg_report_period, last_bbg_report_year } = stock // use to filter RFEntries

    const getPeriodNumber = period => {
      switch (period) {
        case "Q1":
          return 1
        case "Q2":
          return 2
        case "H1":
          return 3
        case "Q3":
          return 4
        case "Q4":
          return 5
        case "H2":
          return 6
        case "FY":
          return 7
        default:
          throw new Error("wrong period")
      }
    }

    const selectedRFEntries = rFEntries.filter(
      rfe =>
        (rfe.year === last_bbg_report_year &&
          rfe.period_number >= getPeriodNumber(last_bbg_report_period)) ||
        rfe.year > last_bbg_report_year,
    )

    const output = selectedRFEntries

    const yearsToDevelop = ["Q3", "Q4", "H2", "FY"].includes(last_bbg_report_period)
      ? [last_bbg_report_year, last_bbg_report_year + 1]
      : [last_bbg_report_year]

    switch (freq) {
      case "all":
        return output
      case "fy":
        return output.filter(onlyFY)
      case "mix":
        return output.filter(filterMix(yearsToDevelop))
      default:
        throw new Error("wrong freq")
    }
  }

  const lastReport = last(reports)

  const selectedRFEntries = rFEntries.filter(
    rfe =>
      (rfe.year === lastReport.year && rfe.period_number > lastReport.period_number) ||
      rfe.year > lastReport.year,
  )

  const output = [...reports, ...selectedRFEntries]

  const yearsToDevelop = ["Q3", "Q4", "H2", "FY"].includes(lastReport.period)
    ? lastReport.period === "FY"
      ? [lastReport.year, lastReport.year + 1]
      : [lastReport.year - 1, lastReport.year, lastReport.year + 1]
    : [lastReport.year - 1, lastReport.year]

  switch (freq) {
    case "all":
      return output
    case "fy":
      return output.filter(onlyFY)
    case "mix":
      return output.filter(filterMix(yearsToDevelop))
    default:
      throw new Error("wrong freq")
  }
})
