// @ts-nocheck
import { of } from "rxjs"
import { switchMap, mergeMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import { reject } from "ramda"

import sheetsDuck from "../."
import monitorsDuck from "../../monitors/index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { deleteSheet, deleteSheetOk, deleteSheetError },
} = sheetsDuck

const deleteSheetEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(deleteSheet.type),
    switchMap(action => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/desks/sheets/${action.payload.id}`
      return ajax({
        url,
        method: "DELETE",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(() => {
          const { id, monitor } = action.payload

          const activeSheet =
            monitor.active_sheet_id === (parseInt(id) || null) ? null : monitor.active_sheet_id
          const newSheetList = reject(e => e === parseInt(id), monitor.sheet_ids)

          return [
            monitorsDuck.actions.setMonitorSheets({
              id: monitor.id,
              sheet_ids: newSheetList,
            }),
            monitorsDuck.actions.setActiveSheet({
              id: monitor.id,
              active_sheet_id: activeSheet,
            }),
            monitorsDuck.actions.fetchMonitorList(),
            deleteSheetOk(id),
          ]
        }),
        catchError(error => of(deleteSheetError(error.xhr.response))),
      )
    }),
  )

export default deleteSheetEpic
