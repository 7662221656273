// @ts-nocheck
import { AgGridReact } from "ag-grid-react"
import { path, prop } from "ramda"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import profileDuck from "../../../../../../state/modules/profile"
import { createStockRows, randomid } from "../../../../../../utils"
import { ValueOrActionRenderer } from "../../../../../_shared/agFormatters"
import { AddDialog, CannotDragAlert, DeleteAlert, EditDialog } from "../components"
import { TableContainer } from "./Table.styled"
import {
  cellClassRules,
  getContextMenuItems,
  getFormattedValue,
  getHeaderClasses,
  rowClassRules,
} from "./utils"

const Table = ({ tabDef, entries, stock, options, showDelete, stockSlug, targetCol }) => {
  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [cannotDrag, setCannotDrag] = useState(false)

  const wentToLastACol = useSelector(
    path(["profile", "userstockconfigs", stockSlug, "wentToLastCol"]),
  )
  const dispatch = useDispatch()
  const handleSetWentToLastCol = wentToLastCol =>
    dispatch(profileDuck.actions.stockSetWentToLastCol({ slug: stockSlug, wentToLastCol }))

  const handleDelete = ({ rowIndex }) => {
    dispatch(
      profileDuck.actions.deleteLine({
        slug: stockSlug,
        tabId: tabDef.id,
        index: rowIndex,
      }),
    )
    setDeleteAlert(false)
  }

  const showEdit = useSelector(path(["profile", "userstockconfigs", stockSlug, "showEdit"]))
  const showEmpty = useSelector(path(["profile", "userstockconfigs", stockSlug, "showEmpty"]))

  const { id, rowDefs, isCustom } = tabDef
  const colTitles = entries.map(entry => ({
    field: `${entry.year}${entry.period}`,
    headerClass: getHeaderClasses(entry),
    cellRenderer: ValueOrActionRenderer,
    cellRendererParams: {
      handleDelete: () => showDelete({ id: entry.id, slug: entry.slug }),
      isRForecast: entry.forecast,
      stockSlug: stockSlug,
    },
    cellClassRules: cellClassRules(entry),
  }))

  const handleRowDragEnter = () => {
    if (!showEmpty) {
      return setCannotDrag(true)
    }
  }
  const handleRowDragEnd = ({ node, overNode }) => {
    const sourceIndex = prop("rowIndex", node)
    const targetIndex = prop("rowIndex", overNode)
    return (
      targetIndex !== false &&
      showEmpty &&
      dispatch(
        profileDuck.actions.moveLine({
          slug: stockSlug,
          tabId: id,
          sourceIndex,
          targetIndex,
        }),
      )
    )
  }

  const columnDefs = [
    {
      headerName: "",
      field: "desc",
      pinned: "left",
      width: 320,
      rowDrag: path(["context", "isCustom"]),
    },
    ...colTitles,
  ]

  const rowHeaders = showEdit
    ? [
        { type: "actions", text: "Actions" },
        ...createStockRows({ rowDefs, entries, stock, options }),
      ]
    : createStockRows({ rowDefs, entries, stock, options })

  const rowData = rowHeaders.map(header => {
    const col = entries.reduce(
      (acc, report) => {
        const cellValue = ["title1", "title2"].includes(header.type)
          ? null
          : header.type === "actions"
          ? `ActionButton${report.slug}`
          : getFormattedValue(header, report)
        acc[`${report.year}${report.period}`] = cellValue
        return acc
      },
      {
        desc: header.text,
        type: header.type,
        styles: header.styles,
        level1Index: header.level1Index,
        id: randomid(),
      },
    )
    return col
  })

  const agOptions = {
    reactNext: true,
    defaultColDef: {
      width: 100,
      resizable: true,
    },
    columnDefs,
    rowData,
    rowSelection: "single",
    context: { isCustom },
  }

  const onAnimationQueueEmpty = ({ api }) => {
    if (api && !wentToLastACol) {
      api.ensureColumnVisible(targetCol)
      handleSetWentToLastCol(true)
    }
  }

  const [, setGridApi] = useState({})
  const [, setGridColumnApi] = useState({})
  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  return (
    <TableContainer className="ag-theme-balham-dark">
      <AgGridReact
        onGridReady={onGridReady}
        animateRows={true}
        onRowDragEnter={handleRowDragEnter}
        onRowDragEnd={handleRowDragEnd}
        onAnimationQueueEmpty={onAnimationQueueEmpty}
        rowMultiSelectWithClick={true}
        rowClassRules={rowClassRules}
        sizeColumnsToFit
        getContextMenuItems={params =>
          getContextMenuItems({ params, setAddModal, setEditModal, setDeleteAlert })
        }
        {...agOptions}
      />
      <AddDialog stockSlug={stockSlug} tabId={id} addModal={addModal} setAddModal={setAddModal} />
      <EditDialog
        stockSlug={stockSlug}
        tabDef={tabDef}
        editModal={editModal}
        setEditModal={setEditModal}
      />
      <DeleteAlert
        tabDef={tabDef}
        deleteAlert={deleteAlert}
        setDeleteAlert={setDeleteAlert}
        handleDelete={handleDelete}
      />
      <CannotDragAlert
        stockSlug={stockSlug}
        cannotDrag={cannotDrag}
        setCannotDrag={setCannotDrag}
      />
    </TableContainer>
  )
}

export default Table
