// @ts-nocheck
import * as R from "ramda"

/**
 * It takes the list of nodes and the list of node definitions, and returns a list of nodes with the
 * optional property added to each handleBounds
 * Need this function because when a new node is adding to a formula,
 * handlebounds are filled when edges are connected but info about optional is missing at this level.
 */

export const enhanceNodes = ({ nodes, nodeDefs }) => {
  return R.map(node => {
    const nodeDef = R.find(R.propEq("type", node.type))(nodeDefs.nodes)

    return {
      ...node,
      handleBounds: {
        target: nodeDef.inputs
          ? nodeDef.inputs.map((inputDef, index) => ({
              ...node.handleBounds.target[index],
              optional: inputDef.optional ? inputDef.optional : false,
            }))
          : null,
        source: nodeDef.outputs
          ? nodeDef.outputs.map((outputDef, index) => ({
              ...node.handleBounds.source[index],
              optional: outputDef.optional ? outputDef.optional : false,
            }))
          : null,
      },
    }
  }, nodes)
}
