// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import { updateAnalyse, updateAnalyseOk, updateAnalyseError } from "../../index"
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const updateAnalyseEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(updateAnalyse.type),
    switchMap(({ payload: { analyse } }) => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/analysis/${analyse.id}/`
      return ajax({
        url,
        method: "PATCH",
        body: analyse,
      }).pipe(
        map(({ response }) => updateAnalyseOk({ analyse: response })),
        catchError(payload => {
          let error
          if (payload?.response?.error) {
            error = payload?.response?.error
          }
          return of(updateAnalyseError({ id: analyse.id, error }))
        }),
      )
    }),
  )

export default updateAnalyseEpic
