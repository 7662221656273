// @ts-nocheck
import { Button, ButtonGroup, Icon, Tag, Tooltip } from "@blueprintjs/core"
import { compose, find, pathOr, propEq, reject, values } from "ramda"
import { memo, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import desksDuck from "../../../../state/modules/desks"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { Col, Row } from "../../../components"
import { useAuth } from "../../../hooks"
import { EditDesk } from "../Desk/EditDesk"
import { OwnerInfoTooltip } from "../components"
import { updatePos, useGetDeskOwnerName, useGetWritePermission } from "../utils"
import ItemTypes from "./itemTypes"

const style = {
  border: "1px solid",
  borderColor: "rgba(255, 255, 255, 0.4)",
  borderRadius: "3px 3px",
  padding: "2px",
}

const handleStyle = {
  cursor: "move",
}

const VisibleDeskItem = ({ deskId, findCard, moveCard, userDesks, activeDeskId }) => {
  const { profile } = useAuth()
  const navigate = useNavigate()
  const [dialog, setDialog] = useState(false)
  const toggleDialog = () => setDialog(!dialog)

  const allDesks = values(useSelector(pathOr([], ["desks", "desks", "data"])))
  const desk = allDesks.find(desk => desk.id === deskId)

  // Variables for permissions
  const userId = profile.id
  const deskOwnerId = desk.owner_id
  const deskOwnerName = useGetDeskOwnerName(desk)
  const hasWritePermission = useGetWritePermission(desk)
  // end Variables for permissions

  const deskName = find(propEq("id", deskId))(allDesks).name

  const redirect = () => navigate(`/desks/`)

  const dispatch = useDispatch()

  const removeFromUserDesk = () => {
    if (deskId === activeDeskId) {
      dispatch(
        desksDuck.actions.upsertUserDesks(
          compose(updatePos, reject(propEq("desk", deskId)))(userDesks),
        ),
      )
      setTimeout(() => {
        redirect()
      }, 500)
    } else
      dispatch(
        desksDuck.actions.upsertUserDesks(
          compose(updatePos, reject(propEq("desk", deskId)))(userDesks),
        ),
      )
  }

  const handleClick = () => removeFromUserDesk()

  const colorStyle = () => {
    if (userId === deskOwnerId) {
      return { textAlign: "center" }
    }
    if (hasWritePermission) {
      return { textAlign: "center", color: SCIENT_COLORS.lightBlue1 }
    }
    return { textAlign: "center", color: SCIENT_COLORS.gray4 }
  }

  //For dnd Card
  const originalIndex = findCard(deskId).index

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, deskId, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    end: (dropResult, monitor) => {
      const { deskId: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveCard(droppedId, originalIndex)
      }
    },
  })

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => false,
    hover({ deskId: draggedId }) {
      if (draggedId !== deskId) {
        const { index: overIndex } = findCard(deskId)
        moveCard(draggedId, overIndex)
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  //End dnd Card

  return (
    <div ref={preview} style={{ ...style, opacity }} fill="true">
      <Row key={deskId} style={{ margin: "1px 0" }}>
        <Col xs={1}>
          <div ref={node => drag(drop(node))} style={handleStyle}>
            <Icon
              icon="move"
              size={20}
              style={{ margin: "auto", padding: "5px", color: "#AAB6C1" }}
            />
          </div>
        </Col>
        <Col xs={8}>
          <Tag fill minimal large style={colorStyle()}>
            {deskName}
          </Tag>
        </Col>
        <Col xs={2}>
          <ButtonGroup fill>
            <Tooltip
              content="Remove from visible desks"
              placement="right"
              openOnTargetFocus={false}
              compact
            >
              <Button id={`remove${deskName}`} minimal icon="remove" onClick={handleClick} />
            </Tooltip>
            {userId === deskOwnerId || hasWritePermission ? (
              <>
                <EditDesk
                  id={deskId}
                  userDesks={userDesks}
                  isOpen={dialog}
                  toggleDialog={toggleDialog}
                  isUserDesk={true}
                />
                <Tooltip content="Edit desk" placement="right" openOnTargetFocus={false} compact>
                  <Button minimal onClick={toggleDialog} icon="cog" />
                </Tooltip>
                {userId !== deskOwnerId && <OwnerInfoTooltip deskOwnerName={deskOwnerName} />}
              </>
            ) : (
              <>
                <p style={{ margin: "0 4px 0 0", fontSize: "11px", textAlign: "center" }}>
                  Read only
                </p>
                <OwnerInfoTooltip deskOwnerName={deskOwnerName} />
              </>
            )}
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  )
}

export default memo(VisibleDeskItem)
