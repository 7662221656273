// @ts-nocheck
import { path } from "ramda"
import { useSelector } from "react-redux"

import { FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import Tabs from "./Tabs"
import View from "./View"

const Main = ({ stockSlug, showDelete }) => {
  const activeTab = useSelector(path(["profile", "userstockconfigs", stockSlug, "activeTab"]))

  return (
    <FlexContainerAndItem flexGrow={1} flexDirection="column">
      <Tabs activeTab={activeTab} stockSlug={stockSlug} />
      {activeTab === null ? (
        <div
          className="center"
          style={{
            position: "relative",
            margin: "auto",
            top: "25%",
            right: 0,
            bottom: 0,
            left: 0,
            width: "250px",
            height: "250px",
          }}
        >
          <h2>No tab selected</h2>
        </div>
      ) : (
        <View stockSlug={stockSlug} activeTab={activeTab} showDelete={showDelete} />
      )}
    </FlexContainerAndItem>
  )
}

export default Main
