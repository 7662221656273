import { Intent, Tag } from "@blueprintjs/core"
import { useEffect } from "react"
import { IdeaOrdering } from "../../../../../api/ideas"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { useIdeasCache } from "../../../../hooks"
import { IdeaType } from "../../types/business"
import { capitalizeFirstLetter } from "../../../../../utils"

const InboxTagCategories = () => {
  const {
    ideaTypeFilter,
    tradeOpenOnly,
    tradeClosedOnly,
    orderBy,
    setIdeaTypeFilter,
    setTradeOpenOnly,
    setTradeClosedOnly,
  } = useIdeasCache()

  /** OnClick handler for tag categorie open trade only */
  const onClickTradeOpen = () => {
    setTradeOpenOnly && setTradeOpenOnly(!tradeOpenOnly)
    setTradeClosedOnly && setTradeClosedOnly(false)
    setIdeaTypeFilter && setIdeaTypeFilter(null)
  }

  /** OnClick handler for tag categorie closed trade only */
  const onClickTradeClosed = () => {
    setTradeClosedOnly && setTradeClosedOnly(!tradeClosedOnly)
    setTradeOpenOnly && setTradeOpenOnly(false)
    setIdeaTypeFilter && setIdeaTypeFilter(null)
  }

  /** OnClick handler for tag categorie note */
  const onClickIdeaType = (ideaType: IdeaType) => () => {
    setIdeaTypeFilter && setIdeaTypeFilter(ideaTypeFilter === ideaType ? null : ideaType)
    setTradeOpenOnly && setTradeOpenOnly(false)
    setTradeClosedOnly && setTradeClosedOnly(false)
  }

  /**
   * Effect to reset tag categories when ordering by trade open or close.
   */
  useEffect(() => {
    if (orderBy === IdeaOrdering.TRADE_CLOSE_DATE) {
      setTradeOpenOnly && setTradeOpenOnly(false)
      setIdeaTypeFilter && setIdeaTypeFilter(null)
    }
    if (orderBy === IdeaOrdering.TRADE_OPEN_DATE) {
      setIdeaTypeFilter && setIdeaTypeFilter(null)
    }
    if (orderBy === IdeaOrdering.TRADE_PERFORMANCE) {
      setIdeaTypeFilter && setIdeaTypeFilter(null)
    }
  }, [orderBy, setTradeOpenOnly, setIdeaTypeFilter])

  const categories = [
    {
      name: "Open Trades",
      onClick: onClickTradeOpen,
      selected: tradeOpenOnly,
      displayCondition: orderBy !== IdeaOrdering.TRADE_CLOSE_DATE,
    },
    {
      name: "Closed Trades",
      onClick: onClickTradeClosed,
      selected: tradeClosedOnly,
      displayCondition: orderBy !== IdeaOrdering.TRADE_CLOSE_DATE,
    },
    {
      name: capitalizeFirstLetter(IdeaType.NOTE),
      onClick: onClickIdeaType(IdeaType.NOTE),
      selected: ideaTypeFilter === IdeaType.NOTE,
      displayCondition:
        orderBy !== IdeaOrdering.TRADE_CLOSE_DATE &&
        orderBy !== IdeaOrdering.TRADE_OPEN_DATE &&
        orderBy !== IdeaOrdering.TRADE_PERFORMANCE,
    },
    {
      name: capitalizeFirstLetter(IdeaType.STRUCTURAL),
      onClick: onClickIdeaType(IdeaType.STRUCTURAL),
      selected: ideaTypeFilter === IdeaType.STRUCTURAL,
      displayCondition:
        orderBy !== IdeaOrdering.TRADE_CLOSE_DATE &&
        orderBy !== IdeaOrdering.TRADE_OPEN_DATE &&
        orderBy !== IdeaOrdering.TRADE_PERFORMANCE,
    },
  ]

  return (
    <FlexContainer flexDirection="row" gap="10px">
      {categories.map(
        category =>
          category.displayCondition && (
            <Tag
              interactive
              key={category.name}
              intent={category.selected ? Intent.PRIMARY : Intent.NONE}
              onClick={category.onClick}
            >
              {category.name}
            </Tag>
          ),
      )}
    </FlexContainer>
  )
}

export default InboxTagCategories
