// @ts-nocheck
import React from "react"
import { useSelector } from "react-redux"
import { compose, map, reject, pathOr, update } from "ramda"
import { Button, MenuItem, Classes } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"

import { getColTitle, getNiceFormulaItem } from "../../../../../utils"

export const SelectCol = ({ panelsState, setPanelsState, cols }) => {
  const fieldList = useSelector(pathOr({}, ["shared", "fieldList"]))
  const factorList = useSelector(pathOr({}, ["shared", "fieldList", "factor_list"]))

  const options = compose(
    map(col => ({
      label: getColTitle(col, fieldList, factorList),
      value: col.colId,
    })),
    reject(
      col =>
        col.kind === "stockParam" &&
        col.path !== "STK_beta_custom" &&
        col.path !== "STK_cycl_score",
    ),
    reject(col => col.kind === "stockField" && col.path === "VAL_method"),
    reject(col => col.kind === "separationCol"),
    reject(col => col.kind === "formula"),
  )(cols)

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.value}
        text={item.label}
        onClick={handleClick}
      />
    )
  }

  const appendExistingCol = item => {
    setPanelsState(
      compose(
        update(0, `#${item.value}`),
        update(1, ""),
        update(2, ""),
        update(3, ""),
      )(panelsState),
    )
  }

  return (
    <div className={Classes.DIALOG_BODY} style={{ margin: "86px 120px" }}>
      <Select
        className="sct-fullwidth"
        filterable={false}
        items={options}
        itemRenderer={itemRenderer}
        onItemSelect={appendExistingCol}
        disabled={options.length === 0}
        popoverProps={{
          position: "bottom",
        }}
      >
        <Button
          fill
          rightIcon="caret-down"
          text={
            panelsState[0] === ""
              ? "Select existing col"
              : getNiceFormulaItem({
                  item: panelsState[0],
                  cols,
                  fieldList,
                  factorList,
                })
          }
          disabled={options.length <= 0}
        />
      </Select>
    </div>
  )
}
