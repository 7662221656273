// @ts-nocheck
import { compose, map, prop, takeWhile } from "ramda"
import { nextEmptyEntries } from "."

export const createSequence = (startName, endName) => {
  const nextEntries = nextEmptyEntries(startName, 1000)
  const isEnd = x => x.name !== endName
  const inBetween = compose(map(prop("name")), takeWhile(isEnd))(nextEntries)

  return [startName, ...inBetween, endName]
}
