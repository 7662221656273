// @ts-nocheck
import { keys, pathOr } from "ramda"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import profileDuck from "../../../../state/modules/profile"

import Header from "./Header"
import Main from "./Main"

const ConfigLoader = ({ stockSlug, showDelete }) => {
  const dispatch = useDispatch()
  const forecastSlugs = keys(useSelector(pathOr({}, ["rForecasts", stockSlug, "forecasts"])))

  /**
   * @todo: Profile is already deprecated on redux although we need to move the
   * userstocksconfig out of redux also in order to completely remove profile from redux
   */
  const userstockconfig = useSelector(pathOr({}, ["profile", "userstockconfigs", stockSlug]))

  const stockSetInitial = () => {
    dispatch(profileDuck.actions.stockSetInitial({ slug: stockSlug, forecastSlugs }))
  }
  useEffect(stockSetInitial, [])

  if (!userstockconfig.ready) return <p>Loading userstockconfig.</p>

  if (userstockconfig.notEnoughData) return <p>Not enough forecasts.</p>

  return (
    <>
      <Header stockSlug={stockSlug} />
      <Main stockSlug={stockSlug} showDelete={showDelete} />
    </>
  )
}

export default ConfigLoader
