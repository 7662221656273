import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { CardContainer, noSelect } from "../../../../../styled/layout.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

export const IdeaPositionsContainer = styled(CardContainer)`
  border-bottom: 1.5px solid #232e37;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 46px;
  padding: 10px 20px 10px 20px;
  ${noSelect}
`

export const PositionInfosContainer = styled(FlexContainerAndItem)({
  overflow: "hidden",
  position: "relative",
})

type PositionInfosProps = {
  perf?: number
  fontSize?: string
}

export const PositionInfos = styled.p<PositionInfosProps>(props => ({
  color:
    props.perf && props.perf > 0
      ? SCIENT_COLORS.forest3
      : props.perf && props.perf < 0
        ? SCIENT_COLORS.vermilion4
        : "white",
  margin: 0,
  fontSize: props.fontSize ? props.fontSize : "14px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}))

export const PriceInfo = styled.p({
  color: SCIENT_COLORS.blue4,
  margin: 0,
  fontSize: "14px",
  whiteSpace: "nowrap",
})

export const iconCss = css({
  marginBottom: "3px",
  cursor: "help",
})
