import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { useApi } from "../../../hooks/useApi"
import { TradingStock } from "../Stock/types"

const useUpdateTradingStockMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (stock: TradingStock) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.trading.updateTradingStock, { onSuccess, onError })
}

export default useUpdateTradingStockMutation
