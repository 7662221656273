// @ts-nocheck
import { Button, Card, Checkbox, Collapse, HTMLTable, Label } from "@blueprintjs/core"
import { Field } from "formik"
import { append, compose, filter, includes, indexOf, map, reject } from "ramda"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { getRowTitle } from "../../../utils/index"
import { FormikSelect, FormikTextInput } from "../FormikFields"
import FormikStyling from "../FormikStyling"

export const Aggregate = ({ values, setValues, card, showOptions, toogleShowOptions, index }) => {
  const methodOptions = ["median", "average"]
  const sourceList = compose(
    map(e => ({
      title: getRowTitle(e),
      rowId: e.rowId,
    })),
    filter(e => e.kind === "stockRows"),
  )(values.rowDefs.rows)

  const handleChange = source => {
    includes(source.rowId, card.sources)
      ? setValues({
          rowDefs: {
            ...values.rowDefs,
            rows: map(
              x =>
                indexOf(x, values.rowDefs.rows) === index
                  ? {
                      ...x,
                      sources: reject(e => e === source.rowId, values.rowDefs.rows[index].sources),
                    }
                  : x,
              values.rowDefs.rows,
            ),
          },
        })
      : setValues({
          rowDefs: {
            ...values.rowDefs,
            rows: map(
              x =>
                indexOf(x, values.rowDefs.rows) === index
                  ? { ...x, sources: append(source.rowId, values.rowDefs.rows[index].sources) }
                  : x,
              values.rowDefs.rows,
            ),
          },
        })
  }

  const handleSelectAll = () => {
    sourceList.length === card.sources.length
      ? setValues({
          rowDefs: {
            ...values.rowDefs,
            rows: map(
              x =>
                indexOf(x, values.rowDefs.rows) === index
                  ? {
                      ...x,
                      sources: [],
                    }
                  : x,
              values.rowDefs.rows,
            ),
          },
        })
      : setValues({
          rowDefs: {
            ...values.rowDefs,
            rows: map(
              x =>
                indexOf(x, values.rowDefs.rows) === index
                  ? { ...x, sources: map(e => e.rowId, sourceList) }
                  : x,
              values.rowDefs.rows,
            ),
          },
        })
  }

  return (
    <Card>
      <Label>
        Method
        <Field
          name={`rowDefs.rows[${index}].method`}
          inputProps={{ options: methodOptions }}
          component={FormikSelect}
          style={{ margin: "0 0 16px 0" }}
        />
      </Label>

      <HTMLTable striped compact style={{ width: "450px" }}>
        <thead>
          <tr>
            <th>Source</th>

            <th>Actions</th>
          </tr>
          {card.sources.length === 0 && (
            <tr>
              <th style={{ color: SCIENT_COLORS.orange3, marginLeft: "10px" }}>
                Select at least one source!
              </th>
            </tr>
          )}
        </thead>

        <tbody>
          <tr key={"all"}>
            <td>
              <strong>All sources</strong>
            </td>
            <td>
              <Checkbox
                intent="green"
                large
                checked={sourceList.length === card.sources.length}
                onChange={handleSelectAll}
              ></Checkbox>
            </td>
          </tr>

          {sourceList.map(source => (
            <tr key={source.rowId}>
              <td style={{ paddingLeft: "30px" }}>{source.title}</td>
              <td>
                <Checkbox
                  checked={includes(source.rowId, card.sources)}
                  onChange={() => handleChange(source)}
                ></Checkbox>
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      <Button
        fill
        alignText="left"
        minimal
        icon={showOptions ? "caret-down" : "caret-right"}
        onClick={toogleShowOptions}
      >
        {showOptions ? "Hide" : ""} Options
      </Button>
      <Collapse isOpen={showOptions}>
        <Card>
          <Field
            name={`rowDefs.rows[${index}].frontOpts.titleOverride`}
            component={FormikTextInput}
            labelProps={{ label: "Title Override" }}
            inputProps={{ placeholder: "Enter title" }}
            helperText="Enter name or leave empty."
          />
          <FormikStyling index={index} />
        </Card>
      </Collapse>
    </Card>
  )
}
