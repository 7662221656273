// @ts-nocheck
import React from "react"
import { pathOr } from "ramda"
import { Alert } from "@blueprintjs/core"

export const DeleteAlert = ({ tabDef, deleteAlert, setDeleteAlert, handleDelete }) => (
  <Alert
    className="bp5-dark"
    canEscapeKeyCancel={true}
    canOutsideClickCancel={true}
    icon="trash"
    intent="danger"
    isOpen={deleteAlert}
    onCancel={() => setDeleteAlert(false)}
    cancelButtonText="Cancel"
    confirmButtonText="Delete"
    onConfirm={() => handleDelete(deleteAlert)}
  >
    Delete {pathOr("", ["rowDefs", deleteAlert.rowIndex, "text"], tabDef)}?
  </Alert>
)
