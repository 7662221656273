// @ts-nocheck
// This is copied from python file in the backend shared/constants/reports.py

export const sales_fields = [
  { name: "sales", interpolation: "flow" },
  {
    name: "sales_growth_readonly",
    old: "sales_growth",
    pct: true,
    interpolation: "end",
  },
  { name: "sales_organic_growth", pct: true, interpolation: "end" },
  { name: "sales_price_growth", pct: true, interpolation: "end" },
  { name: "sales_volume_growth", pct: true, interpolation: "end" },
  { name: "sales_other_organic_growth", pct: true, interpolation: "end" },
  { name: "sales_other_organic_growth_2", pct: true, interpolation: "end" },
  { name: "sales_calendar_effect_growth", pct: true, interpolation: "end" },
  { name: "sales_scope_growth", interpolation: "end" },
  { name: "sales_acquisition_growth", pct: true, interpolation: "end" },
  { name: "sales_fx_growth", pct: true, interpolation: "end" },
  { name: "sales_other_scope_growth", pct: true, interpolation: "end" },
  {
    name: "sales_growth_qoq_readonly",
    old: "sales_growth_qoq",
    pct: true,
    interpolation: "end",
  },
  { name: "sales_organic_growth_qoq", pct: true, interpolation: "end" },
  { name: "sales_volume_growth_qoq", pct: true, interpolation: "end" },
  { name: "sales_price_growth_qoq", pct: true, interpolation: "end" },
  { name: "sales_other_organic_growth_qoq", pct: true, interpolation: "end" },
  { name: "sales_scope_growth_qoq", pct: true, interpolation: "end" },
  { name: "sales_acquisition_growth_qoq", pct: true, interpolation: "end" },
  { name: "sales_other_scope_growth_qoq", pct: true, interpolation: "end" },
  { name: "sales_fx_growth_qoq", pct: true, interpolation: "end" },
]
export const orders_fields = [
  { name: "orders", interpolation: "flow" },
  {
    name: "orders_growth_readonly",
    old: "orders_growth",
    pct: true,
    interpolation: "end",
  },
  { name: "orders_price_growth", pct: true, interpolation: "end" },
  { name: "orders_volume_growth", pct: true, interpolation: "end" },
  { name: "orders_other_organic_growth", pct: true, interpolation: "end" },
  { name: "orders_organic_growth", pct: true, interpolation: "end" },
  { name: "orders_acquisition_growth", pct: true, interpolation: "end" },
  { name: "orders_fx_growth", pct: true, interpolation: "end" },
  { name: "orders_other_scope_growth", pct: true, interpolation: "end" },
  { name: "orders_scope_growth", pct: true, interpolation: "end" },
  { name: "book_to_bill_readonly", frontOnly: true, interpolation: "linear" },
]

export const profit_fields = [
  { name: "profit", interpolation: "flow" },
  {
    name: "profit_growth_readonly",
    old: "profit_growth",
    pct: true,
    interpolation: "end",
  },
  {
    name: "profit_margin_readonly",
    old: "profit_margin",
    frontOnly: true,
    override: "profit_margin_override",
    pct: true,
    interpolation: "linear",
  },
  {
    name: "profit_margin_override",
    old: "profit_margin_computed",
    pct: true,
    interpolation: "linear",
  },
]
export const backlog_fields = [
  { name: "backlog", interpolation: "linear" },
  {
    name: "backlog_growth_readonly",
    old: "backlog_growth",
    pct: true,
    interpolation: "end",
  },
]

export const shared_fields = [
  ...sales_fields,
  ...orders_fields,
  ...profit_fields,
  ...backlog_fields,
]

export const is_fields = [
  {
    name: "is_ppa_amortization",
    old: "ppa_amortization",
    interpolation: "flow",
  },
  {
    name: "is_walkdown_customsegment_to_reported_ebit",
    old: "walkdown_customsegment_to_reported_ebit",
    interpolation: "flow",
  },
  { name: "is_exceptionals", old: "exceptionals", interpolation: "flow" },
  {
    name: "is_ebit_from_customsegments_readonly",
    old: "ebit_from_customsegments",
    interpolation: "flow",
  },
  { name: "is_reported_cogs", old: "reported_cogs", interpolation: "flow" },
  { name: "is_s_g_and_a", old: "s_g_and_a", interpolation: "flow" },
  {
    name: "is_expensed_r_and_d",
    old: "expensed_r_and_d",
    interpolation: "flow",
  },
  {
    name: "is_other_income_expense",
    old: "other_income_expense",
    interpolation: "flow",
  },
  {
    name: "is_non_operating_income_above",
    old: "non_operating_income_above",
    interpolation: "flow",
  },
  {
    name: "is_non_operating_income_below",
    old: "non_operating_income_below",
    interpolation: "flow",
  },
  {
    name: "is_share_of_profit_from_associates_above",
    old: "share_of_profit_from_associates_above",
    interpolation: "flow",
  },
  {
    name: "is_share_of_profit_from_associates_below",
    old: "share_of_profit_from_associates_below",
    interpolation: "flow",
  },
  { name: "is_depreciation", old: "depreciation", interpolation: "flow" },
  { name: "is_amortization", old: "amortization", interpolation: "flow" },
  { name: "is_ebitda", old: "ebitda", interpolation: "flow" },
  { name: "is_ebita", old: "ebita", interpolation: "flow" },
  {
    name: "is_ebit_reported_readonly",
    old: "ebit_reported",
    frontOnly: true,
    override: "is_ebit_reported_override",
    interpolation: "flow",
  },
  {
    name: "is_ebit_reported_override",
    old: "ebit_reported_override",
    interpolation: "flow",
  },
  {
    name: "is_financial_income",
    old: "financial_income",
    interpolation: "flow",
  },
  {
    name: "is_profit_before_tax_readonly",
    old: "profit_before_tax",
    frontOnly: true,
    interpolation: "flow",
  },
  { name: "is_tax_expense", old: "tax_expense", interpolation: "flow" },
  {
    name: "is_minority_interest",
    old: "minority_interest",
    interpolation: "flow",
  },
  {
    name: "is_net_profit_from_continuing_operations",
    old: "net_profit_from_continuing_operations",
    interpolation: "flow",
  },
  { name: "is_dividend", old: "dividend", interpolation: "flow" },
  {
    name: "is_net_profit_from_discontinued_operations",
    old: "net_profit_from_discontinued_operations",
    interpolation: "flow",
  },
  {
    name: "is_net_profit_for_the_period",
    old: "net_profit_for_the_period",
    interpolation: "flow",
  },
  {
    name: "is_average_shares_out",
    old: "average_shares_out",
    interpolation: "flow",
  },
  {
    name: "is_average_fully_diluted_out",
    old: "average_fully_diluted_out",
    interpolation: "flow",
  },
  {
    name: "is_diluted_eps_adjusted",
    old: "diluted_eps_adjusted",
    interpolation: "flow",
  },
  {
    name: "is_diluted_eps_reported",
    old: "diluted_eps_reported",
    interpolation: "flow",
  },
  {
    name: "is_d_a_reported_in_is",
    old: "d_a_reported_in_is",
    interpolation: "flow",
  },
  {
    name: "is_restructuring_costs",
    old: "restructuring_costs",
    interpolation: "flow",
  },
  { name: "is_dps", old: "dps", interpolation: "flow" },
  { name: "is_roce", old: "roce", interpolation: "flow" },
  { name: "is_share_buyback", old: "share_buyback", interpolation: "flow" },
  { name: "is_share_issuance", old: "share_issuance", interpolation: "flow" },
  { name: "is_buyback_price", old: "buyback_price", interpolation: "flow" },
  { name: "is_issuance_price", old: "issuance_price", interpolation: "flow" },
  { name: "is_payout_ratio", old: "payout_ratio", interpolation: "flow" },
  { name: "is_tax_rate", old: "tax_rate", interpolation: "flow" },
  {
    name: "is_depreciation_as_ola_n_1",
    old: "depreciation_as_ola_n_1",
    interpolation: "flow",
  },
  {
    name: "is_operating_lease_depreciation",
    old: "operating_lease_depreciation",
    interpolation: "flow",
  },
  {
    name: "is_lease_interest_to_liabilities",
    old: "lease_interest_to_liabilities",
    interpolation: "flow",
  },
  { name: "is_lease_interest", old: "lease_interest", interpolation: "flow" },
  { name: "is_ppe_depreciation", interpolation: "flow" },
  { name: "is_gross_profit", interpolation: "flow" },
]
export const bs_fields = [
  {
    name: "bs_working_capital_company_definition",
    old: "working_capital_company_definition",
    interpolation: "linear",
  },
  {
    name: "bs_gross_bv_tangibles",
    old: "gross_bv_tangibles",
    interpolation: "linear",
  },
  {
    name: "bs_accumulated_depreciation",
    old: "accumulated_depreciation",
    interpolation: "linear",
  },
  { name: "bs_ntfa", old: "ntfa", interpolation: "linear" },
  {
    name: "bs_intangibles_with_indefinite_useful_life_gross",
    old: "intangibles_with_indefinite_useful_life_gross",
    interpolation: "linear",
  },
  {
    name: "bs_intangibles_with_indefinite_useful_life_net",
    old: "intangibles_with_indefinite_useful_life_net",
    interpolation: "linear",
  },
  {
    name: "bs_impairment_intangibles_indefinite_useful_life",
    old: "impairment_intangibles_indefinite_useful_life",
    interpolation: "linear",
  },
  { name: "bs_goodwill_gross", old: "goodwill_gross", interpolation: "linear" },
  { name: "bs_goodwill_net", old: "goodwill_net", interpolation: "linear" },
  {
    name: "bs_impairment_to_goodwill_for_the_year",
    old: "impairment_to_goodwill_for_the_year",
    interpolation: "linear",
  },
  {
    name: "bs_other_intangibles_gross",
    old: "other_intangibles_gross",
    interpolation: "linear",
  },
  {
    name: "bs_other_intangibles_net",
    old: "other_intangibles_net",
    interpolation: "linear",
  },
  {
    name: "bs_amortization_expense_other_intangibles",
    old: "amortization_expense_other_intangibles",
    interpolation: "linear",
  },
  {
    name: "bs_tangible_assets",
    old: "tangible_assets",
    interpolation: "linear",
  },
  {
    name: "bs_intangible_assets",
    old: "intangible_assets",
    interpolation: "linear",
  },
  {
    name: "bs_investment_in_associate",
    old: "investment_in_associate",
    interpolation: "linear",
  },
  {
    name: "bs_investment_in_other_affiliated_companies",
    old: "investment_in_other_affiliated_companies",
    interpolation: "linear",
  },
  {
    name: "bs_non_current_financial_assets",
    old: "non_current_financial_assets",
    interpolation: "linear",
  },
  {
    name: "bs_deferred_tax_assets",
    old: "deferred_tax_assets",
    interpolation: "linear",
  },
  {
    name: "bs_other_non_current_assets",
    old: "other_non_current_assets",
    interpolation: "linear",
  },
  { name: "bs_fixed_assets", old: "fixed_assets", interpolation: "linear" },
  { name: "bs_cash", old: "cash", interpolation: "linear" },
  { name: "bs_receivables", old: "receivables", interpolation: "linear" },
  { name: "bs_inventories", old: "inventories", interpolation: "linear" },
  {
    name: "bs_other_non_wc_current_assets",
    old: "other_non_wc_current_assets",
    interpolation: "linear",
  },
  {
    name: "bs_other_wc_current_assets",
    old: "other_wc_current_assets",
    interpolation: "linear",
  },
  { name: "bs_current_assets", old: "current_assets", interpolation: "linear" },
  { name: "bs_total_assets", old: "total_assets", interpolation: "linear" },
  { name: "bs_capital_shares", old: "capital_shares", interpolation: "linear" },
  { name: "bs_other_reserves", old: "other_reserves", interpolation: "linear" },
  {
    name: "bs_retained_earnings",
    old: "retained_earnings",
    interpolation: "linear",
  },
  { name: "bs_minorities", old: "minorities", interpolation: "linear" },
  {
    name: "bs_shareholders_equity",
    old: "shareholders_equity",
    interpolation: "linear",
  },
  { name: "bs_long_term_debt", old: "long_term_debt", interpolation: "linear" },
  {
    name: "bs_non_current_leases",
    old: "non_current_leases",
    interpolation: "linear",
  },
  {
    name: "bs_other_long_term_liabilities",
    old: "other_long_term_liabilities",
    interpolation: "linear",
  },
  {
    name: "bs_pension_provisions",
    old: "pension_provisions",
    interpolation: "linear",
  },
  {
    name: "bs_deferred_tax_liab",
    old: "deferred_tax_liab",
    interpolation: "linear",
  },
  {
    name: "bs_tax_liabilities",
    old: "tax_liabilities",
    interpolation: "linear",
  },
  { name: "bs_provisions", old: "provisions", interpolation: "linear" },
  {
    name: "bs_fixed_liabilities",
    old: "fixed_liabilities",
    interpolation: "linear",
  },
  {
    name: "bs_short_term_borrowings",
    old: "short_term_borrowings",
    interpolation: "linear",
  },
  { name: "bs_current_leases", old: "current_leases", interpolation: "linear" },
  {
    name: "bs_accounts_payable_and_other_wc_current_liabilities",
    old: "accounts_payable_and_other_wc_current_liabilities",
    interpolation: "linear",
  },
  {
    name: "bs_short_term_provisions",
    old: "short_term_provisions",
    interpolation: "linear",
  },
  {
    name: "bs_current_tax_liabilities",
    old: "current_tax_liabilities",
    interpolation: "linear",
  },
  {
    name: "bs_other_non_wc_current_liabilities",
    old: "other_non_wc_current_liabilities",
    interpolation: "linear",
  },
  {
    name: "bs_current_liabilities",
    old: "current_liabilities",
    interpolation: "linear",
  },
  {
    name: "bs_total_equity_liabilities",
    old: "total_equity_liabilities",
    interpolation: "linear",
  },
  {
    name: "bs_number_employees",
    old: "number_employees",
    interpolation: "linear",
  },
  { name: "bs_avg_salary", old: "avg_salary", interpolation: "linear" },
  {
    name: "bs_associates_fairvalue",
    old: "associates_fairvalue",
    interpolation: "linear",
  },
  {
    name: "bs_minorities_fairvalue",
    old: "minorities_fairvalue",
    interpolation: "linear",
  },
  {
    name: "bs_pensions_liab_fairvalue",
    old: "pensions_liab_fairvalue",
    interpolation: "linear",
  },
  { name: "bs_ev_adj_readonly", frontOnly: true, interpolation: "linear" },
  {
    name: "bs_ev_adj_override",
    old: "ev_adj_override",
    interpolation: "linear",
  },
  { name: "bs_bps", old: "bps", interpolation: "linear" },
  {
    name: "bs_amortization_pct_bv",
    old: "amortization_pct_bv",
    interpolation: "linear",
  },
  {
    name: "bs_depreciation_pct_ntfa",
    old: "depreciation_pct_ntfa",
    interpolation: "linear",
  },
  {
    name: "bs_net_debt_readonly",
    frontOnly: true,
    override: "bs_net_debt_override",
    interpolation: "linear",
  },
  {
    name: "bs_net_debt_override",
    old: "net_debt_override",
    interpolation: "linear",
  },
  { name: "bs_wcr_to_sales", old: "wcr_to_sales", interpolation: "linear" },
  {
    name: "bs_fi_to_grossdebt",
    old: "fi_to_grossdebt",
    interpolation: "linear",
  },
  {
    name: "bs_pct_cash_debtreduction",
    old: "pct_cash_debtreduction",
    interpolation: "linear",
  },
  {
    name: "bs_operating_lease_assets",
    old: "operating_lease_assets",
    interpolation: "linear",
  },
]
export const cf_fields = [
  { name: "cf_cash_taxes", old: "cash_taxes", interpolation: "flow" },
  {
    name: "cf_d_a_delta_vs_p_l",
    old: "d_a_delta_vs_p_l",
    interpolation: "flow",
  },
  {
    name: "cf_gains_and_losses_from_investment_in_associate",
    old: "gains_and_losses_from_investment_in_associate",
    interpolation: "flow",
  },
  {
    name: "cf_gains_and_losses_on_fixed_assets_divestments",
    old: "gains_and_losses_on_fixed_assets_divestments",
    interpolation: "flow",
  },
  {
    name: "cf_gains_and_losses_on_retirement_benefits",
    old: "gains_and_losses_on_retirement_benefits",
    interpolation: "flow",
  },
  {
    name: "cf_financial_income_losses_delta_vs_p_l",
    old: "financial_income_losses_delta_vs_p_l",
    interpolation: "flow",
  },
  {
    name: "cf_changes_in_taxes",
    old: "changes_in_taxes",
    interpolation: "flow",
  },
  {
    name: "cf_share_based_expense",
    old: "share_based_expense",
    interpolation: "flow",
  },
  {
    name: "cf_restructuring_income",
    old: "restructuring_income",
    interpolation: "flow",
  },
  { name: "cf_other_items", old: "other_items", interpolation: "flow" },
  {
    name: "cf_changes_in_inventories",
    old: "changes_in_inventories",
    interpolation: "flow",
  },
  {
    name: "cf_changes_in_receivables",
    old: "changes_in_receivables",
    interpolation: "flow",
  },
  {
    name: "cf_changes_in_payables",
    old: "changes_in_payables",
    interpolation: "flow",
  },
  {
    name: "cf_other_changes_in_current_items_of_bs",
    old: "other_changes_in_current_items_of_bs",
    interpolation: "linear",
  },
  { name: "cf_capex_tangibles", old: "capex_tangibles", interpolation: "flow" },
  {
    name: "cf_capex_intangibles",
    old: "capex_intangibles",
    interpolation: "flow",
  },
  {
    name: "cf_cash_outflows_for_financial_assets",
    old: "cash_outflows_for_financial_assets",
    interpolation: "flow",
  },
  {
    name: "cf_cash_outflows_for_acquisitions",
    old: "cash_outflows_for_acquisitions",
    interpolation: "flow",
  },
  {
    name: "cf_cash_inflow_from_sale_of_tangible_assets",
    old: "cash_inflow_from_sale_of_tangible_assets",
    interpolation: "flow",
  },
  {
    name: "cf_cash_inflow_from_sale_of_intangible_assets",
    old: "cash_inflow_from_sale_of_intangible_assets",
    interpolation: "flow",
  },
  {
    name: "cf_cash_inflow_from_sale_of_subsidiaries",
    old: "cash_inflow_from_sale_of_subsidiaries",
    interpolation: "linear",
  },
  {
    name: "cf_interest_and_dividends",
    old: "interest_and_dividends",
    interpolation: "flow",
  },
  {
    name: "cf_cfi_readonly",
    old: "cfi",
    frontOnly: true,
    interpolation: "flow",
  },
  {
    name: "cf_dividends_to_minorities",
    old: "dividends_to_minorities",
    interpolation: "flow",
  },
  { name: "cf_dividends", old: "dividends", interpolation: "flow" },
  {
    name: "cf_proceeds_issuance_of_shares",
    old: "proceeds_issuance_of_shares",
    interpolation: "flow",
  },
  {
    name: "cf_proceeds_from_borrowings",
    old: "proceeds_from_borrowings",
    interpolation: "flow",
  },
  {
    name: "cf_repayment_of_borrowings",
    old: "repayment_of_borrowings",
    interpolation: "flow",
  },
  {
    name: "cf_interest_paid_and_other_financial_disbursement",
    old: "interest_paid_and_other_financial_disbursement",
    interpolation: "flow",
  },
  {
    name: "cf_net_cash_contributions_to_pensions",
    old: "net_cash_contributions_to_pensions",
    interpolation: "flow",
  },
  {
    name: "cf_add_back_non_cash_pension_charge",
    old: "add_back_non_cash_pension_charge",
    interpolation: "flow",
  },
  {
    name: "cf_cff_readonly",
    old: "cff",
    frontOnly: true,
    interpolation: "flow",
  },
  {
    name: "cf_opfcf_company_definition",
    old: "opfcf_company_definition",
    interpolation: "flow",
  },
  {
    name: "cf_fx_impact_on_cash_and_equivalents",
    old: "fx_impact_on_cash_and_equivalents",
    interpolation: "flow",
  },
  { name: "cf_other_changes", old: "other_changes", interpolation: "flow" },
  {
    name: "cf_cash_at_the_beginning_of_the_year",
    old: "cash_at_the_beginning_of_the_year",
    interpolation: "flow",
  },
  { name: "cf_restricted_cash", old: "restricted_cash", interpolation: "flow" },
  {
    name: "cf_cash_at_the_end_of_the_year_reported",
    old: "cash_at_the_end_of_the_year_reported",
    interpolation: "flow",
  },
  { name: "cf_wc_delta_bs_cf", old: "wc_delta_bs_cf", interpolation: "linear" },
  {
    name: "cf_cfo_readonly",
    old: "cfo",
    frontOnly: true,
    interpolation: "flow",
  },
  { name: "cf_opfcf_readonly", frontOnly: true, interpolation: "flow" },
  { name: "cf_opfcf_override", old: "opfcf", interpolation: "flow" },
  {
    name: "cf_intangible_capex_to_sales",
    old: "intangible_capex_to_sales",
    interpolation: "flow",
  },
  {
    name: "cf_tangible_capex_to_sales",
    old: "tangible_capex_to_sales",
    interpolation: "flow",
  },
  { name: "cf_leases_to_sales", old: "leases_to_sales", interpolation: "flow" },
  {
    name: "cf_capex_lease_assets",
    old: "capex_lease_assets",
    interpolation: "flow",
  },
]

export const currency_fields = [
  { name: "sales_exposure", interpolation: "end" },
  { name: "cost_exposure", interpolation: "end" },
]

export const geo_fields = [{ name: "share", interpolation: "linear" }]

export const field_fields = [{ name: "value" }]
