import { Divider, Icon } from "@blueprintjs/core"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { MediumText } from "../../../../styled/text.styled"
import ListingCard from "./ListingCard"
import {
  HeaderContainer,
  StockListingsCardList,
  StockListingsContainer,
} from "./StockListings.styled"
import { Listing } from "./types"

interface IStockListingsProps {
  listings: Listing[]
}

const StockListings = ({ listings }: IStockListingsProps) => (
  <StockListingsContainer flexDirection="column" gap="5px">
    <Divider />

    <HeaderContainer gap="25px" justifyContent="space-between">
      <FlexContainer gap="25px">
        <Icon icon="list" size={22} />
        <MediumText>Listings</MediumText>
      </FlexContainer>
    </HeaderContainer>

    <StockListingsCardList compact>
      {listings.map((listing, index) => (
        <ListingCard key={index} index={index} listing={listing} />
      ))}
    </StockListingsCardList>
  </StockListingsContainer>
)

export default StockListings
