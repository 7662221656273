// @ts-nocheck
import { compose, dissoc, isEmpty } from "ramda"
import { getPrevloopSource } from "./"
import { randomid } from "../../../../../../utils"

// This function 'formatRowTree' is formatting 'children' for sortableTree. When formatted, 'children' is saved in local state 'rowTree'.
// This function is adding an uniq temp-id to each node and prefix it
// by the kind (group, splits, segs, fields) of the node. If a node has a source, it is added to the prefix.

const getFirstPrefix = (object, source) =>
  object.kind === "group"
    ? object.loop_on === ""
      ? "group"
      : object.loop_on.includes("fields")
      ? `fields[${source[0]}]`
      : object.loop_on.includes("splits")
      ? `splits[${source[0]}]`
      : object.loop_on.includes("segs")
      ? "segs"
      : ""
    : isEmpty(object.formula_tree)
    ? "header"
    : "data"

const getSecondPrefix = object => {
  const regex = /{.+}/g
  return object.loop_on.match(regex)[0]
}

const assocKeys = object => {
  if (object.children) {
    object.children = object.children.map(child => assocKeys(child))
  }

  const source =
    object.kind === "group" &&
    (object.loop_on.includes("splits") || object.loop_on.includes("fields")) &&
    getPrevloopSource([object])

  const temp_id =
    object.kind === "group" && !isEmpty(object.loop_on)
      ? getFirstPrefix(object, source) + getSecondPrefix(object) + "|" + randomid()
      : getFirstPrefix(object, source) + "|" + randomid()

  // If formula is empty, Single is a header, so a key 'isHeader' is added
  const result =
    object.kind === "single" && isEmpty(object.formula_tree)
      ? { ...object, isHeader: true, temp_id }
      : { ...object, temp_id }
  return result
}

const dissocKeys = object => {
  if (object.children) object.children = object.children.map(child => dissocKeys(child))
  const result = compose(dissoc("isHeader"), dissoc("temp_id"), dissoc("path"))(object)
  return result
}

export const formatRowTree = (children, option) => {
  if (option === "dissoc") {
    return children.map(e => dissocKeys(e))
  }
  return children.map(e => {
    return assocKeys(e)
  })
}
