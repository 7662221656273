import { GetRowIdParams, RowValueChangedEvent } from "ag-grid-community"
import { AgGridReact } from "ag-grid-react"
import { pick } from "ramda"
import { useCallback, useRef } from "react"
import { useDispatch } from "react-redux"

import entriesDuck from "../../../../../state/modules/entries"
import { DateEditor, NumberEditor } from "../../../../_shared/agEditors"
import {
  DateFormatter,
  DelDataSetFormatter,
  NumberFormatter,
  StatusFormatter,
} from "../../../../_shared/agFormatters"

interface IRowData {
  comment: string | null
  dataset: number
  end: Date
  error: boolean
  id: number
  loading: boolean
  name: string
  name_for_custom: string | null
  release_date: Date
  slug: string
  source: string
  start: Date
  str_name: string
  unit_str: string
  value: number
}
interface IMainGrid {
  dataSet: Record<string, string | number | boolean | null>
  results: IRowData[]
  pct: boolean
}

const MainGrid = ({ dataSet, results, pct }: IMainGrid) => {
  const mainGridRef = useRef<AgGridReact | null>(null)

  const options = {
    reactNext: true,
    defaultColDef: {
      resizable: true,
    },
    columnDefs: [
      {
        headerName: "Status",
        field: "loading",
        cellRenderer: StatusFormatter,
        editable: false,
        suppressSizeToFit: true,
        width: 80,
      },
      {
        headerName: "Delete",
        field: "delete",
        cellRenderer: DelDataSetFormatter,
        editable: false,
        suppressSizeToFit: true,
        width: 80,
      },
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        filter: true,
        editable: false,
      },
      {
        headerName: "Start",
        field: "start",
        sortable: true,
        filter: true,
        valueFormatter: DateFormatter,
        cellEditor: "dateEditor",
        editable: !dataSet.aggregated,
      },
      {
        headerName: "Release",
        field: "release_date",
        sortable: true,
        filter: true,
        valueFormatter: DateFormatter,
        cellEditor: "dateEditor",
        editable: !dataSet.aggregated,
      },
      {
        headerName: "Value",
        field: "value",
        sortable: true,
        filter: true,
        valueFormatter: (params: { value: number }) =>
          NumberFormatter({ value: params.value, pct }),
        cellEditor: "numberEditor",
        editable: !dataSet.aggregated,
        cellStyle: { textAlign: "right" },
      },
    ],
    rowData: results,
    editType: "fullRow",
    getRowId: (params: GetRowIdParams) => params.data.id,

    components: {
      dateEditor: DateEditor,
      numberEditor: NumberEditor,
    },
  }

  const dispatch = useDispatch()
  const updateEntry = useCallback(
    (props: {
      entryId: number | string | boolean | null
      form: Record<string, string | number>
      idx: number | null
    }) => dispatch(entriesDuck.actions.updateEntry(props)),
    [dispatch],
  )

  const onRowValueChanged = ({ rowIndex, data }: RowValueChangedEvent) => {
    const form = pick(["dataset", "name", "start", "end", "release_date", "value"], data)
    updateEntry({ idx: rowIndex, entryId: data.id, form })
  }

  const sizeColumnsToFit = useCallback(() => {
    mainGridRef.current?.api?.sizeColumnsToFit()
  }, [])

  return (
    <div
      className="ag-theme-balham-dark"
      style={{
        margin: "0 auto",
        height: "100%",
        width: "90%",
      }}
    >
      <AgGridReact
        ref={mainGridRef}
        onRowValueChanged={onRowValueChanged}
        onFirstDataRendered={sizeColumnsToFit}
        {...options}
      />
    </div>
  )
}

export default MainGrid
