// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import profileDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

import shapeSheets from "./shapeSheets"

const {
  actions: { fetchSheetList, fetchSheetListOk, fetchSheetListError },
} = profileDuck

const fetchSheetDetailEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchSheetList.type),
    mergeMap(() => {
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/sheets/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          return fetchSheetListOk(shapeSheets(response))
        }),
        catchError(error => {
          if (error.status === 404) {
            return of(fetchSheetListError({ error: error.response.detail }))
          }
          if (error.status === 500) {
            return of(fetchSheetListError({ error }))
          }
          return of(fetchSheetListError(error.xhr.response))
        }),
      )
    }),
  )

export default fetchSheetDetailEpic
