// @ts-nocheck
import React from "react"
import { useSelector } from "react-redux"
import { Icon, NonIdealState } from "@blueprintjs/core"
import * as R from "ramda"

import UserDeskToRemove from "./UserDeskToRemove"

const RemoveUserDesksNotShared = () => {
  const userDesks = R.values(useSelector(R.pathOr({}, ["desks", "userDesks", "data"])))

  const allDesks = R.values(useSelector(R.pathOr([], ["desks", "desks", "data"])))

  const deskIdList = R.compose(
    R.values,
    R.map(desk => desk.id),
  )(allDesks)

  const desksIdinUserDesks = R.compose(
    R.values,
    R.map(userdesk => userdesk.desk),
  )(userDesks)
  const deskDiff = R.difference(desksIdinUserDesks, deskIdList)

  const userDeskToRemove = R.filter(e => R.includes(e.desk, deskDiff), userDesks)

  return (
    <div
      className="content"
      style={{
        margin: "0 auto",
        height: "100%",
        width: "100%",
      }}
    >
      <NonIdealState
        icon={"error"}
        description={
          deskDiff.length > 1 ? (
            <p>
              Desks below aren't shared anymore with you. Remove them from your visible desks by
              clicking button <Icon icon="remove" />.
            </p>
          ) : (
            <p>
              Desk below isn't shared anymore with you. Remove it from your visible desks by
              clicking button <Icon icon="remove" />.
            </p>
          )
        }
        title="Error"
      >
        <div>
          {userDeskToRemove.map(userDesk => (
            <UserDeskToRemove key={userDesk.desk} userDesks={userDesks} userDesk={userDesk} />
          ))}
        </div>
      </NonIdealState>
    </div>
  )
}

export default RemoveUserDesksNotShared
