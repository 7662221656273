// @ts-nocheck
import { useMemo } from "react"
import { pathOr } from "ramda"
import { useSelector } from "react-redux"

import { formatFieldFieldsList } from "../formatFieldFieldsList"

export const useStockCustomFields = (sourceId, isGroupSegs) => {
  const stCustomFields = useSelector(
    pathOr([], ["search", "omniByIds", "results", sourceId, "fieldFields"]),
  )
  const rfCustomFields = useSelector(
    pathOr([], ["search", "omniByIds", "results", sourceId, "stockParams", "fieldFields"]),
  )

  const isSourceReportForecast = sourceId && !isGroupSegs && sourceId.includes("rf")

  const stockCustomFieldsMemoized = useMemo(() => {
    return isSourceReportForecast
      ? formatFieldFieldsList(rfCustomFields)
      : formatFieldFieldsList(stCustomFields)
  }, [isSourceReportForecast, rfCustomFields, stCustomFields])

  return stockCustomFieldsMemoized
}
