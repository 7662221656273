// @ts-nocheck
import { Button, Classes } from "@blueprintjs/core"
import { FieldArray, Form } from "formik"
import update from "immutability-helper"
import * as R from "ramda"
import React from "react"
import { useDrop } from "react-dnd"

import itemTypes from "./itemTypes"
import RowItem from "./RowItem"

const Rows = ({ values, setValues, initialRow, handleClose, isValid }) => {
  const cards = values.rowDefs

  const handleCollapse = row =>
    setValues({
      rowDefs: R.map(
        x =>
          x.active
            ? R.dissoc("active", x)
            : x.rowId === row.rowId
            ? R.assoc("active", true, x)
            : R.dissoc("active", x),
        values.rowDefs,
      ),
    })

  const findCard = rowId => {
    const card = cards.filter(c => c.rowId === rowId)[0]
    return {
      card,
      index: cards.indexOf(card),
    }
  }

  const moveCard = (rowId, atIndex) => {
    const { card, index } = findCard(rowId)
    const newCards = update(cards, {
      $splice: [
        [index, 1],
        [atIndex, 0, card], // move tab from 1 to 0
      ],
    })
    const newValues = { rowDefs: newCards }
    return setValues(newValues)
  }
  const [, drop] = useDrop({ accept: itemTypes.CARD })

  return (
    <div>
      <Form>
        <FieldArray name="rowDefs">
          {arrayHelpers => {
            return (
              <div
                ref={drop}
                style={{
                  padding: "8px",
                  margin: "8px 0",
                  overflowY: "auto",
                  maxHeight: "700px",
                }}
              >
                {cards.map((card, index) => (
                  <RowItem
                    key={card.rowId}
                    card={card}
                    moveCard={moveCard}
                    findCard={findCard}
                    index={index}
                    rowId={card.rowId}
                    arrayHelpers={arrayHelpers}
                    handleCollapse={handleCollapse}
                    values={values}
                    setValues={setValues}
                  />
                ))}
              </div>
            )
          }}
        </FieldArray>

        <div style={{ margin: "0 8px" }}>
          <FieldArray name="rowDefs">
            {arrayHelpers => {
              const pushRow = () => {
                setValues({ rowDefs: R.map(R.dissoc("active"), values.rowDefs) })
                arrayHelpers.push(initialRow())
              }
              return <Button fill icon="plus" onClick={pushRow} text="Add row" />
            }}
          </FieldArray>
        </div>

        <div className={Classes.DIALOG_FOOTER} style={{ margin: "10px" }}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button icon="remove" text="Cancel" onClick={handleClose} />
            <Button
              disabled={!isValid}
              type="submit"
              intent="primary"
              icon="floppy-disk"
              text="Save"
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Rows
