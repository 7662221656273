// @ts-nocheck
import React from "react"
import { H3 } from "@blueprintjs/core"
import { Grid } from "react-flexbox-grid"
import FormatResults from "./FormatResults"
import { Callout } from "../../../../../../components"

const RegResults = ({ analyse }) => {
  if (analyse.complete)
    return (
      <Grid fluid>
        <H3>Results</H3>
        <FormatResults analyse={analyse} />
      </Grid>
    )
  if (analyse.regressor_failed)
    return (
      <Callout
        icon="predictive-analysis"
        intent="danger"
        title="Regressor failed:"
        body={analyse.regressor_failed}
      />
    )
}

export default RegResults
