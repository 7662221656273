// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { Button, ButtonGroup, Classes, H2, H4, Icon, Popover } from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"

import { FlexContainer } from "../../../../../../../../../styled/flexbox.styled"
import { pluralize } from "../../../../../../../../../utils"
import NodeListByCategory from "./NodeListByCategory"
import {
  Aside,
  CircleIcon,
  DashedCircleIcon,
  Footer,
  LegendText,
  NodesContainer,
  Separation,
  h2Css,
} from "./Toolbar.styled"

const Toolbar = ({ nodeDefs, onSave, validateFormulaTree, setOpenFormulaBuilder }) => {
  const [showCategory, setShowCategory] = useState([])
  const [errors, setErrors] = useState([])
  const hasErrors = !R.isEmpty(errors)
  const clearErrors = () => setErrors([])

  const handleSave = () => {
    const errors = validateFormulaTree()
    if (!R.isEmpty(errors)) {
      setErrors(errors)
    } else {
      onSave()
      setOpenFormulaBuilder(false)
    }
  }

  return (
    <Aside>
      <NodesContainer>
        <H2 align="center" css={h2Css}>
          Nodes
        </H2>
        {R.compose(
          R.map(cat => (
            <NodeListByCategory
              key={cat.id}
              category={cat}
              showCategory={showCategory}
              setShowCategory={setShowCategory}
            />
          )),
          R.filter(R.prop("show")),
        )(nodeDefs.categories)}
      </NodesContainer>
      <Footer>
        <Separation />
        <ButtonGroup fill>
          <Button text="Cancel" onClick={() => setOpenFormulaBuilder(false)} />
          <Popover
            isOpen={hasErrors}
            onClosed={clearErrors}
            popoverClassName={Classes.POPOVER_CONTENT_SIZING}
            placement="top-start"
            content={
              <div>
                <H4>The following {pluralize("error", errors.length)} must be solved:</H4>
                <ul>
                  {errors.map(errNode => {
                    const endStringArray = []
                    const errSourceLength = errNode.errSources.length
                    errSourceLength &&
                      endStringArray.push(
                        `${errSourceLength} input ${pluralize("connection", errSourceLength)}`,
                      )
                    const errTargetLength = errNode.errTargets.length
                    errTargetLength &&
                      endStringArray.push(
                        `${errTargetLength} output ${pluralize("connection", errTargetLength)}`,
                      )
                    return (
                      <li key={errNode.id}>
                        <strong>{errNode.data.title}</strong> node is missing{" "}
                        {endStringArray.join(" and ")}.
                      </li>
                    )
                  })}
                </ul>
                <Button fill onClick={clearErrors} intent="danger" text="OK" />
              </div>
            }
          >
            <Button
              text="Validate"
              intent={hasErrors ? "danger" : "primary"}
              onClick={handleSave}
              disabled={hasErrors}
            />
          </Popover>
        </ButtonGroup>
        <Separation />
        <FlexContainer flexDirection="column" gap="10px">
          <FlexContainer>
            <Icon icon="info-sign" color="black" />
            <LegendText>To delete a node or a edge, select it and press backspace key.</LegendText>
          </FlexContainer>
          <FlexContainer>
            <CircleIcon />
            <LegendText>Mandatory Link</LegendText>
          </FlexContainer>
          <FlexContainer>
            <DashedCircleIcon />
            <LegendText>Optional Link</LegendText>
          </FlexContainer>
          <FlexContainer>
            <Icon icon="many-to-one" color="white" />
            <LegendText>Accepts multiple links</LegendText>
          </FlexContainer>
        </FlexContainer>
      </Footer>
    </Aside>
  )
}

export default Toolbar
