// @ts-nocheck
import * as R from "ramda"

const reduceRightIndexed = R.addIndex(R.reduceRight)

export const concatWithSeparator = lists =>
  R.compose(
    reduceRightIndexed((list, acc, index, newList) => {
      const realIndex = newList.length - index - 1
      return R.concat(newList.length > 1 && realIndex !== 0 ? ["separator", ...list] : list, acc)
    }, []),
    R.reject(R.isEmpty),
  )(lists)

export const contextMenu = ({ params, setEdit, userId, deskOwnerId, hasWritePermission }) => {
  const { node } = params

  const rowId = node ? R.propOr(-1, "id", node) : -1
  const desc = node && node.data.desc

  const defaultItems = params.defaultItems || []

  const rowMenuItems =
    node && rowId !== -1
      ? [
          (userId === deskOwnerId || hasWritePermission) && {
            name: `Edit row: ${desc}`,
            action: () => setEdit({ rowId, show: true, add: false }),
            icon: "<span icon='edit' class='bp5-icon bp5-intent-primary bp5-icon-edit' />",
          },
        ]
      : []

  const insertMenuItems =
    rowId === -1
      ? [
          (userId === deskOwnerId || hasWritePermission) && {
            name: "Edit rows",
            icon: "<span icon='edit' class='bp5-icon bp5-intent-primary bp5-icon-edit' />",
            action: () => setEdit({ rowId, show: true, add: false }),
          },
        ]
      : []

  return concatWithSeparator([rowMenuItems, insertMenuItems, defaultItems])
}
