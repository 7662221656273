// @ts-nocheck
import React from "react"

import { Grid } from "../../../../../components"
import { InputRow } from "./components"

const CustomFields = ({ values, reportData: { prevReport, prevYearReport }, handleChange }) => {
  return (
    <Grid fluid>
      {values.fields.map(field => {
        return (
          <InputRow
            key={field.slug}
            title={field.name}
            basePath={["fields", `slug:${field.slug}`, "value"]}
            commentPath={["fields", `slug:${field.slug}`, "comment"]}
            values={values}
            prevReport={prevReport}
            prevYearReport={prevYearReport}
            handleChange={handleChange}
            comment
          />
        )
      })}
    </Grid>
  )
}

export default CustomFields
