// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import desksDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { setUserDesks, setUserDesksOk, setUserDesksError },
} = desksDuck

const setUserDesksEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(setUserDesks.type),
    mergeMap(action => {
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/userdesks/`,
        method: "PATCH",
        body: action.payload.userDesks,
        crossDomain: true,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          return setUserDesksOk({ payload: response })
        }),
        catchError(error => {
          return of(setUserDesksError(error.xhr.response))
        }),
      )
    }),
  )

export default setUserDesksEpic
