import styled from "@emotion/styled"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

export const UpdateMessageContainer = styled(FlexContainer)({
  height: "100%",
  width: "100%",
  border: `1.5px solid ${SCIENT_COLORS.gray4}`,
  borderRadius: "12px 12px 0 12px",
})
