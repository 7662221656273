// @ts-nocheck
import * as R from "ramda"
import { isObjectArray, isStringArray } from "../../arrayUtils"

export const formatSingleAndStr = name =>
  R.mapObjIndexed((value, key) => {
    if (Number.isFinite(value)) {
      return [value]
    }
    if (key === "str_name") {
      return R.compose(
        R.join(">"),
        R.update(1, name),
        R.split(">"),
        R.when(Array.isArray, R.head),
      )(value)
    }
    if (isStringArray(value) && value.length > 0) {
      return R.uniq(value).toString()
    }

    if (isObjectArray(value)) {
      return R.map(formatSingleAndStr(name)(R.__), value)
    }

    if (R.equals("Object", R.type(value))) {
      return formatSingleAndStr(name)(value)
    }
    return value
  })
