// @ts-nocheck
import * as R from "ramda"

import { useSelector } from "react-redux"

const createSharedSplitFields = (name, fields, source, options) => {
  const splitList = R.propOr({}, "splitFields", source)
  const hasMainSplit = !R.isEmpty(splitList) && R.find(R.propEq("slug", "main"))(splitList)
  const hasSecondarySplit =
    !R.isEmpty(splitList) && R.find(R.propEq("slug", "secondary"))(splitList)

  const mainSegs = R.compose(
    R.map(seg => [
      { type: "title2", text: seg.label },
      ...fields.map(field => ({
        path: R.replace("]", `,'${field.key}']`, seg.key),
        text: `${seg.text} ${field.text}`,
      })),
    ]),
    R.prop("segs"),
    R.find(R.propEq("name", "Main")),
    R.prop("splitFields"),
  )

  const secondarySegs = R.compose(
    R.map(e => [
      { type: "title2", text: e.text },
      ...fields.map(field => ({
        path: R.replace("]", `,'${field.key}']`, e.key),
        text: e.text + field.text,
      })),
    ]),
    R.prop("segs"),
    R.find(R.propEq("name", "Secondary")),
    R.propOr({}, "splitFields"),
  )

  return !R.isEmpty(splitList)
    ? R.flatten([
        hasMainSplit ? { type: "title1", text: `Main split ${name}` } : [],
        hasMainSplit ? mainSegs(source, options) : [],
        hasSecondarySplit ? { type: "title1", text: `Secondary split ${name}` } : [],
        hasSecondarySplit ? secondarySegs(source, options) : [],
      ])
    : []
}

const createFieldFields = R.cond([
  [
    R.prop("fieldFields"),
    R.compose(
      R.map(({ key, text }) => ({
        path: key,
        text: text,
      })),
      R.prop("fieldFields"),
    ),
  ],
])

export const useStockFields = (
  src,
  sourceOrigin,
  options = { timeAware: true, nonTimeAware: false },
) => {
  const fieldList = useSelector(R.pathOr(null, ["shared", "fieldList"]))
  const fields = stockFields(src, fieldList, sourceOrigin, options)

  return fields
}

export const stockFields = (src, fieldList, sourceOrigin, options) => {
  if (!fieldList) return null

  const source = src.kind === "st" ? src : src.stockParams

  const { shared_fields, report_fields, valuation_fields } = fieldList
  const { sales_fields, orders_fields, profit_fields, backlog_fields } = shared_fields
  const { is_fields, bs_fields, cf_fields } = report_fields

  if (["primary", "loopingGroup"].includes(sourceOrigin)) {
    return [
      { type: "title1", text: `Sales` },
      ...sales_fields.map(row => ({ text: `${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: `Orders` },
      ...orders_fields.map(row => ({ text: `${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: `Profit` },
      ...profit_fields.map(row => ({ text: `${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: `Backlog` },
      ...backlog_fields.map(row => ({
        text: `${row.text}`,
        path: `['${row.key}']`,
      })),

      { type: "title1", text: "IncomeStatement" },
      ...is_fields.map(row => ({ text: `[IS] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Balancesheet" },
      ...bs_fields.map(row => ({ text: `[BS] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Cashflow" },
      ...cf_fields.map(row => ({ text: `[CF] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Valuation" },
      ...valuation_fields
        .filter(row => row.time_aware !== null)
        .map(row => ({
          text: `[VAL] ${row.text}`,
          path: `['VAL_${row.key}']`,
        })),
    ]
  }

  return [
    { type: "title1", text: "Group Sales" },
    ...sales_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),
    ...createSharedSplitFields("sales", sales_fields, source, options),

    { type: "title1", text: "Group Orders" },
    ...orders_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),
    ...createSharedSplitFields("orders", orders_fields, source, options),

    { type: "title1", text: "Group Profit" },
    ...profit_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),
    ...createSharedSplitFields("profit", profit_fields, source, options),

    { type: "title1", text: "Group Backlog" },
    ...backlog_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),
    ...createSharedSplitFields("backlog", backlog_fields, source, options),

    { type: "title1", text: "Fields" },
    ...createFieldFields(source),

    { type: "title1", text: "IncomeStatement" },
    ...is_fields.map(row => ({ text: `[IS] ${row.text}`, path: `['${row.key}']` })),

    { type: "title1", text: "Balancesheet" },
    ...bs_fields.map(row => ({ text: `[BS] ${row.text}`, path: `['${row.key}']` })),

    { type: "title1", text: "Cashflow" },
    ...cf_fields.map(row => ({ text: `[CF] ${row.text}`, path: `['${row.key}']` })),

    { type: "title1", text: "Valuation" },
    ...valuation_fields
      .filter(row => row.time_aware !== null)
      .map(row => ({
        text: `[VAL] ${row.text}`,
        path: `['VAL_${row.key}']`,
      })),
  ]
}
