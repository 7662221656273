// @ts-nocheck
import { curry, isEmpty, isNil } from "ramda"

export const removePointsBefore = curry((lareportedPoint, data) => {
  if (isNil(data) || isEmpty(data)) return null
  let newData = data
  if (typeof data === "string") {
    newData = JSON.parse(data)
  }

  return [lareportedPoint, ...newData.filter(pt => pt[0] > lareportedPoint[0])]
})
