// @ts-nocheck
import { Button, Tooltip } from "@blueprintjs/core"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../state/modules/sheets"

export const RefreshButton = ({ sheet }) => {
  const dispatch = useDispatch()
  const reloadSheet = () => dispatch(sheetsDuck.actions.fetchSheetDetail({ id: sheet.id }))

  return (
    <Tooltip content="Reload sheet" placement="bottom" openOnTargetFocus={false} compact>
      <Button icon="refresh" onClick={reloadSheet}></Button>
    </Tooltip>
  )
}
