/** @jsxImportSource @emotion/react */

import { Tab, Tabs } from "@blueprintjs/core"
import { useField, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { Sector } from "../../../../api/world"
import { IdeaPosition } from "../types/business"
import { IIdeaCreateForm } from "./IdeaCreate"
import SectorPanel from "./SectorPanel"
import { sectorTabsCss } from "./SectorTabs.styled"

interface ISectorTabsProps {
  sectors: Sector[]
  fieldName: string
  disabled: boolean
}

export const SectorTabs = ({ sectors, fieldName, disabled }: ISectorTabsProps) => {
  /**
   * Represent all the different levels for a sector
   */
  const sectorTabs = [
    { id: 4, title: "Indexes" },
    { id: 1, title: "Level 1" },
    { id: 2, title: "Level 2" },
    { id: 3, title: "Level 3" },
  ]
  const [field] = useField(fieldName)
  const { setFieldValue, initialValues } = useFormikContext<Partial<IIdeaCreateForm>>()
  /**
   * Remove the value when we don't render the sector
   * tabs.
   * (Meaning that either long or short source is Sector)
   */
  useEffect(() => {
    const shortOrLong = field.name.split(".")[1] as IdeaPosition
    if (!field.value && initialValues.trade && initialValues.trade[shortOrLong]?.instance.id) {
      setFieldValue(field.name, initialValues.trade[shortOrLong]?.instance.id)
    }
    return () => {
      setFieldValue(field.value, undefined)
    }
  }, [field.name, field.value, setFieldValue, initialValues])

  const [selectedTabId, setSelectedTabId] = useState(4)
  const [sectorTabsTouched, setSectTabsTouched] = useState(false)

  useEffect(() => {
    const shortOrLong = fieldName.split(".")[1] as IdeaPosition
    if (
      !sectorTabsTouched &&
      initialValues.trade &&
      initialValues.trade[shortOrLong]?.instance.id
    ) {
      const level = sectors.find(
        sector =>
          initialValues.trade && sector.id === initialValues.trade[shortOrLong]?.instance.id,
      )?.level
      level && setSelectedTabId(level)
    }
  }, [sectors, fieldName, initialValues, sectorTabsTouched])

  return (
    <Tabs
      id="sectors"
      css={sectorTabsCss}
      selectedTabId={selectedTabId}
      onChange={newTabId => {
        setSectTabsTouched(true)
        setSelectedTabId(newTabId as number)
      }}
    >
      {sectorTabs.map(({ id, title }) => {
        return (
          <Tab
            id={id}
            key={id}
            title={title}
            disabled={disabled}
            panel={
              <SectorPanel
                sectors={sectors.filter(sector => sector.level === id)}
                name={field.name}
                disabled={disabled}
              />
            }
          />
        )
      })}
    </Tabs>
  )
}

export default SectorTabs
