import { addDays, addMonths, addWeeks, addYears } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

export const nthDate = (date: string, periodicity: string, nth: number) => {
  /**
   * We receive UTC date in ISO string with with time fixed to 00:00.
   * This date is the start day of a period (year, quarter, month...)
   * In order to do operation on it with date fns, we need to convert it to a Date object,
   * which keep the same time as UTC (we don't want to have change in day when convert to local).
   * To achieve that, we use utcToZonedTime from "date-fns-tz".
   *
   * Ex for case "M":
   *   if date = "2023-07-01T00:00:00.000Z"
   *   so localDate = "Sat Jul 01 2023 00:00:00 GMT-0700 (heure d’été du Pacifique nord-américain)"
   */
  const localDate = utcToZonedTime(new Date(date), "UTC")

  switch (periodicity) {
    case "Y":
    case undefined:
      return addYears(localDate, nth)
    case "H":
      return addMonths(localDate, nth * 6)
    case "Q":
      return addMonths(localDate, nth * 3)
    case "BM":
      return addMonths(localDate, nth * 2)
    case "M":
      return addMonths(localDate, nth)
    case "W":
      return addWeeks(localDate, nth)
    case "D":
      return addDays(localDate, nth)
    default:
      throw new Error("in nth date")
  }
}
