// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { Button, Classes, H5, Icon, Popover, Radio, RadioGroup, Tooltip } from "@blueprintjs/core"
import { useCallback } from "react"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../../state/modules/sheets"
import { SCIENT_COLORS } from "../../../../../../styled/scientColors"
import { MediumText } from "../../../../../../styled/text.styled"
import {
  H3Title,
  H4Title,
  IconContainer,
  iconStyle,
  RadioContainer,
  RadioTitleExplanation,
  TooltipContent,
} from "./SmartSelectOptions.styled"

export const NGTFSelectMethod = ({ sheet }) => {
  const dispatch = useDispatch()

  const { name } = sheet.ngtf_select_method

  const radioOptions = {
    ss: {
      label: "Most Granular",
      description:
        "Each timeseries is an aggregation of the smallest available frequency\
         for each year before being resampled to the target frequency.",
      example:
        "If sheet freq is Y and Q, H, Y timeseries are available, \
      Q will be picked for this specific year.",
    },
    fss: {
      label: "Exact or Granular",
      description:
        "Each timeseries is an aggregation of the target frequency for each year, \
        if not available we then retrieve the smallest one. After this selection \
        we resample to the target frequency.",
      example:
        "If sheet freq is Y and Q and Y timeseries are available. Y will be \
        picked if available (Otherwise we pick Q).",
    },
  }

  const handleClickSelect = useCallback(
    event => {
      const { value } = event.target
      dispatch(
        sheetsDuck.actions.updateSheet({
          id: sheet.id,
          ngtf_select_method: value,
        }),
      )
    },
    [sheet, dispatch],
  )

  return (
    <Popover
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      placement="bottom-end"
      content={
        <>
          <H5>Freq Conversion Strategy</H5>
          <RadioTitleExplanation>
            How the system should resample the selected timeseries to match the sheet frequency:
          </RadioTitleExplanation>
          <RadioContainer>
            <RadioGroup selectedValue={name} onChange={handleClickSelect}>
              {Object.keys(radioOptions).map(id => {
                return <Radio label={radioOptions[id].label} value={id} key={id} />
              })}
            </RadioGroup>
            <IconContainer>
              {Object.keys(radioOptions).map(id => {
                return (
                  <Tooltip
                    content={
                      <TooltipContent flexDirection="column" gap="10px">
                        <H3Title>Description:</H3Title>
                        <MediumText color={SCIENT_COLORS.darkGray5}>
                          {radioOptions[id].description}
                        </MediumText>
                        {radioOptions[id].example && (
                          <>
                            <H4Title>Example:</H4Title>
                            <MediumText color={SCIENT_COLORS.darkGray5}>
                              {radioOptions[id].example}
                            </MediumText>
                          </>
                        )}
                      </TooltipContent>
                    }
                    key={`tooltip-${id}`}
                    placement="right"
                    compact
                    openOnTargetFocus={false}
                  >
                    <Icon icon="help" key={`icon-${id}`} css={iconStyle} />
                  </Tooltip>
                )
              })}
            </IconContainer>
          </RadioContainer>
        </>
      }
    >
      <Tooltip
        content="Freq Conversion Strategy"
        placement="bottom"
        openOnTargetFocus={false}
        compact
      >
        <Button icon="join-table" />
      </Tooltip>
    </Popover>
  )
}
