// @ts-nocheck
import React, { useEffect } from "react"
import { clearStocks, searchStocks } from "../../state/modules/search"
import { useDispatch, useSelector } from "react-redux"

import { MenuItem } from "@blueprintjs/core"
import { Suggest } from "."
import { pathOr } from "ramda"

export const StockSuggest = ({ submit, placeholder, dataCy, large = true }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    return () => dispatch(clearStocks())
  }, [dispatch])

  const updateSearch = query =>
    query !== "" &&
    dispatch(
      searchStocks({
        search: query,
      }),
    )

  const stockList = useSelector(pathOr([], ["search", "stocks", "results"]))
  const searching = useSelector(pathOr(null, ["search", "stocks", "searching"]))

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        text={`${item.bloomberg_code} (${item.name})`}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }
  return (
    <Suggest
      dataCy={dataCy}
      placeholder={placeholder}
      loading={searching}
      resetOnClose={true}
      resetOnSelect={true}
      items={stockList}
      onItemSelect={submit}
      onQueryChange={updateSearch}
      itemRenderer={itemRenderer}
      large={large}
      fill
    />
  )
}
