import { ICellEditorParams } from "ag-grid-community"
import { forwardRef, memo, useImperativeHandle, useRef, useState } from "react"

const KEY_BACKSPACE = "Backspace"
const KEY_ENTER = "Enter"
const KEY_TAB = "Tab"

export const NumberEditor = memo(
  forwardRef((props: ICellEditorParams, ref) => {
    const [value, setValue] = useState(props.value ? props.value : "")
    const refInput = useRef<HTMLInputElement | null>(null)

    /* Utility Methods */
    const cancelBeforeStart =
      props.eventKey && props.eventKey.length === 1 && "1234567890".indexOf(props.eventKey) < 0

    const isLeftOrRight = (event: React.KeyboardEvent) => {
      return ["ArrowLeft", "ArrowLeft"].indexOf(event.key) > -1
    }

    const isCharNumeric = (charStr: string) => {
      return /^-?[\d.,]*$/.test(charStr)
    }

    const isNumericKey = (event: React.KeyboardEvent) => {
      const charStr = event.key
      return isCharNumeric(charStr)
    }

    const isBackspace = (event: React.KeyboardEvent) => {
      return event.key === KEY_BACKSPACE
    }

    const finishedEditingPressed = (event: React.KeyboardEvent) => {
      const key = event.key
      return key === KEY_ENTER || key === KEY_TAB
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
      if (isLeftOrRight(event) || isBackspace(event)) {
        event.stopPropagation()
        return
      }

      if (!finishedEditingPressed(event) && !isNumericKey(event)) {
        if (event.preventDefault) event.preventDefault()
      }

      if (finishedEditingPressed(event)) {
        props.stopEditing()
      }
    }

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return value === "" || value === null ? null : Number(value)
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return cancelBeforeStart
        },
      }
    })

    return (
      <div className="bp5-input-group bp5-fill">
        <input
          ref={refInput}
          className="bp5-input bp5-fill"
          value={value ? value : ""} //`value` prop on `input` should not be null. So we use an empty string.
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
          onKeyDown={(event: React.KeyboardEvent) => onKeyDown(event)}
        />
      </div>
    )
  }),
)
