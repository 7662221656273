// @ts-nocheck
import React, { useState } from "react"
import { compose, last, prop, reverse } from "ramda"
import { AgGridReact } from "ag-grid-react"

import { Grid, Row, Col } from "../../../../components"
import { NumberFormatter, DateFormatter } from "../../../../_shared/agFormatters"

const PastEntries = ({ dataSet: { unit }, entries: { results } }) => {
  const pct = unit ? unit.includes("pct") : false
  const entries = reverse(results)

  const colTitles = entries.map(entry => ({
    field: entry.name,
    cellStyle: { textAlign: "right" },
  }))
  const columnDefs = [
    { headerName: "PAST ENTRIES", field: "desc", pinned: "left", width: 150 },
    ...colTitles,
  ]
  const rowHeaders = ["start", "end", "release_date", "value"]
  const rowData = rowHeaders.map((header, index) => {
    const col = entries.reduce(
      (acc, entry) => {
        acc[entry.name] = [0, 1, 2].includes(index)
          ? DateFormatter({ value: entry[header] })
          : NumberFormatter({ value: entry[header], pct })
        return acc
      },
      { desc: header },
    )
    return col
  })

  const options = {
    reactNext: true,
    defaultColDef: {
      width: 100,
    },
    columnDefs,
    rowData,
  }

  const [, setGridApi] = useState({})
  const [, setGridColumnApi] = useState({})
  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const [wentToLastCol, setWentToLastCol] = useState(false)
  const onAnimationQueueEmpty = ({ api }) => {
    if (api && !wentToLastCol) {
      api.ensureColumnVisible(compose(prop("field"), last)(colTitles))
      setWentToLastCol(true)
    }
  }

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <div
            className="ag-theme-balham-dark"
            style={{
              margin: "0 auto",
              height: "160px",
              width: "100%",
            }}
          >
            <AgGridReact
              onGridReady={onGridReady}
              onAnimationQueueEmpty={onAnimationQueueEmpty}
              sizeColumnsToFit
              {...options}
            />
          </div>
        </Col>
      </Row>
    </Grid>
  )
}

export default PastEntries
