// @ts-nocheck
import React from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { includes } from "ramda"

import { PageHeader } from "../../../../components"
import { formatNumber } from "../../../../_shared"

const DataSetHeader = ({ dataSet, moreButtons = [] }) => {
  const lastValue = dataSet.last_entry
    ? `${formatNumber(dataSet.last_value, dataSet.unit_str && includes("pct", dataSet.unit_str))}`
    : "-"

  const { id } = useParams()

  const navigate = useNavigate()
  const location = useLocation()

  const toChart = {
    "data-cy": "ChartToEntries",
    text: "Chart",
    onClick: () => navigate(`/datasets/${id}/entries`),
    icon: "timeline-line-chart",
  }
  const toTable = {
    "data-cy": "EntriesToChart",
    text: "Table",
    onClick: () => navigate(`/datasets/${id}/entries/table`),
    icon: "th",
  }
  const toEdit = {
    "data-cy": "ToEdit",
    text: "Edit dataset",
    onClick: () => navigate(`/datasets/${id}`),
    icon: "cog",
  }
  const toForecasts = {
    "data-cy": "toForecasts",
    text: "Forecasts",
    onClick: () => navigate(`/datasets/${id}/forecasts`),
    icon: "series-derived",
  }

  const buttonProps = () => {
    if (location.pathname.includes("table"))
      return dataSet.aggregated ? [toChart, toForecasts] : [toEdit, toChart, toForecasts]
    if (location.pathname.includes("entries"))
      return dataSet.aggregated ? [toTable, toForecasts] : [toEdit, toTable, toForecasts]
    if (location.pathname.includes("forecasts"))
      return dataSet.aggregated ? [toTable, toChart] : [toEdit, toTable, toChart]
    return [toChart, toTable, toForecasts]
  }

  const subtitle = dataSet.bloomberg_code
    ? `Last val: ${lastValue} • ${dataSet.bloomberg_code}${dataSet.aggregated ? " • AGG" : ""}`
    : `Last val: ${lastValue}${dataSet.aggregated ? " • AGG" : ""}`
  return (
    <PageHeader
      title={dataSet.name}
      subtitle={subtitle}
      btnPropsList={[...moreButtons, ...buttonProps()]}
    />
  )
}

export default DataSetHeader
