// @ts-nocheck
import * as R from "ramda"

const searchDSForecastsReducers = {
  searchDSForecasts: R.assocPath(["dSForecasts", "searching"], true),
  searchDSForecastsOk: (state, { payload: { results } }) =>
    R.over(
      R.lensProp("dSForecasts"),
      R.compose(R.assoc("searching", false), R.assoc("results", results)),
      state,
    ),
  searchDSForecastsError: (state, { payload: { error } }) =>
    R.set(R.lensProp("dSForecasts"), { searching: false, error: error, results: [] }, state),
  clearDSForecasts: state =>
    R.set(R.lensProp("dSForecasts"), { searching: false, results: [] }, state),
}

export default searchDSForecastsReducers
