// @ts-nocheck
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"

export const controlGroupCss = css({
  display: "flex",
  marginTop: "2px",
  flexFlow: "row wrap",
})
export const cardCss = css({
  padding: "4px",
  marginBottom: "1px",
  background: "rgba(16, 22, 26, 0.3) !important",
  minHeight: "36px",
})
export const customTextInputCss = css({
  minWidth: "450px",
})
export const buttonAddCss = type =>
  css({
    backgroundColor: type === "dynamic" ? `${SCIENT_COLORS.gray1} !important` : "",
  })

export const HeaderItemContainer = styled.div(props => ({
  padding: "2px",
  opacity: props.isDragging ? 0 : 1,
}))

export const CenterTextStyled = styled.p({
  textAlign: "center",
})
