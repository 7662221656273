/** @jsxImportSource @emotion/react */
import { HTMLSelect, Icon, Intent } from "@blueprintjs/core"
import { useState } from "react"
import { UseControllerProps, UseFormWatch, useController } from "react-hook-form"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { LargeText } from "../../../../../styled/text.styled"
import { ConfirmDialog } from "../../../../components"
import { ScientTradingAccount } from "../../types"
import { WaveInputs } from "../types/form"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"

interface ISelectAccountProps extends UseControllerProps<WaveInputs> {
  accounts: ScientTradingAccount[]
  watch: UseFormWatch<WaveInputs>
  onChangeSelectedAccount: () => void
}

const SelectAccount = ({
  accounts,
  name,
  control,
  rules,
  watch,
  onChangeSelectedAccount,
}: ISelectAccountProps) => {
  const { field, fieldState } = useController({ control, name, rules })
  //State use to store account id when user changes of account and at least one book is already selected
  const [accountInDialog, setAccountInDialog] = useState("")

  //Watch the orders to know if at least one book is selected
  const isBookSelected = watch("orders").some(order => order.book_id)

  //Create select options from the accounts
  const accountsOptions = accounts.map(account => ({ label: account.name, value: account.id }))

  /**
   * Handle the change of the account
   */
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (isBookSelected) {
      //If user has already selected a book, we ask for confirmation
      setAccountInDialog(event.target.value)
    } else {
      //If user has not selected a book, we change the account
      field.onChange(event.target.value)
    }
  }

  /**
   * Confirm the changing of the account
   */
  const handleConfirmAccountSelection = () => {
    field.onChange(accountInDialog)
    onChangeSelectedAccount()
    setAccountInDialog("")
  }

  return (
    <FlexContainer gap="22px" alignItems="center">
      <ConfirmDialog
        title="Change account"
        dialogText="You're going to change account. This will reset the wave."
        open={accountInDialog !== ""}
        close={() => setAccountInDialog("")}
        handleClick={handleConfirmAccountSelection}
        confirmButtonText="Yes, change account and reset the wave"
        confirmButtonIntentColor={Intent.DANGER}
      />
      <Icon icon="git-repo" size={40} />

      <FlexContainer gap="5px" flexDirection="column">
        <FlexContainer justifyContent="space-between">
          <LargeText>Account</LargeText>
          {fieldState.error && <ErrorIconWithTooltip errorMessage={fieldState.error.message} />}
        </FlexContainer>

        <HTMLSelect
          value={field.value}
          options={
            accountsOptions.length === 1
              ? accountsOptions
              : [{ label: "", value: "" }, ...(accountsOptions || [])]
          }
          onChange={handleChange}
          disabled={accountsOptions.length === 1}
        />
      </FlexContainer>
    </FlexContainer>
  )
}

export default SelectAccount
