// @ts-nocheck
import { pathOr } from "ramda"

export const handleError = (setSubmitting, status, setStatus, error) => {
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    setStatus({
      ...status,
      errors: pathOr([error.message], ["response", "data", "non_field_errors"], error),
    })
    setSubmitting(false)
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    setStatus({ ...status, errors: ["Network error. Check your connection."] })
    setSubmitting(false)
  } else {
    // Something happened in setting up the request and triggered an Error
    setStatus({ ...status, errors: [error.message] })
    setSubmitting(false)
  }
}
