// @ts-nocheck
import { Button, ControlGroup, Tag } from "@blueprintjs/core"
import { format } from "date-fns"
import { path } from "ramda"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import profileDuck from "../../../../../state/modules/profile"
import { Col, Grid, Row } from "../../../../components"

import AddReport from "./AddReport"
import ConsensusDrawer from "./ConsensusDrawer"
import DiffMethod from "./DiffMethod"
import SearchStock from "./SearchStock"
import SelectConsensusFields from "./SelectConsensusFields"
import SelectDiff from "./SelectDiff"
import SelectForecast from "./SelectForecast"
import ThresholdDays from "./ThresholdDays"
import ViewType from "./ViewType"

const Header = ({ stockSlug }) => {
  const [drawer, setDrawer] = useState(false)
  const toggleDrawer = () => setDrawer(!drawer)

  const dispatch = useDispatch()
  const handleSetFreq = freq =>
    dispatch(profileDuck.actions.stockSetFreq({ slug: stockSlug, freq }))
  const handleSetDiffMethod = diffMethod =>
    dispatch(profileDuck.actions.stockSetDiffMethod({ slug: stockSlug, diffMethod }))

  /**
   * @todo: Profile is already deprecated on redux although we need to move the
   * userstocksconfig out of redux also in order to completely remove profile from redux
   */
  const { activeTab, freq, diffMethod, showEdit } = useSelector(
    path(["profile", "userstockconfigs", stockSlug]),
  )

  const { name, next_report_date, next_bbg_coord } = useSelector(
    path(["stockDetail", stockSlug, "stockData"]),
  )

  const placeholder = name
  const nextEarnings = `Next Earnings: ${next_bbg_coord.year}${
    next_bbg_coord.periods
  } released on ${format(new Date(next_report_date), "dd/MM/yyyy")}`

  return (
    <Grid fluid style={{ width: "97%", margin: "0 auto", maxWidth: "1920px" }}>
      <Row center="xs" middle="xs" style={{ marginBottom: "5px" }}>
        <Col xs>
          <Row center="xs" style={{ margin: "0" }}>
            <Col xs>
              <Tag minimal>{nextEarnings}</Tag>
            </Col>
          </Row>
          <Row
            style={{
              margin: "auto",
              maxWidth: "300px",
            }}
          >
            <Col xs>
              <ControlGroup fill>
                <SearchStock placeholder={placeholder} />
              </ControlGroup>
            </Col>
          </Row>
        </Col>

        <Col xs>
          {activeTab === "consensus" ? (
            <ThresholdDays dispatch={dispatch} stockSlug={stockSlug} />
          ) : (
            <ViewType freq={freq} handleSetFreq={handleSetFreq} />
          )}
        </Col>

        <Col xs>
          {activeTab === "consensus" ? (
            <SelectConsensusFields dispatch={dispatch} stockSlug={stockSlug} />
          ) : (
            <SelectForecast stockSlug={stockSlug} />
          )}
        </Col>

        {activeTab === "consensus" && (
          <>
            <Col xs={3}>
              <SelectDiff stockSlug={stockSlug} dispatch={dispatch} />
            </Col>
            <Col xs>
              <DiffMethod diffMethod={diffMethod} handleSetDiffMethod={handleSetDiffMethod} />
            </Col>
          </>
        )}

        {activeTab === "consensus" && (
          <Col xs>
            <ConsensusDrawer
              dispatch={dispatch}
              isOpen={drawer}
              onClose={toggleDrawer}
              stockSlug={stockSlug}
            />

            <Button
              onClick={toggleDrawer}
              text={"Consensus Settings"}
              style={{ margin: "28px auto 8px" }}
            />
          </Col>
        )}

        {showEdit && activeTab !== "consensus" && (
          <Col xs>
            <AddReport stockSlug={stockSlug} dispatch={dispatch} />
          </Col>
        )}
      </Row>
    </Grid>
  )
}

export default Header
