// @ts-nocheck
import { pick, prop } from "ramda"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Route, Routes, useParams, Outlet } from "react-router-dom"

import dataSetsDuck from "../../../../state/modules/dataSets"
import dsForecastsDuck from "../../../../state/modules/dsForecasts"
import { Loader } from "../../../components"
import Edit from "./Edit"
import Forecasts from "./Forecasts"
import Graph from "./Graph"
import Table from "./Table"

const DataSetDetail = ({
  dataSets,
  entries,
  dsForecasts,
  fetchDataSet,
  fetchAllForecasts,
  fetchForecasts,
}) => {
  const { id } = useParams()
  useEffect(() => {
    fetchDataSet({ id: id })
    fetchForecasts({ dataset: id })
    fetchAllForecasts()
  }, [fetchAllForecasts, fetchDataSet, fetchForecasts, id])

  if (dataSets.fetching) {
    return <Loader title="Loading DataSet" />
  }
  if (entries.fetching) {
    return <Loader title="Loading Entries" />
  }
  return (
    <div className="content">
      <Outlet />
      <Routes>
        <Route
          path="entries/table"
          element={<Table entries={entries.entries} dataSet={dataSets.dataSet} />}
        />
        <Route
          path="entries"
          element={
            <Graph entries={entries.entries} dataSet={dataSets.dataSet} dsForecasts={dsForecasts} />
          }
        />
        <Route
          path="forecasts"
          element={<Forecasts entries={entries.entries} dataSet={dataSets.dataSet} />}
        />
        <Route path="*" element={<Edit dataSet={dataSets.dataSet} />} />
      </Routes>
    </div>
  )
}

const mapStateToProps = ({ entries, dataSets, dsForecasts }) => ({
  entries: entries,
  dataSets: pick(["dataSet", "fetching"])(dataSets),
  dsForecasts: prop(["dsForecasts"])(dsForecasts),
})

export default connect(mapStateToProps, { ...dataSetsDuck.actions, ...dsForecastsDuck.actions })(
  DataSetDetail,
)
