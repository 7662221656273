// @ts-nocheck
import { Button, Collapse, ControlGroup, H1, H6, Icon, Tooltip } from "@blueprintjs/core"
import { Form, Formik } from "formik"
import * as R from "ramda"
import { useEffect, useState } from "react"
import { addNodeUnderParent } from "react-sortable-tree"
import { shallowEqual } from "recompose"

import { SCIENT_COLORS } from "../../../../../../../../../styled/scientColors"
import { MediumText } from "../../../../../../../../../styled/text.styled"
import { randomid } from "../../../../../../../../../utils"
import { updateRowTree, useEnhancedPath } from "../../../../utils"
import { initialFormulaTreeData } from "../../FormulaBuilder/utils"
import { getCustomFieldSlugList, getLoopParams } from "../../utils"
import { useCustomFields } from "../../utils/hooks"
import { GroupName } from "./GroupName"
import { LoopBuilder } from "./LoopBuilder"
import { SegLoopBuilder } from "./SegLoopBuilder"

export const Group = ({
  activeItem,
  setActiveItem,
  st_sources,
  rf_sources,
  rowTree,
  setRowTree,
  selectNode,
  setGroupNotComplete,
}) => {
  // If active item is already an existing looping group, loopingFeatures is opened
  // and button to choose kind of group (lopping or not) is disabled
  const [loopingFeatures, setLoopingFeatures] = useState(false)
  const toogleLoopingFeatures = () => setLoopingFeatures(!loopingFeatures)

  const [noLoopingGroup, setNoLoopingGroup] = useState(false)
  const toogleNoLoopingGroup = () => setNoLoopingGroup(!noLoopingGroup)

  const enhancedPath = useEnhancedPath(activeItem.path, st_sources, rf_sources)

  const hasSplitAncestor =
    enhancedPath.splits && !activeItem.temp_id.includes("splits") ? true : false
  const isGroupSegs = enhancedPath.segs ? true : false

  useEffect(() => {
    setLoopingFeatures(activeItem.loop_on || hasSplitAncestor ? true : false)
  }, [activeItem.loop_on, hasSplitAncestor])

  useEffect(() => {
    setNoLoopingGroup(!activeItem.loop_on && activeItem.name ? true : false)
  }, [activeItem.loop_on, activeItem.name])

  const loopParams = getLoopParams({ activeItem, loopOnSegs: isGroupSegs })

  // Logic for existing row node when one or many customFields are selected
  const isCustomFieldsChoiceSelected =
    loopParams.kind === "fields" &&
    loopParams.slugs &&
    !R.includes(loopParams.slugs, ["'active': 1", "'all': 1"])

  const customFields = useCustomFields({
    sourceId: loopParams.src,
    srcKind: loopParams.srcKind,
    isCustomFieldsChoiceSelected,
    isGroupSegs,
  })

  // Extract the list of CustomFieldKey from 'loopParams.slugs' string
  const selectedCustomFieldKeyList = isCustomFieldsChoiceSelected
    ? getCustomFieldSlugList(loopParams.slugs)
    : []

  const notSelectedCustomFields = isCustomFieldsChoiceSelected
    ? R.reject(e => selectedCustomFieldKeyList.includes(e.key), customFields)
    : []
  const selectedCustomFields = isCustomFieldsChoiceSelected
    ? R.filter(e => selectedCustomFieldKeyList.includes(e.key), customFields)
    : []
  // END

  const initialValues = {
    ...activeItem,
    loopParams: {
      ...loopParams,
      srcName: enhancedPath.srcName ? enhancedPath.srcName : "",
      srcTicker: enhancedPath.srcTicker ? enhancedPath.srcTicker : "",
      notSelectedCustomFields,
      selectedCustomFields,
      customFields,
    },
  }

  return (
    <div className="sct-item-fields">
      <H1 style={{ textAlign: "center" }}>Group</H1>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={rawValues => {
          // When add child, need to use the newRowTree and the newPath
          const addSingleUnderParent = ({ type, newRowTree, newPath }) => {
            const newNode = {
              temp_id: type + "|" + randomid(),
              header: "",
              kind: "single",
              frontOpts: type === "data" ? { decimals: { max: 2, min: 2 } } : {},
              formula_tree: type === "data" ? initialFormulaTreeData : {},
              isHeader: type === "header" ? true : false,
            }
            setRowTree(
              values =>
                addNodeUnderParent({
                  treeData: newRowTree,
                  parentKey: newPath[newPath.length - 1],
                  expandParent: true,
                  getNodeKey: ({ node }) => node.temp_id,
                  newNode,
                  addAsFirstChild: values.addAsFirstChild,
                }).treeData,
            )
            selectNode(newNode, newPath)
          }

          if (rawValues.loopParams.kind) {
            // for loopinGroup
            const isCustomFieldsChoiceSelected =
              rawValues.loopParams.kind === "fields" &&
              rawValues.loopParams.slugs &&
              !R.includes(rawValues.loopParams.slugs, ["'active': 1", "'all': 1"])

            const sourceId =
              rawValues.loopParams.src === "stsrc0"
                ? rawValues.loopParams.src
                : `'${rawValues.loopParams.src}'` // source needs single quote around it for back, except if it's primary source

            // From loopParams, a new_temp_id is generated and stored in a key 'new_temp_id'
            // then function updateRowTree replaces the existing temp_id by the new_temp_id
            // and replaces the last element of the path by this new_temp_id
            // And loop_on is set from the loopParams
            const new_temp_id =
              rawValues.loopParams.kind === "segs"
                ? `${rawValues.loopParams.kind}{${rawValues.loopParams.slugs}}|${randomid()}`
                : `${rawValues.loopParams.kind}[${rawValues.loopParams.src}]{${
                    rawValues.loopParams.slugs
                  }}|${randomid()}`

            //Tranform list of selectedCustomFields in a string
            const customFieldSlugsString =
              isCustomFieldsChoiceSelected &&
              R.join(
                ",",
                R.map(e => `'${e.key}'`, rawValues.loopParams.selectedCustomFields),
              )

            // Build loopParamsSlugs for use in new_loop_on
            const loopParamsSlugs = isCustomFieldsChoiceSelected
              ? `'slugs': [${customFieldSlugsString}]`
              : rawValues.loopParams.slugs

            // Build the loop_on string
            const new_loop_on =
              rawValues.loopParams.kind === "segs"
                ? `prevloop([${rawValues.loopParams.kind}({${rawValues.loopParams.slugs}})])`
                : `prevloop([${sourceId}, ${rawValues.loopParams.kind}({${loopParamsSlugs}})])`

            // Use to update the rowTree.
            const newRawValues = {
              ...R.dissoc("loopParams", rawValues),
              loop_on: new_loop_on,
              new_temp_id,
            }
            const newRowTree = updateRowTree({
              rowTree,
              activeItem: newRawValues,
            })

            const newPath =
              rawValues.loopParams.kind === "segs"
                ? R.append(newRawValues.new_temp_id, rawValues.path)
                : R.update(
                    newRawValues.path.length - 1,
                    newRawValues.new_temp_id,
                    newRawValues.path,
                  )

            // When add child, need to use the newRowTree and the newPath
            const addGroupUnderParent = () => {
              const newNode = {
                temp_id: "newGroup" + "|" + randomid(),
                children: [],
                kind: "group",
                loop_on: "",
                name: "",
              }
              setRowTree(
                values =>
                  addNodeUnderParent({
                    treeData: newRowTree,
                    parentKey: newPath[newPath.length - 1],
                    expandParent: true,
                    getNodeKey: ({ node }) => node.temp_id,
                    newNode,
                    addAsFirstChild: values.addAsFirstChild,
                  }).treeData,
              )
              setGroupNotComplete(true)
              selectNode(newNode, newPath)
            }

            // if group is splits, will add a newGroup as child. Else, if group is fields or segs, will add a single Data as child
            // add child only if group has no children
            const addChild = () => {
              return rawValues.loopParams.kind === "splits"
                ? (rawValues.temp_id.includes("newGroup") || rawValues.children.length === 0) &&
                    addGroupUnderParent()
                : (rawValues.temp_id.includes("newGroup") || rawValues.children.length === 0) &&
                    addSingleUnderParent({ type: "data", newRowTree, newPath })
            }

            setGroupNotComplete(false)
            setRowTree(newRowTree)
            setActiveItem("")
            addChild()
          } else {
            // for no looping group
            const newRawValues = {
              ...R.dissoc("loopParams", rawValues),
              new_temp_id: `group|${randomid()}`,
            }
            const newPath = R.update(
              newRawValues.path.length - 1,
              newRawValues.new_temp_id,
              newRawValues.path,
            )
            const newRowTree = updateRowTree({
              rowTree,
              activeItem: newRawValues,
            })

            // add child only if group has no children
            const addChild = () => {
              return (
                (rawValues.temp_id.includes("newGroup") || rawValues.children.length === 0) &&
                addSingleUnderParent({ type: "data", newRowTree, newPath })
              )
            }

            setRowTree(newRowTree)
            setActiveItem("")
            addChild()
            setGroupNotComplete(false)
          }
        }}
      >
        {({ initialValues, values, setValues, handleSubmit }) => {
          // Test if this node has been modified by comparing initialValues and values
          const changeInActiveItem = !shallowEqual(initialValues, values)

          return (
            <Form>
              <br />
              <ControlGroup style={{ marginLeft: "auto", marginRight: "auto" }} fill>
                {loopingFeatures === false && (
                  <>
                    <Button
                      disabled={noLoopingGroup && activeItem.name}
                      onClick={toogleNoLoopingGroup}
                      text={
                        noLoopingGroup
                          ? "Close no looping group and go back to group choice"
                          : "No looping group"
                      }
                      large
                      fill
                    />
                    <Tooltip
                      content={
                        <div>
                          <H6 style={{ marginBottom: "5px" }}>
                            No looping group works like folder
                          </H6>
                          <MediumText color={SCIENT_COLORS.darkGray5}>
                            You can group inside many Data or Header
                          </MediumText>
                        </div>
                      }
                      placement="left"
                      openOnTargetFocus={false}
                      compact
                    >
                      <Icon icon="help" size={20} style={{ margin: "0 8px" }} />
                    </Tooltip>
                  </>
                )}
                {noLoopingGroup === false && (
                  <>
                    <Button
                      disabled={(loopingFeatures && activeItem.loop_on) || hasSplitAncestor}
                      onClick={toogleLoopingFeatures}
                      text={
                        activeItem.loop_on || hasSplitAncestor
                          ? "Looping features"
                          : loopingFeatures
                          ? "Close looping features and go back to group choice"
                          : "Open looping features"
                      }
                      large
                      fill
                    />
                    <Tooltip
                      content={
                        <div>
                          <H6 style={{ marginBottom: "5px" }}>
                            Looping features let you add multiple rows with a few clicks.
                          </H6>
                          <MediumText color={SCIENT_COLORS.darkGray5}>
                            For example, you can add one or more fields (sales, profit...):
                            <br /> &nbsp;&nbsp;&nbsp;- for each segment of a stock
                            <br /> &nbsp;&nbsp;&nbsp;- for each stock in a group of stocks
                            <br /> Or you can add multiple customfields.
                            <br />
                          </MediumText>
                        </div>
                      }
                      placement="bottom"
                      openOnTargetFocus={false}
                      compact
                    >
                      <Icon icon="help" size={20} style={{ marginLeft: "8px" }} />
                    </Tooltip>
                  </>
                )}
              </ControlGroup>

              <Collapse isOpen={noLoopingGroup}>
                <br />
                <GroupName
                  noLoopingGroup={noLoopingGroup}
                  handleSubmit={handleSubmit}
                  values={values}
                />
              </Collapse>

              {loopingFeatures &&
                (hasSplitAncestor ? (
                  <SegLoopBuilder // to choose Segments when looping in Split is selected
                    values={values}
                    setValues={setValues}
                    handleSubmit={handleSubmit}
                    activeItem={activeItem}
                    changeInActiveItem={changeInActiveItem}
                  />
                ) : (
                  <LoopBuilder // for splits or fields groups
                    values={values}
                    setValues={setValues}
                    handleSubmit={handleSubmit}
                    activeItem={activeItem}
                    st_sources={st_sources}
                    rf_sources={rf_sources}
                    loopingFeatures={loopingFeatures}
                    changeInActiveItem={changeInActiveItem}
                  />
                ))}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
