// @ts-nocheck
import { is, isNil, pipe, reject, map, when } from "ramda"

export const removeNulls = when(
  is(Object),
  pipe(
    reject(isNil),
    map(a => removeNulls(a)),
  ),
)
