// @ts-nocheck
import { curry, indexBy, lensPath, prop, over } from "ramda"

/*
This function can change any array to an object indexed by a given key.
Check the tests for examples

Params:

path = [] Array
key = "" String
obj = {} Object
*/

export const nestedIndexBy = curry((path, key, obj) =>
  over(lensPath(path), indexBy(prop(key)), obj),
)
