// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import dsForecastDuck from "../../."
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const {
  actions: { updateForecast, updateForecastOk, updateForecastError },
} = dsForecastDuck

const updateForecastEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(updateForecast.type),
    switchMap(action => {
      const { index, form } = action.payload
      const { dataset, id } = form
      const url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/${dataset}/dsforecasts/${id}/`
      return ajax({
        url,
        body: form,
        method: "PATCH",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "application/json",
        },
      }).pipe(
        map(({ response }) => updateForecastOk({ index, data: response })),
        catchError(error => of(updateForecastError(error.xhr.response))),
      )
    }),
  )
export default updateForecastEpic
