// @ts-nocheck
import { pathOr, pick } from "ramda"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import rForecastDuck from "../../../../state/modules/rForecasts/index"
import stockDetailDuck from "../../../../state/modules/stockDetail"

import { Loader } from "../../../components"
import { useApi } from "../../../hooks/useApi"
import { createFields, getRelatedData } from "../utils"
import AddForm from "./AddForm"
import ConfigLoader from "./ConfigLoader"
import EditForm from "./EditForm"
import { StockDetailContainer } from "./StockDetail.styled"
import DeleteAlert from "./components/DeleteAlert"

const StockDetail = () => {
  const { scientApi } = useApi()

  const { stockSlug } = useParams()
  const [deleteIdSlug, setDeleteIdSlug] = useState({})
  const [deleting, setDeleting] = useState(false)
  const [deleteError, setDeleteError] = useState()
  const showDelete = infos => setDeleteIdSlug(infos)
  const hideDelete = () => setDeleteIdSlug({})

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(stockDetailDuck.actions.fetchStock({ slug: stockSlug }))
    dispatch(
      rForecastDuck.actions.fetchRForecasts({ stkSlug: stockSlug, consensusStart: "future" }),
    )
  }, [dispatch, stockSlug])

  const addSlug = useSelector(pathOr(null, ["stockDetail", stockSlug, "addSlug"]))
  const clearAddSlug = () => {
    return dispatch(stockDetailDuck.actions.clearAddSlug({ slug: stockSlug }))
  }

  const editSlug = useSelector(pathOr(null, ["stockDetail", stockSlug, "editSlug"]))
  const clearEditSlug = () => {
    return dispatch(stockDetailDuck.actions.clearEditSlug({ slug: stockSlug }))
  }

  const stock = useSelector(({ stockDetail }) => stockDetail[stockSlug])

  const rForecastFetching = useSelector(pathOr(false, ["rForecasts", stockSlug, "fetching"]))

  if (!stock || !stock.stockData || stock.stockFetching)
    return <Loader title="Fetching stock detail..." />

  const { stockData, reportData, reportFetching } = stock
  if (!reportData || reportFetching) return <Loader title="Loading reports..." />
  if (rForecastFetching) return <Loader title="Loading forecasts..." />

  const emptyReport = createFields(stock.stockData)

  const formData = {
    ...getRelatedData({
      emptyReport,
      reports: reportData.results,
      report_periods: stockData.report_periods,
      editSlug,
    }),
    stockParams: pick(
      [
        "ppa_in_segments",
        "exceptionals_in_segments",
        "restructuring_in_segments",
        "default_profit_metric",
      ],
      stock.stockData,
    ),
  }

  const handleDelete = () => {
    const stkSlug = stockSlug
    const rptSlug = deleteIdSlug.slug
    const id = deleteIdSlug.id
    setDeleting(true)
    setDeleteError()
    scientApi.reporting.reports.detail
      .delete({
        id,
      })
      .then(() => {
        setDeleting(false)
        dispatch(stockDetailDuck.actions.deleteReport({ stkSlug, rptSlug }))
        hideDelete()
      })
      .catch(e => {
        setDeleting(false)
        setDeleteError(e)
      })
  }

  if (editSlug) {
    return (
      <EditForm
        stkSlug={stockSlug}
        dispatch={dispatch}
        reportData={formData}
        handleClose={clearEditSlug}
        scientApi={scientApi}
      />
    )
  }

  if (addSlug) {
    return (
      <AddForm
        stkSlug={stockSlug}
        dispatch={dispatch}
        reportData={formData}
        handleClose={clearAddSlug}
        scientApi={scientApi}
      />
    )
  }

  return (
    <StockDetailContainer flexDirection="column">
      <ConfigLoader stockSlug={stockSlug} showDelete={showDelete} />
      <DeleteAlert
        idAndSlug={deleteIdSlug}
        cancel={hideDelete}
        confirm={handleDelete}
        loading={deleting}
        error={deleteError}
      />
    </StockDetailContainer>
  )
}
export default StockDetail
