// @ts-nocheck
import { combineEpics } from "redux-observable"

import createDeskEpic from "./createDeskEpic"
import deleteDeskEpic from "./deleteDeskEpic"
import fetchDesksEpic from "./fetchDesksEpic"
import fetchAllEpic from "./fetchAllEpic"
import fetchUserDesksEpic from "./fetchUserDesksEpic"
import updateDeskEpic from "./updateDeskEpic"
import upsertUserDesksEpic from "./upsertUserDesksEpic"
import setUserDesksEpic from "./setUserDesksEpic"

const sheetEpics = combineEpics(
  fetchDesksEpic,
  fetchAllEpic,
  createDeskEpic,
  deleteDeskEpic,
  fetchUserDesksEpic,
  updateDeskEpic,
  upsertUserDesksEpic,
  setUserDesksEpic,
)

export default sheetEpics
