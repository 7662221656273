import styled from "@emotion/styled"
import { FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { LargeText } from "../../../../styled/text.styled"

export const NoDataMessage = styled(LargeText)({
  fontStyle: "italic",
  textAlign: "center",
})

export const OrdersContainer = styled(FlexContainerAndItem)({
  border: `1px solid ${SCIENT_COLORS.gray1}`,
  background: SCIENT_COLORS.darkGray4,
})
