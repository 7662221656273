// @ts-nocheck
import { Button, MenuItem, Tag } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import { format, subHours } from "date-fns"
import { compose, find, map, path, pathOr, propEq, sort, values } from "ramda"
import { useSelector } from "react-redux"

import profileDuck from "../../../../../state/modules/profile"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { Col, Row } from "../../../../components"

const SelectDiff = ({ dispatch, stockSlug }) => {
  const allRForecasts = values(useSelector(path(["rForecasts", stockSlug, "forecasts"])))

  const allRForecastsByDate = sort(
    (a, b) => new Date(b.owner_updated_at) - new Date(a.owner_updated_at),
    allRForecasts,
  )

  const forecastsWithAverage = [
    {
      slug: `${stockSlug}-average`,
      company: "Average",
      label: "Base",
      owner: "default",
      owner_updated_at: new Date(),
      source_updated_at: subHours(new Date(), 1),
    },
    ...allRForecastsByDate,
  ]

  const diffPair = useSelector(path(["profile", "userstockconfigs", stockSlug, "diffPair"]))

  const lastReportDate = useSelector(
    pathOr(null, ["stockDetail", stockSlug, "stockData", "last_report_date"]),
  )

  const options = compose(
    map(forecast => ({
      name: forecast.company,
      label:
        forecast.company +
        "-" +
        (forecast.label === "Base" ? "" : `${forecast.label}-`) +
        (forecast.owner === "default" ? "" : `${forecast.owner}-`) +
        format(new Date(forecast.owner_updated_at), "dd/MM/yy HH:mm"),
      value: forecast.slug,
      style:
        forecast.owner_updated_at < lastReportDate
          ? { color: SCIENT_COLORS.red3 }
          : forecast.owner_updated_at < forecast.source_updated_at
          ? { color: SCIENT_COLORS.orange3 }
          : null,
    })),
    sort((a, b) => new Date(b.owner_updated_at) - new Date(a.owner_updated_at)),
  )(forecastsWithAverage)

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.value}
        text={item.label}
        onClick={handleClick}
        style={item.style}
      />
    )
  }

  const selectedDefender = find(propEq("value", diffPair[0]), options)
  const defenderButtonText = options.length <= 0 ? "No forecast" : selectedDefender.name
  const defenderButtonStyle = selectedDefender ? selectedDefender.style : null

  const selectedChallenger = find(propEq("value", diffPair[1]), options)
  const challengerButtonText = options.length <= 0 ? "No forecast" : selectedChallenger.name
  const challengerButtonStyle = selectedChallenger ? selectedChallenger.style : null

  const handleClickSelectDefender = item => {
    return dispatch(
      profileDuck.actions.stockSetDiffDefender({
        slug: stockSlug,
        forecastSlug: item.value,
        diffPair,
      }),
    )
  }
  const handleClickSelectchallenger = item => {
    return dispatch(
      profileDuck.actions.stockSetDiffChallenger({
        slug: stockSlug,
        forecastSlug: item.value,
        diffPair,
      }),
    )
  }

  return (
    <>
      <Row center="xs" style={{ margin: "0" }}>
        <Col xs>
          <Tag minimal>Challenger</Tag>
        </Col>
        <Col xs>
          <Tag minimal>Defender</Tag>
        </Col>
      </Row>
      <Row center="xs" style={{ margin: "6px auto 8px" }}>
        <Col xs>
          <Select
            className="sct-fullwidth"
            filterable={false}
            items={options}
            itemRenderer={itemRenderer}
            onItemSelect={handleClickSelectchallenger}
            disabled={options.length <= 0}
            popoverProps={{ position: "bottom" }}
          >
            <Button
              fill
              rightIcon="caret-down"
              text={challengerButtonText}
              disabled={options.length <= 0}
              style={challengerButtonStyle}
            />
          </Select>
        </Col>
        <Col xs>
          <Select
            className="sct-fullwidth"
            filterable={false}
            items={options}
            itemRenderer={itemRenderer}
            onItemSelect={handleClickSelectDefender}
            disabled={options.length <= 0}
            popoverProps={{ position: "bottom" }}
          >
            <Button
              fill
              rightIcon="caret-down"
              text={defenderButtonText}
              disabled={options.length <= 0}
              style={defenderButtonStyle}
            />
          </Select>
        </Col>
      </Row>
    </>
  )
}

export default SelectDiff
