// @ts-nocheck
import React from "react"
import { FormGroup, InputGroup as BP5InputGroup, Spinner } from "@blueprintjs/core"

export const InputGroup = ({
  labelProps,
  style,
  inputProps: {
    loading,
    onEnter = () => {
      // do nothing
    },
    onEsc = () => {
      // do nothing
    },
    ...rest
  },
}) => {
  const handleKeyUp = e => {
    if (e.key === "Enter") {
      onEnter()
    }
    if (e.key === "Escape") {
      onEsc()
    }
  }

  if (labelProps) {
    return (
      <FormGroup
        // helperText="Helper text with details..."
        // label="Label A"
        // labelFor="text-input"
        // labelInfo="(required)"
        {...labelProps}
      >
        <BP5InputGroup
          rightElement={loading ? <Spinner size="18" /> : null}
          // autoFocus={autoFocus}
          onKeyDown={handleKeyUp}
          {...rest}
        />
      </FormGroup>
    )
  }

  return (
    <div className="sct-side-item" style={style}>
      <BP5InputGroup
        rightElement={loading ? <Spinner size="18" /> : null}
        // autoFocus={autoFocus}
        onKeyDown={handleKeyUp}
        {...rest}
      />
    </div>
  )
}
