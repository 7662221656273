// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import { fetchFieldList, fetchFieldListOk, fetchFieldListError } from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const fetchSharedEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchFieldList.type),
    mergeMap(() =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/shared/fields/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchFieldListOk({ fieldList: response })),
        catchError(error => of(fetchFieldListError(error.xhr.response))),
      ),
    ),
  )

export default fetchSharedEpic
