// @ts-nocheck
import { assoc, compose, dissoc, dissocPath, lensPath, mergeDeepLeft, over, set } from "ramda"
const forecastPath = index => lensPath(["dsForecasts", "results", index])
const createdPath = state => lensPath(["dsForecasts", "results", state.dsForecasts.results.length])

export const fetchForecasts = state => ({ ...state, loading: true })
export const fetchForecastsOk = (state, dsForecasts) => ({
  ...state,
  loading: false,
  dsForecasts,
})
export const fetchForecastsError = (state, error) => ({
  ...state,
  loading: false,
  error,
})

export const fetchAllForecasts = state => ({ ...state, loading: true })
export const fetchAllForecastsOk = (state, all) => ({
  ...state,
  loading: false,
  all,
})
export const fetchAllForecastsError = (state, error) => ({
  ...state,
  loading: false,
  error,
})

export const createForecastOk = (state, forecast) => set(createdPath(state), forecast)(state)
export const fetchForecast = (state, { index }) =>
  over(forecastPath(index), assoc("loadingEntries", true))(state)
export const fetchForecastOk = (state, { index, forecast }) =>
  set(forecastPath(index), forecast)(state)
export const fetchForecastError = (state, { index, error }) =>
  over(
    forecastPath(index),
    compose(assoc("error", error), assoc("loadingEntries", undefined)),
  )(state)

export const updateForecast = (state, { index }) =>
  over(forecastPath(index), compose(assoc("loadingEntries", true)))(state)
export const updateForecastOk = (state, { index, data }) =>
  over(forecastPath(index), compose(dissoc("loadingEntries"), mergeDeepLeft(data)))(state)
export const updateForecastError = (state, { index, error }) =>
  over(forecastPath(index), compose(assoc("error", error), dissoc("loadingEntries")))(state)

export const deleteForecast = (state, { index }) =>
  over(forecastPath(index), assoc("loading", true))(state)
export const deleteForecastOk = (state, { index }) =>
  dissocPath(["dsForecasts", "results", index])(state)
export const deleteForecastError = (state, { index, error }) =>
  over(forecastPath(index), compose(dissoc("loading"), assoc("error", error)))(state)

export const clearEntries = (state, { index }) =>
  dissocPath(["dsForecasts", "results", index, "dsfentries"])(state)
