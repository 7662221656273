// @ts-nocheck
import { ButtonGroup, Card } from "@blueprintjs/core"
import { Field } from "formik"
import { isEmpty } from "ramda"
import { DndProvider } from "react-dnd"
import Backend from "react-dnd-html5-backend"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { Decimals, FormikTextInput } from "./FormikFields"
import FormikStyling from "./FormikStyling"
import { FormulaBuilder } from "./FormulaBuilder"

export const ItemFields = ({ index, card, values, setValues, DFState, setDFState }) => {
  const formulaItems = card.frontOpts.formulaItems ? card.frontOpts.formulaItems : []

  return (
    <Card>
      <Field
        name={`rowDefs[${index}].frontOpts.name`}
        component={FormikTextInput}
        labelProps={{ label: "Name" }}
        inputProps={{ placeholder: "Name..." }}
        helperText="Enter name or leave empty."
      />
      {!isEmpty(formulaItems) && isEmpty(card.frontOpts.name) && (
        <p style={{ color: SCIENT_COLORS.orange3 }}>Field name is required!</p>
      )}

      <p style={{ margin: "16px 0 4px" }}>Formula Builder</p>
      <DndProvider backend={Backend}>
        <FormulaBuilder
          formulaItems={formulaItems}
          values={values}
          setValues={setValues}
          card={card}
          DFState={DFState}
          setDFState={setDFState}
        />
      </DndProvider>

      <Decimals index={index} />
      <ButtonGroup fill>
        <FormikStyling index={index} />
      </ButtonGroup>
    </Card>
  )
}
