// @ts-nocheck
import autodux from "autodux"

import * as actions from "./actions"

const monitorsDuck = autodux({
  slice: "monitors",
  initial: {},
  actions,
})

export default monitorsDuck
