// @ts-nocheck
import React, { useState } from "react"

import Header from "../Header"
import MainGrid from "./MainGrid"
import AddGrid from "./AddGrid"

const EntriesTable = ({ dataSet, entries: { results } }) => {
  const [addPage, setAddPage] = useState(false)

  const addPageProps = {
    icon: "align-left",
    text: "Add entries",
    "data-cy": "AddEntries",
    onClick: () => setAddPage(true),
  }
  const pct = dataSet.unit_str && dataSet.unit_str.includes("pct")

  if (addPage) {
    return <AddGrid dataSet={dataSet} lastEntry={results[0]} setAddPage={setAddPage} />
  }
  return (
    <>
      <Header dataSet={dataSet} moreButtons={!dataSet.aggregated ? [addPageProps] : []} />

      <MainGrid dataSet={dataSet} results={results} pct={pct} />
    </>
  )
}

export default EntriesTable
