// @ts-nocheck
import { find, propEq } from "ramda"

// const getVerboseName = (df_id, dataframes) => {
//   const dataframe = dataframes[df_id]
//   const verboseName = dataframe.extra.verbose_name
//   return verboseName
// }

// to get src name of a dataframe
const getSrcName = (df_id, dataframes) => {
  const dataframe = dataframes[df_id]
  const srcName = dataframe.extra.src_name
  return srcName
}

// to get name of an existing row
const getExistingRowName = (rowId, rowDefs) => {
  const row = find(propEq("rowId", rowId))(rowDefs)
  if (row) {
    const name = row.frontOpts.name

    return name
  }
  return "Error: unreferenced item. Please delete."
}

export const getNiceFormulaItem = ({ item, dataframes, rowDefs }) => {
  if (item.includes("#")) {
    const regexDataframe = /#(\w+)/gi
    const df_id = regexDataframe.exec(item)[1]
    const srcName = getSrcName(df_id, dataframes)
    const niceItem = item.replace(regexDataframe, srcName)
    return niceItem
  }
  if (item.includes("@")) {
    const regexExistingRow = /@(\w+)/gi
    const rowId = regexExistingRow.exec(item)[1]
    const rowName = getExistingRowName(rowId, rowDefs)
    const niceItem = item.replace(regexExistingRow, rowName)
    return niceItem
  }

  return item
}
