// @ts-nocheck
import { Button, ControlGroup } from "@blueprintjs/core"
import { Field, Formik } from "formik"
import React from "react"
import { useDispatch } from "react-redux"
import dsForecastsDuck from "../../../../../state/modules/dsForecasts"
import { Col, Row } from "../../../../components"
import { handleError, NonFieldErrors, TextInput } from "../../../../components/formik"
import { useApi } from "../../../../hooks/useApi"
import { useAuth } from "../../../../hooks"

const Create = ({ dataSet }) => {
  const { scientApi } = useApi()
  const { profile } = useAuth()
  const owner = profile.username

  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{ dataset: dataSet.id, owner, name: "" }}
      onSubmit={async (values, { status, setStatus, setSubmitting, resetForm }) => {
        try {
          setSubmitting(true)
          const response = await scientApi.datasets.dsForecasts.list.create(values)
          resetForm()
          setSubmitting(false)
          dispatch(dsForecastsDuck.actions.createForecastOk(response.data))
        } catch (e) {
          handleError(setSubmitting, status, setStatus, e)
        }
      }}
    >
      {({ submitting, handleSubmit, handleChange, status }) => (
        <Row>
          <Col xs={12}>
            <ControlGroup>
              <Field
                // labelProps={{ label: "Bloomberg code" }}
                data-cy="DSFCreateInput"
                placeholder="New forecast"
                name="name"
                component={TextInput}
                id="name"
                onChange={handleChange}
                //"data-cy": "Bloomberg Code"
              />
              <Button
                data-cy="DSFCreateSubmit"
                intent="primary"
                icon="add"
                onClick={handleSubmit}
                text="Add"
                disabled={submitting}
              />
            </ControlGroup>
            <NonFieldErrors status={status} />
          </Col>
        </Row>
      )}
    </Formik>
  )
}
export default Create
