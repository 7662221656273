import styled from "@emotion/styled"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { Spinner } from "@blueprintjs/core"

export const FlexContainerFullHeight = styled(FlexContainer)({
  height: "100%",
})

export const SpinnerCenter = styled(Spinner)`
  margin: 7px auto;
`
