import styled from "@emotion/styled"
import { FlexContainer } from "../../../../../styled/flexbox.styled"

export const ParticipantsPopoverContainer = styled(FlexContainer)({
  padding: "5px 10px",
})

export const ParticipantsPopoverTarget = styled(FlexContainer)({
  cursor: "help",
})
