import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const InputContainer = styled(FlexContainer)({
  width: "100%",
})

export const TitleLabel = styled.p({
  margin: "10px 0 0",
})
