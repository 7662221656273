import "@blueprintjs/core/lib/css/blueprint.css"
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css"
import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css"
import "@blueprintjs/icons/lib/css/blueprint-icons.css"
import "@blueprintjs/select/lib/css/blueprint-select.css"
import "@blueprintjs/table/lib/css/table.css"
import "@nosferatu500/react-sortable-tree/style.css"
import "react-dates/initialize"
import "react-grid-layout/css/styles.css"
import "rxjs"
import "./ag-grid.scss"
import "./css/animation.scss"
import "./css/colors.scss"
import "./css/layout.scss"
import "./css/loader.scss"
import "./css/react-grid-layout.scss"
import "./css/side.scss"
import "./css/table.scss"
import "./css/tf-modal.scss"

import { FocusStyleManager } from "@blueprintjs/core"
import { LicenseManager } from "ag-grid-enterprise"
import Highcharts from "highcharts/highstock"
import Theme from "highcharts/themes/dark-unica"
import { createRoot } from "react-dom/client"
import setUpDatadogRum from "./config/datadog"
import configureStore from "./state/configureStore"
import App from "./views/App"

LicenseManager.setLicenseKey(
  "CompanyName=SCIENT ANALYTICS,LicensedApplication=scient analytics,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-014560,ExpiryDate=4_January_2023_[v2]_MTY3Mjc5MDQwMDAwMA==6e152cab6be4e30c4fb2b8cc5dfdcad2",
)

FocusStyleManager.onlyShowFocusOnTabs()

Theme(Highcharts)

const store = configureStore()

if (process.env.NODE_ENV === "production") {
  setUpDatadogRum()
}

const root = createRoot(document.getElementById("root") as HTMLElement)
root.render(<App store={store} />)
