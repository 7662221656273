// @ts-nocheck
import * as R from "ramda"
import { isArray, isObjectArray, isNumericArray } from "../../arrayUtils"

const computeAverage = forecast => {
  const calculateAverage = anything => {
    if (isArray(anything) && anything.length === 0) {
      return null
    }

    if (isNumericArray(anything) && anything.length > 0) {
      return anything.reduce((total, amount, index, array) => {
        total += amount === null || 0 ? null : amount
        if (index === array.length - 1) {
          return +(((total * 1) / array.length) * 1).toFixed(8)
        } else {
          return total
        }
      }, 0)
    }

    if (isObjectArray(anything) && anything.length > 0) {
      return anything.map(el => R.map(e => calculateAverage(e), el))
    }

    return anything
  }

  const result = R.map(e => calculateAverage(e), forecast)
  return result
}
export default computeAverage
