import { DataframeData } from "../types"

export type Exposure = {
  type: string
  dataframe: DataframeData
}

export type IRiskExposures = {
  key_metrics: DataframeData
  exposures: Exposure[]
}

export type IRebalancingExposures = {
  key_metrics: DataframeData
  categories: Exposure[]
}

export type PositionRiskZoom = {
  long: DataframeData
  short: DataframeData
}

// Define the structure of the data returned by the zoom endpoint
export enum RiskZoomDataType {
  LONG_SHORT = "long_short", // long short position
  STYLES = "styles", //  single style dataframe
}

// Define descriminated union type for zoom data based on the data type
export type RiskZoom =
  | { data_type: RiskZoomDataType.LONG_SHORT; data: PositionRiskZoom }
  | { data_type: RiskZoomDataType.STYLES; data: DataframeData }

/** Define all the available risk exposure types */
export enum RiskType {
  SECTOR_LEVEL_1 = "B_L1",
  SECTOR_LEVEL_2 = "B_L2",
  SECTOR_LEVEL_3 = "B_L3",
  CYCLICALITY = "B_CND",
  CURRENCIES = "B_CUR",
  GEO = "B_GEO",
  STYLES = "B_STYLES",
}
