// @ts-nocheck
import React from "react"
import * as R from "ramda"
import { Button, Classes, Dialog, HTMLSelect, Label } from "@blueprintjs/core"

export const RowKindDialog = ({ values, setValues, showItemKind, setShowItemKind, initialRow }) => {
  const sheetKinds = [
    { label: "Title", value: "row" },
    { label: "Stock group", value: "stockRows" },
    { label: "Aggregate", value: "aggregate" },
  ]
  const handleSelectKind = ({ target: { value } }) => setShowItemKind(value)

  const handleAddItem = () => {
    const newItem = initialRow(showItemKind)
    const newValues = {
      rowDefs: {
        ...values.rowDefs,
        rows: [...R.map(R.dissoc("active"), values.rowDefs.rows), newItem],
      },
    }
    return setValues(newValues) && setShowItemKind(false)
  }

  return (
    <Dialog
      className="bp5-dark"
      title="Add item"
      icon="plus"
      isOpen={showItemKind}
      onClose={() => setShowItemKind(false)}
    >
      <div className={Classes.DIALOG_BODY}>
        <div>
          <br />
          <Label>
            Kind
            <HTMLSelect options={sheetKinds} value={showItemKind} onChange={handleSelectKind} />
          </Label>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {/* <Button icon="cross" name="Cancel" onClick={toggleShowItemType} /> */}
          <Button intent="primary" icon="plus" name="Add" text="Add" onClick={handleAddItem} />
        </div>
      </div>
    </Dialog>
  )
}
