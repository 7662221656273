import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../../hooks/useApi"
import { IAccountBookPairs } from "../../types"

const useRebalancingQuery = ({
  accountBookPairs,
  type,
}: {
  accountBookPairs: IAccountBookPairs
  type: string
}) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["rebalancing", accountBookPairs, type],
    () => scientApi.trading.getRebalancingExposures({ accountBookPairs, type }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )

  return {
    rebalancing: data,
    isLoading,
    error,
  }
}

export default useRebalancingQuery
