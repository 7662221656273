// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { Card, H4, Icon, Tooltip } from "@blueprintjs/core"
import { Col, Grid, Row } from "react-flexbox-grid"

import { FlexContainer } from "../../../../../../../../../styled/flexbox.styled"
import { FormikSelect, FormikSwitch } from "../../../../../../../../components/formik/fields"
import { LabelTitleStyled, iconCss } from "./Single.styled"
import { SCIENT_COLORS } from "../../../../../../../../../styled/scientColors"

const colors = [
  { label: "Default", value: "" },
  { label: "Blue", value: SCIENT_COLORS.blue5 },
  { label: "Green", value: SCIENT_COLORS.green5 },
  { label: "Orange", value: SCIENT_COLORS.orange5 },
  { label: "Red", value: SCIENT_COLORS.red5 },
  { label: "Indigo", value: SCIENT_COLORS.indigo5 },
  { label: "Turquoise", value: SCIENT_COLORS.turquoise5 },
  { label: "Sepia", value: SCIENT_COLORS.sepia5 },
]

const styles = [
  { label: "Solid", value: "solid" },
  { label: "ShortDot", value: "shortDot" },
  { label: "Dot", value: "dot" },
  { label: "Dash", value: "shortDash" },
]
const type = [
  { label: "Default", value: "" },
  { label: "Spline", value: "spline" },
  { label: "Histogram", value: "column" },
  { label: "Line", value: "line" },
]

export const ChartSettings = () => {
  return (
    <Card>
      <Grid fluid>
        <Row center="xs">
          <H4>Chart settings</H4>
        </Row>
        <br />
        <Row>
          <Col xsOffset={3} xs>
            <FormikSwitch
              name={"frontOpts.chartHideLine"}
              label={"Hide line"}
              large
              alignIndicator="left"
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col xsOffset={3} xs>
            <FormikSwitch
              name={"frontOpts.chartOwnAxis"}
              label={"Own Y axis"}
              large
              alignIndicator="left"
            />
          </Col>
        </Row>
        <br />

        <Row center="xs">
          <Col xs={8} xl={6}>
            <FormikSelect
              name={"frontOpts.chartType"}
              labelProps={{
                label: (
                  <FlexContainer justifyContent="center">
                    <LabelTitleStyled>Type</LabelTitleStyled>
                    <Tooltip
                      content="With default choice, chart type is histogram for row in percent, else type
                      is spline."
                      placement="auto"
                      openOnTargetFocus={false}
                      compact
                    >
                      <Icon icon="help" size={16} css={iconCss} />
                    </Tooltip>
                  </FlexContainer>
                ),
              }}
              options={type}
            />
          </Col>
        </Row>

        <Row center="xs">
          <Col xs={8} xl={6}>
            <FormikSelect
              name={"frontOpts.chartColor"}
              labelProps={{ label: "Color" }}
              options={colors}
            />
          </Col>
        </Row>

        <Row center="xs">
          <Col xs={8} xl={6}>
            <FormikSelect
              name={"frontOpts.chartDashStyle"}
              labelProps={{ label: "Style" }}
              options={styles}
            />
          </Col>
        </Row>
      </Grid>
    </Card>
  )
}
