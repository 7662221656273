// @ts-nocheck
import React from "react"
import { assoc, compose, isEmpty, reject, map } from "ramda"
import { Button, Classes, MenuItem, H5 } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"

import { InsertLag } from "./InsertLag"
import { getNiceFormulaItem } from "../../../../../utils"

export const SelectExistingRow = ({ rowDefs, panelsState, setPanelsState }) => {
  // options are the existing rows
  const options = compose(
    map(row => ({
      label: row.frontOpts.name,
      value: row.rowId,
    })),
    reject(row => row.active),
    reject(row => isEmpty(row.frontOpts.formulaItems)),
  )(rowDefs)

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.value}
        text={item.label}
        onClick={handleClick}
      />
    )
  }

  const appendExistingRow = item => {
    setPanelsState(assoc("sourceId", `@${item.value}`), panelsState)
  }
  const existingRowName =
    panelsState.sourceId && getNiceFormulaItem({ item: panelsState.sourceId, rowDefs })

  return (
    <>
      <div className={Classes.DIALOG_BODY} style={{ margin: "30px 120px" }}>
        <H5>Select existing row</H5>
        <Select
          className="sct-fullwidth"
          filterable={false}
          items={options}
          itemRenderer={itemRenderer}
          onItemSelect={appendExistingRow}
          disabled={options.length === 0}
          popoverProps={{
            position: "bottom",
          }}
        >
          <Button
            fill
            rightIcon="caret-down"
            text={existingRowName || "Select Row"}
            disabled={options.length <= 0}
          />
        </Select>
      </div>

      <div className={Classes.DIALOG_BODY} style={{ margin: "30px 120px" }}>
        <H5>Insert Lag</H5>
        <InsertLag panelsState={panelsState} setPanelsState={setPanelsState} />
      </div>
    </>
  )
}
