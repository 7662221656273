// @ts-nocheck
import React from "react"
import { Tag } from "@blueprintjs/core"

import { Row, Col, Button } from "../../../../../../components"

const DSFLink = ({ index, dsflink, setModal }) => {
  const type = dsflink.subsource ? "subsource" : dsflink.source ? "source" : false
  const infos = () => {
    switch (type) {
      case "source":
        return {
          ref: `D${dsflink.ref}`,
          periodicity: dsflink.source.periodicity,
          text: dsflink.source.name,
        }
      case "subsource":
        return {
          ref: `F${dsflink.ref}`,
          forecast_periods: dsflink.forecast_periods,
          periodicity: dsflink.source.periodicity,
          text: dsflink.subsource.str_name,
        }
      default:
        return { id: "", text: "" }
    }
  }
  return (
    <Row>
      <Col xs={1}>
        <Button data-cy="DSFChoose" fill text="Choose" onClick={() => setModal({ index })} />
      </Col>
      <Col xs={8}>{infos().text}</Col>
      <Col xs={1}>
        <Tag fill style={{ textAlign: "center" }}>
          {infos().ref}
        </Tag>
      </Col>
      <Col xs={1}>
        <Tag fill style={{ textAlign: "center" }}>
          {infos().periodicity}
        </Tag>
      </Col>
      <Col xs={1}>
        <Tag fill style={{ textAlign: "center" }}>
          {infos().forecast_periods}
        </Tag>
      </Col>
    </Row>
  )
}

export default DSFLink
