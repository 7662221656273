// @ts-nocheck
import React from "react"

import { Grid } from "../../../../../components"
import { slugToName } from "../../../utils"
import { TitleRow, InputRow, ControlRow, ReadonlyRow } from "./components"

const Backlog = ({ handleChange, reportData: { prevReport, prevYearReport }, values }) => {
  return (
    <Grid fluid>
      <TitleRow key="Group" title="Group" />
      <InputRow
        key="BacklogGroup"
        title="Backlog"
        basePath={["backlog"]}
        commentPath={["Backlog_comment"]}
        values={values}
        prevReport={prevReport}
        prevYearReport={prevYearReport}
        handleChange={handleChange}
        sideUpdates={[
          {
            target: ["backlog_growth_readonly"],
            source: ["backlog"],
            method: "growthYoY",
          },
        ]}
      />
      <ReadonlyRow
        key="BacklogGrowth"
        title="Backlog growth"
        basePath={["backlog_growth_readonly"]}
        commentPath={["backlog_growth_readonly_comment"]}
        values={values}
        prevReport={prevReport}
        prevYearReport={prevYearReport}
        handleChange={handleChange}
      />

      {values.splits !== undefined &&
        values.splits.map(split => {
          const splitSlug = split.slug
          const expected = values["backlog"]
          const sumList = split.segs.map(rseg => rseg["backlog"])
          return (
            <div key={splitSlug}>
              <TitleRow key={splitSlug} title={slugToName(splitSlug)} />
              <ControlRow
                key={`Sum check ${splitSlug}`}
                title={`Sum check ${splitSlug}`}
                expected={expected}
                sumList={sumList}
              />

              {split.segs.map(rseg => {
                const segSlug = rseg.slug
                const pathBase = ["splits", `slug:${splitSlug}`, "segs", `slug:${segSlug}`]
                return (
                  <>
                    <TitleRow key={segSlug} title={`${slugToName(segSlug)}`} level={3} />
                    <InputRow
                      key={`${segSlug}_backlog`}
                      title={"Backlog"}
                      basePath={[...pathBase, "backlog"]}
                      commentPath={[...pathBase, "backlog_comment"]}
                      values={values}
                      prevReport={prevReport}
                      prevYearReport={prevYearReport}
                      handleChange={handleChange}
                      sideUpdates={[
                        {
                          target: [...pathBase, "backlog_growth_readonly"],
                          source: [...pathBase, "backlog"],
                          method: "growthYoY",
                        },
                      ]}
                    />
                    <ReadonlyRow
                      key={`${segSlug}_backlog_growth`}
                      title={"Backlog growth"}
                      basePath={[...pathBase, "backlog_growth_readonly"]}
                      commentPath={[...pathBase, "backlog_growth_comment"]}
                      values={values}
                      prevReport={prevReport}
                      prevYearReport={prevYearReport}
                      handleChange={handleChange}
                    />
                  </>
                )
              })}
            </div>
          )
        })}
    </Grid>
  )
}

export default Backlog
