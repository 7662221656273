// @ts-nocheck
import * as R from "ramda"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Button, ButtonGroup, Card, Classes, Dialog, H4, Icon } from "@blueprintjs/core"
import { Field } from "formik"

import { Grid, Row, Col } from "../../../../../../components"

import ChooseVariable from "../../../../components/ChooseVariable"
import { DynamicField } from "../../../../../../components/formik"
import { indepColors } from "../../../../components/colors"
const Item = ({ indep, index, total, method, remove, swap }) => {
  const [dialog, setDialog] = useState(false)
  const indepPath = `independents.${index}`

  const methodFields = R.flatten([
    R.filter(
      R.propEq("editable", true),
      useSelector(R.path(["shared", "fieldList", "analysis", "indep", method])),
    ),
    R.filter(
      R.propEq("editable", true),
      useSelector(R.path(["shared", "fieldList", "analysis", "indep", "basic"])),
    ),
  ])

  return (
    <Col xs={12}>
      <Card elevation={3}>
        <Grid>
          <Row center="xs">
            <Col xs={6}>
              <ButtonGroup fill>
                <Button
                  disabled={index === total - 1}
                  icon="arrow-down"
                  onClick={() => swap(index, index + 1)}
                />
                <Button
                  disabled={index === 0}
                  icon="arrow-up"
                  onClick={() => swap(index, index - 1)}
                />
                <Button intent="danger" icon="remove" onClick={() => remove(index)} />
              </ButtonGroup>
            </Col>
          </Row>
        </Grid>
        <H4>
          <Icon size={24} color={indepColors[index]} icon="symbol-square" />
          {indep.name ? `${indep.name} [${indep.freq}]` : "Choose variable"}{" "}
          <Button
            onClick={() => setDialog(true)}
            text={indep.path ? "Edit source" : "Select source"}
          />
        </H4>

        <Field name={`${indepPath}.source`}>
          {({ form: { setFieldValue } }) => {
            const handleSubmit = params => {
              setFieldValue(`${indepPath}.source`, params.source)
              setFieldValue(`${indepPath}.name`, params.name)
              setFieldValue(`${indepPath}.freq`, params.freq)
              setFieldValue(`${indepPath}.path`, params.path)
              setDialog(false)
            }

            return (
              <Dialog
                title="Choose explaining variable"
                icon="plus"
                className="bp5-dark"
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                isOpen={dialog}
                onClose={() => setDialog(false)}
                setDialog={setDialog}
              >
                <div className={Classes.DIALOG_BODY}>
                  <ChooseVariable variable={indep} submit={handleSubmit} indep={true} />
                </div>
              </Dialog>
            )
          }}
        </Field>
        {methodFields.map((fieldDef, index) => {
          const newFieldDef = { ...fieldDef, name: `${indepPath}.${fieldDef.name}` }
          return <DynamicField key={index} fieldDef={newFieldDef} />
        })}
      </Card>
    </Col>
  )
}

export default Item
