import { getIn, useField, useFormikContext } from "formik"
import { IdeaRecommendation } from "../types/business"
import FormikRadioLabel from "./FormikRadioLabel"
import { IdeaCreateLabel, IdeaSettingsInnerContainer } from "./IdeaCreate.styled"
import { IdeaCreateRecommendationContainer } from "./IdeaCreateRecommendation.styled"
import { IIdeaCreateForm } from "./IdeaCreate"
import { Callout } from "@blueprintjs/core"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

interface IIdeaCreateRecommendationProps {
  fieldName: string
  hidden: boolean
  disabled?: boolean
  index?: number
}

const IdeaCreateRecommendation = ({
  fieldName,
  hidden,
  disabled,
  index,
}: IIdeaCreateRecommendationProps) => {
  const [recommendationField] = useField(fieldName)
  const { errors } = useFormikContext<Partial<IIdeaCreateForm>>()

  return (
    <IdeaCreateRecommendationContainer hidden={hidden} flexDirection="column" gap="5px">
      <IdeaCreateLabel>{`Recommendation` + (index ? ` (${index})` : "")}</IdeaCreateLabel>
      <IdeaSettingsInnerContainer flexDirection="column">
        {(Object.keys(IdeaRecommendation) as Array<keyof typeof IdeaRecommendation>).map(
          recommendation => {
            return (
              <FormikRadioLabel
                gap="7px"
                key={recommendation}
                name={recommendationField.name}
                value={IdeaRecommendation[recommendation]}
                label={IdeaRecommendation[recommendation]}
                disabled={!!disabled}
              />
            )
          },
        )}
      </IdeaSettingsInnerContainer>
      {getIn(errors, recommendationField.name) && (
        <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
          {"Recommendation is required for struct ideas"}
        </Callout>
      )}
    </IdeaCreateRecommendationContainer>
  )
}

export default IdeaCreateRecommendation
