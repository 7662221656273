// @ts-nocheck
import React from "react"
import { Classes, Radio, RadioGroup } from "@blueprintjs/core"

export const SelectSource = ({ selectedSource, setSelectedSource, setPanelsState }) => {
  const handleSelect = ({ target: { value } }) => {
    return setPanelsState(["", "", "", ""]) & setSelectedSource(value)
  }
  return (
    <div className={Classes.DIALOG_BODY} style={{ margin: "62px 40px" }}>
      <p>Select which type of sources do you want to insert in formula</p>
      <RadioGroup onChange={handleSelect} selectedValue={selectedSource}>
        <Radio label="Column" value="existingCol" large />
        <Radio label="Formula" value="existingFormula" large />
      </RadioGroup>
    </div>
  )
}
