// @ts-nocheck
import React from "react"
import { Label, ControlGroup } from "@blueprintjs/core"
import { NumericGroup } from "../../../../../components"

const Decimals = ({ decimals, setDecimals }) => {
  return (
    <Label>
      Decimals (min/max)
      <ControlGroup>
        <NumericGroup
          inputProps={{
            min: 0,
            max: decimals.max,
            value: decimals.min,
            onValueChange: value => setDecimals({ ...decimals, min: value }),
          }}
        />
        <NumericGroup
          inputProps={{
            min: decimals.min,
            value: decimals.max,
            onValueChange: value => setDecimals({ ...decimals, max: value }),
          }}
        />
      </ControlGroup>
    </Label>
  )
}

export default Decimals
