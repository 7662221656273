// @ts-nocheck
import * as R from "ramda"
import { useState } from "react"
import { ReactFlowProvider } from "react-flow-renderer"
import { useSelector } from "react-redux"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { useEnhancedPath } from "../../../utils"
import Canvas from "./Canvas"
import Toolbar from "./Toolbar"
import { useOnSave } from "./helpers"
import "./index.css"
import { enhanceFormulaTree, enhanceNodes, getNodeDefs, initialFormulaTreeData } from "./utils"

const FormulaBuilder = ({
  activeItem,
  setActiveItem,
  setOpenFormulaBuilder,
  st_sources,
  rf_sources,
}) => {
  const { header, formula_tree } = activeItem

  const enhancedPath = useEnhancedPath(activeItem.path, st_sources, rf_sources)

  const operations = useSelector(R.path(["transforms", "results", "operations"]))
  const categories = useSelector(R.path(["transforms", "results", "categories"]))

  const nodeDefs = getNodeDefs({
    header,
    enhancedPath,
    formula_tree,
    st_sources,
    rf_sources,
    operations,
    categories,
  })

  const initialRFInstance = enhanceFormulaTree({
    formulaTree: R.isEmpty(formula_tree) ? initialFormulaTreeData : formula_tree,
    nodeDefs,
  })

  const [rfInstance, setRfInstance] = useState(null)

  const validateFormulaTree = () => {
    const { nodes, edges } = rfInstance ? rfInstance.toObject() : { nodes: [], edges: [] }

    /**
     * TODO: See later if possible to avoid use of function enhanceNodes by injecting the key 'optional' when node is dropping into canvas
     */

    const enhancedNodesForValidation = enhanceNodes({
      nodes: nodes,
      nodeDefs,
    })

    const nodesWithErrors = enhancedNodesForValidation
      .map(node => {
        const { id, handleBounds } = node
        const errSources = handleBounds.target
          ? handleBounds.target.filter(target => {
              const edge =
                target.optional ||
                R.find(R.allPass([R.propEq("target", id), R.propEq("targetHandle", target.id)]))(
                  edges,
                )
              return !edge
            })
          : []

        const errTargets = handleBounds.source
          ? handleBounds.source.filter(source => {
              const edge =
                source.optional ||
                R.find(
                  R.allPass([R.propEq("source", id), R.propEq("sourceHandle", source.id)]),
                  edges,
                )
              return !edge
            })
          : []

        return { ...node, errSources, errTargets }
      })
      .filter(node => !R.isEmpty(node.errSources) || !R.isEmpty(node.errTargets))

    return nodesWithErrors
  }

  const onSave = useOnSave(rfInstance, activeItem, setActiveItem)

  return (
    <div style={{ backgroundColor: SCIENT_COLORS.darkGray2, height: "96vh" }}>
      <div className="dndflow">
        <ReactFlowProvider>
          <Canvas
            nodeDefs={nodeDefs}
            initialRFInstance={initialRFInstance}
            rfInstance={rfInstance}
            setRfInstance={setRfInstance}
          />
          <Toolbar
            nodeDefs={nodeDefs}
            onSave={onSave}
            validateFormulaTree={validateFormulaTree}
            setOpenFormulaBuilder={setOpenFormulaBuilder}
          />
        </ReactFlowProvider>
      </div>
    </div>
  )
}

export default FormulaBuilder
