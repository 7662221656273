// @ts-nocheck
import React from "react"
import { Dialog } from "@blueprintjs/core"

import Content from "./Content"

const EditModal = ({ editRowItems, setEditRowItems, sheet }) => {
  const { show } = editRowItems

  const handleCancel = () => {
    setEditRowItems({ show: false })
  }
  return (
    <Dialog
      className="bp5-dark"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon="plus"
      title={`Edit ${sheet.name} rows`}
      intent="primary"
      isOpen={show}
      onClose={handleCancel}
      style={{ height: "100%", width: "900px" }}
      enforceFocus={false}
    >
      <Content sheet={sheet} editRowItems={editRowItems} setEditRowItems={setEditRowItems} />
    </Dialog>
  )
}

export default EditModal
