// @ts-nocheck
import { of } from "rxjs"
import { map, mergeMap, catchError, withLatestFrom } from "rxjs/operators"
import { ofType } from "redux-observable"

import monitorsDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { setMonitorSheets, setActiveSheet, upsertMonitorOk, upsertMonitorError },
} = monitorsDuck

const upsertMonitorEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(setMonitorSheets.type, setActiveSheet.type),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { id } = action.payload
      const body = state.monitors.data[id]
      const url = `${REACT_APP_API_DOMAIN}/api/v1/desks/monitors/${id}/`
      const method = "PATCH"
      return ajax({
        url,
        body,
        method,
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "application/json",
        },
      }).pipe(
        map(({ response }) => {
          return upsertMonitorOk({ id, payload: response })
        }),
        catchError(error => of(upsertMonitorError(error.xhr.response))),
      )
    }),
  )
export default upsertMonitorEpic
