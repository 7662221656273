import { DateInput3 } from "@blueprintjs/datetime2"
import { ICellEditorParams } from "ag-grid-community"
import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import React, { forwardRef, memo, useImperativeHandle, useRef, useState } from "react"

const KEY_BACKSPACE = "Backspace"
const KEY_ENTER = "Enter"
const KEY_TAB = "Tab"

export interface DateEditorProps extends ICellEditorParams {
  maxDate?: Date
}

export const DateEditor = memo(
  forwardRef((props: DateEditorProps, ref) => {
    const [value, setValue] = useState(props.value ? props.value : "")
    const refInput = useRef<HTMLDivElement | null>(null)

    /* Utility Methods */
    const cancelBeforeStart =
      props.eventKey && props.eventKey.length === 1 && "1234567890".indexOf(props.eventKey) < 0

    const isLeftOrRight = (event: React.KeyboardEvent) => {
      return ["ArrowLeft", "ArrowLeft"].indexOf(event.key) > -1
    }

    const isCharNumericOrHyphen = (charStr: string) => {
      return !!/\d|-/.test(charStr)
    }

    const isNumericOrHyphenKey = (event: React.KeyboardEvent) => {
      const charStr = event.key
      return isCharNumericOrHyphen(charStr)
    }

    const isBackspace = (event: React.KeyboardEvent) => {
      return event.key === KEY_BACKSPACE
    }

    const finishedEditingPressed = (event: React.KeyboardEvent) => {
      const key = event.key
      return key === KEY_ENTER || key === KEY_TAB
    }

    const onKeyDown = (event: React.KeyboardEvent) => {
      if (isLeftOrRight(event) || isBackspace(event)) {
        event.stopPropagation()
        return
      }

      if (!finishedEditingPressed(event) && !isNumericOrHyphenKey(event)) {
        if (event.preventDefault) event.preventDefault()
      }

      if (finishedEditingPressed(event)) {
        props.stopEditing()
      }
    }

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return value === "" || value === null
            ? null
            : format(utcToZonedTime(new Date(value), "UTC"), "yyyy-MM-dd'T'HH:mm:ss'Z'")
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return cancelBeforeStart
        },
      }
    })

    return (
      /**
       * We set ref in the parent component of DateInput3, to avoid The React warning
       * "Function components cannot be given refs. Attempts to access this ref will fail.
       * Did you mean to use React.forwardRef()?" which occurs when you pass the ref prop to a function component.
       * https://bobbyhadz.com/blog/function-components-cannot-be-given-refs-in-react
       */
      <div
        className="bp5-input-group bp5-fill"
        ref={refInput}
        onKeyDown={(event: React.KeyboardEvent) => onKeyDown(event)}
      >
        <DateInput3
          showActionsBar
          dateFnsFormat={"dd-MM-yy"} // Mutually exclusive with the formatDate and parseDate props.
          placeholder="DD-MM-YY"
          maxDate={props.maxDate ? props.maxDate : undefined}
          value={value ? format(utcToZonedTime(value, "UTC"), "yyyy-MM-dd") : ""}
          onChange={(date: string) =>
            setValue(
              date ? format(utcToZonedTime(new Date(date), "UTC"), "yyyy-MM-dd'T'HH:mm:ss'Z'") : "",
            )
          }
        />
      </div>
    )
  }),
)
