import { Divider, Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { Fragment } from "react"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { CardContainer } from "../../../../../styled/layout.styled"
import { MediumText } from "../../../../../styled/text.styled"
import { Idea } from "../../types/business"
import { IdeaInfosContainer, Separator } from "./IdeaNoteInfos.styled"

interface IIdeaNoteInfosProps {
  idea: Idea
}

const IdeaNoteInfos = ({ idea }: IIdeaNoteInfosProps) => {
  return (
    <IdeaInfosContainer gap="20px">
      {!!idea.meeting_date && (
        <CardContainer alignItems="center" gap="10px">
          <FlexContainer gap="5px">
            <Icon icon={IconNames.CALENDAR} />
            <MediumText>Meeting Date:</MediumText>
          </FlexContainer>
          <MediumText fontWeight="bold">{idea.meeting_date}</MediumText>
        </CardContainer>
      )}
      {!!idea.meeting_date && !!idea.related_stocks.length && <Divider />}
      {!!idea.related_stocks.length && (
        <>
          <CardContainer
            flexWrap="wrap"
            alignItems="center"
            flexDirection="row"
            flexGrow={1}
            gap="10px"
          >
            <FlexContainer gap="5px">
              <Icon icon={IconNames.BRIEFCASE} />
              <MediumText>Related Stocks:</MediumText>
            </FlexContainer>
            {idea.related_stocks.map((stock, index) => (
              <Fragment key={stock.id}>
                <MediumText fontWeight="bold">{stock.bloomberg_code}</MediumText>
                {index !== idea.related_stocks.length - 1 && <Separator>-</Separator>}
              </Fragment>
            ))}
          </CardContainer>
        </>
      )}
    </IdeaInfosContainer>
  )
}

export default IdeaNoteInfos
