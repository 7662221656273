// @ts-nocheck
import { Button, MenuItem, Tag } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import { format } from "date-fns"
import { compose, find, map, pathOr, propEq, sort, values } from "ramda"
import { useDispatch, useSelector } from "react-redux"

import profileDuck from "../../../../../state/modules/profile"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { Col, Row } from "../../../../components"

const SelectForecast = ({ stockSlug }) => {
  const lastReportDate = useSelector(
    pathOr(null, ["stockDetail", stockSlug, "stockData", "last_report_date"]),
  )

  const options = useSelector(
    compose(
      map(forecast => ({
        label:
          forecast.company +
          "-" +
          (forecast.label === "Base" ? "" : `${forecast.label}-`) +
          (forecast.owner === "default" ? "" : `${forecast.owner}-`) +
          format(new Date(forecast.owner_updated_at), "dd/MM/yy HH:mm"),
        value: forecast.slug,
        style:
          forecast.owner_updated_at < lastReportDate
            ? { color: SCIENT_COLORS.red3 }
            : forecast.owner_updated_at < forecast.source_updated_at
            ? { color: SCIENT_COLORS.orange3 }
            : null,
      })),
      sort((a, b) => new Date(b.owner_updated_at) - new Date(a.owner_updated_at)),
      values,
      pathOr([], ["rForecasts", stockSlug, "forecasts"]),
    ),
  )

  const value = useSelector(pathOr(null, ["profile", "userstockconfigs", stockSlug, "forecast"]))

  const dispatch = useDispatch()
  const handleClickSelect = item => {
    return dispatch(
      profileDuck.actions.stockSetForecast({
        slug: stockSlug,
        forecast: item.value,
      }),
    )
  }
  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.value}
        text={item.label}
        onClick={handleClick}
        style={item.style}
      />
    )
  }

  const selectedField = find(propEq("value", value), options)
  const buttonText =
    options.length <= 0 ? "No forecast" : selectedField ? selectedField.label : "No selection"
  const buttonStyle = selectedField ? selectedField.style : null

  return (
    <>
      <Row center="xs" style={{ margin: "0" }}>
        <Col xs>
          <Tag minimal>Forecast</Tag>
        </Col>
      </Row>
      <Row center="xs" style={{ margin: "6px auto 8px", maxWidth: "400px" }}>
        <Col xs={10}>
          <Select
            className="sct-fullwidth"
            filterable={false}
            items={options}
            itemRenderer={itemRenderer}
            onItemSelect={handleClickSelect}
            disabled={options.length <= 0}
            popoverProps={{ position: "bottom" }}
          >
            <Button
              fill
              rightIcon="caret-down"
              text={buttonText}
              disabled={options.length <= 0}
              style={buttonStyle}
            />
          </Select>
        </Col>
      </Row>
    </>
  )
}

export default SelectForecast
