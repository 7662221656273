// @ts-nocheck
export const newFormatNumber = (value, options = {}) => {
  const { minDigit, maxDigit = 1, pct = false, alreadyMultiplyBy100 = false } = options
  if ([null, undefined, "", "nan", NaN].includes(value)) {
    return ""
  }

  const multiplier = pct && !alreadyMultiplyBy100 ? 100 : 1

  const cleanedValue = value * multiplier
  // const cleanedString = cleanedValue.toLocaleString("en-GB", {
  const cleanedString = Intl.NumberFormat("en-GB", {
    minimumFractionDigits: minDigit,
    maximumFractionDigits: maxDigit,
  }).format(cleanedValue)

  return pct ? cleanedString + "%" : cleanedString
}
