import { useInfiniteQuery } from "@tanstack/react-query"
import { IdeaOrdering, IdeaSorting } from "../../../../api/ideas"
import { useApi } from "../../../hooks/useApi"
import { Idea, IdeaType } from "../types/business"

/** Create all comparaison functions for all sequential columns */
const compareFnByCreationDate = (ideaA: Idea, ideaB: Idea) =>
  new Date(ideaB.created_at).getTime() - new Date(ideaA.created_at).getTime()

const compareFnByDefaultSortingDate = (ideaA: Idea, ideaB: Idea) => {
  return !!ideaA.default_sorting_date && !!ideaB.default_sorting_date
    ? new Date(ideaB.default_sorting_date).getTime() -
        new Date(ideaA.default_sorting_date).getTime()
    : 0
}

const compareFnByTradeOpenAt = (ideaA: Idea, ideaB: Idea) => {
  return !!ideaA.trade?.opened_at && !!ideaB.trade?.opened_at
    ? new Date(ideaB.trade.opened_at).getTime() - new Date(ideaA.trade.opened_at).getTime()
    : 0
}

const compareFnByTradeCloseAt = (ideaA: Idea, ideaB: Idea) => {
  return !!ideaA.trade?.closed_at && !!ideaB.trade?.closed_at
    ? new Date(ideaB.trade.closed_at).getTime() - new Date(ideaA.trade.closed_at).getTime()
    : 0
}

const conpareFnByTradePerformance = (ideaA: Idea, ideaB: Idea) =>
  ideaA.trade?.main_perf && ideaB.trade?.main_perf
    ? ideaB.trade.main_perf - ideaA.trade.main_perf
    : 0

/**
 * Return the comparaison function based on the filtering params, the ordering
 * and sorting preferences and the draft mode.
 */
const getSortIdeasCompareFn = (
  orderByParam: IdeaOrdering,
  sortDirectionParam: IdeaSorting,
  draftParam: boolean,
) => {
  let orderByFn = compareFnByDefaultSortingDate
  if (
    orderByParam === IdeaOrdering.CREATION_DATE ||
    (orderByParam === IdeaOrdering.DEFAULT && draftParam)
  ) {
    orderByFn = compareFnByCreationDate
  } else if (orderByParam === IdeaOrdering.TRADE_OPEN_DATE) {
    orderByFn = compareFnByTradeOpenAt
  } else if (orderByParam === IdeaOrdering.TRADE_CLOSE_DATE) {
    orderByFn = compareFnByTradeCloseAt
  } else if (orderByParam === IdeaOrdering.TRADE_PERFORMANCE) {
    orderByFn = conpareFnByTradePerformance
  }
  return (ideaA: Idea, ideaB: Idea) => {
    const res = orderByFn(ideaA, ideaB)
    if (sortDirectionParam === "ASC") {
      return -res
    }
    return res
  }
}

export interface IInfiniteIdeas {
  stockParam?: number
  sectorParam?: number
  userParam?: number
  draftParam: boolean
  ideaTypeParam: IdeaType | null
  tradeOpenOnlyParam?: boolean
  tradeClosedOnlyParam?: boolean
  orderByParam: IdeaOrdering
  sortDirectionParam: IdeaSorting
}

/**
 * React query hook to GET ideas paginated
 */
const useInfiniteIdeas = ({
  stockParam,
  sectorParam,
  userParam,
  draftParam,
  ideaTypeParam,
  tradeOpenOnlyParam,
  tradeClosedOnlyParam,
  orderByParam,
  sortDirectionParam,
}: IInfiniteIdeas) => {
  const { scientApi } = useApi()

  /**
   * We need to use dataUpdatedAt as it will be set when the cache change
   * hence, our custom useInfiniteIdeas hook will also rerender otherwise
   * it will be locked on cache update
   * As of v4, this is our only way to set State on cache update for live event
   */
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    status,
    isFetching,
    dataUpdatedAt,
    isFetchingNextPage,
  } = useInfiniteQuery<{
    data: Idea[]
    next_cursor: string | null
  }>(
    /**
     * Draft is being separated from the rest of filters to implement different createIdea behaviors
     * based on this field.
     */
    [
      "ideas",
      draftParam ? "draft" : "public",
      { orderBy: orderByParam, sortDirection: sortDirectionParam },
      {
        stock: stockParam,
        sector: sectorParam,
        user: userParam,
        ideaType: ideaTypeParam,
        tradeOpenOnly: tradeOpenOnlyParam,
        tradeClosedOnly: tradeClosedOnlyParam,
      },
    ],
    ({ pageParam }) =>
      scientApi.ideas.getInfiniteIdeas({
        pageParam,
        stockParam,
        sectorParam,
        userParam,
        draftParam,
        ideaTypeParam,
        tradeOpenOnlyParam,
        tradeClosedOnlyParam,
        orderBy: orderByParam,
        sortDirection: sortDirectionParam,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.next_cursor) {
          return lastPage.next_cursor
        }
        return undefined
      },
      // https://github.com/TanStack/query/issues/3065
      // TODO: once v5 is out, allow typing on select for infinite data
      // @ts-ignore
      select: data => {
        let ideas: Idea[] = []
        for (const page of data.pages) {
          ideas = [...ideas, ...page.data]
        }
        ideas.sort(getSortIdeasCompareFn(orderByParam, sortDirectionParam, draftParam))
        return ideas
      },
      staleTime: Infinity,
    },
  )

  return {
    // @ts-ignore
    ideas: data as Idea[] | undefined,
    fetchNextPage,
    hasNextPage,
    error,
    status,
    isFetching,
    dataUpdatedAt,
    isFetchingNextPage,
  }
}

export default useInfiniteIdeas
