import { ConversationMessage, Idea, IdeaTrade } from "../../views/pages/Ideas/types/business"
import { Wave } from "../../views/pages/PortfolioManagement/Trading/types/wave"
import { useWebsocketEvent } from "./useWebsocketEvent"

export type ScientWebsocketEvent =
  | NewMessageEvent
  | UpdateMessageEvent
  | NewIdeaEvent
  | UpdateIdeaEvent
  | OpenTradeEvent
  | CloseTradeEvent
  | NewModelUpdatesEvent
  | TradingWaveUpdated

export type NewMessageEvent = {
  type: "idea.message.new"
  message: ConversationMessage
  idea: Idea
}

export type UpdateMessageEvent = {
  type: "idea.message.update"
  message: ConversationMessage
  idea_id: number
  is_last_message: boolean
}

export type NewIdeaEvent = {
  type: "idea.new"
  idea: Idea
}

export type UpdateIdeaEvent = {
  type: "idea.update"
  idea: Idea
}

export type OpenTradeEvent = {
  type: "idea.trade.open"
  trade: IdeaTrade
}

export type CloseTradeEvent = {
  type: "idea.trade.close"
  trade: IdeaTrade
}

export type NewModelUpdatesEvent = {
  type: "model_updates.new"
}

export type TradingDataUpdated = {
  type: "trading.data_updated"
}

export type TradingWaveUpdated = {
  type: "trade.wave.update"
  trade_wave: Wave
}

/**
 * Live events hooks for Ideas
 */
export const useNewMessageEvent = (handler: (data: NewMessageEvent) => void) =>
  useWebsocketEvent<NewMessageEvent>({ handler, type: "idea.message.new" })

export const useUpdateMessageEvent = (handler: (data: UpdateMessageEvent) => void) =>
  useWebsocketEvent<UpdateMessageEvent>({ handler, type: "idea.message.update" })

export const useNewIdeaEvent = (handler: (data: NewIdeaEvent) => void) =>
  useWebsocketEvent<NewIdeaEvent>({ handler, type: "idea.new" })

export const useUpdateIdeaEvent = (handler: (data: UpdateIdeaEvent) => void) =>
  useWebsocketEvent<UpdateIdeaEvent>({ handler, type: "idea.update" })

export const useOpenIdeaTradeEvent = (handler: (data: OpenTradeEvent) => void) =>
  useWebsocketEvent<OpenTradeEvent>({ handler, type: "idea.trade.open" })

export const useCloseIdeaTradeEvent = (handler: (data: CloseTradeEvent) => void) =>
  useWebsocketEvent<CloseTradeEvent>({ handler, type: "idea.trade.close" })
/**
 * Live events hooks for Noc
 */
export const useNewModelUpdateEvent = (handler: (data: NewModelUpdatesEvent) => void) =>
  useWebsocketEvent<NewModelUpdatesEvent>({ handler, type: "model_updates.new" })

/**
 * Live events hooks for Portfolio
 */
export const useTradingDataUpdated = (handler: (data: TradingDataUpdated) => void) =>
  useWebsocketEvent<TradingDataUpdated>({ handler, type: "trading.data_updated" })

/**
 * Live events hooks for Trading
 */
export const useTradingWaveUpdated = (handler: (data: TradingWaveUpdated) => void) =>
  useWebsocketEvent<TradingWaveUpdated>({ handler, type: "trade.wave.update" })
