// @ts-nocheck
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { HTMLTable, Spinner } from "@blueprintjs/core"

// import {
//   updateAnalyse,
//   deleteAnalyse,
//   updateFolder,
//   deleteFolder,
// } from "../../../../state/modules/analysis"
import { Button, ButtonWithAlert, Callout, EditName } from "../../../../components"
import Row from "./Row"

const Folder = ({ index, analyses, myGroups, allFolders, folder, openFolder, setOpenFolder }) => {
  const [edit, setEdit] = useState(false)
  const [name, setName] = useState(folder.name)
  const selected = openFolder === folder.id

  const dispatch = useDispatch()
  const updateFolder = props => dispatch(updateFolder(props))
  const deleteFolder = props => dispatch(deleteFolder(props))

  const handleClick = () => (selected ? setOpenFolder() : setOpenFolder(folder.id))
  const handleChange = event => setName(event.target.value)
  const handleCancel = () => {
    setName(folder.name)
    setEdit(false)
  }
  const handleSubmit = () => {
    updateFolder({ ...folder, index, name: name })
    setEdit(false)
  }
  const handleDelete = () => {
    deleteFolder({ index, id: folder.id })
  }
  return (
    <Callout
      style={{ marginBottom: "4px" }}
      key={folder.id}
      title={
        edit ? (
          <EditName
            value={name}
            change={handleChange}
            submit={handleSubmit}
            cancel={handleCancel}
          />
        ) : folder.loading ? (
          <Spinner size={30} />
        ) : (
          <>
            <Button
              minimal
              icon={selected ? "folder-open" : "folder-close"}
              text={folder.name}
              onClick={handleClick}
            />
            {folder.id && (
              <div style={{ float: "right" }}>
                <Button minimal icon="edit" onClick={() => setEdit(!edit)} />
                <ButtonWithAlert
                  buttonProps={{
                    minimal: true,
                    icon: "trash",
                    "data-cy": `DeleteFolder${index}`,
                  }}
                  alertProps={{
                    intent: "danger",
                    confirmButtonText: "Delete folder",
                    onConfirm: handleDelete,
                  }}
                >
                  <h4>
                    Delete folder <em>{folder.name}?</em>
                  </h4>
                  <p>Analyses will not be deleted.</p>
                  <p>After confirm, reload the page to find them in "Other" folder.</p>
                </ButtonWithAlert>
              </div>
            )}
          </>
        )
      }
      body={
        selected && (
          <HTMLTable striped style={{ width: "100%" }}>
            <tbody>
              {analyses ? (
                analyses.map((a, i) => (
                  <Row
                    key={a.id}
                    index={i}
                    analyse={a}
                    myGroups={myGroups}
                    allFolders={allFolders}
                  />
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <em>Folder is empty</em>
                  </td>
                </tr>
              )}
            </tbody>
          </HTMLTable>
        )
      }
    />
  )
}

export default Folder
