// @ts-nocheck
import React from "react"
import { useSelector } from "react-redux"
import { Button, MenuItem, Tag } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import { compose, dissoc, find, map, pathOr, prop, propEq, values } from "ramda"

import { Row, Col } from "../../../../components"
import profileDuck from "../../../../../state/modules/profile"
import { getAllTabs } from "../../utils"

const SelectConsensusFields = ({ dispatch, stockSlug }) => {
  /**
   * @todo: Profile is already deprecated on redux although we need to move the
   * userstocksconfig out of redux also in order to completely remove profile from redux
   */
  const profile = useSelector(prop("profile"))
  const allTabs = getAllTabs(stockSlug, profile)

  const options = compose(
    map(tab => ({
      id: tab.id,
      title: tab.title,
    })),
    values,
    dissoc("consensus"),
  )(allTabs)

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem active={modifiers.active} key={item.id} text={item.title} onClick={handleClick} />
    )
  }

  const consensusFields = useSelector(
    pathOr([], ["profile", "userstockconfigs", stockSlug, "consensusFields"]),
  )

  const selectedField = find(propEq("id", consensusFields), options)
  const buttonText =
    options.length <= 0 ? "No forecast" : selectedField ? selectedField.title : "No selection"

  const handleChange = tab =>
    dispatch(
      profileDuck.actions.stockSetConsensusFields({ slug: stockSlug, consensusFields: tab.id }),
    )

  return (
    <>
      <Row center="xs" style={{ margin: "0" }}>
        <Col xs>
          <Tag minimal>Fields group</Tag>
        </Col>
      </Row>
      <Row center="xs" style={{ margin: "6px auto 8px" }}>
        <Col xs={11}>
          <Select
            className="sct-fullwidth"
            filterable={false}
            items={options}
            itemRenderer={itemRenderer}
            onItemSelect={handleChange}
            disabled={options.length <= 0}
            popoverProps={{ position: "bottom" }}
          >
            <Button fill rightIcon="caret-down" text={buttonText} disabled={options.length <= 0} />
          </Select>
        </Col>
      </Row>
    </>
  )
}

export default SelectConsensusFields
