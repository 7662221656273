// @ts-nocheck
import React from "react"

import { Divider, Drawer } from "@blueprintjs/core"

import { Grid } from "../../../../../../components"
import GridPreferences from "./GridPreferences"
import TabList from "./TabList"

const Settings = ({ stockSlug, isOpen, onClose }) => {
  return (
    <Drawer
      className="bp5-dark"
      icon="settings"
      isOpen={isOpen}
      isCloseButtonShown={true}
      onClose={onClose}
      title="Stock Settings"
      size="30%"
      hasBackdrop={true}
    >
      <Grid fluid>
        <GridPreferences stockSlug={stockSlug} />
        <Divider />
        <TabList stockSlug={stockSlug} />
      </Grid>
    </Drawer>
  )
}

export default Settings
