// @ts-nocheck
import { Cell, Column, Table } from "@blueprintjs/table"
import { differenceInDays } from "date-fns"
import { includes } from "ramda"
import { Link } from "react-router-dom"

import { formatNumber } from "../../../_shared"
import { colorPctRenderer, cw, dateRenderer, fieldRenderer } from "../../../_shared/cellRenderers"

const Main = ({ response: { results } }) => {
  const renderName = rowIndex => {
    const {
      completed,
      date,
      dataset: { id, name },
    } = results[rowIndex]

    const warning = () => {
      if (!completed && differenceInDays(new Date(), new Date(date)) > 0) {
        return <span style={{ fontWeight: "bold", color: "red" }}>!</span>
      }
      return null
    }

    return (
      <Cell>
        <Link to={`/datasets/${id}/entries/table`}>{name}</Link>
        {warning()}
      </Cell>
    )
  }

  const renderVal = rowIdx => {
    const { last_value, unit_str } = results[rowIdx].dataset
    return <Cell>{formatNumber(last_value, unit_str ? includes("pct", unit_str) : false)}</Cell>
  }

  return (
    <Table
      numRows={results.length}
      numFrozenRows={0}
      numFrozenColumns={1}
      columnWidths={[cw.name, cw.date, cw.chg, cw.chg, cw.chg, cw.chg, cw.chg, 150, 100, 120, 80]}
    >
      <Column name="Dataset events" cellRenderer={renderName} />
      <Column name="Evt date" cellRenderer={rowIdx => dateRenderer(rowIdx, "date", results)} />
      <Column
        name="Evt Name"
        cellRenderer={rowIdx => fieldRenderer(rowIdx, "entry_name", results)}
      />
      <Column
        name="DS WoW"
        cellRenderer={rowIdx => colorPctRenderer(rowIdx, "dataset;chg_1w", results)}
      />
      <Column
        name="DS MoM"
        cellRenderer={rowIdx => colorPctRenderer(rowIdx, "dataset;chg_1m", results)}
      />
      <Column
        name="DS YoY"
        cellRenderer={rowIdx => colorPctRenderer(rowIdx, "dataset;chg_1y", results)}
      />
      <Column
        name="DS MA3M"
        cellRenderer={rowIdx => colorPctRenderer(rowIdx, "dataset;ma_3m_12m", results)}
      />
      <Column
        name="DS Last release"
        cellRenderer={rowIdx => dateRenderer(rowIdx, "dataset;last_release", results)}
      />
      <Column name="Last value" cellRenderer={renderVal} />
      <Column
        name="DS Category"
        cellRenderer={rowIdx => fieldRenderer(rowIdx, "dataset;category", results)}
      />
      <Column
        name="DS Sector 1"
        cellRenderer={rowIdx => fieldRenderer(rowIdx, "dataset;sector_1", results)}
      />
    </Table>
  )
}

export default Main
