// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import profileDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import { nestedIndexBy } from "../../../../utils"
import { assoc, compose } from "ramda"
import { userstockconfigDefaults } from "../defaults"
const {
  actions: { fetchProfile, fetchProfileOk, fetchProfileError },
} = profileDuck

const fetchProfileEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchProfile.type),
    mergeMap(() =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/profile/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          const payload = compose(
            assoc("userstockconfigDefaults", userstockconfigDefaults),
            nestedIndexBy(["userstockconfigs"], "stock"),
          )(response)
          return fetchProfileOk(payload)
        }),
        catchError(error => of(fetchProfileError(error.xhr.response))),
      ),
    ),
  )

export default fetchProfileEpic
