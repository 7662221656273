// @ts-nocheck
import { AgGridReact } from "ag-grid-react"
import { format } from "date-fns"
import { includes, last, path, pathOr } from "ramda"
import { useState } from "react"
import { useSelector } from "react-redux"

import { createStockRows, randomid } from "../../../../../../../utils"
import { getFormattedValue, rowClassRules } from "../utils"
import { ConsensusContainer } from "./Consensus.styled"
import {
  addComputedForecasts,
  concatRFEntries,
  getConsensusSlugs,
  shapeForecasts,
  sortForecasts,
} from "./utils"

const getHeaderClasses = forecast => {
  const borderClass = "sct-border"
  const starredClass =
    forecast.starred && !forecast.defender && !forecast.challenger ? "sct-starred" : ""
  const excludedClass =
    forecast.excluded && !forecast.defender && !forecast.challenger ? "sct-excluded" : ""
  const outThresholdClass =
    forecast.outThreshold && !forecast.defender && !forecast.challenger ? "sct-outThreshold" : ""
  return [borderClass, starredClass, excludedClass, outThresholdClass].join(" ")
}

const Consensus = ({ stockSlug, stock, profile, options, allTabs }) => {
  const rawForecasts = useSelector(pathOr([], ["rForecasts", stockSlug, "forecasts"]))
  const consensusFields = useSelector(
    pathOr("sales", ["profile", "userstockconfigs", stockSlug, "consensusFields"]),
  )
  const rowDefs = allTabs[consensusFields].rowDefs

  const { starred, excluded, thresholdDays, diffPair, diffMethod } = path(
    ["userstockconfigs", stockSlug],
    profile,
  )

  const year = stock.next_bbg_coord.year || stock.next_db_coord.year
  const nextPeriod =
    (stock.next_bbg_coord.periods.length > 0 && last(stock.next_bbg_coord.periods).toLowerCase()) ||
    last(stock.next_db_coord.periods).toLowerCase()
  const quarterAmount = useSelector(
    pathOr(1, ["profile", "userstockconfigs", stockSlug, "quarterAmount"]),
  )
  const yearAmount = useSelector(
    pathOr(1, ["profile", "userstockconfigs", stockSlug, "yearAmount"]),
  )
  const slugs = getConsensusSlugs(year, nextPeriod, quarterAmount, yearAmount)

  const shapedForecasts = shapeForecasts({ slugs, stock, forecasts: rawForecasts })

  const emptyForecast = shapeForecasts({
    slugs,
    stock,
    forecasts: {
      noentry: {
        label: "Base",
        slug: `${stock.slug}-noentry`,
        str_name: `${stock.bloomberg_code}>noentry`,
        owner: "default",
        company: "NOENTRY",
        stock: stock.slug,
      },
    },
  })

  const sortedForecasts = sortForecasts({
    forecasts: shapedForecasts,
    starred,
    excluded,
    thresholdDays,
  })

  const consensusForecasts = addComputedForecasts({
    forecasts: sortedForecasts,
    diffMethod,
    diffPair,
    noEntry: emptyForecast.noentry.rfentries,
    stock,
  })
  const consensusEntries = concatRFEntries(consensusForecasts)

  const cellClassRules = forecast => {
    return {
      "sct-cell": () => true,
      "sct-border": () => true,
      "sct-excluded": () => forecast.excluded && !forecast.defender && !forecast.challenger,
      "sct-outThreshold": () => forecast.outThreshold && !forecast.defender && !forecast.challenger,
    }
  }

  const colTitles = consensusForecasts.map(forecast => ({
    headerName:
      forecast.company +
      // 'updated_at' date does not exist for Diff and Average columns (do not make sens to have it because this columns are computed)
      (includes("average", forecast.slug) || includes("diff", forecast.slug)
        ? ""
        : "-" +
          (forecast.label === "Base" ? "" : `${forecast.label}-`) +
          (forecast.owner === "default" ? "" : `${forecast.owner}-`) +
          format(new Date(forecast.owner_updated_at), "dd/MM/yy HH:mm")),
    field: forecast.str_name,
    headerClass: getHeaderClasses(forecast),
    children: forecast.rfentries.map(entry => ({
      headerName: entry.slug,
      headerClass: getHeaderClasses(forecast),
      field: entry.str_name,

      cellClassRules: cellClassRules(forecast),

      cellClass: params => {
        if (forecast.company.includes("Diff")) {
          return parseFloat(params.value) > 0
            ? "sct-positive-value"
            : parseFloat(params.value) < 0
            ? "sct-negative-value"
            : null
        }
        return null
      },
    })),
  }))

  const columnDefs = [{ headerName: "", field: "desc", pinned: "left", width: 300 }, ...colTitles]

  const rowHeaders = createStockRows({
    rowDefs,
    entries: consensusEntries,
    stock,
    options,
  })

  const rowData = rowHeaders.map(header => {
    const col = consensusEntries.reduce(
      (acc, report) => {
        const cellValue = ["title1", "title2"].includes(header.type)
          ? null
          : getFormattedValue(header, report, diffMethod)
        acc[`${report.str_name}`] = cellValue
        return acc
      },
      { desc: header.text, type: header.type, styles: header.styles, id: randomid() },
    )
    return col
  })

  const agOptions = {
    reactNext: true,
    defaultColDef: {
      width: 100,
      resizable: true,
    },
    columnDefs,
    rowData,
    rowSelection: "single",
  }

  const [, setGridApi] = useState({})
  const [, setGridColumnApi] = useState({})
  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  return (
    <ConsensusContainer className="ag-theme-balham-dark">
      <AgGridReact
        onGridReady={onGridReady}
        rowMultiSelectWithClick={true}
        rowClassRules={rowClassRules}
        sizeColumnsToFit
        {...agOptions}
      />
    </ConsensusContainer>
  )
}

export default Consensus
