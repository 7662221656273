// @ts-nocheck
import { includes } from "ramda"

import { newFormatNumber } from "../../../../../../_shared"
import { getDeepNestedValue } from "../../../../../../../utils"

export const getFormattedValue = (header, report, diffMethod) => {
  // format diff column
  if (includes("diff", report.str_name)) {
    if (header.decimals && diffMethod === "value") {
      if (getDeepNestedValue(header.path, report) > 0) {
        return `+${newFormatNumber(getDeepNestedValue(header.path, report), {
          minDigit: header.decimals.min,
          maxDigit: header.decimals.max,
        })}`
      }
      return newFormatNumber(getDeepNestedValue(header.path, report), {
        minDigit: header.decimals.min,
        maxDigit: header.decimals.max,
      })
    }
    if (header.pct && diffMethod === "value" && getDeepNestedValue(header.path, report) !== null) {
      if (getDeepNestedValue(header.path, report) > 0) {
        return `+${newFormatNumber(+(10000 * getDeepNestedValue(header.path, report)).toFixed(8), {
          maxDigit: 0,
        })} bps`
      }
      return `${newFormatNumber(+(10000 * getDeepNestedValue(header.path, report)).toFixed(8), {
        maxDigit: 0,
      })} bps`
    }
    if (!header.pct && diffMethod === "value" && getDeepNestedValue(header.path, report) > 0) {
      return `+${newFormatNumber(getDeepNestedValue(header.path, report), {
        maxDigit: 0,
      })}`
    }

    if (header.pct && diffMethod === "pct") {
      return "N/A"
    }
    if (!header.pct && diffMethod === "pct" && getDeepNestedValue(header.path, report) !== null) {
      if (getDeepNestedValue(header.path, report) > 0) {
        return `+${newFormatNumber(+(100 * getDeepNestedValue(header.path, report)).toFixed(8), {
          minDigit: 2,
          maxDigit: 2,
        })} %`
      }
      return `${newFormatNumber(+(100 * getDeepNestedValue(header.path, report)).toFixed(8), {
        minDigit: 2,
        maxDigit: 2,
      })} %`
    } else {
      return newFormatNumber(getDeepNestedValue(header.path, report), {
        maxDigit: 0,
      })
    }
  }

  // format others columns
  if (header.pct && header.decimals)
    return newFormatNumber(getDeepNestedValue(header.path, report), {
      minDigit: header.decimals.min,
      maxDigit: header.decimals.max,
      pct: header.pct,
    })
  if (header.decimals)
    return newFormatNumber(getDeepNestedValue(header.path, report), {
      minDigit: header.decimals.min,
      maxDigit: header.decimals.max,
    })
  if (header.pct)
    return newFormatNumber(getDeepNestedValue(header.path, report), {
      minDigit: 1,
      maxDigit: 1,
      pct: header.pct,
    })
  return newFormatNumber(getDeepNestedValue(header.path, report), {
    maxDigit: 0,
  })
}
