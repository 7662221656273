// @ts-nocheck
import React, { memo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useDrop } from "react-dnd"
import update from "immutability-helper"
import { path } from "ramda"

import monitorsDuck from "../../../../../state/modules/monitors"
import { Loader } from "../../../../components"

import ItemTypes from "./ItemTypes"
import SheetItem from "./SheetItem"

const SheetsContainer = ({ monitor, sheetList, userId, deskOwnerId }) => {
  const dispatch = useDispatch()

  const findCard = useCallback(
    id => {
      const card = sheetList.filter(c => `${c}` === id)[0]
      return {
        card,
        index: sheetList.indexOf(card),
      }
    },
    [sheetList],
  )
  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id)
      const newCards = update(sheetList, {
        $splice: [
          [index, 1],
          [atIndex, 0, card], // move tab from 1 to 0
        ],
      })
      return dispatch(
        monitorsDuck.actions.setMonitorSheets({ id: monitor.id, sheet_ids: newCards }),
      )
    },
    [findCard, monitor, dispatch, sheetList],
  )

  const [, drop] = useDrop({ accept: ItemTypes.CARD })

  const sheetsReady = useSelector(path(["sheets", "ready"]))
  const monitorReady = monitor.ready

  if (!sheetsReady || !monitorReady) {
    return <Loader style={{ position: "relative" }} />
  }

  return (
    <div
      ref={drop}
      style={{
        padding: "8px",
        margin: "8px 0",
        overflowY: "auto",
        maxHeight: "380px",
      }}
    >
      {sheetList.map(sheetId => (
        <SheetItem
          key={sheetId}
          sheetId={`${sheetId}`}
          moveCard={moveCard}
          findCard={findCard}
          monitor={monitor}
          userId={userId}
          deskOwnerId={deskOwnerId}
        />
      ))}
    </div>
  )
}
export default memo(SheetsContainer)
