import { clearInterval } from "@datadog/browser-core"
import { useEffect, useRef, useState } from "react"
import { getDateDistance } from "../../../Ideas/utils/getDateDistance"

interface IntervalDateDistanceProps {
  date?: string | null
  interval: number
}

const useIntervalDateDistance = ({ date, interval }: IntervalDateDistanceProps) => {
  const [dateDistance, setDateDistance] = useState<string | undefined>(undefined)
  const intervalRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (date) {
        setDateDistance(getDateDistance(date))
      }
    }, interval)
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [date, interval])

  useEffect(() => {
    if (date) {
      setDateDistance(getDateDistance(date))
    }
  }, [date])

  return dateDistance
}

export default useIntervalDateDistance
