// @ts-nocheck
import * as R from "ramda"
import React from "react"
import { Card, H3, Elevation } from "@blueprintjs/core"
import { Grid, Row, Col, ButtonWithDialog } from "../../../../../../components"

import { enhanceIndep, isDsOrDsf } from "./utils"
import { colorLinkText } from "../../../../../../_shared/highcharts"
import BottomData from "./BottomData"
import TextResults from "./TextResults"
import Legend from "./Legend"
import ConvertToForecast from "./ConvertToForecast"

const FormatResults = ({ analyse }) => {
  const { dependent, independents } = analyse

  if (dependent) {
    const ranking = analyse.test_all ? ["first", "second", "third"] : ["first"]
    const canConvertToForecast = isDsOrDsf(dependent) && R.all(isDsOrDsf)(independents)

    return (
      <>
        {ranking
          // .filter(rnk => analyse[`${rnk}_intercept`])
          .map((rnk, index) => {
            const intercept = analyse[`${rnk}_intercept`]
            const enhancedIndeps = independents.map((indep, index) =>
              enhanceIndep({ indep, index, rnk }),
            )

            return (
              <Row key={index}>
                <Col xs={12}>
                  <Card key={index} elevation={Elevation.TWO}>
                    <H3 data-cy={`Formula${index}`}>
                      Y(n) = {intercept?.toFixed(2)}{" "}
                      {enhancedIndeps.map((indep, i) => (
                        <span key={i}>
                          <span> {indep.sign} </span>
                          <span style={colorLinkText(indep.color, i)}>
                            {`${indep.slope} X${indep.index}${indep.nPeriods}`}
                          </span>
                        </span>
                      ))}
                    </H3>
                    <Grid fluid>
                      <BottomData analyse={analyse} rnk={rnk} />
                    </Grid>

                    <TextResults index={index} analyse={analyse} rnk={rnk} />
                    {canConvertToForecast && (
                      <ButtonWithDialog
                        buttonProps={{
                          icon: "series-derived",
                          text: "Convert to forecast",
                          intent: "primary",
                        }}
                        dialogProps={{
                          title: "Convert result to forecast",
                          style: { width: "554px" },
                          intent: "primary",
                        }}
                      >
                        {({ setDialog }) => (
                          <ConvertToForecast
                            index={index}
                            analyse={analyse}
                            enhancedIndeps={enhancedIndeps}
                            // rnk={rnk}
                            intercept={intercept}
                            setDialog={setDialog}
                          />
                        )}
                      </ButtonWithDialog>
                    )}
                  </Card>
                </Col>
              </Row>
            )
          })}
        <Legend analyse={analyse} />
      </>
    )
  }
  return null
}

export default FormatResults
