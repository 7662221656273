/** @jsxImportSource @emotion/react */
import { SCIENT_AGGRID_COLORS, SCIENT_COLORS } from "../../../../styled/scientColors"
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TbodyContainer,
  TheadContainer,
  TrContainer,
} from "../../../../styled/table.styled"
import { MediumText } from "../../../../styled/text.styled"
import useRebalancingTable from "./hooks/useRebalancingTable"
import { PositionData } from "./types"

interface IPositionTableProps {
  position: PositionData
  emptyRows: number
  isLong?: boolean
}

const PositionTable = ({ position, emptyRows, isLong }: IPositionTableProps) => {
  const { formattedRows, formattedPinnedBottomRows, firstColumn, otherColumns } =
    useRebalancingTable({ position, emptyRows })

  return (
    // Adapt gridTemplateColumns to the number of metrics columns (=othersColumns) there's in the table
    <TableContainer gridTemplateColumns={`0.8fr 0.3fr repeat(${otherColumns.length}, 1fr)`}>
      <TheadContainer>
        <TrContainer>
          {/* Tickers column */}
          <HeaderCell justifyContent="flex-start" height="28px">
            <MediumText color={SCIENT_AGGRID_COLORS.headerFont}>{firstColumn.field}</MediumText>
          </HeaderCell>
          {/* To have a separation between ticker column (ie the first column) and others columns,
              an empty column is added beetween them. */}
          <th />
          {/* Metrics columns */}
          {otherColumns.map((colName, index) => (
            <HeaderCell
              key={index}
              justifyContent="flex-end"
              backgroundColor={isLong ? SCIENT_COLORS.green4 : SCIENT_COLORS.red3}
              height="28px"
            >
              <MediumText>{colName.field}</MediumText>
            </HeaderCell>
          ))}
        </TrContainer>
      </TheadContainer>

      <TbodyContainer>
        {formattedRows.map((row, rowIndex) => (
          <TrContainer key={rowIndex}>
            <RowCell index={rowIndex} height="28px" justifyContent="flex-start">
              <MediumText>{row[firstColumn.field]}</MediumText>
            </RowCell>
            <th />
            {otherColumns.map((col, colIndex) => (
              <RowCell key={colIndex} index={rowIndex} height="28px" justifyContent="flex-end">
                <MediumText>{row[col.field]}</MediumText>
              </RowCell>
            ))}
          </TrContainer>
        ))}

        {formattedPinnedBottomRows.map((bottomRow, bottomRowIndex) => (
          <TrContainer key={bottomRowIndex}>
            <RowCell
              backgroundColor={SCIENT_COLORS.darkGray1}
              height="28px"
              justifyContent="flex-start"
            >
              <MediumText color={SCIENT_AGGRID_COLORS.headerFont}>
                {bottomRow[firstColumn.field] as string}
              </MediumText>
            </RowCell>
            <th />
            {otherColumns.map((col, colIndex) => (
              <RowCell
                key={colIndex}
                backgroundColor={SCIENT_COLORS.darkGray1}
                height="28px"
                justifyContent="flex-end"
              >
                <MediumText color={SCIENT_AGGRID_COLORS.headerFont}>
                  {bottomRow[col.field]}
                </MediumText>
              </RowCell>
            ))}
          </TrContainer>
        ))}
      </TbodyContainer>
    </TableContainer>
  )
}

export default PositionTable
