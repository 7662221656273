import { HTMLSelect, Label } from "@blueprintjs/core"
import { Dispatch, SetStateAction } from "react"

import { FlexContainer, FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { roundNumber } from "../../../_shared"
import { FieldType } from ".././types/business"
import MetricUpdate from "../MetricUpdate"
import { NoDataIcon } from "../MetricUpdate.styled"
import { UpdatedMetric } from "../types/business"
import { NocChartMetadataContainer } from "./NocChartMetadata.styled"

interface INocChartMetadata {
  selectedAnalyst: string | null
  selectedDatetime: string
  current_update: UpdatedMetric
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>
  setSelectedPeriod: Dispatch<SetStateAction<string>>
  selectedPeriod: string
  setSelectedMetric: Dispatch<SetStateAction<string>>
  setSelectedUpdate: Dispatch<SetStateAction<UpdatedMetric | null>>
  availablePeriods: string[]
  selectedMetric: string
}

const NocChartMetadata = ({
  selectedAnalyst,
  selectedDatetime,
  current_update,
  setOpenChartDialog,
  setSelectedPeriod,
  selectedPeriod,
  setSelectedMetric,
  setSelectedUpdate,
  availablePeriods,
  selectedMetric,
}: INocChartMetadata) => {
  return (
    <NocChartMetadataContainer flexDirection="column" gap="10px">
      <FlexContainer gap="75px" alignItems="baseline">
        <FlexContainer flexDirection="column">
          <Label>
            Updated by {selectedAnalyst} on {selectedDatetime}
          </Label>
        </FlexContainer>
        <FlexContainerAndItem
          justifyContent="flex-start"
          gap="10px"
          alignItems="baseline"
          flexGrow={2}
        >
          <Label>Select Period</Label>
          <FlexContainer>
            <HTMLSelect
              fill
              iconName="caret-down"
              defaultValue={selectedPeriod}
              options={availablePeriods}
              onChange={({ target: { value } }) => setSelectedPeriod(value)}
            />
          </FlexContainer>
        </FlexContainerAndItem>
        <FlexContainerAndItem gap="20px" alignItems="baseline" flexGrow={2}>
          <Label>Select Metric</Label>
          <FlexContainer>
            <HTMLSelect
              fill
              iconName="caret-down"
              defaultValue={selectedMetric}
              options={[
                FieldType.KEY_PROFIT,
                FieldType.OSG,
                FieldType.PROFIT_MARGIN,
                FieldType.SALES,
                FieldType.EBITDA,
                FieldType.EBIT,
                FieldType.NET_INCOME,
              ]}
              onChange={({ target: { value } }) => setSelectedMetric(value)}
            />
          </FlexContainer>
        </FlexContainerAndItem>
      </FlexContainer>
      <FlexContainer justifyContent="space-between">
        <MetricUpdate
          updatedMetric={current_update}
          setOpenChartDialog={setOpenChartDialog}
          setSelectedPeriod={setSelectedPeriod}
          period={selectedPeriod}
          setSelectedMetric={setSelectedMetric}
          wrapping={false}
          inChartDialog={true}
          setSelectedUpdate={setSelectedUpdate}
        />
        <FlexContainer justifyContent="flex-end" gap="10px">
          <FlexContainer>
            {!current_update.value_change ? (
              <NoDataIcon>Current Value: --</NoDataIcon>
            ) : [FieldType.OSG, FieldType.PROFIT_MARGIN].includes(current_update.metric) ? (
              <span>
                Current Value: <strong>{roundNumber(current_update.current_value)} %</strong>
              </span>
            ) : (
              <span>
                Current Value: <strong>{roundNumber(current_update.current_value)}</strong>
              </span>
            )}
          </FlexContainer>
          |
          <FlexContainer>
            {!current_update.consensus_data || !current_update.consensus_data.consensus_value ? (
              <NoDataIcon>Current CON Value: --</NoDataIcon>
            ) : [FieldType.OSG, FieldType.PROFIT_MARGIN].includes(current_update.metric) ? (
              <span>
                Current CON Value:{" "}
                <strong>{roundNumber(current_update.consensus_data?.consensus_value)} %</strong>
              </span>
            ) : (
              <span>
                Current CON Value:{" "}
                <strong>{roundNumber(current_update.consensus_data?.consensus_value)}</strong>
              </span>
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </NocChartMetadataContainer>
  )
}
export default NocChartMetadata
