// @ts-nocheck
import autodux from "autodux"

import * as actions from "./actions"

const dsForecastsDuck = autodux({
  slice: "dsForecasts",
  initial: {
    loading: true,
    error: false,
    dsForecasts: {},
    all: {},
  },
  actions,
})

export default dsForecastsDuck
