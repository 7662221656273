// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import desksDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import shapeDesks from "./shapeDesks"

const {
  actions: { fetchDesks, fetchDesksOk, fetchDesksError },
} = desksDuck

const fetchDesksEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchDesks.type),
    mergeMap(action =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/desks/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          return fetchDesksOk(shapeDesks(response, action.payload))
        }),
        catchError(error => {
          return of(fetchDesksError(error.xhr.response))
        }),
      ),
    ),
  )

export default fetchDesksEpic
