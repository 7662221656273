import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { MediumText } from "../../../../../styled/text.styled"

export const ErrorsContentContainer = styled(FlexContainer)({
  marginLeft: "15px",
})
export const RowErrorContainer = styled.div({
  marginBottom: "10px",
})
export const ErrorMessageText = styled(MediumText)({
  marginLeft: "20px",
})

export const errorAlertCss = css({
  backgroundColor: SCIENT_COLORS.darkGray3,
})
export const errorTitleCss = css({
  marginBottom: "15px",
  color: "white",
})
