// @ts-nocheck
import { compose, replace, split } from "ramda"

// This function is to extract the list of slug in a loop_on or in loopParams.slugs
// Both are string and look like:
//        loop_on = "prevloop([stsrc0, fields({'slugs': ['total_net_interest','bridge_acq__div','fixed_assets']})])"
//        loopParams.slugs = "'slugs': ['total_net_interest','bridge_acq__div','fixed_assets']"
export const getCustomFieldSlugList = string => {
  const regex = /('slugs': \[)(.+')(\])/

  const slugsString = regex.exec(string)[2]
  const slugList = compose(split(","), replace(/'/g, ""))(slugsString)

  return slugList
}
