/**
 * Function which creates a debounced version of the
 * provided function that delays its execution.
 */

import { useEffect, useRef } from "react"

export const useDebounce = (func: (...args: any[]) => void, delay: number) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  /**
   * Cleanup the previous timeout
   * It ensures that any pending timeout is cleared when the component unmounts or re-renders.
   */
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const debouncedFunc = (...args: any[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      func(...args)
    }, delay)
  }

  return debouncedFunc
}
