// @ts-nocheck
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Button, ButtonGroup, ControlGroup } from "@blueprintjs/core"

import profileDuck from "../../../../../../../state/modules/profile"
import { InputGroup } from "../../../../../../components"

const TabItem = ({ stockSlug, availableTabs, addTab, removeTab, tab, setTabToDelete }) => {
  const dispatch = useDispatch()

  const [edit, setEdit] = useState(false)
  const [name, setName] = useState(tab.title)

  const startEdit = () => setEdit(true)
  const cancelEdit = () => {
    setName(tab.title)
    setEdit(false)
  }
  const confirmEdit = () => {
    dispatch(profileDuck.actions.stockRenameTab({ slug: stockSlug, tabId: tab.id, name }))
    setEdit(false)
  }

  const isActive = availableTabs.includes(tab.id)
  const index = availableTabs.indexOf(tab.id)
  const handleClick = () => (isActive ? removeTab(index) : addTab(tab.id))

  return (
    <>
      {edit ? (
        <ControlGroup fill>
          <InputGroup
            fill
            style={{ padding: "0px" }}
            inputProps={{
              value: name,
              onChange: ({ target: { value } }) => setName(value),
              onEnter: confirmEdit,
              onEsc: cancelEdit,
            }}
          />
          <Button minimal icon="undo" onClick={cancelEdit} style={{ maxWidth: "30px" }} />
          <Button
            minimal
            intent="primary"
            icon="tick"
            onClick={confirmEdit}
            style={{ maxWidth: "30px" }}
          />
        </ControlGroup>
      ) : (
        <ButtonGroup fill>
          <Button
            icon={isActive ? "eye-open" : "eye-off"}
            fill
            active={isActive}
            onClick={handleClick}
          >
            {tab.title}
          </Button>
          {tab.isCustom && (
            <>
              <Button minimal icon="edit" onClick={startEdit} />
              <Button minimal icon="trash" onClick={() => setTabToDelete(tab)} />
            </>
          )}
        </ButtonGroup>
      )}
    </>
  )
}

export default TabItem
