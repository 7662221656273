// @ts-nocheck
import { ofType } from "redux-observable"
import { map, switchMap } from "rxjs/operators"

import { fetchTransformOperations, fetchTransformOperationsOk } from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const fetchTransformOperationsEpic = (action$, state$, { ajax, auth }) => {
  return action$.pipe(
    ofType(fetchTransformOperations.type),
    switchMap(() => {
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/transforms/operations/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          return fetchTransformOperationsOk(response)
        }),
      )
    }),
  )
}

export default fetchTransformOperationsEpic
