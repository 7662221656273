import { Button, Icon } from "@blueprintjs/core"
import { isEmpty } from "lodash"
import { Dispatch, SetStateAction } from "react"
import { IUser } from "../../../../api/index"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { OmniSuggestLight } from "../../../components/OmniSuggestLight"
import UserSuggest from "../../../components/UserSuggest"
import {
  AbsoluteFilterIconContainer,
  FilterSearchInputContainer,
  RelativeFilterIconContainer,
} from "../../Ideas/List/Inbox/InboxFilters.styled"
import { FieldType } from "../types/business"
import MetricSelection from "./MetricSelection"
import { NocFilterItemContainer, NocFiltersContainer, StickyContainer } from "./NocFilters.styled"

interface INocFiltersProps {
  userFilter: IUser | null
  stockFilter: { id: number; bloomberg_code: string } | null
  setUserFilter: Dispatch<SetStateAction<IUser | null>>
  setStockFilter: Dispatch<SetStateAction<{ id: number; bloomberg_code: string } | null>>
  seenMetrics: FieldType[]
  setSeenMetrics: Dispatch<SetStateAction<Array<FieldType>>>
}

const NocFilters = ({
  userFilter,
  stockFilter,
  setUserFilter,
  setStockFilter,
  seenMetrics,
  setSeenMetrics,
}: INocFiltersProps) => {
  return (
    <StickyContainer>
      <NocFiltersContainer gap="50px" justifyContent="flex-start" alignItems="center">
        <FlexContainer gap="15px" alignItems="center">
          <RelativeFilterIconContainer>
            <Icon icon="filter" />
            <AbsoluteFilterIconContainer>
              <Icon icon="dollar" size={12} />
            </AbsoluteFilterIconContainer>
          </RelativeFilterIconContainer>
          <NocFilterItemContainer gap="10px">
            <FilterSearchInputContainer flexGrow={1}>
              <OmniSuggestLight
                exclude={{ ds: true, dsf: true, rf: true }}
                inputValueRenderer={item => item.bloomberg_code}
                // @ts-ignore
                onItemSelect={item => setStockFilter(item)}
                placeholder="Filter by Stock"
                selectedItem={stockFilter}
              />
            </FilterSearchInputContainer>
            {!isEmpty(stockFilter) && (
              <Button
                icon="cross"
                onClick={() => setStockFilter && setStockFilter(null)}
                fill={false}
              />
            )}
          </NocFilterItemContainer>
        </FlexContainer>
        <FlexContainer gap="15px" alignItems="center">
          <RelativeFilterIconContainer>
            <Icon icon="filter" />
            <AbsoluteFilterIconContainer>
              <Icon icon="person" size={12} />
            </AbsoluteFilterIconContainer>
          </RelativeFilterIconContainer>
          <NocFilterItemContainer gap="10px">
            <FilterSearchInputContainer flexGrow={1} flexDirection="row-reverse">
              <UserSuggest
                selectedUser={userFilter}
                // @ts-ignore
                onUserSelect={user => setUserFilter(user)}
                placeholder="Filter by Analyst"
              />
            </FilterSearchInputContainer>
            {!isEmpty(userFilter) && (
              <Button
                icon="cross"
                onClick={() => setUserFilter && setUserFilter(null)}
                fill={false}
              />
            )}
          </NocFilterItemContainer>
        </FlexContainer>
        <MetricSelection seenMetrics={seenMetrics} setSeenMetrics={setSeenMetrics} />
      </NocFiltersContainer>
    </StickyContainer>
  )
}
export default NocFilters
