// @ts-nocheck
import { css } from "@emotion/react"

export const textAreaCss = ({
  backgroundColor,
  borderRadius,
  boxShadow,
  fontFamily,
  fontSize,
  color,
  minHeight,
}) => {
  return css({
    backgroundColor,
    borderRadius,
    boxShadow,
    fontFamily,
    fontSize,
    color,
    minHeight,
    resize: "none",
  })
}
