// @ts-nocheck
//import { roundNumber } from "../../_shared"
import { sum, subtract } from "ramda"
import { roundNumber } from "../../_shared"
import { convertPath, getDeepNestedValue } from "../../../utils"

export const totalSum = ({ field, numVal, sideUpdate, values }) => {
  const { add = [], sub = [] } = sideUpdate
  const addVal = sum(
    add.map(src => {
      const srcName = convertPath(src, values, { flatten: true })
      if (srcName === field.name) {
        return roundNumber(numVal, 6)
      }
      return roundNumber(getDeepNestedValue(src, values), 6)
    }),
  )
  const subVal = sum(
    sub.map(src => {
      const srcName = convertPath(src, values, { flatten: true })
      if (srcName === field.name) {
        return roundNumber(numVal, 6)
      }
      return roundNumber(getDeepNestedValue(src, values), 6)
    }),
  )
  return subtract(addVal, subVal)
}

export const evAjdSum = ({ field, numVal, sideUpdate, values }) => {
  const { add = [], sub = [], multi = [] } = sideUpdate

  const multiVal = multi.map(src => {
    const srcName = convertPath(src, values, { flatten: true })
    if (srcName === field.name) {
      return roundNumber(numVal, 6) * 12
    }
    return roundNumber(getDeepNestedValue(src, values), 6) * 12
  })

  const addVal = sum(
    add.map(src => {
      const srcName = convertPath(src, values, { flatten: true })
      if (srcName === field.name) {
        return roundNumber(numVal, 6)
      }
      return roundNumber(getDeepNestedValue(src, values), 6)
    }),
  )

  const totalAdd = sum([addVal, multiVal])

  const subVal = sum(
    sub.map(src => {
      const srcName = convertPath(src, values, { flatten: true })
      if (srcName === field.name) {
        return roundNumber(numVal, 6)
      }
      return roundNumber(getDeepNestedValue(src, values), 6)
    }),
  )

  return subtract(totalAdd, subVal)
}
