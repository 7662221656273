// @ts-nocheck
import { fields } from "./fields"
import { compose, isEmpty, map, pathOr, propOr } from "ramda"
import { slugify } from "../../../../utils"

export const createFields = stock => {
  const splits = compose(
    map(({ slug, name, segs }) => ({
      slug,
      name,
      segs: segs.map(({ id, slug, name }) => ({
        id,
        slug,
        name,
        ...sharedFields,
      })),
    })),
    propOr([], "splits"),
  )(stock)

  const currencies = compose(
    map(id => ({
      slug: slugify(id),
      name: id,
      sales_exposure: "",
      cost_exposure: "",
    })),
    pathOr([], ["currencies"]),
  )(stock)

  const geos = compose(
    map(id => ({
      slug: slugify(id),
      name: id,
      share: "",
    })),
    pathOr([], ["geos"]),
  )(stock)

  const fields = compose(
    map(({ slug, name }) => ({
      slug: slug,
      value: "",
      name: name,
      kpi: false,
      // comment: "",
    })),
    pathOr([], ["fields"]),
  )(stock)

  let reportFields = {
    id: "",
    slug: "",
    stock: stock.slug,
    ...mainEmptyFields,
    ...sharedFields,
    ...cashflowFields,
    ...incomestatementFields,
    ...balancesheetFields,
  }

  if (!isEmpty(splits)) {
    reportFields = { ...reportFields, splits }
  }
  if (!isEmpty(currencies)) {
    reportFields = { ...reportFields, currencies }
  }

  if (!isEmpty(geos)) {
    reportFields = { ...reportFields, geos }
  }

  if (!isEmpty(fields)) {
    reportFields = { ...reportFields, fields }
  }
  return reportFields
}

const arrayToObject = (obj, el) => ({ ...obj, [`${el}`]: "" })

const mainEmptyFields = fields.main.reduce(arrayToObject, {})
const sharedFields = fields.shared.reduce(arrayToObject, {})
const incomestatementFields = fields.incomestatement.reduce(arrayToObject, {})
const balancesheetFields = fields.balancesheet.reduce(arrayToObject, {})
const cashflowFields = fields.cashflow.reduce(arrayToObject, {})
