import styled from "@emotion/styled"
import { FlexContainerProps, FlexItemProps, FlexContainerAndItem } from "./flexbox.styled"

type FullScreenViewProps = {
  fullscreen: boolean
}

export const FullScreenFlexContainer = styled(FlexContainerAndItem)<
  FlexContainerProps & FullScreenViewProps & FlexItemProps
>(props => ({
  height: props.fullscreen ? "100vh" : "100%",
  width: props.fullscreen ? "100vw" : "100%",
  position: props.fullscreen ? "absolute" : "relative",
  top: props.fullscreen ? 0 : undefined,
  left: props.fullscreen ? 0 : undefined,
  zIndex: props.fullscreen ? 10 : undefined,
  overflow: "auto",
}))

type FullScreenIconProps = {
  active: boolean
}

export const FullScreenIcon = styled.svg<FullScreenIconProps>(props => ({
  cursor: "pointer",
  backgroundColor: props.active ? "#3a4a5a" : undefined,
  borderRadius: props.active ? "5px" : undefined,
  path: {
    fill: props.active ? "white" : undefined,
  },
  "&:hover": {
    backgroundColor: "#3a4a5a",
    borderRadius: "5px",
    path: {
      fill: "white",
    },
  },
}))
