// @ts-nocheck
import { assoc, compose, converge, map, mergeDeepRight, pathOr } from "ramda"

export const getAllTabs = (stockSlug, profile) =>
  converge(mergeDeepRight, [
    compose(map(assoc("isCustom", false)), pathOr({}, ["userstockconfigDefaults", "defaultTabs"])),
    compose(
      map(assoc("isCustom", true)),
      pathOr({}, ["userstockconfigs", stockSlug, "customTabs"]),
    ),
  ])(profile)
