// @ts-nocheck
/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react"
import { filter, includes } from "ramda"
import { Button, Card, Elevation, InputGroup, Menu, MenuItem } from "@blueprintjs/core"
import { Grid, Row, Col } from "react-flexbox-grid"

import {
  LabelStyled,
  cardCss,
  gridCss,
  smallMenuCss,
  normalMenuCss,
  wideMenuCss,
  menuItemCss,
  inputGroupCss,
} from "./TransferList.styled"

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

export const TransferList = ({
  left,
  right,
  leftLabel,
  rightLabel,
  handleAllRight,
  handleCheckedRight,
  handleCheckedLeft,
  handleAllLeft,
  width = 1440,
}) => {
  const [checked, setChecked] = useState([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  // Logic to filter list of customFields exposed on left
  const [query, setQuery] = useState("")
  const [exposedToLeft, setExposeToLeft] = useState(left)

  const handleChangeQuery = e => {
    const { value } = e.target
    setQuery(value.toUpperCase())
  }

  // useEffect will update the list of fields exposed on left as soon as query or left changed
  useEffect(() => {
    const filteredValue = filter(e => includes(query, e.text.toUpperCase()), left)
    setExposeToLeft(filteredValue)
  }, [left, query])
  // End logic filter

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const customList = (items, viewFilter) => {
    return (
      <Card elevation={Elevation.THREE} css={cardCss}>
        {viewFilter && (
          <InputGroup
            placeholder="Filter"
            leftIcon="search"
            onChange={handleChangeQuery}
            small
            css={inputGroupCss}
          ></InputGroup>
        )}
        <Menu css={width > 1800 ? wideMenuCss : width > 1380 ? normalMenuCss : smallMenuCss}>
          {items.map(item => {
            return (
              <MenuItem
                key={item.key}
                text={item.text}
                active={checked.indexOf(item) !== -1}
                onClick={handleToggle(item)}
                css={menuItemCss}
              />
            )
          })}
        </Menu>
      </Card>
    )
  }

  const viewFilter = true

  return (
    <Card css={cardCss}>
      <Grid fluid css={gridCss}>
        <Row around="xs" middle="xs">
          <Col>
            <Row center="xs">
              <LabelStyled>{leftLabel}</LabelStyled>
            </Row>
            <Row>
              <Col>{customList(exposedToLeft, viewFilter)} </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <Button
                  icon="double-chevron-right"
                  large
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  icon="chevron-right"
                  large
                  onClick={() => handleCheckedRight(checked, setChecked, leftChecked, not)}
                  disabled={leftChecked.length === 0}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Button
                  icon="chevron-left"
                  large
                  onClick={() => handleCheckedLeft(checked, setChecked, rightChecked, not)}
                  disabled={rightChecked.length === 0}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  icon="double-chevron-left"
                  large
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row center="xs">
              <LabelStyled>{rightLabel}</LabelStyled>
            </Row>
            <Row>
              <Col> {customList(right)} </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </Card>
  )
}
