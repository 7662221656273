// @ts-nocheck
import React, { useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { Button, ButtonGroup, Card, Collapse, Icon } from "@blueprintjs/core"
import { reject } from "ramda"

import ItemTypes from "../itemTypes"
import { DeleteAlert } from "../../../../components"
import { getRowTitle } from "../../../utils"
import { Row } from "./Row"
import { StockRows } from "./StockRows"
import { Aggregate } from "./Aggregate"

const style = {
  // width: "20em",
  border: "1px solid",
  borderColor: "rgba(255, 255, 255, 0.4)",
  borderRadius: "3px 3px",
  padding: "0.7rem",
  // cursor: "move",
}
const handleStyle = {
  cursor: "move",
}
const Item = ({ rowId, values, setValues, card, index, handleCollapse, moveCard, findCard }) => {
  const [deleteAlert, setDeleteAlert] = useState(false)

  const title = getRowTitle(card)

  //For dnd Card
  const originalIndex = findCard(rowId).index
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, rowId, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    end: (dropResult, monitor) => {
      const { rowId: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveCard(droppedId, originalIndex)
      }
    },
  })
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => false,
    hover({ rowId: draggedId }) {
      if (draggedId !== rowId) {
        const { index: overIndex } = findCard(rowId)
        moveCard(draggedId, overIndex)
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  //End dnd Card

  const [showOptions, setShowOptions] = useState(false)
  const toogleShowOptions = () => setShowOptions(!showOptions)

  const handleDelete = () => {
    const newValues = {
      rowDefs: {
        ...values.rowDefs,
        rows: reject(row => row.rowId === card.rowId, values.rowDefs.rows),
      },
    }

    return setValues(newValues)
  }

  return (
    <div ref={preview} style={{ ...style, opacity }} fill="true">
      <Card key={card.rowId} id={card.rowId} elevation={card.active ? 4 : 0} style={{ padding: 0 }}>
        <ButtonGroup fill>
          <div ref={node => drag(drop(node))} style={handleStyle}>
            <Icon
              icon="move"
              size={20}
              style={{ margin: "auto", padding: "5px", color: "#AAB6C1" }}
            />
          </div>

          <Button
            fill
            minimal
            icon={card.active ? "caret-down" : "caret-right"}
            onClick={() => handleCollapse(card)}
          >
            {title}
          </Button>
          <Button type="button" icon="trash" onClick={() => setDeleteAlert(true)} />
        </ButtonGroup>

        <Collapse isOpen={card.active}>
          {card.kind === "row" && (
            <Row showOptions={showOptions} toogleShowOptions={toogleShowOptions} index={index} />
          )}
          {card.kind === "stockRows" && (
            <StockRows
              values={values}
              setValues={setValues}
              card={card}
              showOptions={showOptions}
              toogleShowOptions={toogleShowOptions}
              index={index}
            />
          )}
          {card.kind === "aggregate" && (
            <Aggregate
              values={values}
              setValues={setValues}
              card={card}
              showOptions={showOptions}
              toogleShowOptions={toogleShowOptions}
              index={index}
            />
          )}
        </Collapse>
        <DeleteAlert
          deleteAlert={deleteAlert}
          setDeleteAlert={setDeleteAlert}
          handleDelete={handleDelete}
          deleteMessage={`Delete ${title} ?`}
        />
      </Card>
    </div>
  )
}
export default Item
