// @ts-nocheck
import { Button, Card, Collapse, Colors, H5, H6, Label, MenuItem } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import { Field } from "formik"
import * as R from "ramda"
import { useState } from "react"
import { useSelector } from "react-redux"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { Col, Row } from "../../../../../../../components"
import { FormikTextInput } from "../FormikFields"
import FormikStyling from "../FormikStyling"

export const StockParam = ({ values, setValues, showOptions, toogleShowOptions, index }) => {
  const fieldList = useSelector(R.pathOr({}, ["shared", "fieldList"]))
  const formattedFields = [
    { type: "title1", text: "Stocks fields" },
    ...fieldList.stock_fields.map(row => ({
      text: `[STK] ${row.text}`,
      path: `STK_${row.key}`,
    })),
  ]

  const path = values.colDefs.cols[index].path
  const text = path && R.find(R.propEq("path", path))(formattedFields)["text"]

  const [filteredFields, setFilteredFields] = useState(formattedFields)
  const itemRenderer = (field, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    if (["title1", "title2"].includes(field.type))
      return (
        <li key={field.text} className="bp5-menu-header">
          {field.type === "title1" ? (
            <H5 style={{ color: Colors.GRAY4 }}>{field.text}</H5>
          ) : (
            <H6 style={{ color: Colors.GRAY5 }}>{field.text}</H6>
          )}
        </li>
      )
    return (
      <MenuItem
        active={modifiers.active}
        key={field.path}
        onClick={handleClick}
        text={field.text}
      />
    )
  }

  const handleQueryChange = query => {
    const results = formattedFields.filter(field => {
      if (["header", "subHeader"].includes(field.type)) return true
      const lowerQ = R.toLower(query)
      const titleIncludesQ = R.toLower(field.text).includes(lowerQ)
      if (field.path) {
        const cleanedPath = field.path.replace("'value'", "")
        return cleanedPath.includes(lowerQ) || titleIncludesQ
      }
      return titleIncludesQ
    })
    setFilteredFields(results)
  }

  const handleSubmitPath = field => {
    const newValues = {
      colDefs: {
        ...values.colDefs,
        cols: R.map(
          x =>
            R.indexOf(x, values.colDefs.cols) === index
              ? {
                  ...x,
                  path: field.path,
                  frontOpts: { ...x.frontOpts },
                }
              : x,
          values.colDefs.cols,
        ),
      },
    }
    return setValues(newValues)
  }

  const buttonText = text ? text : "Select Field"

  return (
    <Card>
      <Row middle="xs">
        <Col xs={8}>
          <Label>
            Field
            <Select
              className="sct-fullwidth"
              items={filteredFields}
              itemRenderer={itemRenderer}
              noResults={<MenuItem disabled={true} text="No results." />}
              onItemSelect={handleSubmitPath}
              onQueryChange={handleQueryChange}
            >
              <Button fill icon="form" rightIcon="caret-down" text={buttonText} />
            </Select>
            {path === "" && <p style={{ color: SCIENT_COLORS.orange3 }}>Field is required!</p>}
          </Label>
        </Col>
      </Row>

      <Button
        fill
        alignText="left"
        minimal
        icon={showOptions ? "caret-down" : "caret-right"}
        onClick={toogleShowOptions}
      >
        {showOptions ? "Hide" : ""} Options
      </Button>
      <Collapse isOpen={showOptions}>
        <Card>
          <Row>
            <Col xs={8}>
              <Field
                name={`colDefs.cols[${index}].frontOpts.titleOverride`}
                component={FormikTextInput}
                labelProps={{ label: "Title override" }}
                inputProps={{ placeholder: "Enter Title" }}
                helperText="Enter title or leave empty."
              />
            </Col>
          </Row>
          <FormikStyling index={index} />
        </Card>
      </Collapse>
    </Card>
  )
}
