// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import { deleteAnalyse, deleteAnalyseOk, deleteAnalyseError } from "../../index"
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const deleteAnalyseEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(deleteAnalyse.type),
    switchMap(({ payload: { id } }) => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/analysis/${id}`
      return ajax({
        url,
        method: "DELETE",
      }).pipe(
        map(() => deleteAnalyseOk({ id })),
        catchError(error => of(deleteAnalyseError({ error: error.xhr.response }))),
      )
    }),
  )

export default deleteAnalyseEpic
