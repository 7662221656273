// @ts-nocheck
import { Button, H5 } from "@blueprintjs/core"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../state/modules/sheets"
import Chart from "./Chart"
import Table from "./Table"

const TimeFrame = ({ desk, monitor, sheet, sheetId, output, height }) => {
  const [editRowItems, setEditRowItems] = useState({ show: false })

  const processingOutput = sheet.processing_output
  const pauseProcessing = sheet.pause_processing

  const dispatch = useDispatch()
  const reloadSheet = () => dispatch(sheetsDuck.actions.fetchSheetDetail({ id: sheet.id }))

  return (
    <>
      {processingOutput === true ? (
        <div
          style={{
            margin: "50px auto",
            height: "100%",
            width: "50%",
            textAlign: "center",
          }}
        >
          <H5>This large sheet in being processed and will be available soon</H5>
          <H5>Please reload to see the result.</H5>
          <Button text="Reload" icon="refresh" onClick={reloadSheet} large></Button>
        </div>
      ) : sheet.display === "table" ? (
        <Table
          desk={desk}
          monitor={monitor}
          sheetId={sheetId}
          editRowItems={editRowItems}
          setEditRowItems={setEditRowItems}
          pauseProcessing={pauseProcessing}
        />
      ) : (
        <Chart
          monitor={monitor}
          sheet={sheet}
          output={output}
          height={height}
          pauseProcessing={pauseProcessing}
        />
      )}
    </>
  )
}

export default TimeFrame
