// @ts-nocheck
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { filter, includes, pathOr, toLower, values } from "ramda"
import { MenuItem } from "@blueprintjs/core"

import { Suggest } from "../../../../../components"

export const MonitorSuggest = ({ deskCible, monitorCible, setMonitorCible }) => {
  const monitors = useSelector(pathOr({}, ["monitors", "data"]))
  const monitorList = values(monitors)
  const monitorsIdInDeskCible = deskCible && deskCible.monitor_ids

  const MonitorsForSuggest =
    deskCible && filter(e => includes(e.id, monitorsIdInDeskCible), monitorList)

  const [monitorResults, setMonitorResults] = useState(MonitorsForSuggest)

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        text={item.name}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }

  const updateDeskSearch = query => {
    if (query === "") {
      setMonitorResults(MonitorsForSuggest)
    }
    const selection = MonitorsForSuggest.filter(m => {
      const lowerQ = toLower(query)
      const titleIncludesQ = toLower(m.name).includes(lowerQ)
      return titleIncludesQ
    })
    setMonitorResults(selection)
  }

  return (
    <Suggest
      placeholder={monitorCible ? monitorCible.name : "Choose monitor"}
      resetOnClose={true}
      resetOnSelect={true}
      items={monitorResults}
      onItemSelect={item => setMonitorCible(item)}
      selectedItem={deskCible}
      onQueryChange={updateDeskSearch}
      itemRenderer={itemRenderer}
      large={false}
    />
  )
}
