// @ts-nocheck
import * as R from "ramda"
import { isFilledOnlyOneInList } from "./"

const errorComponent = requiredParamTitleList => (
  <>
    <p>Only one of this field must be filled:</p>
    <ul style={{ margin: "-4px 0 8px 0" }}>
      {requiredParamTitleList.map(e => (
        <li key={e}>{e}</li>
      ))}
    </ul>
  </>
)

// Function to know/test if at least one of the required param is filled.
export const validateParamLinkOneInList = ({ paramValues, paramLinkOneInList }) => {
  const errors = {}
  if (paramLinkOneInList && !R.isEmpty(paramLinkOneInList)) {
    // to know if only one of the parameters of One In List is filled. It's the goal to have one param filled.
    const isOnlyOneFilled = isFilledOnlyOneInList({ paramValues, paramLinkOneInList })

    // Titles of required params to be display in Callout
    const requiredParamTitleList = R.compose(
      R.map(e => e.title),
      R.prop("required_params"),
    )(paramLinkOneInList)

    if (isOnlyOneFilled === false) {
      errors.ONE_IN_LIST = errorComponent(requiredParamTitleList)
    }

    return errors
  }
  return errors
}
