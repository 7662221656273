/**
 * Handles all live events for Trading
 */
import { useTradingWaveUpdated } from "./useEvents"
import { useQueryClient } from "@tanstack/react-query"
import { Wave } from "../../views/pages/PortfolioManagement/Trading/types/wave"
import { WaveStatus } from "../../views/pages/PortfolioManagement/Trading/types/common"

interface ITradingLiveEvents {
  getWaves: () => Wave[]
  updateWave: (
    waveId: number | undefined,
    status: WaveStatus | undefined,
    execution_summary: string | undefined,
  ) => void
}

const useTradingLiveEvents = ({ updateWave, getWaves }: ITradingLiveEvents) => {
  const queryClient = useQueryClient()
  /**
   * Handles trade wave update Live event
   */
  useTradingWaveUpdated(event => {
    queryClient.invalidateQueries({ queryKey: ["waves"] })
    const waveToUpdate = getWaves().find(wave => wave.id === event.trade_wave.id)

    if (waveToUpdate) {
      // Receiving a wave from a live event, id is always a number
      updateWave(event.trade_wave.id, event.trade_wave.status, event.trade_wave.execution_summary)
    }
  })
}

export default useTradingLiveEvents
