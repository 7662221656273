// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { Button, InputGroup, Menu, MenuItem, Popover } from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"
import { useDrag, useDrop } from "react-dnd"

import { buttonAddCss, HeaderItemContainer } from "./HeaderBuilder.styled"
import ItemTypes from "./itemTypes"

export const HeaderItem = ({
  values,
  setValues,
  headerItems,
  headerItem,
  index,
  moveItem,
  findItem,
}) => {
  const { item, text, type, itemId } = headerItem
  const [modifyInput, setModifyInput] = useState(item)

  const onModify = e => {
    const { value } = e.target
    setModifyInput(value)
  }

  const modifySymbol = (value, index) => {
    const newHeaderItems =
      value.length > 0
        ? headerItems.map((headerItem, i) =>
            i === index ? { item: value, itemId: headerItem.itemId } : headerItem,
          )
        : R.remove(index, 1, headerItems)

    return setValues({
      ...values,
      headerItems: newHeaderItems,
    })
  }
  const deleteHeaderItem = index =>
    setValues({
      ...values,
      headerItems: headerItems.filter((headerItem, i) => i !== index),
    })

  //For dnd
  const originalIndex = findItem(itemId).index
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.BUTTON, itemId, originalIndex }, // In lastest version of dnd, type must be specify outside the item
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    end: (dropResult, monitor) => {
      const { itemId: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveItem(droppedId, originalIndex)
      }
    },
  })
  const [, drop] = useDrop({
    accept: ItemTypes.BUTTON,
    canDrop: () => false,
    hover({ itemId: draggedId }) {
      if (draggedId !== itemId) {
        const { index: overIndex } = findItem(itemId)
        moveItem(draggedId, overIndex)
      }
    },
  })
  //End dnd

  return (
    <HeaderItemContainer ref={node => drag(drop(node))} isDragging={isDragging}>
      <Popover
        content={
          <Menu>
            <MenuItem
              key={`delete${itemId}`}
              text={`Delete`}
              icon="delete"
              onClick={() => deleteHeaderItem(index)}
            />

            {!R.includes(item, [
              "<sourceName>",
              "<ticker>",
              "<splitName>",
              "<segName>",
              "<fieldName>",
            ]) && (
              <MenuItem
                key={`modify${itemId}`}
                text={`Modify `}
                icon="edit"
                children={
                  <InputGroup
                    type="text"
                    value={modifyInput}
                    placeholder="Enter text"
                    onChange={onModify}
                    rightElement={
                      <>
                        <Button
                          onClick={() => modifySymbol(modifyInput, index) && setModifyInput("")}
                          icon="tick"
                          intent="primary"
                          disabled={modifyInput === item}
                        />
                      </>
                    }
                  />
                }
              />
            )}
          </Menu>
        }
        placement="right-start"
      >
        <Button text={type === "dynamic" ? text : item} css={buttonAddCss(type)} small />
      </Popover>
    </HeaderItemContainer>
  )
}
