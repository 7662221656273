// @ts-nocheck
import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Label,
  NumericInput,
  Switch,
  TextArea,
} from "@blueprintjs/core"
import { Field } from "formik"
import { pathOr } from "ramda"

import { SCIENT_COLORS } from "../../../../../../../styled/scientColors"

export const resolveError = (form, field, defaultValue = null) =>
  field.name
    .split(/[.[\]'"]/)
    .filter(p => p)
    .reduce((o, p) => (o ? o[p] : defaultValue), form.errors)

export const FormikTextInput = ({ field, labelProps, inputProps, title }) => {
  return (
    <FormGroup
      // helperText="Helper text with details..."
      labelFor="text-input"
      {...labelProps}
    >
      <InputGroup {...field} {...inputProps} />
      {title === "" && <p style={{ color: SCIENT_COLORS.orange3 }}>Title is required!</p>}
      {/* displayed error message when title is missing */}
    </FormGroup>
  )
}

export const FormikTextArea = ({ field, inputProps, labelProps }) => {
  return (
    <FormGroup labelFor="text-input" {...labelProps}>
      <TextArea fill {...field} {...inputProps} />
    </FormGroup>
  )
}

export const FormikSwitch = ({ field, inputProps, labelProps }) => {
  return (
    <Switch
      checked={field.value}
      style={{ margin: "20px" }}
      {...field}
      {...labelProps}
      {...inputProps}
    />
  )
}

export const FormikSelect = ({ field, inputProps }) => {
  return <HTMLSelect value={field.value} fill {...field} {...inputProps} />
}

export const fakeSyntheticEvent = (value, id) => ({
  persist: () => {
    // do nothing.
  },
  target: {
    type: "change",
    id,
    name: id,
    value,
  },
})

export const FormikDecimalInput = ({ field, form, inputProps }) => {
  const handleValueChange = value => form.setFieldValue(field.name, value)
  const error = resolveError(form, field)
  return (
    <>
      <NumericInput min={0} onValueChange={handleValueChange} {...field} {...inputProps} />
      {error && <p style={{ color: SCIENT_COLORS.orange3 }}>{error.replace(field.name, "")}</p>}
    </>
  )
}

export const Decimals = ({ index }) => {
  return (
    <ControlGroup style={{ margin: "0 8px" }} fill>
      <Label>
        Min decimals
        <Field
          name={`colDefs.cols[${index}].frontOpts.decimals.min`}
          component={FormikDecimalInput}
        />
      </Label>
      <Label>
        Max decimals
        <Field
          name={`colDefs.cols[${index}].frontOpts.decimals.max`}
          component={FormikDecimalInput}
        />
      </Label>

      <Field
        name={`colDefs.cols[${index}].frontOpts.pct`}
        labelProps={{ label: "In percent" }}
        inputProps={{ min: 0 }}
        component={FormikSwitch}
      />
    </ControlGroup>
  )
}

export const ColWidth = ({ index }) => {
  return (
    <Label>
      Column width (in pixel)
      <Field
        name={`colDefs.cols[${index}].frontOpts.width`}
        component={FormikDecimalInput}
        inputProps={{ min: 40, placeholder: "Enter value to customise" }}
        fill
      />
    </Label>
  )
}

const freqOptions = ["d", "w", "m", "q", "h", "fy"]
const FreqButton = ({ field, form, freq }) => {
  const handleSetFreq = () => form.setFieldValue(field.name, freq)
  return (
    <Button
      key={freq}
      active={freq === field.value}
      // disabled={freq === field.value}
      onClick={handleSetFreq}
      text={freq.toUpperCase()}
    />
  )
}

const FormikNumericInput = ({ field, form, inputProps, kind, path, timeAware }) => {
  const handleValueChange = value => form.setFieldValue(field.name, value)
  const error = resolveError(form, field)
  return (
    <>
      <NumericInput
        minorStepSize={null}
        stepSize={1}
        onValueChange={handleValueChange}
        disabled={kind === "stockField" && !path}
        min={timeAware && timeAware === "fy1-4" ? 1 : undefined}
        max={kind === "factor" ? 0 : timeAware === "fy1-4" ? 4 : undefined}
        {...field}
        {...inputProps}
      />
      {error && <p style={{ color: SCIENT_COLORS.orange3 }}>{error.replace(field.name, "")}</p>}
      {/* displayed error message */}
    </>
  )
}

export const TimeChoice = ({ index, path, timeAware, kind, last }) => {
  return (
    <ControlGroup style={{ marginLeft: "8px" }} fill>
      <FormGroup label="Freq" style={{ marginTop: "4px" }}>
        {freqOptions.map(freq => (
          <Field
            key={freq}
            name={`colDefs.cols[${index}].freq`}
            freq={freq}
            component={FreqButton}
          />
        ))}
      </FormGroup>
      {/* hide periods when last reported facor is called */}
      {kind === "factor" && last === true ? null : (
        <>
          <Label>
            Lower period
            <Field
              name={`colDefs.cols[${index}].lowerPeriod`}
              component={FormikNumericInput}
              kind={kind}
              path={path}
              timeAware={timeAware}
            />
          </Label>
          <Label>
            Upper period
            <Field
              name={`colDefs.cols[${index}].upperPeriod`}
              component={FormikNumericInput}
              kind={kind}
              path={path}
              timeAware={timeAware}
            />
          </Label>
        </>
      )}
    </ControlGroup>
  )
}

export const Agg = ({ index }) => {
  return (
    <Field
      name={`colDefs.cols[${index}].agg`}
      labelProps={{ label: "Aggregation" }}
      component={FormikSwitch}
    />
  )
}

export const HideButton = ({ field, form, card }) => {
  const hide = pathOr(false, ["hide"], card)
  const icon = hide ? "eye-off" : "eye-open"
  const toggleHide = hide => {
    if (hide) {
      return form.setFieldValue(field.name, false)
    } else {
      return form.setFieldValue(field.name, true)
    }
  }
  return <Button key={`hide${field.name}`} icon={icon} onClick={() => toggleHide(hide)} />
}
