// @ts-nocheck
export const stockFields = fieldList => {
  return [
    { type: "title1", text: "Group Sales" },
    ...fieldList.shared_fields.sales_fields.map(row => ({
      text: `[Group] ${row.text}`,
      path: row.key,
      pct: row.pct ? row.pct : false,
      timeAware: row.time_aware,
    })),

    { type: "title1", text: "Orders" },
    ...fieldList.shared_fields.orders_fields.map(row => ({
      text: `[Group] ${row.text}`,
      path: row.key,
      pct: row.pct ? row.pct : false,
      timeAware: row.time_aware,
    })),

    { type: "title1", text: "Profit" },
    ...fieldList.shared_fields.profit_fields.map(row => ({
      text: `[Group] ${row.text}`,
      path: row.key,
      pct: row.pct ? row.pct : false,
      timeAware: row.time_aware,
    })),

    { type: "title1", text: "Backlog" },
    ...fieldList.shared_fields.backlog_fields.map(row => ({
      text: `[Group] ${row.text}`,
      path: row.key,
      pct: row.pct ? row.pct : false,
      timeAware: row.time_aware,
    })),

    { type: "title1", text: "IncomeStatement" },
    ...fieldList.report_fields.is_fields.map(row => ({
      text: `[IS] ${row.text}`,
      path: row.key,
      pct: row.pct ? row.pct : false,
      timeAware: row.time_aware,
    })),

    { type: "title1", text: "Balancesheet" },
    ...fieldList.report_fields.bs_fields.map(row => ({
      text: `[BS] ${row.text}`,
      path: row.key,
      pct: row.pct ? row.pct : false,
      timeAware: row.time_aware,
    })),

    { type: "title1", text: "Cashflow" },
    ...fieldList.report_fields.cf_fields.map(row => ({
      text: `[CF] ${row.text}`,
      path: row.key,
      pct: row.pct ? row.pct : false,
      timeAware: row.time_aware,
    })),

    { type: "title1", text: "Valuation" },
    ...fieldList.valuation_fields.map(row => ({
      text: `[VAL] ${row.text}`,
      path: `VAL_${row.key}`,
      timeAware: row.time_aware,
    })),

    { type: "title1", text: "Custom fields" },
    ...fieldList.field_fields.map(row => ({
      text: `[FLD] ${row.text}`,
      path: `fields, slug:${row.key}, value`,
      timeAware: row.time_aware,
    })),
  ]
}
