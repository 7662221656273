// @ts-nocheck
import * as R from "ramda"

import { shaveRFInstance } from "./utils"
import { useCallback } from "react"

export const updateConnectable = R.curry((params, value, nodes) => {
  const { source, sourceHandle, target, targetHandle } = params
  const sourceIndex = source && R.findIndex(R.propEq("id", source))(nodes)
  const sourceHandleIndex = source && parseInt(sourceHandle.split("|")[1])
  const sourceHandleConnectableLens = R.lensPath([
    sourceIndex,
    "data",
    "outputs",
    sourceHandleIndex,
    "connectable",
  ])

  const targetIndex = target && R.findIndex(R.propEq("id", target))(nodes)
  const targetHandleIndex = target && parseInt(targetHandle.split("|")[1])
  const targetHandleConnectableLens = R.lensPath([
    targetIndex,
    "data",
    "inputs",
    targetHandleIndex,
    "connectable",
  ])
  const newNodes = R.compose(
    R.when(() => !R.isNil(source), R.set(sourceHandleConnectableLens, value)),
    R.when(() => !R.isNil(target), R.set(targetHandleConnectableLens, value)),
  )(nodes)
  return newNodes
})

export const useOnSave = (rfInstance, activeItem, setActiveItem) =>
  useCallback(() => {
    if (rfInstance) {
      const flow = shaveRFInstance(rfInstance.toObject())
      const updatedItem = R.assoc("formula_tree", flow, activeItem)
      setActiveItem(updatedItem)
    }
  }, [rfInstance, activeItem, setActiveItem])
