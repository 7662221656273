import { format } from "date-fns"

import { SmallText } from "../../../../styled/text.styled"
import { Header, StockMetadataCard } from "./StockMetadata.styled"
import { TradingStock } from "./types"

interface IStockMetadataProps {
  selectedStock: TradingStock
}

type MetadataField = {
  headerName: string
  field: keyof TradingStock
  isDate?: boolean
}

const metadataFields: MetadataField[] = [
  { headerName: "Created", field: "created_at", isDate: true },
  { headerName: "Updated", field: "updated_at", isDate: true },
  { headerName: "BB Company", field: "bb_company", isDate: false },
]

/**
 * Shows uneditable stock metadata
 */

const StockMetadata = ({ selectedStock }: IStockMetadataProps) => (
  <StockMetadataCard>
    <table>
      <tbody>
        {metadataFields.map(metadataField => {
          const value = metadataField.isDate
            ? format(new Date(selectedStock[metadataField.field] as string), "MM/dd/yyyy HH:mm")
            : selectedStock[metadataField.field]

          return (
            <tr key={metadataField.field}>
              <Header>
                <SmallText>{metadataField.headerName} </SmallText>
              </Header>
              <td>
                <SmallText>{value as string | number}</SmallText>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </StockMetadataCard>
)

export default StockMetadata
