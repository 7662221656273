// @ts-nocheck
import { divide, sum } from "ramda"
import { roundNumber } from "../../_shared"
import { convertPath, getDeepNestedValue } from "../../../utils"

export const division = ({ field, numVal, sideUpdate, values }) => {
  const { nume = [], denom = [] } = sideUpdate
  const numeVal = sum(
    nume.map(src => {
      const srcName = convertPath(src, values, { flatten: true })
      if (srcName === field.name) {
        return roundNumber(numVal, 6)
      }
      return roundNumber(getDeepNestedValue(src, values), 6)
    }),
  )
  const denomVal = sum(
    denom.map(src => {
      const srcName = convertPath(src, values, { flatten: true })
      if (srcName === field.name) {
        return roundNumber(numVal, 6)
      }
      return roundNumber(getDeepNestedValue(src, values), 6)
    }),
  )
  return divide(numeVal, denomVal)
}
