// @ts-nocheck
import axios from "axios"
import { JWT_AUTH_TOKEN } from "./constants"
import jwtDecode from "jwt-decode"

const logout = (next = null) => {
  localStorage.removeItem(JWT_AUTH_TOKEN)
  if (next) {
    next()
  }
}
const hasToken = () => !!localStorage.getItem(JWT_AUTH_TOKEN)
const getToken = () => localStorage.getItem(JWT_AUTH_TOKEN)
const storeToken = token => {
  localStorage.setItem(JWT_AUTH_TOKEN, token)
  /**
   * In production, CloudFront consumes token via Cookie in order
   * to authenticate images on s3
   */
  if (process.env.NODE_ENV === "production") {
    const expireDate = new Date()

    /**
     * Expires in 100 days
     */
    const expireTime = expireDate.getTime() + 100 * 24 * 3600 * 1000
    expireDate.setTime(expireTime)
    document.cookie = `token=${token};domain=scient.io;path=/;expires=${expireDate.toUTCString()}; Secure`
  }
}
const deleteToken = () => localStorage.removeItem(JWT_AUTH_TOKEN)
const decodeToken = token => jwtDecode(token)
const setAuthHeaders = token => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

const auth = {
  hasToken,
  getToken,
  logout,
  storeToken,
  deleteToken,
  decodeToken,
  setAuthHeaders,
}

export default auth
