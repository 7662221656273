import { useQuery } from "@tanstack/react-query"
import { useApi } from "./useApi"

export const useQueryGroups = () => {
  const { scientApi, apiAuthenticated, api2FAVerified } = useApi()
  return useQuery({
    queryKey: ["groups"],
    queryFn: scientApi.groups.get,
    staleTime: Infinity,
    enabled: apiAuthenticated && api2FAVerified,
  })
}
