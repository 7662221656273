// @ts-nocheck
import React, { useState, useEffect } from "react"
import { Button, ButtonGroup, Classes, Dialog, Label } from "@blueprintjs/core"
import { equals } from "ramda"

import { ConnectedStockPathSelect, InputGroup, Switch } from "../../../../../components"
import StylingPalette from "./StylingPalette"
import Decimals from "./Decimals"

const AddEditDialog = ({ kind, stockSlug, possibleData, handleClose, handleSubmit, initial }) => {
  const [type, setType] = useState()
  const [path, setPath] = useState()
  const [text, setText] = useState("")
  const [pct, setPct] = useState()
  const [styles, setStyles] = useState()
  const [decimals, setDecimals] = useState()

  const handleSelectPath = field => {
    setPath(field.path)
    setText(field.text)
  }

  useEffect(() => {
    setType(initial.type)
    setPath(initial.path)
    setText(initial.text)
    setPct(initial.pct)
    setStyles(initial.styles)
    setDecimals(initial.decimals || { min: 0, max: 1 })
  }, [initial])

  const row = {
    type,
    path,
    text,
    pct,
    styles,
    decimals: equals(decimals, { min: 0, max: 1 }) ? undefined : decimals,
  }

  const handleText = ({ target: { value } }) => setText(value)
  const handleType = type => {
    if (type !== "data") {
      setPath(undefined)
      setPct(undefined)
    } else {
      setPath([])
      setPct(false)
    }
    setType(type)
  }

  return (
    <Dialog
      className="bp5-dark"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      icon="plus"
      title={kind}
      intent="primary"
      isOpen={possibleData}
      onClose={handleClose}
      enforceFocus={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <Label>
          Type{" "}
          <ButtonGroup>
            <Button active={type === "data"} text="Data" onClick={() => handleType("data")} />
            <Button
              active={type === "title1"}
              text="Title 1"
              onClick={() => handleType("title1")}
            />
            <Button
              active={type === "title2"}
              text="Title 2"
              onClick={() => handleType("title2")}
            />
            {/* <Button active={type === "forEach"} text="Loop" onClick={() => handleType("forEach")} /> */}
          </ButtonGroup>
        </Label>
        <InputGroup
          labelProps={{ label: "Header" }}
          inputProps={{
            placeholder: "New name…",
            value: text,
            onChange: handleText,
            onEnter: () => handleSubmit(row),
            onEsc: handleClose,
          }}
        />
        {type === "data" && (
          <>
            <Label>
              Source
              <ConnectedStockPathSelect slug={stockSlug} value={path} update={handleSelectPath} />
            </Label>
            <Switch
              name="pct"
              label="in percent"
              checked={pct}
              onChange={({ currentTarget: { checked } }) => setPct(checked)}
            />
          </>
        )}
        <Decimals decimals={decimals} setDecimals={setDecimals} />
        <StylingPalette styles={styles} setStyles={setStyles} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent="danger" icon="remove" text="Cancel" onClick={handleClose} />
          <Button
            intent="primary"
            icon="floppy-disk"
            text={kind}
            onClick={() => handleSubmit(row)}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default AddEditDialog
