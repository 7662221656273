/**
 * Custom formatting of locale for display of Date distances
 * Taken from https://github.com/date-fns/date-fns/issues/1706
 */
const formatDistanceLocale = {
  lessThanXSeconds: "1min",
  xSeconds: "1min",
  halfAMinute: "1min",
  lessThanXMinutes: "{{count}}min",
  xMinutes: "{{count}}min",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}m",
  xMonths: "{{count}}m",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
}

export const formatDistance = (
  token: keyof typeof formatDistanceLocale,
  count: string,
  options: {
    addSuffix: boolean
    comparison: number
  },
) => {
  options = options || {}

  const result = formatDistanceLocale[token].replace("{{count}}", count)

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return "in " + result
    } else {
      return result + " ago"
    }
  }

  return result
}
