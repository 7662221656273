// @ts-nocheck
import { Divider, Icon, Tag } from "@blueprintjs/core"
import { pathOr, sort, values } from "ramda"
import { useSelector } from "react-redux"

import { Col, Grid, Row } from "../../../../../components"
import ForecastCheckbox from "./ForecastCheckbox"
import SelectQuarterAmount from "./SelectQuarterAmount"
import SelectYearAmount from "./SelectYearAmount"

const ConsensusPref = ({ stockSlug, dispatch }) => {
  const allRForecasts = values(useSelector(pathOr([], ["rForecasts", stockSlug, "forecasts"])))

  const allRForecastsByDate = sort(
    (a, b) => new Date(b.owner_updated_at) - new Date(a.owner_updated_at),
    allRForecasts,
  )
  const style = { fontWeight: "bold" }

  return (
    <Grid fluid>
      <Row center="xs">
        <Col xs={8}>
          <Tag large minimal fill style={style}>
            Forecasts
          </Tag>
        </Col>
        <Col xs={2}>
          <Tag large minimal fill>
            <Icon icon="star" />
          </Tag>
        </Col>
        <Col xs={2}>
          <Tag large minimal fill>
            <Icon icon="disable" />
          </Tag>
        </Col>
      </Row>
      <Divider />
      {allRForecastsByDate.map(forecast => (
        <ForecastCheckbox
          key={forecast.id}
          dispatch={dispatch}
          forecast={forecast}
          stockSlug={stockSlug}
        />
      ))}
      <Divider />
      <Row center="xs">
        <Col xs={6} style={{ margin: "10px auto 0" }}>
          <SelectQuarterAmount dispatch={dispatch} stockSlug={stockSlug} />
        </Col>

        <Col xs={6} style={{ margin: "10px auto 0" }}>
          <SelectYearAmount dispatch={dispatch} stockSlug={stockSlug} />
        </Col>
      </Row>
    </Grid>
  )
}

export default ConsensusPref
