// @ts-nocheck
import * as R from "ramda"

import { capitalizeFirstLetter, exposeFirstXStringFromList } from "../../../../../../../../utils/"
import { getHeaderItems } from "../../../utils"
import { getLoopParams } from "./"

export const getRowTreeSingleName = ({ header, enhancedPath, formula_tree = [] }) => {
  const headerItems = getHeaderItems(header, enhancedPath)
  return headerItems.length > 0
    ? R.join(
        " ",
        headerItems.map(e => e.text),
      )
    : R.isEmpty(formula_tree)
    ? "New header"
    : "New Data"
}

const getRowTreeGroupName = (node, enhancedPath) => {
  if (node.temp_id.includes("newGroup")) return "New Group"

  const isSegs = enhancedPath.segs ? true : false

  const loopParams = getLoopParams({ activeItem: node, loopOnSegs: isSegs })

  const srcName = enhancedPath.primarySrc === true ? "Primary Source" : enhancedPath.srcName

  const slugsObject =
    loopParams.kind && loopParams.slugs && JSON.parse(`{${R.replace(/'/g, '"', loopParams.slugs)}}`)

  const niceSlugs = slugsObject
    ? slugsObject.active
      ? "Active"
      : slugsObject.all
      ? "All"
      : loopParams.kind === "fields" || isSegs
      ? `(${R.replace(/_/g, " ", exposeFirstXStringFromList(slugsObject.slugs, 2))})`
      : R.replace(/_/g, " ", exposeFirstXStringFromList(slugsObject.slugs, 2))
    : ""

  return node.name
    ? node.name
    : node.loop_on
    ? (loopParams.kind === "fields" || isSegs) && R.includes("slugs", loopParams.slugs)
      ? srcName + " Chosen " + capitalizeFirstLetter(loopParams.kind) + " " + niceSlugs
      : srcName + " " + niceSlugs + " " + capitalizeFirstLetter(loopParams.kind)
    : "New Group"
}

// This function returns a nice name to display for each node of the rowTree
// For Groups, if user has defined a name, it is displayed, else an automatic name is generate.
export const getRowTreeItemName = (node, path, enhancedPath) => {
  switch (node.kind) {
    case "single":
      return getRowTreeSingleName({
        header: node.header,
        enhancedPath,
        formula_tree: node.formula_tree,
      })
    case "group":
      return getRowTreeGroupName(node, enhancedPath)
    default:
      throw new Error("Wrong node kind.")
  }
}
