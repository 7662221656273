// @ts-nocheck
import { getTime } from "date-fns"

import * as R from "ramda"
import { allColors, predColors } from "../../components/colors"

const formatSeries = analyse => {
  const { colTitles, rowData } = analyse.grid

  const indepSeries = rowData.map((row, index) => {
    return {
      name: row.extra.header,
      yAxis: row.extra?.frontOpts?.chart?.own_y_axis ? index + 1 : 0,
      color: allColors[index],
      dashStyle: index !== 0 && "dot",
      data: R.compose(
        R.sortBy(R.prop(0)),
        R.values(),
        R.mapObjIndexed((obj, key) => [
          /**
           * From string, we generate Unix time in ms with getTime().
           * Unix time is used by HighCharts.
           *  */
          getTime(new Date(R.find(R.propEq("field", key), colTitles).end.valueOf())),
          Number(R.propOr(null, "value", obj)),
        ]),
        R.omit(["extra"]),
      )(row),
      tooltip: formatTooltip(),
    }
  })

  const varGraphs =
    analyse.method === "var" && analyse.var_graph
      ? {
          main: JSON.parse(analyse.var_graph.data),
          upper: JSON.parse(analyse.var_graph_upperbound.data),
          lower: JSON.parse(analyse.var_graph_lowerbound.data),
        }
      : null

  const dependent = rowData[0]

  const varSeries = varGraphs
    ? [
        {
          name: `${dependent.extra.header} VAR`,
          yAxis: 0,
          tooltip: formatTooltip(),
          data: formatData(varGraphs.main),
          color: predColors[1],
        },
        {
          name: `${dependent.extra.header} VAR Lower`,
          yAxis: 0,
          tooltip: formatTooltip(),
          data: formatData(varGraphs.lower),
          color: predColors[0],
        },
        {
          name: `${dependent.extra.header} VAR Upper`,
          yAxis: 0,
          tooltip: formatTooltip(),
          data: formatData(varGraphs.upper),
          color: predColors[2],
        },
      ]
    : []

  const output = [...indepSeries, ...varSeries]

  return output
}

export default formatSeries

const formatTooltip = () => ({
  headerFormat: "{point.x:%d-%m-%Y}",
  pointFormat: "{series.name}: {point.y}",
  valueDecimals: 3,
})

const formatData = varDict =>
  R.map(([dateStr, value]) => [parseInt(dateStr), value], R.toPairs(varDict))
