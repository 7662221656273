import styled from "@emotion/styled"
import { ShadowContainer } from "../../../../styled/layout.styled"

type IdeaCreateTargetPriceContainerProps = {
  hidden: boolean
}

export const IdeaCreateTargetPriceContainer = styled(ShadowContainer)(
  (props: IdeaCreateTargetPriceContainerProps) => ({
    padding: "10px",
    display: props.hidden ? "none" : "flex",
  }),
)
