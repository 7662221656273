import { Control, FieldValues } from "react-hook-form"

import FactoryIcon from "../../../../icons/FactoryIcon"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { MediumText } from "../../../../styled/text.styled"
import SectorSelect from "../components/SectorSelect"
import { HeaderContainer, SectorContainer, SectorsContainer } from "./StockSectors.styled"
import { SectorLevel, TradingSector, TradingStock } from "./types"

type IStockSectorsProps = {
  stockSectors: TradingSector[]
  control: Control<FieldValues & TradingStock>
}

export const StockSectors = ({ stockSectors, control }: IStockSectorsProps) => {
  return (
    <FlexContainer flexDirection="column" gap="10px">
      <HeaderContainer alignItems="center" gap="25px">
        <FactoryIcon height="22px" width="22px" />
        <MediumText>Sectors</MediumText>
      </HeaderContainer>

      <SectorsContainer>
        {Object.values(SectorLevel)
          .filter(value => typeof value === "number")
          .map((value, index) => {
            return (
              <SectorContainer key={value} alignItems="center" gap="50px">
                <MediumText>L{value}</MediumText>
                <SectorSelect
                  control={control}
                  level={value as SectorLevel}
                  name={`sectors.${index}`}
                />
              </SectorContainer>
            )
          })}
      </SectorsContainer>
    </FlexContainer>
  )
}

export default StockSectors
