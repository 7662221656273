// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import { updateFolders, updateFoldersOk, updateFoldersError } from "../../index"
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const updateFoldersEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(updateFolders.type),
    switchMap(({ payload: { folders } }) => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/analysis/folders/`
      return ajax({
        url,
        method: "PATCH",
        body: folders,
      }).pipe(
        map(({ response }) => updateFoldersOk({ folders: response })),
        catchError(error => of(updateFoldersError({ error: error.xhr.response }))),
      )
    }),
  )

export default updateFoldersEpic
