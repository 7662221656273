import { isEqual, omitBy } from "lodash"
import { OrderInputs } from "../Trading/types/form"
import { defaultOrder } from "../Trading/WaveCreationForm"

// Check if an order is empty.
export const isDefaultOrder = (order: OrderInputs) => {
  const cleanedOrder = omitBy(order, v => v === null || v === undefined)
  return isEqual(cleanedOrder, defaultOrder)
}

// Check if all orders of a wave are empty.
export const isWaveEmpty = (orders: OrderInputs[]) =>
  !orders.map(order => isDefaultOrder(order)).includes(false)
