import { ScientValueFormatter } from "../components/agGridMapping"

interface IFormatValueProps {
  value: number | string | null
  type: ScientValueFormatter
  pctDecimal?: number
  floatDecimal?: number
  withoutPercentSign?: boolean
  pctMinFractionDigits?: number
  pctMaxFractionDigits?: number
  floatMinFractionDigits?: number
  floatMaxFractionDigits?: number
}

/**
 * Function to format a value according its type.
 * As 'toFixed' is combined with 'Intl.NumberFormat', props 'minimumFractionDigits'
 * and 'maximumFractionDigits' is required to assure having always the same given number
 * of decimal.
 * If not, 'Intl.NumberFormat' will remove decimal if for exemple we have 1.00 even with '.toFixed(2)'
 */

export const formatValue = ({
  value,
  type,
  pctDecimal = 2,
  floatDecimal = 2,
  withoutPercentSign = false,
  pctMinFractionDigits = 0,
  pctMaxFractionDigits = 3,
  floatMinFractionDigits = 0,
  floatMaxFractionDigits = 3,
}: IFormatValueProps) => {
  switch (type) {
    case ScientValueFormatter.PERCENTAGE:
      return typeof value === "number"
        ? `${Intl.NumberFormat("en-GB", {
            minimumFractionDigits: pctMinFractionDigits,
            maximumFractionDigits: pctMaxFractionDigits,
          }).format(+(value * 100).toFixed(pctDecimal))} ${!withoutPercentSign ? "%" : ""}`
        : "-"
    case ScientValueFormatter.NUMBER_GB:
      return typeof value === "number"
        ? Intl.NumberFormat("en-GB", {
            minimumFractionDigits: floatMinFractionDigits,
            maximumFractionDigits: floatMaxFractionDigits,
          }).format(+value.toFixed(floatDecimal))
        : "-"
    case ScientValueFormatter.INT_GB:
      return typeof value === "number" ? Intl.NumberFormat("en-GB").format(+value.toFixed(0)) : "-"
    case ScientValueFormatter.TEXT_CAPITALIZED:
      return typeof value === "string" ? (value as string).toUpperCase() : undefined
    case ScientValueFormatter.TEXT:
      return typeof value === "string" ? value : undefined
    default:
      return undefined
  }
}
