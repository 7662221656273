// @ts-nocheck
import { Callout } from "@blueprintjs/core"
import { Field } from "formik"
import * as R from "ramda"
import { useDispatch, useSelector } from "react-redux"

import { addSingleItem } from "../../../../../../../../../../../../state/modules/search"
import { SCIENT_COLORS } from "../../../../../../../../../../../../styled/scientColors"
import { OmniSuggest } from "../../../../../../../../../../../components"
import { ParamTitle } from "../../../../components"

const DsChoiceHelper = ({ name, values, param }) => {
  const sourceId = values[name] && values[name].replace(/'/g, "")
  const source = useSelector(R.pathOr(null, ["search", "omniByIds", "results", sourceId]))
  const src_name = source ? source.src_name : ""

  const dispatch = useDispatch()

  return (
    <>
      <ParamTitle
        param={param}
        title={source ? `Selected dataSet: ${src_name}` : "Select dataSet source"}
      />
      <Field name={name}>
        {({ form: { touched, errors, setValues } }) => {
          const handleSubmit = item => {
            dispatch(addSingleItem({ item }))
            setValues({ source: `'${item.kind_id}'` })
          }
          return (
            <>
              <OmniSuggest onSubmit={handleSubmit} exclude={{ st: true, rf: true }} />
              {touched[name] && errors[name] && (
                <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                  {errors[name]}
                </Callout>
              )}
            </>
          )
        }}
      </Field>
    </>
  )
}

export default DsChoiceHelper
