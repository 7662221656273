// @ts-nocheck
import { addYears } from "date-fns"
import { find, head, last, propEq, toLower, toUpper } from "ramda"

import { mergeBySlug } from "./mergeBySlug"

export const getRelatedData = ({ emptyReport, reports, report_periods, editSlug = false }) => {
  const reportPeriods = report_periods.replace(/ /g, "").split(",")
  const reportById = slug => ({ ...emptyReport, ...find(propEq("slug", slug))(reports) })

  const prevYearSlug = report => toLower(`${report.year - 1}${report.period}`)

  if (!editSlug) {
    const prevReport = { ...emptyReport, ...last(reports) }
    const reportsLength = reports.length
    const prevReportToDisplay =
      prevReport.period === "FY" ? { ...emptyReport, ...reports[reportsLength - 2] } : prevReport

    const { year, period } = prevReport

    const reportPeriodAndYear =
      period === last(reportPeriods)
        ? { period: toUpper(head(reportPeriods)), year: year + 1 }
        : {
            period: toUpper(reportPeriods[reportPeriods.findIndex(x => x === period) + 1]),
            year: year,
          }

    const prevYearReportSlug = prevYearSlug(reportPeriodAndYear)
    const prevYearReport = reportById(prevYearReportSlug)

    const report = {
      ...emptyReport,
      ...reportPeriodAndYear,
      start: prevYearReport.start
        ? addYears(new Date(prevYearReport.start), 1).toISOString()
        : null,
      end: prevYearReport.end ? addYears(new Date(prevYearReport.end), 1).toISOString() : null,
    }

    return {
      report,
      prevReport: prevReportToDisplay,
      prevYearReport,
    }
  }

  // To get all CustomFields, we need to merge customFields from existing report with customFields in emptyReport

  const reportFields = reportById(editSlug).fields
  const emptyReportFields = emptyReport.fields
  const allCustomFields = mergeBySlug(emptyReportFields, reportFields)

  const report = { ...reportById(editSlug), fields: allCustomFields }

  const { year, period } = report

  const reportPeriodsLength = reportPeriods.length

  const prevSlug =
    period === head(reportPeriods)
      ? `${year - 1}${toLower(reportPeriods[reportPeriodsLength - 2])}`
      : `${year}${toLower(reportPeriods[reportPeriods.findIndex(x => x === period) - 1])}`
  const prevReport = {
    ...emptyReport,
    ...find(propEq("slug", prevSlug))(reports),
  }
  const prevYearReportSlug = prevYearSlug(report)
  const prevYearReport = reportById(prevYearReportSlug)

  return {
    report,
    prevReport,
    prevYearReport,
  }
}
