// @ts-nocheck
import * as R from "ramda"
import * as recast from "recast"

const getFormulaSources = tree => {
  let formulaSources = []
  const recursion = object => {
    if (object.kind === "single" && !R.isEmpty(object.formula_tree)) {
      const nodes = object.formula_tree.nodes
      const selectSources = R.compose(
        R.map(e => R.replace(/'/g, "", e)),
        R.reject(e => e === "stsrc0"),
        R.reject(e => e === false),
        R.flatten(),
        R.map(
          node =>
            ["select", "selectds"].includes(node.type) &&
            R.path(["data", "params", 0, "value"], node),
        ),
      )(nodes)

      formulaSources = [...formulaSources, ...selectSources]
    }

    if (object.children) {
      object.children.forEach(child => recursion(child))
    }
  }
  // we transform tree (which is an array) in object with key children to help looping
  recursion({ children: tree })
  return formulaSources
}

const getloopSources = tree => {
  const ast = recast.parse(JSON.stringify(tree))
  const literal = []
  recast.visit(ast, {
    visitLiteral: function (path) {
      const slug = path.value.value
      literal.push(slug)
      this.traverse(path)
    },
  })

  const loopOnList = R.compose(
    R.filter(e => e.includes("prevloop") && !e.includes("prevloop([segs") && !e.includes("stsrc0")),
    R.reject(e => e === undefined),
    R.reject(e => e === null),
    R.reject(e => typeof e === "boolean"),
    R.reject(e => typeof e === "number"),
  )(literal)

  const regex = /(rf\d+_?\d*)|(st\d+_?\d*)/g
  const loopSources = JSON.stringify(loopOnList).match(regex)

  return loopSources
}

// Use to have the list of sourceId in order to get related omniByIds
export const getNgTfSources = tree => {
  const loopSources = getloopSources(tree)
  const formulaSources = getFormulaSources(tree)
  const sources = R.uniq(R.union(formulaSources, loopSources))

  return sources
}
