// @ts-nocheck
import * as R from "ramda"

import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { ButtonGroup, Button, ControlGroup, Classes } from "@blueprintjs/core"

import { fetchAnalyses, updateFolders } from "../../../../../state/modules/analysis"
import { ButtonWithDialog, InputGroup } from "../../../../components"

const Buttons = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [name, setName] = useState("")

  const fetchingAnalyses = useSelector(R.path(["analysis", "analyses", "fetching"]))
  const folders = useSelector(R.path(["analysis", "folders", "list"]))

  const handleCreateFolder = setDialog => {
    dispatch(updateFolders({ folders: [...folders, { name }] }))
    setDialog(false)
    return setName("")
  }

  return (
    <ButtonGroup fill>
      <Button
        icon="refresh"
        loading={fetchingAnalyses}
        onClick={() => dispatch(fetchAnalyses())}
        text="Refresh"
      />
      <ButtonWithDialog
        buttonProps={{ icon: "folder-new", text: "New folder" }}
        dialogProps={{ title: "Create folder" }}
      >
        {({ setDialog }) => (
          <div className={Classes.DIALOG_BODY}>
            <ControlGroup vertical={true}>
              <InputGroup
                inputProps={{
                  type: "text",
                  value: name,
                  placeholder: "Folder name",
                  onChange: event => setName(event.target.value),
                  onEnter: () => handleCreateFolder(setDialog),
                  onEsc: () => setDialog(false),
                }}
              />
              <Button
                fill
                intent="primary"
                icon="floppy-disk"
                text="Ok"
                onClick={() => handleCreateFolder(setDialog)}
              />
            </ControlGroup>
          </div>
        )}
      </ButtonWithDialog>
      <Button
        icon="regression-chart"
        intent="primary"
        onClick={() => navigate("/analysis/new/")}
        text="New analyse"
      />
    </ButtonGroup>
  )
}

export default Buttons
