// @ts-nocheck
import { Button, InputGroup, Menu, MenuDivider, MenuItem, Popover } from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"
import { useDrag, useDrop } from "react-dnd"

import { getNiceFormulaItem } from "../../../../utils"
import ItemTypes from "./itemTypes"

const style = {
  padding: "2px",
}

const operators = ["+", "-", "*", "/", "**", "(", ")"]

export const FormulaItem = ({
  values,
  setValues,
  card,
  formulaItems,
  formulaItem,
  index,
  moveItem,
  findItem,
  toggleShowPanels,
  DFState,
}) => {
  const rowDefs = values.rowDefs
  const { item, itemId } = formulaItem

  // Logic for input
  const regexp = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/g // to control input: accept only number

  const [modifyInput, setModifyInput] = useState("")
  const modifyInputIsNumber = regexp.test(modifyInput)

  const onModify = e => {
    const { value } = e.target
    setModifyInput(value)
  }

  const modifySymbol = (value, index) =>
    setValues({
      rowDefs: R.map(
        row =>
          row.rowId === card.rowId
            ? {
                ...row,
                frontOpts: {
                  ...row.frontOpts,
                  formulaItems: formulaItems.map((formulaItem, i) =>
                    i === index ? { item: value, itemId: formulaItem.itemId } : formulaItem,
                  ),
                },
              }
            : row,
        rowDefs,
      ),
    })

  const deleteFomulaItem = index => {
    setValues({
      rowDefs: R.map(
        row =>
          row.rowId === card.rowId
            ? {
                ...row,
                frontOpts: {
                  ...row.frontOpts,
                  formulaItems: formulaItems.filter((formulaItem, i) => i !== index),
                },
              }
            : row,
        rowDefs,
      ),
    })
  }

  //For dnd
  const originalIndex = findItem(itemId).index
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.BUTTON, itemId, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    end: (dropResult, monitor) => {
      const { itemId: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveItem(droppedId, originalIndex)
      }
    },
  })
  const [, drop] = useDrop({
    accept: ItemTypes.BUTTON,
    canDrop: () => false,
    hover({ itemId: draggedId }) {
      if (draggedId !== itemId) {
        const { index: overIndex } = findItem(itemId)
        moveItem(draggedId, overIndex)
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  //End dnd

  const niceText = getNiceFormulaItem({ item, dataframes: DFState, rowDefs: values.rowDefs })
  const menuItemText = `${R.take(16, niceText)}${niceText.length > 16 ? "..." : ""}`

  return (
    <div ref={node => drag(drop(node))} style={{ ...style, opacity }} fill="true">
      <Popover
        content={
          <Menu>
            {(item.includes("#") || item.includes("@")) && (
              <MenuItem
                key={`edit${itemId}`}
                text={`Edit ${menuItemText}`}
                icon="edit"
                onClick={() => toggleShowPanels(item)}
              />
            )}

            {/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/gi.test(item) === true && ( // test if item is a number
              <MenuItem
                key={`modify${itemId}`}
                text={`Modify ${menuItemText}`}
                icon="edit"
                children={
                  <InputGroup
                    type="numeric"
                    value={modifyInput}
                    placeholder="Enter number"
                    onChange={onModify}
                    rightElement={
                      <>
                        <Button
                          onClick={() => modifySymbol(modifyInput, index) && setModifyInput("")}
                          text={
                            modifyInputIsNumber === true || !modifyInput ? "Modify" : "Not a number"
                          }
                          intent={modifyInputIsNumber === true ? "primary" : "none"}
                          disabled={modifyInputIsNumber === false}
                        />
                      </>
                    }
                  />
                }
              />
            )}
            <MenuItem
              key={`delete${itemId}`}
              text={`Delete ${menuItemText}`}
              icon="delete"
              onClick={() => deleteFomulaItem(index)}
            />
            {/[+-/*()]/g.test(item) &&
              item.length < 3 && ( // to match only the operators
                <>
                  <MenuDivider />
                  {operators.map(op => (
                    <MenuItem
                      key={op}
                      text={op}
                      icon="blank"
                      onClick={() => modifySymbol(op, index)}
                    />
                  ))}
                </>
              )}
          </Menu>
        }
        placement="right-start"
      >
        <Button
          text={niceText}
          intent={
            item.includes("#") ||
            item.includes("@") ||
            /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/gi.test(item) === true //test if item is a number
              ? "primary"
              : /[()]/g.test(item)
              ? "warning"
              : "none"
          }
          small
        />
      </Popover>
    </div>
  )
}
