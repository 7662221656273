import styled from "@emotion/styled"
import { FlexContainerAndItem } from "../../../../styled/flexbox.styled"

export const PortfolioContainer = styled(FlexContainerAndItem)({
  height: "100%",
  width: "100%",
  gridArea: "content",
  overflow: "auto",
  padding: "10px 20px 15px 20px",
  ".bp5-divider": {
    borderColor: "rgba(242,242,242, 0.2)",
    margin: "2px 0 0 0",
  },
})
