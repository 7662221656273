/** @jsxImportSource @emotion/react */
import { Button, Icon, OverlayToaster, Toaster, Tooltip } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_AGGRID_COLORS } from "../../../../styled/scientColors"
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TbodyContainer,
  TheadContainer,
  TrContainer,
} from "../../../../styled/table.styled"
import { MediumText } from "../../../../styled/text.styled"
import { ButtonWithAlert } from "../../../components"
import { WithLoading } from "../../../components/WithLoading"
import { isDefaultOrder } from "../utils/checkEmptyWave"
import { removeDuplicate } from "../utils/removeDuplicateInArrayOfObject"
import { BookText, InputContainer, bp5InputCss, inputCss } from "./OrdersForm.styled"
import { BookSuggest } from "./components/BookSuggest"
import { FloatInput } from "./components/FloatInput"
import { IntegerInput } from "./components/IntegerInput"
import { NumericInputWithSuffix } from "./components/NumericInputWithSuffix"
import { PercentageInput } from "./components/PercentageInput"
import { TickerSuggest } from "./components/TickerSuggest"
import { TimeInput } from "./components/TimeInput"
import { WaveSelect } from "./components/WaveSelect"
import useComplianceRules from "./hooks/useComplianceRules"
import { ExecutionType, ITickerItem, OrderType, OrdersColumn, Side } from "./types/common"
import { OrderInputs, WaveInputs } from "./types/form"

interface IWaveFormProps {
  orders: OrderInputs[]
  control: Control<FieldValues & WaveInputs>
  register: UseFormRegister<FieldValues & WaveInputs>
  deleteOrder: (index: number) => void
  copyPreviousOrder: (index: number) => void
  errors: FieldErrors<WaveInputs>
  watch: UseFormWatch<WaveInputs>
  trigger: UseFormTrigger<WaveInputs>
  setValue: UseFormSetValue<WaveInputs>
}

// HOC to add loading spinner to TickerSuggest
const TickerSuggestWithLoading = WithLoading(TickerSuggest)

// Create toaster instance
const toaster: Toaster = OverlayToaster.create({ position: "top" })

const OrdersForm = ({
  orders,
  control,
  register,
  deleteOrder,
  copyPreviousOrder,
  errors,
  watch,
  trigger,
  setValue,
}: IWaveFormProps) => {
  // State to manage ticker items and to be able to add new Ticker not in list provided by back-end
  const [tickerItems, setTickerItems] = useState<ITickerItem[]>([])
  const watchAccountId = watch("account_id")

  // Get list of tickers from user's universe
  const { complianceRules, error, isLoading: isLoadingTickerUniverse } = useComplianceRules()

  /**
   * Set ticker items with duplicates removed when compliance rules are fetched.
   */
  useEffect(() => {
    if (complianceRules?.user_universe) {
      setTickerItems(
        removeDuplicate(
          complianceRules.user_universe.map(ticker => ({ ticker })),
          "ticker",
        ) as ITickerItem[],
      )
    }
  }, [complianceRules])

  /**
   * Show error message if universe is not found.
   */
  useEffect(() => {
    if (error) {
      toaster.show({
        message:
          "Universe not found, please contact support through intercom to add it. Meanwhile you will have to add tickers manually.",
        intent: "warning",
      })
    }
  }, [error])

  return (
    <FlexContainer>
      <TableContainer gridTemplateColumns="30px repeat(11, 1fr) 2fr 30px">
        <TheadContainer>
          <TrContainer>
            <th />
            {(Object.keys(OrdersColumn) as Array<keyof typeof OrdersColumn>).map(colName => (
              <HeaderCell key={colName}>
                <MediumText color={SCIENT_AGGRID_COLORS.headerFont}>
                  {OrdersColumn[colName]}
                </MediumText>
              </HeaderCell>
            ))}
            {/* empty <th> as Delete Button header */}
            <th />
          </TrContainer>
        </TheadContainer>

        <TbodyContainer>
          {orders.map((order: OrderInputs, index: number) => {
            /**
             * retrieve each row state and test if order's empty,
             * in order to avoid playing validation rules on empty row
             */
            const rowState = watch(`orders[${index}]`)
            const isDefaultState = isDefaultOrder(rowState)

            return (
              <TrContainer key={order.id}>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  {index !== 0 && (
                    <Tooltip content="Copy previous order" placement="bottom" hoverOpenDelay={1500}>
                      <Button icon="bring-data" onClick={() => copyPreviousOrder(index)} />
                    </Tooltip>
                  )}
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  {!watchAccountId ? (
                    <BookText>Select account first</BookText>
                  ) : (
                    <BookSuggest
                      name={`orders[${index}].book_id`}
                      accountId={watchAccountId}
                      customCss={bp5InputCss(index)}
                      control={control}
                      rules={{ required: !isDefaultState && "Book is required" }}
                    />
                  )}
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <TickerSuggestWithLoading
                    name={`orders[${index}].symbol.ticker`}
                    index={index}
                    tickerItems={tickerItems}
                    setTickerItems={setTickerItems}
                    customCss={bp5InputCss(index)}
                    isLoading={isLoadingTickerUniverse}
                    loaderSize={15}
                    control={control}
                    rules={{ required: !isDefaultState && "Ticker is required" }}
                    trigger={() => trigger([`orders[${index}].execution_type`])}
                    setValue={setValue}
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <WaveSelect
                    name={`orders[${index}].side` as const}
                    index={index}
                    optionsEnum={Side}
                    control={control}
                    watch={watch}
                    rules={{ required: !isDefaultState && "Side is required" }}
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <NumericInputWithSuffix
                    name={`orders[${index}].amount_usd` as const}
                    index={index}
                    control={control}
                    rules={{
                      max: {
                        value: 5000000,
                        message: "USD is limited to 5M",
                      },
                      validate: (value: number) => {
                        const quantityValue = watch(`orders[${index}].quantity`)
                        if (!isDefaultState) {
                          if (quantityValue && value) {
                            return "USD should be empty if quantity is filled"
                          }
                          if (!quantityValue && !value) {
                            return "USD is required"
                          }
                        }
                        // Validation passed
                        return true
                      },
                    }}
                    onChange={() => trigger(`orders[${index}].quantity`)}
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <IntegerInput
                    name={`orders[${index}].quantity` as const}
                    register={register}
                    onChange={() => trigger(`orders[${index}].amount_usd`)}
                    error={errors?.orders && errors.orders[index]?.quantity}
                    validate={{
                      validate: (value: number) => {
                        const amountUsdValue = watch(`orders[${index}].amount_usd`)
                        if (!isDefaultState) {
                          if (amountUsdValue && value) {
                            return "Quantity should be empty if USD is filled"
                          }
                          if (!amountUsdValue && !value) {
                            return "Quantity is required"
                          }
                        }
                        return true
                      },
                    }}
                    min={{ value: 1, message: "Minimum Quantity is 1" }}
                    minOfInput={1} //to avoid negative number when using key down
                    customCss={inputCss(index)}
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <WaveSelect
                    name={`orders[${index}].order_type`}
                    index={index}
                    optionsEnum={OrderType}
                    control={control}
                    watch={watch}
                    rules={{
                      required: !isDefaultState ? "Type is required" : false,
                      validate: (value: OrderType) => {
                        const executionTypeValue = watch(`orders[${index}].execution_type`)
                        if (!isDefaultState) {
                          if (executionTypeValue === "strict_limit" && value !== "limit") {
                            return "Type should be 'limit' when Execution is 'Strict limit'"
                          }
                        }
                        return true
                      },
                    }}
                    onChange={() => trigger(`orders[${index}].limit_price`)}
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <FloatInput
                    name={`orders[${index}].limit_price` as const}
                    register={register}
                    min={{ value: 0.01, message: "Minimum limit_price is 0.01" }}
                    error={errors?.orders && errors.orders[index]?.limit_price}
                    validate={{
                      validate: (value: number) => {
                        const orderTypeValue = watch(`orders[${index}].order_type`)
                        if (!isDefaultState) {
                          if (orderTypeValue === "limit" && !value) {
                            return "Limit is required when order Type is 'Limit'"
                          }
                          if (orderTypeValue === "market" && value) {
                            return "Limit should be empty if Type is 'Market'"
                          }
                        }
                        return true
                      },
                    }}
                    minOfInput={0.01}
                    customCss={inputCss(index)}
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <WaveSelect
                    name={`orders[${index}].execution_type`}
                    index={index}
                    optionsEnum={ExecutionType}
                    control={control}
                    watch={watch}
                    rules={{
                      validate: value => {
                        if (!isDefaultState) {
                          if (!value) {
                            return "Execution is required'"
                          }
                          // extract the last 2 characters from the ticker (corresponding to the market where the ticker is trade)
                          const market = watch(`orders[${index}].symbol.ticker`).slice(-2)
                          if (
                            !["US", "GR"].includes(market) &&
                            value === ExecutionType.PRE_MARKET
                          ) {
                            return "Pre market works only for US or GR market"
                          }
                          if (
                            watch(`orders[${index}].symbol.ticker`).endsWith("INDEX") &&
                            value !== ExecutionType.FUTURE
                          ) {
                            return "Execution type must be FUTURE when ticker ends by INDEX"
                          }
                        }
                        return true
                      },
                    }}
                    onChange={() =>
                      trigger([
                        `orders[${index}].time_in_market`,
                        `orders[${index}].execution_details`,
                        `orders[${index}].symbol.ticker`,
                      ])
                    }
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <PercentageInput
                    name={`orders[${index}].execution_cap_pct` as const}
                    index={index}
                    control={control}
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <IntegerInput
                    name={`orders[${index}].time_in_market` as const}
                    register={register}
                    customCss={inputCss(index)}
                    error={errors?.orders && errors.orders[index]?.time_in_market}
                    index={index}
                    validate={{
                      validate: (value: number) => {
                        const executionTypeValue = watch(`orders[${index}].execution_type`)
                        const targetEndValue = watch(`orders[${index}].target_end_datetime`)
                        if (!isDefaultState) {
                          if (value && executionTypeValue === "here") {
                            return "Time in Market should not be specified if execution type is 'Here'"
                          }

                          if (value && targetEndValue) {
                            return "Time in Market should be empty if Target End is filled"
                          }
                        }
                        return true
                      },
                    }}
                    onChange={() => trigger([`orders[${index}].target_end_datetime`])}
                    min={{ value: 5, message: "Minimum time in market is 5 min" }}
                    max={{ value: 480, message: "maximum time in market is 480 min" }}
                    minOfInput={5} //to avoid number under when using key down
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <TimeInput
                    name={`orders[${index}].target_end_datetime` as const}
                    register={register}
                    watch={watch}
                    index={index}
                    customCss={inputCss(index)}
                    error={errors?.orders && errors.orders[index]?.target_end_datetime}
                    trigger={() => trigger([`orders[${index}].time_in_market`])}
                    isEmptyRowState={isDefaultState}
                  />
                </RowCell>
                <RowCell index={index} padding="1px 1px 1px 0px">
                  <InputContainer>
                    <input
                      type="text"
                      {...register(`orders[${index}].execution_details` as const, {
                        validate: (value: string) => {
                          const executionTypeValue = watch(`orders[${index}].execution_type`)
                          if (!isDefaultState) {
                            if (executionTypeValue === "pair" && !value) {
                              return "Execution detail is required when execution type is 'Pair'"
                            }
                            return true
                          }
                        },
                      })}
                      css={inputCss(index)}
                    />
                    {errors?.orders && errors.orders[index]?.execution_details && (
                      <Tooltip
                        content={errors?.orders[index]?.execution_details?.message}
                        placement="bottom"
                        openOnTargetFocus={false}
                        compact
                      >
                        <Icon icon="error" size={20} intent="warning" />
                      </Tooltip>
                    )}
                  </InputContainer>
                </RowCell>

                <RowCell index={index} padding="1px 1px 1px 0px">
                  <ButtonWithAlert
                    buttonProps={{
                      icon: "trash",
                    }}
                    alertProps={{
                      intent: "danger",
                      confirmButtonText: "Yes, delete",
                      onConfirm: () => deleteOrder(index),
                    }}
                  >
                    <p>Do you really want to delete this order?</p>
                  </ButtonWithAlert>
                </RowCell>
              </TrContainer>
            )
          })}
        </TbodyContainer>
      </TableContainer>
    </FlexContainer>
  )
}

export default OrdersForm
