// @ts-nocheck
import { combineEpics } from "redux-observable"

import createSheetEpic from "./createSheetEpic"
import fetchSheetListEpic from "./fetchSheetListEpic"
import fetchSheetDetailEpic from "./fetchSheetDetailEpic"
import updateSheetEpic from "./updateSheetEpic"
import deleteSheetEpic from "./deleteSheetEpic"

const sheetEpics = combineEpics(
  createSheetEpic,
  fetchSheetListEpic,
  fetchSheetDetailEpic,
  updateSheetEpic,
  deleteSheetEpic,
)

export default sheetEpics
