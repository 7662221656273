// @ts-nocheck
/**
 * Function to round number to n decimals
 * To round to 0 decimals, precision= 0
 * To round to 2 decimals, precision= 2
 * To round to 3 decimals, precision= 3
 * Etc...
 * By default precision equals 2.
 */

export const roundNumber = (num, precision = 2) => {
  const mult = Math.pow(10, precision)
  return Math.round(mult * num) / mult
}
