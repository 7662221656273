// @ts-nocheck
import * as R from "ramda"
import { isArray, isObjectArray } from "../../arrayUtils"

const handleEqualKeys = (key, left, right) => {
  if (isArray(left) && R.equals(left, right)) {
    return left
  }
  if (key === "overrides") {
    return []
  }
  if (key === "year") {
    return right.toString()
  }

  if (isObjectArray(left) && isObjectArray(right)) {
    const deepObjectList = R.flatten([left, right])

    if (deepObjectList.length < 2) {
      return left
    }

    const slugs = R.reduce(
      (acc, el) => {
        if (acc.includes(el.slug)) {
          return R.uniq(R.flatten(acc))
        } else return R.uniq(R.flatten([...acc, el.slug]))
      },
      [],
      deepObjectList,
    )

    const concatDeepObjectList = R.map(
      slug =>
        R.compose(
          R.reduce((acc, el) => R.mergeDeepWithKey(handleEqualKeys, acc, el), {}),
          R.map(entry => R.map(n => (n === null ? [] : n), entry)),
          R.filter(entry => entry.slug === slug), //takes entries whith same slug
        )(deepObjectList),
      slugs,
    )

    return concatDeepObjectList
  }
  const result = R.flatten([left, right])
  return result
}

const concatForecasts = R.reduce((acc, el) => R.mergeDeepWithKey(handleEqualKeys, acc, el), {})

export default concatForecasts
