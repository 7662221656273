// @ts-nocheck
import * as R from "ramda"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { RadioGroup, Classes } from "@blueprintjs/core"

import api from "../../../../../../../api"
import dsForecastsDuck from "../../../../../../../state/modules/dsForecasts"
import { getDSFLinks, getDsIdDsfId, getDsfFormula } from "./utils"
import { Button, InputGroup } from "../../../../../../components"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../../../../hooks"

const ConvertToForecast = ({ analyse, enhancedIndeps, intercept, setDialog }) => {
  const { profile } = useAuth()
  const [name, setName] = useState("")
  const [selectedDSFId, setSelectedDSFId] = useState(null)
  const [canSubmit, setCanSubmit] = useState(null)
  const [updatingDSFIdx, setUpdatingDSFIdx] = useState(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const dsForecasts = R.filter(
    R.propEq("aggregated", true),
    useSelector(R.pathOr([], ["dsForecasts", "dsForecasts", "results"])),
  )
  const owner = profile.username
  const isUpdating = R.pathOr(false, [updatingDSFIdx, "loadingEntries"], dsForecasts)

  const { dependent } = analyse
  const { dsId } = getDsIdDsfId(dependent)

  useEffect(() => {
    dispatch(dsForecastsDuck.actions.fetchForecasts({ dataset: dsId }))
  }, [dispatch, dsId])

  const handleName = ({ target: { value } }) => {
    setSelectedDSFId(null)
    if (R.isEmpty(value)) {
      setCanSubmit(null)
    } else {
      setCanSubmit("create")
    }
    return setName(value)
  }
  const handleSelect = ({ target: { value } }) => {
    setCanSubmit("update")
    setName("")
    return setSelectedDSFId(parseInt(value))
  }

  const handleSubmit = async () => {
    const dsflinks = getDSFLinks(enhancedIndeps)
    const formula = getDsfFormula(enhancedIndeps, intercept)
    if (canSubmit === "create") {
      const values = {
        dataset: dsId,
        owner,
        name,
        formula,
        dsflinks,
        process_agg_output: true,
        aggregated: true,
      }

      const response = await api.dsForecasts.list.create(values)
      dispatch(dsForecastsDuck.actions.createForecastOk(response.data))
      return navigate(`/datasets/${dsId}/forecasts`)
    }
    const index = R.findIndex(R.propEq("id", selectedDSFId), dsForecasts)
    setUpdatingDSFIdx(index)
    const values = { formula, dataset: dsId, dsflinks, process_agg_output: true }
    const { data } = await api.dsForecasts.detail.update({ id: selectedDSFId, values })
    dispatch(dsForecastsDuck.actions.updateForecastOk({ index, data }))
    return navigate(`/datasets/${dsId}/forecasts`)
  }

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <h4>Convert to existing</h4>
        <RadioGroup
          onChange={handleSelect}
          selectedValue={selectedDSFId}
          options={dsForecasts.map(f => ({ label: f.str_name, value: f.id }))}
        />
        <h4>Create new</h4>
        <InputGroup
          inputProps={{
            value: name,
            onChange: handleName,
            onEnter: handleSubmit,
            onEsc: () => setDialog(false),
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button disabled={!canSubmit} onClick={() => setDialog(false)} text="Close" />
          <Button
            intent={isUpdating ? "warning" : "primary"}
            disabled={!canSubmit}
            onClick={handleSubmit}
            text={isUpdating ? "Loading" : "OK"}
          />
        </div>
      </div>
    </>
  )
}
export default ConvertToForecast
