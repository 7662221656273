// @ts-nocheck
import * as R from "ramda"

import { Button, Colors, H5, H6, MenuItem } from "@blueprintjs/core"
import React, { useState, useEffect } from "react"
import { randomid, stockFields } from "../../utils"

import { Select } from "@blueprintjs/select"
import { useDispatch, useSelector } from "react-redux"
import { searchOmniByIds } from "../../state/modules/search"
import { fetchFieldList } from "../../state/modules/shared/"

const convertPath = (oldPath, fieldList) => {
  const { shared_fields, report_fields, valuation_fields } = fieldList
  const { sales_fields, orders_fields, profit_fields, backlog_fields } = shared_fields
  const { is_fields, bs_fields, cf_fields } = report_fields
  const concatedSharedFields = [
    ...sales_fields,
    ...orders_fields,
    ...profit_fields,
    ...backlog_fields,
    ...valuation_fields,
  ]

  const groupLevelFields = [...concatedSharedFields, ...is_fields, ...bs_fields, ...cf_fields].map(
    e => e.key,
  )
  const segLevelFields = concatedSharedFields.map(e => e.key)

  // transform string oldPath into an array
  const oldPathList = oldPath.replace("[", "").replace("]", "").replace(/'/g, "").split(",")

  let newPath = []
  let prevItem = null

  oldPathList.forEach((item, index) => {
    if (item.includes("VAL")) {
      newPath = R.append(item, newPath)
    }
    if (
      (index === 0 && ["splits", "fields", "geos", "currencies"].includes(item)) ||
      (index === 2 && "segs".includes(item))
    ) {
      prevItem = item
    }

    if (index === 0 && groupLevelFields.includes(item)) {
      newPath = R.append(item, newPath)
    }

    if ([1, 3].includes(index)) {
      let prefix = ""
      if (prevItem) {
        prevItem === "currencies" ? (prefix = "currency") : (prefix = prevItem.slice(0, -1))
        newPath = R.append(`${prefix}:${R.split(":", item)[1]}`, newPath)
        if (prevItem === "currencies") {
          newPath = R.append(oldPathList[2], newPath)
        }
      }
    }

    if (index === 4 && segLevelFields.includes(item)) {
      newPath = R.append(item, newPath)
    }
  })
  newPath = R.map(e => `'${e}'`, newPath) // put simple quote around each item
  newPath = `[${R.join(", ", newPath)}]` // transform array into string

  return newPath
}

export const OmniPathSelect = ({ source = null, value, handleSelect, maskVal, sourceOrigin }) => {
  const dispatch = useDispatch()

  const [query, setQuery] = useState("")
  const [fetching, setFetching] = useState(true)

  const fieldList = useSelector(R.pathOr(null, ["shared", "fieldList"]))

  useEffect(() => {
    if (!stockFields) {
      setFetching(true)
      dispatch(searchOmniByIds({ ids: [source.kind_id] }))
    }
  }, [dispatch, source])

  useEffect(() => {
    if (!fieldList) {
      setFetching(true)
      dispatch(fetchFieldList())
    } else {
      setFetching(false)
    }
  }, [dispatch, fieldList])

  const allFields = stockFields(source, fieldList, sourceOrigin)
  const convertedAllFields = allFields
    ? R.map(
        field => (field.path ? { ...field, path: convertPath(field.path, fieldList) } : field),
        allFields,
      )
    : []

  const fields = fetching
    ? []
    : maskVal
    ? convertedAllFields.filter(
        choice => !choice.text.includes("[VAL]") && choice.text !== "Valuation",
      )
    : convertedAllFields

  const filteredResults = fields.filter(field => {
    if (["header", "subHeader"].includes(field.type)) return true
    const lowerQ = R.toLower(query)
    const titleIncludesQ = R.toLower(field.text).includes(lowerQ)
    if (field.path) {
      const cleanedPath = field.path.replace("'value'", "")
      return cleanedPath.includes(lowerQ) || titleIncludesQ
    }
    return titleIncludesQ
  })

  const itemRenderer = (field, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    if (["title1", "title2"].includes(field.type))
      return (
        <li key={randomid()} className="bp5-menu-header">
          {field.type === "title1" ? (
            <H5 style={{ color: Colors.GRAY4 }}>{field.text}</H5>
          ) : (
            <H6 style={{ color: Colors.GRAY5 }}>{field.text}</H6>
          )}
        </li>
      )
    return (
      <MenuItem
        active={modifiers.active}
        key={field.path}
        onClick={handleClick}
        text={field.text}
      />
    )
  }

  const handleQueryChange = query => setQuery(query)

  const buttonText = value
    ? R.prop("text", R.find(R.propEq("path", value))(convertedAllFields))
    : "Add Field"

  return (
    <Select
      className="sct-fullwidth"
      items={filteredResults}
      itemRenderer={itemRenderer}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={handleSelect}
      onQueryChange={handleQueryChange}
    >
      <Button
        disabled={fetching}
        fill
        icon="form"
        rightIcon="caret-down"
        text={fetching ? "Please wait" : buttonText}
      />
    </Select>
  )
}
