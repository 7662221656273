// @ts-nocheck
import React from "react"
import { FormGroup, HTMLSelect as BP5HTMLSelect } from "@blueprintjs/core"

export const HTMLSelect = ({
  field, // { name, value, onChange, onBlur }
  //form: { touched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  labelProps = null,
  ...props
}) => (
  <>
    {labelProps ? (
      <FormGroup {...labelProps}>
        <BP5HTMLSelect fill {...field} {...props} />
      </FormGroup>
    ) : (
      <BP5HTMLSelect fill {...field} {...props} />
    )}
  </>
)
