// @ts-nocheck
import { map } from "ramda"

export const recurse = (obj, func) =>
  typeof obj === "string"
    ? func(obj)
    : map(val => {
        if (Array.isArray(val)) {
          return val.map(el => {
            return recurse(el, func)
          })
        }
        if (typeof val === "object" && val !== null) {
          return recurse(val, func)
        }
        return func(val)
      }, obj)

export const recursiveNullToEmptyStr = obj => recurse(obj, el => (el === null ? "" : el))
export const recursiveEmptyStrToNull = obj => recurse(obj, el => (el === "" ? null : el))
