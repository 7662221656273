// @ts-nocheck
import autodux from "autodux"
import { isEmpty } from "ramda"

export const initialFilters = {
  search: "",
  completed: "all",
  begin: null,
  stop: null,
  from: "today",
  dsCategory: null,
  hide_bbg: false,
  hide_agg: true,
  searching: false,
}

const eventSearch = JSON.parse(localStorage.getItem("eventSearch")) || initialFilters

const dataSetEventsDuck = autodux({
  slice: "dataSetEvents",
  initial: {
    ...eventSearch,
    searching: false,
    response: {},
  },
  actions: {
    searchDSEvents: (state, params) => {
      if (isEmpty(params)) {
        return { ...dataSetEventsDuck.initial, searching: true }
      }
      return {
        ...state,
        searching: true,
        ...params,
      }
    },
    searchDSEventsOk: (state, response) => ({
      ...state,
      searching: false,
      response,
    }),
    searchDSEventsError: (state, error) => ({
      ...state,
      searching: false,
      error,
    }),
    clearDSEventsEvents: () => ({ ...dataSetEventsDuck.initial }),
  },
})

export default dataSetEventsDuck
