// @ts-nocheck
import { map } from "ramda"

// This function is to format the fieldFields (= customFields) for a given stock or rf
// Need to extract slug from key of fieldFields
// Key is formated as  :
//           { key: "['fields','slug:aftermarket','value']" }
// The final goal is to have slug as key value and to have label as text value.

export const formatFieldFieldsList = fieldFields => {
  const regex = /(slug:)(.+)(','value'])/

  const formatedList = map(e => {
    const slug = regex.exec(e.key)
    return { text: e.label, key: slug[2] }
  }, fieldFields)

  return formatedList
}
