// @ts-nocheck
import * as R from "ramda"

const searchStocksReducers = {
  searchStocks: R.assocPath(["stocks", "searching"], true),
  searchStocksOk: (state, { payload: { results } }) =>
    R.over(
      R.lensProp("stocks"),
      R.compose(R.assoc("searching", false), R.assoc("results", results)),
      state,
    ),

  searchStocksError: (state, { payload: { error } }) =>
    R.set(R.lensProp("stocks"), { searching: false, error: error, results: [] }, state),
  clearStocks: state => R.set(R.lensProp("stocks"), { searching: false, results: [] }, state),
}

export default searchStocksReducers
