// @ts-nocheck
import * as R from "ramda"

const analyseLens = index => R.lensPath(["analyses", "list", index])
const analysesLens = R.lensPath(["analyses", "list"])

const findAnalyseIndex = (id, list) => R.findIndex(R.propEq("id", id))(list)

export const deleteAnalyse = (state, { payload: { id } }) => {
  const index = findAnalyseIndex(id, state.analyses.list)
  return R.over(analyseLens(index), R.assoc("fetching", true), state)
}

export const deleteAnalyseOk = (state, { payload: { id } }) => {
  const index = findAnalyseIndex(id, state.analyses.list)
  return R.over(analysesLens, R.remove(index, 1), state)
}

export const deleteAnalyseError = (state, { payload: { id, error } }) => {
  const index = findAnalyseIndex(id, state.analyses.list)
  return R.over(analyseLens(index), R.compose(R.assoc("error", error), R.dissoc("fetching")), state)
}
