/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { UseControllerProps, useController } from "react-hook-form"

import {
  appendSuffixToStringNumber,
  transformNumberToStringWithSuffix,
  transformStringWithSuffixToNumber,
} from "../../utils/transformNumber"
import { inputCss } from "../OrdersForm.styled"
import { FlexContainerFullHeight } from "./Components.styled"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"
import { WaveInputs } from "../types/form"

/**
 * Specific NumericInput with suffix which is able to:
 *      _ As a user I want to see 10K for 10 000, 12M for 12 000 000.
 *      _ As a user if I write 10 then automatically I want see 10K after leaving cell.
 *      _ As a user if I want million, I have to add M after number. So if I want 12 000 000, I can write 12M
 *      _ As a dev, I want to register in the payload the extended number (10 000 for 10K)
 *      _ As a dev, the system must be able from the payload to display compact number.
 *
 * It takes numeric value, transform it into string with suffix K or M and display it.
 * When user leave the cell, the string is transform into a numeric value.
 */

interface INumericInputWithSuffixProps extends UseControllerProps<WaveInputs> {
  index: number
  onChange?: () => void
}

export const NumericInputWithSuffix = ({
  index,
  control,
  name,
  rules,
  onChange,
}: INumericInputWithSuffixProps) => {
  const { field, fieldState } = useController({ control, name, rules })
  const [displayValue, setDisplayValue] = useState<string>(
    field.value ? transformNumberToStringWithSuffix(field.value) : "",
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim()
    /**
     * Allow empty string to be able to delete value entered
     * or string which test regex to allow only number followed by 'K' or 'M'
     */
    if (inputValue === "" || /^\d+\.?(\d+)?[mMkK]?$/.test(inputValue)) {
      setDisplayValue(inputValue.toUpperCase()) // to have always 'K' or 'M' in uppercase
      field.onChange(transformStringWithSuffixToNumber(inputValue))
      onChange && onChange() // Call the onChange function if it exists
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setDisplayValue(appendSuffixToStringNumber(displayValue, "K"))
    }
  }

  const handleBlur = () => {
    setDisplayValue(appendSuffixToStringNumber(displayValue, "K"))
  }

  return (
    <FlexContainerFullHeight alignItems="center">
      <input
        type="text"
        value={field.value ? displayValue : ""}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        css={inputCss(index)}
      />
      {fieldState.error && <ErrorIconWithTooltip errorMessage={fieldState.error.message} />}
    </FlexContainerFullHeight>
  )
}
