// @ts-nocheck
export const getEnv = () => {
  switch (window.location.host) {
    case "staging.scient.io":
      return "staging"
    case "scient.io":
      return "production"
    default:
      return "dev"
  }
}
