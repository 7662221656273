// src/hoc/WithLoading.tsx

import React, { ComponentType } from "react"
import { SpinnerContainer } from "./WithLoading.styled"
import { Intent, Spinner } from "@blueprintjs/core"

interface WithLoadingProps {
  isLoading: boolean
  loaderSize?: number
}

// High Order Component to show a spinner while the component is loading
const WithLoading = <P extends object>(Component: ComponentType<P>) => {
  const WithLoadingComponent: React.FC<P & WithLoadingProps> = ({
    isLoading,
    loaderSize,
    ...props
  }) => {
    if (!isLoading) {
      return <Component {...(props as P)} />
    }
    return (
      <SpinnerContainer justifyContent="center" flexGrow={1}>
        <Spinner intent={Intent.SUCCESS} size={loaderSize} />
      </SpinnerContainer>
    )
  }

  return WithLoadingComponent
}

export default WithLoading
