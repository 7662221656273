// @ts-nocheck
import React from "react"
import { isEmpty } from "ramda"
import { Menu, MenuItem, MenuDivider, Classes } from "@blueprintjs/core"
import { useApi } from "../../../../hooks/useApi"

const ContextMenu = ({ index, ds, createChangeDSOk, createMavDSOk, navigate }) => {
  const { scientApi } = useApi()

  const handleCreateChangeDS = async ({ calendarize = false }) => {
    const values = { source: ds.id, change: "12m", mode: "pct", calendarize }
    const response = await scientApi.aggregators.changeDs.list.create({ values })
    const chg_output = response.data.output
    createChangeDSOk({
      sourceIndex: index,
      chg_output,
    })
    // todo fetchDataSetSearch
  }
  const handleCreateMavDS = async window => {
    const values = { source: ds.id, window }
    const response = await scientApi.aggregators.mavDs.list.create({ values })
    const mav_output = response.data.output
    createMavDSOk({
      sourceIndex: index,
      mav_output,
    })
    // todo fetchDataSetSearch
  }
  const windows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  return (
    <Menu className={Classes.ELEVATION_1}>
      {!ds.aggregated && (
        <MenuItem
          data-cy="EditDataSet"
          icon="cog"
          onClick={() => navigate(`/datasets/${ds.id}`)}
          text="Edit"
        />
      )}
      <MenuItem
        data-cy="ContextToChart"
        icon="timeline-line-chart"
        onClick={() => navigate(`/datasets/${ds.id}/entries`)}
        text="Chart"
      />
      <MenuItem
        data-cy="ContextToEntries"
        icon="th"
        onClick={() => navigate(`/datasets/${ds.id}/entries/table`)}
        text="Table"
      />
      <MenuItem
        data-cy="ContextToForecasts"
        icon="series-derived"
        onClick={() => navigate(`/datasets/${ds.id}/forecasts`)}
        text="Forecasts"
      />
      {ds.chg_outputs.filter(chgDS => chgDS.name.includes("pct, 12m")).length !== 1 && (
        <>
          <MenuDivider />
          <MenuItem text="Create YoY %">
            <MenuItem onClick={handleCreateChangeDS} text="Normal" />
            <MenuItem
              onClick={() => handleCreateChangeDS({ calendarize: true })}
              text="Create cal"
            />
          </MenuItem>
        </>
      )}
      <MenuItem text="MAV periods">
        {windows.map(window => {
          const relatedDS = ds.mav_outputs.filter(mavDS => mavDS.name.includes(`MA#${window})`))
          if (!isEmpty(relatedDS)) {
            return (
              <MenuItem
                key={relatedDS[0].id}
                onClick={() => navigate(`/datasets/${relatedDS[0].id}/entries`)}
                text={`Go to MAV#${window}`}
                intent="primary"
              />
            )
          }
          return (
            <MenuItem
              key="createMav"
              onClick={() => handleCreateMavDS(window)}
              text={`Create MAV#${window}`}
            />
          )
        })}
      </MenuItem>
      {!isEmpty(ds.chg_outputs) && (
        <>
          <MenuDivider title="Related DataSets" />
          {ds.chg_outputs.map(chgDS => (
            <MenuItem
              key={chgDS.id}
              onClick={() => navigate(`/datasets/${chgDS.id}/entries`)}
              text={chgDS.name}
            />
          ))}
        </>
      )}
    </Menu>
  )
}

export default ContextMenu
