// @ts-nocheck
import { Button, Collapse, ControlGroup, H6, Icon, Tooltip } from "@blueprintjs/core"
import { Field } from "formik"

import { SCIENT_COLORS } from "../../../../../../../../../styled/scientColors"
import { MediumText } from "../../../../../../../../../styled/text.styled"
import { TextInput } from "../../../../../../../../components/formik"

export const GroupName = ({ loopingFeatures, noLoopingGroup, handleSubmit, values }) => {
  return (
    <>
      <ControlGroup>
        <p>Group name</p>
        <Tooltip
          content={
            <div>
              <H6 style={{ marginBottom: "5px" }}>
                Group name is just for organizational purpose and sharing.
              </H6>
              <MediumText color={SCIENT_COLORS.darkGray5}>
                As its group node, it will not be displayed on the table. <br /> If you want to give
                your group of rows a header, click at HEADER on the left menu.
              </MediumText>
            </div>
          }
          placement="right"
          openOnTargetFocus={false}
          compact
        >
          <Icon icon="help" size={20} style={{ marginLeft: "8px" }} />
        </Tooltip>
      </ControlGroup>

      <Field placeholder="Enter a group name" name={"name"} component={TextInput} />

      <Collapse isOpen={loopingFeatures}>
        <p style={{ marginTop: "8px" }}>
          Leave blank and the app will create a name for you based on what you add inside the
          looping group
        </p>
      </Collapse>

      {noLoopingGroup && (
        <>
          <Button
            intent="primary"
            large
            onClick={handleSubmit}
            disabled={!values.name}
            text="Add group name"
          />
          <p>If Group has no child, a child Data will be add to it</p>
        </>
      )}
    </>
  )
}
