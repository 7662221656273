// @ts-nocheck
import { Button, Card, HTMLSelect, Label } from "@blueprintjs/core"
import React, { useState } from "react"
import { InputGroup, OmniPathSelect, OmniSuggest } from "../../../components"

const freqOpts = [
  { label: "Choose freq", value: "" },
  { label: "Yearly", value: "Y" },
  { label: "Quarterly", value: "Q" },
  { label: "Monthly", value: "M" },
  { label: "Weekly", value: "W" },
  { label: "Daily", value: "D" },
]

const ChooseVariable = ({ variable, submit, freqRequired = true, indep = false }) => {
  const [_variable, _setVariable] = useState(variable)

  const handleSourceChoice = source => {
    const freq =
      source.kind === "ds"
        ? source.periodicity
        : source.kind === "dsf"
        ? source.datasetParams.periodicity
        : "Q"
    _setVariable(variable => ({ ...variable, path: "", name: source.verbose_name, freq, source }))
  }

  const handleFieldChoice = ({ text, path }) => {
    _setVariable(variable => ({
      ...variable,
      path,
      name: `${variable.source.verbose_name} ${text.replace(/[[\]]/g, "")}`,
    }))
  }

  const handleSetName = ({ target: { value } }) =>
    _setVariable(variable => ({ ...variable, name: value }))

  const handleSetFreq = ({ target: { value } }) => {
    _setVariable(variable => ({ ...variable, freq: value }))
  }

  const kindIsStRf = ["st", "rf"].includes(_variable?.source?.kind)

  const handleSubmit = () => {
    const realPath = kindIsStRf
      ? `${_variable.path.slice(0, 2)}${_variable.source.kind_id}', '${_variable.path.slice(2)}`
      : `['${_variable.source.kind_id}']`

    submit({ ..._variable, path: realPath })
  }

  const isValid = () => {
    const mainCheck = _variable.source && _variable.name && (_variable.freq || !freqRequired)
    return kindIsStRf ? mainCheck && _variable.path : mainCheck
  }

  return (
    <>
      <Card>
        <Label>Source</Label>
        <OmniSuggest
          value={_variable.source}
          onSubmit={handleSourceChoice}
          placeholder={
            indep ? "Choose the explaining variable (X)" : "Choose the explained variable (Y)"
          }
        />
      </Card>
      {["st", "rf"].includes(_variable?.source?.kind) && (
        <Card>
          <Label>Field</Label>
          <OmniPathSelect
            source={_variable.source}
            value={_variable.path}
            handleSelect={handleFieldChoice}
            maskVal
          />
        </Card>
      )}
      <Card>
        <InputGroup
          labelProps={{ label: "Name" }}
          inputProps={{ value: _variable.name, onChange: handleSetName }}
        />
      </Card>
      {freqRequired && (
        <Card>
          <Label>Frequency</Label>
          <HTMLSelect
            name="freq"
            fill
            value={_variable.freq}
            options={freqOpts}
            onChange={handleSetFreq}
            disabled
          />
        </Card>
      )}
      <Button
        large
        fill
        onClick={handleSubmit}
        intent={"primary"}
        disabled={!isValid()}
        text={isValid() ? "Continue" : "Fill all fields to continue"}
      />
    </>
  )
}

export default ChooseVariable
