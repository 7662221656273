// @ts-nocheck
import React from "react"
import { Label } from "@blueprintjs/core"
import { Field } from "formik"

import { Select } from "../../../../../../../components"

const heatmapOpts = [
  { label: "None", value: "" },
  { label: "Vs. Average (red-green)", value: "avgGreen" },
  { label: "Vs. Average (green-red)", value: "avgRed" },
  { label: "Vs. Average (blue-red)", value: "avgBlue" },
  { label: "Vs. Zero (red-green)", value: "zeroGreen" },
  { label: "Vs. Zero (green-red)", value: "zeroRed" },
  { label: "Vs. Zero (blue-red)", value: "zeroBlue" },
]

const HeatmapSelect = ({ field }) => {
  return (
    <Select
      selectProps={{
        options: heatmapOpts,
        ...field,
      }}
    />
  )
}

const FormikHeatmap = ({ index }) => (
  <Label>
    Heatmap
    <Field key={index} name={`rowDefs[${index}].frontOpts.heatmap`} component={HeatmapSelect} />
  </Label>
)

export default FormikHeatmap
