// @ts-nocheck
import { applyMiddleware, createStore } from "redux"

import { ajax as rxajax } from "rxjs/observable/dom/ajax"
import auth from "../config/auth"
import { composeWithDevTools } from "@redux-devtools/extension"
import { createEpicMiddleware } from "redux-observable"
import { loadState } from "./localStorage"
import { randomid } from "../utils"
import reducers from "."
import rootEpic from "./rootEpic"
import { async as scheduler } from "rxjs/scheduler/async"
import thunk from "redux-thunk"

const ajax = params =>
  rxajax({
    ...params,
    crossDomain: true,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${auth.getToken()}`,
    },
  })

export const epicMiddleware = createEpicMiddleware({
  dependencies: {
    ajax,
    auth,
    scheduler,
    randomid,
  },
})

const configureStore = () => {
  const middlewares = [epicMiddleware, thunk]

  const persistedState = loadState()

  const store = createStore(
    reducers,
    persistedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )

  epicMiddleware.run(rootEpic)

  return store
}

export default configureStore
