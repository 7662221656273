// @ts-nocheck
import { Alignment, Button, ButtonGroup, FormGroup, H5, Switch } from "@blueprintjs/core"
import * as R from "ramda"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../state/modules/sheets"
import { ForecastSuggest, HeaderHeight, SharedHeader } from "../components"
import { LaunchProcessing } from "../components/CloneSheet/LaunchProcessing"
import { EditColumn } from "./EditColumn"
import { EditRow } from "./EditRow"
import MscGrid from "./MscGrid"
import { TimeGranularity } from "./TimeGranularity"
import { companyList } from "./utils"

const Msc = ({ desk, monitor, sheet, output }) => {
  const processingOutput = sheet.processing_output
  const pauseProcessing = sheet.pause_processing

  const [editRowItems, setEditRowItems] = useState({ show: false })
  const [editColItems, setEditColItems] = useState({ show: false })

  const rowDefs = sheet.rowDefs

  //variables for ForecastSuggest
  const forecastCompany = R.pathOr("Choose forecast", ["rowDefs", "forecast", "company"], sheet)

  const dispatch = useDispatch()
  const reloadSheet = () => dispatch(sheetsDuck.actions.fetchSheetDetail({ id: sheet.id }))

  const handleSubmitForecast = value => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        rowDefs: {
          ...sheet.rowDefs,
          forecast: { company: value.key, owner: "default", label: "base" },
        },
      }),
    )
  }
  //end variables for ForecastSuggest

  const onChangeCalendarization = event => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        calendarization: event.currentTarget.checked,
      }),
    )
  }

  return (
    <>
      <div className="sct-sheet-header-container">
        <SharedHeader monitor={monitor} sheet={sheet} />

        <ButtonGroup style={{ marginLeft: "20px" }}>
          <HeaderHeight sheet={sheet} rowDefs={rowDefs} />
        </ButtonGroup>
        {pauseProcessing === false && (
          <>
            <FormGroup
              label="Forecast"
              inline
              disabled
              style={{ margin: "0 0 0 50px", fontSize: "16px" }}
            >
              <ForecastSuggest
                list={companyList}
                company={forecastCompany.toUpperCase()}
                submit={handleSubmitForecast}
              />
            </FormGroup>
            <FormGroup
              label="Time granularity"
              inline
              disabled
              style={{ margin: "6px 0 0 50px", fontSize: "16px" }}
            >
              <TimeGranularity dispatch={dispatch} sheet={sheet} />
            </FormGroup>
            <FormGroup
              label="Calendarized"
              inline
              disabled
              style={{ margin: "0 0 0 50px", fontSize: "16px" }}
            >
              <Switch
                alignIndicator={Alignment.RIGHT}
                onChange={onChangeCalendarization}
                checked={sheet?.calendarization !== undefined ? sheet.calendarization : true}
              />
            </FormGroup>
          </>
        )}
      </div>
      <div
        className="ag-theme-balham-dark"
        style={{
          margin: "0 auto",
          height: "100%",
          width: "100%",
        }}
      >
        {pauseProcessing === true ? (
          <LaunchProcessing sheet={sheet} /> // Use when cloning a sheet
        ) : processingOutput === true ? (
          <div
            style={{
              margin: "40px auto",
              height: "100%",
              width: "50%",
              textAlign: "center",
            }}
          >
            <H5>This large sheet in being processed and will be available soon</H5>
            <H5>Please reload to see the result.</H5>
            <Button text="Reload" icon="refresh" onClick={reloadSheet} large></Button>
          </div>
        ) : (
          <MscGrid
            desk={desk}
            sheet={sheet}
            output={output}
            setEditRowItems={setEditRowItems}
            setEditColItems={setEditColItems}
          />
        )}
        <EditRow editRowItems={editRowItems} setEditRowItems={setEditRowItems} sheet={sheet} />
        <EditColumn editColItems={editColItems} setEditColItems={setEditColItems} sheet={sheet} />
      </div>
    </>
  )
}

export default Msc
