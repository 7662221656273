// @ts-nocheck
import { compose, pathOr, propEq } from "ramda"

const stylePath = ["data", "extra", "frontOpts", "styles"]

export const rowClassRules = {
  "sct-focus1": compose(propEq("focus", "1"), pathOr({}, stylePath)),
  "sct-focus2": compose(propEq("focus", "2"), pathOr({}, stylePath)),
  "sct-focus3": compose(propEq("focus", "3"), pathOr({}, stylePath)),
  "sct-focus4": compose(propEq("focus", "4"), pathOr({}, stylePath)),
  "sct-italic": compose(propEq("italic", true), pathOr({}, stylePath)),
  "sct-bold": compose(propEq("bold", true), pathOr({}, stylePath)),
  "sct-underline": compose(propEq("underline", true), pathOr({}, stylePath)),
  "sct-empty-row": compose(propEq("emptyRow", true), pathOr({}, stylePath)),

  "sct-front-blue": compose(propEq("frontColor", "blue"), pathOr({}, stylePath)),
  "sct-front-green": compose(propEq("frontColor", "green"), pathOr({}, stylePath)),
  "sct-front-orange": compose(propEq("frontColor", "orange"), pathOr({}, stylePath)),
  "sct-front-red": compose(propEq("frontColor", "red"), pathOr({}, stylePath)),
  "sct-front-indigo": compose(propEq("frontColor", "indigo"), pathOr({}, stylePath)),
  "sct-front-turquoise": compose(propEq("frontColor", "turquoise"), pathOr({}, stylePath)),
  "sct-front-sepia": compose(propEq("frontColor", "sepia"), pathOr({}, stylePath)),
}
