// @ts-nocheck
import React, { useState } from "react"
import { MenuItem } from "@blueprintjs/core"

import { Suggest } from "../../../../components"

export const ForecastSuggest = ({ list, company, submit }) => {
  const [results, setResults] = useState(list)

  const forecastItemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.key}
        text={item.text}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }

  const updateSearch = query => {
    if (query === "") {
      setResults(list)
    }
    const selection = list.filter(l => l.key.includes(query.toLowerCase()) || l.key.includes(query))
    setResults(selection)
  }
  return (
    <Suggest
      placeholder={company}
      resetOnClose={true}
      resetOnSelect={true}
      items={results}
      onItemSelect={submit}
      onQueryChange={updateSearch}
      itemRenderer={forecastItemRenderer}
      large={false}
    />
  )
}
