import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../hooks/useApi"

const useSectors = () => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(["sectors"], scientApi.world.getSectors, {
    retry: 1,
    staleTime: Infinity, // Static list, no need to refresh.
  })

  return {
    sectors: data,
    error,
    isLoading,
  }
}

export default useSectors
