// @ts-nocheck
import { addMonths, format, subYears } from "date-fns"
import { ofType } from "redux-observable"
import { of } from "rxjs"
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators"

import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import entriesDuck from "../index"

const begin = format(subYears(new Date(), 100), "yyyy-MM-dd")
const stop = format(addMonths(new Date(), 2), "yyyy-MM-dd")

const {
  actions: { fetchEntries, fetchEntriesOk, fetchEntriesError },
} = entriesDuck

const fetchEntriesEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchEntries.type),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const {
        dataSets: {
          dataSet: { id },
        },
      } = state
      const params = `?datasets=${id}&begin=${begin}&stop=${stop}&page_size=20000`
      const url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/entries/${params}`
      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchEntriesOk(response)),
        catchError(error => of(fetchEntriesError(error.xhr.response))),
      )
    }),
  )

export default fetchEntriesEpic
