// @ts-nocheck
import autodux from "autodux"

import * as actions from "./actions"

const sheetsDuck = autodux({
  slice: "sheets",
  initial: {},
  actions,
})

export default sheetsDuck
