// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { Button, ControlGroup, Icon, InputGroup, Tooltip } from "@blueprintjs/core"
import { includes, isEmpty } from "ramda"
import { useState } from "react"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { MediumText } from "../../../../../../../../styled/text.styled"
import { randomid } from "../../../../../../../../utils"
import { useEnhancedPath } from "../../../utils"
import { controlGroupCss, customTextInputCss } from "./HeaderBuilder.styled"

export const HeaderBuilderButtons = ({
  activeItem,
  st_sources,
  rf_sources,
  values,
  setValues,
  headerItems,
}) => {
  const [input, setInput] = useState("")

  const enhancedPath = useEnhancedPath(activeItem.path, st_sources, rf_sources)

  const hasFieldAncestor = enhancedPath.fields ? true : false
  const hasSplitdAncestor = enhancedPath.splits ? true : false
  const hasSegAncestor = enhancedPath.segs ? true : false

  const hasAncestorsLoopingGroup =
    enhancedPath.splits || enhancedPath.segs || enhancedPath.fields ? true : false

  const onChange = e => {
    const { value } = e.target
    setInput(value)
  }

  const addItem = value => {
    const type = includes(value, [
      "<sourceName>",
      "<ticker>",
      "<splitName>",
      "<segName>",
      "<fieldName>",
    ])
      ? "dynamic"
      : "static"

    const text = value => {
      switch (value) {
        case "<sourceName>":
          return "Primary name"
        case "<ticker>":
          return "Primary ticker"
        case "<splitName>":
          return "Split name"
        case "<segName>":
          return "Segment name"
        case "<fieldName>":
          return "Field name"
        default:
          return ""
      }
    }

    setValues({
      ...values,
      headerItems: [...headerItems, { item: value, text: text(value), type, itemId: randomid() }],
    })
  }

  return (
    <ControlGroup css={controlGroupCss} fill>
      {((enhancedPath.sourceId &&
        enhancedPath.primarySrc &&
        st_sources.length > 0 &&
        activeItem.kind === "single") ||
        (st_sources.length > 0 && !enhancedPath.sourceId)) && (
        <>
          <Button
            onClick={() => addItem("<sourceName>")}
            text={"Primary name"}
            disabled={headerItems.some(e => e.item.includes("<sourceName>"))}
          >
            <Tooltip
              content={
                <MediumText color={SCIENT_COLORS.darkGray5}>
                  Click to add the <strong>primary sourceName</strong> (i.e. Legrand…) to row
                  header.
                </MediumText>
              }
              placement="bottom"
              openOnTargetFocus={false}
              compact
            >
              <Icon className="sct-button-in-button" icon="info-sign" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => addItem("<ticker>")}
            text={"Primary ticker"}
            disabled={headerItems.some(e => e.item.includes("<ticker>"))}
          >
            <Tooltip
              content={
                <MediumText color={SCIENT_COLORS.darkGray5}>
                  Click to add the <strong>short primary source name</strong> (i.e. LR FP…) to row
                  header.
                </MediumText>
              }
              placement="bottom"
              openOnTargetFocus={false}
              compact
            >
              <Icon className="sct-button-in-button" icon="info-sign" />
            </Tooltip>
          </Button>
        </>
      )}

      {hasAncestorsLoopingGroup && (
        <>
          {hasSplitdAncestor && (
            <Button
              onClick={() => addItem("<splitName>")}
              text={"Split name"}
              disabled={headerItems.some(e => e.item.includes("<splitName>"))}
            >
              <Tooltip
                content={
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    Click to add <strong>split name</strong> (i.e. main, secondary) to row header.
                  </MediumText>
                }
                placement="bottom"
                openOnTargetFocus={false}
                compact
              >
                <Icon className="sct-button-in-button" icon="info-sign" />
              </Tooltip>
            </Button>
          )}

          {hasSegAncestor && (
            <Button
              onClick={() => addItem("<segName>")}
              text={"Segment name"}
              disabled={headerItems.some(e => e.item.includes("<segName>"))}
            >
              <Tooltip
                content={
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    Click to add <strong>segment name</strong> (i.e. electrification products,
                    motion…) <br />
                    for each seg row header.
                  </MediumText>
                }
                placement="bottom"
                openOnTargetFocus={false}
                compact
              >
                <Icon className="sct-button-in-button" icon="info-sign" />
              </Tooltip>
            </Button>
          )}

          {hasFieldAncestor && (
            <Button
              onClick={() => addItem("<fieldName>")}
              text={"Field name"}
              disabled={headerItems.some(e => e.item.includes("<fieldName>"))}
            >
              <Tooltip
                content={
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    Click to add <strong>field name</strong> (i.e. bridge mix, base orders organic…)
                    <br />
                    to each row header in grid for each rows generates by looping.
                  </MediumText>
                }
                placement="bottom"
                openOnTargetFocus={false}
                compact
              >
                <Icon className="sct-button-in-button" icon="info-sign" />
              </Tooltip>
            </Button>
          )}
        </>
      )}

      <InputGroup
        css={customTextInputCss}
        type="text"
        value={input}
        placeholder="Add custom text"
        onChange={onChange}
        rightElement={
          <Button
            onClick={() => {
              addItem(input)
              setInput("")
            }}
            text={"Add to header"}
            intent={isEmpty(input) ? "none" : "primary"}
            disabled={isEmpty(input)}
            large
          />
        }
      />
    </ControlGroup>
  )
}
