// @ts-nocheck
import * as R from "ramda"

// Function to know/test if only one of the required param in the one_in_list is filled.
// paramLinkOneInList must exist and must not be empty
export const isFilledOnlyOneInList = ({ paramValues, paramLinkOneInList }) => {
  // Take the names of required params and put them in a list
  const requiredParamNameList = R.map(e => e.name, paramLinkOneInList?.required_params)

  // Filter in paramValues, only the required param keys
  const keysToTest = Object.keys(paramValues)
    .filter(key => requiredParamNameList.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: paramValues[key] })
    }, {})

  // Put in list value of filled param. If list is empty, so one param must be filled.
  // If there's more than one value in list, so we know that too many param are filled (we want only one_In_list)
  const requiredParamsFilled = Object.values(keysToTest).filter(e => {
    return !R.isEmpty(e)
  })
  if (requiredParamsFilled.length === 1) {
    return true
  }

  return false
}
