// @ts-nocheck
import React, { useState } from "react"
import { assoc } from "ramda"
import { Button, HTMLSelect, NumericInput } from "@blueprintjs/core"

const lagTypeOpts = [
  { label: "Choose lag type", value: "" },
  { label: "Days", value: "d" },
  { label: "Weeks", value: "w" },
  { label: "Months", value: "m" },
  { label: "Quarters", value: "q" },
  { label: "Years", value: "y" },
]

export const InsertLag = ({ panelsState, setPanelsState }) => {
  const [lagType, setLagType] = useState("")
  const [lagAmount, setLagAmount] = useState()

  const appendLag = () => setPanelsState(assoc("lag", `{${lagType},${lagAmount}}`, panelsState))
  const removeLag = () => setPanelsState(assoc("lag", "", panelsState))

  return (
    <>
      <HTMLSelect
        options={lagTypeOpts}
        value={lagType}
        fill
        onChange={({ target: { value } }) => setLagType(value)}
      />
      <NumericInput
        min={-365}
        max={365}
        value={lagAmount}
        onValueChange={setLagAmount}
        placeholder="Enter lag amount"
        fill
      />
      <Button
        intent="primary"
        text={panelsState.lag ? "Change lag" : "Insert lag"}
        fill
        onClick={appendLag}
        disabled={!lagAmount || !lagType}
      />
      <Button text="Remove lag" fill onClick={removeLag} disabled={panelsState.lag === ""} />
    </>
  )
}
