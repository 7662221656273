import { MultiSelect } from "@blueprintjs/select"
import { IdeaCreateLabel, IdeaSettingsInnerContainer } from "./IdeaCreate.styled"
import { IdeaCreateParticipantsContainer } from "./IdeaCreateParticipants.styled"
import { useGlobalState } from "../../../hooks/useGlobalState"
import { useCallback, useEffect, useState } from "react"
import { IUser } from "../../../../api"
import { MenuItem } from "@blueprintjs/core"
import { useField, useFormikContext } from "formik"
import { IIdeaCreateForm } from "./IdeaCreate"

const IdeaCreateParticipants = () => {
  const { users } = useGlobalState()
  const [participantsField] = useField("participants")
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>(
    participantsField.value?.map((id: number) => users.find(user => user.id === id)) || [],
  )
  const { setFieldValue } = useFormikContext<IIdeaCreateForm>()

  const onUserSelect = useCallback((user: IUser) => {
    setSelectedUsers(selectedUsers => {
      if (selectedUsers.includes(user)) {
        return selectedUsers.filter(selectedUser => selectedUser.id !== user.id)
      }
      return [...selectedUsers, user]
    })
  }, [])

  useEffect(() => {
    setFieldValue(
      participantsField.name,
      selectedUsers.map(user => user.id),
    )
  }, [participantsField.name, selectedUsers, setFieldValue])

  return (
    <IdeaCreateParticipantsContainer flexDirection="column">
      <IdeaCreateLabel>Participants</IdeaCreateLabel>
      <IdeaSettingsInnerContainer>
        <MultiSelect
          fill
          items={users}
          selectedItems={selectedUsers}
          onItemSelect={item => onUserSelect(item)}
          tagInputProps={{
            onRemove: (value, index) =>
              setSelectedUsers(selectedUsers.filter((_, i) => i !== index)),
          }}
          itemRenderer={(item, { handleClick, modifiers }) => (
            <MenuItem
              text={item.username}
              roleStructure="listoption"
              selected={selectedUsers.includes(item)}
              shouldDismissPopover={false}
              onClick={handleClick}
              key={`participant-${item.id}`}
            />
          )}
          tagRenderer={item => item.username}
        />
      </IdeaSettingsInnerContainer>
    </IdeaCreateParticipantsContainer>
  )
}

export default IdeaCreateParticipants
