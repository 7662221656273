// @ts-nocheck
import * as R from "ramda"
import React from "react"
import { useSelector } from "react-redux"

import { DynamicField } from "../../../../../components/formik"
const MainSettings = ({ method }) => {
  const fields = R.compose(R.filter(R.propEq("editable", true)))(
    useSelector(R.path(["shared", "fieldList", "analysis", method])),
  )
  return (
    <div>
      {fields.map((fieldDef, index) => (
        <DynamicField key={index} fieldDef={fieldDef} />
      ))}
    </div>
  )
}

export default MainSettings
