// @ts-nocheck
import { of } from "rxjs"
import { mergeMap } from "rxjs/operators"
import { ofType } from "redux-observable"

import authDuck from "../index"

const {
  actions: { logout, logoutOk },
} = authDuck

const logoutEpic = (action$, _, { auth }) =>
  action$.pipe(
    ofType(logout.type),
    mergeMap(() => {
      auth.deleteToken()
      return of(logoutOk())
    }),
  )

export default logoutEpic
