import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainerAndItem } from "./flexbox.styled"
import { SCIENT_COLORS } from "./scientColors"

export const SctSheetHeaderContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0 2px",
  height: "36px",
})

export const ShadowContainer = styled(FlexContainerAndItem)({
  backgroundColor: SCIENT_COLORS.darkGray4,
  border: `1.5px solid ${SCIENT_COLORS.darkGray3}`,
  borderRadius: "5px",
  webkitBoxShadow: "2px 3px 6px 0px #000000",
  boxShadow: "2px 3px 6px 0px #000000",
})

export const CardContainer = styled(FlexContainerAndItem)({
  backgroundColor: SCIENT_COLORS.darkGray4,
  borderRadius: "5px",
})

/**
 * Css properties to disable selection on text
 */
export const noSelect = css({
  webkitTouchCallout: "none" /* iOS Safari */,
  webkitUserSelect: "none" /* Safari */,
  khtmlUserSelect: "none" /* Konqueror HTML */,
  mozUserSelect: "none" /* Old versions of Firefox */,
  msUserSelect: "none" /* Internet Explorer/Edge */,
  userSelect: "none",
})

/**
 * Used in Nav globally -- adapted from former css
 */
export const NavAndSideStyled = styled.div({
  display: "grid",
  gridGap: 0,
  gridTemplateColumns: "6fr auto",
  gridTemplateRows: "50px min-content auto",
  gridTemplateAreas: `"nav nav" "subnav subnav" "content side"`,
  ".side": {
    backgroundColor: SCIENT_COLORS.darkGray5,
  },
  ".content": {
    backgroundColor: SCIENT_COLORS.darkGray3,
  },
})
