// @ts-nocheck
import * as R from "ramda"

const analyseLens = index => R.lensPath(["analyses", "list", index])
const findAnalyseIndex = (id, list) => R.findIndex(R.propEq("id", id))(list)

export const updateAnalyse = (state, { payload: { analyse } }) => {
  const index = findAnalyseIndex(analyse.id, state.analyses.list)
  return R.over(analyseLens(index), R.assoc("fetching", true), state)
}

export const updateAnalyseOk = (state, { payload: { analyse } }) => {
  const index = findAnalyseIndex(analyse.id, state.analyses.list)
  return R.set(analyseLens(index), analyse, state)
}
export const updateAnalyseError = (state, { payload: { id, error } }) => {
  const index = findAnalyseIndex(id, state.analyses.list)
  return R.over(analyseLens(index), R.compose(R.assoc("error", error), R.dissoc("fetching")), state)
}
