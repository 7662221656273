// @ts-nocheck
import * as R from "ramda"
import "./nodes.scss"

import React, { useState } from "react"

import { getParamsValues, isFilledOnlyOneInList } from "../../utils"
import DisplayNode from "./DisplayNode"
import { EditNode } from "./EditNode"

const Node = ({ id, data, type }) => {
  const { params, parameter_links } = data

  /**
   * Get list of params with key optional false, show not false, and value empty,
   * to know wich param must be filled. In this case EditNode must open.
   *  */
  const paramsRequired = params?.filter(e => !e.optional && !!e.show && R.isEmpty(e.value))

  // Logic to know if only one of the parameters of One In List is filled
  const paramValues = getParamsValues(params)
  const paramLinkOneInList =
    parameter_links &&
    R.compose(
      R.head, // There's always only one ParamLinkType of type ONE_IN_LIST
      R.filter(e => e.link_type === "ParamLinkType.ONE_IN_LIST"),
    )(parameter_links)
  const isOnlyOneFilled =
    paramLinkOneInList && isFilledOnlyOneInList({ paramValues, paramLinkOneInList }) // return true if only one required param of the ONE_IN_LIST is filled, else return false

  // When drop a node in canvas, EditNode must open   :
  //      _ if params are not optional and have no value and have key show!==false,
  //      _ if parameter_links is not empty and has link_type of type ONE_IN_LIST and none of the required_params of ONE_IN_LIST is filled
  const [edit, setEdit] = useState(
    (paramsRequired && !R.isEmpty(paramsRequired)) ||
      (paramLinkOneInList && !R.isEmpty(paramLinkOneInList) && !isOnlyOneFilled)
      ? true
      : false,
  )

  const toggleEdit = () => setEdit(!edit)

  return (
    <>
      <DisplayNode data={data} toggleEdit={toggleEdit} />
      {edit && <EditNode id={id} edit={edit} setEdit={setEdit} nodeType={type} data={data} />}
    </>
  )
}

export default Node
