// @ts-nocheck
import { Button, ButtonGroup, Callout, FormGroup } from "@blueprintjs/core"
import { Field } from "formik"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

const FreqChoice = ({ name, setFieldValue, removeSingleQuotes, freqs, value, touched, errors }) => {
  const handleClick = freq => {
    return setFieldValue(name, removeSingleQuotes ? freq : `'${freq}'`)
  }

  return (
    <>
      <ButtonGroup>
        {freqs.map(freq => (
          <Button
            key={freq}
            onClick={() => handleClick(freq)}
            active={value === freq || value === `'${freq}'`}
            text={freq}
          />
        ))}
      </ButtonGroup>
      {touched[name] && errors[name] && (
        <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
          {errors[name]}
        </Callout>
      )}
    </>
  )
}

export const FormikFreqChoice = ({
  name,
  freqs = ["D", "W", "M", "Q", "H", "Y"],
  removeSingleQuotes = false,
  labelProps = {},
}) => {
  return (
    <Field name={name}>
      {({ field: { name, value }, form: { setFieldValue, touched, errors } }) => {
        if (labelProps) {
          return (
            <FormGroup {...labelProps}>
              <FreqChoice
                name={name}
                setFieldValue={setFieldValue}
                removeSingleQuotes={removeSingleQuotes}
                freqs={freqs}
                value={value}
                touched={touched}
                errors={errors}
              />
            </FormGroup>
          )
        }

        return (
          <FreqChoice
            name={name}
            setFieldValue={setFieldValue}
            removeSingleQuotes={removeSingleQuotes}
            freqs={freqs}
            value={value}
            touched={touched}
            errors={errors}
          />
        )
      }}
    </Field>
  )
}
