import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../../hooks/useApi"
import { IAccountBookPairs } from "../../types"

const useRiskExposuresQuery = ({ accountBookPairs }: { accountBookPairs: IAccountBookPairs }) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["riskExposures", accountBookPairs],
    () => scientApi.trading.getRiskExposures({ accountBookPairs }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )

  return {
    riskExposures: data,
    isLoading,
    error,
  }
}

export default useRiskExposuresQuery
