// @ts-nocheck
import * as R from "ramda"

const searchOmniByNameReducers = {
  searchOmniByName: R.assocPath(["omniByName", "searching"], true),
  searchOmniByNameOk: (state, { payload: { results } }) =>
    R.over(
      R.lensProp("omniByName"),
      R.compose(R.assoc("searching", false), R.assoc("results", results)),
      state,
    ),

  searchOmniByNameError: (state, { payload: { error } }) =>
    R.set(R.lensProp("omniByName"), { searching: false, error: error, results: [] }, state),
  clearOmniByName: state =>
    R.set(R.lensProp("omniByName"), { searching: false, results: [] }, state),
}

export default searchOmniByNameReducers
