// @ts-nocheck
import React from "react"

import { Callout } from "../../../../../../components"

const Callouts = ({ dsforecast }) => (
  <>
    {dsforecast.process_agg_output && (
      <Callout
        icon="predictive-analysis"
        intent="success"
        title="Processing aggregation"
        body="Come back later to see results or reload page after a while."
      />
    )}
    {dsforecast.agg_output_error && (
      <Callout
        icon="predictive-analysis"
        intent="danger"
        title="Aggregation failed."
        body={`Here is the output: ${dsforecast.agg_output_error}`}
      />
    )}
  </>
)

export default Callouts
