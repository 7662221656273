// @ts-nocheck
import { H2 } from "@blueprintjs/core"
import React from "react"
import ChooseVariable from "../components/ChooseVariable"

const Step1ChooseDep = ({ variable, submit }) => {
  return (
    <div>
      <H2>Choose the variable you want to predict</H2>
      <ChooseVariable variable={variable} submit={submit} />
    </div>
  )
}

export default Step1ChooseDep
