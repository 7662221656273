// @ts-nocheck
import { of } from "rxjs"
import { map, mergeMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import monitorsDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { updateMonitor, updateMonitorOk, updateMonitorError },
} = monitorsDuck

const updateMonitorEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(updateMonitor.type),
    mergeMap(action =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/monitors/${action.payload.id}/`,
        method: "PATCH",
        body: action.payload,
        crossDomain: true,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          return updateMonitorOk(response)
        }),
        catchError(error => {
          return of(updateMonitorError({ id: action.payload.id, error: error.xhr.response.detail }))
        }),
      ),
    ),
  )

export default updateMonitorEpic
