// @ts-nocheck
import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import APIProvider from "./APIProvider"
import AuthProvider from "./AuthProvider"
import ScientRouter from "./Routes"

const queryClient = new QueryClient({
  defaultOptions: {
    /**
     * Disable refetch on window focus by default.
     */
    refetchOnWindowFocus: false,
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // 15 mins
  },
})

const App = ({ store }) => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <APIProvider>
          <AuthProvider>
            <Provider store={store}>
              <ScientRouter />
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
          </AuthProvider>
        </APIProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
