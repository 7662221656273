// @ts-nocheck
// export const operation = {
//   cf_cfo: {
//     add: [
//       ["sales"],

//       ["is_other_income_expense"],
//       ["is_non_operating_income_above"],
//       ["is_share_of_profit_from_associates_above"],

//       ["is_financial_income"],
//       ["is_non_operating_income_below"],
//       ["is_share_of_profit_from_associates_below"],

//       ["is_depreciation"],
//       ["is_amortization"],

//       ["cf_cash_taxes"],
//       ["cf_d_a_delta_vs_p_l"],
//       ["cf_gains_and_losses_from_investment_in_associate"],
//       ["cf_gains_and_losses_on_fixed_assets_divestments"],
//       ["cf_gains_and_losses_on_retirement_benefits"],
//       ["cf_financial_income_losses_delta_vs_p_l"],
//       ["cf_changes_in_taxes"],
//       ["cf_share_based_expense"],
//       ["cf_restructuring_income"],
//       ["cf_other_items"],
//       ["cf_changes_in_inventories"],
//       ["cf_changes_in_receivables"],
//       ["cf_changes_in_payables"],
//       ["cf_other_changes_in_current_items_of_bs"],
//       ["cf_wc_delta_bs_cf"],
//     ],
//     sub: [
//       ["is_reported_cogs"],
//       ["is_s_g_and_a"],
//       ["is_expensed_r_and_d"],
//       ["is_d_a_reported_in_is"],
//     ],
//   },
// }

export const cfo = {
  target: ["cf_cfo_readonly"],
  add: [
    ["sales"],

    ["is_other_income_expense"],
    ["is_non_operating_income_above"],
    ["is_share_of_profit_from_associates_above"],

    ["is_financial_income"],
    ["is_non_operating_income_below"],
    ["is_share_of_profit_from_associates_below"],

    ["is_depreciation"],
    ["is_amortization"],

    ["cf_cash_taxes"],
    ["cf_d_a_delta_vs_p_l"],
    ["cf_gains_and_losses_from_investment_in_associate"],
    ["cf_gains_and_losses_on_fixed_assets_divestments"],
    ["cf_gains_and_losses_on_retirement_benefits"],
    ["cf_financial_income_losses_delta_vs_p_l"],
    ["cf_changes_in_taxes"],
    ["cf_share_based_expense"],
    ["cf_restructuring_income"],
    ["cf_other_items"],
    ["cf_changes_in_inventories"],
    ["cf_changes_in_receivables"],
    ["cf_changes_in_payables"],
    ["cf_other_changes_in_current_items_of_bs"],
    ["cf_wc_delta_bs_cf"],
  ],
  sub: [["is_reported_cogs"], ["is_s_g_and_a"], ["is_expensed_r_and_d"], ["is_d_a_reported_in_is"]],
  method: "sum",
}

export const cfi = {
  target: ["cf_cfi_readonly"],
  add: [
    ["cf_capex_tangibles"],
    ["cf_capex_intangibles"],
    ["cf_capex_lease_assets"],
    ["cf_cash_outflows_for_financial_assets"],
    ["cf_cash_inflow_from_sale_of_tangible_assets"],
    ["cf_cash_inflow_from_sale_of_intangible_assets"],
    ["cf_cash_outflows_for_acquisitions"],
    ["cf_cash_inflow_from_sale_of_subsidiaries"],
    ["cf_interest_and_dividends"],
  ],
  method: "sum",
}

export const cff = {
  target: ["cf_cff_readonly"],
  add: [
    ["cf_dividends_to_minorities"],
    ["cf_dividends"],
    ["cf_proceeds_issuance_of_shares"],
    ["cf_proceeds_from_borrowings"],
    ["cf_repayment_of_borrowings"],
    ["cf_interest_paid_and_other_financial_disbursement"],
    ["cf_net_cash_contributions_to_pensions"],
    ["cf_add_back_non_cash_pension_charge"],
  ],
  method: "sum",
}

export const opfcf = {
  target: ["cf_opfcf_readonly"],
  add: [
    ["sales"],

    ["is_other_income_expense"],
    ["is_non_operating_income_above"],
    ["is_share_of_profit_from_associates_above"],

    ["is_financial_income"],
    ["is_non_operating_income_below"],
    ["is_share_of_profit_from_associates_below"],

    ["is_depreciation"],
    ["is_amortization"],

    ["cf_cash_taxes"],
    ["cf_d_a_delta_vs_p_l"],
    ["cf_gains_and_losses_from_investment_in_associate"],
    ["cf_gains_and_losses_on_fixed_assets_divestments"],
    ["cf_gains_and_losses_on_retirement_benefits"],
    ["cf_financial_income_losses_delta_vs_p_l"],
    ["cf_changes_in_taxes"],
    ["cf_share_based_expense"],
    ["cf_restructuring_income"],
    ["cf_other_items"],
    ["cf_changes_in_inventories"],
    ["cf_changes_in_receivables"],
    ["cf_changes_in_payables"],
    ["cf_other_changes_in_current_items_of_bs"],
    ["cf_wc_delta_bs_cf"],

    ["cf_capex_tangibles"],
    ["cf_capex_intangibles"],
    // ["cf_capex_lease_assets"],
    ["cf_cash_inflow_from_sale_of_tangible_assets"],
    ["cf_cash_inflow_from_sale_of_intangible_assets"],
    ["cf_dividends_to_minorities"],
  ],
  sub: [
    ["is_reported_cogs"],
    ["is_s_g_and_a"],
    ["is_expensed_r_and_d"],
    ["is_d_a_reported_in_is"],
    ["cf_share_based_expense"],
  ],
  method: "sum",
  override: ["cf_opfcf_override"],
}
