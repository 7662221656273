// @ts-nocheck
import React from "react"
import { assoc, compose, dissoc } from "ramda"
import { Classes, H5 } from "@blueprintjs/core"

import { DataFrameSuggest } from "../../../../../../../../../components"

export const SelectNewSource = ({ panelsState, setPanelsState, DFState, setDFState }) => {
  const appendSource = item => {
    const strName = item.str_name
    const regexId = /(.*)>(.*)/gi
    const sourceId = regexId.exec(strName)[2]

    setDFState(assoc(item.df_id, item, DFState))
    setPanelsState(
      compose(
        item.kind === "ds" || item.kind === "dsf" ? assoc("field", "['value']") : dissoc("field"),
        assoc("sourceId", `#${sourceId}`),
      )(panelsState),
    )
  }

  return (
    <div className={Classes.DIALOG_BODY} style={{ margin: "60px 120px" }}>
      <H5>
        Select source
        <DataFrameSuggest
          placeholder={panelsState.sourceId ? panelsState.sourceId : "Search..."}
          submit={appendSource}
        />
      </H5>
    </div>
  )
}
