import { RowDoubleClickedEvent } from "ag-grid-community"
import { useCallback, useEffect, useState } from "react"

import { useAgGrid } from "../../components/hooks/useAgGrid"
import { DataframeData, IAccountBookPairs } from "../../types"
import { getContextMenuItems } from "../../utils/getContextMenuItems"
import { RiskType } from "../types"
import { getRiskTypeFromValue } from "../utils/getRiskTypeFromValue"
import ExposureGrid from "./ExposureGrid"
import RiskZoomDialog from "./RiskZoomDialog"

interface IRiskGridProps extends DataframeData {
  riskType: string
  selectedFilters: IAccountBookPairs
}

const RiskGrid = ({
  columns,
  rows,
  pinnedBottomRows,
  riskType,
  selectedFilters,
}: IRiskGridProps) => {
  const [isRiskZoomDialogOpen, setIsRiskZoomDialogOpen] = useState(false)
  const [selectedRiskCategory, setSelectedRiskCategory] = useState("")
  const [selectedCategoryLabel, setSelectedCategoryLabel] = useState("")

  const { gridRef, defaultColDef, columnsData, sizeColumnsToFit, getRowStyle } = useAgGrid({
    columns,
    pinnedRowStyles: pinnedBottomRows?.rowStyles,
  })

  // Convert string riskType to RiskType enum
  const convertedRiskType: RiskType | undefined = getRiskTypeFromValue(riskType)

  /*
   * Handle double click on a row
   * Open RiskZoomDialog with the selected category
   */
  const onRowDoubleClicked = useCallback(
    (event: RowDoubleClickedEvent) => {
      setSelectedRiskCategory(
        event.data.category_id !== undefined ? event.data.category_id : event.data[riskType],
      )
      setSelectedCategoryLabel(event.data[riskType])
      if (convertedRiskType === RiskType.STYLES) {
        // For Styles we open the dialog directly
        setIsRiskZoomDialogOpen(true)
      }
    },
    [convertedRiskType, riskType],
  )

  /**
   * Open RiskZoomDialog when a category is selected.
   * This is triggered separately from the onRowDoubleClicked event
   * to prevent race conditions with state not being updated in time.
   */
  useEffect(() => {
    if (convertedRiskType !== RiskType.STYLES && selectedRiskCategory !== "") {
      // For all risk type except Styles we open the dialog when a category is selected
      setIsRiskZoomDialogOpen(true)
    }
  }, [selectedRiskCategory, convertedRiskType])

  if (!convertedRiskType) {
    console.warn(`Warning: Risk type '${riskType}' is not valid and cannot be converted.`)
    return null
  }

  const onCloseDialog = () => {
    setIsRiskZoomDialogOpen(false)
    setSelectedRiskCategory("")
    setSelectedCategoryLabel("")
  }

  return (
    <div id={convertedRiskType} key={convertedRiskType}>
      <ExposureGrid
        ref={gridRef}
        rowData={rows}
        pinnedBottomRowData={pinnedBottomRows?.rowData}
        columnDefs={columnsData}
        defaultColDef={defaultColDef}
        getRowStyle={getRowStyle}
        onFirstDataRendered={sizeColumnsToFit}
        suppressColumnVirtualisation={true}
        rowHeight={25}
        domLayout="autoHeight"
        suppressCellFocus={true}
        getContextMenuItems={params => getContextMenuItems(params)}
        onRowDoubleClicked={onRowDoubleClicked}
      />
      {isRiskZoomDialogOpen && (
        <RiskZoomDialog
          isOpen={isRiskZoomDialogOpen}
          type={convertedRiskType}
          onClose={onCloseDialog}
          category={selectedRiskCategory}
          categoryLabel={selectedCategoryLabel}
          selectedFilters={selectedFilters}
        />
      )}
    </div>
  )
}
export default RiskGrid
