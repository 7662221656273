// @ts-nocheck
import React from "react"
import { assoc, compose, dissoc } from "ramda"
import { Button, ButtonGroup, Card } from "@blueprintjs/core"

import { getNiceFormulaItem } from "../../../../../utils"

export const SelectExistingSource = ({
  existingSources,
  panelsState,
  setPanelsState,
  setSelectedSource,
  DFState,
}) => {
  const formatedExistingSources = existingSources.map(e => `#${e}`)

  const appendExistingSource = source => {
    setPanelsState(
      compose(
        source.includes("ds") || source.includes("dsf")
          ? assoc("field", "['value']")
          : dissoc("field"),
        assoc("sourceId", source),
      )(panelsState),
    )
    setSelectedSource("existingSource")
  }

  return (
    <Card
      style={{
        height: "auto",
        maxHeight: "450px",
        width: "520px",
        overflow: "auto",
        display: "grid",
      }}
    >
      <ButtonGroup vertical>
        {formatedExistingSources.map(source => (
          <Button
            key={source}
            text={getNiceFormulaItem({ item: source, dataframes: DFState })}
            onClick={() => appendExistingSource(source)}
            active={panelsState.sourceId && source.includes(panelsState.sourceId)}
          />
        ))}
      </ButtonGroup>
    </Card>
  )
}
