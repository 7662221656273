// @ts-nocheck
import autodux from "autodux"
import { format } from "date-fns"
import {
  assoc,
  compose,
  dissoc,
  indexBy,
  lensPath,
  lensProp,
  mergeDeepLeft,
  over,
  prop,
  set,
} from "ramda"

const fcstLens = (stkSlug, fcstSlug) => lensPath([stkSlug, "forecasts", fcstSlug])

const rForecastDuck = autodux({
  slice: "rForecasts",
  initial: {},
  actions: {
    fetchRForecasts: (state, { stkSlug }) => set(lensProp(stkSlug), { fetching: true }, state),
    fetchRForecastsOk: (state, { stkSlug, data }) =>
      over(
        lensProp(stkSlug),
        mergeDeepLeft({
          fetching: false,
          forecasts: indexBy(prop("slug"), data.results),
          lastFetch: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx"),
        }),
      )(state),
    fetchRForecastsError: (state, { stkSlug, error }) =>
      set(lensProp(stkSlug), { fetching: false, error }, state),

    fetchRForecast: (state, { stkSlug, fcstSlug }) =>
      over(fcstLens(stkSlug, fcstSlug), assoc("fetching", true), state),
    fetchRForecastOk: (state, { stkSlug, fcstSlug, payload }) =>
      set(fcstLens(stkSlug, fcstSlug), payload, state),
    fetchRForecastError: (state, { stkSlug, fcstSlug, error }) =>
      over(fcstLens(stkSlug, fcstSlug), compose(dissoc("fetching"), assoc("error", error)), state),
  },
})

export default rForecastDuck
