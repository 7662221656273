// @ts-nocheck
import React from "react"
import { Card, H4 } from "@blueprintjs/core"
import { Grid, Row, Col } from "react-flexbox-grid"

import { HeaderBuilder } from "../../HeaderBuilder"
import StylingPalette from "./FormikStyling"

export const MainSettings = ({ activeItem, st_sources, rf_sources, values, setValues }) => {
  return (
    <Card>
      <Grid fluid>
        <Row center="xs">
          <H4>Main settings</H4>
        </Row>
        <br />
        <Row>
          <Col xs>
            <HeaderBuilder
              activeItem={activeItem}
              values={values}
              setValues={setValues}
              st_sources={st_sources}
              rf_sources={rf_sources}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <StylingPalette activeItem={activeItem} />
        </Row>
      </Grid>
    </Card>
  )
}
