import { Button, Tooltip } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { useEffect, useMemo, useState } from "react"

import { createToaster } from "../../../../utils/createToaster"
import useRefreshTradingData from "../hooks/useRefreshTradingData"
import { IRefreshTradingDataResponse } from "../types"

interface IRefreshButtonProps {
  dateDistance: string
}

export const RefreshButton = ({ dateDistance }: IRefreshButtonProps) => {
  const [isRefreshing, setIsRefreshing] = useState(false)
  const isFreshData = useMemo(() => dateDistance === "1min ago", [dateDistance])

  /**
   *  When user press the refresh button, refresh trading data (using trading_pipeline) is called.
   *  When dateDistance is less than 1 min, data has been refreshed, so 'isRefreshing' is set to false.
   */
  useEffect(() => {
    if (isFreshData) {
      setIsRefreshing(false)
    }
  }, [isFreshData])

  const { mutate } = useRefreshTradingData({
    onSuccess: (data: IRefreshTradingDataResponse) => {
      setIsRefreshing(true)
    },
    onError: (error: AxiosError) => {
      createToaster({
        message: "Data cannot be refreshed. Please contact a Scient administrator.",
        intent: "warning",
      })
    },
  })

  return (
    <Tooltip content="Refresh available once every minute" placement="bottom" hoverOpenDelay={1000}>
      <Button
        onClick={() => mutate()}
        disabled={isFreshData}
        icon="refresh"
        intent="primary"
        loading={isRefreshing}
      />
    </Tooltip>
  )
}
