// @ts-nocheck
export const selectStSrcKeys = item => {
  const { id, kind, kind_id } = item
  switch (kind) {
    case "st":
      return {
        id,
        kind,
        kind_id,
        name: item.name,
        ticker: item.bloomberg_code,
        last_report_date: item.last_report_date,
      }
    case "rf":
      return {
        id,
        kind,
        kind_id,
        parent_id: item.parent_id,
        company: item.company,
        src_name: item.src_name,
        name: item.verbose_name,
        ticker: item.ticker,
      }
    default:
      throw new Error("wrong king in formatStSrc")
  }
}
