// @ts-nocheck
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { find, isEmpty, pathOr, propEq, toLower } from "ramda"
import { Button, MenuItem, H5, H6, Colors } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"

import stockDetailDuck from "../../state/modules/stockDetail"
import { allEntryFields, createStockRows, randomid } from "../../utils"

export const ConnectedStockPathSelect = ({ dataCy, slug, value, update }) => {
  const stock = useSelector(pathOr({}, ["stockDetail", slug]))
  const dispatch = useDispatch()
  useEffect(() => {
    isEmpty(stock) && dispatch(stockDetailDuck.actions.fetchStock({ slug }))
  }, [dispatch, slug, stock])

  if (!stock || stock.stockFetching || !stock.reportData || stock.reportFetching) {
    return <Button fill disabled text="Loading..." />
  }

  return (
    <StockPathSelect stockData={stock.stockData} update={update} value={value} dataCy={dataCy} />
  )
}

export const StockPathSelect = ({
  stockData,
  entries = [],
  update,
  value,
  options = { showEmpty: true },
  dataCy,
}) => {
  const allFields = createStockRows({
    rowDefs: allEntryFields,
    entries,
    stock: stockData,
    options,
  }).map(field => ({ ...field, id: randomid() }))
  const [filteredFields, setFilteredFields] = useState(allFields)

  const itemRenderer = (field, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    if (field.type === "date") return null
    if (["title1", "title2"].includes(field.type))
      return (
        <li key={field.id} className="bp5-menu-header">
          {field.type === "title1" ? (
            <H5 style={{ color: Colors.GRAY4 }}>{field.text}</H5>
          ) : (
            <H6 style={{ color: Colors.GRAY5 }}>{field.text}</H6>
          )}
        </li>
      )
    return (
      <MenuItem active={modifiers.active} key={field.id} onClick={handleClick} text={field.text} />
    )
  }

  const handleQueryChange = query => {
    const results = allFields.filter(field => {
      if (["header", "subHeader"].includes(field.type)) return true
      const lowerQ = toLower(query)
      if (toLower(field.text).includes(lowerQ)) {
        return true
      }
      return false
    })
    setFilteredFields(results)
  }

  const selectedField = find(propEq("path", value), allFields)
  const buttonText = selectedField ? selectedField.text : "(No selection)"

  return (
    <Select
      className="sct-fullwidth"
      items={filteredFields}
      itemRenderer={itemRenderer}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={update}
      onQueryChange={handleQueryChange}
    >
      <Button fill icon="form" rightIcon="caret-down" text={buttonText} data-cy={dataCy} />
    </Select>
  )
}
