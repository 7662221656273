// @ts-nocheck
import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"

export const HomeContainer = styled(FlexContainer)({
  width: "100%",
  height: "100%",
  textAlign: "center",
  gridArea: "content",
})

export const HomeIconContainer = styled(FlexContainer)({
  marginLeft: "15%",
})

export const WidgetContainer = styled(FlexContainer)({
  width: "40%",
})
