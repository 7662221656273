import { Callout, Icon, Tooltip } from "@blueprintjs/core"
import { getIn, useField, useFormikContext } from "formik"
import { useState } from "react"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { IdeaCreateLabel } from "./IdeaCreate.styled"
import { IdeaCreateConvictionContainer, StarSelectArea } from "./IdeaCreateConviction.styled"

interface IIdeaCreateConviction {
  disabled: boolean
  fieldName: string
  hidden?: boolean
  index?: number
}

/**
 * When trade is open or closed, user can't modify the conviction.
 * A tooltip displays to inform the user.
 */
const IdeaCreateConviction = ({ disabled, fieldName, hidden, index }: IIdeaCreateConviction) => {
  const convictions = [1, 2, 3, 4, 5]

  const [starOverred, setStarOverred] = useState(0)
  const [conviction] = useField(fieldName)

  const { setFieldValue, errors } = useFormikContext<Record<string, string>>()

  const onStarClick = (starId: number) => {
    if (starId === conviction.value) {
      setFieldValue(conviction.name, undefined)
    } else {
      setFieldValue(conviction.name, starId)
    }
  }
  const focusedStarId = conviction.value === undefined ? starOverred : conviction.value

  return (
    <IdeaCreateConvictionContainer flexDirection="column" justifyContent="center" hidden={!!hidden}>
      <FlexContainer flexDirection="row" alignItems="center" gap="25px">
        <IdeaCreateLabel>{`Conviction` + (index ? ` (${index})` : "")}</IdeaCreateLabel>
        <Tooltip
          content={`Cannot modify conviction in this case`}
          placement="top"
          compact
          disabled={!disabled}
          matchTargetWidth
        >
          <StarSelectArea onMouseLeave={() => setStarOverred(0)} gap="10px" disabled={disabled}>
            {convictions.map(id => (
              <Icon
                id={`${id}`}
                key={id}
                color={
                  id <= focusedStarId && focusedStarId > 4
                    ? SCIENT_COLORS.forest3
                    : id <= focusedStarId && focusedStarId > 2
                      ? SCIENT_COLORS.orange4
                      : id <= focusedStarId && focusedStarId > 0
                        ? SCIENT_COLORS.vermilion4
                        : undefined
                }
                icon="star"
                size={20}
                onClick={() => !disabled && onStarClick(id)}
                onMouseEnter={() => setStarOverred(id)}
              />
            ))}
          </StarSelectArea>
        </Tooltip>
      </FlexContainer>
      <FlexContainer alignItems="center" flexDirection="row">
        {getIn(errors, conviction.name) && (
          <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
            {`Conviction is a required field`}
          </Callout>
        )}
      </FlexContainer>
    </IdeaCreateConvictionContainer>
  )
}

export default IdeaCreateConviction
