// @ts-nocheck
import React from "react"
import { Drawer } from "@blueprintjs/core"

import ConsensusPref from "./ConsensusPref"

const ConsensusDrawer = ({ stockSlug, isOpen, onClose, dispatch }) => {
  return (
    <Drawer
      className="bp5-dark"
      icon="settings"
      isOpen={isOpen}
      isCloseButtonShown={true}
      onClose={onClose}
      title="Consensus Settings"
      size="25%"
      hasBackdrop={true}
    >
      <ConsensusPref stockSlug={stockSlug} dispatch={dispatch} />
    </Drawer>
  )
}
export default ConsensusDrawer
