// @ts-nocheck
import * as R from "ramda"

export const fetchFieldList = R.compose(R.assoc("ready", false), R.dissoc("error"))

export const fetchFieldListOk = (state, { payload: { fieldList } }) => ({ fieldList, ready: true })

export const fetchFieldListError = (state, { payload: { error } }) => ({
  ...state,
  error,
  ready: true,
})
