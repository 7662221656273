// @ts-nocheck
import { mergeMap, map } from "rxjs/operators"
import { ofType } from "redux-observable"
// import { isEmpty } from "ramda"

import dataSetSearchDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { createChangeDS, createChangeDSOk },
} = dataSetSearchDuck

const createChangeDSEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(createChangeDS.type),
    mergeMap(action => {
      const { sourceIndex, sourceId, change, mode } = action.payload
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/aggregators/changeds/`,
        method: "POST",
        crossDomain: true,
        responseType: "json",
        body: {
          source: sourceId,
          change,
          mode,
        },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          const chg_output = response.output
          return createChangeDSOk({ sourceIndex, chg_output })
        }),
      )
    }),
  )

export default createChangeDSEpic
