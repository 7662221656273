// @ts-nocheck
import React from "react"
import { TargetHandle } from "./handles"

const Inputs = props => {
  const { inputs } = props

  return (
    <>
      {inputs?.map((input, index) => {
        const handleId = `|${index}|${input.param_type}`
        // If many is true, so the handle accepts many edges
        const { many, name } = input

        return (
          <div key={handleId}>
            <TargetHandle
              {...input}
              id={handleId}
              index={index}
              length={inputs.length}
              many={many}
              name={name}
            />
          </div>
        )
      })}
    </>
  )
}

export default Inputs
