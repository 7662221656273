import { add, addDays, addWeeks, format, parse, startOfISOWeekYear, startOfYear } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

/**
 * This function takes a period (2020Y, 2020M5...) and returns
 * an object with:
 *         _ start date of the period
 *         _ end date of the period
 */

export const datesFromName = (periodString: string) => {
  const [[, year, period, num]] = periodString.matchAll(/(\d{4})(Y|H|Q|M|BM|M|W|D)?(\d+)?/g)

  const duration = () => {
    switch (period) {
      case "Y":
      case undefined:
        return { years: 1 }
      case "H":
        return { months: 6 }
      case "Q":
        return { months: 3 }
      case "BM":
        return { months: 2 }
      case "M":
        return { months: 1 }
      case "W":
        return { weeks: 1 }
      case "D":
        return { days: 1 }
      default:
        throw new Error("In duration")
    }
  }

  if (period === "Y" || !period) {
    return {
      start: format(parse(year, "yyyy", new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      end: format(add(parse(year, "yyyy", new Date()), duration()), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    }
  }

  const getStartSuffix = () => {
    switch ([period, num].join(",")) {
      case "H,1":
      case "Q,1":
      case "BM,1":
      case "M,1":
        return "-01-01T00:00Z"
      case "M,2":
        return "-02-01T00:00Z"
      case "BM,2":
      case "M,3":
        return "-03-01T00:00Z"
      case "Q,2":
      case "M,4":
        return "-04-01T00:00Z"
      case "BM,3":
      case "M,5":
        return "-05-01T00:00Z"
      case "M,6":
        return "-06-01T00:00Z"
      case "H,2":
      case "Q,3":
      case "BM,4":
      case "M,7":
        return "-07-01T00:00Z"
      case "M,8":
        return "-08-01T00:00Z"
      case "BM,5":
      case "M,9":
        return "-09-01T00:00Z"
      case "Q,4":
      case "M,10":
        return "-10-01T00:00Z"
      case "BM,6":
      case "M,11":
        return "-11-01T00:00Z"
      case "M,12":
      case "":
        return "-12-01T00:00Z"
      default:
        throw new Error(`${period}, ${num}`)
    }
  }
  if (["H", "Q", "BM", "M"].includes(period)) {
    const start = utcToZonedTime(new Date(`${year}${getStartSuffix()}`), "UTC")
    const end = add(start, duration())
    return {
      start: format(start, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      end: format(end, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    }
  }
  if (period === "W") {
    // Convert year and week (=num) to numbers
    const parsedYear = parseInt(year)
    const parsedWeek = parseInt(num)
    /**
     *  Calculate the start date of the year.
     *  We choose new date as the 1th february of the year to avoid 1th january
     *  where week can belong to the previous year.
     */
    const startOfYearDate = startOfISOWeekYear(new Date(parsedYear, 1, 1))
    // Add the parsedWeek - 1 to the startOfYearDate to get the start date
    const start = addWeeks(startOfYearDate, parsedWeek - 1)

    const end = add(start, duration())
    return {
      start: format(start, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      end: format(end, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    }
  }
  if (period === "D") {
    // Convert year and day (=num) to numbers
    const parsedYear = parseInt(year)
    const parsedDay = parseInt(num)
    // Calculate the start date of the year
    const startOfYearDate = startOfYear(new Date(parsedYear, 0, 1))
    // Add the parsedDay - 1 to the startOfYearDate to get the start date
    const start = addDays(startOfYearDate, parsedDay - 1) // Subtract 1 because day numbers are one-based

    const end = add(start, duration())
    return {
      start: format(start, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      end: format(end, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    }
  }
}
