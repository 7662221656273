// @ts-nocheck
import Chart from "./Chart"
import React from "react"
import Table from "./Table"

const NextGenTimeFrame = ({
  desk,
  monitor,
  sheet,
  sheetId,
  height,
  editRowItems,
  setEditRowItems,
}) => {
  return (
    <>
      {sheet.display === "table" ? (
        <Table
          desk={desk}
          monitor={monitor}
          sheetId={sheetId}
          height={height}
          editRowItems={editRowItems}
          setEditRowItems={setEditRowItems}
        />
      ) : (
        <Chart
          desk={desk}
          monitor={monitor}
          sheetId={sheetId}
          height={height}
          editRowItems={editRowItems}
          setEditRowItems={setEditRowItems}
        />
      )}
    </>
  )
}

export default NextGenTimeFrame
