// @ts-nocheck
import { map } from "ramda"

// For a formula node, return an objet with param id as key assoc to the value of the param
export const getParamsValues = params => {
  const formatedParams =
    params &&
    map(
      param =>
        param.type === "FLOAT" && param.many === true
          ? { ...param, value: param.value.replace(/[\[\]]/g, "") }
          : param,
      params,
    )

  return formatedParams?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.value }), {})
}
