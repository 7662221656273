// @ts-nocheck
import * as R from "ramda"

import { addEdge, updateEdge } from "react-flow-renderer"

import { randomid } from "../../../../../../../../../utils"
import { updateConnectable } from "../helpers"
import { useCallback } from "react"

export const useOnConnect = ({ setNodes, setEdges }) =>
  useCallback(
    params => {
      setNodes(oldNodes => updateConnectable(params, false, oldNodes))

      return setEdges(eds => addEdge(params, eds))
    },
    [setEdges, setNodes],
  )

export const useOnDragOver = () =>
  useCallback(event => {
    event.preventDefault()
    event.dataTransfer.dropEffect = "move"
  }, [])

export const useOnEdgeUpdate = ({ setNodes, setEdges }) =>
  useCallback(
    (oldEdge, newConnection) => {
      if (isValidConnection(newConnection)) {
        setNodes(oldNodes =>
          R.compose(
            updateConnectable(oldEdge, true),
            updateConnectable(newConnection, false),
          )(oldNodes),
        )

        return setEdges(els => updateEdge(oldEdge, newConnection, els))
      }
    },
    [setEdges, setNodes],
  )

export const useOnEdgesDelete = ({ nodes, setNodes }) =>
  useCallback(
    edges => {
      R.map(edge => {
        return setNodes(updateConnectable(edge, true, nodes))
      }, edges)
    },
    [nodes, setNodes],
  )

export const useOnDrop = ({ reactFlowWrapper, setNodes, rfInstance, nodeDefs }) =>
  useCallback(
    event => {
      event.preventDefault()

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
      const type = event.dataTransfer.getData("application/reactflow")

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return
      }

      const additionalData = R.find(R.propEq("type", type))(nodeDefs.nodes)

      const id = `${type}_${randomid(12)}`
      const data = R.compose(
        R.assoc("enhancedPath", nodeDefs.enhancedPath),
        R.assoc("key", id),
        R.over(
          R.lensProp("params"),
          R.map(param =>
            R.compose(R.dissoc("default_value"), R.assoc("value", param.default_value))(param),
          ),
        ),
      )(additionalData)

      const position = rfInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      })

      const newNode = {
        id,
        type,
        position,
        data,
      }

      setNodes(nds => nds.concat(newNode))
    },
    [reactFlowWrapper, setNodes, rfInstance, nodeDefs],
  )

export const useOnNodesDelete = ({ nodes, setNodes, edges }) =>
  useCallback(
    deletedNodes => {
      const connectorsToUpdate = R.compose(
        R.flatten,
        R.reduce(R.concat, R.__, []),
        R.map(deletedNode => {
          const sourceEdges = R.compose(
            R.map(R.pick(["target", "targetHandle"])),
            R.filter(R.propEq("source", deletedNode.id)),
          )(edges)
          const targetEdges = R.compose(
            R.map(R.pick(["source", "sourceHandle"])),
            R.filter(R.propEq("target", deletedNode.id)),
          )(edges)
          return [...sourceEdges, ...targetEdges]
        }),
      )(deletedNodes)

      const newNodes = R.reduce(
        (newNodes, connector) => {
          const output = updateConnectable(connector, true, newNodes)
          return output
        },
        nodes,
        connectorsToUpdate,
      )

      setNodes(newNodes)
    },
    [edges, nodes, setNodes],
  )

export const isValidConnection = connection => {
  const sourceType = connection.sourceHandle.split("|")[2]
  const targetType = connection.targetHandle.split("|")[2]

  return sourceType === targetType
}
