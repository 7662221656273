// @ts-nocheck
import React from "react"
import { ControlGroup, Button, Classes } from "@blueprintjs/core"

import { ButtonWithAlert, ButtonWithDialog } from "../../../../../components"
import Sharing from "./Sharing"

const Manage = ({
  dataSet,
  index,
  id,
  isOwnerOrAdmin,
  fetchForecast,
  updateForecast,
  deleteForecast,
  dsforecast,
  groups,
  name,
}) => {
  return (
    <div>
      <ControlGroup style={{ textAlign: "center" }}>
        <Button
          data-cy="RefreshDSF"
          onClick={() => fetchForecast({ dataset: dataSet.id, index, id })}
          icon="refresh"
        />
        {isOwnerOrAdmin && (
          <>
            <ButtonWithDialog
              buttonProps={{
                "data-cy": "DSFShare",
                intent: "success",
                icon: "social-media",
              }}
              dialogProps={{
                title: "Sharing",
              }}
            >
              {({ setDialog }) => (
                <div className={Classes.DIALOG_BODY}>
                  <Sharing
                    index={index}
                    dataset={dataSet.id}
                    dsforecast={dsforecast}
                    myGroups={groups}
                    updateForecast={updateForecast}
                    setDialog={setDialog}
                  />
                </div>
              )}
            </ButtonWithDialog>
            <ButtonWithAlert
              buttonProps={{
                intent: "danger",
                icon: "trash",
                "data-cy": "DSFDelete",
              }}
              alertProps={{
                confirmButtonText: `Yes, delete ${name}`,
                onConfirm: () => deleteForecast({ dataset: dataSet.id, index, id }),
              }}
            >
              <p>Do you really want to delete {name}?</p>
            </ButtonWithAlert>
          </>
        )}
      </ControlGroup>
    </div>
  )
}

export default Manage
