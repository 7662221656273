import { Dispatch, SetStateAction } from "react"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { RiskType } from "../../Risks/types"
import { RebalancingType } from "../types"
import { SectorButton } from "./SectorButtons.style"

interface ISectorButtons {
  selectedSector: Partial<RiskType>
  setSelectedSector: Dispatch<SetStateAction<RebalancingType>>
}

/**
 * Scient app uses Blueprint Dark mode, which therefore applies to Button.
 * We want white buttons and
 * There's no equivalent of className="bp5-dark" for light mode.
 * So we have to customise Button with our own css.
 */
export const SectorButtons = ({ selectedSector, setSelectedSector }: ISectorButtons) => {
  return (
    <FlexContainer>
      <SectorButton
        onClick={() => setSelectedSector(RiskType.SECTOR_LEVEL_1)}
        isActive={selectedSector === RiskType.SECTOR_LEVEL_1}
        withLeftBorderRadius
        withBorders
      >
        L1
      </SectorButton>
      <SectorButton
        onClick={() => setSelectedSector(RiskType.SECTOR_LEVEL_2)}
        isActive={selectedSector === RiskType.SECTOR_LEVEL_2}
        withRightBorderRadius
      >
        L2
      </SectorButton>
    </FlexContainer>
  )
}
