import { css } from "@emotion/react"
import styled from "@emotion/styled"

export const RiskSelectorAnchor = styled.a({
  ".bp5-icon-minus": { opacity: 0 },
  "&:hover": {
    ".bp5-icon-minus": { opacity: 1 },
  },
})

export const minusIconCss = css({
  color: "white !important",
  marginTop: "-5px",
})

interface IconWrapperProps {
  margin: string
}

export const IconWrapper = styled.div<IconWrapperProps>`
  margin: ${props => props.margin};
  .bp5-icon {
    color: white !important;
  }
`
