// @ts-nocheck
import React, { useState } from "react"
import { pick } from "ramda"

import "./form.css"

import TabContent from "./TabContent"
import Header from "./Header"

const Form = props => {
  const {
    handleSubmit,
    isSubmitting,
    reportData: { report, prevReport, prevYearReport },
    values,
    status,
    handleClose,
  } = props
  const [tabId, setInternalTabId] = useState("dates")
  const [subTabId, setSubTabId] = useState("default")

  const setTabId = tabId => {
    setSubTabId("default")
    setInternalTabId(tabId)
  }

  // const handleChange = params => {
  //   const {
  //     target: { id, name, type, value },
  //   } = params
  //   if (id === "sales") {
  //     setFieldValue("sales_growth", 0.05)
  //   }
  //
  //   return props.handleChange(params)
  // }

  return (
    <>
      <div className="subnav">
        <Header
          tabId={tabId}
          setTabId={setTabId}
          subTabId={subTabId}
          setSubTabId={setSubTabId}
          report={report}
          prevReport={prevReport}
          prevYearReport={prevYearReport}
          handleClose={handleClose}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          status={status}
          dates={pick(["year", "period", "start", "end", "release_date"], values)}
        />
      </div>
      <div className="content">
        <TabContent tabId={tabId} subTabId={subTabId} {...props} />
      </div>
    </>
  )
}

export default Form
