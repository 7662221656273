import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useApi } from "../../../hooks/useApi"

const useResetPwdRequestMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (email: string) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.auth.passwordReset, { onSuccess, onError })
}

export { useResetPwdRequestMutation }
