// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import dataframesDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import shapeDataframes from "./shapeDataframes"

const {
  actions: { fetchDataframeList, fetchDataframeListOk, fetchDataframeListError },
} = dataframesDuck

const fetchDataframeListEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchDataframeList.type),
    mergeMap(action => {
      const { ids, sheetId } = action.payload

      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/dataframes/search/?ids=${ids}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          return fetchDataframeListOk({
            sheetId,
            byDfId: ids.length > 0 ? shapeDataframes(response) : {},
          })
        }),
        catchError(error => of(fetchDataframeListError(error.xhr.response))),
      )
    }),
  )

export default fetchDataframeListEpic
