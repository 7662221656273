/** @jsxImportSource @emotion/react */

import { Button, Icon, Intent, Tag, Tooltip } from "@blueprintjs/core"
import { format, parseISO } from "date-fns"
import { useMemo } from "react"

import { FlexContainer, FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { MediumText } from "../../../../../styled/text.styled"
import { roundNumber } from "../../../../_shared"
import { useAuth, useIdeasCache } from "../../../../hooks"
import { useGlobalState } from "../../../../hooks/useGlobalState"
import { createToaster } from "../../../../utils/createToaster"
import { useCloseTradeMutation, useOpenTradeMutation } from "../../hooks"
import { Idea, IdeaType, isStockPosition } from "../../types/business"
import {
  IdeaPositionsContainer,
  PositionInfos,
  PositionInfosContainer,
  PriceInfo,
  iconCss,
} from "./IdeaPositions.styled"

/**
 * Perf component display trend perf.
 * It can be use with short, long or global perf.
 */
interface IPerfProps {
  perf: number
  fontSize?: string
  iconSize?: number
}

const Perf = ({ perf, fontSize, iconSize }: IPerfProps) => {
  return (
    <FlexContainer gap="6px">
      <Icon
        icon={perf === 0 ? "arrow-right" : perf > 0 ? "trending-up" : "trending-down"}
        color={perf === 0 ? undefined : perf > 0 ? SCIENT_COLORS.forest3 : SCIENT_COLORS.vermilion4}
        size={iconSize ? iconSize : 20}
      />
      <PositionInfos perf={perf} fontSize={fontSize}>
        {roundNumber(perf)}%
      </PositionInfos>
    </FlexContainer>
  )
}

interface IIdeaPositionsProps {
  idea: Idea
}

const IdeaPositions = ({ idea }: IIdeaPositionsProps) => {
  const trade = idea.trade

  /**
   * If trade returns an error, user is prompted to contact the dev support.
   * Dev team needs to check error message in idea.trade.
   */
  const tradeError = trade?.computation_error

  const ideaIsTradable = !idea.draft && idea.idea_type === IdeaType.TRADE

  const ideaOwnerId = idea.user.id
  const { profile } = useAuth()
  const userId = profile?.id

  const { updateIdea } = useIdeasCache()

  /**
   * Need date of the day in order to not display idea perf the day tracking is started.
   * Instead of, stock price of the day is displayed.
   */
  const today = format(parseISO(new Date().toISOString()), "dd-MM-yyyy")
  const openTradeDate = trade?.opened_at && format(parseISO(trade.opened_at), "dd-MM-yyyy")
  const closeTradeDate = trade?.closed_at && format(parseISO(trade.closed_at), "dd-MM-yyyy")

  const { mutate: openTradeMutation, isLoading: isLoadingOpenTrade } = useOpenTradeMutation({
    onSuccess: trade => {
      updateIdea && trade.idea_id && updateIdea(trade.idea_id, { trade })
    },
    onError: () => {
      createToaster({
        message: "An error occurred, please refresh the page.",
        intent: "danger",
      })
    },
  })

  const { mutate: closeTradeMutation, isLoading: isLoadingCloseTrade } = useCloseTradeMutation({
    onSuccess: trade => {
      updateIdea && trade.idea_id && updateIdea(trade.idea_id, { ...idea, trade })
    },
    onError: () => {
      createToaster(
        openTradeDate === today
          ? {
              message: "Cannot close trade of an idea the same day it opened.",
              intent: Intent.WARNING,
            }
          : {
              message: "An error occurred, please refresh the page.",
              intent: Intent.DANGER,
            },
      )
    },
  })

  /**
   * @Todo Properly check types with ts Stock vs Sector
   */
  let shortName: string | undefined = undefined
  let longName: string | undefined = undefined
  if (trade?.short.instance) {
    shortName = isStockPosition(trade.short.instance)
      ? trade.short.instance.bloomberg_code
      : trade.short.instance.name
  }
  if (trade?.long.instance) {
    longName = isStockPosition(trade.long.instance)
      ? trade.long.instance.bloomberg_code
      : trade.long.instance.name
  }

  /**
   * Array positions is used to render position label, name and perf if exist.
   */
  const positions = useMemo(() => {
    return [
      {
        type: "long",
        label: "LONG",
        name: longName,
        trade: trade?.long,
        currencyLabel: trade?.long.instance
          ? isStockPosition(trade.long.instance)
            ? trade.long.instance.trading_currency?.name
            : trade.long.instance.related_dataset_unit
          : undefined,
      },
      {
        type: "short",
        label: "SHORT",
        name: shortName,
        trade: trade?.short,
        currencyLabel: trade?.short.instance
          ? isStockPosition(trade.short.instance)
            ? trade.short.instance.trading_currency?.name
            : trade.short.instance.related_dataset_unit
          : undefined,
      },
    ]
  }, [longName, shortName, trade?.long, trade?.short])

  return (
    <IdeaPositionsContainer justifyContent="space-between" flexDirection="row-reverse">
      <FlexContainerAndItem alignItems="center" gap="10px">
        {openTradeDate && <Tag>Open {openTradeDate}</Tag>}

        {ideaOwnerId === userId && ideaIsTradable && !idea.trade?.closed_at && (
          <Tooltip
            content={
              <>
                {!openTradeDate ? (
                  <FlexContainer flexDirection="column" alignItems="center" gap="6px">
                    <MediumText color={SCIENT_COLORS.darkGray5}>Open the trade.</MediumText>
                    <MediumText color={SCIENT_COLORS.darkGray5}>
                      Trend will be available one day after the trend opens.
                    </MediumText>
                  </FlexContainer>
                ) : (
                  "Close the trade"
                )}
              </>
            }
            placement="bottom"
            compact
          >
            <Button
              text={openTradeDate && !closeTradeDate ? "Close" : "Open"}
              intent={openTradeDate && !closeTradeDate ? "danger" : "success"}
              icon="pulse"
              onClick={() =>
                openTradeDate && !closeTradeDate
                  ? closeTradeMutation(trade.id as number)
                  : openTradeMutation(trade?.id as number)
              }
              loading={isLoadingOpenTrade || isLoadingCloseTrade}
              style={{ width: "102px" }}
            />
          </Tooltip>
        )}

        {idea.trade?.closed_at && <Tag>Closed {closeTradeDate}</Tag>}
      </FlexContainerAndItem>

      <PositionInfosContainer alignItems="center" gap="50px">
        {positions.map(pos => {
          /**
           * If performance returns an error, user is prompted to contact the dev support.
           * Dev team needs to check error message in idea.performance.
           */
          const positionPerf = pos.trade?.perf
          const startPrice = pos.trade?.start_price
          const endPrice = pos.trade?.end_price
          const startDate =
            pos.trade?.start_price_at && format(parseISO(pos.trade.start_price_at), "dd-MM-yyyy")
          const endDate =
            pos.trade?.end_price_at && format(parseISO(pos.trade.end_price_at), "dd-MM-yyyy")

          return (
            <PositionInfosContainer alignItems="center" gap="10px" key={pos.type}>
              <PositionInfos>
                {pos.label}: {pos.name}
              </PositionInfos>

              {trade && !tradeError && (
                <Tooltip
                  content={
                    <FlexContainer flexDirection="column" alignItems="center" gap="6px">
                      {startPrice ? (
                        <MediumText color={SCIENT_COLORS.darkGray5}>
                          Open: {roundNumber(startPrice)} {pos.currencyLabel} on {startDate}
                        </MediumText>
                      ) : !positionPerf ? (
                        /**
                         * If there's no startPrice and trade is open, the next available price will be taken as startPrice.
                         * If there's no startPrice and trade is closed, performance can't be calculated yet until data is added in db.
                         */
                        <FlexContainer flexDirection="column" alignItems="center" gap="6px">
                          <MediumText color={SCIENT_COLORS.darkGray5}>
                            No {pos.type} price available since open date {startDate}.
                          </MediumText>
                          <MediumText color={SCIENT_COLORS.darkGray5}>
                            {closeTradeDate
                              ? "No historical data available to compute the performance."
                              : "The next available price will be taken as the opening price."}
                          </MediumText>
                        </FlexContainer>
                      ) : (
                        <FlexContainer flexDirection="column" alignItems="center" gap="6px">
                          <MediumText color={SCIENT_COLORS.darkGray5}>Open: {startDate}</MediumText>
                          {endDate && (
                            <MediumText color={SCIENT_COLORS.darkGray5}>
                              Closed: {endDate}
                            </MediumText>
                          )}
                        </FlexContainer>
                      )}

                      {today !== openTradeDate && endPrice && (
                        <MediumText color={SCIENT_COLORS.darkGray5}>
                          {closeTradeDate ? "Close" : "Last"}: {roundNumber(endPrice)}
                          {pos.currencyLabel} on {endDate}
                        </MediumText>
                      )}
                    </FlexContainer>
                  }
                  placement="bottom"
                  compact
                >
                  {tradeError ? (
                    <Icon icon="warning-sign" css={iconCss} />
                  ) : !startPrice && !pos.trade?.perf ? (
                    // In case we have no start price and no performance
                    // We can have no start price but a performance if the position is a group of stocks
                    <Icon icon="help" css={iconCss} />
                  ) : openTradeDate === today && startPrice ? (
                    <PriceInfo>
                      {roundNumber(startPrice)} {pos.currencyLabel}
                    </PriceInfo>
                  ) : positionPerf || positionPerf === 0 ? (
                    <Perf perf={positionPerf} />
                  ) : null}
                </Tooltip>
              )}
            </PositionInfosContainer>
          )
        })}
      </PositionInfosContainer>

      <PositionInfosContainer alignItems="center">
        {trade?.main_perf || trade?.main_perf === 0 ? (
          <Tooltip
            content={
              <FlexContainer flexDirection="column" alignItems="center" gap="6px">
                <MediumText color={SCIENT_COLORS.darkGray5}>Trade open: {openTradeDate}</MediumText>
                {closeTradeDate && (
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    Trade closed: {closeTradeDate}
                  </MediumText>
                )}
              </FlexContainer>
            }
            placement="bottom"
            compact
          >
            <Perf perf={trade?.main_perf} fontSize="20px" iconSize={25} />
          </Tooltip>
        ) : tradeError ? (
          <Tooltip
            content={
              <FlexContainer flexDirection="column" alignItems="center" gap="6px">
                <MediumText color={SCIENT_COLORS.darkGray5}>
                  An error occurred to get performance.
                </MediumText>
                {tradeError.startsWith("empty_sector") && (
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    Sector has no stock associated.
                  </MediumText>
                )}
                <MediumText color={SCIENT_COLORS.darkGray5}>
                  Please contact Tech Support.
                </MediumText>
              </FlexContainer>
            }
            placement="bottom"
            compact
          >
            <Icon icon="warning-sign" css={iconCss} />
          </Tooltip>
        ) : null}
      </PositionInfosContainer>
    </IdeaPositionsContainer>
  )
}

export default IdeaPositions
