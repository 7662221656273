// @ts-nocheck
import React from "react"
import * as R from "ramda"
import { Button, ButtonGroup, Collapse, H3 } from "@blueprintjs/core"

export const SegLoopBuilder = ({
  values,
  setValues,
  handleSubmit,
  activeItem,
  changeInActiveItem,
}) => {
  const loopParams = values.loopParams

  const setSlugs = value => {
    return setValues({
      ...values,
      loopParams: { slugs: value, kind: "segs" },
    })
  }

  return (
    <>
      <br />
      <H3 style={{ margin: "auto" }}>Loop in:</H3>
      <br />
      <ButtonGroup fill large>
        <Button
          active={loopParams.slugs && R.includes("all", loopParams.slugs)}
          text="All segments"
          onClick={() => setSlugs("'all': 1")}
        />
        <Button
          active={loopParams.slugs && R.includes("active", loopParams.slugs)}
          text="Active segments"
          onClick={() => setSlugs("'active': 1")}
        />
      </ButtonGroup>

      <br />
      <br />
      <br />

      <Collapse isOpen={loopParams.slugs && changeInActiveItem}>
        <Button
          type="submit"
          intent="primary"
          icon="tick"
          text={changeInActiveItem && !R.isEmpty(activeItem.loop_on) ? "Validate change" : "Next"}
          onClick={handleSubmit}
          fill
          large
        />
      </Collapse>
    </>
  )
}
