import { Icon, Tooltip } from "@blueprintjs/core"

type HelpTooltipProps = {
  content: JSX.Element | string
  marginSize: number
  iconSize: number
}

export const HelpTooltip = ({ content, marginSize = 4, iconSize = 16 }: HelpTooltipProps) => {
  return (
    <Tooltip content={content} placement="auto" compact>
      <Icon style={{ margin: `${marginSize}px` }} intent="primary" icon="help" size={iconSize} />
    </Tooltip>
  )
}
