/** @jsxImportSource @emotion/react */
import { TextArea } from "@blueprintjs/core"
import { useRef } from "react"

import { useAutosizeTextArea } from "../hooks"
import { textAreaCss } from "./TextAreaAutosize.styled"

/**
 * This is a text area where height is adjusted automaticaly to content.
 * Style can be customize with:
 *      _ backgroundColor (need !important to be override),
 *      _ borderRadius,
 *      _ boxShadow (need !important to be override),
 *      _ fontFamily,
 *      _ fontSize,
 *      _ color (need !important to be override),
 *      _ minHeight.
 */

interface ITextAreaAutosizeProps {
  backgroundColor?: string
  borderRadius?: string
  boxShadow?: string
  fontFamily?: string
  fontSize?: string
  color?: string
  minHeight?: string
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  value: string
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

export const TextAreaAutosize: React.FC<ITextAreaAutosizeProps> = ({
  backgroundColor,
  borderRadius,
  boxShadow,
  fontFamily = "inherit",
  fontSize = "15px",
  color,
  minHeight = "40px",
  onKeyDown,
  placeholder,
  value,
  handleChange,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  // Updates the height of the <TextArea> when the value changes.
  useAutosizeTextArea(textAreaRef.current, value)

  return (
    <TextArea
      onChange={handleChange}
      value={value}
      onKeyDown={onKeyDown as (event: React.KeyboardEvent<HTMLTextAreaElement>) => void}
      placeholder={placeholder}
      inputRef={textAreaRef}
      rows={1}
      css={textAreaCss({
        backgroundColor,
        borderRadius,
        boxShadow,
        fontFamily,
        fontSize,
        color,
        minHeight,
      })}
    />
  )
}
