// @ts-nocheck
import React, { useState } from "react"
import { Button, Dialog } from "@blueprintjs/core"

export const ButtonWithDialog = ({ buttonProps, dialogProps, children, show = false }) => {
  const [dialog, setDialog] = useState(show)
  return (
    <>
      <Button onClick={() => setDialog(true)} {...buttonProps} />
      <Dialog
        className="bp5-dark"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        icon={buttonProps.icon}
        intent={buttonProps.intent}
        isOpen={dialog}
        {...dialogProps}
        onClose={() => setDialog(false)}
        setDialog={setDialog}
      >
        {children({ setDialog })}
      </Dialog>
    </>
  )
}
