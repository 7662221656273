// @ts-nocheck
import { Icon } from "@blueprintjs/core"
import React from "react"
import { useOutlet } from "react-router-dom"
import { Col, Grid, Row } from "../../components"
import SearchStock from "./Detail/Header/SearchStock"

const StockStartPage = () => {
  const outlet = useOutlet()

  return (
    outlet || (
      <div
        style={{
          margin: "0 auto",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          className="center"
          style={{
            position: "absolute",
            margin: "auto",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: "320px",
            height: "250px",
          }}
        >
          <Grid fluid>
            <Row center="xs">
              <Col xs>
                <Icon icon="search" size={50} />
              </Col>
            </Row>
            <SearchStock />
          </Grid>
        </div>
      </div>
    )
  )
}

export default StockStartPage
