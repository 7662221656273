import { DateInput3 } from "@blueprintjs/datetime2"
import { IdeaCreateLabel, IdeaSettingsInnerContainer } from "./IdeaCreate.styled"
import { IdeaCreateMeetingDateContainer } from "./IdeaCreateMeetingDate.styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { useField, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { IIdeaCreateForm } from "./IdeaCreate"

const IdeaCreateMeetingDate = () => {
  const [meetingDateField] = useField("meeting_date")
  const [dateValue, setDateValue] = useState<string | null>(meetingDateField.value)
  const { setFieldValue } = useFormikContext<IIdeaCreateForm>()

  useEffect(() => {
    setFieldValue(meetingDateField.name, dateValue)
    return () => {
      setFieldValue(meetingDateField.name, undefined)
    }
  }, [dateValue, meetingDateField.name, meetingDateField.value, setFieldValue])

  return (
    <IdeaCreateMeetingDateContainer flexDirection="column" gap="5px">
      <FlexContainer alignItems="center" gap="10px">
        <IdeaCreateLabel>Meeting Date</IdeaCreateLabel>
        <Icon icon={IconNames.CALENDAR} />
      </FlexContainer>
      <IdeaSettingsInnerContainer>
        <DateInput3
          dateFnsFormat="dd/MM/yyyy"
          placeholder="Optional Meeting Date"
          showActionsBar
          timePrecision={undefined}
          value={dateValue}
          onChange={date => setDateValue(date)}
        />
      </IdeaSettingsInnerContainer>
    </IdeaCreateMeetingDateContainer>
  )
}

export default IdeaCreateMeetingDate
