import { AxiosInstance, AxiosResponse } from "axios"

export interface IReportingApi {
  reports: {
    detail: {
      update: ({ values }: any) => Promise<AxiosResponse<any, any>>
      delete: ({ id }: any) => Promise<AxiosResponse<any, any>>
    }
    list: {
      create: ({ values }: any) => Promise<AxiosResponse<any, any>>
    }
  }
}

/**
 * Api routes for /api/v1/reporting/
 */
const reporting = (axiosClient: AxiosInstance): IReportingApi => ({
  reports: {
    detail: {
      /**
       * PUT /api/v1/reporting/reports/{id}/
       */
      update: ({ values }: any) => axiosClient.put(`/reporting/reports/${values.id}/`, values),
      /**
       * DELETE /api/v1/reporting/reports/{id}/
       */
      delete: ({ id }: any) => axiosClient.delete(`/reporting/reports/${id}/`),
    },
    list: {
      /**
       * POST /api/v1/reporting/reports/
       */
      create: ({ values }: any) => axiosClient.post(`/reporting/reports/`, values),
    },
  },
})

export default reporting
