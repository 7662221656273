// @ts-nocheck
// Compute fields in add/editReport

export const fixedAssets = {
  target: ["bs_fixed_assets"],
  add: [
    ["bs_tangible_assets"],
    ["bs_intangible_assets"],
    ["bs_operating_lease_assets"],
    ["bs_investment_in_associate"],
    ["bs_investment_in_other_affiliated_companies"],
    ["bs_non_current_financial_assets"],
    ["bs_deferred_tax_assets"],
    ["bs_other_non_current_assets"],
  ],
  method: "sum",
}
export const currentAssets = {
  target: ["bs_current_assets"],
  add: [
    ["bs_cash"],
    ["bs_receivables"],
    ["bs_inventories"],
    ["bs_other_non_wc_current_assets"],
    ["bs_other_wc_current_assets"],
  ],
  method: "sum",
}
export const totalAssets = {
  target: ["bs_total_assets"],
  add: [
    ["bs_tangible_assets"],
    ["bs_intangible_assets"],
    ["bs_operating_lease_assets"],
    ["bs_investment_in_associate"],
    ["bs_investment_in_other_affiliated_companies"],
    ["bs_non_current_financial_assets"],
    ["bs_deferred_tax_assets"],
    ["bs_other_non_current_assets"],

    ["bs_cash"],
    ["bs_receivables"],
    ["bs_inventories"],
    ["bs_other_non_wc_current_assets"],
    ["bs_other_wc_current_assets"],
  ],
  method: "sum",
}

export const shareholdersEquity = {
  target: ["bs_shareholders_equity"],
  add: [["bs_capital_shares"], ["bs_other_reserves"], ["bs_retained_earnings"], ["bs_minorities"]],
  method: "sum",
}

export const fixedLiabilities = {
  target: ["bs_fixed_liabilities"],
  add: [
    ["bs_long_term_debt"],
    ["bs_non_current_leases"],
    ["bs_other_long_term_liabilities"],
    ["bs_pension_provisions"],
    ["bs_deferred_tax_liab"],
    ["bs_tax_liabilities"],
    ["bs_provisions"],
  ],
  method: "sum",
}

export const currentLiabilities = {
  target: ["bs_current_liabilities"],
  add: [
    ["bs_short_term_borrowings"],
    ["bs_current_leases"],
    ["bs_accounts_payable_and_other_wc_current_liabilities"],
    ["bs_short_term_provisions"],
    ["bs_current_tax_liabilities"],
    ["bs_other_non_wc_current_liabilities"],
  ],
  method: "sum",
}
export const totalEquityLiabilities = {
  target: ["bs_total_equity_liabilities"],
  add: [
    ["bs_capital_shares"],
    ["bs_other_reserves"],
    ["bs_retained_earnings"],
    ["bs_minorities"],

    ["bs_long_term_debt"],
    ["bs_non_current_leases"],
    ["bs_other_long_term_liabilities"],
    ["bs_pension_provisions"],
    ["bs_deferred_tax_liab"],
    ["bs_tax_liabilities"],
    ["bs_provisions"],

    ["bs_short_term_borrowings"],
    ["bs_current_leases"],
    ["bs_accounts_payable_and_other_wc_current_liabilities"],
    ["bs_short_term_provisions"],
    ["bs_current_tax_liabilities"],
    ["bs_other_non_wc_current_liabilities"],
  ],
  method: "sum",
}

export const ntfa = {
  target: ["bs_ntfa"],
  add: [["bs_gross_bv_tangibles"]],
  sub: [["bs_accumulated_depreciation"]],
  method: "sum",
}

export const evAdj = (stockData, values) => ({
  target: ["bs_ev_adj_readonly"],
  method: "computeEvAjd",
  multi: [["is_minority_interest"]],
  add: [
    [
      ...(values.bs_pensions_liab_fairvalue
        ? ["bs_pensions_liab_fairvalue"]
        : ["bs_pension_provisions"]),
    ],
    ["bs_deferred_tax_liab"],
    ["bs_tax_liabilities"],

    ["bs_other_non_wc_current_liabilities"],
    ["bs_short_term_provisions"],
    ["bs_provisions"],
    ["bs_other_long_term_liabilities"],
  ],
  sub: [
    [
      ...(stockData.associates_above_ebit
        ? [""]
        : values.bs_associates_fairvalue
        ? ["bs_associates_fairvalue"]
        : ["bs_investment_in_associate"]),
    ],
    ["bs_deferred_tax_assets"],
    ["bs_other_non_wc_current_assets"],
    ["bs_other_non_current_assets"],
    ["bs_non_current_financial_assets"],
    ["bs_investment_in_other_affiliated_companies"],
  ],
  override: ["bs_ev_adj_override"],
})

export const netDebt = {
  target: ["bs_net_debt_readonly"],
  add: [
    ["bs_current_leases"],
    ["bs_non_current_leases"],
    ["bs_long_term_debt"],
    ["bs_short_term_borrowings"],
  ],
  sub: [["bs_cash"]],
  method: "sum",
  override: ["bs_net_debt_override"],
}
