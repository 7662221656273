import { Card, Divider } from "@blueprintjs/core"
import styled from "@emotion/styled"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { SmallText } from "../../../../styled/text.styled"

type ListingCardContainerProps = {
  index: number
}

export const ListingCardContainer = styled(Card)<ListingCardContainerProps>(props => ({
  backgroundColor:
    props.index % 2 !== 1
      ? `${SCIENT_COLORS.darkGray2} !important`
      : `${SCIENT_COLORS.darkGray1} !important`,
}))

export const FlexListingCardContainer = styled(FlexContainer)({
  width: "100%",
})

export const ListingCardDivider = styled(Divider)({
  height: "15px",
})

export const TickerText = styled(SmallText)({
  width: "90px",
})

export const CustomText = styled(SmallText)({
  width: "140px",
})
