// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import { fetchAnalyse, fetchAnalyseOk, fetchAnalyseError } from "../../index"
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const fetchAnalyseEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(fetchAnalyse.type),
    switchMap(({ payload: { id } }) => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/analysis/${id}/`

      return ajax({
        url,
        method: "GET",
      }).pipe(
        map(({ response }) => fetchAnalyseOk({ analyse: response })),
        catchError(error => of(fetchAnalyseError({ error: error.xhr.response }))),
      )
    }),
  )

export default fetchAnalyseEpic
