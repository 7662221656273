import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const HeaderContainer = styled(FlexContainer)({
  margin: "0 10px",
})

export const SectorsContainer = styled.div({
  padding: "10px 15% 0 !important",
})

export const SectorContainer = styled(FlexContainer)({
  marginBottom: "10px",
})
