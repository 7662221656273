import { useQuery } from "@tanstack/react-query"
import { useApi } from "./useApi"

export const useQueyUsers = () => {
  const { scientApi, apiAuthenticated, api2FAVerified } = useApi()

  return useQuery({
    queryKey: ["users"],
    queryFn: scientApi.users.get,
    staleTime: Infinity, // Static list, no need to refresh.
    enabled: apiAuthenticated && api2FAVerified,
  })
}
