// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import rForecastDuck from "../."
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchRForecast, fetchRForecastOk, fetchRForecastError },
} = rForecastDuck

const fetchRForecastEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchRForecast.type),
    mergeMap(action => {
      const { stkSlug, fcstSlug } = action.payload

      const url = `${REACT_APP_API_DOMAIN}/api/v1/estimates/rforecasts/${fcstSlug}/`
      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchRForecastOk({ stkSlug, fcstSlug, payload: response })),
        catchError(error => of(fetchRForecastError(error.xhr.response))),
      )
    }),
  )

export default fetchRForecastEpic
