import {
  add,
  format,
  getDayOfYear,
  getISOWeek,
  getISOWeekYear,
  getMonth,
  getQuarter,
  getYear,
  sub,
} from "date-fns"

interface IdateToPeriod {
  start?: Date
  end?: Date
  periodicity: string
}

/**
 * This function take a date object and a periodicity
 * and returns an object with:
 *    _ the start day of the period  (01-06-2020)
 *    _ the end day of the period (which is the same as the start day of the next period) (01-07-2020)
 *    _ the name of the period (2020M6)
 */

export const dateToPeriod = ({ start, end, periodicity }: IdateToPeriod) => {
  const duration = () => {
    switch (periodicity) {
      case "Y":
      case undefined:
        return { years: 1 }
      case "H":
        return { months: 6 }
      case "Q":
        return { months: 3 }
      case "BM":
        return { months: 2 }
      case "M":
        return { months: 1 }
      case "W":
        return { weeks: 1 }
      case "D":
        return { days: 1 }
      default:
        throw new Error("In duration")
    }
  }

  const period = (startDate: Date, endDate: Date) => {
    /**
     * We format startDate/endDate object to the ISOString format.
     * Using format with ISO patern, instead of .toISOString(), allows to save the day.
     *    ex:
     *       With startDate = Wed Dec 01 2021 00:00:00 GMT+0100 (heure normale d’Europe centrale)
     *          format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") = "2021-12-01T00:00:00.000Z"
     *          startDate.toISOString() = 2021-11-30T23:00:00.000Z
     */
    switch (periodicity) {
      case "Y":
      case undefined:
        return {
          start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          name: `${getYear(startDate)}`,
        }
      case "H":
        return {
          start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          name: `${getYear(startDate)}H${(getMonth(startDate) + 1) / 6 > 1 ? 2 : 1}`,
        }
      case "Q":
        return {
          start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          name: `${getYear(startDate)}Q${getQuarter(startDate)}`,
        }
      /**
       * getMonth() returns index of month from 0 to 11
       * So we add +1 to the result to have the month.
       */
      case "BM":
        return {
          start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          name: `${getYear(startDate)}BM${Math.round((1 + getMonth(startDate)) / 2)}`,
        }
      case "M":
        return {
          start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          name: `${getYear(startDate)}M${getMonth(startDate) + 1}`,
        }
      case "W":
        return {
          start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          name: `${getISOWeekYear(startDate)}W${getISOWeek(startDate)}`,
        }
      case "D":
        return {
          start: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          end: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
          name: `${getYear(startDate)}D${getDayOfYear(startDate)}`,
        }
      default:
        throw new Error("in dateToPeriod")
    }
  }

  if (start) {
    const endDate = add(start, duration())
    return period(start, endDate)
  }
  if (end) {
    const startDate = sub(end, duration())
    return period(startDate, end)
  }

  throw new Error("No start nor end.")
}
