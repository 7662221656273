import { FullScreenIcon } from "../styled/fullscreen.styled"

interface ITinyMCEFullscreenIconProps {
  onClick: () => void
  fullscreen: boolean
  fill?: string
}

/**
 * Fullscreen Icon taken from TinyMCE
 */
const TinyMCEFullscreenIcon = ({ onClick, fullscreen, fill }: ITinyMCEFullscreenIconProps) => {
  return (
    <FullScreenIcon width="24" height="24" focusable="false" onClick={onClick} active={fullscreen}>
      <path
        d="m15.3 10-1.2-1.3 2.9-3h-2.3a.9.9 0 1 1 0-1.7H19c.5 0 .9.4.9.9v4.4a.9.9 0 1 1-1.8 0V7l-2.9 3Zm0 4 3 3v-2.3a.9.9 0 1 1 1.7 0V19c0 .5-.4.9-.9.9h-4.4a.9.9 0 1 1 0-1.8H17l-3-2.9 1.3-1.2ZM10 15.4l-2.9 3h2.3a.9.9 0 1 1 0 1.7H5a.9.9 0 0 1-.9-.9v-4.4a.9.9 0 1 1 1.8 0V17l2.9-3 1.2 1.3ZM8.7 10 5.7 7v2.3a.9.9 0 0 1-1.7 0V5c0-.5.4-.9.9-.9h4.4a.9.9 0 0 1 0 1.8H7l3 2.9-1.3 1.2Z"
        fillRule="nonzero"
        fill={fill}
      />
    </FullScreenIcon>
  )
}

export default TinyMCEFullscreenIcon
