// @ts-nocheck
import { Button, Popover, Tag } from "@blueprintjs/core"
import { format, utcToZonedTime } from "date-fns-tz"

export const PrevValue = ({ field, text, value, isDate }) =>
  field.endsWith("_comment") ? (
    <Popover
      popoverProps={{
        content: value,
        targetOnly: false,
        placement: "bottom-end",
        className: "sct-fullwidth",
      }}
    >
      <Button style={{ width: "100%" }} text={text} fill disabled={!value} />
    </Popover>
  ) : (
    <Tag large fill minimal style={{ textAlign: "right" }}>
      {value
        ? isDate
          ? field === "release_date"
            ? /**
               * Below is the release date.
               * Front receive UTC and display local time to users.
               */
              format(new Date(value), "dd-MM-yyyy")
            : /**
               * Below is dates for start and end of periods.
               * We receive UTC date in ISO string with time fixed to 00:00.
               * When formating 'new Date(value)' is converted to local time.
               * In order keep same start/end day all around the world, we use utcToZonedTime.
               */
              format(utcToZonedTime(new Date(value), "UTC"), "dd-MM-yyyy")
          : value
        : ""}
    </Tag>
  )
