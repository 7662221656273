/**
 * @param list of  object
 * @param objectKey
 * @returns list without duplicate object wich have same value for the given objectKey
 */

export const removeDuplicate = <T>(list: T[], objectKey: keyof T): T[] => {
  return list.reduce((acc: T[], current: T) => {
    if (!acc.some(item => item[objectKey] === current[objectKey])) {
      acc.push(current)
    }
    return acc
  }, [])
}
