import { useLocation, useNavigate } from "react-router-dom"
import { Button, MaybeElement } from "@blueprintjs/core"
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated/16px/blueprint-icons-16"

interface INavButton {
  icon: BlueprintIcons_16Id | MaybeElement
  text: string
  to: {
    pathname: string
  }
}

const NavButton = ({ icon, text, to }: INavButton) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Button
      onClick={() => navigate(to.pathname)}
      icon={icon}
      text={text}
      active={pathname.includes(to.pathname)}
      minimal
    />
  )
}

export default NavButton
