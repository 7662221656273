// @ts-nocheck
import { H6, Icon, Tooltip } from "@blueprintjs/core"

import { Col, Row } from "../../../../../../../../components"

export const ParamTitle = ({ param, title }) => {
  const { min, max, type } = param
  const titleTodisplay = title ? title : param.title

  // if min or max = 0, JS returns false so need bang(!) to have true
  const formatedMin = min === 0 ? !min : min
  const formatedMax = max === 0 ? !max : max

  const article = type === "INTEGER" ? "an" : "a"

  const numericHelpText =
    formatedMin && formatedMax
      ? `(must be ${article} ${type} greater than or equal to ${min} and less than or equal to ${max})`
      : formatedMin
      ? `(must be ${article} ${type} greater than or equal to ${min})`
      : formatedMax
      ? `(must be ${article} ${type} less than or equal to ${max})`
      : ["INTEGER", "FLOAT", "NUMBER"].includes(type)
      ? type === "NUMBER"
        ? "(type: FLOAT)"
        : `(type: ${type})`
      : ""

  return (
    <Row style={{ margin: 0 }}>
      <Col>
        <H6>{titleTodisplay}</H6>
      </Col>
      <Col>
        <Tooltip content={param.description} placement="bottom" openOnTargetFocus={false} compact>
          <Icon icon="info-sign" style={{ marginLeft: "8px" }} />
        </Tooltip>
      </Col>
      <Col>
        <p style={{ marginLeft: "8px", fontStyle: "italic", fontSize: "12px" }}>
          {numericHelpText}
        </p>
      </Col>
    </Row>
  )
}
