import { Icon, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query"
import { Dispatch, SetStateAction } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Button } from "../../../../components"
import { useIdeasCache } from "../../../../hooks"
import { NoIdeasPlaceholder, NoIdeasTitle } from "../../Ideas.styled"
import { Idea, IdeaRevision } from "../../types/business"
import IdeaCard from "./IdeaCard"
import { InboxContainer, SpinnerContainer } from "./Inbox.styled"

interface IInboxProps {
  ideas?: Idea[]
  selectedIdea?: Idea
  setSelectedIdeaId: Dispatch<SetStateAction<string | number | undefined>>
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<Idea[], unknown>>
  hasNextPage: boolean
  isFetchingIdeas: boolean
  setSelectedRevision: Dispatch<SetStateAction<IdeaRevision | undefined>>
}

const Inbox = ({
  ideas,
  selectedIdea,
  setSelectedIdeaId,
  fetchNextPage,
  hasNextPage,
  isFetchingIdeas,
  setSelectedRevision,
}: IInboxProps) => {
  const { draftFilter } = useIdeasCache()
  return (
    <InboxContainer flexDirection="column" flexGrow={1} id="ideas-inbox">
      {ideas?.length ? (
        /**
         * No use of css={} since the className is used by InfiniteScroll
         */
        <InfiniteScroll
          dataLength={ideas.length}
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
          loader={
            <SpinnerContainer justifyContent="center" flexGrow={1}>
              <Spinner intent={Intent.SUCCESS} size={SpinnerSize.SMALL} />
            </SpinnerContainer>
          }
          next={fetchNextPage}
          hasMore={hasNextPage}
          scrollableTarget="ideas-inbox"
        >
          {ideas?.length &&
            ideas.map(idea => {
              return (
                <IdeaCard
                  idea={idea}
                  key={idea.id}
                  selected={idea === selectedIdea}
                  setSelectedIdeaId={setSelectedIdeaId}
                  setSelectedRevision={setSelectedRevision}
                />
              )
            })}
          {hasNextPage && !isFetchingIdeas && (
            <Button large icon="add" intent="primary" onClick={fetchNextPage} />
          )}
        </InfiniteScroll>
      ) : !isFetchingIdeas ? (
        <NoIdeasPlaceholder
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="25px"
        >
          <Icon icon="zoom-out" size={50} />
          <NoIdeasTitle>{draftFilter ? "No Draft Idea" : "No Ideas Matching filters"}</NoIdeasTitle>
        </NoIdeasPlaceholder>
      ) : (
        <SpinnerContainer justifyContent="center" alignItems="center" flexGrow={1}>
          <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
        </SpinnerContainer>
      )}
    </InboxContainer>
  )
}

export default Inbox
