export enum SourceType {
  PROPRIETARY_THINKING = "proprietary_thinking",
  CORPORATE_ACCESS = "corporate_access",
  SELL_SIDE = "sell_side",
}

export enum IdeaType {
  TRADE = "trade",
  NOTE = "note",
  STRUCTURAL = "structural",
}

export enum PositionType {
  STOCK = "stock",
  SECTOR = "sector",
}

export enum IdeaPosition {
  LONG = "long",
  SHORT = "short",
}

export enum IdeaRecommendation {
  BUY = "Buy",
  SELL = "Sell",
  NEUTRAL = "Neutral",
}

export type IdeaUser = {
  id: number
  username: string
}

export type Conversation = {
  id: number
  is_read: boolean
}

export type IdeaStock = {
  id: number
  name: string
  bloomberg_code: string
  trading_currency: {
    id: number
    name: string
    code: string
    slug: string
  }
  position_type: PositionType
}

export type IdeaSector = {
  id: number
  name: string
  level: number
  related_dataset_unit: string
  position_type: PositionType
}

/**
 * Positions typeguard
 */
export const isStockPosition = (
  position: Partial<IdeaStock> | Partial<IdeaSector>,
): position is Partial<IdeaStock> => {
  return position.position_type === PositionType.STOCK
}

export const isSectorPosition = (
  position: Partial<IdeaStock> | Partial<IdeaSector>,
): position is Partial<IdeaStock> => {
  return position.position_type === PositionType.SECTOR
}

export type IdeaPositionTrade = {
  perf?: number
  start_price?: number
  end_price?: number
  start_price_at?: string
  end_price_at?: string
  instance: Partial<IdeaStock> | Partial<IdeaSector>
}

export type IdeaTrade = {
  id?: number
  short: IdeaPositionTrade
  long: IdeaPositionTrade
  idea_id?: number
  main_perf?: number
  opened_at?: string
  closed_at?: string
  created_at?: string
  computation_error?: string
}

export type IdeaRevision = {
  id?: number
  content: string
  conviction?: number
  recommendation: IdeaRecommendation
  idea_id: number
  participants?: IdeaUser[]
  conversation: Conversation
  target_price?: number
  created_at: string
  updated_at: string
}

export type Idea = {
  /**
   * In case id is created locally to not wait for
   * backend response, we use uuid4 hence it is a string
   */
  id: number
  source_type: SourceType
  source: string | null
  idea_type: IdeaType
  user: Partial<IdeaUser>
  title: string
  draft: boolean
  seen: boolean
  revisions_conversations_read: boolean
  related_stocks: Partial<IdeaStock>[]
  related_sector?: Partial<IdeaSector>
  trade: IdeaTrade | null
  revisions: IdeaRevision[]
  meeting_date: string | null
  last_message_created_at: string
  latest_revision_updated_at: string
  default_sorting_date: string
  created_at: string
  updated_at: string
  published_at: string
  status?: "success" | "failure"
  participants?: IdeaUser[]
}

export type ConversationMessage = {
  id: number | string
  tmp_id?: string
  user: IdeaUser
  content: string
  conversation_id: number
  created_at: string
  status?: "success" | "failure" | "sending"
}
