import { Divider, Icon } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { CardContainer } from "../../../../../styled/layout.styled"
import { MediumText } from "../../../../../styled/text.styled"
import { Idea, IdeaRevision } from "../../types/business"
import { IdeaInfosContainer } from "./IdeaNoteInfos.styled"

interface IIdeaStructuralInfosProps {
  idea: Idea
  selectedRevision: IdeaRevision
}

const IdeaStructuralInfos = ({ idea, selectedRevision }: IIdeaStructuralInfosProps) => {
  return (
    <IdeaInfosContainer gap="20px">
      <CardContainer alignItems="center" gap="10px">
        <FlexContainer gap="5px">
          <Icon icon={IconNames.LIGHTBULB} />
          <MediumText>Recommendation:</MediumText>
        </FlexContainer>
        <MediumText fontWeight="bold">{selectedRevision.recommendation}</MediumText>
      </CardContainer>
      {selectedRevision.target_price && (
        <>
          <Divider />
          <CardContainer alignItems="center" gap="10px">
            <FlexContainer gap="5px">
              <Icon icon={IconNames.DOLLAR} />
              <MediumText>Target Price:</MediumText>
            </FlexContainer>
            <MediumText fontWeight="bold">{selectedRevision.target_price}</MediumText>
          </CardContainer>
        </>
      )}
      {(!!idea.related_sector || !!idea.related_stocks.length) && (
        <>
          <Divider />
          <CardContainer flexWrap="wrap" alignItems="center" flexGrow={1} gap="10px">
            <Icon icon={IconNames.BRIEFCASE} />
            <MediumText>Related {idea.related_sector ? "Sector" : "Stock"}:</MediumText>
            <MediumText fontWeight="bold">
              {idea.related_sector
                ? idea.related_sector.name
                : idea.related_stocks[0].bloomberg_code}
            </MediumText>
          </CardContainer>
        </>
      )}
    </IdeaInfosContainer>
  )
}

export default IdeaStructuralInfos
