// @ts-nocheck
import { compose, filter, includes } from "ramda"

import { formatSingleAndStr } from "./computation/formatSingleAndStr"
import computeDiffPct from "./computation/computeDiffPct"
import concatForecasts from "./computation/concatForecasts"

export const diffPctForecast = ({ forecasts, diffPair, noEntry, stock }) => {
  const defender = filter(forecast => includes(diffPair[0], forecast.slug), forecasts)
  const challenger = filter(forecast => includes(diffPair[1], forecast.slug), forecasts)
  const diffForecasts = [...defender, ...challenger]

  const computeDiffPctForecast = compose(
    computeDiffPct,
    formatSingleAndStr("diffPct"),
    concatForecasts,
  )(diffForecasts)

  const cleanDiffPctForecast =
    diffForecasts.length === 2
      ? {
          str_name: `${stock.bloomberg_code}>diff-pct`,
          slug: `${stock.slug}-diff-pct`,
          company: `Diff (pct): ${diffForecasts[1].company} vs ${diffForecasts[0].company}`,
          label: "Base",
          owner: "default",
          rfentries: computeDiffPctForecast.rfentries,
        }
      : {
          str_name: `${stock.bloomberg_code}>diff-pct`,
          slug: `${stock.slug}-diff-pct`,
          company: "Diff (pct): select diff forecasts",
          label: "Base",
          owner: "default",
          rfentries: noEntry,
        }
  return cleanDiffPctForecast
}
