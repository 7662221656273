// @ts-nocheck
import { Button, Icon } from "@blueprintjs/core"
import { pathOr } from "ramda"
import { useState } from "react"
import { DndProvider } from "react-dnd"
import Backend from "react-dnd-html5-backend"
import { useDispatch, useSelector } from "react-redux"

import profileDuck from "../../../../../../state/modules/profile"
import { SCIENT_COLORS } from "../../../../../../styled/scientColors"
import { Col, Grid, Row } from "../../../../../components"
import NewTab from "./NewTab"
import Settings from "./Settings"
import TabContainer from "./TabContainer"

const TabNav = ({ stockSlug, activeTab }) => {
  const [drawer, setDrawer] = useState(false)
  const [dialog, setDialog] = useState(false)
  const toggleDrawer = () => setDrawer(!drawer)

  const dispatch = useDispatch()
  const consensusFields = useSelector(
    pathOr("sales", ["profile", "userstockconfigs", stockSlug, "consensusFields"]),
  )

  const handleChange = tabId => {
    dispatch(profileDuck.actions.stockSetActiveTab({ slug: stockSlug, activeTab: tabId }))
    tabId === "consensus"
      ? dispatch(
          profileDuck.actions.stockSetConsensusFields({
            slug: stockSlug,
            consensusFields: consensusFields,
          }),
        )
      : dispatch(
          profileDuck.actions.stockSetConsensusFields({
            slug: stockSlug,
            consensusFields: tabId,
          }),
        )
  }

  return (
    <>
      <Settings stockSlug={stockSlug} isOpen={drawer} onClose={toggleDrawer} />
      <Grid fluid style={{ marginLeft: "20px" }}>
        <Row middle="xs" style={{ margin: "0" }}>
          <DndProvider backend={Backend}>
            <TabContainer stockSlug={stockSlug} activeTab={activeTab} handleChange={handleChange} />
          </DndProvider>
          <Col xs={0} style={{ marginLeft: "4px" }}>
            <Button
              fill
              outlined="true"
              onClick={() => setDialog(true)}
              style={{ height: "32px", width: "32px" }}
            >
              <Icon icon="plus" color={SCIENT_COLORS.lightGray5} />
            </Button>
          </Col>
          <Col xs={0} style={{ marginLeft: "4px" }}>
            <Button
              fill
              outlined="true"
              onClick={toggleDrawer}
              style={{ height: "32px", width: "32px" }}
            >
              <Icon icon="settings" color={SCIENT_COLORS.lightGray5} />
            </Button>
          </Col>
        </Row>
      </Grid>
      <NewTab stockSlug={stockSlug} dialog={dialog} setDialog={setDialog} />
    </>
  )
}

export default TabNav
