// @ts-nocheck
import React from "react"
import { Alert } from "@blueprintjs/core"
import { Callout } from "../../../../components"

const DeleteAlert = ({ idAndSlug, cancel, confirm, loading, error }) => (
  <Alert
    className="bp5-dark"
    canEscapeKeyCancel={true}
    canOutsideClickCancel={true}
    icon="trash"
    intent="danger"
    isOpen={idAndSlug.id}
    onCancel={cancel}
    cancelButtonText="Cancel"
    confirmButtonText={loading ? "Please wait..." : "Delete"}
    onConfirm={confirm}
  >
    <h4>Confirm deletion?</h4>
    <p>Do you really want to delete report {idAndSlug.slug}?</p>
    {error && <Callout title="There was an error" body={`Could not delete ${idAndSlug.slug}`} />}
  </Alert>
)

export default DeleteAlert
