// @ts-nocheck
import React, { useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { Button, ButtonGroup, Card, Collapse, Icon } from "@blueprintjs/core"

import ItemTypes from "./itemTypes"
import { DeleteAlert } from "../../../../components"
import { ItemFields } from "./ItemFields"

const style = {
  // width: "20em",
  border: "1px solid",
  borderColor: "rgba(255, 255, 255, 0.4)",
  borderRadius: "3px 3px",
  padding: "0.7rem",
  // cursor: "move",
}
const handleStyle = {
  cursor: "move",
}

const RowItem = ({
  card,
  moveCard,
  findCard,
  index,
  rowId,
  arrayHelpers,
  handleCollapse,
  setCursorPos,
  values,
  setValues,
  DFState,
  setDFState,
}) => {
  const [deleteAlert, setDeleteAlert] = useState(false)
  //For dnd Card
  const originalIndex = findCard(rowId).index
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, rowId, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    end: (dropResult, monitor) => {
      const { rowId: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveCard(droppedId, originalIndex)
      }
    },
  })
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => false,
    hover({ rowId: draggedId }) {
      if (draggedId !== rowId) {
        const { index: overIndex } = findCard(rowId)
        moveCard(draggedId, overIndex)
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  //End dnd Card

  const handleDelete = () => arrayHelpers.remove(index)

  return (
    <div ref={preview} style={{ ...style, opacity }} fill="true">
      <Card key={card.rowId} id={card.rowId} elevation={card.active ? 4 : 0} style={{ padding: 0 }}>
        <ButtonGroup fill>
          <div ref={node => drag(drop(node))} style={handleStyle}>
            <Icon
              icon="move"
              size={20}
              style={{ margin: "auto", padding: "5px", color: "#AAB6C1" }}
            />
          </div>

          <Button
            fill
            minimal
            icon={card.active ? "caret-down" : "caret-right"}
            onClick={() => handleCollapse(card)}
          >
            {card.frontOpts.name || "No title"} <small>({card.rowId})</small>
          </Button>
          <Button type="button" icon="trash" onClick={() => setDeleteAlert(true)} />
        </ButtonGroup>

        <Collapse isOpen={card.active}>
          <ItemFields
            index={index}
            card={card}
            setCursorPos={setCursorPos}
            values={values}
            setValues={setValues}
          />
        </Collapse>

        <DeleteAlert
          deleteAlert={deleteAlert}
          setDeleteAlert={setDeleteAlert}
          handleDelete={handleDelete}
          deleteMessage={`Delete ${card.frontOpts.name} ?`}
        />
      </Card>
    </div>
  )
}
export default RowItem
