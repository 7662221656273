// @ts-nocheck
import React from "react"
import { update } from "ramda"

import { Button, ButtonGroup, Classes } from "@blueprintjs/core"

export const InsertMeanMedian = ({ panelsState, setPanelsState }) => {
  const appendMeanMedian = value =>
    panelsState[2] === value
      ? setPanelsState(update(2, "", panelsState))
      : setPanelsState(update(2, value, panelsState))

  return (
    <div className={Classes.DIALOG_BODY} style={{ margin: "71px 120px" }}>
      <p>Insert:</p>
      <ButtonGroup fill>
        <Button
          text="Mean"
          onClick={() => appendMeanMedian("|MEAN")}
          active={panelsState[2] === "|MEAN"}
        />
        <Button
          text="Median"
          onClick={() => appendMeanMedian("|MEDIAN")}
          active={panelsState[2] === "|MEDIAN"}
        />
      </ButtonGroup>
    </div>
  )
}
