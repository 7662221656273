// @ts-nocheck
import { H4, Radio, RadioGroup } from "@blueprintjs/core"
import { path } from "ramda"
import { useDispatch, useSelector } from "react-redux"

import profileDuck from "../../../../../../../state/modules/profile"
import { SCIENT_COLORS } from "../../../../../../../styled/scientColors"
import { Col, Row, Switch } from "../../../../../../components"

const GridPreferences = ({ stockSlug }) => {
  const { showEdit, showInactive, showEmpty } = useSelector(
    path(["profile", "userstockconfigs", stockSlug]),
  )

  const { freq } = useSelector(path(["profile", "userstockconfigs", stockSlug]))

  const dispatch = useDispatch()
  const toggleEdit = () => dispatch(profileDuck.actions.stockToggleEdit({ slug: stockSlug }))
  const toggleInactive = () =>
    dispatch(profileDuck.actions.stockToggleInactive({ slug: stockSlug }))
  const toggleEmpty = () => dispatch(profileDuck.actions.stockToggleEmpty({ slug: stockSlug }))

  const handleSetFreq = freq =>
    dispatch(profileDuck.actions.stockSetFreq({ slug: stockSlug, freq }))

  return (
    <>
      <Row>
        <Col>
          <H4 style={{ color: SCIENT_COLORS.blue5 }}>Grid Preferences</H4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Switch label="Edit mode" checked={showEdit} onChange={toggleEdit} />
          <Switch label="Show Empty" checked={showEmpty} onChange={toggleEmpty} />
          <Switch label="Show Inactive" checked={showInactive} onChange={toggleInactive} />
        </Col>
      </Row>

      <Row>
        <Col>
          <RadioGroup
            label="Preferred View"
            onChange={event => handleSetFreq(event.currentTarget.value)}
            selectedValue={freq}
            inline
          >
            <Radio label="ALL" value="all" />
            <Radio label="FY" value="fy" />
            <Radio label="MIX" value="mix" />
          </RadioGroup>
        </Col>
      </Row>
    </>
  )
}

export default GridPreferences
