import { Button, ButtonGroup, EditableText } from "@blueprintjs/core"
import { isEmpty } from "lodash"
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { ConfirmDialog } from "../../../components"
import { createToaster } from "../../../utils/createToaster"
import { ErrorIconWithTooltip } from "../../PortfolioManagement/Trading/components/ErrorIconWithTooltip"
import useUpdateTradingStockMutation from "../hooks/useUpdateTradingStockMutation"
import StockFields from "./StockFields"
import { FieldsContainer, NameContainer, StockName } from "./StockForm.styled"
import StockMetadata from "./StockMetadata"
import StockSectors from "./StockSectors"
import { TradingStock } from "./types"
import { formatStock } from "./utils/formatStock"

interface IStockFormProps {
  selectedStock: TradingStock
  setSelectedStock: Dispatch<SetStateAction<TradingStock>>
}

type confirmDialiogInitialStateProps = {
  open: boolean
  title: string
  dialogText: string
  handleClick: (() => void) | null
  confirmButtonText: string
}

const confirmDialiogInitialState: confirmDialiogInitialStateProps = {
  open: false,
  title: "",
  dialogText: "",
  handleClick: null,
  confirmButtonText: "",
}

const StockForm = ({ selectedStock, setSelectedStock }: IStockFormProps) => {
  const [confirmDialog, setConfirmDialog] = useState(confirmDialiogInitialState)

  const {
    control,
    handleSubmit,
    formState: { isDirty: isDirtyState, errors },
    reset,
  } = useForm<TradingStock>({
    defaultValues: selectedStock,
  })

  // Use effect to reset form when selectedStock changes
  useEffect(() => {
    if (selectedStock) {
      reset(selectedStock)
    }
  }, [selectedStock, reset])

  //Mutate function
  const updateStockMutation = useUpdateTradingStockMutation({
    onSuccess: stock => {
      setSelectedStock(stock)
      createToaster({
        message: `${selectedStock.name} Stock updated with success`,
        intent: "success",
      })
    },
    onError: () => {
      reset()
      createToaster({
        message: `An error occurred updating the Stock ${selectedStock.name}, please refresh the page`,
        intent: "danger",
      })
    },
  })

  /**
   * Callback to submit Form
   */
  const submitForm = useCallback(
    (stock: TradingStock) => {
      const formattedStock = formatStock(stock)
      updateStockMutation.mutate({ stockId: stock.id, stock: formattedStock })
      setConfirmDialog(confirmDialiogInitialState)
    },
    [updateStockMutation],
  )

  const handleSubmitForm = () => {
    setConfirmDialog({
      open: true,
      title: "Update stock",
      dialogText: "You are going update this stock",
      handleClick: () => handleSubmit(submitForm)(),
      confirmButtonText: "Update",
    })
  }

  /**
   * Callback to reset Form
   */
  const resetForm = useCallback(() => {
    reset()
    setConfirmDialog(confirmDialiogInitialState)
  }, [reset])

  const handleResetForm = () => {
    setConfirmDialog({
      open: true,
      title: "Reset stock",
      dialogText: "You are going to cancel your change.",
      handleClick: resetForm,
      confirmButtonText: "Reset",
    })
  }

  return (
    <form>
      <NameContainer alignItems="center" justifyContent="space-between">
        <Controller
          control={control}
          name="name"
          rules={{
            required: "Name is required",
          }}
          render={({ field: { onChange, value }, fieldState: { isDirty } }) => (
            <>
              <StockName isDirty={isDirty}>
                <EditableText value={value} onChange={onChange} placeholder="Edit Name" />
              </StockName>
              {errors.name && <ErrorIconWithTooltip errorMessage={errors.name.message} />}
            </>
          )}
        />
        <StockMetadata selectedStock={selectedStock} />
      </NameContainer>

      <FieldsContainer flexDirection="column" gap="15px">
        <StockFields control={control} />
        <StockSectors stockSectors={selectedStock.sectors} control={control} />
        {isDirtyState && (
          <FlexContainer justifyContent="flex-end">
            <ButtonGroup large fill style={{ width: "300px" }}>
              <Button text="Reset" icon="reset" onClick={handleResetForm} />
              <Button
                text="Update"
                icon="upload"
                intent="primary"
                onClick={handleSubmitForm}
                disabled={!isEmpty(errors)}
              />
            </ButtonGroup>
          </FlexContainer>
        )}
      </FieldsContainer>

      <ConfirmDialog
        title={confirmDialog.title}
        dialogText={confirmDialog.dialogText}
        open={confirmDialog.open}
        close={() => setConfirmDialog(confirmDialiogInitialState)}
        handleClick={confirmDialog.handleClick as () => void}
        confirmButtonText={confirmDialog.confirmButtonText}
      />
    </form>
  )
}

export default StockForm
