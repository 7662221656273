// @ts-nocheck
import { Button, ButtonGroup, Tooltip } from "@blueprintjs/core"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../../state/modules/sheets"

export const FreqButtonNGTF = ({ sheet }) => {
  const dispatch = useDispatch()

  const updateFreq = freq => {
    return dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        tf_freq: freq,
      }),
    )
  }

  const { tf_freq } = sheet

  return (
    <Tooltip content="Freq of the sheet" placement="bottom" openOnTargetFocus={false} compact>
      <ButtonGroup style={{ margin: "0 30px" }}>
        {/* //! Hide Daily temporary */}
        {/* <Button onClick={() => updateFreq("D")} active={tf_freq === "D"} text="D" /> */}
        <Button onClick={() => updateFreq("W")} active={tf_freq === "W"} text="W" />
        <Button onClick={() => updateFreq("M")} active={tf_freq === "M"} text="M" />
        <Button onClick={() => updateFreq("Q")} active={tf_freq === "Q"} text="Q" />
        <Button onClick={() => updateFreq("H")} active={tf_freq === "H"} text="H" />
        <Button onClick={() => updateFreq("Y")} active={tf_freq === "Y"} text="Y" />
      </ButtonGroup>
    </Tooltip>
  )
}
