// @ts-nocheck
import { NumericInput } from "@blueprintjs/core"

import { SCIENT_COLORS } from "../../../../../../../../../styled/scientColors"

export const resolveError = (form, field, default_value = null) =>
  field.name
    .split(/[.[\]'"]/)
    .filter(p => p)
    .reduce((o, p) => (o ? o[p] : default_value), form.errors)

export const fakeSyntheticEvent = (value, id) => ({
  persist: () => {
    // do nothing.
  },
  target: {
    type: "change",
    id,
    name: id,
    value,
  },
})

export const FormikDecimalInput = ({ field, form, inputProps }) => {
  const handleValueChange = value => form.setFieldValue(field.name, value)
  const error = resolveError(form, field)
  return (
    <>
      <NumericInput min={0} onValueChange={handleValueChange} {...field} {...inputProps} />
      {error && <p style={{ color: SCIENT_COLORS.orange3 }}>{error.replace(field.name, "")}</p>}
    </>
  )
}
