// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import entryDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { updateEntry, updateEntryOk, updateEntryError },
} = entryDuck

const updateEntryEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(updateEntry.type),
    switchMap(action => {
      const { idx, entryId, form } = action.payload
      const url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/entries/${entryId}/`
      return ajax({
        url,
        body: form,
        method: "PATCH",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response: { data } }) => updateEntryOk({ idx, data })),
        catchError(error => of(updateEntryError({ idx, error: error.xhr.response }))),
      )
    }),
  )
export default updateEntryEpic
