import { AxiosInstance, AxiosResponse } from "axios"

export interface IAggregatorsApi {
  changeDs: {
    list: {
      create: ({ values }: any) => Promise<AxiosResponse<any, any>>
    }
  }
  mavDs: {
    list: {
      create: ({ values }: any) => Promise<AxiosResponse<any, any>>
    }
  }
}

/**
 * Api routes for /api/v1/aggregators/
 */
const aggregators = (axiosClient: AxiosInstance): IAggregatorsApi => ({
  /**
   * POST /api/v1/aggregators/changeds/
   */
  changeDs: {
    list: {
      create: ({ values }: any) => axiosClient.post(`/aggregators/changeds/`, values),
    },
  },
  /**
   * POST /api/v1/aggregators/mavds/
   */
  mavDs: {
    list: {
      create: ({ values }: any) => axiosClient.post(`/aggregators/mavds/`, values),
    },
  },
})

export default aggregators
