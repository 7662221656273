import { Icon, Tooltip } from "@blueprintjs/core"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

type OwnerInfoTooltipProp = {
  deskOwnerName: string
}

export const OwnerInfoTooltip = ({ deskOwnerName }: OwnerInfoTooltipProp) => {
  return (
    <Tooltip content={`Owner: ${deskOwnerName}`} placement="right" compact>
      <Icon
        className="bp5-icon"
        icon="info-sign"
        style={{ color: SCIENT_COLORS.gray4, marginTop: "7px", marginLeft: "7px" }}
      />
    </Tooltip>
  )
}
