import { AgGridReact } from "ag-grid-react"
import React, { ForwardedRef, useCallback, useEffect, useState } from "react"
import { useResizeDetector } from "react-resize-detector"
import TinyMCEFullscreenIcon from "../../../../../icons/TinuMCEFullScreenIcon"
import { FlexContainer, FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { FullScreenFlexContainer } from "../../../../../styled/fullscreen.styled"
import { LargeText } from "../../../../../styled/text.styled"
import { FullScreenGridContainer, FullScreenGridHeader } from "./FullScreenGrid.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

type FullScreenGridProps = React.ComponentProps<typeof AgGridReact> & {
  label?: string
  children?: React.ReactNode
}

const FullScreenGrid = React.forwardRef(
  (props: FullScreenGridProps, ref: ForwardedRef<AgGridReact>) => {
    const [fullscreen, setFullscreen] = useState(false)
    const { width, ref: resizeDectectorRef } = useResizeDetector()

    /**
     * Extract children from props to avoid sending them to ag-grid
     */
    const { children, ...agGridProps } = props

    const sizeColumnsToFit = useCallback(() => {
      ;(ref as React.RefObject<AgGridReact>).current?.api?.sizeColumnsToFit()
    }, [ref])

    /**
     * Every time we go full screen or we resize the grid, we change
     * column size to fit the actual width.
     */
    useEffect(() => {
      sizeColumnsToFit()
    }, [width, sizeColumnsToFit])

    return (
      <FullScreenFlexContainer
        ref={resizeDectectorRef}
        fullscreen={fullscreen}
        flexDirection="column"
      >
        <FullScreenGridContainer className="ag-theme-balham-dark" flexDirection="column">
          <FullScreenGridHeader flexDirection="row-reverse" alignItems="center" gap="10px">
            <FlexContainerAndItem
              flexGrow={1}
              justifyContent="flex-end"
              gap="30px"
              alignItems="center"
            >
              {props.children}
            </FlexContainerAndItem>
            {props.label && (
              <FlexContainer gap="10px">
                <LargeText fontWeight="bold">{props.label}</LargeText>
                <TinyMCEFullscreenIcon
                  onClick={() => setFullscreen(prevState => !prevState)}
                  fullscreen={fullscreen}
                  fill={SCIENT_COLORS.darkWhite}
                />
              </FlexContainer>
            )}
          </FullScreenGridHeader>
          <AgGridReact ref={ref} {...agGridProps} />
        </FullScreenGridContainer>
      </FullScreenFlexContainer>
    )
  },
)

export default FullScreenGrid
