// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { Icon, Tooltip } from "@blueprintjs/core"
import { head } from "ramda"
import { Handle } from "react-flow-renderer"

import { placeEdges } from "../../../utils"
import { isValidConnection } from "../../helpers"
import { manyToOneIconCss, MultiConector } from "./DisplayNode.styled"

export const SourceHandle = ({ id, connectable, index, length }) => {
  return (
    <Handle
      type="source"
      id={id}
      position="right"
      style={{
        background: "#555",
        top: placeEdges(160, index, length),
        width: "16px",
        height: "16px",
      }}
      isValidConnection={isValidConnection}
      isConnectable={connectable}
    />
  )
}

export const TargetHandle = ({ id, connectable, index, length, many, name, optional }) => {
  // Label (=firstLetterInputName) inside handle are showed only when node has multiple inputs.
  const hasMultipleInputs = length > 1
  const firstLetterInputName = name && head(name)

  return (
    <Handle
      type="target"
      id={id}
      position="left"
      style={{
        background: "#555",
        top: placeEdges(160, index, length),
        width: "16px",
        height: "16px",
        border: optional ? "1px dashed" : "1px solid",
      }}
      isValidConnection={isValidConnection}
      isConnectable={many ? true : connectable} // if many true, connectable is always true because we want to be able to connect as many edge we want to it
    >
      {/**
       * Do not put inside an element else it will be impossible to connect edges.
       * Because element will hide the Handle.
       */}
      {hasMultipleInputs && firstLetterInputName}

      {/**
       * If connector accepts many inputs, an icon is displayed on the right of the connector.
       * If hasMultipleInputs is true, need to have negative marginTop to horizontaly align icon with handle. .
       */}
      {many && (
        <MultiConector hasMultipleInputs={hasMultipleInputs}>
          <Tooltip
            content="Connect as many time series as you want to this input."
            placement="bottom"
            openOnTargetFocus={false}
            compact
          >
            <Icon css={manyToOneIconCss} icon="many-to-one" size={10} />
          </Tooltip>
        </MultiConector>
      )}
    </Handle>
  )
}
