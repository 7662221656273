// @ts-nocheck
import { MenuItem } from "@blueprintjs/core"
import { Suggest } from "@blueprintjs/select"
import { useSectors } from "../pages/Ideas/hooks"

/**
 * Reusable Suggest component to select a sector from
 * the sectors list.
 */
const SectorSuggest = ({ selectedSector, onSectorSelect, placeholder }) => {
  const { sectors } = useSectors()

  const renderSector = (sector, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={sector.id}
        label={`Level ${sector.level}`}
        onClick={handleClick}
        text={sector.name}
      />
    )
  }

  const searchSector = (query, sectors) => {
    return sectors.filter(sector => sector.name.toLowerCase().includes(query.toLowerCase()))
  }

  const noResults = (
    <MenuItem active={false} disabled={true} icon="zoom-out" text="No Sectors found" />
  )

  return (
    <>
      {sectors && (
        <Suggest
          items={sectors}
          inputValueRenderer={sector => sector.name}
          itemRenderer={renderSector}
          itemListPredicate={searchSector}
          onItemSelect={onSectorSelect}
          noResults={noResults}
          fill={true}
          inputProps={{ placeholder }}
          selectedItem={selectedSector}
          resetOnSelect={true}
        />
      )}
    </>
  )
}

export default SectorSuggest
