// @ts-nocheck
import { Button, Card, Collapse, Colors, H5, H6, Label, MenuItem } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import { Field } from "formik"
import * as R from "ramda"
import { useState } from "react"
import { useSelector } from "react-redux"

import { SCIENT_COLORS } from "../../../../../../../../styled/scientColors"
import { Col, Row } from "../../../../../../../components"
import { ForecastSuggest } from "../../../../components"
import { companyList, stockFields } from "../../../utils"
import { Agg, Decimals, FormikTextInput, TimeChoice } from "../FormikFields"
import FormikStyling from "../FormikStyling"

export const StockField = ({ values, setValues, showOptions, toogleShowOptions, index }) => {
  const fieldList = useSelector(R.pathOr({}, ["shared", "fieldList"]))
  const formattedFields = stockFields(fieldList)

  const path = values.colDefs.cols[index].path
  const kind = values.colDefs.cols[index].kind
  const text = path && R.find(R.propEq("path", path))(formattedFields)["text"]
  const timeAware = path && R.find(R.propEq("path", path))(formattedFields)["timeAware"]

  const [filteredFields, setFilteredFields] = useState(formattedFields)
  const itemRenderer = (field, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    if (["title1", "title2"].includes(field.type))
      return (
        <li key={field.text} className="bp5-menu-header">
          {field.type === "title1" ? (
            <H5 style={{ color: Colors.GRAY4 }}>{field.text}</H5>
          ) : (
            <H6 style={{ color: Colors.GRAY5 }}>{field.text}</H6>
          )}
        </li>
      )
    return (
      <MenuItem
        active={modifiers.active}
        key={field.path}
        onClick={handleClick}
        text={field.text}
      />
    )
  }

  const handleQueryChange = query => {
    const results = formattedFields.filter(field => {
      if (["header", "subHeader"].includes(field.type)) return true
      const lowerQ = R.toLower(query)
      const titleIncludesQ = R.toLower(field.text).includes(lowerQ)
      if (field.path) {
        const cleanedPath = field.path.replace("'value'", "")
        return cleanedPath.includes(lowerQ) || titleIncludesQ
      }
      return titleIncludesQ
    })
    setFilteredFields(results)
  }

  const handleSubmitPath = field => {
    const newValues = {
      colDefs: {
        ...values.colDefs,
        cols: R.map(
          x =>
            R.indexOf(x, values.colDefs.cols) === index
              ? {
                  ...x,
                  path: field.path,
                  frontOpts: { ...x.frontOpts, pct: field.pct ? field.pct : false },
                }
              : x,
          values.colDefs.cols,
        ),
      },
    }
    return setValues(newValues)
  }

  const buttonText = text ? text : "Select Field"

  //Variables for ForecastSuggest
  const list = R.prepend({ key: "none", text: "None" }, companyList)

  const forecastOverrideCompany = // Title displayed in Select
    R.path(["colDefs", "cols", index, "forecastOverride", "company"], values) === ""
      ? "Choose forecast"
      : R.pathOr(
          "Choose forecast",
          ["colDefs", "cols", index, "forecastOverride", "company"],
          values,
        ).toUpperCase()

  const handleSubmitForecast = value => {
    const newValues =
      value.key === "none"
        ? {
            colDefs: {
              ...values.colDefs,
              cols: R.map(
                x =>
                  R.indexOf(x, values.colDefs.cols) === index
                    ? {
                        ...x,
                        forecastOverride: undefined,
                      }
                    : x,
                values.colDefs.cols,
              ),
            },
          }
        : {
            colDefs: {
              ...values.colDefs,
              cols: R.map(
                x =>
                  R.indexOf(x, values.colDefs.cols) === index
                    ? {
                        ...x,
                        forecastOverride: { company: value.key, owner: "default", label: "base" },
                      }
                    : x,
                values.colDefs.cols,
              ),
            },
          }
    return setValues(newValues)
  }
  //End

  return (
    <Card>
      <Row middle="xs">
        <Col xs={8}>
          <Label>
            Field
            <Select
              className="sct-fullwidth"
              items={filteredFields}
              itemRenderer={itemRenderer}
              noResults={<MenuItem disabled={true} text="No results." />}
              onItemSelect={handleSubmitPath}
              onQueryChange={handleQueryChange}
            >
              <Button fill icon="form" rightIcon="caret-down" text={buttonText} />
            </Select>
            {path === "" && <p style={{ color: SCIENT_COLORS.orange3 }}>Field is required!</p>}
          </Label>
        </Col>

        <Col xs={4}>
          <Agg index={index} />
        </Col>
      </Row>

      {timeAware !== null && (
        <TimeChoice index={index} kind={kind} path={path} timeAware={timeAware} />
      )}

      <Button
        fill
        alignText="left"
        minimal
        icon={showOptions ? "caret-down" : "caret-right"}
        onClick={toogleShowOptions}
      >
        {showOptions ? "Hide" : ""} Options
      </Button>
      <Collapse isOpen={showOptions}>
        <Card>
          <Row style={{ margin: 0 }}>
            <Col xs={8}>
              <Field
                name={`colDefs.cols[${index}].titleOverride`}
                component={FormikTextInput}
                labelProps={{ label: "Title override" }}
                inputProps={{ placeholder: "Enter Title" }}
                helperText="Enter title or leave empty."
              />
            </Col>
            <Col xs={4}>
              <Label style={{ marginRight: "10%" }}>
                Forecast override
                <ForecastSuggest
                  list={list}
                  company={forecastOverrideCompany.toUpperCase()}
                  submit={handleSubmitForecast}
                />
              </Label>
            </Col>
          </Row>
          <Decimals index={index} />
          <FormikStyling index={index} />
        </Card>
      </Collapse>
    </Card>
  )
}
