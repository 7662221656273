// @ts-nocheck
import React from "react"
import Generic from "./Generic"
import Var from "./Var"
import Reg from "./Reg"
import { NonIdealState, Button } from "@blueprintjs/core"
import { useDispatch } from "react-redux"
import { fetchAnalyse } from "../../../../../../state/modules/analysis"

const Results = ({ analyse, isFailed, hasResults, isProcessing, setTab }) => {
  const dispatch = useDispatch()

  const handleFetchAnalyse = () => {
    dispatch(fetchAnalyse({ id: analyse.id }))
  }
  return hasResults ? (
    <div>
      <Generic analyse={analyse} />
      {isProcessing ? (
        <NonIdealState
          icon={"series-derived"}
          title="Processing the analyse..."
          action={
            <Button icon={"refresh"} onClick={handleFetchAnalyse}>
              Refresh analyse
            </Button>
          }
        />
      ) : isFailed ? (
        <NonIdealState
          icon={"error"}
          title="Failed to compute the analysis"
          description={analyse.failed}
          action={
            <Button icon={"refresh"} onClick={() => setTab("builder")}>
              Go to builder
            </Button>
          }
        />
      ) : (
        <>{analyse.method === "reg" ? <Reg analyse={analyse} /> : <Var analyse={analyse} />}</>
      )}
    </div>
  ) : (
    <>
      <p>No results yet.</p>
      <p>Please refresh analyse in a moment.</p>
    </>
  )
}
export default Results
