// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import monitorsDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import shapeMonitors from "./shapeMonitors"
// import { assoc, compose } from "ramda"

const {
  actions: { fetchMonitorList, fetchMonitorListOk, fetchMonitorListError },
} = monitorsDuck

const fetchMonitorListEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchMonitorList.type),
    mergeMap(() =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/monitors/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => {
          return fetchMonitorListOk({ data: shapeMonitors(response) })
        }),
        catchError(error => {
          return of(fetchMonitorListError(error.xhr.response))
        }),
      ),
    ),
  )

export default fetchMonitorListEpic
