import { useInfiniteQuery } from "@tanstack/react-query"
import { useApi } from "../../../../hooks/useApi"
import { Wave } from "../types/wave"

/**
 * React query hook to GET Waves paginated
 */
const useInfiniteWaves = () => {
  const { scientApi } = useApi()

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    status,
    isFetching,
    dataUpdatedAt,
    isFetchingNextPage,
  } = useInfiniteQuery<{
    data: Wave[]
    next_cursor: string | null
  }>(["waves"], ({ pageParam }) => scientApi.trading.getTradeWaves(pageParam), {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.next_cursor) {
        return lastPage.next_cursor
      }
      return undefined
    },
    // https://github.com/TanStack/query/issues/3065
    // TODO: once v5 is out, allow typing on select for infinite data
    // @ts-ignore
    select: data => {
      let waves: Wave[] = []
      for (const page of data.pages) {
        waves = [...waves, ...page.data]
      }
      return waves
    },
    staleTime: Infinity,
  })

  return {
    waves: data as Wave[] | undefined,
    fetchNextPage,
    hasNextPage,
    error,
    status,
    isFetching,
    dataUpdatedAt,
    isFetchingNextPage,
  }
}

export default useInfiniteWaves
