// @ts-nocheck
import React from "react"
import { compose, isEmpty, reject, map } from "ramda"
import { Button, ButtonGroup, Classes, H5 } from "@blueprintjs/core"

import { SelectExistingSource } from "./SelectExistingSource"
import { getTFSources } from "../../../../../utils"

export const SelectType = ({
  rowDefs,
  selectedSource,
  setSelectedSource,
  panelsState,
  setPanelsState,
  DFState,
}) => {
  const existingSources = getTFSources(rowDefs)

  const existingRows = compose(
    map(row => ({
      label: row.frontOpts.name,
      value: row.rowId,
    })),
    reject(row => row.active),
    reject(row => isEmpty(row.frontOpts.formulaItems)),
  )(rowDefs)

  return (
    <div className={Classes.DIALOG_BODY} style={{ margin: "20px 30px" }}>
      <H5>Select type of source</H5>

      <ButtonGroup style={{ margin: "0 0 12px 20px" }}>
        <Button
          text="New source"
          onClick={() => setPanelsState({}) & setSelectedSource("newSource")}
          active={selectedSource === "newSource"}
          large
        />
        {existingRows.length > 0 && (
          <Button
            text="Existing row"
            onClick={() => setPanelsState({}) & setSelectedSource("existingRow")}
            active={selectedSource === "existingRow"}
            large
          />
        )}
      </ButtonGroup>
      {existingSources.length > 0 && (
        <>
          <H5>Or directly an existing source in the list</H5>
          <SelectExistingSource
            existingSources={existingSources}
            panelsState={panelsState}
            setPanelsState={setPanelsState}
            setSelectedSource={setSelectedSource}
            DFState={DFState}
          />
        </>
      )}
    </div>
  )
}
