import { useState } from "react"

import SearchStock from "../components/SearchStock"
import { SearchStockContainer, StockContainer, StockDetailContainer } from "./Stock.styled"
import StockForm from "./StockForm"
import StockListings from "./StockListings"
import { TradingStock } from "./types"

const Stock = () => {
  const [selectedStock, setSelectedStock] = useState<TradingStock | undefined>(undefined)

  return (
    <StockContainer flexDirection="column" alignItems="center" justifyContent="center" gap="10px">
      <SearchStockContainer justifyContent="center">
        <SearchStock setSelectedStock={setSelectedStock} />
      </SearchStockContainer>

      {selectedStock && (
        <StockDetailContainer>
          <StockForm selectedStock={selectedStock} setSelectedStock={setSelectedStock} />
          <StockListings listings={selectedStock.listings} />
        </StockDetailContainer>
      )}
    </StockContainer>
  )
}

export default Stock
