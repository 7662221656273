import { Button, ButtonGroup } from "@blueprintjs/core"
import { Field, useField, useFormikContext } from "formik"
import React from "react"
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter"

interface IToggleButtonGroupProps {
  items: object
  fieldName: string
  disabled: boolean
}

/**
 * Generate a button group based on an enum.
 * The button will have displayed names the keys of the enum
 * and the selected value is the value associated.
 */
const ToggleButtonGroup = ({ items, fieldName, disabled }: IToggleButtonGroupProps) => {
  const [field] = useField(fieldName)
  const { setFieldValue } = useFormikContext<Record<string, object>>()

  return (
    <Field>
      {() => (
        <ButtonGroup fill>
          {Object.keys(items).map(key => {
            return (
              <Button
                key={key}
                text={capitalizeFirstLetter(items[key as keyof object])}
                active={field.value === items[key as keyof object]}
                onClick={() => {
                  setFieldValue(fieldName, items[key as keyof object])
                }}
                style={disabled ? { pointerEvents: "none" } : undefined}
              />
            )
          })}
        </ButtonGroup>
      )}
    </Field>
  )
}

export default ToggleButtonGroup
