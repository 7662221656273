import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const MetricSelectionContainer = styled(FlexContainer)({
  height: "40px",
  backgroundColor: SCIENT_COLORS.darkGray2,
})

export const MetricSelectionPopover = styled(FlexContainer)({
  width: "750px",
  paddingTop: "8px",
})
