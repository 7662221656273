// @ts-nocheck
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FlexContainer } from "../../../../../../styled/flexbox.styled"

export const RadioContainer = styled.div({
  display: "flex",
})

export const RadioTitleExplanation = styled.p({
  fontStyle: "italic",
  width: "300px",
})

export const IconContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingLeft: "10px",
})

export const TooltipContent = styled(FlexContainer)({
  width: "350px",
  textAlign: "justify",
})

export const H3Title = styled.h3({
  margin: 0,
})

export const H4Title = styled.h4({
  margin: 0,
})

export const iconStyle = css`
  margin-bottom: 10px;
  cursor: help;
  &:hover {
    color: orange;
  }
`
