import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const SummaryContainer = styled(FlexContainer)({
  backgroundColor: SCIENT_COLORS.darkGray5,
  padding: "5px 25px",
  width: "100%",
})

export const MetricsContainer = styled(FlexContainer)({
  width: "70%",
})
