// @ts-nocheck
import { combineEpics } from "redux-observable"

import createEpic from "./create"
import updateEpic from "./update"
import deleteEpic from "./delete"
import fetchEpic from "./fetch"

const analyseEpics = combineEpics(createEpic, updateEpic, deleteEpic, fetchEpic)

export default analyseEpics
