import { Button, Icon, Tooltip } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { isEmpty } from "ramda"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { MediumText } from "../../../../../styled/text.styled"
import { TextAreaAutosize } from "../../../../components"
import { useAuth, useIdeasCache } from "../../../../hooks"
import { useGlobalState } from "../../../../hooks/useGlobalState"
import { createToaster } from "../../../../utils/createToaster"
import { useMessageListMutation } from "../../hooks"
import { ConversationMessage, Idea, IdeaRevision } from "../../types/business"
import {
  CreateMessageContainer,
  KeyIconContainer,
  LoadingMessage,
  ParentContainer,
  SendContainer,
} from "./CreateMessage.styled"

/** Toolpip for the send button */
const ToolTipContent = () => {
  return (
    <FlexContainer flexDirection="column" gap="8px">
      <MediumText color={SCIENT_COLORS.darkGray5}>Send now</MediumText>
      <FlexContainer justifyContent="space-around">
        <KeyIconContainer>
          <Icon icon="key-control" size={20} />
        </KeyIconContainer>
        <KeyIconContainer>
          <Icon icon="key-enter" size={20} />
        </KeyIconContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

interface ICreateMessageProps {
  idea: Idea
  selectedRevision: IdeaRevision
}

const CreateMessage = ({ idea, selectedRevision }: ICreateMessageProps) => {
  const { profile } = useAuth()
  const conversationId = selectedRevision.conversation.id

  const userId = profile?.id

  const messagesListMutation = useMessageListMutation({
    onSuccess: (message: ConversationMessage) => {
      /**
       * Note that we use the tmp_id (newMessage.id) generated by the front
       * to update the newly created message with data from server
       * (meaning id will be replaced with the one created by the server)
       */
      if (conversationId && message.tmp_id) {
        updateMessage &&
          updateMessage(conversationId, message.tmp_id, {
            ...message,
            status: "success",
          })

        /**
         * On success, we update the idea with the last message created_at
         * and set the conversation revision as read
         */
        updateIdea &&
          updateIdea(idea.id, {
            last_message_created_at: message.created_at,
            default_sorting_date: message.created_at,
          })
      }
    },
    onError: (error: AxiosError) => {
      if (conversationId)
        updateMessage &&
          updateMessage(conversationId, error.request?.data["tmp_id"], { status: "failure" })
      /**
       * Create toaster instance and use show
       * since we are in an async loop (we want the toaster to show
       * whatever the page)
       */
      createToaster({
        message: "An error occurred sending the Message, please refresh the page",
        intent: "danger",
      })
    },
  })

  const [newMessageContent, setNewMessageContent] = useState("")

  const clearMessage = () => setNewMessageContent("")
  const { isLoading } = messagesListMutation

  const { createMessage, updateIdea, updateMessage } = useIdeasCache()

  /**
   * We clean white space and empty line in newMessageContent
   */
  const trimedNewMessageContent = newMessageContent.trim()

  /**
   * Handles the logic to send a message
   */
  const handleClick = () => {
    /**
     * We create the newly created message
     */
    if (conversationId && userId) {
      const tmp_id = uuidv4()
      const newMessage: ConversationMessage = {
        content: trimedNewMessageContent,
        created_at: new Date().toISOString(),
        id: tmp_id,
        tmp_id,
        conversation_id: conversationId,
        user: {
          id: userId,
          username: profile.username,
        },
        status: "sending",
      }

      /**
       * We add the message to our local state
       */
      createMessage && createMessage(conversationId, newMessage)

      /**
       * We update the local ideas state with updated last message
       */
      updateIdea &&
        updateIdea(idea.id, {
          last_message_created_at: newMessage.created_at,
          default_sorting_date: newMessage.created_at,
        })
      /**
       * We call the POST message
       * - On success, we will update the message.status to success and the message attributes with the response
       * - On Failure, we will update the message.status to failure in order to be able to retry send
       */
      messagesListMutation.mutate({
        conversationId,
        values: { content: trimedNewMessageContent, tmp_id: newMessage.tmp_id },
      })
    }

    clearMessage()
  }

  const handleTextAreaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setNewMessageContent(event.target?.value)
    },
    [setNewMessageContent],
  )

  // Allow to send message by presssing 'Control + Enter'
  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.ctrlKey && event.key === "Enter" && !isEmpty(trimedNewMessageContent) && !isLoading) {
      handleClick()
    }
  }

  /**
   * Reset message state on new idea selected
   */
  useEffect(() => {
    if (idea.id) {
      clearMessage()
    }
  }, [idea.id])

  return (
    <ParentContainer flexDirection="column" alignItems="center">
      <CreateMessageContainer flexDirection="column">
        <TextAreaAutosize
          placeholder="New message"
          value={newMessageContent}
          handleChange={handleTextAreaChange}
          onKeyDown={onKeyDown}
          backgroundColor={`${SCIENT_COLORS.darkGray3} !important`}
          borderRadius="5px 5px 0 0"
          boxShadow="none !important"
        />

        <SendContainer justifyContent="flex-end" alignItems="center">
          {isLoading && <LoadingMessage>Sending in progress...</LoadingMessage>}
          <Tooltip
            content={<ToolTipContent />}
            placement="top"
            compact
            disabled={isEmpty(trimedNewMessageContent) || isLoading}
          >
            <Button
              icon="send-message"
              intent={!isEmpty(trimedNewMessageContent) ? "primary" : undefined}
              disabled={isEmpty(trimedNewMessageContent) || isLoading}
              onClick={handleClick}
              style={{ margin: "0 2px 2px 0" }}
            />
          </Tooltip>
        </SendContainer>
      </CreateMessageContainer>
    </ParentContainer>
  )
}

export default CreateMessage
