import { FormGroup, HTMLSelectProps } from "@blueprintjs/core"
import { SerializedStyles } from "@emotion/react"
import { FC } from "react"

import { StyledSelect } from "./Select.styled"

interface ISelectProps {
  noPadding?: boolean
  noDivWrapper?: boolean
  labelProps?: Record<string, string>
  selectProps: HTMLSelectProps
  customCss?: SerializedStyles
}

export const Select: FC<ISelectProps> = ({
  noPadding,
  noDivWrapper,
  labelProps,
  selectProps: { value = "", ...rest },
  customCss,
}) => {
  const Wrapped = () => <StyledSelect value={value || ""} customCss={customCss} {...rest} />

  if (labelProps) {
    return (
      <FormGroup {...labelProps}>
        <Wrapped />
      </FormGroup>
    )
  }

  if (noPadding) {
    return (
      <div>
        <Wrapped />
      </div>
    )
  }

  if (noDivWrapper) {
    return <Wrapped />
  }

  return (
    <div className="sct-side-item">
      <Wrapped />
    </div>
  )
}
