import { H1, Icon } from "@blueprintjs/core"
import { HomeContainer, HomeIconContainer, WidgetContainer } from "./Home.styled"

import { FlexContainerAndItem } from "../../../styled/flexbox.styled"
import IdeaTradesInsightsWidget from "../../components/IdeaTradesInsightsWidget/IdeaTradesInsightsWidget"
import useQueryIdeasTradeInsight from "../../hooks/useQueryIdeasTradeInsight"

const Home = () => {
  const { tradeInsight, isLoading } = useQueryIdeasTradeInsight()

  return (
    <HomeContainer>
      <WidgetContainer>
        <IdeaTradesInsightsWidget tradeInsight={tradeInsight} isLoading={isLoading} />
      </WidgetContainer>
      <FlexContainerAndItem
        flexGrow={1}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <HomeIconContainer flexDirection="column">
          <Icon icon="home" size={94} />
          <H1>Home</H1>
        </HomeIconContainer>
      </FlexContainerAndItem>
    </HomeContainer>
  )
}

export default Home
