// @ts-nocheck
import React from "react"
import { Spinner } from "@blueprintjs/core"

export const LoaderIn = ({ title = "Loading..." }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      textAlign: "center",
      alignIems: "center",
    }}
  >
    <Spinner />
    {title && <p style={{ marginTop: "4px" }}>{title}</p>}
  </div>
)
