// @ts-nocheck
import { combineEpics } from "redux-observable"

import analysesEpics from "./analyses"
import analyseEpics from "./analyse"
import foldersEpics from "./folders"

const analysesModuleEpics = combineEpics(analysesEpics, analyseEpics, foldersEpics)

export default analysesModuleEpics
