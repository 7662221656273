// @ts-nocheck
import { findIndex, pathOr, propEq } from "ramda"

export const convertPath = (rawPath, data, options = {}) => {
  const newPath = []
  rawPath.forEach(step => {
    const regex = /^(\w+):(\S+)/g
    if (step.match(regex)) {
      const [, key, value] = regex.exec(step)
      const numRegex = /^[0-9]+$/g
      if (value.match(numRegex)) {
        return newPath.push(findIndex(propEq(key, parseInt(value)), pathOr({}, newPath, data)))
      }
      return newPath.push(findIndex(propEq(key, value), pathOr({}, newPath, data)))
    }
    return newPath.push(step)
  })
  return options.flatten ? newPath.join(".") : newPath
}
