import { QueryKey, useQueryClient } from "@tanstack/react-query"
import React, { ReactNode, useCallback, useState } from "react"
import { IdeaOrdering, IdeaSorting } from "../../../../api/ideas"

import { IUser } from "../../../../api/index"
import useIdeasLiveEvents from "../../../../websocket/hooks/useIdeasLiveEvents"
import { ConversationMessage, Idea, IdeaRevision, IdeaType } from "../types/business"
import { cloneDeep } from "lodash"

/**
 * Context that retains the methods to update the query cache on ideas
 * react query queries.
 * Since we have live events through websocket, we set react query to not
 * refetch (except when ws disconnection occurs), and rely on live updates
 * Live updates are updating the query cache and the context provides
 * an interface with methods to upload it
 */

export interface IIdeaCacheContext {
  updateIdea: ((ideaId: string | number, payload: Partial<Idea>) => void) | undefined
  removeIdea:
    | ((
        ideaId: number,
        {
          draft,
        }: {
          draft: boolean
        },
      ) => void)
    | undefined
  createIdea: ((idea: Partial<Idea>, queryKey?: any, append?: boolean) => void) | undefined
  updateMessage:
    | ((
        conversationId: number,
        messageId: number | string,
        payload: Partial<ConversationMessage>,
      ) => void)
    | undefined
  createMessage: ((conversationId: number, newMessage: ConversationMessage) => void) | undefined
  getMessages: ((conversationId: number) => ConversationMessage[]) | undefined
  getIdeasQueryData?:
    | ((queryKey: any) => {
        queryKey: any
        ideas: Idea[]
        hasMoreIdeas: boolean
      })
    | undefined
  stockFilter: { id: number; bloomberg_code: string } | null
  sectorFilter: {
    id: number
    level: number
    name: string
  } | null
  draftFilter: boolean
  tradeOpenOnly: boolean
  tradeClosedOnly: boolean
  ideaTypeFilter: IdeaType | null
  orderBy: IdeaOrdering
  sortDirection: IdeaSorting
  setStockFilter:
    | React.Dispatch<
        React.SetStateAction<{
          id: number
          bloomberg_code: string
        } | null>
      >
    | undefined
  setSectorFilter:
    | React.Dispatch<
        React.SetStateAction<{
          id: number
          level: number
          name: string
        } | null>
      >
    | undefined
  userFilter: IUser | null
  setUserFilter: React.Dispatch<IUser | null> | undefined
  setDraftFilter: React.Dispatch<React.SetStateAction<boolean>> | undefined
  setIdeaTypeFilter: React.Dispatch<React.SetStateAction<IdeaType | null>> | undefined
  setOrderBy: React.Dispatch<React.SetStateAction<IdeaOrdering>> | undefined
  setSortDirection: React.Dispatch<React.SetStateAction<IdeaSorting>> | undefined
  setTradeOpenOnly: React.Dispatch<React.SetStateAction<boolean>> | undefined
  setTradeClosedOnly: React.Dispatch<React.SetStateAction<boolean>> | undefined
}

export const IdeaCacheContext = React.createContext<IIdeaCacheContext>({
  updateIdea: undefined,
  removeIdea: undefined,
  createIdea: undefined,
  updateMessage: undefined,
  createMessage: undefined,
  getMessages: undefined,
  getIdeasQueryData: undefined,
  /**
   * We set null here, as Blueprint that we use needs null for empty values
   */
  stockFilter: null,
  sectorFilter: null,
  userFilter: null,
  draftFilter: false,
  ideaTypeFilter: null,
  orderBy: IdeaOrdering.DEFAULT,
  sortDirection: IdeaSorting.DESC,
  tradeOpenOnly: false,
  tradeClosedOnly: false,
  setStockFilter: undefined,
  setSectorFilter: undefined,
  setUserFilter: undefined,
  setDraftFilter: undefined,
  setIdeaTypeFilter: undefined,
  setOrderBy: undefined,
  setSortDirection: undefined,
  setTradeOpenOnly: undefined,
  setTradeClosedOnly: undefined,
})

/**
 * Provider used to provide query cache update methods
 * to all children of the Provider
 */
export const IdeaCacheProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient()

  /**
   * All the state for filtering, sorting and ordering ideas.
   * This is stored in the IdeaCacheProvider so that we can
   * retrieve the active cache for handling live events.
   *
   * Sector, Stock and User filters are represented by an object
   * this way we can hold the value of the filter with other useful
   * information for rendering the selected filter.
   * The actual value of the filter sent to the backend must be accessed
   * by their respective id. (e.g stock.id)
   */
  const [stockFilter, setStockFilter] = useState<{ id: number; bloomberg_code: string } | null>(
    null,
  )
  const [sectorFilter, setSectorFilter] = useState<{
    id: number
    level: number
    name: string
  } | null>(null)
  const [userFilter, setUserFilter] = useState<IUser | null>(null)
  const [draftFilter, setDraftFilter] = useState(false)
  const [ideaTypeFilter, setIdeaTypeFilter] = useState(null)
  const [tradeOpenOnly, setTradeOpenOnly] = useState(false)
  const [tradeClosedOnly, setTradeClosedOnly] = useState(false)
  const [orderBy, setOrderBy] = useState<IdeaOrdering>(IdeaOrdering.DEFAULT)
  const [sortDirection, setSortDirection] = useState<IdeaSorting>(IdeaSorting.DESC)

  /**
   * Adds an idea to idea cache associated with the provided queryKey.
   * If no key is provided,
   * we create the idea in the default public or draft cache depending on the
   * idea.draft bool.
   * By default, the idea is prepended to the associated cache,
   * if createIdea is called with append=true we create the idea at the end of
   * the cache. This is useful when we sort ideas by ASC intead of DESC
   */
  const createIdea = useCallback(
    (
      idea: Partial<Idea>,
      queryKey = [
        "ideas",
        idea.draft ? "draft" : "public",
        { orderBy: IdeaOrdering.DEFAULT, sortDirection: IdeaSorting.DESC },
        {
          stock: undefined,
          sector: undefined,
          user: undefined,
          ideaType: "",
          tradeOpenOnly: false,
          tradeClosedOnly: false,
        },
      ],
      append = false,
    ) => {
      queryClient.setQueryData<
        { pages?: Array<{ data: Partial<Idea>[]; next_cursor: string | null }> } | undefined
      >(queryKey, infiniteData => {
        const pages = infiniteData?.pages
        if (pages) {
          if (pages.length > 0 && !append) {
            pages[0].data.unshift(idea)
            return cloneDeep(infiniteData)
          } else if (pages?.length > 0 && append) {
            pages[pages.length - 1].data.push(idea)
            return cloneDeep(infiniteData)
          }
        }
        return infiniteData
      })
    },
    [queryClient],
  )

  /**
   * Query update to update the cached data when we update an idea.
   */
  const updateIdeaQueryUpdater = (
    infiniteData: { pages: Array<{ data: Idea[]; next_cursor: string | null }> } | undefined,
    ideaId: number | string,
    payload: Partial<Idea>,
  ) => {
    /**
     * We search in all pages if the idea to update is present
     * An idea is present if
     * - it was added through the cache update (always first page)
     * - it was initially fetched or loaded through pagination (fetchMore)
     */
    if (infiniteData) {
      for (const page of infiniteData.pages) {
        let indexFound = 0
        const idea = page.data.find((idea, index) => {
          indexFound = index
          return idea.id === ideaId
        })
        if (idea) {
          const updatedIdea = {
            ...idea,
            ...payload,
          }

          /**
           * Set the new reference of the updatedIdea in the cache
           * Force cast Idea for now, as partial conversation update
           * might not
           */
          page.data[indexFound] = updatedIdea as Idea

          return cloneDeep(infiniteData)
        }
      }

      /**
       * If idea was not found, its not fetched yet, nothing to do
       */
      return infiniteData
    }
  }

  /**
   * Updates an idea in the ideas query cache
   */
  const updateIdea = useCallback(
    (ideaId: string | number, payload: Partial<Idea>) => {
      queryClient.setQueriesData(["ideas"], (infiniteData: any) =>
        updateIdeaQueryUpdater(infiniteData, ideaId, payload),
      )
    },
    [queryClient],
  )

  /**
   * Creates a message in the cache on conversationId
   */
  const createMessage = useCallback(
    (conversationId: number, newMessage: ConversationMessage) => {
      const queryIsInit = queryClient.getQueryState([
        `messages-${conversationId}`,
        { conversationId },
      ])
      if (queryIsInit) {
        queryClient.setQueryData(
          [`messages-${conversationId}`, { conversationId }],
          (infiniteData: any) => {
            const pages = infiniteData?.pages
            if (pages?.length > 0) {
              pages[0].data.unshift({ ...newMessage })
              return { ...infiniteData }
            }
            return infiniteData
          },
        )
      }
    },
    [queryClient],
  )

  /**
   * Updates a message in the cache with payload from conversation conversationId
   */
  const updateMessage = useCallback(
    (conversationId: number, messageId: number | string, payload: Partial<ConversationMessage>) => {
      queryClient.setQueryData(
        [`messages-${conversationId}`, { conversationId }],
        (infiniteData: any) => {
          for (const page of infiniteData.pages) {
            let indexFound = 0
            const message = page.data.find((message: ConversationMessage, index: number) => {
              indexFound = index
              return message.id === messageId
            })
            if (message) {
              page.data[indexFound] = { ...message, ...payload }
              return { ...infiniteData }
            }
          }
          return infiniteData
        },
      )
    },
    [queryClient],
  )

  /**
   * Get all messages in the cache
   */
  const getMessages = useCallback(
    (conversationId: number) => {
      let messages: ConversationMessage[] = []
      const paginated: any = queryClient.getQueryData([
        `messages-${conversationId}`,
        { conversationId },
      ])
      if (paginated) {
        for (const page of paginated.pages) {
          messages = [...page.data, ...messages]
        }
      }
      return messages
    },
    [queryClient],
  )

  /**
   * Get all ideas in the cache,
   * wrapped inside useCallback query the ideas
   * depending on the active filters.
   *
   * We return an object that contains the ideas with
   * the associated queryKey.
   * If we explicitly provide a queryKey we return the ideas
   * of this query without using stored filters and sort/order pref.
   * The result also contains hasMoreIdeas bool to know if we have more ideas
   * in the backend that are not loaded yet.
   */
  const getIdeasQueryData = useCallback(
    (queryKey?: QueryKey) => {
      let ideas: Idea[] = []
      if (!queryKey) {
        queryKey = [
          "ideas",
          draftFilter ? "draft" : "public",
          { orderBy: orderBy, sortDirection: sortDirection },
          {
            stock: stockFilter?.id,
            sector: sectorFilter?.id,
            user: userFilter?.id,
            ideaType: ideaTypeFilter,
            tradeOpenOnly: tradeOpenOnly,
            tradeClosedOnly: tradeClosedOnly,
          },
        ]
      }
      const paginated: any = queryClient.getQueryData(queryKey)
      let hasMoreIdeas = true
      if (paginated) {
        for (const page of paginated.pages) {
          ideas = [...page.data, ...ideas]
          if (!page.next_cursor) {
            hasMoreIdeas = false
          }
        }
      }
      return { queryKey: queryKey, ideas: ideas, hasMoreIdeas: hasMoreIdeas }
    },
    [
      queryClient,
      draftFilter,
      orderBy,
      sortDirection,
      stockFilter,
      sectorFilter,
      userFilter,
      ideaTypeFilter,
      tradeOpenOnly,
      tradeClosedOnly,
    ],
  )

  /**
   * Remove a specific idea from the specified cache if found.
   */
  const removeIdea = useCallback(
    (ideaId: number, { draft }: { draft: boolean }) => {
      queryClient.setQueriesData(["ideas", draft ? "draft" : "public"], (infiniteData: any) => {
        if (infiniteData && infiniteData.pages)
          for (const page of infiniteData.pages) {
            let indexFound = 0
            const idea = page.data.find((idea: Idea, index: number) => {
              indexFound = index
              return idea.id === ideaId
            })
            if (idea) {
              page.data.splice(indexFound, 1)
              return { ...infiniteData }
            }
          }
        return infiniteData
      })
    },
    [queryClient],
  )

  /**
   * Inits all handlers to handle live events for Ideas
   */
  useIdeasLiveEvents({
    createIdea,
    updateIdea,
    createMessage,
    updateMessage,
    getMessages,
    getIdeasQueryData,
  })

  return (
    <IdeaCacheContext.Provider
      value={{
        createIdea,
        updateIdea,
        createMessage,
        updateMessage,
        getMessages,
        getIdeasQueryData,
        removeIdea,
        stockFilter,
        setStockFilter,
        sectorFilter,
        setSectorFilter,
        userFilter,
        setUserFilter,
        draftFilter,
        setDraftFilter,
        ideaTypeFilter,
        setIdeaTypeFilter,
        orderBy,
        setOrderBy,
        sortDirection,
        setSortDirection,
        tradeOpenOnly,
        setTradeOpenOnly,
        tradeClosedOnly,
        setTradeClosedOnly,
      }}
    >
      {children}
    </IdeaCacheContext.Provider>
  )
}
