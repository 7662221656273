import { NumericInput } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { useField, useFormikContext } from "formik"
import { IdeaCreateLabel, IdeaSettingsInnerContainer } from "./IdeaCreate.styled"
import { IdeaCreateTargetPriceContainer } from "./IdeaCreateTargetPrice.styled"
import { IIdeaCreateForm } from "./IdeaCreate"

interface IIdeaCreateTargetPriceProps {
  hidden: boolean
  fieldName: string
  disabled?: boolean
  index?: number
}

const IdeaCreateTargetPrice = ({
  hidden,
  fieldName,
  disabled,
  index,
}: IIdeaCreateTargetPriceProps) => {
  const [targetPriceField] = useField(fieldName)
  const { setFieldValue } = useFormikContext<IIdeaCreateForm>()

  return (
    <IdeaCreateTargetPriceContainer flexDirection="column" hidden={hidden}>
      <IdeaCreateLabel>
        {`Target Price` + (index ? ` (${index})` : "") + " - Optional"}
      </IdeaCreateLabel>
      <IdeaSettingsInnerContainer flexDirection="column">
        <NumericInput
          name={targetPriceField.name}
          leftIcon={IconNames.DOLLAR}
          fill
          large
          value={targetPriceField.value}
          onValueChange={value => setFieldValue(targetPriceField.name, value)}
          disabled={!!disabled}
        />
      </IdeaSettingsInnerContainer>
    </IdeaCreateTargetPriceContainer>
  )
}

export default IdeaCreateTargetPrice
