// @ts-nocheck
import * as R from "ramda"
import { recursiveEmptyStrToNull } from "../../../utils"

const cleanIncompleteGeos = obj => {
  if (Array.isArray(obj.geos)) {
    const newObj = R.over(R.lensProp("geos"), R.reject(R.propEq("share", null)), obj)
    if (newObj.geos.length === 0) {
      return R.dissoc("geos")(obj)
    }
    return newObj
  }
  return obj
}

const cleanEmptyGeos = obj => {
  if (obj.geos === "" || obj.geos === null) {
    return R.dissoc("geos")(obj)
  }
  return obj
}

const cleanIncompleteCurrencies = obj => {
  if (Array.isArray(obj.currencies)) {
    const newObj = R.over(
      R.lensProp("currencies"),
      R.reject(R.allPass([R.propEq("sales_exposure", null), R.propEq("cost_exposure", null)])),
      obj,
    )
    if (newObj.currencies.length === 0) {
      return R.dissoc("currencies")(obj)
    }
    return newObj
  }
  return obj
}

const cleanEmptyCurrencies = obj => {
  if (obj.currencies === "" || obj.currencies === null) {
    return R.dissoc("currencies")(obj)
  }
  return obj
}

const cleanIncompleteFields = obj => {
  if (Array.isArray(obj.fields)) {
    const newObj = R.over(R.lensProp("fields"), R.reject(R.propEq("value", null)), obj)
    if (newObj.fields.length === 0) {
      return R.dissoc("fields")(obj)
    }
    return newObj
  }
  return obj
}

const cleanEmptyFields = obj => {
  if (obj.fields === "" || obj.fields === null) {
    return R.dissoc("fields")(obj)
  }
  return obj
}

const cleanEmptySplits = obj => {
  if (obj.splits === "" || obj.splits === null) {
    return R.dissoc("splits")(obj)
  }
  return obj
}

export const cleanReport = R.compose(
  cleanEmptySplits,
  cleanEmptyFields,
  cleanIncompleteFields,
  cleanEmptyCurrencies,
  cleanIncompleteCurrencies,
  cleanEmptyGeos,
  cleanIncompleteGeos,
  recursiveEmptyStrToNull,
)
