// @ts-nocheck
import React, { useState, useEffect } from "react"
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"

import { defaultOptions } from "../../../../../_shared/highcharts"

const QuickChartContent = ({ series, format, name, fullScreen, chartType, height = "40%" }) => {
  // Use to update chart size when move to fullScreen
  const [chart, setChart] = useState()
  useEffect(() => {
    chart && chart.reflow()
  }, [chart, fullScreen])

  // highCharts options
  const options = {
    ...defaultOptions,
    chart: {
      type: chartType,
      height: height, // 16:9 ratio
    },
    title: { text: name },
    scrollbar: {
      enabled: false, // disabled scrollbar in navigator (don't work with blueprint Dialog)
    },
    xAxis: {
      ...defaultOptions.xAxis,
      scrollbar: {
        enabled: true, // enable scrollbar above navigator. This scrollbar is working.
      },
    },
    yAxis: {
      opposite: false, // main Axis (to the left)
      labels: {
        format,
      },
    },
    series,
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
      callback={setChart}
    />
  )
}

export default QuickChartContent
