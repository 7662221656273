import { Tooltip } from "@blueprintjs/core"
import { useField, useFormikContext } from "formik"
import { useEffect, useMemo } from "react"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { IdeaSector, IdeaStock, IdeaType } from "../types/business"
import { IdeaCreateLabel } from "./IdeaCreate.styled"
import IdeaCreateRelatedStocksSettings from "./IdeaCreateRelatedStocksSettings"
import IdeaCreateTradeSettings from "./IdeaCreateTradeSettings"
import { CursorWrapper, IdeaCreateTypeContainer } from "./IdeaCreateType.styled"
import ToggleButtonGroup from "./ToggleButtonGroup"
import { useLocation } from "react-router-dom"
interface IIdeaCreateType {
  initialSeletedStockLong?: Partial<IdeaSector> | Partial<IdeaStock>
  initialSeletedStockShort?: Partial<IdeaSector> | Partial<IdeaStock>
  tradeOpenedAt?: string
  disabled?: boolean
}

const IdeaCreateType = ({
  initialSeletedStockLong,
  initialSeletedStockShort,
  tradeOpenedAt,
  disabled,
}: IIdeaCreateType) => {
  const [ideaType] = useField("idea_type")
  const [ideaShort] = useField("trade.short.instance")
  const [ideaLong] = useField("trade.long.instance")
  const [ideaShortType] = useField("trade.short.instance.position_type")
  const [ideaLongType] = useField("trade.long.instance.position_type")
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if ([IdeaType.NOTE, IdeaType.STRUCTURAL].includes(ideaType.value)) {
      setFieldValue(ideaShort.name, undefined)
      setFieldValue(ideaLong.name, undefined)
    } else {
      setFieldValue(ideaShortType.name, "stock")
      setFieldValue(ideaLongType.name, "stock")
      setFieldValue("related_stocks", [])
    }
  }, [
    ideaShort.name,
    ideaLong.name,
    ideaType.value,
    setFieldValue,
    ideaShortType.name,
    ideaLongType.name,
  ])

  /**
   * Check wether we are creating a new revision
   */
  const { pathname } = useLocation()
  const isRevisionCreateMode = useMemo(() => pathname.includes("revision/create"), [pathname])

  return (
    /**
     * When trade has an open date, Type can't be change.
     * In this case, pointerEvents is disabled and, a tooltip and a not-allowed cursor are displayed.
     */

    <IdeaCreateTypeContainer flexDirection="column">
      <Tooltip
        content={`Type can't be modified ${
          isRevisionCreateMode ? "when creating a new revision" : "on update"
        }.`}
        placement="top"
        compact
        disabled={!tradeOpenedAt && !disabled}
      >
        <CursorWrapper isTradeOpen={!!tradeOpenedAt}>
          <FlexContainer gap="10px" alignItems="center">
            <IdeaCreateLabel>Type</IdeaCreateLabel>
            <ToggleButtonGroup
              fieldName={ideaType.name}
              items={IdeaType}
              disabled={!!tradeOpenedAt || !!disabled}
            />
          </FlexContainer>
          {ideaType.value === IdeaType.TRADE && (
            <IdeaCreateTradeSettings
              initialSeletedStockLong={initialSeletedStockLong}
              initialSeletedStockShort={initialSeletedStockShort}
              isTradeOpen={!!tradeOpenedAt}
            />
          )}
        </CursorWrapper>
      </Tooltip>
      {[IdeaType.NOTE].includes(ideaType.value) && (
        <IdeaCreateRelatedStocksSettings disabled={false} />
      )}
    </IdeaCreateTypeContainer>
  )
}

export default IdeaCreateType
