/** @jsxImportSource @emotion/react */
import { Alert, Button, Callout, H3 } from "@blueprintjs/core"
import { AgGridReact } from "ag-grid-react"
import { all, any, compose, isEmpty, not } from "ramda"
import { Fragment, useCallback, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { GridApi, GridReadyEvent, RowNode, RowValueChangedEvent } from "ag-grid-community"
import dataSetsDuck from "../../../../../state/modules/dataSets"
import { MediumText } from "../../../../../styled/text.styled"
import { capitalizeFirstLetter } from "../../../../../utils"
import { DateEditor, NumberEditor } from "../../../../_shared/agEditors"
import { DateFormatter, NumberFormatter } from "../../../../_shared/agFormatters"
import { Col, Grid, Row } from "../../../../components"
import { useApi } from "../../../../hooks/useApi"
import { defaultLength, nextEmptyEntries } from "../utils"
import {
  ErrorMessageText,
  ErrorsContentContainer,
  RowErrorContainer,
  errorAlertCss,
  errorTitleCss,
} from "./AddGrid.styled"

interface IRowData {
  comment: string | null
  dataset: number
  end: Date
  error: boolean
  id: number
  loading: boolean
  name: string
  name_for_custom: string | null
  release_date: Date
  slug: string
  source: string
  start: Date
  str_name: string
  unit_str: string
  value: number
}
interface IAddGridProps {
  dataSet: Record<
    string,
    string | number | boolean | null | Record<string, string | number | null> | any[]
  >
  lastEntry: IRowData
  setAddPage: (value: boolean) => void
}
interface IEntry {
  end: Date
  name: string
  release_date: Date
  start: Date
  value: number
}

interface IError {
  entries: IEntry[]
  errors: Record<string, string>[]
}

const AddGrid = ({ dataSet, lastEntry, setAddPage }: IAddGridProps) => {
  const { scientApi } = useApi()

  const addGridRef = useRef<AgGridReact | null>(null)
  const options = {
    reactNext: true,
    defaultColDef: {
      resizable: true,
    },
    columnDefs: [
      {
        headerName: "Name",
        field: "name",
        sortable: true,
        filter: true,
        editable: true,
      },
      {
        headerName: "Start",
        field: "start",
        sortable: true,
        filter: true,
        valueFormatter: DateFormatter,
        cellEditor: "dateEditor",
        editable: true,
      },
      {
        headerName: "Release",
        field: "release_date",
        sortable: true,
        filter: true,
        valueFormatter: DateFormatter,
        cellEditor: "dateEditor",
        cellEditorParams: {
          maxDate: new Date(), // release_date can't be in the future
        },
        editable: true,
      },
      {
        headerName: "Value",
        field: "value",
        sortable: true,
        filter: true,
        cellRenderer: NumberFormatter,
        cellEditor: "numberEditor",
        cellStyle: { textAlign: "right" },
        editable: true,
      },
    ],
    rowData:
      !dataSet.periodicity || typeof dataSet.periodicity !== "string"
        ? []
        : lastEntry
        ? nextEmptyEntries(lastEntry.name, defaultLength(dataSet.periodicity))
        : nextEmptyEntries("", defaultLength(dataSet.periodicity)),

    editType: "fullRow",
    components: {
      dateEditor: DateEditor,
      numberEditor: NumberEditor,
    },
  }

  const dispatch = useDispatch()
  const fetchDataSet = useCallback(
    (props: { id: number }) => dispatch(dataSetsDuck.actions.fetchDataSet(props)),
    [dispatch],
  )

  const [cantSubmit, setCantSubmit] = useState<boolean>(true)

  const onRowValueChanged = ({ rowIndex, data }: RowValueChangedEvent) => {
    const fields = [data.release_date, data.value]
    if (all(isEmpty, fields) || !any(isEmpty, fields)) {
      setCantSubmit(false)
    } else {
      setCantSubmit(true)
    }
  }

  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
  }

  /**
   * State to handle error(s) when submitting form
   */
  const [error, setError] = useState<IError | null>(null)

  const handleSubmit = () => {
    const data: {
      end: Date
      name: string
      release_date: Date
      start: Date
      value: number
    }[] = []

    gridApi?.forEachNode((node: RowNode) => data.push(node.data))
    const entries: IEntry[] = data.filter(data =>
      all(compose(not, isEmpty), [data.start, data.release_date, data.value]),
    )
    const form: {
      id: number
      entries: IEntry[]
    } = { id: dataSet.id as number, entries }

    scientApi.datasets.dataSets.detail
      .update(form)
      .then(() => {
        // If the update(form) is successful, fetch the updated data
        fetchDataSet({ id: dataSet.id as number })
        // And close the add page
        setAddPage(false)
      })

      // Handle the error here
      .catch(error => {
        console.log("error submitting form: ", error.response)
        setError({ entries, errors: error.response.data.entries })
      })
  }

  const sizeColumnsToFit = useCallback(() => {
    addGridRef.current?.api?.sizeColumnsToFit()
  }, [])

  return (
    <>
      {/* An alert is displayed if back-end return errors when the form is submitted */}
      <Alert
        css={errorAlertCss}
        isOpen={error ? true : false}
        onClose={() => setError(null)}
        children={
          <div>
            <H3 css={errorTitleCss}>Error submitting form</H3>
            <ErrorsContentContainer flexDirection="column">
              {error?.errors.map((obj, index) => (
                <Fragment key={index}>
                  {Object.keys(obj).length && ( //display only not empty error obj
                    <RowErrorContainer>
                      <MediumText fontWeight="bold">{error?.entries[index].name}</MediumText>
                      {Object.entries(obj).map(([key, value]) => {
                        return (
                          <ErrorMessageText key={key}>
                            {capitalizeFirstLetter(key)}: {value}
                          </ErrorMessageText>
                        )
                      })}
                    </RowErrorContainer>
                  )}
                </Fragment>
              ))}
            </ErrorsContentContainer>
          </div>
        }
      />

      <Grid>
        <Row>
          <Col xs={12}>
            <Button onClick={() => setAddPage(false)} text="Back" icon="chevron-left" />
          </Col>
        </Row>
        <Row
          style={{
            // @ts-ignored
            width: "90%",
            margin: "auto",
          }}
        >
          <Col xs={12}>
            <Callout
              icon="warning-sign"
              intent="primary"
              style={{ margin: "20px 0" }}
              title="You can add multiple entries"
            >
              Be careful, if you provide an existing entry, it will be overriden.
            </Callout>
            <div
              className="ag-theme-balham-dark"
              style={{
                height: "400px",
              }}
            >
              <AgGridReact
                ref={addGridRef}
                onGridReady={onGridReady}
                onRowValueChanged={onRowValueChanged}
                onFirstDataRendered={sizeColumnsToFit}
                {...options}
              />
            </div>
            <Button
              data-cy="SubmitAddEntry"
              disabled={cantSubmit}
              fill
              onClick={handleSubmit}
              text="Submit"
              intent="primary"
            />
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default AddGrid
