// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import generate from "project-name-generator"

import monitorsDuck from "../"
import desksDuck from "../../desks"
import sheetsDuck from "../../sheets"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { createMonitor, createMonitorOk, createMonitorError },
} = monitorsDuck

// When user adds new monitor, he wants a NGTF sheet to be create at the same time.
// const sheetPayload = monitorId => ({
//   monitor_id: monitorId,
//   kind: "ng-tf",
//   name: generate({ number: true }).dashed,
//   tf_freq: "Q",
// })
//! Changed temporary, usual is 'ngtf'
const sheetPayload = monitorId => ({
  monitor_id: monitorId,
  kind: "tf",
  name: generate({ number: true }).dashed,
  colDefs: { freq: "m" },
  rowDefs: [],
})

const createMonitorEpic = (action$, _, { ajax, auth }) => {
  return action$.pipe(
    ofType(createMonitor.type),
    mergeMap(({ payload: { name, desk } }) =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/monitors/`,
        method: "POST",
        body: { name, desk_id: desk.id },
        crossDomain: true,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(({ response }) => {
          return [
            createMonitorOk(response),
            desksDuck.actions.updateDesk({
              id: desk.id,
              layout: [
                ...desk.layout,
                { i: response.id.toString(), x: (desk.layout.length * 4) % 12, y: 0, w: 8, h: 12 },
              ],
            }),
            sheetsDuck.actions.createSheet({
              sheet: sheetPayload(response.id),
              monitor: response,
            }),
          ]
        }),
        catchError(error => {
          return of(createMonitorError(error.xhr.response))
        }),
      ),
    ),
  )
}

export default createMonitorEpic
