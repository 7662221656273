// @ts-nocheck
import React from "react"
import * as R from "ramda"
import { ButtonGroup, Button, Label } from "@blueprintjs/core"
import { Field } from "formik"
import { Row, Col } from "react-flexbox-grid"

import { FormikSelect, FormikSwitch } from "../../../../../../../../components/formik/fields"

const styleButtons = ["bold", "italic"]

export const StyleButton = ({ field, form, style }) => {
  const styles = field.value || {}

  const toggleStyle = style => {
    if (R.has(style, styles)) {
      return form.setFieldValue(field.name, R.dissoc(style, styles))
    } else {
      return form.setFieldValue(field.name, R.assoc(style, true, styles))
    }
  }
  return (
    <Button
      key={field.name}
      active={R.propOr(false, style, styles)}
      onClick={() => toggleStyle(style)}
      text={style}
    />
  )
}

export const textColorOpts = [
  { id: "wht", icon: "symbol-square", color: undefined },
  { id: "blu", icon: "symbol-square", className: "sct-front-blue", color: "blue" },
  { id: "grn", icon: "symbol-square", className: "sct-front-green", color: "green" },
  { id: "orng", icon: "symbol-square", className: "sct-front-orange", color: "orange" },
  { id: "red", icon: "symbol-square", className: "sct-front-red", color: "red" },
  { id: "indg", icon: "symbol-square", className: "sct-front-indigo", color: "indigo" },
  { id: "trqz", icon: "symbol-square", className: "sct-front-turquoise", color: "turquoise" },
  { id: "spia", icon: "symbol-square", className: "sct-front-sepia", color: "sepia" },
]

const ColorButton = ({ field, form, el }) => {
  const handleSetColor = () => form.setFieldValue(field.name, el.color)

  return (
    <Button
      key={el.id}
      icon="symbol-square"
      className={el.className}
      onClick={handleSetColor}
      active={el.color === field.value}
      disabled={el.color === field.value}
      outlined={el.color === field.value}
    />
  )
}

const backgroundOpts = [
  { label: "No focus", value: "" },
  { label: "Focus 1", value: "1" },
  { label: "Focus 2", value: "2" },
  { label: "Focus 3", value: "3" },
  { label: "Focus 4", value: "4" },
]

const StylingPalette = ({ activeItem }) => {
  return (
    <>
      <Col xs={12} lg={6}>
        <Row center="xs">
          <Col xs={12}>
            {!activeItem.isHeader && (
              <FormikSwitch
                name={`frontOpts.pct`}
                label={"In percent"}
                style={{ marginTop: "20px" }}
                large
              />
            )}
          </Col>
        </Row>
        <br />
        {activeItem.isHeader && <br />}

        <Row center="xs">
          <Col xs={7}>
            <FormikSelect
              name={`frontOpts.styles.focus`}
              labelProps={{ label: "Focus" }}
              options={backgroundOpts}
            />
          </Col>
        </Row>
      </Col>
      <br />

      <Col xs={12} lg={6}>
        <Row center="xs">
          <Col xs={7}>
            <Label>
              Style
              <ButtonGroup fill>
                {styleButtons.map(style => (
                  <Field
                    key={style}
                    name={`frontOpts.styles`}
                    style={style}
                    component={StyleButton}
                  />
                ))}
              </ButtonGroup>
            </Label>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={11}>
            <Label>
              Text
              <ButtonGroup style={{ overflowX: "auto" }} fill>
                {textColorOpts.map(el => (
                  <Field
                    key={el.id}
                    name={`frontOpts.styles.frontColor`}
                    el={el}
                    component={ColorButton}
                  />
                ))}
              </ButtonGroup>
            </Label>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default StylingPalette
