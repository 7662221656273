import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../hooks/useApi"
import { IdeaType } from "../types/business"

const useSearchIdeas = ({
  id,
  idea_type,
  stock,
  sector,
  user,
  enabled = true,
}: {
  id?: number
  idea_type?: IdeaType
  stock?: number
  sector?: number
  user?: number
  enabled: boolean
}) => {
  const { scientApi } = useApi()

  const { data, error } = useQuery(
    ["ideas-search", { id, idea_type, stock, sector, user }],
    () => scientApi.ideas.search({ id, idea_type, stock, sector, user }),
    {
      retry: 1,
      staleTime: Infinity, // Static list, no need to refresh.
      enabled: enabled,
      /**
       * we should not cache the search results
       * as creating a new Idea will change the search results
       */
      cacheTime: 0,
    },
  )

  return {
    ideas: data,
    error,
  }
}

export default useSearchIdeas
