import { AgGridReact } from "ag-grid-react"
import React, { ForwardedRef, useCallback, useEffect } from "react"
import { useResizeDetector } from "react-resize-detector"

type ExposuresGridProps = React.ComponentProps<typeof AgGridReact>

const ExposureGrid = React.forwardRef(
  (props: ExposuresGridProps, ref: ForwardedRef<AgGridReact>) => {
    const { width, ref: resizeDectectorRef } = useResizeDetector()

    const sizeColumnsToFit = useCallback(() => {
      ;(ref as React.RefObject<AgGridReact>).current?.api?.sizeColumnsToFit()
    }, [ref])

    /**
     * Every time we resize the grid, we change
     * column size to fit the actual width.
     */
    useEffect(() => {
      sizeColumnsToFit()
    }, [width, sizeColumnsToFit])

    return (
      <div ref={resizeDectectorRef} className="ag-theme-balham-dark">
        <AgGridReact ref={ref} {...props} />
      </div>
    )
  },
)

export default ExposureGrid
