// @ts-nocheck
import React, { useRef, useState } from "react"
import * as R from "ramda"
import ReactFlow, { Controls, useEdgesState, useNodesState } from "react-flow-renderer"

import { useWindowDimensions } from "../../../../../../../../hooks"
import {
  useOnConnect,
  useOnDragOver,
  useOnDrop,
  useOnEdgeUpdate,
  useOnEdgesDelete,
  useOnNodesDelete,
} from "./helpers"
import CustomEdge from "./CustomEdge"
import Node from "./Node"

export const edgeTypes = {
  default: CustomEdge,
}

const getNodeTypes = nodeDefs =>
  R.compose(
    R.map(() => Node),
    R.indexBy(R.prop("type")),
    R.prop("nodes"),
  )(nodeDefs)

const Canvas = ({ nodeDefs, rfInstance, initialRFInstance, setRfInstance }) => {
  const { height, width } = useWindowDimensions()

  const reactFlowWrapper = useRef(null)

  const [nodeTypes] = useState(getNodeTypes(nodeDefs))

  const [nodes, setNodes, onNodesChange] = useNodesState(initialRFInstance.nodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialRFInstance.edges)

  const onConnect = useOnConnect({ setNodes, setEdges })
  const onDragOver = useOnDragOver()
  const onNodesDelete = useOnNodesDelete({ nodes, setNodes, edges })
  const onEdgeUpdate = useOnEdgeUpdate({ setNodes, setEdges })
  const onEdgesDelete = useOnEdgesDelete({ nodes, setNodes })
  const onDrop = useOnDrop({ reactFlowWrapper, setNodes, rfInstance, nodeDefs })

  return (
    <div id="canvas" className="reactflow-wrapper" ref={reactFlowWrapper}>
      <ReactFlow
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onNodesDelete={onNodesDelete}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onEdgesDelete}
        onEdgeUpdate={onEdgeUpdate}
        onConnect={onConnect}
        onInit={setRfInstance}
        onDrop={onDrop}
        onDragOver={onDragOver}
        attributionPosition="bottom-left"
        defaultPosition={[width - 1000, height / 3]}
        defaultZoom={1.5}
      >
        <Controls />
      </ReactFlow>
    </div>
  )
}

export default Canvas
