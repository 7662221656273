import { nthName } from "."

const yearInPeriods = (periodicity: string) => {
  switch (periodicity) {
    case "Y":
    case undefined:
      return -1
    case "H":
      return -2
    case "Q":
      return -4
    case "BM":
      return -6
    case "M":
      return -12
    case "W":
      return -52
    case "D":
      return -365
    default:
      throw new Error("Wrong periodicity.")
  }
}

export const yearBeforeName = (name: string) => {
  const [[, , periodicity]] = name.matchAll(/(\d{4})(Y|H|Q|M|BM|M|W|D|R)?(\d+)?/g)

  return nthName(name, yearInPeriods(periodicity))
}
