// @ts-nocheck
import autodux from "autodux"
import {
  append,
  dec,
  evolve,
  findIndex,
  inc,
  lensPath,
  mergeDeepLeft,
  over,
  propEq,
  remove,
} from "ramda"

const stockDetailDuck = autodux({
  slice: "stockDetail",
  initial: {},
  actions: {
    fetchStock: (state, { slug }) => ({
      ...state,
      [slug]: {
        stockFetching: true,
        stockError: null,
        stockData: null,
      },
    }),
    fetchStockOk: (state, { slug, stockData }) => ({
      ...state,
      [slug]: { stockData },
    }),
    fetchStockError: (state, { slug, stockError }) => ({
      ...state,
      [slug]: { stockError },
    }),

    setAddSlug: (state, { slug }) => ({
      ...state,
      [slug]: {
        ...state[slug],
        addSlug: true,
      },
    }),
    clearAddSlug: (state, { slug }) => ({
      ...state,
      [slug]: {
        ...state[slug],
        addSlug: false,
      },
    }),
    setEditSlug: (state, { slug, reportSlug }) => ({
      ...state,
      [slug]: {
        ...state[slug],
        editSlug: reportSlug,
      },
    }),
    clearEditSlug: (state, { slug }) => ({
      ...state,
      [slug]: {
        ...state[slug],
        editSlug: null,
      },
    }),

    fetchReports: (state, { slug }) => ({
      ...state,
      [slug]: {
        ...state[slug],
        reportFetching: true,
        reportError: null,
        reportData: null,
      },
    }),
    fetchReportsOk: (state, { slug, reportData }) => ({
      ...state,
      [slug]: {
        ...state[slug],
        reportFetching: null,
        reportData,
      },
    }),
    fetchReportsError: (state, { slug, reportError }) => ({
      ...state,
      [slug]: {
        ...state[slug],
        reportFetching: null,
        reportError,
      },
    }),

    createReport: (state, { values }) => {
      const stkSlug = values.stock
      const reportDataPath = lensPath([stkSlug, "reportData"])
      return over(
        reportDataPath,
        evolve({
          count: inc,
          results: append(values),
        }),
        state,
      )
    },
    updateReport: (state, { values }) => {
      const stkSlug = values.stock
      const index = findIndex(propEq("slug", values.slug), state[stkSlug].reportData.results)
      const reportPath = lensPath([stkSlug, "reportData", "results", index])
      return over(reportPath, mergeDeepLeft(values), state)
    },
    deleteReport: (state, { stkSlug, rptSlug }) => {
      const reportDataPath = lensPath([stkSlug, "reportData"])
      const index = findIndex(propEq("slug", rptSlug), state[stkSlug].reportData.results)
      return over(
        reportDataPath,
        evolve({
          count: dec,
          results: remove(index, 1),
        }),
        state,
      )
    },
  },
})

export default stockDetailDuck
