// @ts-nocheck
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

export const MessageBubbleContainer = styled(FlexContainer)({
  padding: "15px 1% 15px 1.5%",
  /**
   * Need 'contentVisibility: auto' for infinite scroll to show all messages.
   * If not, with big list the first part of the list do not displayed correctly.
   *  We can see the div containing message but there're empty.
   */
  contentVisibility: "auto",
})

export const MessageBody = styled.p({
  fontSize: "14px",
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
  hyphens: "auto",
  margin: 0,
  padding: "10px 16px",
})

export const DateDistance = styled(FlexContainer)({
  fontSize: "13px",
  color: SCIENT_COLORS.gray4,
  marginRight: "50%",
  width: "60px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})

export const UserInfo = styled.p({
  margin: "0 0 2px 16px",
  fontSize: "14px",
  color: SCIENT_COLORS.gray4,
})

export const BodyContainer = styled(FlexContainer)({
  marginTop: "4px",
  marginRight: "10%",
  marginLeft: "5px",
  backgroundColor: SCIENT_COLORS.gray1,
  borderRadius: "12px 12px 12px 0",
})

export const ConnectedUserBodyContainer = styled(FlexContainer)({
  marginTop: "4px",
  marginLeft: "10%",
  marginRight: "5px",
  backgroundColor: SCIENT_COLORS.blue3,
  borderRadius: "12px 12px 0 12px",
  position: "relative",
})

export const UpdateMessageIconContainer = styled(FlexContainer)({
  cursor: "pointer",
  width: "22px",
  height: "22px",
  position: "absolute",
  right: "-10px",
  top: "-10px",
  borderRadius: "50%",
  backgroundColor: SCIENT_COLORS.lightGray4,
  border: `1px solid ${SCIENT_COLORS.lightGray1}`,
})
