// @ts-nocheck
/** @jsxImportSource @emotion/react */

import { Button, H4 } from "@blueprintjs/core"
import React from "react"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../../state/modules/sheets"
import { buttonCss, LaunchProcessingContainer } from "./LaunchProcessing.styled"

/**
 * This component is used for clone sheet.
 * When a sheet is cloned, processing for this new sheet must be set to pause.
 * And to get the data, pause processing needs to become false.
 * So processing_output is launched.
 * Nota: if processing_output is directly set to true,
 *       without pass by the pause processing, it doesn't work.
 *  */

export const LaunchProcessing = ({ sheet }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        pause_processing: false,
      }),
    )
  }

  return (
    <LaunchProcessingContainer>
      <H4>To get data in this cloned sheet, </H4>
      <H4>Click to launch processing.</H4>
      <Button
        text="Lauch processing"
        icon="rocket"
        onClick={handleClick}
        large
        intent="primary"
        css={buttonCss}
      />
    </LaunchProcessingContainer>
  )
}
