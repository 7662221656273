import { AxiosInstance, AxiosResponse } from "axios"

export interface IDsForecastsApi {
  list: {
    create: (values: any) => Promise<AxiosResponse<any, any>>
  }
  detail: {
    update: ({ id, values }: any) => Promise<AxiosResponse<any, any>>
  }
}

/**
 * Api routes for /api/v1/dsforecasts/{dataset}/dsforecasts
 */
const dsForecasts = (axiosClient: AxiosInstance): IDsForecastsApi => ({
  /**
   * POST /api/v1/dsforecasts/{dataset}/dsforecasts/
   */
  list: {
    create: (values: any) => axiosClient.post(`/datasets/${values.dataset}/dsforecasts/`, values),
  },
  /**
   * PATCH /api/v1/dsforecasts/{dataset}/dsforecasts/{id}
   */
  detail: {
    update: ({ id, values }: any) =>
      axiosClient.patch(`/datasets/${values.dataset}/dsforecasts/${id}/`, values),
  },
})

export default dsForecasts
