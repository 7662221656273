// @ts-nocheck
import React from "react"
import { useDispatch } from "react-redux"
import { Button, ButtonGroup } from "@blueprintjs/core"

import sheetsDuck from "../../../../../state/modules/sheets"

export const FreqButton = ({ sheet }) => {
  const dispatch = useDispatch()

  const updateFreq = freq => {
    return dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        colDefs: { ...sheet.colDefs, freq: freq },
      }),
    )
  }

  const { freq } = sheet.colDefs

  return (
    <>
      <ButtonGroup style={{ margin: "0 50px" }}>
        <Button onClick={() => updateFreq("w")} active={freq === "w"} text="W" />
        <Button onClick={() => updateFreq("m")} active={freq === "m"} text="M" />
        <Button onClick={() => updateFreq("q")} active={freq === "q"} text="Q" />
        <Button onClick={() => updateFreq("h")} active={freq === "h"} text="H" />
        <Button onClick={() => updateFreq("fy")} active={freq === "fy"} text="Y" />
      </ButtonGroup>
    </>
  )
}
