// @ts-nocheck
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import * as R from "ramda"
import {
  Button,
  ButtonGroup,
  Checkbox,
  Classes,
  Divider,
  H4,
  HTMLTable,
  Popover,
} from "@blueprintjs/core"
import Highcharts from "highcharts/highstock"

import { Select } from "../../../../../components"
import sheetsDuck from "../../../../../../state/modules/sheets"
import { SCIENT_COLORS } from "../../../../../../styled/scientColors"

const ChartHeader = ({ sheet }) => {
  const [popover, setPopover] = useState(false)
  const toggleChartPopover = () => setPopover(!popover)

  const { id, rowDefs } = sheet

  const [newRowDefs, setNewRowDefs] = useState(rowDefs)

  // Reject title's rows
  const rowDefsWithoutTitle = R.reject(row => !row.formula, rowDefs)

  const dispatch = useDispatch()

  const handleChartPreferences = () => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id,
        rowDefs: newRowDefs,
      }),
    )
    toggleChartPopover()
  }

  const colors = [
    { label: "default", value: "" },
    { label: "blue", value: SCIENT_COLORS.blue5 },
    { label: "green", value: SCIENT_COLORS.green5 },
    { label: "orange", value: SCIENT_COLORS.orange5 },
    { label: "red", value: SCIENT_COLORS.red5 },
    { label: "indigo", value: SCIENT_COLORS.indigo5 },
    { label: "turquoise", value: SCIENT_COLORS.turquoise5 },
    { label: "sepia", value: SCIENT_COLORS.sepia5 },
  ]

  const styles = [
    { label: "solid", value: "solid" },
    { label: "shortDot", value: "shortDot" },
    { label: "dot", value: "dot" },
    { label: "dash", value: "shortDash" },
    // { label: "dash", value: "dash" },
    // { label: "longDash", value: "longDash" },
    // { label: "dashDot", value: "dashDot" },
  ]

  // logic to control chart props of all rows at the same time
  const ownYAxisForAll = () => {
    const newRD = R.map(
      e => R.over(R.lensPath(["frontOpts"]), R.assoc("chartOwnAxis", true), e),
      newRowDefs,
    )
    return setNewRowDefs(newRD)
  }
  const YAxisShared = () => {
    const newRD = R.map(
      e => R.over(R.lensPath(["frontOpts"]), R.assoc("chartOwnAxis", false), e),
      newRowDefs,
    )
    return setNewRowDefs(newRD)
  }
  const hideAllLines = () => {
    const newRD = R.map(
      e => R.over(R.lensPath(["frontOpts"]), R.assoc("chartHideLine", true), e),
      newRowDefs,
    )
    return setNewRowDefs(newRD)
  }
  const viewAllLines = () => {
    const newRD = R.map(
      e => R.over(R.lensPath(["frontOpts"]), R.assoc("chartHideLine", false), e),
      newRowDefs,
    )
    return setNewRowDefs(newRD)
  }

  // put chartOwnAxis value for each row in an array with uniq values
  const ownAxisValuesList = R.compose(
    R.uniq,
    R.map(e => e.frontOpts.chartOwnAxis),
  )(newRowDefs)
  // test if ownAxisValuesList have one or two values. If only one: all row have the same chartOwnAxis value. If two (ie true and false):  rows have differents  chartOwnAxis values
  const isAllOwnAxis =
    ownAxisValuesList.length === 1
      ? ownAxisValuesList[0] === true
        ? true
        : false
      : "indeterminate"

  // put chartHideLine value for each row in an array with uniq values
  const hideValuesList = R.compose(
    R.uniq,
    R.map(e => e.frontOpts.chartHideLine),
  )(newRowDefs)
  // test if hideValuesList have one or two values. If only one: all row have the same chartHideLine value. If two (ie true and false):  rows have differents  chartHideLine values
  const isAllHide =
    hideValuesList.length === 1 ? (hideValuesList[0] === true ? true : false) : "indeterminate"

  return (
    <ButtonGroup style={{ margin: "0 0 0 10px" }}>
      <Popover
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        isOpen={popover}
        placement="bottom-end"
        content={
          <div>
            <H4>Chart preferences</H4>

            <HTMLTable striped className="sct-table">
              <thead className="sct-thead ">
                <tr>
                  <th className="sct-th-name">Name</th>
                  <th className="sct-th-select">Color</th>
                  <th className="sct-th-select">Style</th>
                  <th className="sct-th-checkbox">Own Y axis</th>
                  <th className="sct-th-checkbox">Hide line</th>
                </tr>
              </thead>
              <tbody className="sct-tbody-overflow">
                <tr>
                  <td>All</td>
                  <td></td>
                  <td></td>
                  <td>
                    <Checkbox
                      label="off"
                      onChange={YAxisShared}
                      checked={isAllOwnAxis === false}
                      indeterminate={isAllOwnAxis === "indeterminate"}
                      style={{ paddingLeft: "52px" }}
                    />
                    <Checkbox
                      label="on"
                      onChange={ownYAxisForAll}
                      checked={isAllOwnAxis === true}
                      indeterminate={isAllOwnAxis === "indeterminate"}
                      style={{ paddingLeft: "52px" }}
                    />
                  </td>
                  <td>
                    <Checkbox
                      label="off"
                      onChange={viewAllLines}
                      checked={isAllHide === false}
                      indeterminate={isAllHide === "indeterminate"}
                      style={{ paddingLeft: "52px" }}
                    />
                    <Checkbox
                      label="on"
                      onChange={hideAllLines}
                      checked={isAllHide === true}
                      indeterminate={isAllHide === "indeterminate"}
                      style={{ paddingLeft: "52px" }}
                    />
                  </td>
                </tr>

                {newRowDefs.map((row, index) => {
                  const chartOwnAxis = R.pathOr(false, ["frontOpts", "chartOwnAxis"], row)
                  const chartColor = R.pathOr("", ["frontOpts", "chartColor"], row)
                  const chartDashStyle = R.pathOr("solid", ["frontOpts", "chartDashStyle"], row)
                  const chartHideLine = R.pathOr(false, ["frontOpts", "chartHideLine"], row)

                  // Need this index to colorized name with same color than line when default color active
                  const indexForColor = R.findIndex(R.propEq("rowId", row.rowId))(
                    rowDefsWithoutTitle,
                  )

                  const handleChartColor = ({ currentTarget: { value } }) => {
                    const newRD = R.over(
                      R.lensPath([index, "frontOpts"]),
                      R.assoc("chartColor", value),
                      newRowDefs,
                    )
                    return setNewRowDefs(newRD)
                  }
                  const handleChartDashStyle = ({ currentTarget: { value } }) => {
                    const newRD = R.over(
                      R.lensPath([index, "frontOpts"]),
                      R.assoc("chartDashStyle", value),
                      newRowDefs,
                    )
                    return setNewRowDefs(newRD)
                  }

                  const handleChartOwnAxis = ({ currentTarget: { checked } }) => {
                    const newRD = R.over(
                      R.lensPath([index, "frontOpts"]),
                      R.assoc("chartOwnAxis", checked),
                      newRowDefs,
                    )
                    return setNewRowDefs(newRD)
                  }

                  const handleChartHideLine = ({ currentTarget: { checked } }) => {
                    const newRD = R.over(
                      R.lensPath([index, "frontOpts"]),
                      R.assoc("chartHideLine", checked),
                      newRowDefs,
                    )
                    return setNewRowDefs(newRD)
                  }

                  return (
                    <React.Fragment key={row.rowId}>
                      {!R.isEmpty(row.formula) && (
                        <tr key={row.rowId}>
                          <td
                            className="sct-td-name"
                            style={{
                              color: chartColor
                                ? chartColor
                                : Highcharts.getOptions().colors[indexForColor],
                            }}
                          >
                            {row.frontOpts.name}
                          </td>
                          <td className="sct-td-select">
                            <Select
                              selectProps={{
                                options: colors,
                                value: chartColor,
                                onChange: handleChartColor,
                              }}
                            />
                          </td>
                          <td className="sct-td-select">
                            <Select
                              selectProps={{
                                options: styles,
                                value: chartDashStyle,
                                onChange: handleChartDashStyle,
                              }}
                            />
                          </td>
                          <td className="sct-td-checkbox">
                            <Checkbox onChange={handleChartOwnAxis} checked={chartOwnAxis} />
                          </td>
                          <td className="sct-td-checkbox">
                            <Checkbox onChange={handleChartHideLine} checked={chartHideLine} />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </HTMLTable>
            <Divider style={{ marginBottom: "8px" }} />
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button icon="cross" text="Cancel" onClick={toggleChartPopover} />
                <Button
                  intent="primary"
                  icon="floppy-disk"
                  text="Save"
                  onClick={handleChartPreferences}
                />
              </div>
            </div>
          </div>
        }
      >
        <Button icon="series-configuration" onClick={toggleChartPopover} />
      </Popover>
    </ButtonGroup>
  )
}

export default ChartHeader
