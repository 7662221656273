// @ts-nocheck
import { of } from "rxjs"
import { map, catchError, switchMap } from "rxjs/operators"
import { ofType } from "redux-observable"

import { fetchFolders, fetchFoldersOk, fetchFoldersError } from "../../index"
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const fetchFoldersEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(fetchFolders.type),
    switchMap(() => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/analysis/folders/`
      return ajax({
        url,
        method: "GET",
      }).pipe(
        map(({ response }) => {
          return fetchFoldersOk({ folders: response.results })
        }),
        catchError(error => of(fetchFoldersError({ error: error.xhr.response }))),
      )
    }),
  )

export default fetchFoldersEpic
