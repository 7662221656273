// @ts-nocheck
import { dissoc, update } from "ramda"

// It updates rowTree when editing an item (single or group).
const setActiveItem = (object, activeItem) => {
  if (object.temp_id === activeItem.temp_id) {
    if (activeItem.kind === "single") {
      return activeItem
    } else {
      // Update path with the new_temp_id
      const newPath = update(activeItem.path.length - 1, activeItem.new_temp_id, activeItem.path)
      return {
        ...dissoc("new_temp_id", activeItem),
        temp_id: activeItem.new_temp_id,
        path: newPath,
      }
    }
  }

  if (object.children)
    object.children = object.children.map(child => setActiveItem(child, activeItem))
  return object
}

export const updateRowTree = ({ rowTree, activeItem }) =>
  rowTree.map(object => setActiveItem(object, activeItem))
