// @ts-nocheck
export const formatNumber = (value, pct, multiplyBy100 = true, precision = 0.1) => {
  if (value === null || value === undefined) {
    return ""
  }
  const multiplier = pct && multiplyBy100 ? 100 / precision : 1 / precision
  const divider = 1 / precision

  const cleanedValue = Math.round(value * multiplier) / divider
  const cleanedString = cleanedValue.toLocaleString("en-UK", { maximumFractionDigits: 6 })

  return pct ? cleanedString + "%" : cleanedString
}
