// @ts-nocheck
import React from "react"
import { Checkbox, Label } from "@blueprintjs/core"
import { Field } from "formik"

import { Select } from "../../../../../../components"

const heatmapOpts = [
  { label: "None", value: "" },
  { label: "Vs. Average (red-green)", value: "avgGreen" },
  { label: "Vs. Average (green-red)", value: "avgRed" },
  { label: "Vs. Average (blue-red)", value: "avgBlue" },
  { label: "Vs. Zero (red-green)", value: "zeroGreen" },
  { label: "Vs. Zero (green-red)", value: "zeroRed" },
  { label: "Vs. Zero (blue-red)", value: "zeroBlue" },
]

const AllRowsCheckbox = ({ field, form }) => {
  const handleChange = () => {
    if (field.value === true) return form.setFieldValue(field.name, false)
    return form.setFieldValue(field.name, true)
  }
  return (
    <Checkbox checked={field.value === true} label="Apply to all rows" onChange={handleChange} />
  )
}

const HeatmapSelect = ({ field }) => {
  return (
    <Select
      selectProps={{
        options: heatmapOpts,
        ...field,
      }}
    />
  )
}

const FormikHeatmap = ({ index }) => (
  <Label>
    Heatmap
    <Field
      key={`value${index}`}
      name={`colDefs.cols[${index}].frontOpts.heatmap.type`}
      component={HeatmapSelect}
    />
    <Field
      key={`kind${index}`}
      name={`colDefs.cols[${index}].frontOpts.heatmap.allRows`}
      component={AllRowsCheckbox}
    />
  </Label>
)

export default FormikHeatmap
