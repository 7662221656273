import { HTMLSelect } from "@blueprintjs/core"
import { SerializedStyles } from "@emotion/react"
import styled from "@emotion/styled"

interface StyledSelectProps {
  customCss?: SerializedStyles // no camelCase here because Warning: React does not recognize the `customCss` prop on a DOM element.
}

/**
 * Here we can pass css prop following this model:
 *
 *      css({
 *        color: white;
 *      })
 *
 * Use 'shouldForwardProp' to prevent styling props from being passed down
 * to the real element in the DOM tree as attribute and to prevent create invalid attribute
 */
export const StyledSelect = styled(HTMLSelect, {
  shouldForwardProp: prop => prop !== "customCss",
})<StyledSelectProps>(({ customCss }) => customCss || {})
