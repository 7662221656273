// @ts-nocheck
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { filter, pathOr, toLower, values } from "ramda"
import { MenuItem } from "@blueprintjs/core"

import { Suggest } from "../../../../../components"
import { useGetWritePermission } from "../../../utils"
import { useAuth } from "../../../../../hooks/"

/**
 * User can clone sheet in their own Desks or in shared Desks with write permission,
 * but not in desks with only read permission.
 */
export const DeskSuggest = ({ deskCible, setDeskCible }) => {
  const { profile } = useAuth()
  const desks = useSelector(pathOr({}, ["desks", "desks", "data"]))

  const userId = profile.id
  const HasWritePermission = desk => useGetWritePermission(desk)
  const deskList = filter(
    desk => desk.owner_id === userId || HasWritePermission(desk),
    values(desks),
  )
  const [deskResults, setDeskResults] = useState(deskList)

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        text={item.name}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }

  const updateDeskSearch = query => {
    if (query === "") {
      setDeskResults(deskList)
    }
    const selection = deskList.filter(desk => {
      const lowerQ = toLower(query)
      const titleIncludesQ = toLower(desk.name).includes(lowerQ)
      return titleIncludesQ
    })
    setDeskResults(selection)
  }

  return (
    <Suggest
      placeholder={deskCible ? deskCible.name : "Choose desk"}
      resetOnClose={true}
      resetOnSelect={true}
      items={deskResults}
      onItemSelect={item => setDeskCible(item)}
      selectedItem={deskCible}
      onQueryChange={updateDeskSearch}
      itemRenderer={itemRenderer}
      large={false}
    />
  )
}
