// @ts-nocheck
import React from "react"
import { useDispatch } from "react-redux"
import * as R from "ramda"
import { Formik } from "formik"
import { DndProvider } from "react-dnd"
import Backend from "react-dnd-html5-backend"

import { randomid } from "../../../../../../../utils"
import sheetsDuck from "../../../../../../../state/modules/sheets"
import { handleError, NonFieldErrors } from "../../../../../../components/formik"
import { Loader } from "../../../../../../components"
import { getFormulaItems, TfRowDefsSchema } from "../../utils"
import Rows from "./Rows"
import { useApi } from "../../../../../../hooks/useApi"

const initialRow = () => ({
  active: true,
  formula: "",
  frontOpts: { name: "", decimals: { min: 0, max: 2 }, formulaItems: [] },
  rowId: randomid(),
})

const Content = ({ sheet, editRowItems, setEditRowItems }) => {
  const { scientApi } = useApi()

  const rowDefs = R.compose(
    R.map(R.mergeDeepRight({ frontOpts: { decimals: { min: 0, max: 0 } } })),
    editRowItems.add
      ? R.insert(editRowItems.rowIndex, initialRow())
      : R.adjust(editRowItems.rowIndex, R.assoc("active", true)),
  )(sheet.rowDefs)

  // colDefs with key 'formulaItems' for formula item (formulaIems is an object with keys item and itemId)
  const formatedRowDefs = R.map(
    row => ({
      ...row,
      frontOpts: { ...row.frontOpts, formulaItems: getFormulaItems(row.formula) },
    }),
    rowDefs,
  )

  const handleClose = () => setEditRowItems({ show: false })

  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{ rowDefs: formatedRowDefs }}
      validationSchema={TfRowDefsSchema}
      onSubmit={(rawValues, { status, setStatus, setSubmitting }) => {
        const rowDefs = rawValues.rowDefs
        const newRowDefs = R.compose(
          R.map(row => R.dissocPath(["frontOpts", "formulaItems"], row)),
          R.map(row =>
            row.frontOpts.formulaItems
              ? {
                  ...row,
                  formula: R.compose(
                    R.join(" "),
                    R.map(e => e.item),
                  )(row.frontOpts.formulaItems),
                }
              : row,
          ),
          R.map(R.dissoc("active")),
        )(rowDefs)
        const values = { id: sheet.id, rowDefs: newRowDefs }

        setSubmitting(true)
        scientApi.desks.sheets.detail
          .update({ values })
          .then(() => {
            setSubmitting(false)
            dispatch(sheetsDuck.actions.fetchSheetDetail({ id: sheet.id }))
            handleClose()
          })
          .catch(e => handleError(setSubmitting, status, setStatus, e))
      }}
    >
      {({ values, setValues, status, isValid, isSubmitting }) => {
        return (
          <div>
            {isSubmitting && <Loader />}
            <NonFieldErrors status={status} />

            <DndProvider backend={Backend}>
              <Rows
                values={values}
                setValues={setValues}
                initialRow={initialRow}
                handleClose={handleClose}
                isValid={isValid}
              />
            </DndProvider>
          </div>
        )
      }}
    </Formik>
  )
}

export default Content
