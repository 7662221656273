// @ts-nocheck
import React from "react"
import { Alert } from "@blueprintjs/core"

export const DeleteAlert = ({
  deleteAlert,
  setDeleteAlert,
  handleDelete,
  deleteMessage,
  canNotDelete,
}) => (
  <Alert
    className="bp5-dark"
    canEscapeKeyCancel={true}
    canOutsideClickCancel={true}
    icon="trash"
    intent={canNotDelete ? "primary" : "danger"}
    isOpen={deleteAlert}
    onCancel={() => setDeleteAlert(false)}
    cancelButtonText={!canNotDelete && "Cancel"}
    confirmButtonText={canNotDelete ? "Cancel" : "Delete"}
    onConfirm={() => (canNotDelete ? setDeleteAlert(false) : handleDelete(deleteAlert))}
  >
    {deleteMessage}
  </Alert>
)
