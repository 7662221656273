import { getIn, useField, useFormikContext } from "formik"
import { IdeaCreateLabel } from "./IdeaCreate.styled"
import { Alert, Button, ButtonGroup, Callout, Intent, Tab, Tabs, Tooltip } from "@blueprintjs/core"
import { IdeaCreateStockOrSectorContainer } from "./IdeaCreateRelatedStockOrSector.styled"
import { useEffect, useMemo, useState } from "react"
import { sectorTabsCss } from "./SectorTabs.styled"
import SectorPanel from "./SectorPanel"
import useSectors from "../hooks/useSectors"
import { IIdeaCreateForm } from "./IdeaCreate"
import IdeaCreateRelatedStocksSettings from "./IdeaCreateRelatedStocksSettings"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { useLocation, useNavigate } from "react-router-dom"
import useSearchIdeas from "../hooks/useSearchIdeas"
import { useGlobalState } from "../../../hooks/useGlobalState"
import { MediumText } from "../../../../styled/text.styled"
import { SCIENT_ROUTES } from "../../../Routes"
import { useAuth } from "../../../hooks"

enum RelatedStockOrSectorSelectedTab {
  STOCK = "Stock",
  SECTOR = "Sector",
}

interface IRelatedStockOrSectorProps {
  disabled: boolean
}

const IdeaCreateRelatedStockOrSector = ({ disabled }: IRelatedStockOrSectorProps) => {
  /**
   * Represent all the different levels for a sector
   */
  const sectorTabs = [
    { id: 4, title: "Indexes" },
    { id: 1, title: "Level 1" },
    { id: 2, title: "Level 2" },
    { id: 3, title: "Level 3" },
  ]
  const { initialValues, errors, setFieldValue } = useFormikContext<Partial<IIdeaCreateForm>>()
  const { profile } = useAuth()

  const navigate = useNavigate()

  const [relatedSectorField] = useField("related_sector.id")
  const [relatedStockField] = useField("related_stocks[0].id")
  const [typeField] = useField("idea_type")

  const { ideas: ideasFound } = useSearchIdeas({
    stock: relatedStockField.value,
    sector: relatedSectorField.value,
    user: profile?.id,
    idea_type: typeField.value,

    /**
     * Search enabled only when either related stock or sector is set
     * Also disabled when editing or creating a new revision
     * as editing stock or sector is not allowed in these cases
     */
    enabled:
      (!!relatedSectorField.value || !!relatedStockField.value) && !!profile?.id && !disabled,
  })

  /**
   * Since we access fields via useField
   * we need to handle setting the other field to undefined
   * as there can only be just one stock or one sector
   */
  useEffect(() => {
    if (relatedSectorField.value) {
      setFieldValue("related_stocks", [])
    } else if (relatedStockField.value) {
      setFieldValue("related_sector", undefined)
    }
  }, [relatedSectorField.value, relatedStockField.value, setFieldValue])

  const [selectedTab, setSelectedTab] = useState<RelatedStockOrSectorSelectedTab>(
    initialValues.related_sector
      ? RelatedStockOrSectorSelectedTab.SECTOR
      : RelatedStockOrSectorSelectedTab.STOCK,
  )
  const { sectors } = useSectors()

  const [selectedTabId, setSelectedTabId] = useState(4)
  const [sectorTabsTouched, setSectTabsTouched] = useState(false)

  useEffect(() => {
    if (!sectorTabsTouched && initialValues.related_sector && initialValues.related_sector.id) {
      const level = sectors?.find(
        sector => initialValues.trade && sector.id === initialValues.related_sector?.id,
      )?.level
      level && setSelectedTabId(level)
    }
  }, [initialValues.related_sector, initialValues.trade, sectorTabsTouched, sectors])

  /**
   * Check wether we are creating a new revision
   */
  const { pathname } = useLocation()
  const isRevisionCreateMode = useMemo(() => pathname.includes("revision/create"), [pathname])

  /**
   * State for validation alert on creating a new Struct Idea
   * that already exists for the same stock/sector
   */
  const [alertState, setAlertState] = useState({
    canEscapeKeyCancel: true,
    canOutsideClickCancel: false,
    isLoading: false,
    isOpen: false,
    isOpenError: false,
    willLoad: false,
  })

  /**
   * When we detect that an idea already exists for the same stock/sector
   * we alert the user to create a new revision or cancel the creation
   * by clearing the fields
   */
  useEffect(() => {
    if (ideasFound && ideasFound.length > 0) {
      setAlertState(alertState => {
        return { ...alertState, isOpen: true }
      })
    }
  }, [ideasFound])

  return (
    <>
      <Tooltip
        content={`Related Stock or Sector can't be modified ${
          isRevisionCreateMode ? "when creating a new revision" : "on update"
        }.`}
        placement="top"
        compact
        disabled={!disabled}
      >
        <IdeaCreateStockOrSectorContainer flexDirection="column" justifyContent="center" gap="10px">
          <IdeaCreateLabel>Related Stock or Sector</IdeaCreateLabel>
          <ButtonGroup fill>
            <Button
              active={selectedTab === RelatedStockOrSectorSelectedTab.STOCK}
              onClick={() => setSelectedTab(RelatedStockOrSectorSelectedTab.STOCK)}
              disabled={disabled}
            >
              {RelatedStockOrSectorSelectedTab.STOCK}
            </Button>
            <Button
              active={
                selectedTab === RelatedStockOrSectorSelectedTab.SECTOR ||
                !!initialValues.related_sector
              }
              onClick={() => setSelectedTab(RelatedStockOrSectorSelectedTab.SECTOR)}
              disabled={disabled}
            >
              {RelatedStockOrSectorSelectedTab.SECTOR}
            </Button>
          </ButtonGroup>
          {selectedTab === RelatedStockOrSectorSelectedTab.STOCK && (
            <IdeaCreateRelatedStocksSettings disabled={disabled} />
          )}
          {
            /**
             * Taken back from sector selection
             * would need rework
             **/
            selectedTab === RelatedStockOrSectorSelectedTab.SECTOR && sectors && (
              <Tabs
                id="sectors"
                css={sectorTabsCss}
                selectedTabId={selectedTabId}
                onChange={newTabId => {
                  setSectTabsTouched(true)
                  setSelectedTabId(newTabId as number)
                }}
              >
                {sectorTabs.map(({ id, title }) => {
                  return (
                    <Tab
                      id={id}
                      key={id}
                      title={title}
                      panel={
                        <SectorPanel
                          sectors={sectors.filter(sector => sector.level === id)}
                          name={relatedSectorField.name}
                          disabled={disabled}
                        />
                      }
                    />
                  )
                })}
              </Tabs>
            )
          }
          {(getIn(errors, "related_stocks") || getIn(errors, "related_sector")) && (
            <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
              {`Related Stock or Sector is a required field`}
            </Callout>
          )}
        </IdeaCreateStockOrSectorContainer>
      </Tooltip>
      <Alert
        {...alertState}
        cancelButtonText="Cancel"
        confirmButtonText="Create New Revision"
        icon="lightbulb"
        intent={Intent.WARNING}
        onCancel={() => {
          setFieldValue("related_stocks", [])
          setFieldValue("related_sector", undefined)
          setAlertState(alertState => {
            return { ...alertState, isOpen: false }
          })
        }}
        onConfirm={() => {
          setAlertState(alertState => {
            return { ...alertState, isOpen: false }
          })
          // Always true in this case
          if (ideasFound) {
            navigate(`${SCIENT_ROUTES.IDEAS}/${ideasFound[0].id}/revision/create`)
          }
        }}
      >
        <MediumText color="black">
          You already created a structural Idea for the stock/sector you selected. You cannot have
          multiple Structural Ideas for the same stock/sector. Would you like to create a new
          revision instead?
        </MediumText>
      </Alert>
    </>
  )
}

export default IdeaCreateRelatedStockOrSector
