import styled from "@emotion/styled"
import { CardContainer } from "../../../../../styled/layout.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

export const ParentContainer = styled(CardContainer)({
  width: "100%",
  maxHeight: "45%",
  borderRadius: "0 0 5px 5px",
  paddingBottom: "10px",
})

export const CreateMessageContainer = styled(CardContainer)({
  width: "98%",
  height: "100%",
  border: `1.5px solid ${SCIENT_COLORS.gray1}`,
  "&:focus-within": {
    border: `1.5px solid ${SCIENT_COLORS.gray4}`,
  },
})

export const SendContainer = styled(CardContainer)({
  backgroundColor: SCIENT_COLORS.darkGray3,
  borderRadius: "0 0 5px 5px",
  height: "32px",
})

export const KeyIconContainer = styled.span({
  border: "1.5px solid #232e37",
  borderRadius: "5px",
})

export const LoadingMessage = styled.p({
  fontSize: "12px",
  color: SCIENT_COLORS.gray3,
  margin: "10px",
})
