import { datesFromName, dateToPeriod, nthDate } from "."

export const nextEmptyEntries = (initialName: string, numOfEntries: number) => {
  const iterator = Array.from(Array(numOfEntries), (_, x) => x + 1)

  if (initialName === "") return iterator.map(() => ({ start: "", end: "", name: "", value: "" }))

  const [[, , periodicity]] = initialName.matchAll(/(\d{4})(Y|H|Q|M|BM|M|W|D|R)?(\d+)?/g)

  if (periodicity === "R") return iterator.map(() => ({ start: "", end: "", name: "", value: "" }))

  const initialStart = datesFromName(initialName)?.start

  if (initialStart) {
    const entries = iterator.map(el => ({
      ...dateToPeriod({ start: nthDate(initialStart, periodicity, el), periodicity }),
      value: "",
    }))

    return entries
  } else {
    throw new Error("No initial start date")
  }
}
