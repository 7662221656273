// @ts-nocheck
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export const LaunchProcessingContainer = styled.div({
  margin: "50px auto",
  height: "20%",
  width: "50%",
  textAlign: "center",
})

export const buttonCss = css({
  marginTop: "15px",
})
