import { Button, Classes, Dialog, Intent } from "@blueprintjs/core"

interface IConfirmDialogProps {
  title: string
  dialogText: string
  confirmButtonIntentColor?: Intent
  confirmButtonText: string
  open: boolean
  close: () => void
  handleClick: () => void
}

export const ConfirmDialog = ({
  title,
  dialogText,
  confirmButtonIntentColor = Intent.PRIMARY,
  confirmButtonText,
  open,
  close,
  handleClick,
}: IConfirmDialogProps) => (
  <Dialog
    title={title}
    className="bp5-dark"
    isOpen={open}
    isCloseButtonShown={false}
    onClose={close}
  >
    <div className={Classes.DIALOG_BODY}>
      <p>{dialogText}</p>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button onClick={close} text="Cancel" />
        <Button
          onClick={handleClick}
          intent={confirmButtonIntentColor}
          text={confirmButtonText || "Confirm"}
        />
      </div>
    </div>
  </Dialog>
)
