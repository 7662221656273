// @ts-nocheck
import React from "react"
import { Grid as RFGGrid, Row as RFGRow, Col as RFGCol } from "react-flexbox-grid"

export const Grid = ({ children, ...rest }) => (
  <RFGGrid fluid {...rest}>
    {children}
  </RFGGrid>
)

export const Row = ({ children, style = { margin: "8px 0 16px 0" }, ...rest }) => (
  <RFGRow style={style} {...rest}>
    {children}
  </RFGRow>
)

export const Col = ({ children, ...rest }) => <RFGCol {...rest}>{children}</RFGCol>
