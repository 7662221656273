// @ts-nocheck
import { Button, Icon, Tab, Tabs, Tooltip } from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import monitorsDuck from "../../../../state/modules/monitors"
import { Loader } from "../../../components"
import { useAuth } from "../../../hooks"
import Sheet from "../Sheet"
import { EditSheet } from "../Sheet/EditSheet"
import { mapOrder, useGetWritePermission } from "../utils"
import NoSheet from "./NoSheet"

const Monitor = ({ desk, monitor, monitorPrefs, toggleMonitorPrefs, height }) => {
  const { profile } = useAuth()
  const [editSheetName, setEditSheetName] = useState(false)
  const toggleEditSheetName = () => setEditSheetName(!editSheetName)

  const activeSheet = R.includes(monitor.active_sheet_id, monitor.sheet_ids)
    ? monitor.active_sheet_id
    : monitor.sheet_ids.length > 0
      ? monitor.sheet_ids[0]
      : null

  const sheets = useSelector(R.pathOr({}, ["sheets", "list"]))

  const sheetsReady = useSelector(R.path(["sheets", "ready"]))
  const monitorReady = monitor.ready

  // Variables for permissions
  const userId = profile.id
  const deskOwnerId = desk.owner_id
  const hasWritePermission = useGetWritePermission(desk)
  // end Variables for permissions

  const dispatch = useDispatch()

  const handleChange = tabId => {
    dispatch(monitorsDuck.actions.setActiveSheet({ id: monitor.id, active_sheet_id: tabId }))
    setEditSheetName(false)
  }

  if (R.isEmpty(monitorPrefs) && (!sheetsReady || !monitorReady)) {
    return <Loader style={{ position: "relative" }} />
  }

  const sheetIds = monitor.sheet_ids
  const sheetsInMonitor = R.values(R.filter(e => R.includes(e.id, sheetIds), sheets))
  const sortedSheets = mapOrder(sheetsInMonitor, sheetIds, "id")

  return (
    <>
      {!R.isEmpty(sheetIds) && !R.isEmpty(sheets) && (
        <div className="sct-monitor-tabs">
          <Tabs animate id="navbar" onChange={handleChange} selectedTabId={activeSheet}>
            {sortedSheets.map(sheet => (
              <Tab
                key={sheet.id}
                id={sheet.id}
                disabled={activeSheet === sheet.id}
                children={
                  editSheetName &&
                  sheet.id === activeSheet && // Edit is allowed only for active sheet
                  (userId === deskOwnerId || hasWritePermission) ? ( // Edit only if user has permission
                    <EditSheet
                      sheet={sheet}
                      toggleEdit={toggleEditSheetName}
                      dispatch={dispatch}
                      monitorHeader={true}
                    />
                  ) : (
                    <Button minimal>
                      {sheet.name}

                      {sheet.id === activeSheet && // display edit icon only for active sheet and only if user has permission
                        (userId === deskOwnerId || hasWritePermission) && (
                          <Tooltip
                            content="Edit name"
                            placement="bottom"
                            openOnTargetFocus={false}
                            compact
                          >
                            <Icon
                              className="sct-button-in-button"
                              onClick={toggleEditSheetName}
                              icon="edit"
                              size="12px"
                              style={{ margin: "0 0 2px 0px" }}
                            />
                          </Tooltip>
                        )}
                    </Button>
                  )
                }
              ></Tab>
            ))}
          </Tabs>
        </div>
      )}
      <div className="sct-monitor-content">
        {!R.isEmpty(sheetIds) && !R.isEmpty(sheets) ? (
          <Sheet desk={desk} monitor={monitor} sheetId={activeSheet} height={height} />
        ) : (
          <NoSheet monitor={monitor} toggleMonitorPrefs={toggleMonitorPrefs} />
        )}
      </div>
    </>
  )
}
export default Monitor
