// @ts-nocheck
import { switchMap, map, debounceTime } from "rxjs/operators"
import { ofType } from "redux-observable"
// import { isEmpty } from "ramda"

import searchDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { search, searchOk },
} = searchDuck

const searchEpic = (action$, state$, { ajax, auth, scheduler }, debounceMs = 800) =>
  action$.pipe(
    ofType(search.type),
    debounceTime(debounceMs, scheduler),
    // .filter(action => action.payload.length > 2)
    switchMap(action => {
      const { name, kinds } = action.payload

      const params = kinds ? `?search=${name}&kinds=${kinds}` : `?search=${name}`

      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/dataframes/search/${params}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(map(({ response }) => searchOk(response.results)))
    }),
  )

export default searchEpic
