// @ts-nocheck
import { assoc, compose, indexBy, map, prop } from "ramda"

const shapeDesks = desksFromBackend => {
  const desks = prop("results", desksFromBackend)

  return {
    data: compose(map(assoc("ready", true)), indexBy(prop("id")))(desks),
  }
}

export default shapeDesks
