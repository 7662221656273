import { Alert, AlertProps, Button, ButtonProps } from "@blueprintjs/core"
import { ReactNode, useState } from "react"

type AlertPropsWithoutIsOpen = Omit<AlertProps, "isOpen">

interface IButtonWithAlertProps {
  buttonProps: ButtonProps
  alertProps: AlertPropsWithoutIsOpen
  children: ReactNode
}

export const ButtonWithAlert = ({ buttonProps, alertProps, children }: IButtonWithAlertProps) => {
  const [alert, setAlert] = useState(false)

  return (
    <>
      <Button onClick={() => setAlert(true)} {...buttonProps} />
      <Alert
        className="bp5-dark"
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        isOpen={alert}
        onCancel={() => setAlert(false)}
        onClose={() => setAlert(false)}
        cancelButtonText="Cancel"
        /**
         * If icon and intent are not define in alertProps, Alert takes the same icon and intent
         * than defined in buttonProps
         */
        icon={buttonProps.icon}
        intent={buttonProps.intent}
        {...alertProps}
      >
        {children}
      </Alert>
    </>
  )
}
