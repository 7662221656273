/** @jsxImportSource @emotion/react */

import { Button } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { Field, Form, Formik } from "formik"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { SCIENT_ROUTES } from "../../Routes"
import { createToaster } from "../../utils/createToaster"
import TextInput from "../Login/LoginTextInput"
import {
  FormContainer,
  PasswordContainer,
  resetPasswordRequestSubmitButtonCss,
} from "./PasswordResetRequest.styled"
import { useResetPwdRequestMutation } from "./hooks"

const PasswordResetRequestForm = () => {
  const navigate = useNavigate()
  const resetPwdRequestMutation = useResetPwdRequestMutation({
    onSuccess: () => {
      createToaster({
        message: `Password reset request sent`,
        intent: "success",
      })
      navigate(SCIENT_ROUTES.HOME)
    },
    onError: (error: AxiosError) => {
      createToaster({
        message:
          "An error occurred sending the reset password request, please refresh the page and retry.",
        intent: "danger",
      })
    },
  })
  const submitForm = (email: string) => {
    resetPwdRequestMutation.mutate(email)
  }

  const PasswordResetRequestSchema = Yup.object().shape({
    email: Yup.string().email("Must be a valid email").required("Email is required"),
  })
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={PasswordResetRequestSchema}
      onSubmit={email => {
        submitForm(email["email"])
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <FormContainer flexDirection="column" gap="20px" alignItems="center">
        <Form>
          <FlexContainer flexDirection="column" gap="20px" alignItems="center">
            <PasswordContainer flexDirection="column" gap="10px" alignItems="center">
              <Field name="email" component={TextInput} placeholder="Email Address" />
            </PasswordContainer>
            <Button
              fill
              intent="primary"
              icon="confirm"
              type="submit"
              css={resetPasswordRequestSubmitButtonCss}
            >
              Confirm
            </Button>
          </FlexContainer>
        </Form>
      </FormContainer>
    </Formik>
  )
}

export default PasswordResetRequestForm
