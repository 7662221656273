// @ts-nocheck
import * as R from "ramda"

export const stockSetInitial = (state, { slug, forecastSlugs }) => {
  const stockLens = R.lensPath(["userstockconfigs", slug])

  // if (forecastSlugs.length < 2) return R.set(stockLens, { ready: true, notEnoughData: true }, state)

  // if no stockConfig, create one with base diffPair & excluded
  const stockConfig =
    forecastSlugs.length < 2 && R.isNil(R.view(stockLens, state))
      ? R.compose(
          R.assoc("availableTabs", ["sales", "salesGrowth", "orders"]),
          R.dissoc("availableTabs"),
          R.assoc("diffPair", []),
          R.dissoc("defaultTabs"),
        )(state.userstockconfigDefaults)
      : R.isNil(R.view(stockLens, state))
      ? R.compose(
          R.assoc("diffPair", [`${slug}-con-default-base`, `${slug}-hpr-default-base`]),
          R.assoc("excluded", [`${slug}-con-default-base`, `${slug}-hpr-default-base`]),
          R.dissoc("defaultTabs"),
        )(state.userstockconfigDefaults)
      : forecastSlugs.length < 2
      ? {
          ...R.view(stockLens, state),
          availableTabs: R.reject(e => e === "consensus", R.view(stockLens, state).availableTabs),
          diffPair: [],
          starred: [],
          excluded: [],
        }
      : R.view(stockLens, state)

  const defender =
    forecastSlugs.length < 2
      ? null
      : forecastSlugs.includes(stockConfig.diffPair[0])
      ? stockConfig.diffPair[0]
      : forecastSlugs[0]

  const challenger =
    forecastSlugs.length < 2
      ? null
      : forecastSlugs.includes(stockConfig.diffPair[1])
      ? stockConfig.diffPair[1]
      : forecastSlugs[1]

  const consensusFields = stockConfig.availableTabs.includes(stockConfig.consensusFields)
    ? stockConfig.consensusFields
    : stockConfig.availableTabs[0]

  const recessiveKeys = {
    activeTab: R.pathOr(null, ["availableTabs", 0], stockConfig),
    stock: slug,
    customTabs: {},
  }

  const dominantKeys =
    forecastSlugs.length < 2
      ? { ready: true }
      : { ready: true, diffPair: [defender, challenger], consensusFields }

  return R.set(
    R.lensPath(["userstockconfigs", slug]),
    R.mergeDeepRight(R.mergeDeepLeft(stockConfig, recessiveKeys), dominantKeys),
    state,
  )
}

export const stockSetFreq = (state, { slug, freq }) =>
  R.set(R.lensPath(["userstockconfigs", slug, "freq"]), freq, state)

export const stockToggleEdit = (state, { slug }) =>
  R.over(R.lensPath(["userstockconfigs", slug, "showEdit"]), R.not, state)

export const stockToggleInactive = (state, { slug }) =>
  R.over(R.lensPath(["userstockconfigs", slug, "showInactive"]), R.not, state)

export const stockToggleEmpty = (state, { slug }) =>
  R.over(R.lensPath(["userstockconfigs", slug, "showEmpty"]), R.not, state)

export const stockSetForecast = (state, { slug, forecast }) =>
  R.set(R.lensPath(["userstockconfigs", slug, "forecast"]), forecast, state)

export const stockSetWentToLastCol = (state, { slug, wentToLastCol }) =>
  R.set(R.lensPath(["userstockconfigs", slug, "wentToLastCol"]), wentToLastCol, state)
