// @ts-nocheck
import { Icon, NonIdealState } from "@blueprintjs/core"
import { Cell, Column, Table2 } from "@blueprintjs/table"
import { includes, isEmpty } from "ramda"
import { useSelector } from "react-redux"

import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import dataSetSearchDuck from "../../../../../state/modules/dataSetSearch"
import { formatNumber } from "../../../../_shared"
import {
  colorPctRenderer,
  cw,
  dateRenderer,
  fieldRenderer,
  numStyle,
} from "../../../../_shared/cellRenderers"
import { LoaderIn } from "../../../../components"
import ContextMenu from "./ContextMenu"

const Main = () => {
  const navigate = useNavigate()
  const { searching, results } = useSelector(({ dataSetSearch }) => ({
    results: dataSetSearch.response.results,
    searching: dataSetSearch.searching,
  }))

  const dispatch = useDispatch()
  const createChangeDSOk = dispatch(params => dataSetSearchDuck.actions.createChangeDSOk(params))
  const createMavDSOk = dispatch(params => dataSetSearchDuck.actions.createMavDSOk(params))

  if (searching) {
    return <LoaderIn title="searching..." />
  }

  if (isEmpty(results)) {
    return (
      <NonIdealState
        icon={"search"}
        title="No dataset found"
        description="Please change or clear your search parameters in the sidebar."
      />
    )
  }

  const renderVal = rowIdx => (
    <Cell style={numStyle}>
      {formatNumber(
        results[rowIdx].last_value,
        results[rowIdx].unit_str ? includes("pct", results[rowIdx].unit_str) : false,
      )}
    </Cell>
  )

  const renderAgg = rowIdx => <Cell>{results[rowIdx].aggregated && <Icon icon="tick" />}</Cell>

  const contextMenuRenderer = ({ target }) => {
    const index = target.rows[0]
    const ds = results[index]
    return (
      <ContextMenu
        index={index}
        ds={ds}
        navigate={navigate}
        createChangeDSOk={createChangeDSOk}
        createMavDSOk={createMavDSOk}
      />
    )
  }

  /**
   * We don't use HotkeysProvider, because no need hotkeys in this table. So we get warning in the console:
   * "[Blueprint] useHotkeys() was used outside of a <HotkeysProvider> context. These hotkeys will not be shown in the hotkeys help dialog."
   */
  return (
    <Table2
      numRows={results.length}
      numFrozenRows={0}
      numFrozenColumns={1}
      columnWidths={[cw.name, 50, cw.chg, cw.chg, cw.chg, cw.chg, cw.chg, cw.chg, cw.date, 100, 40]}
      bodyContextMenuRenderer={contextMenuRenderer}
    >
      <Column name="Dataset" cellRenderer={rowIdx => fieldRenderer(rowIdx, "name", results)} />
      <Column name="Agg" cellRenderer={rowIdx => renderAgg(rowIdx)} />
      <Column name="Sector" cellRenderer={rowIdx => fieldRenderer(rowIdx, "sector_1", results)} />
      <Column name="WoW" cellRenderer={rowIdx => colorPctRenderer(rowIdx, "chg_1w", results)} />
      <Column name="MoM" cellRenderer={rowIdx => colorPctRenderer(rowIdx, "chg_1m", results)} />
      <Column name="YoY" cellRenderer={rowIdx => colorPctRenderer(rowIdx, "chg_1y", results)} />
      <Column name="MA3M" cellRenderer={rowIdx => colorPctRenderer(rowIdx, "ma_3m_12m", results)} />
      <Column
        name="2YS"
        cellRenderer={rowIdx => colorPctRenderer(rowIdx, "two_y_stacked", results)}
      />
      <Column
        name="Release"
        cellRenderer={rowIdx => dateRenderer(rowIdx, "last_release", results)}
      />
      <Column name="Val" cellRenderer={renderVal} />
      <Column name="P" cellRenderer={rowIdx => fieldRenderer(rowIdx, "periodicity", results)} />
    </Table2>
  )
}

export default Main
