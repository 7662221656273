// @ts-nocheck
import { MenuItem, Spinner } from "@blueprintjs/core"
import { Suggest as BP5Suggest } from "@blueprintjs/select"
import React from "react"

const inputValueRenderer = () => ""

const itemRenderer = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) return null
  return (
    <MenuItem
      active={modifiers.active}
      key={item.id}
      text={item.name}
      onClick={handleClick}
      shouldDismissPopover={true}
    />
  )
}
export const Suggest = ({ large, loading, placeholder, dataCy, ...props }) => {
  return (
    <BP5Suggest
      inputProps={{
        placeholder,
        rightElement: loading ? (
          <Spinner size="18" />
        ) : (
          <div style={{ height: "18px", width: "32px" }} />
        ),
        fill: true,
        large,
        "data-cy": dataCy,
      }}
      inputValueRenderer={inputValueRenderer}
      itemRenderer={itemRenderer}
      noResults={<MenuItem disabled text="No results" />}
      {...props}
    />
  )
}
