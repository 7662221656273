// @ts-nocheck
import { getTime } from "date-fns"
import Highcharts from "highcharts/highstock"

import * as R from "ramda"

/**
 * Use to build series for Highcharts
 */
export const formatData = (colTitles, visibleRowData, rowDataWithoutTitle, rowDefs) => {
  return visibleRowData.map((row, index) => {
    const dashStyle = R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.chartDashStyle
      ? R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.chartDashStyle
      : "solid"

    const indexForColor = R.findIndex(R.pathEq(["extra", "rowId"], row.extra.rowId))(
      rowDataWithoutTitle,
    )

    const pct = R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.pct
      ? R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.pct
      : false

    return {
      name: R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.name,
      yAxis: R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.chartOwnAxis
        ? index + 1
        : 0,
      pct: R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.pct
        ? R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.pct
        : false,
      color: R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.chartColor
        ? R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.chartColor
        : Highcharts.getOptions().colors[indexForColor],

      data: R.compose(
        R.sortBy(R.prop(0)),
        R.values(),
        R.mapObjIndexed((obj, key) => [
          /**
           * From string, we generate Unix time in ms with getTime().
           * Unix time is used by HighCharts.
           *  */
          getTime(new Date(R.find(R.propEq("field", key), colTitles).end.valueOf())),
          pct ? Number(R.propOr(null, "value", obj)) * 100 : Number(R.propOr(null, "value", obj)),
        ]),
        R.omit(["extra"]),
      )(row),
      dashStyle: dashStyle,
      tooltip: {
        headerFormat: "{point.x:%d-%m-%Y}",
        pointFormat: pct ? "{series.name}: {point.y}%" : "{series.name}: {point.y}",
        valueDecimals: pct ? 2 : 0,
      },
    }
  })
}
