// @ts-nocheck
import {
  Button,
  Callout,
  Classes,
  Dialog,
  H1,
  H3,
  Tab,
  Tabs,
  TabsExpander,
} from "@blueprintjs/core"
import { useState } from "react"

import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { updateAnalyse } from "../../../../../state/modules/analysis"
import ChooseVariable from "../../components/ChooseVariable"
import { cleanSourceForBackend } from "../../utils"
import Builder from "./Builder"
import Results from "./Results"

const getResultTitle = isProcessing => {
  if (isProcessing) {
    return (
      <span>
        <em>Processing results...</em>
      </span>
    )
  }
  return "Results"
}

const Side = ({ analyse, handleFetchAnalyse }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isComplete = !!analyse.complete
  const isFailed = !!analyse.failed
  const hasResults = isComplete || isFailed
  const isProcessing = analyse.processing && !analyse.complete && !analyse.failed

  const [tab, setTab] = useState(hasResults || isProcessing ? "results" : "builder")
  const [openAddExplained, setOpenAddExplained] = useState()
  const toggleAddExplained = () => setOpenAddExplained(!openAddExplained)

  const handleAddExplained = () => {
    toggleAddExplained()
  }

  const handleSubmit = dep => {
    const dependent = cleanSourceForBackend(dep)
    dispatch(updateAnalyse({ analyse: { id: analyse.id, dependent } }))
  }

  return (
    <div>
      <section>
        <H3>Predicting with {analyse.method === "reg" ? "regressor" : "var"}</H3>

        {analyse.dependent ? (
          <H1>
            {analyse.dependent.name} ({analyse.dependent.freq})
          </H1>
        ) : (
          <H3>
            No variable to explain{" "}
            <Button onClick={handleAddExplained} text="Add explained variable" />
          </H3>
        )}
      </section>
      {analyse.error && (
        <Callout style={{ margin: "12px 0" }} title="Validation error" intent="warning">
          {analyse.error}
        </Callout>
      )}
      <Tabs id="analyseTabs" onChange={setTab} selectedTabId={tab} large>
        <Tab
          id="builder"
          title="Builder"
          panel={<Builder analyse={analyse} handleAddExplained={handleAddExplained} />}
        />
        <Tab
          id="results"
          title={getResultTitle(isProcessing)}
          panel={
            <Results
              analyse={analyse}
              isComplete={isComplete}
              isFailed={isFailed}
              hasResults={hasResults}
              isProcessing={isProcessing}
              setTab={setTab}
            />
          }
          disabled={!hasResults && !isProcessing}
        />
        <TabsExpander />
        <Button outlined icon="refresh" onClick={handleFetchAnalyse}>
          Refresh analyse
        </Button>
        <Button outlined icon="align-justify" onClick={() => navigate("/analysis/")}>
          All analyses
        </Button>
      </Tabs>
      <Dialog
        title="Choose explained variable"
        icon="plus"
        className="bp5-dark"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        isOpen={openAddExplained}
        onClose={toggleAddExplained}
      >
        <div className={Classes.DIALOG_BODY}>
          <ChooseVariable variable={analyse?.dependent || {}} submit={handleSubmit} />
        </div>
      </Dialog>
    </div>
  )
}

export default Side
