import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { LargeText, MediumItalicText } from "../../../styled/text.styled"
import { OTPMachineState } from "../../AuthProvider"
import { SCIENT_ROUTES } from "../../Routes"
import { ScientLogoNew } from "../../components/ScientLogo/ScientLogoNew"
import { useAuth } from "../../hooks"
import { LoginFullScreen, TransitionContainer } from "./Login.styled"
import LoginForm from "./LoginForm"
import SuggestOTP from "./SuggestOTP"
import SetupOTP from "./SetupOTP"
import VerifyOTP from "./VerifyOTP"
import { Spinner, SpinnerSize } from "@blueprintjs/core"

const Login = () => {
  const { authenticated, otpState, profile, isLoadingProfile } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (authenticated && otpState === OTPMachineState.OK) {
      navigate(SCIENT_ROUTES.HOME)
    }
  }, [authenticated, navigate, otpState])

  /**
   * If access Token is in local Storage we have
   * to wait for verification that will set authenticated
   * otherwise will remove the token from local storage
   * We dont want to show login page when verifying the token
   */
  return (
    <LoginFullScreen alignItems="center" justifyContent="center">
      {!authenticated && (
        <TransitionContainer flexDirection="column" gap="20px" alignItems="center">
          <FlexContainer flexDirection="column" gap="5px" alignItems="center">
            <ScientLogoNew height="60px" />
            <LargeText color="#2e58a5">Welcome</LargeText>
            <MediumItalicText color="#2e58a5">Log in to Scient to continue</MediumItalicText>
          </FlexContainer>
          <LoginForm />
        </TransitionContainer>
      )}
      {isLoadingProfile && <Spinner size={SpinnerSize.STANDARD} />}
      {profile && authenticated && otpState === OTPMachineState.SUGGEST_OTP_SETUP && <SuggestOTP />}
      {profile && authenticated && otpState === OTPMachineState.SETUP_OTP && <SetupOTP />}
      {profile && authenticated && otpState === OTPMachineState.VERIFY_OTP_SETUP && <VerifyOTP />}
    </LoginFullScreen>
  )
}

export default Login
