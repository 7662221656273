// @ts-nocheck
import React from "react"

import { NumericInput as BP5NumericInput, FormGroup } from "@blueprintjs/core"
import { convertPath, getDeepNestedValue } from "../../../utils"
import { roundNumber } from "../../_shared"
import { evAjdSum, totalSum } from "./sum"
import { division } from "./divide"

export const NumericInput = ({
  field, // { name, value, onChange, onBlur }
  form: { values, setFieldValue, setStatus, status }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  labelProps = null,
  onValueChange,
  onEnter = () => {
    // do nothing
  },
  onEsc = () => {
    // do nothing
  },
  sideUpdates = [],
  prevReport,
  prevYearReport,
  ...props
}) => {
  const handleFocus = () => setStatus({ ...status, focus: field.name })

  const handleNumChange = (numVal, stringVal) => {
    if (sideUpdates !== []) {
      sideUpdates.forEach(sideUpdate => {
        if (sideUpdate.method === "sum" && status.focus === field.name) {
          if (sideUpdate.override) {
            const overrideValue = getDeepNestedValue(sideUpdate.override, values)
            const flattenPath = path => convertPath(path, values, { flatten: true })
            const addFlattenPath = sideUpdate.add.map(el => flattenPath(el))
            const subFlattenPath = sideUpdate.sub.map(el => flattenPath(el))
            if (field.name === flattenPath(sideUpdate.override) && stringVal !== "") {
              setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), stringVal)
            } else if (
              [addFlattenPath, subFlattenPath].includes(field.name) &&
              overrideValue !== ""
            ) {
              setFieldValue(
                convertPath(sideUpdate.target, values, { flatten: true }),
                overrideValue,
              )
            } else {
              const result = totalSum({ field, numVal, sideUpdate, values })
              setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), result)
            }
          } else {
            const result = totalSum({ field, numVal, sideUpdate, values })
            setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), result)
          }
        }

        if (sideUpdate.method === "divis" && status.focus === field.name) {
          if (sideUpdate.override) {
            const overrideValue = getDeepNestedValue(sideUpdate.override, values)
            const flattenPath = path => convertPath(path, values, { flatten: true })

            const numeFlattenPath = sideUpdate.nume.map(el => flattenPath(el))
            const denomFlattenPath = sideUpdate.denom.map(el => flattenPath(el))
            if (field.name === flattenPath(sideUpdate.override) && stringVal !== "") {
              setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), stringVal)
            } else if (
              [numeFlattenPath, denomFlattenPath].includes(field.name) &&
              overrideValue !== ""
            ) {
              setFieldValue(
                convertPath(sideUpdate.target, values, { flatten: true }),
                overrideValue,
              )
            } else {
              const result = division({ field, numVal, sideUpdate, values })
              setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), result)
            }
          } else {
            const result = division({ field, numVal, sideUpdate, values })
            setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), result)
          }
        }

        if (sideUpdate.method === "erase" && status.focus === field.name) {
          const { targets } = sideUpdate
          targets.forEach(target =>
            setFieldValue(convertPath(target, values, { flatten: true }), ""),
          )
        }

        if (sideUpdate.method === "growthYoY" && status.focus === field.name) {
          const { target, source } = sideUpdate
          const flatTarget = convertPath(target, values, { flatten: true })
          const prevValue = getDeepNestedValue(source, prevYearReport)
          const result =
            numVal && prevValue && roundNumber((numVal - prevValue) / Math.abs(prevValue), 6)
          setFieldValue(flatTarget, result)
        }

        if (sideUpdate.method === "growthQoQ" && status.focus === field.name) {
          const { target, source } = sideUpdate
          const flatTarget = convertPath(target, values, { flatten: true })
          const prevValue = getDeepNestedValue(source, prevReport)
          const result =
            numVal && prevValue && roundNumber((numVal - prevValue) / Math.abs(prevValue), 6)
          setFieldValue(flatTarget, result)
        }

        if (sideUpdate.method === "computeEvAjd" && status.focus === field.name) {
          const overrideValue = getDeepNestedValue(sideUpdate.override, values)
          const flattenPath = path => convertPath(path, values, { flatten: true })
          const addFlattenPath = sideUpdate.add.map(el => flattenPath(el))
          const subFlattenPath = sideUpdate.sub.map(el => flattenPath(el))
          const multiFlattenPath = sideUpdate.multi.map(el => flattenPath(el))
          if (field.name === flattenPath(sideUpdate.override) && stringVal !== "") {
            setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), stringVal)
          } else if (
            [addFlattenPath, subFlattenPath, multiFlattenPath].includes(field.name) &&
            overrideValue !== ""
          ) {
            setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), overrideValue)
          } else {
            const result = evAjdSum({ field, numVal, sideUpdate, values })
            setFieldValue(convertPath(sideUpdate.target, values, { flatten: true }), result)
          }
        }
      })
    }
    onValueChange(numVal, stringVal)
  }

  const handleKeyUp = e => {
    if (e.key === "Enter") {
      onEnter()
    }
    if (e.key === "Esc") {
      onEsc()
    }
  }

  return (
    <>
      {labelProps ? (
        <FormGroup {...labelProps}>
          <BP5NumericInput
            fill
            buttonPosition="none"
            onFocus={handleFocus}
            onValueChange={handleNumChange}
            onKeyUp={handleKeyUp}
            {...field}
            {...props}
          />
        </FormGroup>
      ) : (
        <BP5NumericInput
          fill
          buttonPosition="none"
          onFocus={handleFocus}
          onValueChange={handleNumChange}
          onKeyUp={handleKeyUp}
          {...field}
          {...props}
        />
      )}
    </>
  )
}
