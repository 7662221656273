// @ts-nocheck
import * as R from "ramda"

const createSharedSplitFields = (name, fields, dataframe, options) => {
  const mainSegs = R.compose(
    R.map(e => [
      { type: "title2", text: e.text },
      ...fields.map(field => ({
        path: R.replace("]", `,'${field.key}']`, e.key),
        text: e.text + field.text,
      })),
    ]),
    R.filter(e => e.key.includes("slug:main")),
    R.pathOr([], ["extra", "splitFields"]),
  )
  const secondarySegs = R.compose(
    R.map(e => [
      { type: "title2", text: e.text },
      ...fields.map(field => ({
        path: R.replace("]", `,'${field.key}']`, e.key),
        text: e.text + field.text,
      })),
    ]),
    R.filter(e => e.key.includes("slug:secondary")),
    R.pathOr([], ["extra", "splitFields"]),
  )

  return R.flatten([
    { type: "title1", text: `Main ${name}` },
    ...mainSegs(dataframe, options),
    { type: "title1", text: `Secondary ${name}` },
    ...secondarySegs(dataframe, options),
  ])
}

const createFieldFields = dataframe => {
  return R.compose(
    R.map(field => ({
      path: field.key,
      text: field.text,
    })),
    R.pathOr([], ["extra", "fieldFields"]),
  )(dataframe)
}

export const allStockFields = (shared, dataframe, options = {}) => {
  const fieldList = shared.fieldList
  const { shared_fields, report_fields, valuation_fields } = fieldList
  const { sales_fields, orders_fields, profit_fields, backlog_fields } = shared_fields
  const { is_fields, bs_fields, cf_fields } = report_fields

  // if dataframe is empty, we don't want to display splits titles
  if (R.isEmpty(dataframe)) {
    return [
      { type: "title1", text: "Group Sales" },
      ...sales_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Orders" },
      ...orders_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Profit" },
      ...profit_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Backlog" },
      ...backlog_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "IncomeStatement" },
      ...is_fields.map(row => ({ text: `[IS] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Balancesheet" },
      ...bs_fields.map(row => ({ text: `[BS] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Cashflow" },
      ...cf_fields.map(row => ({ text: `[CF] ${row.text}`, path: `['${row.key}']` })),

      { type: "title1", text: "Valuation" },
      ...valuation_fields
        .filter(row => row.time_aware !== null)
        .map(row => ({
          text: `[VAL] ${row.text}`,
          path: `['VAL_${row.key}']`,
        })),
    ]
  }

  return [
    { type: "title1", text: "Group Sales" },
    ...sales_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),
    ...createSharedSplitFields("sales", sales_fields, dataframe, options),

    { type: "title1", text: "Orders" },
    ...orders_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),
    ...createSharedSplitFields("orders", orders_fields, dataframe, options),

    { type: "title1", text: "Profit" },
    ...profit_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),
    ...createSharedSplitFields("profit", profit_fields, dataframe, options),

    { type: "title1", text: "Backlog" },
    ...backlog_fields.map(row => ({ text: `[Group] ${row.text}`, path: `['${row.key}']` })),
    ...createSharedSplitFields("backlog", backlog_fields, dataframe, options),

    { type: "title1", text: "Fields" },
    ...createFieldFields(dataframe),

    { type: "title1", text: "IncomeStatement" },
    ...is_fields.map(row => ({ text: `[IS] ${row.text}`, path: `['${row.key}']` })),

    { type: "title1", text: "Balancesheet" },
    ...bs_fields.map(row => ({ text: `[BS] ${row.text}`, path: `['${row.key}']` })),

    { type: "title1", text: "Cashflow" },
    ...cf_fields.map(row => ({ text: `[CF] ${row.text}`, path: `['${row.key}']` })),

    { type: "title1", text: "Valuation" },
    ...valuation_fields
      .filter(row => row.time_aware !== null)
      .map(row => ({
        text: `[VAL] ${row.text}`,
        path: `['VAL_${row.key}']`,
      })),
  ]
}
