// @ts-nocheck
import * as R from "ramda"

/**
 * This function is to know if a param must be display or not depending of the 'show' key.
 * Key 'show' can be true or an object like this:
 *
 *      show: { how: "ALL", checks: [["linkSourceName", ["linkSourceValue"]]] }
 *
 * If ALL => all checks must be satisfy to display the param.
 * If ANY => at least one check must be satisfy
 * We can have one or many linkSourceValue.
 *
 * The 2 first cases of the switch are for the param of type FieldSelect.
 *     Until the user has chosen a source, Field Select does not appear.
 *     If user has chosen a source of type stock, FieldSelect is diplayed => [filled: "chosenStockId", source: "stock"]
 *     If user has chosen a source of type dataset, FieldSelect is not diplayed => [filled: "chosenDSId", source: "dataSet"]
 */

export const shouldDisplay = ({ show, values }) => {
  if (typeof show === "boolean") {
    return show
  }

  const checks = show.checks.map(check => {
    const linkSourceName = check[0]
    const linkSourceValue = check[1]

    switch (linkSourceName) {
      case "filled":
        return !R.isEmpty(values[check[1]])
      case "source":
        return R.test(/(st)|(rf)/g, values.source)
      case `${linkSourceName}`:
        return R.includes(values[linkSourceName], linkSourceValue)
      default:
        throw new Error("Something went wrong in shouldDisplay")
    }
  })

  if (show.how === "ANY" && checks.includes(true)) {
    return true
  }
  if (show.how === "ALL" && !checks.includes(false)) {
    return true
  }
  return false
}
