// @ts-nocheck
import React, { useState } from "react"
import { Button, Card, Collapse } from "@blueprintjs/core"
import { Field } from "formik"

import { FormikTextInput } from "../FormikFields"
import FormikStyling from "../FormikStyling"

export const StockItem = ({ index, stock, stockIndex, handleDelete, kind }) => {
  const [showOptions, setShowOptions] = useState(false)
  const toogleShowOptions = () => setShowOptions(!showOptions)

  return (
    <>
      <tr>
        <td>
          <Button
            fill
            alignText="left"
            minimal
            icon={showOptions ? "caret-down" : "caret-right"}
            onClick={toogleShowOptions}
          >
            {stock.str_name}
          </Button>
          <Collapse isOpen={showOptions}>
            <Card style={{ width: "430px" }}>
              <Field
                name={`rowDefs.rows[${index}].stocks.[${stockIndex}].frontOpts.titleOverride`}
                component={FormikTextInput}
                labelProps={{ label: "Title Override" }}
                inputProps={{ placeholder: "Enter title" }}
                helperText="Enter name or leave empty."
              />
              <FormikStyling index={index} stockIndex={stockIndex} kind={kind} />
            </Card>
          </Collapse>
        </td>
        <td>
          <Button minimal icon="trash" onClick={() => handleDelete(stock)} />
        </td>
      </tr>
    </>
  )
}
