// @ts-nocheck
import { Button, ButtonGroup, Icon, Spinner, Tooltip } from "@blueprintjs/core"
import { format, utcToZonedTime } from "date-fns-tz"
import { useDispatch } from "react-redux"

import { formatNumber } from "."
import datasetsDuck from "../../state/modules/dataSets"
import stockDetailDuck from "../../state/modules/stockDetail"
import { ButtonWithAlert } from "../components"
import { useApi } from "../hooks/useApi"

export const DateFormatter = ({ value }) => {
  // Handle the case when value is falsy (null or undefined)
  if (!value) {
    return ""
  }
  /**
   * We receive value as UTC time and want to keep UTC.
   * DateFormatter is use for aggrid rowData and colTitles.
   *
   * Exemple: for rowData, aggrid displays the start and end date of a period,
   * so need UTC to not display start=31-01-2022 for 2022M2 for American users for exemple.
   * We want start=01-02-2022
   */
  const date = new Date(value)
  if (!isNaN(date.getTime())) {
    return format(utcToZonedTime(date, "UTC"), "dd-MM-yy", {
      timeZone: "UTC",
    })
  } else {
    return "Invalid Date"
  }
}

export const NumberFormatter = ({ value, pct = false }) =>
  value === "" ? "" : formatNumber(value, pct)

export const StatusFormatter = ({ data: { loading, error } }) => {
  if (loading) return <Spinner size={22} />
  if (error) {
    const errors = (
      <ul style={{ listStyle: "none", marginLeft: "5px", paddingLeft: "0" }}>
        {Object.keys(error).map(key => (
          <li>
            <strong style={{ textTransform: "capitalize" }}>{key}</strong>
            <ul style={{ listStyle: "none", marginLeft: "5px", paddingLeft: "0" }}>
              {error[key].map(errString => (
                <li>{errString}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    )
    return (
      <div style={{ textAlign: "center" }}>
        <Tooltip content={errors} placement="bottom" openOnTargetFocus={false} compact>
          <Icon icon="error" intent="warning" />
        </Tooltip>
      </div>
    )
  }
  return (
    <div style={{ textAlign: "center" }}>
      <Icon icon="tick" />
    </div>
  )
}

export const DelDataSetFormatter = ({ data: { id, dataset, name } }) => {
  const { scientApi } = useApi()

  const dispatch = useDispatch()
  const handleDelete = () => {
    scientApi.datasets.entries.detail
      .delete(id)
      .then(() => dispatch(datasetsDuck.actions.fetchDataSet({ id: dataset })))
  }
  return (
    <div style={{ textAlign: "center" }}>
      <ButtonWithAlert
        buttonProps={{ icon: "trash", intent: "danger", minimal: true, "data-cy": "DeleteEntry" }}
        alertProps={{
          confirmButtonText: `Delete ${name}`,
          onConfirm: handleDelete,
        }}
      >
        <p>Do you really want to delete {name}?</p>
      </ButtonWithAlert>
    </div>
  )
}

export const ValueOrActionRenderer = ({ value, handleDelete, stockSlug, isRForecast = null }) => {
  const dispatch = useDispatch()
  const setEditSlug = reportSlug => {
    return dispatch(
      stockDetailDuck.actions.setEditSlug({ slug: stockSlug, reportSlug: reportSlug }),
    )
  }

  if (value && value.includes("ActionButton")) {
    if (isRForecast) return ""
    const slug = value.replace("ActionButton", "")
    return (
      <ButtonGroup fill>
        <Button minimal intent="primary" icon="edit" onClick={() => setEditSlug(slug)} />
        <Button minimal intent="danger" icon="trash" onClick={() => handleDelete(slug)} />
      </ButtonGroup>
    )
  }
  return <div>{value}</div>
}
