// @ts-nocheck
import { withFormik } from "formik"

import stockDetailDuck from "../../../../state/modules/stockDetail"
import { getDeepNestedValue } from "../../../../utils"
import { handleError } from "../../../components/formik"
import { useApi } from "../../../hooks/useApi"
import { cleanReport, reportSchema } from "../utils"
import Form from "./Form"

const AddForm = withFormik({
  mapPropsToValues: ({ reportData: { report, prevReport } }) => {
    const currencies = () => {
      if (report.currencies && report.currencies.length > 0) {
        const currency = report.currencies.map(c =>
          getDeepNestedValue(["currencies", `slug:${c.slug}`], prevReport),
        )
        return currency
      }
      return []
    }
    const geos = () => {
      if (report.geos && report.geos.length > 0) {
        const geos = report.geos.map(geo =>
          getDeepNestedValue(["geos", `slug:${geo.slug}`], prevReport),
        )
        return geos
      }
      return []
    }

    const values = {
      ...report,
      release_date: "",
      currencies: currencies(),
      geos: geos(),
    }
    return values
  },
  validationSchema: reportSchema,
  handleSubmit: (values, { props, status, setStatus, setSubmitting }) => {
    const { id, dispatch, handleClose } = props
    setSubmitting(true)
    props.scientApi.reporting.reports.list
      .create({
        id,
        values: cleanReport(values),
      })
      .then(response => {
        setSubmitting(false)
        dispatch(stockDetailDuck.actions.createReport({ id, values: response.data }))
        handleClose()
      })
      .catch(e => handleError(setSubmitting, status, setStatus, e))
  },
})(Form)

export default AddForm
