// @ts-nocheck
import { Button, Classes, FormGroup, NumericInput, Popover, Tooltip } from "@blueprintjs/core"
import { useState } from "react"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../state/modules/sheets"

export const HeaderHeight = ({ sheet, rowDefs }) => {
  // header = sub-header
  const [headerHeight, setHeaderHeight] = useState(
    rowDefs.frontOpts.headerHeight ? rowDefs.frontOpts.headerHeight : 27,
  )
  // groupHeader = header
  const [groupHeaderHeight, setGroupHeaderHeight] = useState(
    rowDefs.frontOpts.groupHeaderHeight ? rowDefs.frontOpts.groupHeaderHeight : 32,
  )
  const dispatch = useDispatch()

  const handleSubmitHeaderHeight = () => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        rowDefs: {
          ...sheet.rowDefs,
          frontOpts: { headerHeight: headerHeight, groupHeaderHeight: groupHeaderHeight },
        },
      }),
    )
  }

  const handleClose = () => {
    setHeaderHeight(rowDefs.frontOpts.headerHeight ? rowDefs.frontOpts.headerHeight : 27)
    setGroupHeaderHeight(
      rowDefs.frontOpts.groupHeaderHeight ? rowDefs.frontOpts.groupHeaderHeight : 32,
    )
  }

  return (
    <Popover
      onClosed={() => handleClose()}
      content={
        <div style={{ width: "198px" }}>
          <FormGroup
            fill
            label="Customise header height"
            labelFor="header-height"
            labelInfo="(Enter value in pixel)"
          >
            <NumericInput
              id="header-height"
              value={groupHeaderHeight}
              onValueChange={value => setGroupHeaderHeight(value)}
              min={20}
              max={150}
            />
          </FormGroup>
          <FormGroup
            fill
            label="Customise sub-header height"
            labelFor="sub-header-height"
            labelInfo="(Enter value in pixel)"
          >
            <NumericInput
              id="sub-header-height"
              value={headerHeight}
              onValueChange={value => setHeaderHeight(value)}
              min={20}
              max={150}
            />
          </FormGroup>
          <Button fill intent="primary" text="Submit" onClick={handleSubmitHeaderHeight} />
        </div>
      }
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      placement="bottom-end"
    >
      <Tooltip
        content="Customise header height"
        placement="bottom"
        openOnTargetFocus={false}
        compact
      >
        <Button icon="arrows-vertical" />
      </Tooltip>
    </Popover>
  )
}
