// @ts-nocheck
import * as R from "ramda"
import * as recast from "recast"

export const getPrevloopSource = (rowNodes, primarySource) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const ast = recast.parse(JSON.stringify(rowNodes))
  const literal = []
  recast.visit(ast, {
    visitLiteral: function (path) {
      const slug = path.value.value
      literal.push(slug)
      this.traverse(path)
    },
  })

  const loopOnList = R.compose(
    R.filter(e => e.includes("prevloop") && !e.includes("segs")),
    R.reject(e => e === undefined),
    R.reject(e => e === null),
    R.reject(e => typeof e === "boolean"),
    R.reject(e => typeof e === "number"),
  )(literal)

  if (R.isEmpty(loopOnList)) return []

  const regex = /(rf\w+)|(st\w+)/g
  const loopSources = JSON.stringify(loopOnList)
    .match(regex)
    .map(source => (primarySource && source === "stsrc0" ? primarySource : source))

  return loopSources
}
