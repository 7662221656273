// @ts-nocheck
import { mergeMap, map, debounceTime } from "rxjs/operators"
import { ofType, combineEpics } from "redux-observable"

import consensusDuck from "./index"
import { REACT_APP_API_DOMAIN } from "../../../config/constants"

const {
  actions: { searchStock, searchStockOk },
} = consensusDuck

export const searchStockEpic = (action$, _, { ajax, auth, scheduler }, debounceMs = 800) =>
  action$.pipe(
    ofType(searchStock.type),
    debounceTime(debounceMs, scheduler),
    // .filter(action => action.payload.length > 2)
    mergeMap(action =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/stocks/search/?search=${action.payload}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) =>
          searchStockOk(
            response.results.map(stock => ({
              title: stock.name,
              description: stock.bloomberg_code,
              slug: stock.slug,
            })),
          ),
        ),
      ),
    ),
  )

const consensusEpics = combineEpics(searchStockEpic)

export default consensusEpics
