// @ts-nocheck
import { H5, HTMLTable, Tag } from "@blueprintjs/core"
import { format, utcToZonedTime } from "date-fns-tz"
import { compose, map, takeLast } from "ramda"
import { Col, Row } from "react-flexbox-grid"

import { FlexContainer } from "../../../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../../../styled/scientColors"
import { MediumText } from "../../../../../../../styled/text.styled"
import { formatNumber } from "../../../../../../_shared"
import { HelpTooltip } from "../../../../../../components"
import formatGraphSeries from "../../../_shared/formatGraphSeries"

const Tables2 = ({ analyse }) => {
  return (
    <Row>
      <Col xs={12}>
        {analyse.causal || analyse.causal === 0 ? (
          <div style={{ margin: "5px 0", width: "100%" }}>
            <Tag large intent={analyse.causal <= 0.05 ? "success" : "danger"}>
              Causality: {analyse.causal}
            </Tag>
            <HelpTooltip
              content={
                <FlexContainer flexDirection="column">
                  <H5>This value is a p-value for the Granger causality test on the variables</H5>
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    This test aims to determine whether one or more x-type variables
                    <br />
                    are useful for the prediction of a y-type variable, up to some statistically
                    significant level.
                  </MediumText>
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    In essence, it is a t-test on the following null hypothesis:
                    <br />
                    <em>“there is no causality between the designated variables”.</em>
                    <br />
                    One can successfully reject this hypothesis, and so establish that the variables
                    <br />
                    have statistically significant causality, if the p-value of the test
                    <br />
                    is smaller or equal to 0.05.
                  </MediumText>
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    Note that this is not the same as an R-squared value, which makes a statement
                    <br />
                    on the amount of error of the predicted model over the actual test data.
                    <br />
                  </MediumText>
                </FlexContainer>
              }
            />
          </div>
        ) : null}
        {analyse.rsquared || analyse.rsquared === 0 ? (
          <div style={{ margin: "5px 0" }}>
            <Tag large>R-Squared: {analyse.rsquared}</Tag>
            <HelpTooltip
              content={
                <FlexContainer flexDirection="column">
                  <H5>𝑅2 is a goodness-of-fit measure</H5>
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    It measures how well the model fits the data that you have so by looking at
                    <br />
                    how much error there is between the modelled values and the actual values.
                  </MediumText>
                  <MediumText color={SCIENT_COLORS.darkGray5}>
                    What it does not do however, is measure how well the model will fit the data
                    <br />
                    that you don't have yet (i.e. how well it will forecast).
                  </MediumText>
                </FlexContainer>
              }
            />
          </div>
        ) : null}
        <div style={{ margin: "5px 0" }}>
          <Tag
            large
            intent={analyse.gaussian ? "success" : "warning"}
            rightIcon={analyse.gaussian ? "tick" : "cross"}
          >
            Gaussian
          </Tag>
        </div>
        <div style={{ margin: "5px 0" }}>
          <Tag
            large
            intent={analyse.stationarity ? "success" : "warning"}
            rightIcon={analyse.stationarity ? "tick" : "cross"}
          >
            Stationarity
          </Tag>
          <HelpTooltip
            content={
              <FlexContainer flexDirection="column">
                <H5>If the data is stationary</H5>
                <MediumText color={SCIENT_COLORS.darkGray5}>
                  then roughly speaking it has no trend and a constant variance over time.
                  <br />
                  Most statistical analyses (VAR included) require the tested data to satisfy this
                  condition.
                  <br />
                  Thus if the data is not stationary, one has to remember that the prediction
                  obtained might be spurious.
                  <br />
                </MediumText>
              </FlexContainer>
            }
          />
        </div>
        <HTMLTable striped className="sct-table">
          <thead>
            <tr>
              <th>Date</th>
              <th style={{ textAlign: "right" }}>Value</th>
            </tr>
          </thead>
          <tbody>
            {compose(
              takeLast(analyse.pred_period),
              map(([date, value]) => {
                const precision = value < 10 && value > -10 ? 0.0001 : 0.1
                return (
                  <tr key={date}>
                    {/* 'date' is in Unix time and it's use by Highcharts in UTC.
                    So we stay in UTC, for now, to display the same date as in Highcharts.
                    */}
                    <td>
                      {format(utcToZonedTime(new Date(date), "UTC"), "dd-MM-yy", {
                        timeZone: "UTC",
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatNumber(value, undefined, undefined, precision)}
                    </td>
                  </tr>
                )
              }),
            )(formatGraphSeries(analyse.var_graph.data))}
          </tbody>
        </HTMLTable>
      </Col>
    </Row>
  )
}

export default Tables2
