import styled from "@emotion/styled"
import {
  FlexContainer,
  FlexContainerProps,
  FlexItemProps,
  flexContainerCss,
  flexItemCss,
} from "../../../styled/flexbox.styled"
import { CardContainer } from "../../../styled/layout.styled"
import { MediumText } from "../../../styled/text.styled"

export const ModelUpdatesCardContainer = styled(CardContainer)({
  padding: "2px 12px",
  marginBottom: "10px",
  overflow: "hidden",
})

type ItemContainerType = FlexItemProps & FlexContainerProps

export const YearsContainer = styled.div<ItemContainerType>`
  overflow: hidden;
  ${flexContainerCss}
  ${flexItemCss}
`
export const HeaderContainer = styled.div<ItemContainerType>`
  overflow: hidden;
  padding: 3px 0;
  ${flexContainerCss}
  ${flexItemCss}
`

export const TickerItem = styled.h5({
  margin: 0,
})

export const AnalystNameItem = styled(MediumText)({
  margin: "0 0 0 6px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
})

export const LastLineContainer = styled(FlexContainer)({
  ".bp5-button.bp5-minimal": { ".bp5-icon": { color: "#FFFFFF !important" } },
})
