import { AxiosInstance } from "axios"
import { IIdeaCreateForm } from "../views/pages/Ideas/Create/IdeaCreate"
import {
  ConversationMessage,
  Idea,
  IdeaRevision,
  IdeaTrade,
  IdeaType,
} from "../views/pages/Ideas/types/business"
import { IdeaTradesInsights } from "../views/components/IdeaTradesInsightsWidget/types"

/**
 * Api routes for /api/v1/ideas/
 */
const IDEAS_PER_PAGE = 15

export enum IdeaOrdering {
  DEFAULT = "default",
  CREATION_DATE = "creation_date",
  TRADE_OPEN_DATE = "trade_open_date",
  TRADE_CLOSE_DATE = "trade_close_date",
  TRADE_PERFORMANCE = "trade_performance",
}

export enum IdeaSorting {
  DESC = "DESC",
  ASC = "ASC",
}

export interface IIdeasApi {
  getInfiniteIdeas: ({
    pageParam,
    stockParam,
    sectorParam,
    userParam,
    draftParam,
    ideaTypeParam,
    tradeOpenOnlyParam,
    tradeClosedOnlyParam,
    orderBy,
    sortDirection,
  }: {
    pageParam?: string
    stockParam?: number
    sectorParam?: number
    userParam?: number
    draftParam: boolean
    ideaTypeParam: IdeaType | null
    tradeOpenOnlyParam?: boolean
    tradeClosedOnlyParam?: boolean
    orderBy?: IdeaOrdering
    sortDirection?: IdeaSorting
  }) => Promise<{
    data: Idea[]
    next_cursor: string | null
  }>
  create: (values: Partial<Idea> | { participants: number[] | undefined }) => Promise<Idea>
  update: ({
    ideaId,
    values,
  }: {
    ideaId: number | string
    values: Partial<IIdeaCreateForm>
  }) => Promise<Idea>
  publishDraft: (ideaId: number) => Promise<Idea>
  newRevision: ({
    ideaId,
    revision,
  }: {
    ideaId: number | string
    revision: Partial<IdeaRevision>
  }) => Promise<IdeaRevision>
  getIdeasStats: () => Promise<{
    unseen_ideas_count: number
  }>
  getIdeasTradeInsight: () => Promise<IdeaTradesInsights>
  setIdeaAsSeen: (ideaId: number) => Promise<Record<string, never>>
  openTrade: (ideaId: number) => Promise<IdeaTrade>
  closeTrade: (ideaId: number) => Promise<IdeaTrade>
  search: ({
    id,
    idea_type,
    stock,
    sector,
    user,
  }: {
    id?: number
    idea_type?: IdeaType
    stock?: number
    sector?: number
    user?: number
  }) => Promise<Idea[]>
  conversations: {
    setAsRead: (conversationId: number) => Promise<Record<string, never>>
  }
  messages: {
    create: ({
      conversationId,
      values,
    }: {
      conversationId: number
      values: Partial<ConversationMessage>
    }) => Promise<ConversationMessage>
    getInfiniteMessages: (
      pageParam: string,
      conversationId: number,
    ) => Promise<{
      data: ConversationMessage[]
      next_cursor: string
    }>
    update: ({
      messageId,
      values,
    }: {
      messageId: number
      values: Partial<ConversationMessage>
    }) => Promise<ConversationMessage>
  }
}

const ideas = (axiosClient: AxiosInstance): IIdeasApi => ({
  /**
   * GET /api/v1/ideas/ paginated
   */
  getInfiniteIdeas: async ({
    pageParam,
    stockParam,
    sectorParam,
    userParam,
    draftParam,
    ideaTypeParam: ideaTyeParam,
    tradeOpenOnlyParam,
    tradeClosedOnlyParam,
    orderBy,
    sortDirection,
  }: {
    pageParam?: string
    stockParam?: number
    sectorParam?: number
    userParam?: number
    draftParam: boolean
    ideaTypeParam?: IdeaType | null
    tradeOpenOnlyParam?: boolean
    tradeClosedOnlyParam?: boolean
    orderBy?: IdeaOrdering
    sortDirection?: IdeaSorting
  }) => {
    const { data } = await axiosClient.get<{ data: Idea[]; next_cursor: string | null }>(
      "/ideas/",
      {
        params: {
          per_page: IDEAS_PER_PAGE,
          next_cursor: pageParam,
          stock: stockParam,
          sector: sectorParam,
          user: userParam,
          draft: draftParam,
          idea_type: ideaTyeParam,
          trade_open_only: tradeOpenOnlyParam,
          trade_closed_only: tradeClosedOnlyParam,
          ordering_preference: orderBy ? orderBy : IdeaOrdering.DEFAULT,
          sorting_preference: sortDirection ? sortDirection : IdeaSorting.DESC,
        },
      },
    )

    return data
  },

  /**
   * POST /ideas/
   */
  create: async (values: Partial<Idea> & { participants: number[] | undefined }) => {
    const { data } = await axiosClient.post<Idea>("/ideas/", values)

    return data
  },

  update: async ({ ideaId, values }: { ideaId: number; values: Partial<IIdeaCreateForm> }) => {
    const { data } = await axiosClient.put<Idea>(`/ideas/${ideaId}/`, values)

    return data
  },

  newRevision: async ({
    ideaId,
    revision,
  }: {
    ideaId: number
    revision: Partial<IdeaRevision>
  }) => {
    const { data } = await axiosClient.post<IdeaRevision>(`/ideas/${ideaId}/revisions/`, revision)

    return data
  },

  publishDraft: async (ideaId: number) => {
    const { data } = await axiosClient.put<Idea>(`/ideas/${ideaId}/publish/`)

    return data
  },

  /**
   * GET /ideas/stats/
   */
  getIdeasStats: async () => {
    const { data } = await axiosClient.get<{ unseen_ideas_count: number }>("/ideas/stats/")

    return data
  },

  /**
   * GET /ideas/trade/insights/
   */
  getIdeasTradeInsight: async () => {
    const { data } = await axiosClient.get<IdeaTradesInsights>("/ideas/trades/insights")

    return data
  },

  /**
   * POST /ideas/${ideaId}/seen/
   */
  setIdeaAsSeen: async (ideaId: number) => {
    const { data } = await axiosClient.post<Record<string, never>>(`/ideas/${ideaId}/seen/`)

    return data
  },

  /**
   * POST /ideas/trades/${tradeId}/open/
   */
  openTrade: async (tradeId: number) => {
    const { data } = await axiosClient.post<IdeaTrade>(`/ideas/trades/${tradeId}/open/`)

    return data
  },

  /**
   * POST /ideas/trades/${tradeId}/close/
   */
  closeTrade: async (tradeId: number) => {
    const { data } = await axiosClient.post<IdeaTrade>(`/ideas/trades/${tradeId}/close/`)

    return data
  },

  search: async ({
    id,
    idea_type,
    stock,
    sector,
    user,
  }: {
    id?: number
    idea_type?: IdeaType
    stock?: number
    sector?: number
    user?: number
  }) => {
    const { data } = await axiosClient.get<Idea[]>("/ideas/search/", {
      params: {
        id,
        idea_type,
        stock,
        sector,
        user,
      },
    })

    return data
  },

  conversations: {
    /**
     * POST /ideas/conversations/${conversationId}/read/
     */
    setAsRead: async (conversationId: number) => {
      const { data } = await axiosClient.post<Record<string, never>>(
        `/ideas/conversations/${conversationId}/read/`,
      )

      return data
    },
  },

  messages: {
    /**
     * POST /ideas/conversations/${conversationId}/messages/
     */
    create: async ({
      conversationId,
      values,
    }: {
      conversationId: number
      values: Partial<ConversationMessage>
    }) => {
      const { data } = await axiosClient.post<ConversationMessage>(
        `/ideas/conversations/${conversationId}/messages/`,
        values,
      )
      return data
    },
    /**
     * GET /ideas/conversations/${conversationId}/messages/ paginated
     */
    getInfiniteMessages: async (pageParam: string, conversationId: number) => {
      const { data } = await axiosClient.get<{ data: ConversationMessage[]; next_cursor: string }>(
        `/ideas/conversations/${conversationId}/messages/`,
        {
          params: { per_page: 15, next_cursor: pageParam },
        },
      )

      return data
    },
    /**
     * PUT /ideas/messages/${messageId}/
     */
    update: async ({
      messageId,
      values,
    }: {
      messageId: number
      values: Partial<ConversationMessage>
    }) => {
      const { data } = await axiosClient.put<ConversationMessage>(
        `/ideas/messages/${messageId}/`,
        values,
      )

      return data
    },
  },
})

export default ideas
