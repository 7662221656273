import styled from "@emotion/styled"

interface FactoryIconProps {
  width?: string
  height?: string
  margin?: number | string
  color?: string
}

const FactorySvgIcon = styled.svg<FactoryIconProps>(({ width, height, margin, color }) => ({
  width: width || "25px",
  height: height || "25px",
  margin: margin || 0,
  fill: color || "#FFFFFF",
}))

/**
 * Factory icon taken from www.svgrepo.com
 */
const FactoryIcon = ({ width, height, margin, color }: FactoryIconProps) => {
  return (
    <FactorySvgIcon
      viewBox="0 0 512 512"
      width={width}
      height={height}
      margin={margin}
      color={color}
    >
      <path d="M384 64l.387 256H368l-96-128-16 128-96-128-16 128-96-128-16 128v160h448V64h-32v256h-32V64h-32zM64 352h48v32H64v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zM64 416h48v32H64v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32z" />
    </FactorySvgIcon>
  )
}

export default FactoryIcon
