import { useApi } from "./useApi"
import { useQuery } from "@tanstack/react-query"

const useQueryIdeasTradeInsight = () => {
  const { scientApi } = useApi()
  const { data, isLoading } = useQuery(
    ["IdeasTradeInsight"],
    () => scientApi.ideas.getIdeasTradeInsight(),
    {
      staleTime: Infinity,
    },
  )
  return { tradeInsight: data, isLoading }
}

export default useQueryIdeasTradeInsight
