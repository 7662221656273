// @ts-nocheck
import { Callout, FormGroup, InputGroup } from "@blueprintjs/core"
import { Field } from "formik"

import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const FormikTextInput = ({
  name,
  showErrors = true,
  labelProps = null,
  onEnter = () => {
    // do nothing
  },
  onEsc = () => {
    // do nothing
  },
  ...props
}) => {
  const handleKeyUp = e => {
    if (e.key === "Enter") {
      onEnter()
    }
    if (e.key === "Escape") {
      onEsc()
    }
  }

  if (labelProps) {
    return (
      <Field name={name}>
        {({ field: { name, value, onChange }, form: { touched, errors } }) => {
          const handleChange = params => {
            const {
              target: { value },
            } = params
            onChange({ target: { name, value, type: "text" } })
          }
          return (
            <FormGroup {...labelProps}>
              <InputGroup
                type="text"
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyUp}
                fill
                {...props}
              />
              {touched[name] && errors[name] && showErrors && (
                <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                  {errors[name]}
                </Callout>
              )}
            </FormGroup>
          )
        }}
      </Field>
    )
  }

  return (
    <Field name={name}>
      {({ field: { name, value, onChange }, form: { touched, errors } }) => {
        const handleChange = params => {
          const {
            target: { value },
          } = params
          onChange({ target: { name, value, type: "text" } })
        }
        return (
          <>
            <InputGroup
              type="text"
              value={value ?? ""}
              onChange={handleChange}
              onKeyDown={handleKeyUp}
              fill
              {...props}
            />
            {touched[name] && errors[name] && showErrors && (
              <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                {errors[name]}
              </Callout>
            )}
          </>
        )
      }}
    </Field>
  )
}
