// @ts-nocheck
import React, { useState } from "react"
import { dropWhile, head, isEmpty, last, takeWhile } from "ramda"
import { ControlGroup, Button, HTMLSelect, NumericInput, Icon } from "@blueprintjs/core"

import { Callout } from "../../../../../../components"

const BottomButtons = ({
  isOwnerOrAdmin,
  handleSubmit,
  updateRow,
  name,
  periodOpts,
  sequenceError,
  setSequenceError,
}) => {
  const [startOpts, setStartOpts] = useState(periodOpts)
  const [endOpts, setEndOpts] = useState(periodOpts)
  const [start, setStart] = useState(head(periodOpts))
  const [end, setEnd] = useState(last(periodOpts))
  const methodOpts = ["Const", "%Seq", "%YoY", "MAV"]
  const [method, setMethod] = useState(head(methodOpts))
  const [amount, setAmount] = useState("")

  const handleStart = ({ currentTarget: { value } }) => {
    setSequenceError([])
    setEndOpts(dropWhile(x => x !== value, periodOpts))
    setStart(value)
  }
  const handleEnd = ({ currentTarget: { value } }) => {
    setSequenceError([])
    setStartOpts(takeWhile(x => x !== value, periodOpts))
    setEnd(value)
  }

  return (
    <div>
      {!isEmpty(sequenceError) && (
        <Callout
          intent="warning"
          title="One or more datapoints are missing to perform calculation"
          body={
            <p>
              <span>Check your period settings: </span>
              {sequenceError.map(name => (
                <span key={name}>{name}</span>
              ))}
              <span>...</span>
            </p>
          }
        />
      )}
      {isOwnerOrAdmin && (
        <ControlGroup style={{ textAlign: "center" }}>
          <Button
            data-cy="DSFSubmit"
            intent="primary"
            onClick={handleSubmit}
            icon="floppy-disk"
            text={`Save ${name}`}
          />
          <div style={{ width: "8px" }} />
          <HTMLSelect data-cy="DSFStart" options={startOpts} value={start} onChange={handleStart} />
          <HTMLSelect data-cy="DSFEnd" options={endOpts} value={end} onChange={handleEnd} />
          <HTMLSelect
            data-cy="DSFMethod"
            options={methodOpts}
            value={method}
            onChange={({ currentTarget: { value } }) => setMethod(value)}
          />
          <NumericInput
            data-cy="DSFAmount"
            placeholder={"Amount"}
            stepSize={0.1}
            minorStepSize={null}
            majorStepSize={null}
            // value={amount}
            rightElement={["%Seq", "%YoY"].includes(method) ? <Icon icon="percentage" /> : null}
            onValueChange={valueAsNumber => setAmount(valueAsNumber)}
          />
          <Button
            data-cy="DSFUpdateRow"
            onClick={() => updateRow({ start, end, method, amount })}
            icon="edit"
            text="fill cells"
            disabled={(amount === "") | !isEmpty(sequenceError)}
          />
        </ControlGroup>
      )}
    </div>
  )
}

export default BottomButtons
