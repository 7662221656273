// @ts-nocheck
import React from "react"
import { ButtonWithDrawer } from "../../../../../../components"

const TextResults = ({ index, analyse, rnk }) => (
  <ButtonWithDrawer
    dataCy={`TextResults${index}`}
    buttonProps={{
      icon: "align-left",
      text: "Text results",
    }}
    drawerProps={{ icon: "align-left", title: "Text results", size: "55%" }}
  >
    <div style={{ margin: "20px" }}>
      <pre>
        {analyse[`text_results_of_regression_${rnk}`].split("\n").map((item, key) => (
          <React.Fragment key={key}>
            {item.replace(/ /g, "\u00A0")}
            <br />
          </React.Fragment>
        ))}
      </pre>
    </div>
  </ButtonWithDrawer>
)

export default TextResults
