import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"

/**
 * Breakpoint to fit content depending of sreen's width.
 * We set only one breakpoint at 1650px.
 * Above 1650px width is fixed to 1650px and margin set to auto in order to center the ModelUpdatesContainer,
 * Before we let flex do the work.
 */
const breakpoints: { [index: string]: number } = {
  xl: 1650,
}

const mq = Object.keys(breakpoints)
  .map(key => [key, breakpoints[key]] as [string, number])
  .reduce((prev, [key, breakpoint]) => {
    prev[key] = `@media (min-width: ${breakpoint}px)`
    return prev
  }, {} as { [index: string]: string })

export const NocContainer = styled(FlexContainer)`
  height: 100%;
  grid-area: content;
  overflow: auto;
`

export const NestedNocContainer = styled(FlexContainer)`
  width: 99%;
  ${mq["xl"]} {
    width: 1650px;
  }
`
