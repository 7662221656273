// @ts-nocheck
import React, { useState } from "react"
import * as R from "ramda"
import { useDrop } from "react-dnd"
import { Form } from "formik"
import { Button, Classes } from "@blueprintjs/core"
import update from "immutability-helper"

import itemTypes from "./itemTypes"
import Item from "./Item"
import { ColKindDialog } from "./ColKindDialog"

const style = {
  padding: "8px",
  margin: "8px 0",
  overflowY: "auto",
  maxHeight: "700px",
}

const Columns = ({ values, setValues, initialCol, handleClose, isValid }) => {
  const [showColKind, setShowColKind] = useState(false)

  const cards = values.colDefs.cols

  const handleCollapse = col =>
    setValues({
      colDefs: {
        ...values.colDefs,
        cols: R.map(
          x =>
            x.active
              ? R.dissoc("active", x)
              : x.colId === col.colId
              ? R.assoc("active", true, x)
              : R.dissoc("active", x),
          values.colDefs.cols,
        ),
      },
    })

  const findCard = colId => {
    const card = cards.filter(c => c.colId === colId)[0]
    return {
      card,
      index: cards.indexOf(card),
    }
  }

  const moveCard = (colId, atIndex) => {
    const { card, index } = findCard(colId)
    const newCards = update(cards, {
      $splice: [
        [index, 1],
        [atIndex, 0, card], // move tab from 1 to 0
      ],
    })
    const newValues = { colDefs: { ...values.colDefs, cols: newCards } }
    return setValues(newValues)
  }
  const [, drop] = useDrop({ accept: itemTypes.CARD })

  return (
    <div style={{ overflowY: "auto" }}>
      <Form>
        <div ref={drop} style={style}>
          {cards.map((card, index) => (
            <Item
              key={card.colId}
              values={values}
              setValues={setValues}
              card={card}
              moveCard={moveCard}
              findCard={findCard}
              index={index}
              handleCollapse={handleCollapse}
            />
          ))}
        </div>
        <div style={{ margin: "0 8px" }}>
          <Button
            fill
            icon="plus"
            onClick={() => setShowColKind("separationCol")}
            text="Add item"
          />
        </div>

        <ColKindDialog
          values={values}
          setValues={setValues}
          showColKind={showColKind}
          setShowColKind={setShowColKind}
          initialCol={initialCol}
        />

        <div className={Classes.DIALOG_FOOTER} style={{ margin: "10px" }}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button icon="remove" text="Cancel" onClick={handleClose} />
            <Button
              disabled={!isValid}
              type="submit"
              intent="primary"
              icon="floppy-disk"
              text="Save"
            />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Columns
