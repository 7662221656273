// @ts-nocheck
import React from "react"
import { FormGroup, TextArea as BP5TextArea } from "@blueprintjs/core"

export const TextArea = ({
  field, // { name, value, onChange, onBlur }
  //form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  labelProps = null,
  ...props
}) => {
  return (
    <>
      {labelProps ? (
        <FormGroup {...labelProps}>
          <BP5TextArea type="text" fill {...field} {...props} />
        </FormGroup>
      ) : (
        <BP5TextArea type="text" fill {...field} {...props} />
      )}
    </>
  )
}
