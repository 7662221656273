// @ts-nocheck
import {
  FormikFloatInput,
  FormikIntegerInput,
  FormikSelect,
  FormikSwitch,
  FormikTextArea,
} from "./fields"

export const DynamicField = ({ fieldDef }) => {
  const { name, type, desc, choices } = fieldDef
  switch (type) {
    case "Select": {
      const options = choices.map(([label, value]) => ({ label, value }))
      return <FormikSelect name={name} labelProps={{ label: desc }} options={options} />
    }
    case "PositiveSmallInteger":
      return <FormikIntegerInput name={name} labelProps={{ label: desc }} />
    case "Boolean":
      return <FormikSwitch name={name} label={desc} />
    case "Decimal":
      return <FormikFloatInput name={name} labelProps={{ label: desc }} />
    case "Text":
      return <FormikTextArea name={name} labelProps={{ label: desc }} />
    default:
      return <p>{fieldDef.name}</p>
  }
}
