import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer, FlexContainerAndItem } from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"

export const NoModelUpdatesPlaceholder = styled(FlexContainer)({
  height: "100%",
  width: "100%",
})

export const SpinnerContainer = styled(FlexContainerAndItem)({
  overflow: "hidden",
  padding: "10px 0 10px 0",
})

export const dateCss = css({
  margin: "8px 4px",
  color: `${SCIENT_COLORS.orange4} !important`,
})
