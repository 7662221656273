// @ts-nocheck
import { isEmpty } from "ramda"
import * as yup from "yup"

export const TfRowDefsSchema = yup.object().shape({
  rowDefs: yup.array().of(
    yup.object().shape({
      rowId: yup.string().required(),
      frontOpts: yup.object().shape({
        name: yup.string().when("formulaItems", {
          is: value => value && isEmpty(value) === false,
          then: () => yup.string().required(),
          otherwise: () => yup.string(),
        }),
        styles: yup.object().shape({
          bold: yup.string(),
          italic: yup.string(),
          focus: yup.string(),
          frontColor: yup.string(),
        }),
        decimals: yup.object().shape({
          min: yup.number().required().min(0).integer(),
          max: yup.number().required().min(yup.ref("min")).integer(),
        }),
        pct: yup.boolean(),
      }),
    }),
  ),
})
