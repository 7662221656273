// @ts-nocheck
import React from "react"
import { Tag } from "@blueprintjs/core"

const Item = ({ item, active, fetch }) => (
  <Tag
    onClick={fetch}
    active={active}
    interactive
    toggle
    style={{ marginRight: "3px" }}
    size="mini"
  >
    {item.level}.{item.name}
  </Tag>
)

export default Item
