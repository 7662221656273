// @ts-nocheck
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"

import { formatNumber } from "../../../../../../../_shared"
import { minusOneDayData } from "../../../../../../../_shared/highcharts"

const options = (dataSet, dsforecast, data, pct) => {
  return {
    title: null,
    chart: {
      type: "line",
      height: (8 / 16) * 100 + "%",
    },
    rangeSelector: {
      inputEnabled: false,
      allButtonsEnabled: true,
      buttons: [
        {
          type: "month",
          count: 6,
          text: "6m",
        },
        {
          type: "year",
          count: 1,
          text: "1y",
        },
        {
          type: "year",
          count: 3,
          text: "3y",
        },
        {
          type: "year",
          count: 5,
          text: "5y",
        },
        {
          type: "all",
          text: "ALL",
        },
      ],
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        day: "%d-%m-%y",
        month: "%m-%y",
        year: "%y",
      },
    },
    yAxis: {
      title: {
        text: dataSet.unit,
      },
      labels: {
        formatter: function () {
          return formatNumber(this.value, pct, true)
        },
      },
    },
    tooltip: {
      crosshairs: [true],
      headerFormat: "{point.x:%d-%m-%Y}",
      pointFormatter: function () {
        return "<p>" + this.series.name + ": " + formatNumber(this.y, pct, true) + "</p>"
      },
      valueDecimals: 2,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: `${dsforecast.name} - ${dsforecast.owner}`,
        data: minusOneDayData(data),
      },
    ],

    legend: {
      enabled: true,
      floating: true,
      layout: "horizontal",
      align: "right",
      borderWidth: 0,
      verticalAlign: "top",
      x: -10,
    },
  }
}

const Charts = ({ dataSet, dsforecast, data, pct }) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options(dataSet, dsforecast, data, pct)}
      />
      {JSON.stringify(dsforecast.data)}
    </div>
  )
}

export default Charts
