import { rowClassRules } from "../../../Desks/Sheet/NextGenTimeFrame/utils/classRules"
import { AgGridFilter, ScientCellStyle, ScientValueFormatter } from "../../components/agGridMapping"
import { useAgGrid } from "../../components/hooks/useAgGrid"
import { getContextMenuItems } from "../../utils/getContextMenuItems"
import FullScreenGrid from "./FullScreenGrid"

export interface IGridProps {
  /**
   * Rows and columns to be displayed in ag-grid
   */
  rows: Record<string, string | number | null>[]
  columns: {
    field: string
    filter: AgGridFilter | boolean
    valueFormatter: ScientValueFormatter
    cellStyle: ScientCellStyle
    hide?: boolean
  }[]
  /**
   * Label to be displayed on top of the grid container
   * Info to be displayed on top right of the grid container
   * through FullScreen Children
   */
  label?: string
  children?: React.ReactNode
}

const Grid = ({ rows, columns, label, children }: IGridProps) => {
  const { gridRef, sizeColumnsToFit, defaultColDef, columnsData } = useAgGrid({ columns })

  return (
    <FullScreenGrid
      label={label}
      ref={gridRef}
      rowClassRules={rowClassRules}
      rowData={rows}
      columnDefs={columnsData}
      defaultColDef={defaultColDef}
      onFirstDataRendered={sizeColumnsToFit}
      suppressColumnVirtualisation={true}
      rowHeight={25}
      suppressCellFocus={true}
      getContextMenuItems={params => getContextMenuItems(params)}
    >
      {children}
    </FullScreenGrid>
  )
}

export default Grid
