// @ts-nocheck
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { assoc, path, pathOr, pick, prop, toUpper, isEmpty } from "ramda"

import rForecastDuck from "../../../../../../state/modules/rForecasts"
import { Loader } from "../../../../../components"
import { mergeAndFilterByFreq } from "./utils"

import Consensus from "./Consensus"
import { getAllTabs } from "../../../utils"
import Table from "./Table"
import { NonIdealState } from "@blueprintjs/core"

const View = ({ activeTab, stockSlug, showDelete }) => {
  const dispatch = useDispatch()

  const profile = useSelector(prop("profile"))

  const loadingForecast = useSelector(pathOr(false, ["rForecasts", "loadingDetail"]))
  const { forecast, freq } = path(["userstockconfigs", stockSlug], profile)

  const stock = useSelector(path(["stockDetail", stockSlug, "stockData"]))
  const reports = useSelector(pathOr([], ["stockDetail", stockSlug, "reportData", "results"])).map(
    assoc("forecast", false),
  )
  const rFEntries = useSelector(
    pathOr([], ["rForecasts", stockSlug, "forecasts", forecast, "rfentries"]),
  ).map(assoc("forecast", true))

  const entries = mergeAndFilterByFreq(stock, freq, { reports, rFEntries })

  useEffect(() => {
    if (forecast) {
      dispatch(rForecastDuck.actions.fetchRForecast({ stkSlug: stockSlug, fcstSlug: forecast }))
    }
  }, [dispatch, stockSlug, forecast])

  if (isEmpty(entries)) {
    return <NonIdealState icon="moon" title="There is no entries for this stock." />
  }

  const diffAE = entries.length - reports.length
  const colOffset = diffAE >= 4 ? 4 : diffAE
  const lastReportIndex = reports.length - 1
  const targetCol = toUpper(entries[lastReportIndex + colOffset].slug)

  const options = pick(
    ["showInactive", "showEmpty"],
    path(["userstockconfigs", stockSlug], profile),
  )

  const allTabs = getAllTabs(stockSlug, profile)

  if (loadingForecast) return <Loader title="Loading forecast…" />

  const tabDef = allTabs[activeTab]

  return (
    <>
      {activeTab === "consensus" ? (
        <Consensus
          stockSlug={stockSlug}
          stock={stock}
          profile={profile}
          options={options}
          allTabs={allTabs}
          activeTab={activeTab}
        />
      ) : (
        <Table
          tabDef={tabDef}
          entries={entries}
          stock={stock}
          options={options}
          showDelete={showDelete}
          stockSlug={stockSlug}
          targetCol={targetCol}
        />
      )}
    </>
  )
}

export default View
