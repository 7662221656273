import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

/**
 * This function takes a time string (hours:minutes) in the local timezone
 * and returns the date of the current day with that time in UTC,
 * formatted as 'yyyy-MM-ddTHH:mmZ'.
 */

export const localTimeToDateTimeStringUTC = (time: string): string => {
  // Split the time into hours and minutes and convert them to numbers
  const [hour, minute] = time.split(":").map(Number)

  // Create a new Date object in the local timezone
  const currentDate = new Date()

  // Set the hours and minutes to the currentDate
  currentDate.setHours(hour, minute)

  // Retrieve UTC date
  const utcDate = utcToZonedTime(currentDate, "UTC")

  // Return the UTC date in ISO string
  return format(utcDate, "yyyy-MM-dd'T'HH:mm'Z'")
}
