// @ts-nocheck
import * as Yup from "yup"

export const reportSchema = Yup.object().shape({
  year: Yup.number().moreThan("1970").required("Required"),
  period: Yup.string()
    .matches(/^[QFH]Y?\d*$/, { message: "Must be one of Q1, Q2, H1, Q3, Q4, H2, FY" })
    .required("Required"),
  start: Yup.date().required("Required"),
  end: Yup.date()
    .when("start", (start, schema) => start && schema.min(start))
    .required("Required"),
  release_date: Yup.date()
    .when("end", (end, schema) => end && schema.min(end))
    .required("Required"),
})
