// @ts-nocheck
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Classes, Dialog, Divider } from "@blueprintjs/core"
import { pathOr, prop } from "ramda"
import generate from "project-name-generator"

import desksDuck from "../../../../state/modules/desks"
import monitorsDuck from "../../../../state/modules/monitors"
import { InputGroup, Loader } from "../../../components"
import { UserGroupsCheckbox } from "../components"
import { useGetDeskOwnerName } from "../utils"

const EditActiveDesk = ({
  activeDeskId,
  userDesks,
  userDesk,
  isOpen,
  toggleEditActiveDesk,
  hasWritePermission,
  userId,
}) => {
  const desk = useSelector(pathOr({}, ["desks", "desks", "data", activeDeskId]))
  const deskOwnerId = desk.owner_id
  const deskOwnerName = useGetDeskOwnerName(desk)

  const [name, setName] = useState(desk.name)
  const [groups, setGroups] = useState(desk.groups)

  const handleText = ({ target: { value } }) => {
    setName(value)
  }

  const editUserDesk = { ...userDesk, str_name: `${deskOwnerName}>${name}` }
  const newUserDesks = userDesks.map(e => (e.id !== editUserDesk.id ? e : editUserDesk))

  const deskReady = prop("ready", desk)
  const monitorsReady = useSelector(pathOr(null, ["monitors", "ready"]))

  const dispatch = useDispatch()
  const handleSubmit = () => {
    dispatch(
      desksDuck.actions.updateDesk({
        ...desk,
        name,
        groups,
      }),
    )
    setTimeout(() => {
      dispatch(desksDuck.actions.upsertUserDesks(newUserDesks))
    }, 1000)
    toggleEditActiveDesk()
  }

  const handleAddMonitor = () => {
    dispatch(monitorsDuck.actions.createMonitor({ name: generate({ number: true }).dashed, desk }))
    toggleEditActiveDesk()
  }

  const handleCancel = () => {
    toggleEditActiveDesk()
    setGroups(desk.groups)
    setName(desk.name)
  }

  return (
    <Dialog
      className="bp5-dark"
      title="Edit desk"
      icon="cog"
      isOpen={isOpen}
      onClose={handleCancel}
    >
      {(!deskReady || !monitorsReady) && <Loader style={{ position: "relative", zIndex: 5 }} />}

      {userId === deskOwnerId || hasWritePermission ? (
        <>
          <div className={Classes.DIALOG_BODY}>
            <InputGroup
              style={{ maxWidth: "220px" }}
              inputProps={{
                value: name,
                onChange: handleText,
                onEnter: handleSubmit,
                placeholder: "Enter new name",
              }}
            />

            <UserGroupsCheckbox groups={groups} setGroups={setGroups} />
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button icon="cross" text="Cancel" onClick={handleCancel} />
              <Button intent="primary" icon="floppy-disk" text="Edit Desk" onClick={handleSubmit} />
            </div>
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <Divider style={{ margin: "12px 0" }} />
            <Button
              fill
              icon="plus"
              text="Add monitor"
              onClick={handleAddMonitor}
              intent="primary"
            />
          </div>
        </>
      ) : (
        <div className={Classes.DIALOG_BODY}>
          <p style={{ fontSize: "14px" }}>Edit is not allowed. You have read-only access.</p>
          <p style={{ fontSize: "14px" }}>Desk's owner: {deskOwnerName}.</p>
        </div>
      )}
    </Dialog>
  )
}

export default EditActiveDesk
