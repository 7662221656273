// @ts-nocheck
import {
  grossProfit,
  ebitda,
  ebitReported,
  ebita,
  profitBeforeTax,
  netProfitFromContinuingOperations,
  netProfitForThePeriod,
  cfo,
  opfcf,
  // profitBeta,
} from "."

export const endFieldGrowthYoY = endField => ({
  target: [`${endField}_growth_readonly`],
  source: [endField],
  method: "growthYoY",
})
export const endFieldGrowthQoQ = endField => ({
  target: [`${endField}_growth_qoq_readonly`],
  source: [endField],
  method: "growthQoQ",
})

export const groupValue = ({ endField }) => {
  const groupProfitMargingReadonly = {
    target: ["profit_margin_readonly"],
    nume: [["profit"]],
    denom: [["sales"]],
    method: "divis",
    override: ["profit_margin_override"],
  }
  return endField === "sales"
    ? [
        groupProfitMargingReadonly,
        endFieldGrowthYoY(endField),
        endFieldGrowthQoQ(endField),
        grossProfit,
        ebitda,
        ebitReported,
        ebita,
        profitBeforeTax,
        netProfitFromContinuingOperations,
        netProfitForThePeriod,
        cfo,
        opfcf,
        // profitBeta,
      ]
    : [endFieldGrowthYoY(endField)]
}

export const segValue = ({ endField, pathBase }) =>
  endField === "sales"
    ? [
        {
          target: [...pathBase, `${endField}_growth_readonly`],
          source: [...pathBase, endField],
          method: "growthYoY",
        },
        {
          target: [...pathBase, `${endField}_growth_qoq_readonly`],
          source: [...pathBase, endField],
          method: "growthQoQ",
        },
        {
          target: [...pathBase, "profit_margin_readonly"],
          nume: [[...pathBase, "profit"]],
          denom: [[...pathBase, "sales"]],
          method: "divis",
          override: [...pathBase, "profit_margin_override"],
        },
      ]
    : [
        {
          target: [...pathBase, `${endField}_growth_readonly`],
          source: [...pathBase, endField],
          method: "growthYoY",
        },
        {
          target: [...pathBase, "profit_margin_readonly"],
          nume: [[...pathBase, "profit"]],
          denom: [[...pathBase, "sales"]],
          method: "divis",
          override: [...pathBase, "profit_margin_override"],
        },
      ]

export const organicErase = ({ endField, pathBase }) => ({
  targets: [
    [...pathBase, `${endField}_price_growth`],
    [...pathBase, `${endField}_volume_growth`],
    [...pathBase, `${endField}_other_organic_growth`],
  ],
  method: "erase",
})

export const organicSum = ({ endField, pathBase }) => ({
  target: [...pathBase, `${endField}_organic_growth`],
  add: [
    [...pathBase, `${endField}_price_growth`],
    [...pathBase, `${endField}_volume_growth`],
    [...pathBase, `${endField}_other_organic_growth`],
  ],
  method: "sum",
})

export const scopeErase = ({ endField, pathBase }) => ({
  targets: [
    [...pathBase, `${endField}_acquisition_growth`],
    [...pathBase, `${endField}_fx_growth`],
    [...pathBase, `${endField}_other_scope_growth`],
  ],
  method: "erase",
})

export const scopeSum = ({ endField, pathBase }) => ({
  target: [...pathBase, `${endField}_scope_growth`],
  add: [
    [...pathBase, `${endField}_acquisition_growth`],
    [...pathBase, `${endField}_fx_growth`],
    [...pathBase, `${endField}_other_scope_growth`],
  ],
  method: "sum",
})
export const organicEraseQoq = ({ endField, pathBase }) => ({
  targets: [
    [...pathBase, `${endField}_price_growth_qoq`],
    [...pathBase, `${endField}_volume_growth_qoq`],
    [...pathBase, `${endField}_other_organic_growth_qoq`],
  ],
  method: "erase",
})

export const organicSumQoq = ({ endField, pathBase }) => ({
  target: [...pathBase, `${endField}_organic_growth_qoq`],
  add: [
    [...pathBase, `${endField}_price_growth_qoq`],
    [...pathBase, `${endField}_volume_growth_qoq`],
    [...pathBase, `${endField}_other_organic_growth_qoq`],
  ],
  method: "sum",
})

export const scopeEraseQoq = ({ endField, pathBase }) => ({
  targets: [
    [...pathBase, `${endField}_acquisition_growth_qoq`],
    [...pathBase, `${endField}_fx_growth_qoq`],
    [...pathBase, `${endField}_other_scope_growth_qoq`],
  ],
  method: "erase",
})

export const scopeSumQoq = ({ endField, pathBase }) => ({
  target: [...pathBase, `${endField}_scope_growth_qoq`],
  add: [
    [...pathBase, `${endField}_acquisition_growth_qoq`],
    [...pathBase, `${endField}_fx_growth_qoq`],
    [...pathBase, `${endField}_other_scope_growth_qoq`],
  ],
  method: "sum",
})
