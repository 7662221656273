/** @jsxImportSource @emotion/react */
import { Divider } from "@blueprintjs/core"
import { useState } from "react"

import { IAccountBookPairs } from "../types"
import Exposures from "./Exposures"
import { RisksContainer, dividerCss } from "./Risks.styled"
import RisksHeader from "./RisksHeader"
import useRiskExposuresQuery from "./hooks/useRiskExposuresQuery"

interface IRiskProps {
  selectedFilters: IAccountBookPairs
}

const Risks = ({ selectedFilters }: IRiskProps) => {
  const [fullScreen, setFullScreen] = useState(false)

  /**
   * Get riskExposures based on selectedFilters
   */
  const { riskExposures, isLoading } = useRiskExposuresQuery({
    accountBookPairs: selectedFilters,
  })

  return (
    <RisksContainer flexDirection="column">
      <RisksHeader
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        keyMetrics={riskExposures?.key_metrics}
        isLoading={isLoading}
      />
      <Divider css={dividerCss} />
      <Exposures
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
        exposures={riskExposures?.exposures}
        isLoading={isLoading}
        selectedFilters={selectedFilters}
      />
    </RisksContainer>
  )
}

export default Risks
