// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import brokerStocksDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchBrokerStocks, fetchBrokerStocksOk, fetchBrokerStocksError },
} = brokerStocksDuck

const fetchBrokerStocksEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchBrokerStocks.type),
    mergeMap(action =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/stocks/${action.payload}/brokerstocks/?next=3`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response: { results } }) => {
          return fetchBrokerStocksOk(results)
        }),
        catchError(error => of(fetchBrokerStocksError(error.xhr.response))),
      ),
    ),
  )

export default fetchBrokerStocksEpic
