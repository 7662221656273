// @ts-nocheck
import React, { useState } from "react"
import { head, join, map, props } from "ramda"
import { MultistepDialog, DialogStep } from "@blueprintjs/core"

import { SelectSource } from "./SelectSource"
import { SelectFormula } from "./SelectFormula"
import { SelectCol } from "./SelectCol"
import { SelectPeriod } from "./SelectPeriod"
import { InsertMeanMedian } from "./InsertMeanMedian"
import { InsertLag } from "./InsertLag"
import { randomid } from "../../../../../../../../../../utils"
import { getNiceFormulaItem } from "../../../../../utils"

export const FormulaPanels = ({
  values,
  setValues,
  card,
  showPanels,
  setShowPanels,
  fieldList,
  factorList,
}) => {
  const [selectedSource, setSelectedSource] = useState(
    showPanels === "new"
      ? "existingCol"
      : showPanels.includes("@")
      ? "existingFormula"
      : "existingCol",
  )

  const regex = /((?:@|#)\w+)((?:\||\^)-?\d+)?(\|ME\w+)?({\w+,-?\d+})?/gi //to capture each element inside a formulaItem
  const initialState =
    showPanels === "new"
      ? ["", "", "", ""] // initilize array with 4 items (1: colId ; 2: period ; 3: Mean/Median ; 4: lag) to be able to put elements in order
      : props([1, 2, 3, 4], regex.exec(showPanels)) // take interesting elements
  const [panelsState, setPanelsState] = useState(initialState)

  const formulaItems = card.formulaItems ? card.formulaItems : []
  const cols = values.colDefs.cols

  const niceFormulaItem = join(
    " ",
    panelsState.map(p =>
      getNiceFormulaItem({
        item: p,
        cols,
        fieldList,
        factorList,
      }),
    ),
  )

  // to find col assoc to panelsState. Need it to have kind of col to filter displaying of panel step
  const regexCol = /#(\w+)/gi
  const colId = panelsState[0].includes("#") && head(props([1], regexCol.exec(panelsState[0])))
  const col = head(cols.filter(c => c.colId === colId))
  const kind = col && col.kind

  const handleClose = () => {
    setShowPanels(false)
  }

  return (
    <MultistepDialog
      className="bp5-dark sct-multistep-dialog"
      icon="insert"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      isOpen={showPanels}
      onClose={handleClose}
      finalButtonProps={{
        disabled:
          selectedSource === "existingFormula" ||
          (selectedSource === "existingCol" && kind === "stockParam")
            ? panelsState[0] === ""
            : panelsState[0] === "" || panelsState[1] === "",
        intent: "primary",
        text: showPanels === "new" ? "Add item" : "Edit item",
        onClick: () => {
          const newFormulaItem = join("", panelsState)

          showPanels === "new"
            ? setValues({
                colDefs: {
                  ...values.colDefs,
                  cols: map(
                    col =>
                      col.colId === card.colId
                        ? {
                            ...col,
                            formulaItems: [
                              ...formulaItems,
                              { item: newFormulaItem, itemId: randomid() },
                            ],
                          }
                        : col,
                    cols,
                  ),
                },
              })
            : setValues({
                colDefs: {
                  ...values.colDefs,
                  cols: map(
                    col =>
                      col.colId === card.colId
                        ? {
                            ...col,
                            formulaItems: formulaItems.map(formulaItem =>
                              formulaItem.item === showPanels
                                ? { item: newFormulaItem, itemid: formulaItem.itemId }
                                : formulaItem,
                            ),
                          }
                        : col,
                    cols,
                  ),
                },
              })
          setShowPanels(false)
        },
      }}
      title={niceFormulaItem}
    >
      <DialogStep
        id="selectSource"
        panel={
          <SelectSource
            panelsState={panelsState}
            setPanelsState={setPanelsState}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />
        }
        title="Select source"
      />

      {selectedSource === "existingFormula" && (
        <DialogStep
          id="selectFormula"
          panel={
            <SelectFormula
              cols={cols}
              panelsState={panelsState}
              setPanelsState={setPanelsState}
              niceFormulaItem={niceFormulaItem}
            />
          }
          title="Select existing formula"
          nextButtonProps={{ disabled: panelsState[0] === "" }}
        />
      )}

      {selectedSource === "existingCol" && (
        <DialogStep
          id="selectCol"
          panel={
            <SelectCol
              cols={cols}
              panelsState={panelsState}
              setPanelsState={setPanelsState}
              niceFormulaItem={niceFormulaItem}
            />
          }
          title="Select existing column"
          nextButtonProps={{ disabled: panelsState[0] === "" }}
        />
      )}

      {selectedSource === "existingCol" && panelsState[0] !== "" && kind !== "stockParam" && (
        <DialogStep
          id="selectPeriod"
          panel={
            <SelectPeriod cols={cols} panelsState={panelsState} setPanelsState={setPanelsState} />
          }
          title="Select period"
          nextButtonProps={{ disabled: panelsState[1] === "" }}
        />
      )}

      {(selectedSource === "existingFormula" ||
      (selectedSource === "existingCol" && kind === "stockParam")
        ? panelsState[0] !== ""
        : selectedSource === "existingCol" && panelsState[1] !== "") && (
        <DialogStep
          id="meanOrMedian"
          panel={
            <InsertMeanMedian
              panelsState={panelsState}
              setPanelsState={setPanelsState}
              selectedSource={selectedSource}
            />
          }
          title="Insert Mean ou Median"
        />
      )}

      {selectedSource === "existingCol" && panelsState[1] !== "" && kind !== "stockParam" && (
        <DialogStep
          id="lag"
          panel={
            <InsertLag
              panelsState={panelsState}
              setPanelsState={setPanelsState}
              showPanels={showPanels}
            />
          }
          title="Insert Lag"
        />
      )}
    </MultistepDialog>
  )
}
