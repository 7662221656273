import { css } from "@emotion/react"
import styled from "@emotion/styled"

/**
 * Definition of props for Flexbox
 */

export type FlexContainerProps = {
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse"
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse"
  flexFlow?: string
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline"
  alignContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
  gap?: string
  rowGap?: string
  columnGap?: string
}

export const flexContainerCss = (props: FlexContainerProps) =>
  css({
    display: "flex",
    flexDirection: props.flexDirection,
    flexWrap: props.flexWrap,
    flexFlow: props.flexFlow,
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
    alignContent: props.alignContent,
    gap: props.gap,
    rowGap: props.rowGap,
    columnGap: props.columnGap,
  })

/**
 * Provides a div for quick use in DOM when using only flex Parent props
 */
export const FlexContainer = styled.div(flexContainerCss)

export type FlexItemProps = {
  order?: number
  flexGrow?: number
  flexShrink?: number
  flexBasis?: number | string
  alignSelf?: "auto" | "flex-start" | "flex-end " | "center" | "baseline" | "stretch"
}
/**
 * Each items in flex have a default value of flex: 1
 * flex: 1 is a shortcut for: flex-grow: 0, flex-shrink: 1 and flex-basis: auto
 * so we put the default values in.
 */
export const flexItemCss = (props: FlexItemProps) =>
  css({
    order: props.order,
    flexGrow: props.flexGrow ? props.flexGrow : 0,
    flexShrink: props.flexShrink ? props.flexShrink : 1,
    flexBasis: props.flexBasis ? props.flexBasis : "auto",
    alignSelf: props.alignSelf,
  })

/**
 * Provides a div for quick use in DOM when using only flex Children props
 */
export const FlexItem = styled.div(flexItemCss)

/**
 * Provides a div for quick use in DOM when using both flex Parent and Children props
 * -> This means that a flex container is also an item of a flex parent container
 * -> cc https://css-tricks.com/snippets/css/a-guide-to-flexbox/
 */
export const FlexContainerAndItem = styled.div<FlexItemProps & FlexContainerProps>([
  flexItemCss,
  flexContainerCss,
])
