// @ts-nocheck
import { Button, Classes, H5, Popover } from "@blueprintjs/core"

import { useDispatch } from "react-redux"
import { addSingleItem } from "../../../../../../state/modules/search"
import sheetsDuck from "../../../../../../state/modules/sheets"
import { OmniSuggest } from "../../../../../components"
import { selectStSrcKeys } from "../utils"

export const PrimarySource = ({ sheet }) => {
  const stSrc = sheet.st_sources.length > 0 && sheet.st_sources[0]
  const dispatch = useDispatch()

  const handleSubmit = item => {
    dispatch(addSingleItem({ item }))
    dispatch(
      sheetsDuck.actions.updateSheet({
        id: sheet.id,
        st_sources: [selectStSrcKeys(item)],
        rf_sources: [],
      }),
    )
  }
  return (
    <Popover
      content={
        <div style={{ width: "300px" }}>
          <H5 style={{ margin: "0 0 12px 12px" }}>Select primary source</H5>
          <OmniSuggest onSubmit={handleSubmit} exclude={{ rf: true, ds: true, dsf: true }} />
        </div>
      }
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      placement="bottom-start"
    >
      <Button>Primary source: {stSrc ? stSrc.ticker : "Click to select"}</Button>
    </Popover>
  )
}
