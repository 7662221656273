// @ts-nocheck
export const fakeSyntheticEvent = (value, id) => ({
  persist: () => {
    // do nothing.
  },
  target: {
    type: "change",
    id,
    name: id,
    value,
  },
})
