// @ts-nocheck
/** @jsxImportSource @emotion/react */

import React from "react"
import * as R from "ramda"
import { Button, Collapse } from "@blueprintjs/core"

import { GroupName } from "../GroupName"
import { CustomFieldsChoice } from "./CustomFieldsChoice"
import { KindChoice } from "./KindChoice"
import { SourceChoice } from "./SourceChoice"
import { SplitChoice } from "./SplitChoice"

import { LoopBuilderStyled, collapseCss } from "./LoopBuilder.styled"

export const LoopBuilder = ({
  values,
  setValues,
  handleSubmit,
  activeItem,
  st_sources,
  rf_sources,
  loopingFeatures,
  changeInActiveItem,
}) => {
  const loopParams = values.loopParams

  const isCustomFieldsChoiceSelected =
    loopParams.kind === "fields" &&
    loopParams.slugs &&
    !R.includes(loopParams.slugs, ["'active': 1", "'all': 1"])

  const isOpen =
    (loopParams.kind === "splits" && loopParams.src && loopParams.slugs && changeInActiveItem) ||
    (loopParams.kind === "fields" &&
      loopParams.src &&
      loopParams.slugs &&
      isCustomFieldsChoiceSelected === false &&
      changeInActiveItem) ||
    (loopParams.kind === "fields" &&
      loopParams.src &&
      isCustomFieldsChoiceSelected &&
      !R.isEmpty(loopParams.selectedCustomFields) &&
      changeInActiveItem)

  return (
    <LoopBuilderStyled id="loopBuilderDIV">
      <GroupName loopingFeatures={loopingFeatures} />

      <SourceChoice
        values={values}
        setValues={setValues}
        st_sources={st_sources}
        rf_sources={rf_sources}
      />
      <KindChoice values={values} setValues={setValues} activeItem={activeItem} />

      {loopParams.kind === "splits" && <SplitChoice values={values} setValues={setValues} />}
      {loopParams.kind === "fields" && (
        <CustomFieldsChoice
          values={values}
          setValues={setValues}
          isCustomFieldsChoiceSelected={isCustomFieldsChoiceSelected}
        />
      )}
      <Collapse isOpen={isOpen} css={collapseCss}>
        <Button
          type="submit"
          intent="primary"
          icon="tick"
          text={changeInActiveItem && !R.isEmpty(activeItem.loop_on) ? "Validate change" : "Next"}
          onClick={handleSubmit}
          fill
          large
        />
      </Collapse>
    </LoopBuilderStyled>
  )
}
