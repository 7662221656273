// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import dataSetsDuck from "../index"
import authDuck from "../../auth"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchDataSet, fetchDataSetOk, fetchDataSetError },
} = dataSetsDuck

const fetchDataSetEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchDataSet.type),
    mergeMap(({ payload }) => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/${payload.id}`
      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchDataSetOk(response)),
        catchError(error => {
          if (error.response.detail === "Signature has expired.") {
            auth.deleteToken()
            return of(authDuck.actions.verifyTokenError(error.response.detail))
          }
          return of(fetchDataSetError(error.xhr.response))
        }),
      )
    }),
  )

export default fetchDataSetEpic
