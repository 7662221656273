// @ts-nocheck
import { Button, ButtonGroup, Tooltip } from "@blueprintjs/core"
import { useDispatch } from "react-redux"

import sheetsDuck from "../../../../../state/modules/sheets"

export const Display = ({ sheet }) => {
  const dispatch = useDispatch()

  const updateDisplay = display =>
    dispatch(sheetsDuck.actions.updateSheet({ id: sheet.id, display }))

  return (
    <ButtonGroup style={{ marginLeft: "16px" }}>
      <Tooltip content="Table" placement="bottom" openOnTargetFocus={false} compact>
        <Button
          onClick={() => updateDisplay("table")}
          active={sheet.display === "table"}
          icon="th"
        />
      </Tooltip>

      <Tooltip content="Chart" placement="bottom" openOnTargetFocus={false} compact>
        <Button
          onClick={() => updateDisplay("chart")}
          active={sheet.display === "chart"}
          icon="timeline-line-chart"
        />
      </Tooltip>
    </ButtonGroup>
  )
}
