export type ModelUpdate = {
  model_updated_at: string
  stock: {
    id: number
    bloomberg_code: string
  }
  analyst: {
    id: number | null
    username: string | null
  }
  company: {
    id: number
    name: string
  }
  years: Year[]
}

export type Year = {
  year: number
  period?: string
  updates?: Updates
  quarters: {
    period: string
    updates: Updates
  }[]
  half_years: {
    period: string
    updates: Updates
  }[]
}

// Defines enum for metrics
export enum FieldType {
  KEY_PROFIT = "Key Profit",
  OSG = "OSG",
  PROFIT_MARGIN = "Profit Margin",
  SALES = "Sales",
  EBITDA = "Ebitda",
  EBIT = "Ebit",
  NET_INCOME = "Net Income",
}

export const fromMetricString = (metric: string): FieldType => {
  const enumValues: Array<FieldType> = [
    FieldType.KEY_PROFIT,
    FieldType.OSG,
    FieldType.PROFIT_MARGIN,
    FieldType.SALES,
    FieldType.EBITDA,
    FieldType.EBIT,
    FieldType.NET_INCOME,
  ]
  const metricFieldType = enumValues.filter(metricFieldType => metricFieldType === metric)
  return metricFieldType[0]
}

// Updates definition based on metrics enum
export type Updates = {
  [key in FieldType]: UpdatedMetric
}

export type UpdatedMetric = {
  id: number
  metric: FieldType
  current_value: number | null
  current_value_at: string | null
  previous_value: number | null
  previous_value_at: string | null
  value_change: number | null
  consensus_data: {
    consensus_diff: number | null
    consensus_value: number | null
    consensus_at: string | null
    err_msg: string | null
  } | null
}

export type ISourceData = {
  timestamps: Array<string>
  values: Array<number>
}

export type IChartDataElements = {
  history: ISourceData
  con_history: ISourceData
}

export type IChartData = {
  data: IChartDataElements
}
