import { formatDistanceStrict } from "date-fns"
import locale from "date-fns/locale/en-US"

import { formatDistance } from "../../../utils/date-fns-utils"

/**
 * date-fns helper to get time ago
 */

export const getDateDistance = (dateToFormat: string, addSuffix = true) => {
  if (dateToFormat) {
    return formatDistanceStrict(new Date(dateToFormat), Date.now(), {
      addSuffix,
      locale: {
        ...locale,
        formatDistance,
      },
    })
  }
}
