// @ts-nocheck
/** @jsxImportSource @emotion/react */
import { Button, ButtonGroup, H3 } from "@blueprintjs/core"
import { pick, pipe, prop } from "ramda"
import { connect } from "react-redux"
import { compose, withHandlers } from "recompose"

import dataSetEventsDuck, { initialFilters } from "../../../../state/modules/dataSetEvents"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { noMarginCss } from "../../../../styled/text.styled"
import returnSearchValue from "../../../_shared/returnSearchValue"
import { InputGroup, Select, Switch } from "../../../components"

const options = [
  { label: "All", value: "" },
  { label: "Industry Data", value: "industry_data" },
  { label: "Macro Economy", value: "macro_economy" },
  { label: "Commodity", value: "commodity" },
  { label: "Factor", value: "factor" },
  { label: "Subfactor", value: "subfactor" },
  { label: "Price", value: "price" },
  { label: "FX Rate", value: "fx_rate" },
]

const Sidebar = ({
  searching,
  search,
  from,
  dsCategory,
  hide_bbg,
  hide_agg,
  updateSearch,
  toggleFrom,
  updateDSCat,
  checkboxToggle,
  searchDSEvents,
}) => (
  <FlexContainer flexDirection="column" gap="14px">
    <Button fill icon="refresh" loading={searching} onClick={searchDSEvents} text="Refresh list" />
    <FlexContainer justifyContent="space-between" alignItems="center">
      <H3 css={noMarginCss}>Filter</H3>
      <Button
        icon="filter-remove"
        text="Clear all filters"
        onClick={() => searchDSEvents(initialFilters)}
      />
    </FlexContainer>
    <InputGroup
      inputProps={{
        type: "text",
        name: "search",
        placeholder: "Search by name or ticker",
        value: search,
        onChange: updateSearch,
        loading: searching,
        autoFocus: true,
        rightElement: search ? (
          <Button minimal icon="delete" onClick={() => searchDSEvents({ search: "" })} />
        ) : null,
      }}
    />
    <Select
      selectProps={{
        name: "dsCategory",
        options,
        value: dsCategory,
        onChange: updateDSCat,
        fill: true,
      }}
    />
    <FlexContainer flexDirection="column">
      <Switch name="hide_bbg" checked={hide_bbg} label="Hide Bloomberg" onChange={checkboxToggle} />
      <Switch
        name="hide_agg"
        checked={hide_agg}
        label="Hide Aggregated"
        onChange={checkboxToggle}
      />
    </FlexContainer>

    <ButtonGroup size="small" fill>
      <Button active={from === null} onClick={() => toggleFrom(null)} text="All" />
      <Button active={from === "week"} onClick={() => toggleFrom("week")} text="1 Week" />
      <Button active={from === "today"} onClick={() => toggleFrom("today")} text="Today" />
    </ButtonGroup>
  </FlexContainer>
)

const mapDispatchToProps = {
  searchDSEvents: dataSetEventsDuck.actions.searchDSEvents,
}

export default compose(
  connect(
    pipe(
      prop("dataSetEvents"),
      pick(["searching", "search", "from", "dsCategory", "hide_bbg", "hide_agg"]),
    ),
    mapDispatchToProps,
  ),
  withHandlers({
    updateSearch: ({ searchDSEvents }) => compose(searchDSEvents, returnSearchValue),
    toggleFrom:
      ({ searchDSEvents }) =>
      from =>
        searchDSEvents({ from: from }),
    checkboxToggle:
      ({ searchDSEvents }) =>
      ({ currentTarget: { checked, name } }) =>
        searchDSEvents({ [name]: checked }),
    updateDSCat:
      ({ searchDSEvents }) =>
      ({ currentTarget: { name, value } }) =>
        searchDSEvents({ [name]: value }),
  }),
)(Sidebar)
