// @ts-nocheck
import * as R from "ramda"
import { Button, Callout, H2 } from "@blueprintjs/core"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { InputGroup } from "../../../components"
import { useAuth } from "../../../hooks"

const Step2ChooseName = ({ value, onChange, submit }) => {
  const { profile } = useAuth()
  const [invalid, setInvalid] = useState(false)

  const userId = profile.id

  const myAnalyseNames = R.compose(
    R.map(R.prop("name")),
    R.filter(R.propEq("owner_id", userId)),
  )(useSelector(R.path(["analysis", "analyses", "list"])))

  useEffect(() => {
    setInvalid(myAnalyseNames.includes(value))
  }, [myAnalyseNames, value])

  return (
    <div className="sct-fullwidth">
      <H2>What name do you want to give to your analyse?</H2>
      {invalid && (
        <Callout style={{ margin: "12px 0" }} intent="warning" title="Name already taken">
          You already have an analyse with that name. Please, Choose another name for your analyse.
        </Callout>
      )}
      <InputGroup inputProps={{ value, onChange }} />
      <Button disabled={invalid} fill onClick={submit} text="OK" />
    </div>
  )
}
export default Step2ChooseName
