// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import desksDuck from "../."
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { deleteDesk, deleteDeskOk, deleteDeskError },
} = desksDuck

const deleteDeskEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(deleteDesk.type),
    switchMap(action => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/desks/desks/${action.payload.id}`
      return ajax({
        url,
        method: "DELETE",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(() => deleteDeskOk(action.payload)),
        catchError(error => of(deleteDeskError(error.xhr.response))),
      )
    }),
  )

export default deleteDeskEpic
