import styled from "@emotion/styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

interface SectorButtonProps {
  withBorders?: boolean
  withLeftBorderRadius?: boolean
  withRightBorderRadius?: boolean
  isActive?: boolean
}

export const SectorButton = styled.button<SectorButtonProps>(props => ({
  padding: "1px 10px 0 10px",
  fontSize: "12px",
  fontWeight: "bold",
  color: props.isActive ? "white" : `${SCIENT_COLORS.darkGray5}`,
  backgroundColor: props.isActive ? `${SCIENT_COLORS.darkGray5}` : "white",
  border: "none",
  cursor: "pointer",
  "&:hover": {
    color: "white",
    backgroundColor: `${SCIENT_COLORS.darkGray5}`,
  },
  borderLeft: props.withBorders ? `solid 1px ${SCIENT_COLORS.darkGray5}` : "",
  borderRight: props.withBorders ? `solid 1px ${SCIENT_COLORS.darkGray5}` : "",
  borderTopLeftRadius: props.withLeftBorderRadius ? "2px" : "",
  borderBottomLeftRadius: props.withLeftBorderRadius ? "2px" : "",
  borderTopRightRadius: props.withRightBorderRadius ? "2px" : "",
  borderBottomRightRadius: props.withRightBorderRadius ? "2px" : "",
}))
