// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import rForecastDuck from "../."
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchRForecasts, fetchRForecastsOk, fetchRForecastsError },
} = rForecastDuck

const fetchRForecastsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchRForecasts.type),
    mergeMap(action => {
      const { stkSlug, consensusStart } = action.payload
      const params = [`?stocks=${stkSlug}`]
      if (consensusStart) {
        params.push(`entries=${consensusStart}`)
      }
      const url = `${REACT_APP_API_DOMAIN}/api/v1/estimates/rforecasts/${params.join("&")}`
      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchRForecastsOk({ stkSlug, data: response })),
        catchError(error => of(fetchRForecastsError(error.xhr.response))),
      )
    }),
  )

export default fetchRForecastsEpic
