import { FlexContainer } from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"
import { LargeText, MediumItalicText } from "../../../styled/text.styled"
import { ScientLogoNew } from "../../components/ScientLogo/ScientLogoNew"
import { LoginFullScreen } from "../Login/Login.styled"
import { RequestPasswordResetContainer } from "./PasswordResetRequest.styled"
import PasswordResetRequestForm from "./PasswordResetRequestForm"

const PasswordResetRequest = () => {
  return (
    <LoginFullScreen alignItems="center" justifyContent="center">
      <RequestPasswordResetContainer flexDirection="column" gap="20px" alignItems="center">
        <FlexContainer flexDirection="column" alignItems="center" gap="20px">
          <ScientLogoNew height="60px" />
          <LargeText color={SCIENT_COLORS.blue6}>Reset Password</LargeText>
          <MediumItalicText color={SCIENT_COLORS.blue6}>
            Enter your email address below, and if it's associated with your account, we'll send you
            a link to reset your password
          </MediumItalicText>
          <PasswordResetRequestForm />
          <MediumItalicText color={SCIENT_COLORS.blue6}>
            For further assistance please contact the dev team [dev@scient.io]
          </MediumItalicText>
        </FlexContainer>
      </RequestPasswordResetContainer>
    </LoginFullScreen>
  )
}

export default PasswordResetRequest
