// @ts-nocheck
import React from "react"
import { useDispatch } from "react-redux"
import * as R from "ramda"
import { Formik } from "formik"
import { DndProvider } from "react-dnd"
import Backend from "react-dnd-html5-backend"

import { randomid } from "../../../../../../utils"
import sheetsDuck from "../../../../../../state/modules/sheets"
import { handleError, NonFieldErrors } from "../../../../../components/formik"
import { Loader } from "../../../../../components"
import { getFormulaItems, MscColDefsSchema, getRange } from "../utils"
import Columns from "./Columns"
import { useApi } from "../../../../../hooks/useApi"

const initialCol = kind => {
  switch (kind) {
    case "separationCol":
      return {
        active: true,
        kind,
        frontOpts: { title: "", styles: {} },
        colId: randomid(),
      }
    case "stockField":
      return {
        active: true,
        kind,
        path: "",
        freq: "m",
        lowerPeriod: 0,
        upperPeriod: 0,
        frontOpts: { titleOverride: "", styles: {}, decimals: { min: 0, max: 2 }, pct: false },
        agg: true,
        colId: randomid(),
      }
    case "stockParam":
      return {
        active: true,
        kind,
        path: "",
        frontOpts: { titleOverride: "", styles: {} },
        agg: null,
        colId: randomid(),
      }
    case "factor":
      return {
        active: true,
        kind,
        freq: "d",
        label: "",
        lowerPeriod: 0,
        upperPeriod: 0,
        frontOpts: { titleOverride: "", styles: {}, decimals: { min: 0, max: 2 }, pct: false },
        agg: true,
        colId: randomid(),
      }
    case "formula":
      return {
        // formula
        active: true,
        kind,
        formula: "",
        frontOpts: { title: "", styles: {}, decimals: { min: 0, max: 2 }, pct: false },
        agg: true,
        colId: randomid(),
      }
    default:
      throw new Error("Unkown type of formula")
  }
}

const ColContent = ({ sheet, editColItems, setEditColItems }) => {
  const index = R.findIndex(R.propEq("colId", editColItems.colId))(sheet.colDefs.cols)

  const { scientApi } = useApi()

  const cols = R.compose(
    // R.map(R.mergeDeepRight({ frontOpts: { decimals: { min: 0, max: 0 } } })),
    editColItems.colId === -1 ? R.identity : R.adjust(index, R.assoc("active", true)),
  )(sheet.colDefs.cols)

  const colDefs = { ...sheet.colDefs, cols: cols } // colDefs with active col

  // colDefs with key 'formulaItems' for formula item (formulaIems is an object with keys item and itemId)
  const formatedColDefs = {
    ...colDefs,
    cols: R.map(
      col =>
        col.kind === "formula" ? { ...col, formulaItems: getFormulaItems(col.formula) } : col,
      colDefs.cols,
    ),
  }

  const handleClose = () => setEditColItems({ show: false })

  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{ colDefs: formatedColDefs }}
      validationSchema={MscColDefsSchema}
      onSubmit={(rawValues, { status, setStatus, setSubmitting }) => {
        const cols = rawValues.colDefs.cols
        const sort = rawValues.colDefs.sort

        // logic to know if a col which is going to be delete (or period changed) or hide is sorting. If yes, need to clean key 'sort'
        const target = !R.isEmpty(sort) && R.head(sort).target

        let newSort = sort
        if (target) {
          const regex = /(\w+)((\+|-)(\d+))/g
          const targetElements = regex.exec(target)

          const colIdInTarget = targetElements[1]
          const periodInTarget = parseInt(targetElements[2], 10)

          const hideCol = cols.filter(col => col.hide === true)
          const isTargetHide = hideCol.some(col => col["colId"] === colIdInTarget)

          const isTargetInCols = cols.some(col => col["colId"] === colIdInTarget)

          const { lowerPeriod, upperPeriod } =
            isTargetInCols && R.head(cols.filter(col => col["colId"] === colIdInTarget))

          const rangePeriods = getRange(lowerPeriod, upperPeriod)

          const isTargetPeriodInRange = rangePeriods.includes(periodInTarget)

          // if sort target is in cols return sort with no change, if none or if sort target match hide col return sort with empty array
          newSort =
            isTargetInCols === false || isTargetPeriodInRange === false || isTargetHide ? [] : sort
        }

        const newCols = R.compose(
          R.map(col => (col.kind === "formula" ? R.dissoc("formulaItems", col) : col)),
          R.map(col =>
            col.kind === "formula" && col.formulaItems
              ? {
                  ...col,
                  formula: R.compose(
                    R.join(" "),
                    R.map(e => e.item),
                  )(col.formulaItems),
                }
              : col,
          ),
          R.map(R.dissoc("active")),
        )(cols)

        const values = {
          id: sheet.id,
          colDefs: { ...rawValues.colDefs, cols: newCols, sort: newSort },
        }

        setSubmitting(true)
        scientApi.desks.sheets.detail
          .update({ values })
          .then(() => {
            setSubmitting(false)
            dispatch(sheetsDuck.actions.fetchSheetDetail({ id: sheet.id }))
            // dispatch(sheetsDuck.actions.fetchSheetDetailOk(resp.data))
            handleClose()
          })
          .catch(e => handleError(setSubmitting, status, setStatus, e))
      }}
    >
      {({ values, setValues, setFieldValue, status, isValid, isSubmitting }) => {
        return (
          <div>
            {isSubmitting && <Loader />}
            <NonFieldErrors status={status} />

            <DndProvider backend={Backend}>
              <Columns
                values={values}
                setValues={setValues}
                initialCol={initialCol}
                handleClose={handleClose}
                isValid={isValid}
                setFieldValue={setFieldValue}
              />
            </DndProvider>
          </div>
        )
      }}
    </Formik>
  )
}

export default ColContent
