// @ts-nocheck
import React from "react"
import * as R from "ramda"
import { Button, Collapse, H3 } from "@blueprintjs/core"

import NodeItem from "./NodeItem"

const NodeListByCategory = ({ category, showCategory, setShowCategory }) => {
  const toggleShow = () =>
    setShowCategory(
      R.includes(category.name, showCategory)
        ? R.init(showCategory)
        : R.compose(R.append(category.name), R.init())(showCategory),
    )

  return (
    <div>
      <H3 onClick={toggleShow} style={{ cursor: "pointer" }}>
        <Button
          data-cy="collapse-cat"
          icon={R.includes(category.name, showCategory) ? "caret-down" : "caret-right"}
          minimal
          onClick={toggleShow}
        />
        {category.title}
      </H3>
      <Collapse isOpen={R.includes(category.name, showCategory)}>
        {R.compose(
          R.map(node => <NodeItem key={node.type} node={node} />),
          R.filter(R.prop("show")),
        )(category.nodes)}
      </Collapse>
    </div>
  )
}

export default NodeListByCategory
