// @ts-nocheck
import * as R from "ramda"

const analyseLens = index => R.lensPath(["analyses", "list", index])
const findAnalyseIndex = (id, list) => R.findIndex(R.propEq("id", id))(list)

export const fetchAnalyse = (state, { payload: { id } }) => {
  const index = findAnalyseIndex(id, state.analyses.list)
  return R.over(
    analyseLens(index),
    R.compose(R.dissoc("created"), R.assoc("fetching", true)),
    state,
  )
}

export const fetchAnalyseOk = (state, { payload: { analyse } }) => {
  const index = findAnalyseIndex(analyse.id, state.analyses.list)
  return R.set(analyseLens(index), analyse, state)
}
export const fetchAnalyseError = (state, { payload: { id, error } }) => {
  const index = findAnalyseIndex(id, state.analyses.list)
  return R.over(
    analyseLens(index),
    R.compose(R.assoc("error", error), R.dissoc("created"), R.dissoc("fetching")),
    state,
  )
}
