// @ts-nocheck
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators"

import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import dataframesDuck from "../../dataframes/index"
import { getTFSources } from "../../../../views/pages/Desks/Sheet/TimeFrame/utils"
import { of } from "rxjs"
import { ofType } from "redux-observable"
import profileDuck from "../index"

const {
  actions: { fetchSheetDetail, fetchSheetDetailOk, fetchSheetDetailError, cancelFetchSheetDetail },
} = profileDuck

const fetchSheetDetailEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchSheetDetail.type),
    mergeMap(action => {
      const { id } = action.payload
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/sheets/${id}/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(({ response }) => {
          const sheetId = response.id

          if (response.kind === "tf") {
            const existingSources = getTFSources(response.rowDefs)
            return [
              fetchSheetDetailOk(response),
              dataframesDuck.actions.fetchDataframeList({ sheetId, ids: existingSources }),
            ]
          }
          return [fetchSheetDetailOk(response)]
        }),
        catchError(error => {
          if (error.status === 404) {
            return of(fetchSheetDetailError({ id, error: error.response.detail }))
          }
          if (error.status === 500) {
            return of(fetchSheetDetailError({ id, error }))
          }
          return of(fetchSheetDetailError(error.xhr.response))
        }),
        takeUntil(
          action$.pipe(
            ofType(cancelFetchSheetDetail.type),
            filter(action => {
              return action.payload.id === id
            }),
          ),
        ),
      )
    }),
  )

export default fetchSheetDetailEpic
