// @ts-nocheck
import autodux from "autodux"

import * as actions from "./actions"

const profileDuck = autodux({
  slice: "profile",
  initial: {
    fetching: true,
    userstockconfigs: {},
  },
  actions,
})

export default profileDuck
