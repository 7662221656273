// @ts-nocheck
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { isEmpty, map, path } from "ramda"
import { MultistepDialog, DialogStep } from "@blueprintjs/core"

import { SelectType } from "./SelectType"
import { SelectNewSource } from "./SelectNewSource"
import { SelectExistingRow } from "./SelectExistingRow"
import { SelectField } from "./SelectField"
import { randomid } from "../../../../../../../../../../utils"
import { getNiceFormulaItem } from "../../../../../utils"

export const FormulaPanels = ({
  values,
  setValues,
  card,
  showPanels,
  setShowPanels,
  DFState,
  setDFState,
}) => {
  const [selectedSource, setSelectedSource] = useState(
    showPanels === "new"
      ? "newSource"
      : showPanels.includes("@")
      ? "existingRow"
      : "existingSource",
  )
  const regex = /((#|@)\w+)(\[.*\])?({\w+,-?\d+})?/gi //to capture each element inside a formulaItem
  const panelElements = regex.exec(showPanels)
  const initialState =
    showPanels === "new"
      ? {}
      : {
          sourceId: panelElements[1],
          field: panelElements[3],
          lag: panelElements[4],
        }

  const [panelsState, setPanelsState] = useState(initialState)

  const sourceId = panelsState.sourceId ? panelsState.sourceId : ""
  const field = panelsState.field ? panelsState.field : ""
  const lag = panelsState.lag ? panelsState.lag : ""

  const rowDefs = values.rowDefs

  const shared = useSelector(path(["shared"]))

  const title = sourceId + field + lag
  const niceTitle =
    sourceId && DFState
      ? getNiceFormulaItem({
          item: title,
          dataframes: DFState,
          rowDefs: values.rowDefs,
        })
      : title

  const handleClose = () => {
    setShowPanels(false)
  }

  return (
    <MultistepDialog
      className="bp5-dark sct-multistep-dialog"
      icon="insert"
      enforceFocus={false}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      isOpen={showPanels}
      onClose={handleClose}
      finalButtonProps={{
        disabled: isEmpty(sourceId) || (sourceId.includes("#") && isEmpty(field)),
        intent: "primary",
        text: showPanels === "new" ? "Add item" : "Edit item",
        onClick: () => {
          const newFormulaItem = sourceId + field + lag

          showPanels === "new"
            ? setValues({
                rowDefs: map(
                  row =>
                    row.rowId === card.rowId
                      ? {
                          ...row,
                          frontOpts: {
                            ...row.frontOpts,
                            formulaItems: [
                              ...row.frontOpts.formulaItems,
                              { item: newFormulaItem, itemId: randomid() },
                            ],
                          },
                        }
                      : row,
                  rowDefs,
                ),
              })
            : setValues({
                rowDefs: map(
                  row =>
                    row.rowId === card.rowId
                      ? {
                          ...row,
                          frontOpts: {
                            ...row.frontOpts,
                            formulaItems: row.frontOpts.formulaItems.map(formulaItem =>
                              formulaItem.item === showPanels
                                ? { item: newFormulaItem, itemId: formulaItem.itemId }
                                : formulaItem,
                            ),
                          },
                        }
                      : row,
                  rowDefs,
                ),
              })
          setShowPanels(false)
        },
      }}
      title={niceTitle}
    >
      <DialogStep
        id="selectType"
        panel={
          <SelectType
            rowDefs={rowDefs}
            panelsState={panelsState}
            setPanelsState={setPanelsState}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            DFState={DFState}
          />
        }
        title="Select source type"
      />

      {selectedSource === "newSource" && (
        <DialogStep
          id="newSource"
          panel={
            <SelectNewSource
              panelsState={panelsState}
              setPanelsState={setPanelsState}
              DFState={DFState}
              setDFState={setDFState}
            />
          }
          title="Select new Source"
          nextButtonProps={{ disabled: sourceId === "" }}
        />
      )}

      {selectedSource === "existingRow" && (
        <DialogStep
          id="existingRow"
          panel={
            <SelectExistingRow
              rowDefs={rowDefs}
              panelsState={panelsState}
              setPanelsState={setPanelsState}
            />
          }
          title="Select row / lag"
          nextButtonProps={{ disabled: sourceId === "" }}
        />
      )}

      {(selectedSource === "newSource" || selectedSource === "existingSource") && (
        <DialogStep
          id="selectField"
          panel={
            <SelectField
              panelsState={panelsState}
              setPanelsState={setPanelsState}
              shared={shared}
              DFState={DFState}
            />
          }
          title={
            sourceId.includes("st") || sourceId.includes("rf") ? "Select field/lag" : "Insert lag"
          }
        />
      )}
    </MultistepDialog>
  )
}
