// @ts-nocheck
import autodux from "autodux"

const geosDuck = autodux({
  slice: "geos",
  initial: {
    fetching: false,
    error: false,
    geos: [],
    geo: "",
  },
  actions: {
    fetchGeos: state => ({
      ...state,
      fetching: true,
    }),
    fetchGeosOk: (state, geos) => ({
      ...state,
      fetching: false,
      geos,
    }),
    fetchGeosError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),
    selectGeo: (state, geo) => ({
      ...state,
      geo,
    }),
  },
})

export default geosDuck
