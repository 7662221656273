// @ts-nocheck
import { ButtonGroup } from "@blueprintjs/core"
import { AgGridReact } from "ag-grid-react"
import * as R from "ramda"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import sheetsDuck from "../../../../../../state/modules/sheets"
import { useAuth } from "../../../../../hooks/"
import { useGetOutput, useGetWritePermission } from "../../../utils"
import { DeleteAlert, Display, FreqButton, SharedHeader } from "../../components"
import { LaunchProcessing } from "../../components/CloneSheet/LaunchProcessing"
import { contextMenu, getMilestones, rowClassRules, shapeColTitles, shapeRowData } from "../utils"
import EditModal from "./EditModal"
import QuickChart from "./QuickChart"
import TableHeader from "./TableHeader"

const Table = ({ desk, monitor, sheetId, editRowItems, setEditRowItems, pauseProcessing }) => {
  const { profile } = useAuth()
  const [openQuickChart, setOpenQuickChart] = useState({ show: false })

  const [deleteAlert, setDeleteAlert] = useState(false)
  const [wentToLastACol, handleSetWentToLastACol] = useState(false)
  const [colType, setColType] = useState("name")
  const [gridApi, setGridApi] = useState({})
  const [, setGridColumnApi] = useState({})

  const sheet = useSelector(R.path(["sheets", "list", sheetId]))

  const dataframes = useSelector(R.path(["dataframes", "byDfId"]))

  const { id, rowDefs } = sheet
  const freq = sheet.colDefs.freq
  const { colTitles, rowData } = useGetOutput(sheet.id)

  const toggleColType = () => setColType(colType === "name" ? "date" : "name")

  // Variables for permissions
  const userId = profile.id
  const deskOwnerId = desk.owner_id
  const hasWritePermission = useGetWritePermission(desk)
  // end Variables for permissions

  useEffect(() => {
    if (gridApi.setRowData) {
      gridApi.setRowData(shapeRowData(rowData, rowDefs))
    }
  }, [gridApi, rowData, rowDefs])

  const dispatch = useDispatch()

  const handleDelete = ({ rowIndex }) => {
    dispatch(
      sheetsDuck.actions.updateSheet({
        id,
        rowDefs: R.remove(rowIndex, 1, rowDefs),
      }),
    )
    setDeleteAlert(false)
  }

  const handleGoto = period => gridApi.ensureColumnVisible(period)

  const milestones = getMilestones(colTitles)

  const columnDefs = [
    {
      headerName: "",
      field: "desc",
      pinned: "left",
      width: 320,
      // rowDrag: true,
    },
    ...shapeColTitles(colTitles, colType),
  ]
  const agOptions = {
    reactNext: true,
    defaultColDef: {
      width: freq !== "w" ? 110 : 210,
      resizable: true,
    },
    columnDefs,
    rowSelection: "single",
    getRowId: params => R.path(["data", "extra", "rowId"], params),
  }

  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  /**
   * useEffect to have the last reported or the first estimate (if exists) period visible
   * when grid loading is finished
   */
  useEffect(() => {
    if (!R.isEmpty(gridApi) && !wentToLastACol) {
      gridApi.ensureColumnVisible(milestones.firstECol ? milestones.firstECol : milestones.lastACol)
      handleSetWentToLastACol(true)
    }
  }, [gridApi, milestones.firstECol, milestones.lastACol, wentToLastACol])

  return (
    <>
      <div className="sct-sheet-header-container">
        <ButtonGroup>
          <SharedHeader monitor={monitor} sheet={sheet} />
          <Display sheet={sheet} />
          {pauseProcessing === false && (
            <>
              <TableHeader
                sheet={sheet}
                handleGoto={handleGoto}
                milestones={milestones}
                colType={colType}
                toggleColType={toggleColType}
              />
              <ButtonGroup style={{ marginLeft: "50px" }}>
                <FreqButton sheet={sheet} />
              </ButtonGroup>
            </>
          )}
        </ButtonGroup>
      </div>

      <div
        className="ag-theme-balham-dark"
        style={{
          margin: "0 auto",
          height: "100%",
          width: "100%",
        }}
      >
        {pauseProcessing === true ? (
          <LaunchProcessing sheet={sheet} /> // Use when cloning a sheet
        ) : (
          <AgGridReact
            onGridReady={onGridReady}
            animateRows={true}
            scrollbarWidth={15}
            tooltipShowDelay={1}
            rowMultiSelectWithClick={true}
            rowClassRules={rowClassRules}
            sizeColumnsToFit
            getContextMenuItems={params =>
              contextMenu({
                params,
                setEdit: setEditRowItems,
                setDeleteAlert,
                dataframes,
                rowDefs,
                userId,
                deskOwnerId,
                hasWritePermission,
                setOpenQuickChart,
              })
            }
            overlayNoRowsTemplate={
              "<span style=\"padding: 10px; border: 2px solid #444; font-size: 1.4em\">No rows to show.</br>Right click to open context menu, then click on 'Edit rows'.</span>"
            }
            {...agOptions}
          />
        )}
        {openQuickChart.show && (
          <QuickChart
            openQuickChart={openQuickChart}
            setOpenQuickChart={setOpenQuickChart}
            colTitles={colTitles}
            rowData={rowData}
            rowDefs={rowDefs}
          />
        )}
        <EditModal editRowItems={editRowItems} setEditRowItems={setEditRowItems} sheet={sheet} />
        <DeleteAlert
          deleteAlert={deleteAlert}
          setDeleteAlert={setDeleteAlert}
          handleDelete={handleDelete}
          deleteMessage={`Delete ${R.pathOr(
            "",
            [deleteAlert.rowIndex, "frontOpts", "name"],
            rowDefs,
          )}?`}
        />
      </div>
    </>
  )
}

export default Table
