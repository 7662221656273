// @ts-nocheck
import { ButtonGroup } from "@blueprintjs/core"
import { AgGridReact } from "ag-grid-react"
import * as R from "ramda"
import React, { useEffect, useState } from "react"

import { SctSheetHeaderContainer } from "../../../../../../styled/layout.styled"
import { useAuth } from "../../../../../hooks/"
import { useGetNGTFGrid, useGetSheet, useGetWritePermission } from "../../../utils"
import { Display, SharedHeader } from "../../components"
import { ErrorValueRenderer, FreqButtonNGTF, PrimaryForecast, PrimarySource } from "../components"
import { NGTFSelectMethod } from "../components/SmartSelectOptions"
import { contextMenu, getMilestones, rowClassRules, shapeColTitles, shapeRowData } from "../utils"
import EditModal from "./EditModal"
import TableHeader from "./TableHeader"

const Table = ({ desk, monitor, sheetId, editRowItems, setEditRowItems }) => {
  const { profile } = useAuth()

  const [wentToLastACol, handleSetWentToLastACol] = useState(false)
  const [colType, setColType] = useState("name")
  const [gridApi, setGridApi] = useState({})
  const [, setGridColumnApi] = useState({})

  const sheet = useGetSheet(sheetId)

  const freq = sheet.tf_freq

  const { colTitles, rowData } = useGetNGTFGrid(sheet.id)

  const toggleColType = () => setColType(colType === "name" ? "date" : "name")

  // Variables for permissions
  const userId = profile.id
  const deskOwnerId = desk.owner_id
  const hasWritePermission = useGetWritePermission(desk)
  // end Variables for permissions

  useEffect(() => {
    if (gridApi.setRowData) {
      gridApi.setRowData(shapeRowData(rowData))
    }
  }, [gridApi, rowData])

  const handleGoto = period => gridApi.ensureColumnVisible(period)

  const milestones = getMilestones(colTitles)

  const columnDefs = [
    {
      headerName: "",
      field: "desc",
      pinned: "left",
      width: 320,
      cellRenderer: ErrorValueRenderer,
    },
    ...shapeColTitles(colTitles, colType),
  ]
  const agOptions = {
    reactNext: true,
    defaultColDef: {
      width: freq !== "w" ? 110 : 210,
      resizable: true,
    },
    columnDefs,
    rowSelection: "single",
    getRowId: params => R.path(["data", "extra", "rowId"], params),
  }

  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  /**
   * useEffect to have the last reported or the first estimate (if exists) period visible
   * when grid loading is finished
   */
  useEffect(() => {
    if (!R.isEmpty(gridApi) && !wentToLastACol) {
      gridApi.ensureColumnVisible(milestones.firstECol ? milestones.firstECol : milestones.lastACol)
      handleSetWentToLastACol(true)
    }
  }, [gridApi, milestones.firstECol, milestones.lastACol, wentToLastACol])

  return (
    <>
      <SctSheetHeaderContainer>
        <ButtonGroup>
          <SharedHeader monitor={monitor} sheet={sheet} setEditRowItems={setEditRowItems} />
          <Display sheet={sheet} />
          <TableHeader
            sheet={sheet}
            handleGoto={handleGoto}
            milestones={milestones}
            colType={colType}
            toggleColType={toggleColType}
          />
          {sheet.ngtf_select_method && (
            <ButtonGroup>
              <NGTFSelectMethod sheet={sheet} />
            </ButtonGroup>
          )}
          <ButtonGroup>
            <FreqButtonNGTF sheet={sheet} />
          </ButtonGroup>

          <ButtonGroup>
            <PrimarySource sheet={sheet} />
            {sheet.st_sources.length > 0 && <PrimaryForecast sheet={sheet} />}
          </ButtonGroup>
        </ButtonGroup>
      </SctSheetHeaderContainer>

      <div
        className="ag-theme-balham-dark"
        style={{
          margin: "0 auto",
          height: "100%",
          width: "100%",
        }}
      >
        <AgGridReact
          onGridReady={onGridReady}
          animateRows={true}
          scrollbarWidth={15}
          tooltipShowDelay={1}
          rowMultiSelectWithClick={true}
          rowClassRules={rowClassRules}
          sizeColumnsToFit
          getContextMenuItems={params =>
            contextMenu({
              params,
              setEdit: setEditRowItems,
              userId,
              deskOwnerId,
              hasWritePermission,
            })
          }
          overlayNoRowsTemplate={
            "<span style=\"padding: 10px; border: 2px solid #444; font-size: 1.4em\">No rows to show.</br>Right click to open context menu, then click on 'Edit rows'.</span>"
          }
          {...agOptions}
        />
      </div>
      <EditModal editRowItems={editRowItems} setEditRowItems={setEditRowItems} sheet={sheet} />
    </>
  )
}

export default Table
