import { Icon, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import InfiniteScroll from "react-infinite-scroll-component"
import { LargeText } from "../../../../styled/text.styled"
import {
  InboxContainer,
  NoWaveListPlaceholder,
  SpinnerContainer,
  WaveListContainer,
} from "./WaveList.styled"
import WaveCard from "./WaveCard"
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query"
import { Dispatch, SetStateAction } from "react"
import { Wave } from "./types/wave"
import { Button } from "../../../components"

interface ITradingProps {
  waves?: Wave[]
  selectedWave?: Wave
  setSelectedWaveId: Dispatch<SetStateAction<string | number | undefined>>
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<Wave[], unknown>>
  hasNextPage: boolean
}

const WaveList = ({
  waves,
  selectedWave,
  setSelectedWaveId,
  fetchNextPage,
  hasNextPage,
}: ITradingProps) => {
  /**
   * Display a placeholder if there's no PreviousWave yet.
   */
  if (!waves?.length) {
    return (
      <NoWaveListPlaceholder
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="25px"
      >
        <Icon icon="inbox" size={100} />
        <LargeText>There are no previous Wave yet</LargeText>
      </NoWaveListPlaceholder>
    )
  }

  return (
    <WaveListContainer flexDirection="column" flexGrow={2}>
      <InboxContainer flexDirection="column" id="waves-inbox">
        <InfiniteScroll
          dataLength={waves.length}
          style={{ display: "flex", flexDirection: "column", height: "55vh" }}
          loader={
            <SpinnerContainer justifyContent="center" flexGrow={1}>
              <Spinner intent={Intent.SUCCESS} size={SpinnerSize.SMALL} />
            </SpinnerContainer>
          }
          next={fetchNextPage}
          hasMore={hasNextPage}
          scrollableTarget="waves-inbox"
        >
          {waves.length &&
            waves.map(wave => (
              <WaveCard
                key={wave.id}
                wave={wave}
                selected={wave === selectedWave}
                setSelectedWaveId={setSelectedWaveId}
              />
            ))}
          {hasNextPage && <Button large icon="add" intent="primary" onClick={fetchNextPage} />}
        </InfiniteScroll>
      </InboxContainer>
    </WaveListContainer>
  )
}
export default WaveList
