// @ts-nocheck
import { Button, H2 } from "@blueprintjs/core"
import React from "react"
import { Col, Grid, Row } from "../../../components"

const Step3Method = ({ value, submit }) => (
  <div className="sct-fullwidth">
    <H2>What method would you like to use for prediction?</H2>
    <Grid style={{ marginTop: "24px" }}>
      <Row>
        <Col xs={6}>
          <Button fill active={value === "reg"} onClick={() => submit("reg")} text="Regressor" />
        </Col>
        <Col xs={6}>
          <Button fill active={value === "var"} onClick={() => submit("var")} text="Var" />
        </Col>
      </Row>
      <Row>
        <Col align="center" xs={6}>
          Helper for regressor
        </Col>
        <Col xs={6} align="center">
          Helper for var
        </Col>
      </Row>
    </Grid>
  </div>
)

export default Step3Method
