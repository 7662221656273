import { AxiosInstance } from "axios"
import aggregators, { IAggregatorsApi } from "./aggregators"
import auth, { IAuthApi } from "./auth"
import datasets, { IDatasetsApi } from "./datasets"
import desks, { IDesksApi } from "./desks"
import dsForecasts, { IDsForecastsApi } from "./dsForecasts"
import ideas, { IIdeasApi } from "./ideas"
import monitors, { IMonitorsApi } from "./monitors"
import noc, { INocApi } from "./noc"
import otp, { IOTPApi } from "./otp"
import reporting, { IReportingApi } from "./reporting"
import trading, { ITradingAPI } from "./trading"
import world, { IWorldApi } from "./world"
import bloomberg, { IBloombergAPI } from "./bloomberg"

export interface IScientApi {
  users: {
    get: () => Promise<IUser[]>
  }
  aggregators: IAggregatorsApi
  datasets: IDatasetsApi
  desks: IDesksApi
  dsForecasts: IDsForecastsApi
  auth: IAuthApi
  monitors: IMonitorsApi
  reporting: IReportingApi
  ideas: IIdeasApi
  noc: INocApi
  world: IWorldApi
  trading: ITradingAPI
  profile: {
    get: () => Promise<IUser>
  }
  groups: {
    get: () => Promise<
      {
        id: number
        name: string
      }[]
    >
  }
  uploadImage: (formData: FormData) => Promise<{ location: string }>
  otp: IOTPApi
  bloomberg: IBloombergAPI
}

/**
 * Defines OTP Interface for
 * two factor authentication
 */
export interface IOTP {
  otp_enabled: boolean
  verified: boolean
  otp_base32?: string
  otp_auth_url?: string
}

export interface IUser {
  id: number
  username: string
  email: string
  groups: string[]
  last_login: string
  is_superuser: boolean
  otp?: IOTP
}

export interface IGroupsAPI {
  next: number | null
  previous: number | null
  count: number
  results: { id: number; name: string }[]
}

export interface IUsersAPI {
  next: number | null
  previous: number | null
  count: number
  results: IUser[]
}

/**
 * API factory
 * Generates an API using the axiosClient instance provided
 * /!\ Do not use api in components, refer to APIProvider
 */
const api = (axiosClient: AxiosInstance) => ({
  users: {
    get: async () => {
      const { data } = await axiosClient.get<IUsersAPI>("/users/")

      return data.results
    },
  },
  aggregators: aggregators(axiosClient),
  datasets: datasets(axiosClient),
  desks: desks(axiosClient),
  dsForecasts: dsForecasts(axiosClient),
  auth: auth(axiosClient),
  monitors: monitors(axiosClient),
  reporting: reporting(axiosClient),
  ideas: ideas(axiosClient),
  world: world(axiosClient),
  profile: {
    get: async () => {
      const { data } = await axiosClient.get<IUser>("/profile")
      return data
    },
  },
  groups: {
    get: async () => {
      const { data } = await axiosClient.get<IGroupsAPI>("/groups/")
      return data.results
    },
  },
  uploadImage: async (formData: FormData) => {
    const { data } = await axiosClient.post("/uploader/image/", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    return data
  },
  noc: noc(axiosClient),
  trading: trading(axiosClient),
  otp: otp(axiosClient),
  bloomberg: bloomberg(axiosClient),
})

export default api
