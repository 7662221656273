import { css } from "@emotion/react"
import styled from "@emotion/styled"

type TextProps = {
  color?: string
  fontWeight?: string
  whiteSpace?: "normal" | "nowrap" | "pre" | "pre-line" | "pre-wrap"
}

const Text = styled.p<TextProps>(({ color, fontWeight, whiteSpace }) => ({
  margin: 0,
  color: color || "white",
  fontWeight: fontWeight || "normal",
  whiteSpace: whiteSpace || "normal",
}))

export const SmallText = styled(Text)({
  fontSize: "12px",
})

export const MediumText = styled(Text)({
  fontSize: "14px",
})

export const MediumItalicText = styled(MediumText)({
  fontStyle: "italic",
})

export const LargeText = styled(Text)({
  fontSize: "18px",
})

/**
 * Removed margin.
 * For exemple can be use with Blueprint title H1, H2... to remove margin
 */
export const noMarginCss = css({
  margin: 0,
})
