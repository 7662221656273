// @ts-nocheck
import { css } from "@emotion/react"

export const expandCollapseCss = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "12px",
})

export const insertButtonsCss = css({
  marginBottom: "6px",
})

export const backgroundCss = color =>
  css({
    backgroundColor: `${color} !important`,
  })
