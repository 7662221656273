import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { WaveStatus } from "../Trading/types/common"

export const waveStatusColor = (status: WaveStatus | undefined): string => {
  switch (status) {
    case "Created":
      return SCIENT_COLORS.blue4
    case "Received":
      return SCIENT_COLORS.orange3
    case "Passed":
      return SCIENT_COLORS.forest3
    case "Not passed":
      return SCIENT_COLORS.red3
    case "Sent":
      return SCIENT_COLORS.green6
    case "CSV Upload Error":
      return SCIENT_COLORS.red3
    default:
      console.log("No wave status received")
      return SCIENT_COLORS.gold3
  }
}
