// @ts-nocheck
import React from "react"
//import { withStateHandlers } from "recompose"
import { pick } from "ramda"
import { useDispatch } from "react-redux"
import { Grid, Row, Col } from "react-flexbox-grid"
import { Formik, Field } from "formik"
import { Button } from "@blueprintjs/core"

import dataSetsDuck from "../../../../../state/modules/dataSets"
import { TextInput, TextArea, HTMLSelect, Switch } from "../../../../components/formik/index"
import { useApi } from "../../../../hooks/useApi"

/*
Periodicity
Bloomberg code
Category
  CATEGORIES = (
      ("industry_data", "industry data"),
      ("macro_economy", "macro economy"),
      ("commodity", "commodity"),
      ("factor", "factor"),
      ("subfactor", "subfactor"),
      ("price", "price"),
      ("fx_rate", "fx rate"),
  )
Source
  SOURCES = (
      ('auto', 'auto'),
      ('bbg', 'bbg'),
      ('human', 'human'),
      ('internet', 'internet'),
  )
Comment
Suffix
Divide incoming by 100
Divide existing by 100
Multiply existing by 100
*/

const categoryOptions = [
  { label: "Industry data", value: "industry_data" },
  { label: "Macro-economy", value: "macro_economy" },
  { label: "Commodity", value: "commodity" },
  { label: "Factor", value: "factor" },
  { label: "Subfactor", value: "subfactor" },
  { label: "Price", value: "price" },
  { label: "Fx rate", value: "fx_rate" },
]

const sourceOptions = [
  { label: "Auto", value: "auto" },
  { label: "Bbg", value: "bbg" },
  { label: "Human", value: "human" },
  { label: "Internet", value: "internet" },
]
const Form = ({ dataSet }) => {
  const { scientApi } = useApi()
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={pick(["bloomberg_code", "category", "source", "active", "comment"], dataSet)}
      onSubmit={(values, { status, setStatus, setSubmitting }) => {
        scientApi.updateDataSet({ id: dataSet.id, values }).then(response => {
          setStatus({ ...status, errors: null })
          setSubmitting(false)
          return dispatch(dataSetsDuck.actions.updateDataSetOk(response.data))
        })
      }}
    >
      {({ isSubmitting, handleSubmit, handleChange, values }) => (
        <Grid>
          <Row>
            <Col xs={3} />
            <Col xs={6}>
              <Field
                labelProps={{ label: "Bloomberg code" }}
                name="bloomberg_code"
                component={TextInput}
                id="bloomberg_code"
                onChange={handleChange}
                //"data-cy": "Bloomberg Code"
              />

              <Field
                labelProps={{ label: "Category" }}
                name="category"
                component={HTMLSelect}
                id="category"
                options={categoryOptions}
                value={values.category}
                onChange={handleChange}
                //"data-cy": "Category"
              />

              <Field
                labelProps={{ label: "Source" }}
                name="source"
                component={HTMLSelect}
                id="source"
                options={sourceOptions}
                value={values.source}
                onChange={handleChange}
                //"data-cy": "Boomberg Code"
              />

              <Field
                name="active"
                component={Switch}
                checked={values.active}
                onChange={handleChange}
                label="Active"
                helpButton="If unchecked, will delete all related events from calendar."
              />

              <Field
                name="comment"
                labelProps={{ label: "Comment" }}
                rows="7"
                component={TextArea}
                value={values.comment}
              />

              <Button
                type="submit"
                disabled={isSubmitting}
                data-cy="Submit"
                onClick={handleSubmit}
                text={isSubmitting ? "Updating..." : "Update"}
                intent="primary"
                fill
              />
            </Col>
            <Col xs={3} />
          </Row>
        </Grid>
      )}
    </Formik>
  )
}

export default Form
