import { Button, InputGroup, Intent, ProgressBar } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { LargeText, MediumText } from "../../../styled/text.styled"
import { SCIENT_ROUTES } from "../../Routes"
import { ScientLogoNew } from "../../components/ScientLogo/ScientLogoNew"
import { useResetPasswordConfirmMutation } from "../../hooks"
import { createToaster } from "../../utils/createToaster"

import { SCIENT_COLORS } from "../../../styled/scientColors"
import { LoginFormContainer, LoginFullScreen } from "../Login/Login.styled"
import PasswordRequirements from "./PasswordRequirements"
import { InputPasswordResetContainer } from "./PasswordResetConfirm.styled"

export interface PasswordResetConfirmForm {
  token: string
  new_password: string
}

type PasswordRating = {
  value: number
  intent: Intent
}

const passwordLengthRatings: Record<string, PasswordRating> = {
  empty: {
    value: 0,
    intent: Intent.NONE,
  },
  low: {
    value: 0.333,
    intent: Intent.DANGER,
  },
  medium: {
    value: 0.666,
    intent: Intent.WARNING,
  },
  high: {
    value: 1,
    intent: Intent.SUCCESS,
  },
}

const PasswordResetConfirm = () => {
  const { token } = useParams<{ token: string }>()
  const [password, setPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [passwordLengthRating, setPasswordLengthRating] = useState<PasswordRating>(
    passwordLengthRatings.empty,
  ) // Default to empty
  const [metRequirements, setMetRequirements] = useState<boolean>(false)

  const navigate = useNavigate()

  /**
   * Lock button to hide or show password
   */
  const lockButton = (
    <Button
      icon={showPassword ? "eye-off" : "eye-open"}
      intent={Intent.WARNING}
      minimal={true}
      onClick={() => setShowPassword(!showPassword)}
    />
  )

  /**
   * Mutation to reset password
   */
  const resetPasswordConfirmMutation = useResetPasswordConfirmMutation({
    onSuccess: () => {
      createToaster({ message: "Password changed successfully", intent: Intent.SUCCESS })
      navigate(SCIENT_ROUTES.HOME)
    },
    onError: (error: AxiosError) => {
      createToaster({ message: error.message, intent: Intent.WARNING })
    },
  })

  /**
   * Handle confirm change password
   */
  const handleConfirm = useCallback(() => {
    if (token) {
      resetPasswordConfirmMutation.mutate({
        token,
        new_password: password,
      })
    } else {
      createToaster({ message: "Token not found", intent: Intent.WARNING })
    }
  }, [token, password, resetPasswordConfirmMutation])

  /**
   * Computes the password rating
   * If metRequirements is not satisfied rating is always low.
   */
  useEffect(() => {
    if (password.length === 0) {
      setPasswordLengthRating(passwordLengthRatings.empty)
    } else if (password.length < 9 || !metRequirements) {
      setPasswordLengthRating(passwordLengthRatings.low)
    } else if (password.length < 12) {
      setPasswordLengthRating(passwordLengthRatings.medium)
    } else {
      setPasswordLengthRating(passwordLengthRatings.high)
    }
  }, [metRequirements, password])

  const matchingPasswords = password === confirmPassword

  return (
    <LoginFullScreen alignItems="center" justifyContent="center">
      <LoginFormContainer flexDirection="column" gap="15px" alignItems="center">
        <FlexContainer flexDirection="column" gap="20px" alignItems="center">
          <ScientLogoNew height="60px" />
          <LargeText color={SCIENT_COLORS.blue6}>Change Password</LargeText>
          <MediumText color={SCIENT_COLORS.blue6}>
            Your password must contain at least 9 characters including <br /> one capital letter,
            one number and one special character.
          </MediumText>
          <PasswordRequirements password={password} setMetRequirements={setMetRequirements} />
          <InputPasswordResetContainer flexDirection="column" gap="5px">
            <InputGroup
              placeholder="Enter your password..."
              rightElement={lockButton}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={e => setPassword(e.target.value)}
              asyncControl={true}
            />
            <ProgressBar
              animate={false}
              stripes={false}
              intent={passwordLengthRating.intent}
              value={passwordLengthRating.value}
            />
          </InputPasswordResetContainer>
          <InputPasswordResetContainer flexDirection="column" gap="5px">
            <InputGroup
              placeholder="Confirm your password..."
              rightElement={lockButton}
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </InputPasswordResetContainer>
        </FlexContainer>
        <Button
          disabled={!matchingPasswords || !metRequirements}
          intent="primary"
          rightIcon="log-in"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
        {password.length > 0 && confirmPassword.length > 0 && !matchingPasswords && (
          <MediumText color={SCIENT_COLORS.red3}>Passwords do not match</MediumText>
        )}
      </LoginFormContainer>
    </LoginFullScreen>
  )
}

export default PasswordResetConfirm
