// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import generate from "project-name-generator"

import desksDuck from "../index"
import monitorsDuck from "../../monitors"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { createDesk, createDeskOk, createDeskError, upsertUserDesks },
} = desksDuck

// Create a new desk, automatically create a monitor inside.

const createDeskEpic = (action$, _, { ajax, auth }) => {
  return action$.pipe(
    ofType(createDesk.type),
    mergeMap(action =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/desks/`,
        method: "POST",
        body: action.payload,
        crossDomain: true,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(({ response }) => {
          const userDesks = action.payload.userDesks
          const lastPos = userDesks.length
          return [
            createDeskOk(response),
            upsertUserDesks([...userDesks, { desk: response.id, pos: lastPos + 1 }]),
            monitorsDuck.actions.createMonitor({
              name: generate({ number: true }).dashed,
              desk: response,
            }),
          ]
        }),
        catchError(error => {
          return of(createDeskError(error.xhr.response))
        }),
      ),
    ),
  )
}
export default createDeskEpic
