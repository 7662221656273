import { Button, Popover } from "@blueprintjs/core"

export const InfoButton = ({
  content,
  buttonProps = {},
}: {
  content: JSX.Element
  buttonProps: object
}) => (
  <Popover
    content={content}
    interactionKind="hover"
    popoverClassName="bp5-popover-content-sizing"
    placement="bottom-start"
  >
    <Button icon="info-sign" {...buttonProps} />
  </Popover>
)
