import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"

export const YearPeriodsContainer = styled(FlexContainer)({
  width: "100%",
})

export const CollapsibleFlexContainer = styled(FlexContainer)<{ isCollapsed?: boolean }>`
  // Setting big max height because using auto is not working with transition
  max-height: ${props => (props.isCollapsed ? "0" : "1000px")};
  overflow: ${props => (props.isCollapsed ? "hidden" : "visible")};
  transition: max-height 200ms ease-out;
`
