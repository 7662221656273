// @ts-nocheck
import React from "react"
import { isEmpty, without } from "ramda"
import { Button, Collapse } from "@blueprintjs/core"
import { withToggle } from "../../../../hooks"
import Item from "./Item"

const myFetch = (type, fetch, id, selected) => {
  if (selected.includes(id)) return fetch({ [type]: without([id], selected) })
  return fetch({ [type]: [...selected, id] })
}

const FilterWidget = ({ show, toggle, type, all, selected, fetch }) => (
  <div>
    <Button icon={show ? "caret-up" : "caret-down"} fill onClick={() => toggle()}>
      Filter {type}
    </Button>
    <Collapse isOpen={show}>
      <>
        {all.map(item => (
          <Item
            key={item.id}
            item={item}
            active={isEmpty(selected) || selected.includes(item.id)}
            fetch={() => myFetch(type, fetch, item.id, selected)}
          />
        ))}
      </>
    </Collapse>
  </div>
)

export default withToggle(FilterWidget)
