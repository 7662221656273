import { OverlayToaster, Position, Toast2 } from "@blueprintjs/core"
import { useQueryClient } from "@tanstack/react-query"
import { createContext, useCallback, useEffect, useMemo } from "react"

import { IUser } from "../api/index"
import { Loader } from "./components"
import { useAuth, useQueryGroups, useQueryIdeasStats, useQueyUsers } from "./hooks"

export interface IScientGlobalContext {
  groups: {
    id: number
    name: string
  }[]
  users: IUser[]
  ideasStats:
    | {
        unseen_ideas_count: number
      }
    | undefined
  updateUnseenIdeasCount?: (number: number) => void
}

/**
 * Scient Global context
 */
export const ScientGlobalContext = createContext<IScientGlobalContext>({
  groups: [],
  users: [],
  ideasStats: undefined,
  updateUnseenIdeasCount: undefined,
})

/**
 * Provides access to children to global data
 * Includes:
 * - profile
 * - users
 * - groups
 * - ideas stats
 */
const ScientGlobalStateProvider = ({ children }: { children: JSX.Element }) => {
  const { profile } = useAuth()
  const { data: groupsData, isLoading: isLoadingGroups, error: errorGroups } = useQueryGroups()
  const { data: usersData, isLoading: isLoadingUsers, error: errorUsers } = useQueyUsers()
  const {
    data: ideasStatsData,
    isLoading: isLoadingIdeasStats,
    error: errorIdeasStats,
  } = useQueryIdeasStats()

  const queryClient = useQueryClient()

  /**
   * Setup intercom when profile is loaded
   */
  useEffect(() => {
    if (profile) {
      if (process.env.REACT_APP_INTERCOM === "true") {
        // @ts-ignore
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "lk32h9na",
          name: profile?.username, // Full name
          email: profile?.email, // the email for your user
          created_at: null, // Signup date as a Unix timestamp
        })
      }
    }
  }, [profile])

  /**
   * Updates ideas stat in the cache
   */
  const updateUnseenIdeasCount = useCallback(
    (number: number) => {
      queryClient.setQueryData<{ unseen_ideas_count: number }>(["ideasStats"], ideasStatsData => {
        if (ideasStatsData) {
          const newCount = ideasStatsData.unseen_ideas_count + number
          return {
            ...ideasStatsData,
            unseen_ideas_count: newCount,
          }
        }
      })
    },
    [queryClient],
  )

  /**
   * Memoized state for context change
   * and children prop propagation
   */
  const state = useMemo(
    () => ({
      profile,
      groups: groupsData || [],
      users: usersData || [],
      ideasStats: ideasStatsData,
      updateUnseenIdeasCount,
    }),
    [profile, groupsData, usersData, ideasStatsData, updateUnseenIdeasCount],
  )

  /**
   * If one of those request fails, app cannot boot
   * and we invite the user to refresh the page
   */
  return (
    <>
      {(errorGroups || errorUsers || errorIdeasStats) && (
        <OverlayToaster position={Position.TOP}>
          <Toast2
            message="An error occurred initializing application, please refresh the page"
            intent="danger"
          />
        </OverlayToaster>
      )}
      {isLoadingGroups || errorGroups ? (
        <Loader title="Loading groups..." />
      ) : isLoadingUsers || errorUsers ? (
        <Loader title="Loading users..." />
      ) : isLoadingIdeasStats || errorIdeasStats ? (
        <Loader title="Loading stats..." />
      ) : (
        <ScientGlobalContext.Provider value={state}>{children}</ScientGlobalContext.Provider>
      )}
    </>
  )
}

export default ScientGlobalStateProvider
