import { Button } from "@blueprintjs/core"
import { useState } from "react"

import Chart from "./Chart"

export const QuickChart = () => {
  const [isChartOpen, setIsChartOpen] = useState(false)

  return (
    <div>
      <Button onClick={() => setIsChartOpen(true)} icon="timeline-line-chart" minimal>
        Quick Chart
      </Button>
      {isChartOpen && <Chart isChartOpen={isChartOpen} setIsChartOpen={setIsChartOpen} />}
    </div>
  )
}
