import { OverlayToaster, Position, Toast2 } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { useEffect, useState } from "react"

interface IToastErrorProps {
  error: AxiosError
  errorMessage: string
}

/**
 * Displayed a toater if querring the data fails.
 */

export const ToastError = ({
  error,
  errorMessage = "Unable to get data, please try to refresh the page.",
}: IToastErrorProps) => {
  const [showError, setShowError] = useState(false)

  /**
   * Effect to display the toast error if the query fail.
   */
  useEffect(() => {
    if (error) setShowError(true)
  }, [error])

  return (
    <OverlayToaster position={Position.TOP_RIGHT}>
      {showError && (
        <Toast2 message={errorMessage} intent="danger" onDismiss={() => setShowError(false)} />
      )}
    </OverlayToaster>
  )
}
