import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../hooks/useApi"
import { IChartData } from "../types/business"

export interface IChartHistoricalData {
  update_id: number | null
  with_con?: boolean
}

/**
 * React query hook to GET historical data for charting in NOC
 */

const useChartHistoricalData = ({ update_id, with_con }: IChartHistoricalData) => {
  const { scientApi } = useApi()

  const { data, error, isFetching } = useQuery<{ data: IChartData }>(
    ["chartData", { update_id: update_id, with_con: with_con }],
    () => scientApi.noc.getChartHistoricalData({ update_id: update_id, with_con: with_con }),
    { enabled: !!update_id },
  )
  return { chartData: data as IChartData | undefined, error, isFetching }
}
export default useChartHistoricalData
