// @ts-nocheck
import * as R from "ramda"

const fetchTFRowDefsReducers = {
  fetchTFRowDefs: R.assoc("fetching", true),
  fetchTFRowDefsOk: (state, { payload: { results } }) =>
    R.compose(R.assoc("fetching", false), R.assoc("results", results))(state),
  fetchTFRowDefsError: (state, { payload: { error } }) =>
    R.compose(R.assoc("fetching", false), R.assoc("error", error))(state),
}

export default fetchTFRowDefsReducers
