// @ts-nocheck
import React from "react"
import { useDrag, useDrop } from "react-dnd"
import { Tab, Tabs } from "@blueprintjs/core"

import ItemTypes from "./itemTypes"

const style = {
  width: "8em",
  border: "1px solid",
  borderColor: "rgba(255, 255, 255, 0.4)",
  borderRadius: "3px 3px",
  padding: "0 0.9rem",
  cursor: "move",
}
const TabItem = ({ id, moveTab, findTab, tab, activeTab, handleChange }) => {
  const originalIndex = findTab(id).index
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.TAB, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveTab(droppedId, originalIndex)
      }
    },
  })
  const [, drop] = useDrop({
    accept: ItemTypes.TAB,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findTab(id)
        moveTab(draggedId, overIndex)
      }
    },
  })
  const opacity = isDragging ? 0 : 1
  return (
    <>
      <div ref={node => drag(drop(node))} style={{ ...style, opacity }}>
        <Tabs animate id="navbar" onChange={handleChange} selectedTabId={activeTab}>
          <Tab key={tab.id} id={tab.id} title={tab.title}></Tab>
        </Tabs>
      </div>
    </>
  )
}
export default TabItem
