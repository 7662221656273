// @ts-nocheck
import React from "react"
import { Button, NonIdealState } from "@blueprintjs/core"

const NoSheet = ({ monitor, toggleMonitorPrefs }) => {
  return (
    <NonIdealState
      icon={"search"}
      title={`You don't have any sheet in this monitor.`}
      action={
        <>
          <p>
            <Button
              icon="plus"
              text="Open Edit monitor"
              onClick={() => toggleMonitorPrefs(monitor.id)}
              intent="primary"
            />
          </p>
        </>
      }
    />
  )
}

export default NoSheet
