// @ts-nocheck
import {
  addIndex,
  compose,
  concat,
  head,
  isEmpty,
  join,
  pathOr,
  propOr,
  reduceRight,
  reject,
} from "ramda"

import { getNiceFormulaItem } from "./index"
import { getFormulaItems } from "./getFormulaItems"

const reduceRightIndexed = addIndex(reduceRight)

export const concatWithSeparator = lists =>
  compose(
    reduceRightIndexed((list, acc, index, newList) => {
      const realIndex = newList.length - index - 1
      return concat(newList.length > 1 && realIndex !== 0 ? ["separator", ...list] : list, acc)
    }, []),
    reject(isEmpty),
  )(lists)

export const contextMenuMsc = ({
  sheet,
  params,
  target,
  method,
  fieldList,
  factorList,
  setEditRow,
  setEditCol,
  setAscendingSort,
  setDescendingSort,
  setUnsort,
}) => {
  const { column, node } = params

  const error = pathOr(null, ["data", "extra", "error"], node)

  const kind = pathOr(null, ["colDef", "kind"], column)
  const groupHeaderName = pathOr(null, ["originalParent", "colGroupDef", "headerName"], column)
  const headerName = pathOr(null, ["colDef", "headerName"], column)
  const colName = groupHeaderName + "-" + headerName

  const rowRegex = /(.+)(#)(.+)/g
  const rowNodeId = propOr(-1, "id", node)
  const rowId = node && rowNodeId.match(rowRegex) ? rowRegex.exec(rowNodeId)[1] : rowNodeId

  const colRegex = /(.+)(\+|-)(.+)/g

  // For colId do not use key 'colId' from param (generate a bug); colDefs/Fields is OK
  const colId = pathOr(-1, ["colDef", "field"], column) // children colId (= colId with time extension)
  const groupColId = column && colId.match(colRegex) ? colRegex.exec(colId)[1] : colId // group ColId (= colId without time extension)

  // to get nice Formula
  const formula =
    kind === "formula" &&
    pathOr(null, ["formula"], head(sheet.colDefs.cols.filter(e => e.colId === groupColId)))
  const formulaItems = formula && getFormulaItems(formula)
  const niceFormula =
    formula &&
    join(
      " ",
      formulaItems.map(item =>
        getNiceFormulaItem({
          item: item.item,
          cols: sheet.colDefs.cols,
          fieldList,
          factorList,
        }),
      ),
    )

  //
  const defaultItems = params.defaultItems || []

  const menuItems = [
    error && {
      name: `ERROR ${error}`,
      icon: "<span icon='warning-sign' class='bp5-icon bp5-icon-warning-sign' />",
      action: () => setEditRow({ rowId, show: true }),
      cssClasses: ["sct-row-error"],
    },
    error && {
      name: `Please edit this row and verify your formula.`,
      action: () => setEditRow({ rowId, show: true }),
      cssClasses: ["sct-row-error"],
    },

    colId !== -1 &&
      (kind === "formula"
        ? {
            name: colName,
            tooltip: niceFormula,
            icon: "<span icon='label' class='bp5-icon  bp5-icon-label' />",
          }
        : {
            name: colName,
            icon: "<span icon='label' class='bp5-icon  bp5-icon-label' />",
          }),

    colId !== -1 && "separator",

    colId !== -1 && {
      name: "Sort ascending",
      action: () => setAscendingSort(colId),
      icon:
        colId === target && method === "asc"
          ? "<span icon='caret-up' class='bp5-icon bp5-intent-primary bp5-icon-caret-up' />"
          : "<span icon='caret-up' class='bp5-icon bp5-intent-primary bp5-icon-caret-up' />",
      cssClasses: colId === target && method === "asc" && ["sct-sort-active"],
    },

    colId !== -1 && {
      name: "Sort descending",
      action: () => setDescendingSort(colId),
      icon:
        colId === target && method === "desc"
          ? "<span icon='caret-down' class='bp5-icon bp5-intent-primary bp5-icon-caret-down' />"
          : "<span icon='caret-down' class='bp5-icon bp5-intent-primary bp5-icon-caret-down' />",
      cssClasses: colId === target && method === "desc" && ["sct-sort-active"],
    },

    colId !== -1 &&
      target === colId && {
        // displayed only if sort active for the column selected
        name: "Unsort",
        action: () => setUnsort(),
        icon: "<span icon='double-caret-vertical' class='bp5-icon bp5-intent-primary bp5-icon-double-caret-vertical' />",
      },
    colId !== -1 && "separator",

    {
      name: "Edit rows",
      action: () => setEditRow({ rowId, show: true }),
      icon: "<span icon='drag-handle-horizontal' class='bp5-icon bp5-intent-primary bp5-icon-drag-handle-horizontal' />",
    },
    {
      name: "Edit columns",
      action: () => setEditCol({ colId: groupColId, show: true }),
      icon: "<span icon='drag-handle-vertical' class='bp5-icon bp5-intent-primary bp5-icon-drag-handle-vertical' />",
    },
  ]

  return concatWithSeparator([menuItems, defaultItems])
}
