// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
// import { ifElse, isEmpty, always, pipe, groupBy, map as rMap, pick, prop } from "ramda"

import geosDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchGeos, fetchGeosOk, fetchGeosError },
} = geosDuck

const fetchGeosEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchGeos.type),
    mergeMap(action =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/world/geos/${action.payload}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchGeosOk(response.results)),
        catchError(error => of(fetchGeosError(error.xhr.response))),
      ),
    ),
  )

export default fetchGeosEpic
