// @ts-nocheck
import React from "react"
import { SourceHandle } from "./handles"

const Outputs = ({ outputs }) => {
  return (
    <>
      {outputs?.map((output, index) => {
        const handleId = `|${index}|${output.param_type}`
        return (
          <SourceHandle
            {...output}
            key={handleId}
            id={handleId}
            index={index}
            length={outputs.length}
          />
        )
      })}
    </>
  )
}

export default Outputs
