/** @jsxImportSource @emotion/react */
import { Divider, Elevation, Icon, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { LargeText } from "../../../styled/text.styled"
import { capitalizeFirstLetter } from "../../../utils"
import { camelCasetoCapitalizedSpacedWords } from "../../../utils/camelCasetoCapitalizedSpacedWords"
import IdeaPerformanceMetrics from "./IdeaPerformanceMetrics"
import {
  GlobalMetricsContainer,
  IdeaTradesInsightsWidgetContainer,
  InsightsContainer,
  UserMetricsContainer,
  dividerCss,
} from "./IdeaTradesInsightsWidget.styled"
import { IdeaTradesInsights } from "./types"
import { SpinnerContainer } from "../../pages/Ideas/List/Inbox/Inbox.styled"
import { useMemo } from "react"

interface IIdeaTradesInsightsWidgetProps {
  tradeInsight?: IdeaTradesInsights
  isLoading: boolean
}

const IdeaTradesInsightsWidget = ({ tradeInsight, isLoading }: IIdeaTradesInsightsWidgetProps) => {
  const { user_performances, global_performances } = useMemo(() => {
    if (tradeInsight) {
      const { user_performances, ...global_performances } = tradeInsight
      return { user_performances, global_performances }
    }
    return {}
  }, [tradeInsight])

  return (
    <IdeaTradesInsightsWidgetContainer elevation={Elevation.THREE}>
      <InsightsContainer flexDirection="column" gap="10px" alignItems="center">
        <FlexContainer gap="5px">
          <LargeText>Ideas</LargeText>
          <Icon icon="lightbulb" size={20} />
        </FlexContainer>
        <Divider css={dividerCss} />
        {!isLoading ? (
          <>
            <GlobalMetricsContainer flexDirection="column">
              {global_performances &&
                Object.entries(global_performances).map(([timeFrame, metrics], index) => (
                  <IdeaPerformanceMetrics
                    key={index}
                    label={camelCasetoCapitalizedSpacedWords(timeFrame)}
                    performance={metrics.performance}
                    numberOfTrades={metrics.number_of_trades}
                    isGlobalAllTime={index === 0}
                  />
                ))}
            </GlobalMetricsContainer>
            <Divider css={dividerCss} />
            <UserMetricsContainer flexDirection="column" flexWrap="wrap">
              {user_performances &&
                Object.entries(user_performances).map(([userName, metrics], index) => (
                  <IdeaPerformanceMetrics
                    key={index}
                    label={`${index + 1}. ${capitalizeFirstLetter(userName)}`}
                    performance={metrics.performance}
                    numberOfTrades={metrics.number_of_trades}
                  />
                ))}
            </UserMetricsContainer>
          </>
        ) : (
          <SpinnerContainer justifyContent="center" alignItems="center" flexGrow={1}>
            <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
          </SpinnerContainer>
        )}
      </InsightsContainer>
    </IdeaTradesInsightsWidgetContainer>
  )
}

export default IdeaTradesInsightsWidget
