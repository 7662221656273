// @ts-nocheck
import * as R from "ramda"

export const fetchAnalyses = R.compose(
  R.assocPath(["analyses", "fetching"], true),
  R.dissocPath(["analyses", "error"]),
)

export const fetchAnalysesOk = (state, { payload: { analyses } }) =>
  R.compose(
    R.assocPath(["analyses", "fetching"], false),
    R.assocPath(["analyses", "list"], analyses),
  )(state)

export const fetchAnalysesError = (state, { payload: { error } }) =>
  R.compose(
    R.assocPath(["analyses", "fetching"], false),
    R.assocPath(["analyses", "error"], error),
  )(state)
