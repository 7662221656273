// @ts-nocheck
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { pathOr, toLower } from "ramda"
import { Button, MenuItem, H5, H6, Colors } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"

import { allStockFields, randomid } from "../../utils"

export const ConnectedStockPathSelect3 = ({ dataCy, value, update, shared, dataframe }) => {
  const fetchingDataframes = useSelector(pathOr({}, ["dataframes", "fetching"]))
  const sharedReady = shared.ready

  if (!dataframe || fetchingDataframes === true || !shared || sharedReady === false) {
    return <Button fill disabled text="Loading..." />
  }

  return (
    <StockPathSelect3
      shared={shared}
      dataframe={dataframe}
      update={update}
      value={value}
      dataCy={dataCy}
    />
  )
}

const StockPathSelect3 = ({ shared, dataframe, update, value, dataCy }) => {
  const [query, setQuery] = useState("")

  const allFields = allStockFields(shared, dataframe)

  const filteredResults = allFields.filter(field => {
    if (["header", "subHeader"].includes(field.type)) return true
    const lowerQ = toLower(query)
    const titleIncludesQ = toLower(field.text).includes(lowerQ)
    if (field.path) {
      const cleanedPath = field.path.replace("'value'", "")
      return cleanedPath.includes(lowerQ) || titleIncludesQ
    }
    return titleIncludesQ
  })

  const itemRenderer = (field, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    if (["title1", "title2"].includes(field.type))
      return (
        <li key={randomid()} className="bp5-menu-header">
          {field.type === "title1" ? (
            <H5 style={{ color: Colors.GRAY4 }}>{field.text}</H5>
          ) : (
            <H6 style={{ color: Colors.GRAY5 }}>{field.text}</H6>
          )}
        </li>
      )
    return (
      <MenuItem
        active={modifiers.active}
        key={field.path}
        onClick={handleClick}
        text={field.text}
      />
    )
  }

  const handleQueryChange = query => setQuery(query)

  const selectedField = value
  const buttonText = selectedField ? selectedField : "Add Field"

  return (
    <Select
      className="sct-fullwidth"
      items={filteredResults}
      itemRenderer={itemRenderer}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={update}
      onQueryChange={handleQueryChange}
    >
      <Button fill icon="form" rightIcon="caret-down" text={buttonText} data-cy={dataCy} />
    </Select>
  )
}
