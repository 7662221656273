import styled from "@emotion/styled"
import { FlexContainer, FlexContainerProps } from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"

type PortfolioManagementContainerProps = {
  selectedTab: string
} & FlexContainerProps

export const PortfolioManagementContainer = styled(
  FlexContainer,
)<PortfolioManagementContainerProps>(props => ({
  gridArea: "content",
  height: "100%",
  overflow: "hidden",
  ".bp5-tabs": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    ".bp5-tab-panel": {
      height: "100%",
      flexGrow: 1,
      overflow: "hidden",
      margin: 0,
    },
    ".bp5-tab-list": {
      backgroundColor:
        props.selectedTab === "risks" ? SCIENT_COLORS.indigo1 : SCIENT_COLORS.cerulean1,
      justifyContent: "center",
      paddingTop: "2px",
      paddingBottom: "6px",
      height: "40px",
    },
    ".bp5-tab": {
      color: "white",
    },
    ".bp5-tab-list > *:not(:last-child)": {
      marginRight: "40px",
    },
    ".bp5-tab[aria-selected=true]": {
      fontWeight: "bold",
      boxShadow: "inset 0 -2px 0 white",
    },
    ".bp5-tab:not([aria-disabled=true]):hover": {
      color: SCIENT_COLORS.darkWhite,
    },
  },
}))
