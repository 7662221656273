import { Intent, OverlayToaster, Position } from "@blueprintjs/core"

export const createToaster = ({ message, intent }: { message: string; intent: Intent }) => {
  const toaster = OverlayToaster.create({
    position: Position.TOP,
  })
  toaster.show({
    message,
    intent,
  })
}

export const toaster = OverlayToaster.create({
  position: Position.TOP_RIGHT,
})
