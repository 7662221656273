// @ts-nocheck
import { Callout, FormGroup, InputGroup } from "@blueprintjs/core"
import { Field } from "formik"

import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const FormikIntegerInput = ({ name, labelProps, ...props }) => {
  // Combine 'allowed' and 'pattern' to only accept positive or negative number
  // Paternn avoid to validate with only '-'

  if (labelProps) {
    return (
      <Field name={name}>
        {({ field: { name, value, onChange }, form: { touched, errors } }) => {
          const handleChange = ({ target: { value } }) => {
            const allowed = /^-?[0-9]+$/gm
            if (value === "" || value === "-" || allowed.test(value)) {
              return onChange({ target: { name, value } })
            }
          }
          return (
            <FormGroup {...labelProps}>
              <InputGroup
                type="text"
                pattern="-?[0-9]+"
                value={value}
                onChange={handleChange}
                fill
                {...props}
              />
              {touched[name] && errors[name] && (
                <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                  {errors[name]}
                </Callout>
              )}
            </FormGroup>
          )
        }}
      </Field>
    )
  }

  return (
    <Field name={name}>
      {({ field: { name, value, onChange }, form: { touched, errors } }) => {
        const handleChange = ({ target: { value } }) => {
          const allowed = /^-?[0-9]+$/gm
          if (value === "" || value === "-" || allowed.test(value)) {
            return onChange({ target: { name, value } })
          }
        }
        return (
          <>
            <InputGroup
              type="text"
              pattern="-?[0-9]+"
              value={value}
              onChange={handleChange}
              fill
              {...props}
            />
            {touched[name] && errors[name] && (
              <Callout intent="primary" style={{ color: SCIENT_COLORS.blue5 }}>
                {errors[name]}
              </Callout>
            )}
          </>
        )
      }}
    </Field>
  )
}
