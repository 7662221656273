// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import dataSetsDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { updateDataSet, updateDataSetOk, updateDataSetError },
} = dataSetsDuck

const updateDataSetEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(updateDataSet.type),
    switchMap(({ payload }) => {
      const body = payload
      const url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/${body.id}/`
      return ajax({
        url,
        method: "PATCH",
        body,
        crossDomain: true,
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => updateDataSetOk(response)),
        catchError(error => of(updateDataSetError(error.xhr.response))),
      )
    }),
  )

export default updateDataSetEpic
