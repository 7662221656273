// @ts-nocheck
import { H1 } from "@blueprintjs/core"
import * as R from "ramda"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { createAnalyse } from "../../../../state/modules/analysis"
import { cleanSourceForBackend } from "../utils"

import Step1Dep from "./Step1Dep"
import Step2Name from "./Step2Name"
import Step3Method from "./Step3Method"
import Step4Validate from "./Step4Validate"

const New = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [step, setStep] = useState(1)

  const [dep, setDep] = useState({
    source: null,
    name: "",
    freq: "",
    path: "",
  })
  const [name, setName] = useState("")

  const [method, setMethod] = useState("")

  const created = R.find(R.propEq("created", true))(
    useSelector(R.path(["analysis", "analyses", "list"])),
  )

  const handleSetDep = dep => {
    setDep(dep)
    if (!name) {
      setName(dep.name)
    }
    setStep(2)
  }

  const handleSetName = ({ target: { value } }) => {
    setName(value)
  }

  const handleSetMethod = params => {
    setMethod(params)
    setStep(4)
  }

  const handleCreate = () => {
    const dependent = cleanSourceForBackend(dep)
    const analyse = { name, method, dependent }
    dispatch(createAnalyse({ analyse }))
  }

  useEffect(() => {
    if (created) {
      navigate(`/analysis/${created.id}`)
    }
  }, [created, navigate])

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        margin: "24px",
        height: "100%",
      }}
    >
      <H1>Create a new analyse</H1>
      {step === 1 && <Step1Dep variable={dep} submit={handleSetDep} />}
      {step === 2 && <Step2Name value={name} onChange={handleSetName} submit={() => setStep(3)} />}
      {step === 3 && <Step3Method value={method} submit={handleSetMethod} />}
      {step === 4 && <Step4Validate dep={dep} name={name} method={method} submit={handleCreate} />}
    </div>
  )
}

export default New
