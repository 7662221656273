// @ts-nocheck
import { combineEpics } from "redux-observable"

import searchEpic from "./searchEpic"
import fetchDataframeListEpic from "./fetchDataframeListEpic"
import fetchAdditionalDataframeEpic from "./fetchAdditionalDataframeEpic"

const dataframesEpics = combineEpics(
  searchEpic,
  fetchDataframeListEpic,
  fetchAdditionalDataframeEpic,
)

export default dataframesEpics
