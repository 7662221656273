import { datesFromName, dateToPeriod, nthDate } from "."

export const nthName = (name: string, nth: number) => {
  const [[, , periodicity]] = name.matchAll(/(\d{4})(Y|H|Q|M|BM|M|W|D|R)?(\d+)?/g)

  /**
   * We use the || {} to provide a default empty object
   * if the function datesFromName call returns undefined
   */
  const { start } = datesFromName(name) || {}
  if (start) {
    const nthStart = nthDate(start, periodicity, nth)
    const newName = dateToPeriod({ start: nthStart, periodicity }).name
    return newName
  } else {
    throw new Error("No start date")
  }
}
