// @ts-nocheck
import React from "react"
import { useDispatch } from "react-redux"
import * as R from "ramda"
import { Formik } from "formik"
import { DndProvider } from "react-dnd"
import Backend from "react-dnd-html5-backend"

import { randomid } from "../../../../../../utils"
import sheetsDuck from "../../../../../../state/modules/sheets"
import { handleError, NonFieldErrors } from "../../../../../components/formik"
import { Loader } from "../../../../../components"
import { MscRowDefsSchema } from "../utils"
import Rows from "./Rows"
import { useApi } from "../../../../../hooks/useApi"

// import { mscBasicIn } from "../utils"

const initialRow = kind =>
  kind === "row"
    ? {
        active: true,
        kind,
        frontOpts: { title: "" },
        rowId: randomid(),
      }
    : kind === "stockRows"
    ? {
        active: true,
        kind,
        stocks: [],
        rowId: randomid(),
      }
    : {
        //kind === aggregate
        active: true,
        kind,
        sources: [],
        method: "median",
        frontOpts: { titleOverride: "" },
        rowId: randomid(),
      }

const RowContent = ({ sheet, editRowItems, setEditRowItems }) => {
  const { scientApi } = useApi()

  const index = R.findIndex(R.propEq("rowId", editRowItems.rowId))(sheet.rowDefs.rows)

  const rows = R.compose(
    editRowItems.rowId === -1 ? R.identity : R.adjust(index, R.assoc("active", true)),
  )(sheet.rowDefs.rows)
  const rowDefs = { ...sheet.rowDefs, rows: rows }

  const handleClose = () => setEditRowItems({ show: false })

  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{ rowDefs: rowDefs }}
      validationSchema={MscRowDefsSchema}
      onSubmit={(rawValues, { status, setStatus, setSubmitting }) => {
        const rows = R.map(R.dissoc("active"), rawValues.rowDefs.rows)
        const values = { id: sheet.id, rowDefs: { ...rawValues.rowDefs, rows: rows } }

        setSubmitting(true)
        scientApi.desks.sheets.detail
          .update({ values })
          .then(resp => {
            setSubmitting(false)
            dispatch(sheetsDuck.actions.fetchSheetDetailOk(resp.data))
            handleClose()
          })
          .catch(e => handleError(setSubmitting, status, setStatus, e))
      }}
    >
      {({ values, setValues, status, isValid, isSubmitting }) => {
        return (
          <div>
            {isSubmitting && <Loader />}
            <NonFieldErrors status={status} />

            <DndProvider backend={Backend}>
              <Rows
                values={values}
                setValues={setValues}
                initialRow={initialRow}
                handleClose={handleClose}
                isValid={isValid}
              />
            </DndProvider>
          </div>
        )
      }}
    </Formik>
  )
}

export default RowContent
