// @ts-nocheck
import styled from "@emotion/styled"
import { css } from "@emotion/react"

export const LabelStyled = styled.p({
  fontSize: "15px",
  margin: "0 0 4px",
})

export const cardCss = css({
  padding: "10px 8px",
  overflow: "auto",
})

export const gridCss = css({
  padding: "0px",
})

export const smallMenuCss = css({
  width: "200px",
  height: "220px",
  overflowY: "auto",
  overflowX: "visible",
})
export const normalMenuCss = css({
  width: "250px",
  height: "220px",
  overflowY: "auto",
  overflowX: "visible",
})
export const wideMenuCss = css({
  width: "340px",
  height: "240px",
  overflowY: "auto",
  overflowX: "visible",
})

export const menuItemCss = css({
  padding: "0",
  fontSize: "13px",
})

export const inputGroupCss = css({
  margin: "0 12px 0 4px",
})
