// @ts-nocheck
import { flatten, filter, replace } from "ramda"

// To  JSON.parse string we need to replace simple quote by double quote
const parseString = string => JSON.parse(replace(/'/g, '"', string))

export const formatPathIntoObject = path => {
  // Get <SourceID>
  const regexSource = /\[(\w+)]/
  const sourceId = flatten(path.map(e => e.match(regexSource))).filter(x => x !== null)[1]

  // IS PRIMARY SOURCE ?
  const primarySrc = sourceId === "stsrc0"

  const regex = /{.+}/
  // GET <fields>
  const filterFields = filter(e => e.includes("fields"), path)[0]
  const fields = filterFields ? filterFields.match(regex).filter(x => x !== null)[0] : null

  // GET <splits>
  const filterSplits = filter(e => e.includes("splits"), path)[0]
  const splits = filterSplits ? filterSplits.match(regex).filter(x => x !== null)[0] : null

  // GET <segs>
  const filterSegs = filter(e => e.includes("segs"), path)[0]
  const segs = filterSegs ? filterSegs.match(regex).filter(x => x !== null)[0] : null

  const result = {
    sourceId: sourceId ? sourceId : null,
    primarySrc,
    splits: splits && parseString(splits),
    segs: segs && parseString(segs),
    fields: fields && parseString(fields),
    geos: null,
    currencies: null,
  }
  return result
}
