// @ts-nocheck
import React, { memo, useCallback } from "react"
import { useDispatch } from "react-redux"
import { useDrop } from "react-dnd"
import update from "immutability-helper"

import desksDuck from "../../../../state/modules/desks"
import VisibleDeskItem from "./VisibleDeskItem"
import itemTypes from "./itemTypes"

const VisibleDesksContainer = ({ activeDeskId, userDesks }) => {
  const dispatch = useDispatch()

  // logic for drag&drop
  const findCard = useCallback(
    deskId => {
      const card = userDesks.filter(d => d.desk === deskId)[0]
      return {
        card,
        index: userDesks.indexOf(card),
      }
    },
    [userDesks],
  )

  const moveCard = useCallback(
    (deskId, atIndex) => {
      const { card, index } = findCard(deskId)
      const newCards = update(userDesks, {
        $splice: [
          [index, 1],
          [atIndex, 0, card], // move card from 1 to 0
        ],
      })
      return dispatch(desksDuck.actions.setUserDesks({ userDesks: newCards }))
    },
    [userDesks, findCard, dispatch],
  )
  const [, drop] = useDrop({ accept: itemTypes.CARD })

  return (
    <div
      ref={drop}
      style={{
        padding: "8px",
        margin: "8px 0",
        overflowY: "auto",
        maxHeight: "450px",
      }}
    >
      {userDesks.map(userDesk => (
        <VisibleDeskItem
          key={userDesk.desk}
          deskId={userDesk.desk}
          findCard={findCard}
          moveCard={moveCard}
          userDesks={userDesks}
          activeDeskId={activeDeskId}
        />
      ))}
    </div>
  )
}

export default memo(VisibleDesksContainer)
