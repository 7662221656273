/**
 * Function to tranform number into a string with suffix 'K' or 'M'.
 * For exemple, if number=100000, the function transform it to this string: "100K"
 */

export const transformNumberToStringWithSuffix = (number: number | null) => {
  if (number === null) {
    return "" // Return an empty string if number is null or undefined
  } else if (number >= 1000000) {
    // If number is in millions, with cleaned trailing zeros and add 'M' suffix
    return (number / 1000000).toFixed(2).replace(/\.?0+$/, "") + "M"
  } else {
    // Otherwise, return number in thousands, with cleaned trailing zeros and add 'K' suffix
    return (number / 1000).toFixed(2).replace(/\.?0+$/, "") + "K"
  }
}

/**
 * Function to tranform string number with suffix 'K' or 'M' into a numeric value.
 * For exemple, if string=100K, the function transform it to this number: 100000
 */

export const transformStringWithSuffixToNumber = (value: string): number | null => {
  // Trim the value to remove whitespace from both ends
  const trimedValue = value.trim()
  if (trimedValue === "") {
    return null
  } else if (trimedValue.endsWith("M")) {
    // If the last character is M, we want millions
    return parseFloat(trimedValue) * 1000000
  } else {
    // else we want thousands
    return parseFloat(trimedValue) * 1000
  }
}

/**
 * Function to append a given suffix automatically to a string number.
 * If value ends already by a suffix (in this case user has added the suffix), just return the trimedValue.
 */

export const appendSuffixToStringNumber = (value: string, suffix: string): string => {
  // Trim the value to remove whitespace from both ends
  const trimedValue = value.trim()
  const lastCharacter = trimedValue.slice(-1)
  if (!isNaN(parseInt(lastCharacter))) {
    // If the last character is a digit, append suffix to the input value
    return trimedValue + suffix
  } else {
    // Treat case where user adds suffix ('M' for exemple) by himself and case where value is an empty string
    return trimedValue
  }
}
