import { Icon } from "@blueprintjs/core"
import { format, parseISO } from "date-fns"

import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { capitalizeFirstLetter } from "../../../../../utils"
import { Idea, IdeaRevision, IdeaType } from "../../types/business"
import { SOURCE_TYPE_DISPLAY } from "../../utils/sourceTypeDisplay"
import {
  IdeaHeaderContainer,
  IdeaInfos,
  InfoContainer,
  ItemContainer,
  StarsContainer,
} from "./IdeaHeader.styled"
import ParticipantsPopoverList from "./ParticipantsPopoverList"

interface IIdeaHeader {
  idea: Idea
  selectedRevision?: IdeaRevision
}

const IdeaHeader = ({ idea, selectedRevision }: IIdeaHeader) => {
  const ideaOwnerName = idea && capitalizeFirstLetter(idea.user.username)
  const sourceTypeName = SOURCE_TYPE_DISPLAY[idea.source_type]
  const sourceName = idea && capitalizeFirstLetter(idea.source)
  const isDraft = idea.draft

  /**
   * For draft idea, display idea creation date,
   * and for no draft, display idea publictaion date
   */
  const creationDate = idea?.created_at
  const publishedDate = idea?.published_at
  const formatedCreationDate = creationDate && format(parseISO(creationDate), "dd-MM-yyyy")
  const formatedPublishedDate = publishedDate && format(parseISO(publishedDate), "dd-MM-yyyy")

  return (
    <IdeaHeaderContainer justifyContent="space-between" flexDirection="row-reverse">
      <ItemContainer alignItems="center">
        <Icon icon="calendar" />
        {isDraft ? (
          <IdeaInfos endLine>{`Creation date: ${formatedCreationDate}`}</IdeaInfos>
        ) : (
          <IdeaInfos endLine>{`Publication date: ${formatedPublishedDate}`}</IdeaInfos>
        )}
      </ItemContainer>

      {idea.idea_type !== IdeaType.STRUCTURAL && (
        <ItemContainer alignItems="center">
          <Icon icon="clipboard" />
          <IdeaInfos>
            {sourceTypeName}
            {sourceName && ":"} {sourceName}
          </IdeaInfos>
        </ItemContainer>
      )}

      {!!selectedRevision?.conviction && (
        <StarsContainer alignItems="center">
          {Array(selectedRevision.conviction) // Create an array that can hold the conviction score.
            .fill(0) // Fill it with some value. Here 0 is chosen arbitrary, because it do not matter the value.
            .map(
              (_, i) =>
                !!selectedRevision.conviction && (
                  <Icon
                    size={20}
                    icon="star"
                    key={i}
                    color={
                      selectedRevision.conviction > 4
                        ? SCIENT_COLORS.forest3
                        : selectedRevision.conviction > 2
                          ? SCIENT_COLORS.orange4
                          : SCIENT_COLORS.vermilion4
                    }
                  />
                ),
            )}
          {Array(5 - selectedRevision.conviction) // Conviction is rated on a scale of 0 to 5. So empty start number = 5 - conviction
            .fill(0)
            .map((_, i) => (
              <Icon icon="star-empty" key={i} size={20} />
            ))}
        </StarsContainer>
      )}

      <InfoContainer flexGrow={1} gap="20px">
        <ItemContainer alignItems="center">
          {idea.idea_type === IdeaType.NOTE && idea.participants?.length ? (
            <ParticipantsPopoverList participants={idea.participants} user={idea.user} />
          ) : (
            <>
              <Icon icon="user" />
              <IdeaInfos bold>{ideaOwnerName}</IdeaInfos>
            </>
          )}
        </ItemContainer>
        {idea.title && (
          <ItemContainer alignItems="center">
            <IdeaInfos fontSize="18px">{idea.title}</IdeaInfos>
          </ItemContainer>
        )}
      </InfoContainer>
    </IdeaHeaderContainer>
  )
}

export default IdeaHeader
