// @ts-nocheck
import * as R from "ramda"
import { averageForecast } from "./averageForecast"
import { diffForecast } from "./diffForecast"
import { diffPctForecast } from "./diffPctForecast"

export const addComputedForecasts = ({ forecasts, diffMethod, diffPair, noEntry, stock }) => {
  const average = averageForecast({ sortedForecasts: forecasts, noEntry, stock })
  const forecastsWithAverage = [average, ...forecasts]

  const defender = {
    defender: true,
    ...R.find(R.propEq("slug", diffPair[0]))(forecastsWithAverage),
  }

  const challenger = {
    challenger: true,
    ...R.find(R.propEq("slug", diffPair[1]))(forecastsWithAverage),
  }

  const diff = diffForecast({ forecasts: forecastsWithAverage, diffPair, noEntry, stock })
  const diffPct = diffPctForecast({ forecasts: forecastsWithAverage, diffPair, noEntry, stock })
  if (diffMethod === "pct") {
    return [challenger, diffPct, defender, average, ...forecasts]
  }
  return [challenger, diff, defender, average, ...forecasts]
}
