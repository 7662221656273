import { Editor } from "@tinymce/tinymce-react"
import { useField, useFormikContext } from "formik"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef } from "react"
import { Editor as TinyMCEEditor } from "tinymce"
import { useApi } from "../../../hooks/useApi"
import { IdeaCreateTextAreaContainer } from "./IdeaCreateTextArea.styled"

interface IIdeaCreateTextArea {
  setUploadingImage: React.Dispatch<React.SetStateAction<boolean>>
  fieldName: string
  hidden: boolean
  setContentFieldName: Dispatch<SetStateAction<string>>
  disabled?: boolean
}

const IdeaCreateTextArea = ({
  setUploadingImage,
  fieldName,
  hidden,
  setContentFieldName,
  disabled,
}: IIdeaCreateTextArea) => {
  const { scientApi } = useApi()
  const { setFieldValue } = useFormikContext<Record<string, string>>()
  const editorRef = useRef<TinyMCEEditor | null>(null)
  const [content] = useField(fieldName)

  /**
   * Sets form field value when editor change
   */
  const onChangeEditor = useCallback(
    (a: string, editor: TinyMCEEditor) => {
      setFieldValue(content.name, editor.getContent())
    },
    [setFieldValue, content.name],
  )

  /**
   * Plugins to use from TinyMCE
   */
  const plugins = useMemo(
    () => [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "insertdatetime",
      "table",
      "preview",
      "fullscreen",
    ],
    [],
  )

  /**
   * Toolbar configuration
   */
  const toolbar = useMemo(() => {
    return (
      "select | undo redo | " +
      "bold italic forecolor | alignleft aligncenter " +
      "alignright alignjustify | " +
      "bullist numlist outdent indent | image | " +
      "blocks fontfamily fontsize | " +
      "fullscreen"
    )
  }, [])

  /**
   * File picker callback
   * cc https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#file_picker_callback
   */
  const filePickerCallback = useCallback(
    (cb: (uri: string, options: { title: string }) => void) => {
      const input = document.createElement("input")
      input.setAttribute("type", "file")
      input.setAttribute("accept", "image/*")

      input.addEventListener("change", e => {
        if (e.target) {
          const input = e.target as HTMLInputElement
          if (input.files) {
            const file = input.files[0]
            const reader = new FileReader()
            reader.addEventListener("load", () => {
              const id = "blobid" + new Date().getTime()
              const blobCache = editorRef.current?.editorUpload.blobCache
              const base64 = (reader.result as string).split(",")[1]
              const blobInfo = blobCache?.create(id, file, base64)

              if (blobInfo) {
                blobCache?.add(blobInfo)
                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name })
              }
            })
            reader.readAsDataURL(file)
          }
        }
      })

      input.click()
    },
    [],
  )

  /**
   * Handler called when an image is uploaded
   */
  const imageUploadHandler = useCallback(
    (blobInfo: {
      id: () => string
      name: () => string
      filename: () => string
      blob: () => Blob
      base64: () => string
      blobUri: () => string
      uri: () => string | undefined
    }) =>
      new Promise<string>((resolve, reject) => {
        setUploadingImage(true)
        const formData = new FormData()
        formData.append("image", blobInfo.blob(), blobInfo.filename())
        formData.append("title", blobInfo.filename())

        scientApi
          .uploadImage(formData)
          .then(data => {
            resolve(data.location)
          })
          .catch(error => reject(error))
          .finally(() => {
            setUploadingImage(false)
          })
      }),
    [scientApi, setUploadingImage],
  )

  useEffect(() => {
    if (!hidden) {
      setContentFieldName(content.name)
    }
  }, [content.name, hidden, setContentFieldName])

  return (
    <IdeaCreateTextAreaContainer hidden={hidden}>
      <Editor
        id={content.name}
        disabled={!!disabled}
        value={content.value}
        onEditorChange={onChangeEditor}
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          statusbar: false,
          branding: false,
          promotion: false,
          automatic_uploads: true,
          file_picker_types: "image",
          file_picker_callback: filePickerCallback,
          images_upload_handler: imageUploadHandler,
          font_size_formats: "8px 10px 12px 14px 18px 24px 36px",
          height: "100%",
          width: "100%",
          resize: false,
          menubar: false,
          plugins,
          toolbar,
          content_style:
            "body { font-family:sans-serif; font-size:18px; background-color:#ffffff }",
        }}
      />
    </IdeaCreateTextAreaContainer>
  )
}

export default IdeaCreateTextArea
