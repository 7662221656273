// @ts-nocheck
import React from "react"
import { Button, Card, Collapse } from "@blueprintjs/core"
import { Field } from "formik"

import { FormikTextInput } from "../FormikFields"
import FormikStyling from "../FormikStyling"

export const Row = ({ showOptions, toogleShowOptions, index }) => {
  return (
    <Card>
      <Field
        name={`rowDefs.rows[${index}].frontOpts.title`}
        component={FormikTextInput}
        labelProps={{ label: "Title" }}
        inputProps={{ placeholder: "Title..." }}
        helperText="Enter title."
      />
      <Button
        fill
        alignText="left"
        minimal
        icon={showOptions ? "caret-down" : "caret-right"}
        onClick={toogleShowOptions}
      >
        {showOptions ? "Hide" : ""} Options
      </Button>
      <Collapse isOpen={showOptions}>
        <Card>
          <FormikStyling index={index} />
        </Card>
      </Collapse>
    </Card>
  )
}
