// @ts-nocheck
import { map, switchMap } from "rxjs/operators"
import { fetchTFRowDefs, fetchTFRowDefsOk } from "../index"

import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import { ofType } from "redux-observable"

const fetchTFRowDefsEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchTFRowDefs.type),
    switchMap(() => {
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/desks/tfrowdefs/`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(map(({ response }) => fetchTFRowDefsOk({ results: response.results })))
    }),
  )

export default fetchTFRowDefsEpic
