// @ts-nocheck
import { ButtonGroup, Classes, H5, MenuItem, Popover, Tooltip } from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"

import { Button, Suggest } from "../../../../../components"
import { useGetNGTFGrid } from "../../../utils"

const TableHeader = ({ sheet, handleGoto, milestones, colType, toggleColType }) => {
  const { colTitles } = useGetNGTFGrid(sheet.id)
  const periods = R.map(R.prop("field"), colTitles)

  const [results, setResults] = useState(periods)

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item}
        text={item}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    )
  }

  const handleGotoSubmit = period => {
    handleGoto(period)
  }
  const handleQueryChange = query => {
    if (query === "") {
      setResults(periods)
    }
    const selection = periods.filter(period => period.includes(query))
    setResults(selection)
  }

  return (
    <ButtonGroup
      style={{
        marginLeft: "16px",
      }}
    >
      <Tooltip content="Column title type" placement="bottom" openOnTargetFocus={false} compact>
        <Button onClick={toggleColType} icon={colType === "name" ? "array-string" : "array-date"} />
      </Tooltip>

      <Popover
        content={
          <div style={{ width: "300px" }}>
            <H5>Jump to period</H5>
            <br />
            <Suggest
              fill
              placeholder="Type a period..."
              resetOnClose={true}
              resetOnSelect={true}
              items={results}
              onItemSelect={handleGotoSubmit}
              onQueryChange={handleQueryChange}
              itemRenderer={itemRenderer}
            />
            <br />
            <ButtonGroup fill>
              <Button icon="fast-backward" onClick={() => handleGoto(milestones.firstCol)} />
              <Button
                icon="step-forward"
                text="A"
                onClick={() => handleGoto(milestones.lastACol)}
              />
              <Button
                icon="step-backward"
                text="E"
                onClick={() => handleGoto(milestones.firstECol)}
              />
              <Button icon="fast-forward" onClick={() => handleGoto(milestones.lastCol)} />
            </ButtonGroup>
          </div>
        }
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        placement="bottom-end"
      >
        <Tooltip content="Jump to period" placement="bottom" openOnTargetFocus={false} compact>
          <Button icon="time" />
        </Tooltip>
      </Popover>
    </ButtonGroup>
  )
}
export default TableHeader
