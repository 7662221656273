import { AxiosInstance } from "axios"
import { IOTP } from "."

export interface IOTPApi {
  generate: () => Promise<IOTP>
  validate: (token: string) => Promise<IOTP>
  verify: (token: string) => Promise<IOTP>
}

const otp = (axiosClient: AxiosInstance): IOTPApi => ({
  generate: async () => {
    const response = await axiosClient.post("/otp/generate/")
    return response.data
  },
  validate: async (token: string) => {
    const response = await axiosClient.post("/otp/validate/", { token })
    return response.data
  },
  verify: async (token: string) => {
    const response = await axiosClient.post("/otp/verify/", { token })
    return response.data
  },
})

export default otp
