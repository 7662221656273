// @ts-nocheck
import autodux from "autodux"

const sEstimatesDuck = autodux({
  slice: "sEstimates",
  initial: {
    fetching: false,
    error: false,
    sEstimates: [],
  },
  actions: {
    fetchSEstimates: state => ({
      ...state,
      fetching: true,
    }),
    fetchSEstimatesOk: (state, sEstimates) => ({
      ...state,
      fetching: false,
      sEstimates,
    }),
    fetchSEstimatesError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),
  },
})

export default sEstimatesDuck
