// @ts-nocheck
import { Spinner } from "@blueprintjs/core"
import { compose, isEmpty } from "ramda"
import { connect } from "react-redux"
import { lifecycle } from "recompose"

import dataSetEventsDuck from "../../../state/modules/dataSetEvents"
import { NoResponseContainer } from "./Events.styled"
import Main from "./Main"
import Side from "./Side"

const List = ({ response, sidebar }) => {
  return (
    <>
      <div className="content">
        {isEmpty(response) ? (
          <NoResponseContainer
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="12px"
          >
            <Spinner intent="success" />
            <p>Loading dataset events…</p>
          </NoResponseContainer>
        ) : (
          <Main response={response} />
        )}
      </div>
      <div className={sidebar ? "side side-300" : "hide"}>
        <Side />
      </div>
    </>
  )
}

const mapStateToProps = ({ dataSetEvents: { response }, ui: { sidebar } }) => ({
  response,
  sidebar,
})

export default compose(
  connect(mapStateToProps, dataSetEventsDuck.actions),
  lifecycle({
    componentDidMount() {
      this.props.searchDSEvents({ completed: "0" })
    },
  }),
)(List)
