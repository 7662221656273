import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useApi } from "../../../hooks/useApi"
import { ConversationMessage, Idea, IdeaRevision, IdeaTrade } from "../types/business"
import useInfiniteIdeas from "./useInfiniteIdeas"
import useInfiniteMessages from "./useInfiniteMessages"
import useMessageListMutation from "./useMessageListMutation"
import useSectors from "./useSectors"

const useConvSetAsReadMutation = ({
  onError,
  onSuccess,
}: {
  onError: (error: AxiosError) => void
  onSuccess: () => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.conversations.setAsRead, { onError, onSuccess })
}

const useIdeaSetAsSeenMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.setIdeaAsSeen, { onSuccess, onError })
}

const useCreateIdeaMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (idea: Idea) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.create, { onSuccess, onError })
}

const useUpdateIdeaMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (idea: Idea) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.update, { onSuccess, onError })
}

const useNewRevisionMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (revision: IdeaRevision) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.newRevision, { onSuccess, onError })
}

const usePublishDraftMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (idea: Idea) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.publishDraft, { onSuccess, onError })
}

const useUpdateMessageMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (message: ConversationMessage) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.messages.update, { onSuccess, onError })
}

const useOpenTradeMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (trade: IdeaTrade) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.openTrade, { onSuccess, onError })
}

const useCloseTradeMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (trade: IdeaTrade) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.ideas.closeTrade, { onSuccess, onError })
}

export {
  useCloseTradeMutation,
  useConvSetAsReadMutation,
  useCreateIdeaMutation,
  useIdeaSetAsSeenMutation,
  useInfiniteIdeas,
  useInfiniteMessages,
  useMessageListMutation,
  useNewRevisionMutation,
  useOpenTradeMutation,
  usePublishDraftMutation,
  useSectors,
  useUpdateIdeaMutation,
  useUpdateMessageMutation,
}
