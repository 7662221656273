// @ts-nocheck
import * as R from "ramda"

import { getNgTfSources } from "."

export const getSourceIds = (rowTree, sheet) => {
  const abosolutSourceIds = getNgTfSources(rowTree)
  const primarySourceId = R.compose(
    obj => `${obj.kind}${obj.id}`,
    R.propOr({ kind: "", id: "" }, 0),
  )(!R.isEmpty(sheet.rf_sources) ? sheet.rf_sources : sheet.st_sources)

  const sourceIds = R.compose(
    R.filter(item => item !== ""),
    R.uniq,
  )(primarySourceId ? R.append(primarySourceId, abosolutSourceIds) : abosolutSourceIds)

  return sourceIds
}
