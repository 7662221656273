// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import stockDetailDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchStock, fetchStockOk, fetchStockError, fetchReports },
} = stockDetailDuck

const fetchStockEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchStock.type),
    mergeMap(action => {
      const { slug } = action.payload
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/stocks/${slug}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(({ response }) => [
          fetchStockOk({ slug, stockData: response }),
          fetchReports({ slug }),
        ]),
        catchError(error => of(fetchStockError({ slug, stockError: error.xhr.response }))),
      )
    }),
  )

export default fetchStockEpic
