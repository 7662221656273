import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { RiskType } from "../types"
import { SectorButton } from "./SectorButtons.styled"

/**
 * Scient app uses Blueprint Dark mode, which therefore applies to Button.
 * There's no equivalent of className="bp5-dark" for light mode.
 * So we have to customise Button with our own css.
 */
export const SectorButtons = () => {
  return (
    <FlexContainer>
      <SectorButton href={`#${RiskType.SECTOR_LEVEL_1}`} withLeftBorderRadius>
        L1
      </SectorButton>
      <SectorButton href={`#${RiskType.SECTOR_LEVEL_2}`} withBorders>
        L2
      </SectorButton>
      <SectorButton href={`#${RiskType.SECTOR_LEVEL_3}`} withRightBorderRadius>
        L3
      </SectorButton>
    </FlexContainer>
  )
}
