// @ts-nocheck
import React from "react"
import logo from "./scient-logo.svg"
import logoBlack from "./scient-logo-black.svg"
import logoWhite from "./scient-logo-white.svg"

export const ScientLogo = ({ color, ...props }) => {
  const switchlogo = color => {
    switch (color) {
      case "white":
        return logoWhite
      case "black":
        return logoBlack
      default:
        return logo
    }
  }
  return <img src={switchlogo(color)} alt="scient logo" {...props} />
}
