import { Button, Icon } from "@blueprintjs/core"
import { isEmpty } from "lodash"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { OmniSuggestLight } from "../../../../components/OmniSuggestLight"
import SectorSuggest from "../../../../components/SectorSuggest"
import UserSuggest from "../../../../components/UserSuggest"
import { useIdeasCache } from "../../../../hooks"
import {
  AbsoluteFilterIconContainer,
  FilterSearchInputContainer,
  IdeasFiltersContainer,
  RelativeFilterIconContainer,
} from "./InboxFilters.styled"

const InboxFilters = () => {
  const {
    draftFilter,
    stockFilter,
    sectorFilter,
    userFilter,
    setStockFilter,
    setSectorFilter,
    setUserFilter,
  } = useIdeasCache()

  return (
    <IdeasFiltersContainer flexDirection="column" gap="10px">
      <FlexContainer gap="15px" alignItems="center">
        <RelativeFilterIconContainer>
          <Icon icon="filter" />
          <AbsoluteFilterIconContainer>
            <Icon icon="dollar" size={12} />
          </AbsoluteFilterIconContainer>
        </RelativeFilterIconContainer>
        <FilterSearchInputContainer flexGrow={1}>
          <OmniSuggestLight
            exclude={{ ds: true, dsf: true, rf: true }}
            inputValueRenderer={item => item.bloomberg_code}
            // @ts-ignore
            onItemSelect={item => setStockFilter(item)}
            placeholder="Filter by Stock"
            selectedItem={stockFilter}
          />
        </FilterSearchInputContainer>
        {!isEmpty(stockFilter) && (
          <Button
            icon="cross"
            onClick={() => setStockFilter && setStockFilter(null)}
            fill={false}
          />
        )}
      </FlexContainer>
      <FlexContainer gap="15px" alignItems="center">
        <RelativeFilterIconContainer>
          <Icon icon="filter" />
          <AbsoluteFilterIconContainer>
            <Icon icon="briefcase" size={12} />
          </AbsoluteFilterIconContainer>
        </RelativeFilterIconContainer>
        <FilterSearchInputContainer flexGrow={1} flexDirection="row-reverse">
          <SectorSuggest
            selectedSector={sectorFilter}
            // @ts-ignore
            onSectorSelect={sector => setSectorFilter(sector)}
            placeholder="Filter by Sector"
          />
        </FilterSearchInputContainer>
        {!isEmpty(sectorFilter) && (
          <Button
            icon="cross"
            onClick={() => setSectorFilter && setSectorFilter(null)}
            fill={false}
          />
        )}
      </FlexContainer>
      {!draftFilter && (
        <FlexContainer gap="15px" alignItems="center">
          <RelativeFilterIconContainer>
            <Icon icon="filter" />
            <AbsoluteFilterIconContainer>
              <Icon icon="person" size={12} />
            </AbsoluteFilterIconContainer>
          </RelativeFilterIconContainer>
          <FilterSearchInputContainer flexGrow={1} flexDirection="row-reverse">
            <UserSuggest
              selectedUser={userFilter}
              // @ts-ignore
              onUserSelect={user => setUserFilter(user)}
              placeholder="Filter by User"
            />
          </FilterSearchInputContainer>
          {!isEmpty(userFilter) && (
            <Button
              icon="cross"
              onClick={() => setUserFilter && setUserFilter(null)}
              fill={false}
            />
          )}
        </FlexContainer>
      )}
    </IdeasFiltersContainer>
  )
}

export default InboxFilters
