// @ts-nocheck
import React from "react"

const NodeInfos = ({ data }) => (
  <div>
    <h2>{data.title}</h2>
    <h3>{data.subtitle}</h3>
    <p>Description: {data.description}</p>
    {data.params && (
      <>
        <p style={{ marginBottom: 0 }}>Param{data.params.length > 1 && "s"}: </p>
        <p style={{ fontStyle: "italic", fontSize: "0.85em", margin: 0 }}>(from top to bottom)</p>
        <ul style={{ marginTop: "4px" }}>
          {data.params.map(param => (
            <li key={param.id}>
              {param.title}: {param.value} <br />
              <em>{param.description}</em>
            </li>
          ))}
        </ul>
      </>
    )}
    {data.inputs && (
      <>
        <p style={{ marginBottom: 0 }}>Input{data.inputs.length > 1 && "s"}: </p>
        <p style={{ fontStyle: "italic", fontSize: "0.85em", margin: 0 }}>(from top to bottom)</p>
        <ol style={{ marginTop: "4px" }}>
          {data.inputs.map(input => (
            <li key={input.id}>
              {input.id} <br />
              <em>{input.description}</em>
            </li>
          ))}
        </ol>
      </>
    )}

    {data.outputs && (
      <>
        <p style={{ marginBottom: 0 }}>Output{data.outputs.length > 1 && "s"}: </p>
        <p style={{ fontStyle: "italic", fontSize: "0.85em", margin: 0 }}>(from top to bottom)</p>
        <ol style={{ marginTop: "4px" }}>
          {data.outputs.map(output => (
            <li key={output.id}>
              {output.id} <br />
              <em>{output.description}</em>
            </li>
          ))}
        </ol>
      </>
    )}
  </div>
)

export default NodeInfos
