// @ts-nocheck
import { MenuItem } from "@blueprintjs/core"
import { Suggest } from "@blueprintjs/select"
import { capitalizeFirstLetter } from "../../utils"
import { useGlobalState } from "../hooks/useGlobalState"

/**
 * Reusable Suggest component to select a user from
 * the users list.
 */
const UserSuggest = ({ selectedUser, onUserSelect, placeholder }) => {
  const { users } = useGlobalState()

  const renderUser = (user, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={user.id}
        onClick={handleClick}
        text={capitalizeFirstLetter(user.username)}
      />
    )
  }

  const searchUser = (query, users) => {
    return users.filter(user => user.username.includes(query.toLowerCase()))
  }

  const noResults = (
    <MenuItem active={false} disabled={true} icon="zoom-out" text="No Users found" />
  )

  return (
    <>
      {users && (
        <Suggest
          items={users.filter(user => !user.is_superuser)}
          inputValueRenderer={user => capitalizeFirstLetter(user.username)}
          itemRenderer={renderUser}
          itemListPredicate={searchUser}
          onItemSelect={onUserSelect}
          noResults={noResults}
          fill={true}
          inputProps={{ placeholder }}
          selectedItem={selectedUser}
          resetOnSelect={true}
        />
      )}
    </>
  )
}

export default UserSuggest
