// @ts-nocheck
import React from "react"
import { H4 } from "@blueprintjs/core"

import { Single } from "./Single"
import { Group } from "./Group"

const RowNodeDetail = ({
  activeItem,
  setActiveItem,
  setOpenFormulaBuilder,
  st_sources,
  rf_sources,
  rowTree,
  setRowTree,
  selectNode,
  setGroupNotComplete,
}) => {
  return (
    <>
      {activeItem.kind === "single" ? (
        <Single
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          setOpenFormulaBuilder={setOpenFormulaBuilder}
          st_sources={st_sources}
          rf_sources={rf_sources}
          rowTree={rowTree}
          setRowTree={setRowTree}
        />
      ) : activeItem.kind === "group" ? (
        <Group
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          st_sources={st_sources}
          rf_sources={rf_sources}
          rowTree={rowTree}
          setRowTree={setRowTree}
          selectNode={selectNode}
          setGroupNotComplete={setGroupNotComplete}
        />
      ) : (
        <H4 style={{ margin: "auto" }}>Select a node on the left tree</H4>
      )}
    </>
  )
}
export default RowNodeDetail
