// @ts-nocheck
import * as R from "ramda"

export const search = R.assoc("searching", true)
export const searchOk = (state, data) =>
  R.compose(R.assoc("searching", false), R.assoc("results", data))(state)
export const searchError = (state, error) =>
  R.compose(R.assoc("searching", false), R.assoc("results", []), R.assoc("error", error))(state)

export const clear = R.compose(R.assoc("searching", false), R.assoc("results", []))

export const fetchDataframeList = (state, { sheetId }) => {
  const object = R.objOf(sheetId, true)
  const fetchingDfForSheets = { ...state.fetchingDfForSheets, ...object }
  return R.compose(R.assoc("fetchingDfForSheets", fetchingDfForSheets), R.dissoc("error"))(state)
}
export const fetchDataframeListOk = (state, { sheetId, byDfId }) =>
  R.compose(
    R.set(R.lensPath(["fetchingDfForSheets", sheetId]), false),
    R.over(R.lensPath(["byDfId"]), R.mergeLeft((["byDfId"], byDfId))),
  )(state)
export const fetchDataframeListError = (state, { error }) => ({
  ...state,
  error,
  fetchingDfForSheets: {},
})

export const fetchAdditionalDataframe = (state, { sheetId }) =>
  R.compose(R.set(R.lensPath(["fetchingDfForSheets", sheetId]), true), R.dissoc("error"))(state)

export const fetchAdditionalDataframeOk = (state, { sheetId, newDf }) =>
  R.compose(
    R.set(R.lensPath(["fetchingDfForSheets", sheetId]), false),
    R.over(R.lensPath(["byDfId"]), R.mergeLeft((["byDfId"], newDf))),
  )(state)

export const fetchAdditionalDataframeError = (state, { sheetId, error }) =>
  R.compose(
    R.set(R.lensPath(["fetchingDfForSheets", sheetId]), false),
    R.assoc("error", error),
  )(state)
