// @ts-nocheck
import { either, over, insert, isEmpty, isNil, move, remove, set } from "ramda"
import { rowDefsLens, rowLens } from "./utils"

const isNilOrEmpty = either(isNil, isEmpty)

export const addLine = (state, { slug, tabId, index = -1, row }) => {
  if (isNilOrEmpty(row)) {
    return state
  }
  return over(rowDefsLens(slug, tabId), insert(index, row), state)
}
export const editLine = (state, { slug, tabId, index, row }) => {
  if (isNilOrEmpty(row)) {
    return state
  }
  return set(rowLens(slug, tabId, index), row, state)
}

export const deleteLine = (state, { slug, tabId, index }) =>
  over(rowDefsLens(slug, tabId), remove(index, 1), state)

export const moveLine = (state, { slug, tabId, sourceIndex, targetIndex }) => {
  return over(rowDefsLens(slug, tabId), move(sourceIndex, targetIndex))(state)
}
