/** @jsxImportSource @emotion/react */
import { Divider, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import { useState } from "react"

import { LargeText } from "../../../../styled/text.styled"
import { SpinnerContainer } from "../../Ideas/List/Inbox/Inbox.styled"
import { RiskType } from "../Risks/types"
import { IAccountBookPairs } from "../types"
import Category from "./Category"
import useRebalancingQuery from "./hooks/useRebalancingQuery"
import { CategoriesContainer, dividerCss, RebalancingContainer } from "./Rebalancing.styled"
import RebalancingHeader from "./RebalancingHeader"

interface IRebalancingDataProps {
  selectedFilters: IAccountBookPairs
}

const Rebalancing = ({ selectedFilters }: IRebalancingDataProps) => {
  const [selectedSector, setSelectedSector] = useState(RiskType.SECTOR_LEVEL_1)
  /**
   * Get rebalancing based on selectedFilters
   */
  const { rebalancing, isLoading } = useRebalancingQuery({
    accountBookPairs: selectedFilters,
    type: selectedSector,
  })

  return (
    <RebalancingContainer flexDirection="column">
      <RebalancingHeader
        keyMetrics={rebalancing?.key_metrics}
        selectedSector={selectedSector}
        setSelectedSector={setSelectedSector}
        isLoading={isLoading}
      />
      <Divider css={dividerCss} />
      {!isLoading ? (
        !rebalancing?.categories.length ? (
          <CategoriesContainer justifyContent="center">
            <LargeText>There are no positions for the selected book(s)</LargeText>
          </CategoriesContainer>
        ) : (
          <CategoriesContainer flexDirection="column" gap="35px">
            {rebalancing?.categories.map(category => (
              <Category key={category.name} category={category} isLoading={isLoading} />
            ))}
          </CategoriesContainer>
        )
      ) : (
        <SpinnerContainer justifyContent="center" alignItems="center" flexGrow={1}>
          <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
        </SpinnerContainer>
      )}
    </RebalancingContainer>
  )
}

export default Rebalancing
