import { Icon, Intent, Tag } from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"

import { format } from "date-fns"
import { IdeaOrdering } from "../../../../../api/ideas"
import { FlexContainer, FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { SmallText } from "../../../../../styled/text.styled"
import { capitalizeFirstLetter } from "../../../../../utils"
import { roundNumber } from "../../../../_shared"
import { useIdeasCache } from "../../../../hooks"
import { useGlobalState } from "../../../../hooks/useGlobalState"
import { createToaster } from "../../../../utils/createToaster"
import { useIdeaSetAsSeenMutation } from "../../hooks"
import { Idea, IdeaRevision, IdeaType, isStockPosition } from "../../types/business"
import { getDateDistance } from "../../utils"
import { SOURCE_TYPE_DISPLAY } from "../../utils/sourceTypeDisplay"
import { PositionInfos } from "../Content/IdeaPositions.styled"
import {
  BottomCardContainer,
  Conviction,
  DateDistance,
  IdeaCardContainer,
  InfoContainer,
  NotificationIconContainer,
  SourceInfo,
  TopCardContainer,
  TypeInfo,
  UserInfo,
} from "./IdeaCard.styled"

interface IIdeaCard {
  idea: Idea
  selected: boolean
  setSelectedIdeaId: Dispatch<SetStateAction<string | number | undefined>>
  setSelectedRevision: Dispatch<SetStateAction<IdeaRevision | undefined>>
}

const IdeaCard = ({ idea, selected, setSelectedIdeaId, setSelectedRevision }: IIdeaCard) => {
  const { orderBy } = useIdeasCache()

  const { mutate: setSeenMutate } = useIdeaSetAsSeenMutation({
    onSuccess: () => {
      updateUnseenIdeasCount && updateUnseenIdeasCount(-1)
      updateIdea &&
        updateIdea(idea.id, {
          seen: true,
        })
    },
    onError: error => {
      createToaster({ message: error.message, intent: Intent.WARNING })
    },
  })

  const { updateUnseenIdeasCount } = useGlobalState()

  const [dateDistance, setDateDistance] = useState<string | undefined>(undefined)
  /**
   * Depending on the oderBy option selected,
   * we change the attribute on which we compute the date distance.
   */
  useEffect(() => {
    if (
      orderBy === IdeaOrdering.CREATION_DATE ||
      (idea.draft && orderBy === IdeaOrdering.DEFAULT)
    ) {
      setDateDistance(getDateDistance(idea.created_at))
    } else if (!idea.draft && orderBy === IdeaOrdering.DEFAULT && idea.default_sorting_date) {
      setDateDistance(getDateDistance(idea.default_sorting_date))
    } else if (orderBy === IdeaOrdering.TRADE_OPEN_DATE && idea.trade?.opened_at) {
      setDateDistance(getDateDistance(idea.trade.opened_at))
    } else if (orderBy === IdeaOrdering.TRADE_CLOSE_DATE && idea.trade?.closed_at) {
      setDateDistance(getDateDistance(idea.trade.closed_at))
    }
  }, [
    idea.created_at,
    idea.default_sorting_date,
    idea.draft,
    idea.trade?.closed_at,
    idea.trade?.opened_at,
    orderBy,
  ])

  const { updateIdea } = useIdeasCache()

  /**
   * When IdeaCard is clicked, we set it selected
   * If it is not seen, we set is as seen
   */
  const onClickCard = useCallback(() => {
    setSelectedIdeaId(idea.id)
    setSelectedRevision(undefined)
    if (!idea.draft) {
      if (!idea.seen) {
        if (typeof idea.id === "number") {
          setSeenMutate(idea.id)
        }
      }
    }
  }, [idea.draft, idea.id, idea.seen, setSeenMutate, setSelectedIdeaId, setSelectedRevision])

  let shortName = undefined
  let longName = undefined
  if (idea.trade?.short.instance) {
    shortName = isStockPosition(idea.trade.short.instance)
      ? idea.trade.short.instance.bloomberg_code
      : idea.trade.short.instance.name
  }
  if (idea.trade?.long.instance) {
    longName = isStockPosition(idea.trade?.long.instance)
      ? idea.trade?.long.instance.bloomberg_code
      : idea.trade?.long.instance.name
  }
  const sourceTypeName = SOURCE_TYPE_DISPLAY[idea.source_type]

  const mainPerf = idea?.trade?.main_perf
  const tradeError = idea?.trade?.computation_error

  /**
   * We display the latest conviction on the card
   * meaning the conviction of the latest revision
   */
  const latestConviction = useMemo(() => {
    return idea.revisions.sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    )[idea.revisions.length - 1].conviction
  }, [idea.revisions])

  const latestRecommendation = useMemo(() => {
    return idea.revisions.sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    )[idea.revisions.length - 1].recommendation
  }, [idea.revisions])

  return (
    <IdeaCardContainer
      ideaType={idea.idea_type}
      flexDirection="column"
      selected={selected}
      failure={idea.status === "failure"}
      onClick={onClickCard}
      gap="10px"
    >
      <TopCardContainer flexDirection="row-reverse" justifyContent="space-between">
        <FlexContainer gap="15px" alignItems="center">
          <FlexContainer alignItems="center">
            {
              /**
               * If performance returns error, display a warning sign.
               * If mainPerf is null, meaning startPrice is not yet available, display a question mark (help icon).
               * If mainPerf available, display the percentage.
               */
              tradeError ? (
                <PositionInfos>
                  <Icon icon="warning-sign" />
                </PositionInfos>
              ) : (
                mainPerf !== undefined && (
                  <PositionInfos perf={mainPerf}>
                    {mainPerf || mainPerf === 0 ? (
                      `${mainPerf > 0 ? "+" : ""}${roundNumber(mainPerf)}%`
                    ) : (
                      <Icon icon="help" />
                    )}
                  </PositionInfos>
                )
              )
            }
          </FlexContainer>
          <FlexContainerAndItem flexGrow={1} flexDirection="row-reverse" alignItems="center">
            {latestConviction && (
              <FlexContainer alignItems="center" gap="5px">
                <Icon
                  icon="star"
                  color={
                    latestConviction > 4
                      ? SCIENT_COLORS.forest3
                      : latestConviction > 2
                        ? SCIENT_COLORS.orange4
                        : SCIENT_COLORS.vermilion4
                  }
                />
                <Conviction>{latestConviction}</Conviction>
              </FlexContainer>
            )}
          </FlexContainerAndItem>
          <DateDistance alignItems="center" justifyContent="center">
            {idea.meeting_date ? format(new Date(idea.meeting_date), "dd/MM/yy") : dateDistance}
          </DateDistance>
        </FlexContainer>
        <UserInfo>{capitalizeFirstLetter(idea.user.username)}</UserInfo>
      </TopCardContainer>

      <BottomCardContainer flexDirection="row-reverse" alignItems="center" gap="5px">
        <NotificationIconContainer justifyContent="flex-end">
          {!idea.draft && idea.seen && !idea.revisions_conversations_read && (
            <Icon icon={IconNames.COMMENT} size={12} intent="primary" />
          )}
          {!idea.draft && !idea.seen && <Icon icon="lightbulb" color={SCIENT_COLORS.orange4} />}
        </NotificationIconContainer>

        <InfoContainer flexGrow={1} flexDirection="row-reverse" justifyContent="space-between">
          {idea.idea_type === IdeaType.STRUCTURAL ? (
            <Tag round>
              <SmallText fontWeight="bold" color="#020202">
                {latestRecommendation}
              </SmallText>
            </Tag>
          ) : (
            <SourceInfo>{sourceTypeName}</SourceInfo>
          )}

          <FlexContainerAndItem alignItems="center" flexGrow={1} gap="5px">
            <TypeInfo>
              {idea.trade
                ? `${longName} - ${shortName}`
                : idea.idea_type === IdeaType.STRUCTURAL
                  ? idea.related_sector
                    ? idea.related_sector.name
                    : idea.related_stocks[0].bloomberg_code
                  : idea.title}
            </TypeInfo>
          </FlexContainerAndItem>
        </InfoContainer>
      </BottomCardContainer>
    </IdeaCardContainer>
  )
}

export default IdeaCard
