// @ts-nocheck
import React, { useCallback, useMemo } from "react"
import { connect } from "react-redux"
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import { Label, Checkbox } from "@blueprintjs/core"

import dsForecastsDuck from "../../../../../state/modules/dsForecasts"
import { Loader } from "../../../../components"
import options from "./options"
import Header from "../Header"

const DataSetGraph = ({ entries, dataSet, dsForecasts, clearEntries, fetchForecast }) => {
  const toggleCheckbox = useCallback(
    ({ index, forecast }) => {
      if (!forecast.dsfentries) {
        return fetchForecast({ dataset: dataSet.id, index, id: forecast.id })
      }
      return clearEntries({ index })
    },
    [clearEntries, dataSet.id, fetchForecast],
  )

  const opts = useMemo(
    () => options(entries, dataSet, dsForecasts),
    [entries, dataSet, dsForecasts],
  )

  if (!dsForecasts.results) {
    return <Loader title="Loading Forecasts" />
  }

  return (
    <>
      <Header dataSet={dataSet} />
      <HighchartsReact highcharts={Highcharts} constructorType={"stockChart"} options={opts} />
      <div>
        <Label>Forecasts</Label>
        {dsForecasts.results ? (
          dsForecasts.results.map((forecast, index) => {
            return (
              <Checkbox
                key={forecast.id}
                checked={forecast.dsfentries ? true : false}
                onChange={() => toggleCheckbox({ index, forecast })}
                label={forecast.name}
                inline
              />
            )
          })
        ) : (
          <p>No forecast</p>
        )}
      </div>
    </>
  )
}

export default connect(null, dsForecastsDuck.actions)(DataSetGraph)
