// @ts-nocheck
import { getDeepNestedValue } from "../../../../../../utils"
import { cfo, opfcf } from "./sideUpdates/cf"
import {
  grossProfit,
  ebitda,
  ebitReported,
  ebita,
  profitBeforeTax,
  netProfitFromContinuingOperations,
  netProfitForThePeriod,
} from "./sideUpdates/is"
import { evAdj } from "./sideUpdates/bs"

export const createISRows = ({
  stockData,
  values,
  prevYearReport,
  prevReport,
  handleChange,
  stockParams,
}) => {
  const {
    ppa_in_segments,
    exceptionals_in_segments,
    restructuring_in_segments,
    default_profit_metric,
  } = stockParams

  const reportedEbitSumList = () => [
    [getDeepNestedValue(["profit"], values)],
    [!ppa_in_segments && getDeepNestedValue(["is_ppa_amortization"], values)],
    [!restructuring_in_segments && getDeepNestedValue(["is_restructuring_costs"], values)],
    [getDeepNestedValue(["is_walkdown_customsegment_to_reported_ebit"], values)],
    [!exceptionals_in_segments && getDeepNestedValue(["is_exceptionals"], values)],
  ]

  return [
    {
      type: "title",
      props: {
        level: 1,
        title: "Income Statement",
      },
    },
    {
      type: "readonly",
      props: {
        title: "Sales",
        basePath: ["sales"],
        commentPath: ["sales_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Reported cogs",
        basePath: ["is_reported_cogs"],
        commentPath: ["is_reported_cogs_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          grossProfit,
          ebitda,
          ebita,
          ebitReported,
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "readonly",
      props: {
        title: "Gross Profit",
        basePath: ["is_gross_profit"],
        commentPath: ["is_gross_profit_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "S G and A",
        basePath: ["is_s_g_and_a"],
        commentPath: ["is_s_g_and_a_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          ebitda,
          ebita,
          ebitReported,
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Expensed R And D",
        basePath: ["is_expensed_r_and_d"],
        commentPath: ["is_expensed_r_and_d_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          ebitda,
          ebita,
          ebitReported,
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Other Income Expense",
        basePath: ["is_other_income_expense"],
        commentPath: ["is_other_income_expense_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          ebitda,
          ebita,
          ebitReported,
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Non Operating Income Above",
        basePath: ["is_non_operating_income_above"],
        commentPath: ["is_non_operating_income_above_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          ebitda,
          ebita,
          ebitReported,
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Share Of Profit From Associates Above",
        basePath: ["is_share_of_profit_from_associates_above"],
        commentPath: ["is_share_of_profit_from_associates_above_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          ebitda,
          ebita,
          ebitReported,
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "D A Reported In Is",
        basePath: ["is_d_a_reported_in_is"],
        commentPath: ["is_d_a_reported_in_is_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          ebitReported,
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Operating Lease Depreciation",
        basePath: ["is_operating_lease_depreciation"],
        commentPath: ["is_operating_lease_depreciation_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Operating Lease Interest",
        basePath: ["is_lease_interest"],
        commentPath: ["is_lease_interest_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Ebitda",
        basePath: ["is_ebitda"],
        commentPath: ["is_ebitda_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        // placeholder: getDeepNestedValue(["is_ebitda_readonly"], values),
      },
    },
    {
      type: "input",
      props: {
        title: "Depreciation",
        basePath: ["is_depreciation"],
        commentPath: ["is_depreciation_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [ebitda, ebita, cfo, opfcf],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Ebita",
        basePath: ["is_ebita"],
        commentPath: ["is_ebita_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        // placeholder: getDeepNestedValue(["is_ebita_readonly"], values),
      },
    },
    {
      type: "input",
      props: {
        title: "Amortization",
        basePath: ["is_amortization"],
        commentPath: ["is_amortization_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [ebitda, ebita, cfo, opfcf],
        handleChange,
      },
    },
    {
      type: "control",
      props: {
        title: "Amortization check",
        expected: getDeepNestedValue(["bs_amortization_expense_other_intangibles"], values),
        sumList: [
          getDeepNestedValue(["is_amortization"], values),
          getDeepNestedValue(["is_ppa_amortization"], values),
        ],
      },
    },
    {
      type: "readonly",
      props: {
        title: "Ebit reported Compute",
        basePath: ["is_ebit_reported_readonly"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Ebit reported",
        basePath: ["is_ebit_reported_override"],
        commentPath: ["is_ebit_reported_override_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        placeholder: getDeepNestedValue(["is_ebit_reported_readonly"], values),
        sideUpdates: [ebitReported],
      },
    },
    {
      type: "control",
      props: {
        title: "Check Ebit Reported vs Profit section - group profit + Ebit bridge total",
        expected: getDeepNestedValue(["is_ebit_reported_readonly"], values),
        sumList: reportedEbitSumList,
      },
    },
    {
      type: "input",
      props: {
        title: "Financial income",
        basePath: ["is_financial_income"],
        commentPath: ["is_financial_income_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Non operating income below",
        basePath: ["is_non_operating_income_below"],
        commentPath: ["is_non_operating_income_below_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Share of profit from associates below",
        basePath: ["is_share_of_profit_from_associates_below"],
        commentPath: ["is_share_of_profit_from_associates_below_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          profitBeforeTax,
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          cfo,
          opfcf,
        ],
        handleChange,
      },
    },
    {
      type: "readonly",
      props: {
        title: "Profit before tax",
        basePath: ["is_profit_before_tax_readonly"],
        commentPath: ["is_profit_before_tax_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Tax expense",
        basePath: ["is_tax_expense"],
        commentPath: ["is_tax_expense_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [netProfitFromContinuingOperations, netProfitForThePeriod],
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Minority interest",
        basePath: ["is_minority_interest"],
        commentPath: ["is_minority_interest_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [
          netProfitFromContinuingOperations,
          netProfitForThePeriod,
          evAdj(stockData, values),
        ],
        handleChange,
      },
    },
    {
      type: "readonly",
      props: {
        title: "Net profit from continuing operations",
        basePath: ["is_net_profit_from_continuing_operations"],
        commentPath: ["is_net_profit_from_continuing_operations_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Dividend",
        basePath: ["is_dividend"],
        commentPath: ["is_dividend_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Net profit from discontinued operations",
        basePath: ["is_net_profit_from_discontinued_operations"],
        commentPath: ["is_net_profit_from_discontinued_operations_comment"],
        values,
        prevReport,
        prevYearReport,
        sideUpdates: [netProfitForThePeriod],
        handleChange,
      },
    },
    {
      type: "readonly",
      props: {
        title: "Net profit for the period",
        basePath: ["is_net_profit_for_the_period"],
        commentPath: ["is_net_profit_for_the_period_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Diluted EPS reported",
        basePath: ["is_diluted_eps_reported"],
        commentPath: ["is_diluted_eps_reported_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Diluted EPS adjusted (co. reported)",
        basePath: ["is_diluted_eps_adjusted"],
        commentPath: ["is_diluted_eps_adjusted_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Average shares O/S(m)",
        basePath: ["is_average_shares_out"],
        commentPath: ["is_average_shares_out_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Average fully diluted O/S(m)",
        basePath: ["is_average_fully_diluted_out"],
        commentPath: ["is_average_fully_diluted_out_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "DPS",
        basePath: ["is_dps"],
        commentPath: ["is_dps_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "ROCE",
        basePath: ["is_roce"],
        commentPath: ["is_roce_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Share Buyback",
        basePath: ["is_share_buyback"],
        commentPath: ["is_share_buyback_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Share Issuance",
        basePath: ["is_share_issuance"],
        commentPath: ["is_share_issuance_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Buyback Price",
        basePath: ["is_buyback_price"],
        commentPath: ["is_buyback_price_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Issuance Price",
        basePath: ["is_issuance_price"],
        commentPath: ["is_issuance_price_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Payout Ratio",
        basePath: ["is_payout_ratio"],
        commentPath: ["is_payout_ratio_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Tax Rate",
        basePath: ["is_tax_rate"],
        commentPath: ["is_tax_rate_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Depreciation as ola n1",
        basePath: ["is_depreciation_as_ola_n_1"],
        commentPath: ["is_depreciation_as_ola_n_1_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "input",
      props: {
        title: "Lease Interest to Liabilities",
        basePath: ["is_lease_interest_to_liabilities"],
        commentPath: ["is_lease_interest_to_liabilities_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "title",
      props: {
        level: 1,
        title: "EBIT bridge",
      },
    },
    {
      type: "input",
      props: {
        title: ppa_in_segments ? "ow PPA amortization" : "PPA amortization",
        basePath: ["is_ppa_amortization"],
        commentPath: ["is_ppa_amortization_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        sideUpdates: [
          {
            target: ["is_ebit_from_customsegments_readonly"],
            add: [
              ["profit"],
              !ppa_in_segments && ["is_ppa_amortization"],
              !restructuring_in_segments && ["is_restructuring_costs"],
              ["is_walkdown_customsegment_to_reported_ebit"],
              !exceptionals_in_segments && ["is_exceptionals"],
            ],
            method: "sum",
          },
        ],
      },
    },
    {
      type: "input",
      props: {
        title: restructuring_in_segments ? "ow Restructuring costs" : "Restructuring costs",
        basePath: ["is_restructuring_costs"],
        commentPath: ["is_restructuring_costs_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        sideUpdates: [
          {
            target: ["is_ebit_from_customsegments_readonly"],
            add: [
              ["profit"],
              !ppa_in_segments && ["is_ppa_amortization"],
              !restructuring_in_segments && ["is_restructuring_costs"],
              ["is_walkdown_customsegment_to_reported_ebit"],
              !exceptionals_in_segments && ["is_exceptionals"],
            ],
            method: "sum",
          },
        ],
      },
    },
    {
      type: "input",
      props: {
        title: "Adjustment to calculate clean EBIT (before exceptionals)",
        basePath: ["is_walkdown_customsegment_to_reported_ebit"],
        commentPath: ["is_walkdown_customsegment_to_reported_ebit_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        sideUpdates: [
          {
            target: ["is_ebit_from_customsegments_readonly"],
            add: [
              ["profit"],
              !ppa_in_segments && ["is_ppa_amortization"],
              !restructuring_in_segments && ["is_restructuring_costs"],
              ["is_walkdown_customsegment_to_reported_ebit"],
              !exceptionals_in_segments && ["is_exceptionals"],
            ],
            method: "sum",
          },
        ],
      },
    },
    {
      type: "input",
      props: {
        title: exceptionals_in_segments ? "ow Exceptionals" : "Exceptionals",
        basePath: ["is_exceptionals"],
        commentPath: ["is_exceptionals_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
        sideUpdates: [
          {
            target: ["is_ebit_from_customsegments_readonly"],
            add: [
              ["profit"],
              !ppa_in_segments && ["is_ppa_amortization"],
              !restructuring_in_segments && ["is_restructuring_costs"],
              ["is_walkdown_customsegment_to_reported_ebit"],
              !exceptionals_in_segments && ["is_exceptionals"],
            ],
            method: "sum",
          },
        ],
      },
    },

    {
      type: "readonly",
      props: {
        title: default_profit_metric
          ? `${default_profit_metric} (Group Op Metric)`
          : "Group Op Metric",
        basePath: ["profit"],
        commentPath: ["profit_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },
    {
      type: "readonly",
      props: {
        title: "Ebit from custom segment",
        basePath: ["is_ebit_from_customsegments_readonly"],
        commentPath: ["is_ebit_from_customsegments_comment"],
        values,
        prevReport,
        prevYearReport,
        handleChange,
      },
    },

    // {
    //   type: "control",
    //   props: {
    //     title: "Reported EBIT (check: GroupOP_metric+(if prefix 'ow': EBITbridge)=ebit_reported)",
    //     expected: getDeepNestedValue(["is_ebit_reported"], values),
    //     sumList: reportedEbitSumList(),
    //   },
    // },
  ]
}

// Champs suivant n'apparaisse pas in excel's file
// "roce", +comment
// "ebit_reported_override",
// "share_buyback", +comment
// "share_issuance", +comment
// "roce", +comment
// "depreciation_as_ola_n_1"  +comment
// "lease_interest_to_liabilities" +comment
// "ebit_from_customsegments" +comment

// const incomestatementOrdered = [
// "reported_cogs",
// "gross_profit", //compute
// "s_g_and_a",
// "expensed_r_and_d",
// "other_income_expense",
// "non_operating_income_above",
// "share_of_profit_from_associates_above",
// "d_a_reported_in_is",
// "operating_lease_depreciation", // new item
// "operating_lease_interest", // new item
// "ebitda", //computeField
// "depreciation",
// "ebita", //computeField
// "amortization",
// "ebit_reported", //computeField
// "financial_income",
// "non_operating_income_below",
// "share_of_profit_from_associates_below",
// "profit_before_tax", //computeField
// "tax_expense",
// "minority_interest",
// "net_profit_from_continuing_operations", //computeField
// "dividend",
// "net_profit_from_discontinued_operations",
// "net_profit_for_the_period", //computeField
// "diluted_eps_reported",
// "diluted_eps_adjusted",
// "average_shares_out",
// "average_fully_diluted_out",

// EBIT bridge
// "ppa_amortization",
// "restructuring_costs",
// "walkdown_customsegment_to_reported_ebit",
// "exceptionals",
// "ebit_from_customsegments", // Compute ???  = "Reported EBIT" in excel's file

// "Group Op Metric" a voir si à ajouter  : comes from the Group Profit section

// Champs suivant n'apparaisse pas in excel's file
// "dps",
// "ebit_reported_override",
// "share_buyback",
// "share_issuance",
// "roce",
// ]
