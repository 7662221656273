// @ts-nocheck
import autodux from "autodux"

const scenariosDuck = autodux({
  slice: "scenarios",
  initial: {
    fetching: false,
    error: false,
    scenarios: [],
    scenario: "",
  },
  actions: {
    fetchScenarios: state => ({
      ...state,
      fetching: true,
    }),
    fetchScenariosOk: (state, scenarios) => ({
      ...state,
      fetching: false,
      scenarios,
    }),
    fetchScenariosError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),
    selectScenario: (state, scenario) => ({
      ...state,
      scenario,
    }),
  },
})

export default scenariosDuck
