import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_AGGRID_COLORS } from "../../../../styled/scientColors"
import { Button } from "@blueprintjs/core"

export const FormContainer = styled.form({
  width: "100%",
})

export const RationalAndSubmitContainer = styled(FlexContainer)({
  margin: "10px 80px 0 20px",
})

export const RationalHeader = styled(FlexContainer)({
  width: "100%",
  border: "solid 1px #505254",
  backgroundColor: SCIENT_AGGRID_COLORS.headerBackground,
})

export const AddButton = styled(Button)({
  margin: "2px 0 12px",
})

export const addRowButtonCss = css({
  margin: "2px 0px 12px",
})
