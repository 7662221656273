// @ts-nocheck
import React from "react"
import { Card, Label, H4 } from "@blueprintjs/core"
import { Field } from "formik"
import { Grid, Row } from "react-flexbox-grid"

import { FormikDecimalInput } from "./FormikFields"
import FormikHeatmap from "./FormikHeatmap"

export const CellsSettings = () => {
  return (
    <Card>
      <Grid fluid>
        <Row center="xs">
          <H4>Cell settings</H4>
        </Row>
        <br />
        <Row center="xs">
          <FormikHeatmap />
        </Row>
        <br />
        <Row center="xs">
          <Label>
            Min decimals
            <Field name={"frontOpts.decimals.min"} component={FormikDecimalInput} />
          </Label>
        </Row>
        <Row center="xs">
          <Label>
            Max decimals
            <Field name={"frontOpts.decimals.max"} component={FormikDecimalInput} />
          </Label>
        </Row>
      </Grid>
    </Card>
  )
}
