// @ts-nocheck
import * as R from "ramda"

const searchDataSetsReducers = {
  searchDataSets: R.assocPath(["dataSets", "searching"], true),
  searchDataSetsOk: (state, { payload: { results } }) =>
    R.over(
      R.lensProp("dataSets"),
      R.compose(R.assoc("searching", false), R.assoc("results", results)),
      state,
    ),
  searchDataSetsError: (state, { payload: { error } }) =>
    R.set(R.lensProp("dataSets"), { searching: false, error: error, results: [] }, state),
  clearDataSets: state => R.set(R.lensProp("dataSets"), { searching: false, results: [] }, state),
}

export default searchDataSetsReducers
