// @ts-nocheck
import React from "react"
import { Card, Icon, H5, Elevation } from "@blueprintjs/core"

import { colorLinkText } from "../../../../../../_shared/highcharts"
import { Row, Col } from "../../../../../../components"

const Legend = ({ analyse }) => {
  const { dependent, independents } = analyse

  return (
    <Row>
      <Col xs={12}>
        <Card key={4} elevation={Elevation.ONE}>
          <H5>
            <Icon icon="selection" /> Y = {dependent.name}
          </H5>
          {independents.map((independent, i) => (
            <H5 key={i}>
              <Icon icon="selection" style={colorLinkText(independent.color, i)} />{" "}
              {`${independent.name} (${independent.path})`}
            </H5>
          ))}
        </Card>
      </Col>
    </Row>
  )
}

export default Legend
