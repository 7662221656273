// @ts-nocheck
import * as R from "ramda"

export const updateParams = (initialParams, values) => {
  const keysToUpdate = Object.keys(values)
  const newParams = R.map(
    e =>
      R.includes(e.id, keysToUpdate)
        ? {
            ...e,

            value:
              e.type === "FLOAT" && e.many === true // if many, indiquate an array, so need to pass something like "[2,3,4]"
                ? `[${values[e.id]}]`
                : values[e.id],
          }
        : e,
    initialParams,
  )
  return newParams
}
