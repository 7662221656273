// @ts-nocheck
import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { Button, Classes, Dialog } from "@blueprintjs/core"
import { find, propEq } from "ramda"

import desksDuck from "../../../../state/modules/desks"
import { InputGroup } from "../../../components"
import { useGetDesk } from "../utils"
import { UserGroupsCheckbox } from "../components"
export const EditDesk = ({ id, userDesks, isUserDesk, isOpen, toggleDialog }) => {
  const desk = useGetDesk(id)

  const [name, setName] = useState(desk.name)
  const [groups, setGroups] = useState(desk.groups)

  const handleText = ({ target: { value } }) => setName(value)

  const userDesk = isUserDesk && find(propEq("desk", desk.id))(userDesks)
  const editUserDesk = isUserDesk && { ...userDesk, str_name: `${desk.owner}>${name}` }
  const newUserDesks =
    isUserDesk && userDesks.map(e => (e.id !== editUserDesk.id ? e : editUserDesk))

  const dispatch = useDispatch()

  const handleSubmit = () => {
    if (isUserDesk) {
      dispatch(
        desksDuck.actions.updateDesk({
          ...desk,
          name,
          groups,
        }),
      )
      setTimeout(() => {
        dispatch(desksDuck.actions.upsertUserDesks(newUserDesks))
      }, 1000)
      toggleDialog()
    } else
      dispatch(
        desksDuck.actions.updateDesk({
          ...desk,
          name,
          groups,
        }),
      )
    toggleDialog()
  }

  const handleCancel = () => {
    setName(desk.name)
    setGroups(desk.groups)
    toggleDialog()
  }

  return (
    <Dialog
      className="bp5-dark"
      title="Edit desk"
      icon="cog"
      isOpen={isOpen}
      onClose={handleCancel}
    >
      <div className={Classes.DIALOG_BODY}>
        <InputGroup
          style={{ maxWidth: "220px" }}
          inputProps={{
            value: name,
            onChange: handleText,
            onEnter: handleSubmit,
            onEsc: handleCancel,
          }}
        />

        <UserGroupsCheckbox groups={groups} setGroups={setGroups} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" text="Cancel" onClick={handleCancel} />
          <Button intent="primary" icon="floppy-disk" text="Edit Desk" onClick={handleSubmit} />
        </div>
      </div>
    </Dialog>
  )
}
