import { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { Idea, IdeaRevision, IdeaType } from "../../types/business"
import { RevisionButton, RevisionsListContainer } from "./RevisionsList.styled"
import { useConvSetAsReadMutation } from "../../hooks"
import { createToaster } from "../../../../utils/createToaster"
import { Icon, Intent } from "@blueprintjs/core"
import { useIdeasCache } from "../../../../hooks"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SmallText } from "../../../../../styled/text.styled"
import { format } from "date-fns"
import { IconNames } from "@blueprintjs/icons"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

interface IRevisionsListProps {
  setSelectedRevision: Dispatch<SetStateAction<IdeaRevision | undefined>>
  selectedRevision?: IdeaRevision
  idea: Idea
  /**
   * Wether the conversation should be set as read or not
   * when a revision is selected
   */
  setRead?: boolean

  /**
   * Wether the not read icon is displayed
   */
  displayConversationNotRead?: boolean
}

const RevisionsList = ({
  setSelectedRevision,
  selectedRevision,
  idea,
  setRead,
  displayConversationNotRead,
}: IRevisionsListProps) => {
  const { updateIdea } = useIdeasCache()
  const { mutate: setAsReadMutate } = useConvSetAsReadMutation({
    onSuccess: () => {
      updateIdea &&
        updateIdea(idea.id, {
          revisions: idea.revisions.map(revision => {
            if (revision.id === selectedRevision?.id) {
              return { ...revision, conversation: { ...revision.conversation, is_read: true } }
            }
            return revision
          }),
          revisions_conversations_read: idea.revisions.every(revision => {
            return revision.id === selectedRevision?.id || revision.conversation?.is_read
          }),
        })
    },
    onError: error => {
      /**
       * If there was an error setting as read from the server,
       * we set back the conversation to not read
       */
      createToaster({ message: error.message, intent: Intent.WARNING })
    },
  })

  /**
   * When a revision is selected, we set the conversation as read
   */
  useEffect(() => {
    if (!!setRead && selectedRevision && !selectedRevision.conversation.is_read) {
      setAsReadMutate(selectedRevision.conversation.id)
    }
  }, [selectedRevision, setAsReadMutate, setRead])

  const onClickButton = useCallback(
    (revision: IdeaRevision) => {
      setSelectedRevision(revision as IdeaRevision)
    },
    [setSelectedRevision],
  )

  /**
   * By default, we select the last revision
   */
  useEffect(() => {
    if (idea.revisions.length > 0 && !selectedRevision) {
      setSelectedRevision(
        idea.revisions.sort(
          (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        )[idea.revisions.length - 1] as IdeaRevision,
      )
    }
  }, [idea, selectedRevision, setSelectedRevision])

  /**
   * The Revision component is always mounted as it handles the revision selection
   * and the conversation read status
   * It is shown only for ideas that can have multiple revisions
   */
  return idea.idea_type === IdeaType.STRUCTURAL ? (
    <RevisionsListContainer gap="10px" alignItems="center" flexWrap="nowrap">
      {idea.revisions
        .sort(
          (a, b) =>
            (a.created_at ? new Date(a.created_at).getTime() : new Date().getTime()) -
            (b.created_at ? new Date(b.created_at).getTime() : new Date().getTime()),
        )
        .map((revision, index) => {
          return (
            <FlexContainer
              flexDirection="column"
              key={`revision-selection-${index}`}
              justifyContent="center"
              alignItems="center"
            >
              <FlexContainer justifyContent="center" alignItems="center" gap="2px">
                <RevisionButton
                  value={revision.id}
                  onClick={() => onClickButton(revision)}
                  selected={revision.id === selectedRevision?.id}
                  type="button"
                >
                  <FlexContainer alignItems="center" justifyContent="center">
                    <SmallText>{index + 1}</SmallText>
                  </FlexContainer>
                </RevisionButton>
                {!!displayConversationNotRead && !revision.conversation.is_read && (
                  <Icon icon={IconNames.COMMENT} color={SCIENT_COLORS.blue3} size={15} />
                )}
              </FlexContainer>
              <SmallText>{format(new Date(revision.created_at), "dd-MM-yy")}</SmallText>
            </FlexContainer>
          )
        })}
    </RevisionsListContainer>
  ) : null
}

export default RevisionsList
