// @ts-nocheck
import { compose, filter, includes } from "ramda"

import { formatSingleAndStr } from "./computation/formatSingleAndStr"
import computeDiff from "./computation/computeDiff"
import concatForecasts from "./computation/concatForecasts"

export const diffForecast = ({ forecasts, diffPair, noEntry, stock }) => {
  const defender = filter(forecast => includes(diffPair[0], forecast.slug), forecasts)
  const challenger = filter(forecast => includes(diffPair[1], forecast.slug), forecasts)
  const diffForecasts = [...defender, ...challenger]

  const computeDiffForecast = compose(
    computeDiff,
    formatSingleAndStr("diff"),
    concatForecasts,
  )(diffForecasts)

  const cleanDiffForecast =
    diffForecasts.length === 2
      ? {
          str_name: `${stock.bloomberg_code}>diff-value`,
          slug: `${stock.slug}-diff-value`,
          company: `Diff (value): ${diffForecasts[1].company} vs ${diffForecasts[0].company}`,
          label: "Base",
          owner: "default",
          rfentries: computeDiffForecast.rfentries,
        }
      : {
          str_name: `${stock.bloomberg_code}>diff-value`,
          slug: `${stock.slug}-diff-value`,
          company: "Diff (value): select diff forecasts",
          label: "Base",
          owner: "default",
          rfentries: noEntry,
        }
  return cleanDiffForecast
}
