// @ts-nocheck
import { Button, ButtonGroup, Classes, Dialog, Tooltip } from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import desksDuck from "../../../../state/modules/desks"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { Col, Row } from "../../../components"
import { useAuth } from "../../../hooks"
import { EditDesk } from "../Desk/EditDesk"
import { OwnerInfoTooltip } from "../components"
import { updatePos, useGetDeskOwnerName, useGetWritePermission } from "../utils"

const NoFavoriteDeskItem = ({ userDesks, noFavoriteDesk, setManageDesks }) => {
  const navigate = useNavigate()
  const { profile } = useAuth()
  const [dialog, setDialog] = useState(false)
  const [deskToDelete, setDeskToDelete] = useState(false)

  const toggleDialog = () => setDialog(!dialog)

  const id = noFavoriteDesk.id
  const deskOwnerId = noFavoriteDesk.owner_id
  const userId = profile.id
  const deskOwnerName = useGetDeskOwnerName(noFavoriteDesk)

  const hasWritePermission = useGetWritePermission(noFavoriteDesk)

  const dispatch = useDispatch()

  const lastPos = userDesks.length
  const addExistingDesk = ({ id }) => {
    dispatch(desksDuck.actions.upsertUserDesks([...userDesks, { desk: id, pos: lastPos + 1 }]))
  }

  const handleClick = () => addExistingDesk({ id })

  const handleDelete = () => {
    dispatch(
      desksDuck.actions.upsertUserDesks(
        R.compose(updatePos, R.reject(R.propEq("desk", id)))(userDesks),
      ),
    )
    setTimeout(() => {
      dispatch(desksDuck.actions.deleteDesk({ id }))
    }, 500)
    setDeskToDelete(false)
  }

  const style = () => {
    if (userId === deskOwnerId) {
      return { textAlign: "center" }
    }
    if (hasWritePermission) {
      return { textAlign: "center", color: SCIENT_COLORS.lightBlue1 }
    }
    return { textAlign: "center", color: SCIENT_COLORS.gray4 }
  }

  return (
    <>
      <Row style={{ margin: "1px 0" }}>
        <Col xs={9}>
          <Button
            onClick={() => {
              navigate(`/desks/${noFavoriteDesk.id}/`)
              setManageDesks(false)
            }}
            fill
            style={style()}
          >
            {noFavoriteDesk.name}
          </Button>
        </Col>
        <Col xs={2}>
          <ButtonGroup fill>
            <Tooltip
              content="Add to favorite desks"
              placement="right"
              openOnTargetFocus={false}
              compact
            >
              <Button minimal icon="add" onClick={handleClick} />
            </Tooltip>
            {userId === deskOwnerId || hasWritePermission ? (
              <>
                <EditDesk
                  id={id}
                  userDesks={userDesks}
                  isOpen={dialog}
                  toggleDialog={toggleDialog}
                  isUserDesk={false}
                />
                <Tooltip content="Edit desk" placement="right" openOnTargetFocus={false} compact>
                  <Button minimal icon="cog" onClick={toggleDialog} />
                </Tooltip>
                {userId === deskOwnerId ? (
                  <Button
                    id={`delete${noFavoriteDesk.name}`}
                    minimal
                    icon="trash"
                    onClick={() => setDeskToDelete(noFavoriteDesk)}
                  />
                ) : (
                  <OwnerInfoTooltip deskOwnerName={deskOwnerName} toggleDialog={toggleDialog} />
                )}
              </>
            ) : (
              <>
                <p style={{ margin: "0 4px 0 0", fontSize: "11px", textAlign: "center" }}>
                  Read only
                </p>
                <OwnerInfoTooltip deskOwnerName={deskOwnerName} />
              </>
            )}
          </ButtonGroup>
        </Col>
      </Row>

      <Dialog
        title="Delete desk"
        className="bp5-dark"
        icon="delete"
        intent="danger"
        isOpen={deskToDelete}
        onClose={() => setDeskToDelete(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Are you sure you want to delete desk: <strong>{noFavoriteDesk.name}</strong>?
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setDeskToDelete(false)} text="Close" />
            <Button onClick={handleDelete} intent="danger" text="Delete" />
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default NoFavoriteDeskItem
