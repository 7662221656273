/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"

export const RequestPasswordResetContainer = styled(FlexContainer)({
  background: "linear-gradient(180deg, rgba(226,226,226,1) 0%, rgba(162,162,162,1) 100%)",
  padding: "50px",
  borderRadius: "15px",
  width: "600px",
})

export const FormContainer = styled(FlexContainer)({
  width: "100%",
})

export const resetPasswordRequestSubmitButtonCss = css({
  padding: "12px",
  fontSize: "18px",
  width: "120px !important",
  height: "40px",
})

export const PasswordContainer = styled(FlexContainer)({
  ".css-1km0bek": {
    ".bp3-icon > svg:not([fill])": {
      fill: "#137cbd",
    },
  },
})
