import { Button, Callout, Intent } from "@blueprintjs/core"
import { getIn, useField, useFormikContext } from "formik"
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { IconNames } from "@blueprintjs/icons"
import { FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { SCIENT_ROUTES } from "../../../Routes"
import RevisionsList from "../List/Content/RevisionsList"
import { Idea, IdeaRevision } from "../types/business"
import { IdeaCreateHeaderButtons, IdeaCreateHeaderContainer } from "./IdeaCreateHeader.styled"

/**
 * Represents the action we are about to do with the idea.
 */
export const IdeaActionType = {
  Updating: "Update",
  Publishing: "Publish",
  NewRevision: "Create new revision",
  NewDraft: "Save as draft",
}

interface IIdeaCreateHeaderProps {
  submitDisabled: boolean
  setSelectedRevision: Dispatch<SetStateAction<IdeaRevision | undefined>>
  selectedRevision?: IdeaRevision
  idea?: Idea
  isLoading: boolean
  contentFieldName: string
  isCreatingDraft: boolean
}

const IdeaCreateHeader = ({
  submitDisabled,
  selectedRevision,
  setSelectedRevision,
  idea,
  isLoading,
  contentFieldName,
  isCreatingDraft,
}: IIdeaCreateHeaderProps) => {
  const { errors } = useFormikContext<Record<string, string>>()
  const [content] = useField(contentFieldName)

  const [ideaActionType, setIdeaActionType] = useState(IdeaActionType.Publishing)

  /**
   * Check wether we are creating a new revision
   */
  const { pathname } = useLocation()
  const isRevisionCreateMode = useMemo(() => pathname.includes("revision/create"), [pathname])
  const isUpdating = useMemo(() => pathname.includes("update"), [pathname])

  /**
   * Computes which action should be done on Create View based on route (create or update) and draft mode.
   * Actions are Saving, Updating, Publishing
   */
  useEffect(() => {
    if (isUpdating) {
      setIdeaActionType(IdeaActionType.Updating)
    } else if (isRevisionCreateMode) {
      setIdeaActionType(IdeaActionType.NewRevision)
    } else if (isCreatingDraft) {
      setIdeaActionType(IdeaActionType.NewDraft)
    }
  }, [isUpdating, isRevisionCreateMode, isCreatingDraft])

  return (
    <IdeaCreateHeaderContainer gap="10px">
      <IdeaCreateHeaderButtons gap="10px" style={{ overflow: "auto" }}>
        <FlexContainerAndItem gap="5px" alignItems="center">
          <Link to={SCIENT_ROUTES.IDEAS}>
            <Button text="Message List" icon="arrow-left" large />
          </Link>
          <Button
            text={ideaActionType}
            intent="primary"
            icon={IconNames.SEND_MESSAGE}
            type="submit"
            disabled={submitDisabled}
            loading={isLoading || submitDisabled}
            large
          />
        </FlexContainerAndItem>
        {idea && (
          <RevisionsList
            selectedRevision={selectedRevision}
            setSelectedRevision={setSelectedRevision}
            idea={idea}
            setRead={false}
          />
        )}
      </IdeaCreateHeaderButtons>

      {errors && getIn(errors, content.name) && (
        <Callout intent={Intent.PRIMARY} style={{ color: SCIENT_COLORS.blue5, width: "50%" }}>
          {`Content is a required field`}
        </Callout>
      )}
    </IdeaCreateHeaderContainer>
  )
}

export default IdeaCreateHeader
