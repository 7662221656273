/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"

export const InputPasswordResetContainer = styled(FlexContainer)({
  width: "60%",
})

export const ResetLink = styled.a`
  font-size: 14px !important;
  color: #2e58a5 !important;
`
