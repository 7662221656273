import { convert } from "html-to-text"
import { array, boolean, number, object, string } from "yup"
import { IdeaSector, IdeaType, SourceType } from "../types/business"

/**
 * Yup validation schema for positions.
 */
const positionSchema = object().shape({
  id: number().positive().integer().required(),
  position_type: string().notRequired(), // Stock by default
})

const revisionStructSchema = object().shape({
  content: string()
    .transform(value => convert(value))
    .trim()
    .required("Content is required for struct ideas"),
  recommendation: string().required("Recommendation is required for struct ideas"),
  target_price: number().notRequired(),
  conviction: number().positive().integer().min(1).max(5).required(),
})

const ideaCreateFormSchema = (isUpdate: boolean) =>
  object().shape({
    source_type: string().when("idea_type", {
      is: (val: IdeaType) => [IdeaType.NOTE].includes(val),
      then: () => string().required("Source type is a required field"),
    }),
    source: string().when("idea_type", {
      is: (val: IdeaType) => [IdeaType.NOTE].includes(val),
      then: () =>
        string().when("source_type", {
          is: (value: string) =>
            value === SourceType.CORPORATE_ACCESS || value === SourceType.SELL_SIDE,
          then: () =>
            string()
              .trim()
              .max(100, "Contact must have maximum 100 characters")
              .required("Contact is a required field"),
        }),
    }),
    idea_type: string().required(),
    trade: object().when("idea_type", {
      is: IdeaType.TRADE,
      then: () =>
        object()
          .shape({
            short: object().shape({ instance: positionSchema }),
            long: object().shape({ instance: positionSchema }),
          })
          .required(),
    }),
    revision: object()
      .when("idea_type", {
        is: (idea_type: IdeaType) => idea_type === IdeaType.STRUCTURAL && !isUpdate,
        then: () => revisionStructSchema,
      })
      .when("idea_type", {
        is: (idea_type: IdeaType) => idea_type === IdeaType.TRADE && !isUpdate,
        then: () =>
          object().shape({
            content: string()
              .transform(value => convert(value))
              .trim()
              .required(),
            conviction: number().positive().integer().min(1).max(5).required(),
          }),
      })
      .when("idea_type", {
        is: (idea_type: IdeaType) => idea_type === IdeaType.NOTE && !isUpdate,
        then: () =>
          object().shape({
            content: string()
              .transform(value => convert(value))
              .trim()
              .required(),
          }),
      }),
    revisions: array()
      .when(["idea_type"], {
        is: (ideaType: IdeaType) => {
          return ideaType === IdeaType.STRUCTURAL && isUpdate
        },
        then: () => array().of(revisionStructSchema).required(),
      })
      .when(["idea_type"], {
        is: (ideaType: IdeaType) => ideaType === IdeaType.NOTE && isUpdate,
        then: () =>
          array()
            .of(
              object().shape({
                content: string()
                  .transform(value => convert(value))
                  .trim()
                  .required(),
              }),
            )
            .required(),
      })
      .when(["idea_type"], {
        is: (ideaType: IdeaType) => ideaType === IdeaType.TRADE && isUpdate,
        then: () =>
          array()
            .of(
              object().shape({
                content: string()
                  .transform(value => convert(value))
                  .trim()
                  .required(),
                conviction: number().positive().integer().min(1).max(5).required(),
              }),
            )
            .required(),
      }),
    title: string().when("idea_type", {
      is: IdeaType.NOTE,
      then: () => string().trim().max(100, "Title must have maximum 100 characters").required(),
      otherwise: () => string().trim().max(100, "Title must have maximum 100 characters"),
    }),
    draft: boolean().required(),
    related_stocks: array().when(["idea_type"], {
      is: (ideaType: IdeaType) => ideaType === IdeaType.STRUCTURAL,
      then: () =>
        array().when(["related_sector"], {
          is: (related_sector: IdeaSector) => !related_sector?.id,
          then: () =>
            array()
              .min(1, "At least one stock or sector is required for struct ideas")
              .of(positionSchema)
              .required("At least one stock or sector is required for struct ideas"),
          otherwise: () => array().of(positionSchema).notRequired(),
        }),
      otherwise: () => array().of(positionSchema).notRequired(),
    }),
    related_sector: object().when(["idea_type", "related_stocks"], {
      is: (ideaType: IdeaType, related_stocks: [{ id: number }]) => {
        return ideaType === IdeaType.STRUCTURAL && !related_stocks.length
      },
      then: () =>
        object().shape({
          id: number()
            .positive()
            .integer()
            .required("At least one stock or sector is required for struct ideas"),
        }),
    }),
    meeting_date: string().when(["idea_type"], {
      is: IdeaType.NOTE,
      then: () => string().nullable().notRequired(),
    }),
    participants: array().when(["idea_type"], {
      is: (ideaType: IdeaType) => ideaType === IdeaType.NOTE,
      then: () => array().of(number().positive().integer()).notRequired(),
    }),
  })

export default ideaCreateFormSchema
