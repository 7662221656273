// @ts-nocheck
import React from "react"
import { H4 } from "@blueprintjs/core"

import { Row, Col } from "../../../../../../components"

const BottomData = ({ analyse, rnk }) => (
  <Row>
    <Col xs={4}>
      <H4>R2: {analyse[`${rnk}_r2`]}</H4>
    </Col>
    <Col xs={4}>
      <H4>Next predicted Y: {analyse[`${rnk}_next_predicted_y`]}</H4>
    </Col>
    <Col xs={4}>
      <H4>Frequency adjustment: {analyse[`${rnk}_frequency_adjustment`]}</H4>
    </Col>
  </Row>
)

export default BottomData
