// @ts-nocheck
import { addIndex, compose, concat, isEmpty, propOr, reduceRight, reject } from "ramda"

const reduceRightIndexed = addIndex(reduceRight)

export const concatWithSeparator = lists =>
  compose(
    reduceRightIndexed((list, acc, index, newList) => {
      const realIndex = newList.length - index - 1
      return concat(newList.length > 1 && realIndex !== 0 ? ["separator", ...list] : list, acc)
    }, []),
    reject(isEmpty),
  )(lists)

export const getContextMenuItems = ({ params, setAddModal, setEditModal, setDeleteAlert }) => {
  const {
    node,
    context: { isCustom },
  } = params

  const rowIndex = propOr(-1, "rowIndex", node)

  const defaultItems = params.defaultItems || []

  const rowMenuItems =
    node && isCustom && rowIndex >= 0
      ? [
          {
            name: "Edit",
            action: () => setEditModal({ rowIndex }),
            icon: "<span icon='edit' class='bp5-icon bp5-intent-primary bp5-icon-edit' />",
          },
          {
            name: "Delete",
            action: () => setDeleteAlert({ rowIndex }),
            icon: "<span icon='remove' class='bp5-icon bp5-intent-danger bp5-icon-remove' />",
          },
        ]
      : []

  const insertMenuItems = isCustom
    ? [
        {
          name: "Insert",
          icon: "<span icon='add' class='bp5-icon bp5-intent-primary bp5-icon-add' />",
          action: () => setAddModal({ show: true, rowIndex }),
        },
      ]
    : []

  const stylingItems =
    node & isCustom
      ? [
          {
            name: "Styling",
            subMenu: [
              {
                name: "Bold",
              },
            ],
            icon: "<span icon='style' class='bp5-icon bp5-intent-warning bp5-icon-style' />",
          },
        ]
      : []

  return concatWithSeparator([rowMenuItems, insertMenuItems, stylingItems, defaultItems])
}
