import styled from "@emotion/styled"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"


export const FullScreenGridContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  background-color: ${SCIENT_COLORS.darkGray3};
  //* Override aggrid css to hide horizontal scrollbar and its container. 
  .ag-body-horizontal-scroll {
    height: 0!important;
    min-height: 0!important;
  }
  //* Needed to hide horizontal scrollbar of Summary grid.
  .ag-center-cols-viewport {
  overflow-x: hidden;
  }
`

export const FullScreenGridHeader = styled(FlexContainer)({
  margin: "3px 10px 3px 10px",
})
 