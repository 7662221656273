// @ts-nocheck
import { useSharedFields } from "./useSharedFields"
import { useStockCustomFields } from "./useStockCustomFields"

// srcKind can be "primary" or "new" for absolut source

// If Primary source, we use sharedField as customFields,
// else we use stCustomFields for stock and rfCustomFields for report forecast.

export const useCustomFields = ({
  sourceId,
  srcKind,
  isCustomFieldsChoiceSelected,
  isGroupSegs,
}) => {
  const sharedFields = useSharedFields()
  const stockCustomFields = useStockCustomFields(sourceId, isGroupSegs)

  const isPrimarySelected = srcKind === "primary"

  const customFields = isCustomFieldsChoiceSelected
    ? isPrimarySelected
      ? sharedFields
      : stockCustomFields
    : []
  return customFields
}
