// @ts-nocheck
import React, { useEffect } from "react"
import { NonIdealState } from "@blueprintjs/core"
import { useNavigate } from "react-router-dom"

const NotFound = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const t = setTimeout(() => {
      navigate("/")
    }, 2000)
    return () => {
      clearTimeout(t)
    }
  }, [navigate])

  return (
    <div className="content">
      <NonIdealState
        icon={"search"}
        title={["Uh Oh ... this page does not exist"]}
        description="You will be redirected"
      />
    </div>
  )
}

export default NotFound
