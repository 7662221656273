// @ts-nocheck
import React from "react"

import Values from "./Values"
import GroupGrowth from "./GroupGrowth"
import SplitGrowth from "./SplitGrowth"

const Shared = props => {
  if (props.subTabId === "default") {
    return <Values {...props} />
  }
  if (props.subTabId === "growth;group") {
    return <GroupGrowth {...props} />
  }
  if (props.subTabId.includes("growth;split")) {
    return <SplitGrowth {...props} />
  }
  return null
}

export default Shared
