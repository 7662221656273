// @ts-nocheck
import { compose, pathOr, propEq } from "ramda"

export const getHeaderClasses = entry => {
  const forecastClass = entry.forecast ? "sct-forecast" : "sct-header-a"
  const fyClass = entry.slug.endsWith("fy") ? "sct-fy" : ""
  return [forecastClass, fyClass].join(" ")
}

export const cellClassRules = entry => {
  return {
    "sct-cell": () => true,
    "sct-forecast": () => entry.forecast,
    "sct-fy": params => params.colDef.field.endsWith("FY") === true,
    // "sct-focus1-e": params =>
    //   params.data.classes !== undefined &&
    //   params.data.classes.includes("sct-focus1") &&
    //   entry.forecast,
    // "sct-focus2-e": params =>
    //   params.data.classes !== undefined &&
    //   params.data.classes.includes("sct-focus2") &&
    //   entry.forecast,
    // "sct-focus4-e": params =>
    //   params.data.classes !== undefined &&
    //   params.data.classes.includes("sct-focus4") &&
    //   entry.forecast,git
  }
}

export const rowClassRules = {
  "sct-focus1": compose(propEq("focus", "1"), pathOr({}, ["data", "styles"])),
  "sct-focus2": compose(propEq("focus", "2"), pathOr({}, ["data", "styles"])),
  "sct-focus3": compose(propEq("focus", "3"), pathOr({}, ["data", "styles"])),
  "sct-focus4": compose(propEq("focus", "4"), pathOr({}, ["data", "styles"])),
  "sct-italic": compose(propEq("italic", true), pathOr({}, ["data", "styles"])),
  "sct-bold": compose(propEq("bold", true), pathOr({}, ["data", "styles"])),
  "sct-underline": compose(propEq("underline", true), pathOr({}, ["data", "styles"])),
  "sct-empty-row": compose(propEq("emptyRow", true), pathOr({}, ["data", "styles"])),

  "sct-front-blue": compose(propEq("frontColor", "blue"), pathOr({}, ["data", "styles"])),
  "sct-front-green": compose(propEq("frontColor", "green"), pathOr({}, ["data", "styles"])),
  "sct-front-orange": compose(propEq("frontColor", "orange"), pathOr({}, ["data", "styles"])),
  "sct-front-red": compose(propEq("frontColor", "red"), pathOr({}, ["data", "styles"])),
  "sct-front-indigo": compose(propEq("frontColor", "indigo"), pathOr({}, ["data", "styles"])),
  "sct-front-turquoise": compose(propEq("frontColor", "turquoise"), pathOr({}, ["data", "styles"])),
  "sct-front-sepia": compose(propEq("frontColor", "sepia"), pathOr({}, ["data", "styles"])),
}
