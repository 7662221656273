// @ts-nocheck
import analysis from "./modules/analysis"
import auth from "./modules/auth"
import { combineReducers } from "redux"
import consensus from "./modules/consensus"
import dataSetEvents from "./modules/dataSetEvents"
import dataSetLists from "./modules/dataSetLists"
import dataSetSearch from "./modules/dataSetSearch"
import dataSets from "./modules/dataSets"
import dataframes from "./modules/dataframes"
import desks from "./modules/desks"
import dsForecasts from "./modules/dsForecasts"
import entries from "./modules/entries"
import geos from "./modules/geos"
import lastReports from "./modules/lastReports"
import monitors from "./modules/monitors"
import ping from "./modules/ping"
import profile from "./modules/profile"
import rForecasts from "./modules/rForecasts"
import search from "./modules/search"
import sectors from "./modules/sectors"
import shared from "./modules/shared"
import sheets from "./modules/sheets"
import stockDetail from "./modules/stockDetail"
import tfrowdefs from "./modules/tfrowdefs"
import transforms from "./modules/transforms"
import ui from "./modules/ui"

const appReducer = combineReducers({
  auth: auth.reducer,
  analysis,
  consensus: consensus.reducer,
  dataframes: dataframes.reducer,
  dataSetEvents: dataSetEvents.reducer,
  dataSetLists: dataSetLists.reducer,
  dataSets: dataSets.reducer,
  dataSetSearch: dataSetSearch.reducer,
  desks: desks.reducer,
  entries: entries.reducer,
  dsForecasts: dsForecasts.reducer,
  geos: geos.reducer,
  lastReports: lastReports.reducer,
  monitors: monitors.reducer,
  ping: ping.reducer,
  profile: profile.reducer,
  rForecasts: rForecasts.reducer,
  search,
  sectors: sectors.reducer,
  shared,
  sheets: sheets.reducer,
  stockDetail: stockDetail.reducer,
  tfrowdefs,
  transforms,
  ui: ui.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === "auth/logoutOk") {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
