// @ts-nocheck
import { mergeMap, map, debounceTime, withLatestFrom } from "rxjs/operators"
import { ofType, combineEpics } from "redux-observable"
import { isEmpty } from "ramda"

import dataSetSearchDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { searchDataSet, searchDataSetOk },
} = dataSetSearchDuck

export const searchDataSetEpic = (action$, state$, { ajax, auth, scheduler }, debounceMs = 800) =>
  action$.pipe(
    ofType(searchDataSet.type),
    withLatestFrom(state$),
    debounceTime(debounceMs, scheduler),
    mergeMap(([, state]) => {
      const { search, geos, sectors, eco_only, hide_daily, hide_bbg, ordering, useLocalStorage } =
        state.dataSetSearch

      /**
       *  Note: useLocalStorage is not the hook to manage localStorage.
       *  It's just a boolean to know if this part of Redux state uses the localStorage
       */
      useLocalStorage &&
        localStorage.setItem(
          "dataSetSearch",
          JSON.stringify({
            search,
            geos,
            sectors,
            eco_only,
            hide_daily,
            hide_bbg,
            ordering,
          }),
        )

      const params = [`?search=${search}`]
      if (!isEmpty(geos)) {
        params.push(`geos=${geos}`)
      }
      if (!isEmpty(sectors)) {
        params.push(`sectors=${sectors}`)
      }
      if (eco_only) {
        params.push("eco_only=1")
      }
      if (hide_daily) {
        params.push("hide_daily=1")
      }
      if (hide_bbg) {
        params.push("hide_bbg=1")
      }
      if (ordering) {
        params.push(`ordering=${ordering["1"]},${ordering["2"]},${ordering["3"]}`)
      }
      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/datasets/search/${params.join("&")}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(map(({ response }) => searchDataSetOk(response)))
    }),
  )

const dataSetSearchEpics = combineEpics(searchDataSetEpic)

export default dataSetSearchEpics
