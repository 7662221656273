// @ts-nocheck
import React from "react"
import { Field } from "formik"
import { Tag, H4, H6 } from "@blueprintjs/core"

import { NumericInput, fakeSyntheticEvent } from "../../../../../components/formik"
import { Grid, Row, Col } from "../../../../../components"
import { slugToName, fields } from "../../../utils"
import { getDeepNestedValue } from "../../../../../../utils"

export default ({
  // values,
  currencies,
  handleChange,
  reportData: { prevReport, prevYearReport },
}) => {
  return (
    <Grid fluid>
      {currencies.map((c, index) => {
        const getPrevReportFieldValue = field =>
          getDeepNestedValue(["currencies", `slug:${c.slug}`, field], prevReport)

        const getPrevYearReportFieldValue = field =>
          getDeepNestedValue(["currencies", `slug:${c.slug}`, field], prevYearReport)

        return (
          <div key={index}>
            <Row>
              <Col xsOffset={2} xs={2}>
                <H4>{c.name}</H4>
              </Col>
            </Row>
            {fields.currency.map(field => (
              <Row key={field}>
                <Col xsOffset={2} xs={2}>
                  <H6> {slugToName(field)} </H6>
                </Col>

                <Col xs={2}>
                  <Tag large fill minimal style={{ textAlign: "right" }}>
                    {getPrevYearReportFieldValue(field)}
                  </Tag>
                </Col>

                <Col xs={2}>
                  <Tag large fill minimal style={{ textAlign: "right" }}>
                    {getPrevReportFieldValue(field)}
                  </Tag>
                </Col>

                <Col xs={3}>
                  <Field
                    name={`currencies.${index}.${field}`}
                    key={`currencies.${index}.${field}`}
                    style={{ textAlign: "right" }}
                    component={NumericInput}
                    onValueChange={(numVal, stringVal) =>
                      handleChange(fakeSyntheticEvent(stringVal, `currencies.${index}.${field}`))
                    }
                  />
                </Col>
              </Row>
            ))}
          </div>
        )
      })}
    </Grid>
  )
}
