// @ts-nocheck
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { SCIENT_COLORS } from "../../../../../../../../../../../styled/scientColors"

export const MultiConector = styled.div(props => ({
  marginLeft: "16px",
  marginTop: props.hasMultipleInputs ? "-13px" : 0,
}))

export const Li = styled.li(props => ({
  margin: "4px 0",
  cursor: props.optional && "pointer",
}))

export const eyeIconCss = css({
  marginLeft: "4px",
  marginBottom: "-3px",
})

export const manyToOneIconCss = css({
  marginLeft: "0px",
  color: SCIENT_COLORS.gray4,
})
