// @ts-nocheck
import { compose, find, head, map, propEq, take, replace } from "ramda"
import { stockFields } from "./stockFields"

// generate col titles displayed in EditColumn and in column header of grid
export const getColTitle = (source, fieldList, factorList, onEdit) => {
  switch (source.kind) {
    case "stockField": {
      const formattedFields = stockFields(fieldList)
      const stockField = source.path && head(formattedFields.filter(e => e.path === source.path))
      const title =
        source.path && // get title only when source has a selected path
        (stockField.text.includes("[Group]")
          ? stockField.text.replace(/\[Group\] /g, "")
          : stockField.text)
      const forecastOverride = source.forecastOverride
        ? source.forecastOverride.company
          ? `-${source.forecastOverride.company.toUpperCase()}`
          : ""
        : ""

      const freq = source.path.includes("VAL") ? "" : `(${source.freq.toUpperCase()})` // no freq for valuation fields
      return source.frontOpts.titleOverride
        ? `${source.frontOpts.titleOverride}${freq}${forecastOverride}`
        : `${title}${freq}${forecastOverride}`
    }
    case "stockParam": {
      const text = compose(replace(/_/g, " "), replace(/STK_/g, ""))(source.path)
      const textWithUpperCase = source.path && text[0].toUpperCase() + text.slice(1)
      const stkTitle = `[STK] ${textWithUpperCase}`
      return source.frontOpts.titleOverride ? source.frontOpts.titleOverride : stkTitle
    }
    case "factor": {
      const factorText = source.label ? find(propEq("key", source.label))(factorList)["text"] : ""
      return source.frontOpts.titleOverride
        ? `Factor: ${source.frontOpts.titleOverride} (${source.freq.toUpperCase()})`
        : `Factor: ${factorText} (${source.freq.toUpperCase()})`
    }
    case "formula":
      return onEdit ? `Formula: ${source.frontOpts.title}` : source.frontOpts.title

    default:
      return onEdit
        ? source.frontOpts.title
          ? `Separation col: ${source.frontOpts.title}`
          : "Separation col"
        : source.frontOpts.title
  }
}

// generate row titles displayed in EditRow
export const getRowTitle = source => {
  switch (source.kind) {
    case "row":
      return `Title: ${source.frontOpts.title}`
    case "aggregate":
      return source.frontOpts.titleOverride
        ? `Aggregate: ${source.frontOpts.titleOverride}`
        : `Aggregate: ${source.method}`
    default: {
      // display only the first 3 stocks 's name
      const stockList = compose(
        map(stock =>
          stock.frontOpts.titleOverride
            ? ` ${stock.frontOpts.titleOverride}`
            : ` ${stock.str_name}`,
        ),
        take(3),
      )(source.stocks)
      return source.stocks.length > 3
        ? `Items:${stockList}...`
        : source.stocks.length <= 1
        ? `Item:${stockList}`
        : `Items:${stockList}`
    }
  }
}
