// @ts-nocheck
import { Card } from "@blueprintjs/core"
import update from "immutability-helper"
import * as R from "ramda"
import { useState } from "react"
import { useDrop } from "react-dnd"
import { useSelector } from "react-redux"

import { SCIENT_COLORS } from "../../../../../../../../../styled/scientColors"
import { randomid } from "../../../../../../../../../utils"
import { FormulaButtons } from "./FormulaButtons"
import { FormulaItem } from "./FormulaItem"
import { FormulaPanels } from "./FormulaPanels"
import itemTypes from "./itemTypes"

const style = {
  display: "flex",
  flexWrap: "wrap",
}

export const FormulaBuilder = ({ formulaItems, values, setValues, card }) => {
  const [showPanels, setShowPanels] = useState(false)
  const toggleShowPanels = formulaItem => {
    showPanels ? setShowPanels(false) : setShowPanels(formulaItem)
  }

  const fieldList = useSelector(R.pathOr({}, ["shared", "fieldList"]))
  const factorList = useSelector(R.pathOr({}, ["shared", "fieldList", "factor_list"]))

  const cols = values.colDefs.cols

  // for react dnd
  const findItem = itemId => {
    const formulaItem = formulaItems.filter(e => e.itemId === itemId)[0]
    return {
      formulaItem,
      index: formulaItems.indexOf(formulaItem),
    }
  }

  const moveItem = (itemId, atIndex) => {
    const { formulaItem, index } = findItem(itemId)
    const newFormulaItems = update(formulaItems, {
      $splice: [
        [index, 1],
        [atIndex, 0, formulaItem], // move item from 1 to 0
      ],
    })

    const newValues = {
      colDefs: {
        ...values.colDefs,
        cols: R.map(
          col => (col.colId === card.colId ? { ...col, formulaItems: newFormulaItems } : col),
          cols,
        ),
      },
    }
    return setValues(newValues)
  }
  const [, drop] = useDrop({ accept: itemTypes.BUTTON })
  // end dnd

  return (
    <>
      {showPanels && (
        <FormulaPanels
          showPanels={showPanels}
          setShowPanels={setShowPanels}
          values={values}
          setValues={setValues}
          card={card}
          fieldList={fieldList}
          factorList={factorList}
        />
      )}

      <Card style={{ padding: "4px" }}>
        {/* displayed error message when formula is missing */}
        {formulaItems.length === 0 && (
          <p style={{ color: SCIENT_COLORS.orange3 }}>Formula is required!</p>
        )}

        <div ref={drop} style={style}>
          {formulaItems.map((formulaItem, index) => (
            <FormulaItem
              key={randomid()}
              values={values}
              setValues={setValues}
              card={card}
              formulaItems={formulaItems}
              formulaItem={formulaItem}
              index={index}
              moveItem={moveItem}
              findItem={findItem}
              fieldList={fieldList}
              factorList={factorList}
              toggleShowPanels={toggleShowPanels}
            />
          ))}
        </div>
      </Card>

      <FormulaButtons
        values={values}
        setValues={setValues}
        card={card}
        cols={cols}
        formulaItems={formulaItems}
        toggleShowPanels={toggleShowPanels}
      />
    </>
  )
}
