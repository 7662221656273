// @ts-nocheck
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Classes, Dialog } from "@blueprintjs/core"

import desksDuck from "../../../../state/modules/desks"
import { InputGroup } from "../../../components"

export const CreateNewDesk = ({ userDesks, isOpen, toggleDialog }) => {
  const [text, setText] = useState("")

  const createDeskData = {
    name: text,
    userDesks: userDesks,
  }

  const handleText = ({ target: { value } }) => setText(value)

  const dispatch = useDispatch()
  const handleSubmitNewDesk = () => {
    dispatch(desksDuck.actions.createDesk(createDeskData))
    setText("")
    toggleDialog()
  }
  const handleCancel = () => {
    setText("")
    toggleDialog()
  }

  return (
    <Dialog
      className="bp5-dark"
      title="Create new desk"
      icon="plus"
      isOpen={isOpen}
      onClose={handleCancel}
    >
      <div className={Classes.DIALOG_BODY}>
        <InputGroup
          inputProps={{
            placeholder: "New name...",
            value: text,
            onChange: handleText,
            onEnter: handleSubmitNewDesk,
            onEsc: handleCancel,
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS} style={{ margin: 0 }}>
          <Button icon="cross" text="Cancel" onClick={handleCancel} />
          <Button
            intent="primary"
            icon="floppy-disk"
            text="Create desk"
            onClick={handleSubmitNewDesk}
          />
        </div>
      </div>
    </Dialog>
  )
}
