// @ts-nocheck
import React from "react"
import { Spinner } from "@blueprintjs/core"

export const Loader = ({ title = "Loading...", ...props }) => (
  <div className="content" {...props}>
    <div className="sct-full sct-loader">
      <div>
        <Spinner />
        {title && <p>{title}</p>}
      </div>
    </div>
  </div>
)
