// @ts-nocheck
import { debounceTime, map, switchMap } from "rxjs/operators"
import { searchRForecasts, searchRForecastsOk } from "../index"

import { REACT_APP_API_DOMAIN } from "../../../../config/constants"
import { ofType } from "redux-observable"

const searchRForecastsEpic = (action$, state$, { ajax, auth, scheduler }, debounceMs = 800) =>
  action$.pipe(
    ofType(searchRForecasts.type),
    debounceTime(debounceMs, scheduler),
    // .filter(action => action.payload.length > 2)
    switchMap(action => {
      const { parent, search } = action.payload

      const searchString = search ? `?search=${search}` : ""

      return ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/search/rforecasts/${parent}/${searchString}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(map(({ response }) => searchRForecastsOk({ results: response.results })))
    }),
  )

export default searchRForecastsEpic
