import { AxiosInstance } from "axios"

export type bloombergData = { datetime: string; value: number }

export interface IBloombergAPI {
  getBloombergData: ({ query }: { query: string }) => Promise<bloombergData[]>
}

const bloomberg = (axiosClient: AxiosInstance): IBloombergAPI => ({
  /**
   * GET /api/v1/bloomberg/data/
   */
  getBloombergData: async ({ query }: { query: string }) => {
    const { data } = await axiosClient.get<bloombergData[]>("bloomberg/data/", {
      params: { ticker: query },
    })

    return data
  },
})

export default bloomberg
