// @ts-nocheck
import { of } from "rxjs"
import { map, mergeMap, catchError, withLatestFrom } from "rxjs/operators"
import { ofType } from "redux-observable"

import profileDuck from "../."
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: {
    stockSetFreq,
    stockSetForecast,
    stockSetAvailableTabs,
    stockToggleEdit,
    stockToggleInactive,
    stockToggleEmpty,
    stockSetConsensusFields,
    stockSetThresholdDays,
    stockSetDiffMethod,
    stockSetDiffDefender,
    stockSetDiffChallenger,
    stockToggleStarred,
    stockToggleExcluded,
    stockSetQuarterAmount,
    stockSetYearAmount,
    stockCreateTab,
    stockRenameTab,
    stockDeleteTab,
    upsertUserStockConfigOk,
    upsertUserStockConfigError,
    addLine,
    editLine,
    deleteLine,
    moveLine,
  },
} = profileDuck

const upsertUserStockConfigEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(
      stockSetFreq.type,
      stockToggleEdit.type,
      stockToggleInactive.type,
      stockToggleEmpty.type,
      stockSetForecast.type,
      stockSetAvailableTabs.type,
      stockSetConsensusFields.type,
      stockSetThresholdDays.type,
      stockSetDiffMethod.type,
      stockSetDiffDefender.type,
      stockSetDiffChallenger.type,
      stockToggleStarred.type,
      stockToggleExcluded.type,
      stockSetQuarterAmount.type,
      stockSetYearAmount.type,
      stockCreateTab.type,
      stockRenameTab.type,
      stockDeleteTab.type,
      addLine.type,
      editLine.type,
      deleteLine.type,
      moveLine.type,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { slug } = action.payload
      const usc = state.profile.userstockconfigs[slug]
      const url = usc.id
        ? `${REACT_APP_API_DOMAIN}/api/v1/users/userstockconfigs/${usc.id}/`
        : `${REACT_APP_API_DOMAIN}/api/v1/users/userstockconfigs/`
      const method = usc.id ? "PATCH" : "POST"
      return ajax({
        url,
        body: usc,
        method,
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "application/json",
        },
      }).pipe(
        map(({ response }) => upsertUserStockConfigOk({ slug, data: response })),
        catchError(error => of(upsertUserStockConfigError(error.xhr.response))),
      )
    }),
  )
export default upsertUserStockConfigEpic
