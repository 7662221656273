// @ts-nocheck
import { append, compose, filter, includes, lensPath, map, reject, set, values } from "ramda"

import { Checkbox } from "@blueprintjs/core"
import { Row, Col } from "../../../components"
import { useGlobalState } from "../../../hooks/useGlobalState"
import { useAuth } from "../../../hooks"

export const UserGroupsCheckbox = ({ groups, setGroups }) => {
  const { groups: groupList } = useGlobalState()
  const { profile } = useAuth()
  const userGroupNameList = profile.groups || []
  const userGroupList = compose(
    values,
    filter(e => includes(e.name, userGroupNameList)),
  )(groupList)

  const readGroupIds = compose(
    values,
    map(e => e.group_id),
    filter(e => e.write === false),
  )(groups)

  const writeGroupIds = compose(
    values,
    map(e => e.group_id),
    filter(e => e.write === true),
  )(groups)

  // Checkbox value give a string type, so need to parseInt(value)
  const handleReadGroup = ({ target: { value } }) => {
    if (writeGroupIds.includes(parseInt(value))) {
      setGroups(reject(g => g.group_id === parseInt(value), groups))
    } else if (readGroupIds.includes(parseInt(value)))
      setGroups(reject(g => g.group_id === parseInt(value), groups))
    else setGroups(append({ group_id: parseInt(value), write: false }, groups))
  }

  const handleWriteGroup = ({ target: { value } }) => {
    // Find the index of the groups array where value must be set
    const index = groups.findIndex(e => e.group_id === parseInt(value))

    if (readGroupIds.includes(parseInt(value)) && !writeGroupIds.includes(parseInt(value)))
      setGroups(set(lensPath([index, "write"]), true, groups))
    else if (writeGroupIds.includes(parseInt(value)))
      setGroups(set(lensPath([index, "write"]), false, groups))
    else {
      setGroups(append({ group_id: parseInt(value), write: true }, groups))
    }
  }

  return (
    <>
      <Row style={{ margin: "4px 0 8px" }}>
        <Col xsOffset={4} xs={4} style={{ textAlign: "center" }}>
          <p style={{ fontSize: "14px" }}>Read groups</p>
        </Col>
        <Col xs={4} style={{ textAlign: "center" }}>
          <p style={{ fontSize: "14px" }}>Write groups</p>
        </Col>
      </Row>
      {userGroupList.map(group => {
        return (
          <Row key={group.id} style={{ margin: "1px 0" }}>
            <Col xs={4} style={{ textAlign: "center" }}>
              <p style={{ fontSize: "14px" }}>{group.name}</p>
            </Col>

            <Col xs={4} style={{ textAlign: "center" }}>
              <Checkbox
                value={group.id}
                checked={includes(group.id, readGroupIds) || includes(group.id, writeGroupIds)}
                onChange={handleReadGroup}
              />
            </Col>
            <Col xs={4} style={{ textAlign: "center" }}>
              <Checkbox
                value={group.id}
                checked={includes(group.id, writeGroupIds)}
                onChange={handleWriteGroup}
              />
            </Col>
          </Row>
        )
      })}
    </>
  )
}
