// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import dsForecastsDuck from "../../."
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const {
  actions: { fetchForecast, fetchForecastOk, fetchForecastError },
} = dsForecastsDuck

const fetchForecastEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchForecast.type),
    mergeMap(action => {
      const { index, dataset, id } = action.payload
      const url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/${dataset}/dsforecasts/${id}/`
      return ajax({
        url,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchForecastOk({ index, forecast: response })),
        catchError(error => of(fetchForecastError({ index, error: error.xhr.response }))),
      )
    }),
  )

export default fetchForecastEpic
