import styled from "@emotion/styled"
import { FlexContainerAndItem } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

type RevisionButtonProps = {
  selected?: boolean
}

export const RevisionButton = styled.button<RevisionButtonProps>(props => ({
  height: "20px",
  width: "20px",
  borderRadius: "50%",
  fontSize: "12px",
  lineHeight: "10px",
  border: "none",
  cursor: "pointer",
  backgroundColor: props.selected ? SCIENT_COLORS.lightBlue4 : SCIENT_COLORS.lightBlue2,
  "&:hover": {
    backgroundColor: SCIENT_COLORS.lightBlue3,
  },
}))

export const RevisionsListContainer = styled(FlexContainerAndItem)({
  paddingLeft: "10px",
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
})
