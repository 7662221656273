// @ts-nocheck
import {
  Button,
  Card,
  Classes,
  Dialog,
  OverlayToaster,
  Position,
  Toast2,
  Tooltip,
} from "@blueprintjs/core"
import * as R from "ramda"
import { useState } from "react"
import ReactGridLayout, { WidthProvider } from "react-grid-layout"
import { useDispatch, useSelector } from "react-redux"

import "react-grid-layout/css/styles.css"

import desksDuck from "../../../../state/modules/desks"
import monitorDuck from "../../../../state/modules/monitors"
import { InfoButton } from "../../../components"
import { useAuth } from "../../../hooks"
import Monitor from "../Monitor"
import { EditMonitor } from "../Monitor/EditMonitor/index"
import { useGetDeskOwnerName, useGetWritePermission } from "../utils"
import Infos from "./Infos"
import NoMonitor from "./NoMonitor"

const GridLayout = WidthProvider(ReactGridLayout)

const Desk = ({ desk }) => {
  const [monitorToDelete, setMonitorToDelete] = useState(false)
  const { profile } = useAuth()

  const [monitorPrefs, setMonitorPrefs] = useState([])
  const toggleMonitorPrefs = id => {
    if (monitorPrefs.includes(id)) return setMonitorPrefs(R.reject(R.equals(id), monitorPrefs))
    return setMonitorPrefs([...monitorPrefs, id])
  }

  const monitors = useSelector(R.pathOr(null, ["monitors", "data"]))
  const deskMonitors = R.values(R.filter(e => R.includes(e.id, desk.monitor_ids), monitors))

  // Variables for permissions
  const userId = profile.id
  const deskOwnerId = desk.owner_id
  const deskOwnerName = useGetDeskOwnerName(desk)
  const hasWritePermission = useGetWritePermission(desk)
  // end Variables for permissions

  const { layout } = desk

  const dispatch = useDispatch()
  const clearDeskError = () => dispatch(desksDuck.actions.clearDeskError({ id: desk.id }))

  const handleLayoutChange = incomingLayout => {
    const newLayout = R.map(R.pick(["i", "x", "y", "w", "h"]), incomingLayout)
    if (newLayout.length >= layout.length && newLayout !== layout) {
      return dispatch(desksDuck.actions.updateDesk({ ...desk, layout: newLayout }))
    }
  }

  const deleteMonitor = id => {
    dispatch(monitorDuck.actions.deleteMonitor({ id, desk }))
    setMonitorToDelete(false)
  }

  const createElement = (monitor, params) => {
    const { id, name, error } = monitor
    const { i, h } = params

    const monitorHeight = 48 * h + 10 * (h - 1)

    const clearMonitorError = () => dispatch(monitorDuck.actions.clearMonitorError({ id }))

    return (
      <Card
        className="sct-monitor-container"
        key={i}
        style={{ padding: "4px 0 0 0" }}
        elevation={2}
      >
        <OverlayToaster position={Position.RIGHT}>
          {error && (
            <Toast2
              message={error}
              intent={"danger"}
              onDismiss={() => clearDeskError()}
              timeout={4000}
            />
          )}
          {error && (
            <Toast2
              message={error}
              intent={"danger"}
              onDismiss={() => clearMonitorError()}
              timeout={4000}
            />
          )}
        </OverlayToaster>

        <EditMonitor
          isOpen={R.includes(id, monitorPrefs)}
          toggleMonitorPrefs={toggleMonitorPrefs}
          desk={desk}
          monitor={monitor}
          userId={userId}
          hasWritePermission={hasWritePermission}
        />

        <div className="sct-monitor-left">
          <Button
            className="sct-monitor-drag"
            minimal
            onClick={() => {
              // do nothing
            }}
            icon="move"
          />
          <InfoButton
            content={<Infos monitor={monitor} deskOwnerName={deskOwnerName} />}
            buttonProps={{ minimal: true }}
          />
          <Tooltip content="Edit monitor" placement="bottom" compact openOnTargetFocus={false}>
            <Button minimal onClick={() => toggleMonitorPrefs(id)} icon="cog" />
          </Tooltip>
        </div>

        {deskOwnerId === userId && ( // only the deskOwner can delete a monitor
          <div className="sct-monitor-right">
            <Button
              className="sct-monitor-remove"
              minimal
              onClick={() => setMonitorToDelete({ id, name })}
              icon="cross"
            />
          </div>
        )}
        <Monitor
          desk={desk}
          monitor={monitor}
          monitorPrefs={monitorPrefs}
          toggleMonitorPrefs={toggleMonitorPrefs}
          height={monitorHeight}
        />

        <Dialog
          title="Delete monitor"
          className="bp5-dark"
          icon="delete"
          isOpen={monitorToDelete}
          setMonitorToDelete={setMonitorToDelete}
          onClose={() => setMonitorToDelete(false)}
        >
          <div className={Classes.DIALOG_BODY}>
            <p>
              Are you sure you want to delete monitor: <strong>{monitorToDelete.name}</strong>?
            </p>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => setMonitorToDelete(false)} text="Close" />
              <Button
                onClick={() => deleteMonitor(monitorToDelete.id)}
                intent="danger"
                text="Delete"
              />
            </div>
          </div>
        </Dialog>
      </Card>
    )
  }

  if (R.isEmpty(deskMonitors)) {
    return <NoMonitor desk={desk} />
  }

  return (
    <>
      <GridLayout
        layout={layout}
        cols={12}
        rowHeight={50}
        onLayoutChange={handleLayoutChange}
        draggableHandle=".sct-monitor-drag"
      >
        {deskMonitors.map(el =>
          createElement(el, R.find(R.propEq("i", R.toString(el.id)), layout)),
        )}
      </GridLayout>
    </>
  )
}
export default Desk
