// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import sectorsDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { fetchSectors, fetchSectorsOk, fetchSectorsError },
} = sectorsDuck

const fetchSectorsEpic = (action$, _, { ajax, auth }) =>
  action$.pipe(
    ofType(fetchSectors.type),
    mergeMap(action =>
      ajax({
        url: `${REACT_APP_API_DOMAIN}/api/v1/world/sectors/${action.payload}`,
        method: "GET",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(({ response }) => fetchSectorsOk(response)),
        catchError(error => of(fetchSectorsError(error.xhr.response))),
      ),
    ),
  )

export default fetchSectorsEpic
