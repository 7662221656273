import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { TextAreaAutosize } from "../../../../components"
import { useIdeasCache } from "../../../../hooks"
import { createToaster } from "../../../../utils/createToaster"
import { useUpdateMessageMutation } from "../../hooks"
import { ConversationMessage } from "../../types/business"
import { UpdateMessageContainer } from "./UpdateMessage.styled"

interface IUpdateMessageProps {
  message: ConversationMessage
  onExit: () => void
}

const UpdateMessage = ({ message, onExit }: IUpdateMessageProps) => {
  const messageUpdateRef = useRef<HTMLDivElement>(null)

  const [updateMessageContent, setUpdateMessageContent] = useState("")
  const trimedUpdateMessageContent = updateMessageContent.trim()
  useEffect(() => {
    if (message?.content) {
      setUpdateMessageContent(message.content)
    }
  }, [message])

  /**
   * Check if we click outside the update area,
   * if so we try to submit the update.
   */
  const handleClickOutside = (event: Event) => {
    if (
      messageUpdateRef.current &&
      !messageUpdateRef.current.contains(event.target as HTMLElement)
    ) {
      submitUpdate()
    }
  }

  /**
   * Add and clean the listener for the handleClick behavior.
   */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  const { updateMessage } = useIdeasCache()
  const updateMessageMutation = useUpdateMessageMutation({
    onSuccess: message => {
      updateMessage &&
        updateMessage(message.conversation_id, message.id, { content: message.content })
    },
    onError: () => {
      updateMessage && updateMessage(message.conversation_id, message.id, { status: "failure" })
      /**
       * Create toaster instance and use show
       * since we are in an async loop (we want the toaster to show
       * whatever the page)
       */
      createToaster({
        message: "An error occurred updating the Message, please refresh the page",
        intent: "danger",
      })
    },
  })

  /**
   * When we try to submit the update,
   * we only call the exit callback if the content don't change or if the trimed content is empty string
   * otherwise we call the update endpoint and exit.
   */
  const submitUpdate = () => {
    if (trimedUpdateMessageContent === message.content || trimedUpdateMessageContent === "") {
      onExit()
    } else {
      updateMessageMutation.mutate({
        messageId: message.id as number,
        values: { content: trimedUpdateMessageContent },
      })
      onExit()
    }
  }

  const handleTextAreaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setUpdateMessageContent(event.target?.value)
    },
    [setUpdateMessageContent],
  )

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.ctrlKey && event.key === "Enter") {
      submitUpdate()
    }
  }

  return (
    <UpdateMessageContainer ref={messageUpdateRef} flexDirection="column">
      <TextAreaAutosize
        value={updateMessageContent}
        handleChange={handleTextAreaChange}
        backgroundColor="#2683c1"
        borderRadius="12px 12px 0 12px"
        boxShadow="none !important"
        onKeyDown={onKeyDown}
      />
    </UpdateMessageContainer>
  )
}
export default UpdateMessage
