// @ts-nocheck
import autodux from "autodux"

const brokerStocksDuck = autodux({
  slice: "brokerStocks",
  initial: {
    fetching: false,
    error: false,
    brokerStocks: [],
  },
  actions: {
    fetchBrokerStocks: state => ({
      ...state,
      fetching: true,
    }),
    fetchBrokerStocksOk: (state, brokerStocks) => ({
      ...state,
      fetching: false,
      brokerStocks,
    }),
    fetchBrokerStocksError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),
  },
})

export default brokerStocksDuck
