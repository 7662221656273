// @ts-nocheck
import React from "react"
import { useSelector } from "react-redux"
import * as R from "ramda"

import { Li } from "./DisplayNode.styled"

const Param = ({ param }) => {
  const { type, title, value } = param

  /**
   * Need sources to get the verbose_name for stock, RF or DS/DSF,
   * in order to display a nice name into node's box.
   */
  const sources = useSelector(R.pathOr(<em>none selected</em>, ["search", "omniByIds", "results"]))
  let formattedValue = value

  if (["DsSelect", "SourceSelect", "ValuationSourceSelect"].includes(type)) {
    const verboseName = value ? sources[value.replace(/[['\]]/g, "")].verbose_name : "noneSelected"
    formattedValue = verboseName
  }
  if (type === "FieldSelect") {
    formattedValue =
      value !== "" ? (
        R.compose(R.join("/ "), JSON.parse, R.replace(/'/g, '"'))(value)
      ) : (
        <em>none selected</em>
      )
  }
  if (type === "BOOL") {
    formattedValue = value ? "On" : "Off"
  }
  return (
    <Li>
      <b>{title}</b>: {formattedValue}
    </Li>
  )
}

export default Param
