// @ts-nocheck
import React from "react"

import Header from "../Header"
import PastEntries from "./PastEntries"
import List from "./List"

const Forecasts = ({ entries, dataSet }) => (
  <>
    <Header dataSet={dataSet} />
    <PastEntries dataSet={dataSet} entries={entries} />
    <List pastEntries={entries} dataSet={dataSet} />
  </>
)

export default Forecasts
