import styled from "@emotion/styled"
import { FlexContainer, FlexContainerAndItem } from "../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"

export const IdeaPerformanceMetricsContainer = styled(FlexContainer)({
  width: "100%",
})

type IdeaPerformanceMetricsProps = {
  isGlobalAllTime: boolean
  isPositive?: boolean
}

type MetricContainerProps = {
  isPerfPercent?: boolean
}

export const MetricLabel = styled.p<IdeaPerformanceMetricsProps>(props => ({
  margin: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: props.isGlobalAllTime ? "16px" : "14px",
  fontWeight: props.isGlobalAllTime ? "bold" : "",
}))

export const MetricContainer = styled(FlexContainerAndItem)<MetricContainerProps>(props => ({
  width: "33%",
  paddingRight: props.isPerfPercent ? "50px" : "",
}))

export const TradeIconContainer = styled(FlexContainer)({
  marginRight: "80px",
})

export const TradeNumber = styled.p<IdeaPerformanceMetricsProps>(props => ({
  fontSize: props.isGlobalAllTime ? "16px" : "14px",
  fontWeight: props.isGlobalAllTime ? "bold" : "",
  margin: 0,
}))

export const PerfPercent = styled.p<IdeaPerformanceMetricsProps>(props => ({
  fontWeight: props.isGlobalAllTime ? "bold" : "",
  color: props.isPositive ? SCIENT_COLORS.green6 : SCIENT_COLORS.vermilion4,
  margin: 0,
}))
