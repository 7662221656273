import { useMutation } from "@tanstack/react-query"
import { useApi } from "./useApi"
import { IOTP } from "../../api"
import { AxiosError } from "axios"

interface IUseHookOTPMutation {
  onSuccess?: (data: IOTP) => void
  onError?: (error: AxiosError) => void
}

export const useGenerateOTPMutation = ({ onSuccess, onError }: IUseHookOTPMutation) => {
  const { scientApi } = useApi()

  return useMutation(scientApi.otp.generate, { onSuccess, onError })
}

export const useValidateOTPMutation = ({ onSuccess, onError }: IUseHookOTPMutation) => {
  const { scientApi } = useApi()

  return useMutation(scientApi.otp.validate, { onSuccess, onError })
}

export const useVerifyOTPMutation = ({ onSuccess, onError }: IUseHookOTPMutation) => {
  const { scientApi } = useApi()

  return useMutation(scientApi.otp.verify, { onSuccess, onError })
}
