import { AxiosInstance } from "axios"

export type Sector = {
  id: number
  name: string
  level: number
  related_dataset_unit: string
}

export interface IWorldApi {
  getSectors: () => Promise<Sector[]>
}

/**
 * Api routes for /api/v1/world/
 */
const world = (axiosClient: AxiosInstance): IWorldApi => ({
  /**
   * GET /api/v1/world/sectors/
   */
  getSectors: async () => {
    const { data } = await axiosClient.get<Sector[]>("world/sectors/")

    return data
  },
})

export default world
