// @ts-nocheck
import React from "react"
import { Button, Card, Collapse } from "@blueprintjs/core"
import { Field } from "formik"
import { DndProvider } from "react-dnd"
import Backend from "react-dnd-html5-backend"

import { FormikTextInput, FormikSwitch, Decimals } from "../FormikFields"
import FormikStyling from "../FormikStyling"
import { FormulaBuilder } from "./FormulaBuilder"

export const Formula = ({ values, setValues, card, showOptions, toogleShowOptions, index }) => {
  const formulaItems = card.formulaItems ? card.formulaItems : []
  const title = values.colDefs.cols[index].frontOpts.title

  return (
    <Card>
      <Field
        name={`colDefs.cols[${index}].frontOpts.title`}
        component={FormikTextInput}
        labelProps={{ label: "Title" }}
        inputProps={{ placeholder: "No name" }}
        helperText="Enter name or leave empty."
        title={title}
      />

      <p>Formula Builder</p>
      <DndProvider backend={Backend}>
        <FormulaBuilder
          formulaItems={formulaItems}
          values={values}
          setValues={setValues}
          card={card}
        />
      </DndProvider>

      <Field
        name={`colDefs.cols[${index}].agg`}
        labelProps={{ label: "Allow aggregation rows to use this col" }}
        component={FormikSwitch}
      />

      <Button
        fill
        alignText="left"
        minimal
        icon={showOptions ? "caret-down" : "caret-right"}
        onClick={toogleShowOptions}
      >
        {showOptions ? "Hide" : ""} Options
      </Button>
      <Collapse isOpen={showOptions}>
        <Card>
          <Decimals index={index} />
          <FormikStyling index={index} />
        </Card>
      </Collapse>
    </Card>
  )
}
