// @ts-nocheck
export const companyList = [
  { key: "bam", text: "BAM" },
  { key: "bbg", text: "BBG" },
  { key: "bca", text: "BCA" },
  { key: "ber", text: "BER" },
  { key: "car", text: "CAR" },
  { key: "con", text: "CON" },
  { key: "cti", text: "CTI" },
  { key: "dbg", text: "DBG" },
  { key: "dbk", text: "DBK" },
  { key: "dnb", text: "DNB" },
  { key: "exa", text: "EXA" },
  { key: "fbc", text: "FBC" },
  { key: "gsr", text: "GSR" },
  { key: "gui", text: "GUI" },
  { key: "hpr", text: "HPR" },
  { key: "hsb", text: "HSB" },
  { key: "isi", text: "ISI" },
  { key: "jef", text: "JEF" },
  { key: "jpm", text: "JPM" },
  { key: "kep", text: "KEP" },
  { key: "mfg", text: "MFG" },
  { key: "msr", text: "MSR" },
  { key: "red", text: "RED" },
  { key: "sc", text: "SC" },
  { key: "scb", text: "SCB" },
  { key: "sge", text: "SGE" },
  { key: "svc", text: "SVC" },
  { key: "ubs", text: "UBS" },
]

// export const ownerList = ["default"]

// export const textList = ["base"]
