// @ts-nocheck
import React from "react"
import { Dialog } from "@blueprintjs/core"

import ColContent from "./ColContent"

export const EditColumn = ({ editColItems, setEditColItems, sheet }) => {
  const { show } = editColItems
  const handleCancel = () => {
    setEditColItems({ show: false })
  }
  return (
    <Dialog
      className="bp5-dark"
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      icon="plus"
      title={`Edit ${sheet.name} column items`}
      intent="primary"
      isOpen={show}
      onClose={handleCancel}
      style={{ height: "100%", width: "700px" }}
      enforceFocus={false}
    >
      <ColContent sheet={sheet} editColItems={editColItems} setEditColItems={setEditColItems} />
    </Dialog>
  )
}
