// @ts-nocheck
import React from "react"

import { Grid } from "../../../../../components"
import { slugToName } from "../../../utils"
import { TitleRow, InputRow, ControlRow, ReadonlyRow } from "./components"
import { getDeepNestedValue } from "../../../../../../utils"

const Profit = ({
  handleChange,
  reportData: { prevReport, prevYearReport, stockParams },
  values,
}) => {
  const { ppa_in_segments, exceptionals_in_segments, restructuring_in_segments } = stockParams

  return (
    <Grid fluid>
      <TitleRow title="Group" />
      <InputRow
        key={"Profit Group"}
        title={stockParams.default_profit_metric ? stockParams.default_profit_metric : "Profit"}
        basePath={["profit"]}
        commentPath={["profit_comment"]}
        values={values}
        prevReport={prevReport}
        prevYearReport={prevYearReport}
        handleChange={handleChange}
        sideUpdates={[
          {
            target: ["profit_growth_readonly"],
            source: ["profit"],
            method: "growthYoY",
          },
          {
            target: ["profit_margin_readonly"],
            nume: [["profit"]],
            denom: [["sales"]],
            method: "divis",
            override: ["profit_margin_override"],
          },
          {
            target: ["is_ebit_from_customsegments_readonly"],
            add: [
              ["profit"],
              !ppa_in_segments && ["is_ppa_amortization"],
              !restructuring_in_segments && ["is_restructuring_costs"],
              ["is_walkdown_customsegment_to_reported_ebit"],
              !exceptionals_in_segments && ["is_exceptionals"],
            ],
            method: "sum",
          },
        ]}
      />
      <ReadonlyRow
        key={"Profit growth"}
        title="Profit growth"
        basePath={["profit_growth_readonly"]}
        commentPath={["profit_growth_comment"]}
        values={values}
        prevReport={prevReport}
        prevYearReport={prevYearReport}
        handleChange={handleChange}
      />
      <ReadonlyRow
        key={"Profit margin compute"}
        title="Profit margin compute"
        basePath={["profit_margin_readonly"]}
        values={values}
        prevReport={prevReport}
        prevYearReport={prevYearReport}
        handleChange={handleChange}
      />
      <InputRow
        key={"Profit margin"}
        title="Profit margin"
        basePath={["profit_margin_override"]}
        commentPath={["profit_margin_override_comment"]}
        values={values}
        prevReport={prevReport}
        prevYearReport={prevYearReport}
        handleChange={handleChange}
        placeholder={values.profit_margin_readonly}
        sideUpdates={[
          {
            target: ["profit_margin_readonly"],
            nume: [["profit"]],
            denom: [["sales"]],
            method: "divis",
            override: ["profit_margin_override"],
          },
        ]}
      />

      {values.splits !== undefined &&
        values.splits.map(split => {
          const splitSlug = split.slug
          const expected = values["profit"]
          const sumList = split.segs.map(rseg => rseg["profit"])
          return (
            <div key={splitSlug}>
              <TitleRow title={slugToName(splitSlug)} />
              <ControlRow title={`Sum check ${splitSlug}`} expected={expected} sumList={sumList} />
              {split.segs.map(rseg => {
                const segSlug = rseg.slug
                const pathBase = ["splits", `slug:${splitSlug}`, "segs", `slug:${segSlug}`]
                return (
                  <>
                    <TitleRow title={`${slugToName(segSlug)}`} level={3} />
                    <InputRow
                      key={segSlug}
                      title="Profit"
                      basePath={[...pathBase, "profit"]}
                      commentPath={[...pathBase, "profit_comment"]}
                      values={values}
                      prevReport={prevReport}
                      prevYearReport={prevYearReport}
                      handleChange={handleChange}
                      sideUpdates={[
                        {
                          target: [...pathBase, "profit_growth_readonly"],
                          source: [...pathBase, "profit"],
                          method: "growthYoY",
                        },
                        {
                          target: [...pathBase, "profit_margin_readonly"],
                          nume: [[...pathBase, "profit"]],
                          denom: [[...pathBase, "sales"]],
                          method: "divis",
                          override: [...pathBase, "profit_margin_override"],
                        },
                      ]}
                    />
                    <ReadonlyRow
                      key={`${segSlug}_growth`}
                      title="Profit growth"
                      basePath={[...pathBase, "profit_growth_readonly"]}
                      values={values}
                      prevReport={prevReport}
                      prevYearReport={prevYearReport}
                      handleChange={handleChange}
                    />
                    <ReadonlyRow
                      key={`${segSlug}profit_margin_compute`}
                      title="Profit margin compute"
                      basePath={[...pathBase, "profit_margin_readonly"]}
                      values={values}
                      prevReport={prevReport}
                      prevYearReport={prevYearReport}
                      handleChange={handleChange}
                    />
                    <InputRow
                      key={`${segSlug}profit_margin_override`}
                      title="Profit margin"
                      basePath={[...pathBase, "profit_margin_override"]}
                      commentPath={[...pathBase, "profit_margin_comment"]}
                      values={values}
                      prevReport={prevReport}
                      prevYearReport={prevYearReport}
                      handleChange={handleChange}
                      placeholder={getDeepNestedValue(
                        [...pathBase, "profit_margin_readonly"],
                        values,
                      )}
                      sideUpdates={[
                        {
                          target: [...pathBase, "profit_margin_readonly"],
                          nume: [[...pathBase, "profit"]],
                          denom: [[...pathBase, "sales"]],
                          method: "divis",
                          override: [...pathBase, "profit_margin_override"],
                        },
                      ]}
                    />
                    {/* <ReadonlyRow
                      key={`${segSlug}_group_profit_ratio`}
                      title="Group profit ratio"
                      basePath={[...pathBase, "group_profit_ratio"]}
                      commentPath={[...pathBase, "group_profit_ratio_comment"]}
                      values={values}
                      prevReport={prevReport}
                      prevYearReport={prevYearReport}
                      handleChange={handleChange}
                    /> */}
                  </>
                )
              })}
            </div>
          )
        })}
    </Grid>
  )
}

export default Profit
