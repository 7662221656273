// @ts-nocheck
import { Card, Icon, Tooltip } from "@blueprintjs/core"

import { onDragStart } from "./helpers"

const NodeItem = ({ node }) => {
  return (
    <Card
      key={node.type}
      className="node-sidebar-node"
      style={node.style}
      onDragStart={event => onDragStart(event, node.type)}
      draggable
    >
      {node.title}
      <Tooltip content={node.description} placement="bottom" compact>
        <Icon icon="info-sign" style={{ marginLeft: "8px" }} />
      </Tooltip>
    </Card>
  )
}

export default NodeItem
