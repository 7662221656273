// @ts-nocheck
import * as R from "ramda"
import React from "react"
import { H3, HTMLTable } from "@blueprintjs/core"

import Row from "./Row"

const SharedAnalyses = ({ analyses }) => (
  <>
    <H3>Shared with me</H3>
    {!R.isEmpty(analyses) ? (
      <HTMLTable striped style={{ width: "100%" }}>
        <tbody>
          {analyses.map((a, i) => (
            <Row key={a.id} index={i} analyse={a} />
          ))}
        </tbody>
      </HTMLTable>
    ) : (
      <p>
        <em>No shared analyse found.</em>
      </p>
    )}
  </>
)

export default SharedAnalyses
