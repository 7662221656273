// @ts-nocheck
import React from "react"
import { useSelector } from "react-redux"
import { Button, MenuItem } from "@blueprintjs/core"
import { Select } from "@blueprintjs/select"
import { find, pathOr, propEq } from "ramda"

import profileDuck from "../../../../../../state/modules/profile"

const SelectYearAmount = ({ stockSlug, dispatch }) => {
  const options = [
    { label: "Display next year", value: 1 },
    { label: "Display next 2 years", value: 2 },
    { label: "Display next 3 years", value: 3 },
    { label: "Display next 4 years", value: 4 },
  ]

  const itemRenderer = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={item.value}
        text={item.label}
        onClick={handleClick}
      />
    )
  }

  const value = useSelector(pathOr(1, ["profile", "userstockconfigs", stockSlug, "yearAmount"]))

  const selectedField = find(propEq("value", value), options)
  const buttonText = selectedField.label

  const handleChange = item =>
    dispatch(profileDuck.actions.stockSetYearAmount({ slug: stockSlug, yearAmount: item.value }))

  return (
    <Select
      className="sct-fullwidth"
      filterable={false}
      items={options}
      itemRenderer={itemRenderer}
      onItemSelect={handleChange}
      popoverProps={{ position: "bottom" }}
    >
      <Button fill rightIcon="caret-down" text={buttonText} />
    </Select>
  )
}

export default SelectYearAmount
