// @ts-nocheck
import * as R from "ramda"

import { formatPathIntoObject } from "./"
import { useSelector } from "react-redux"

export const useEnhancedPath = (path, st_sources, rf_sources) => {
  let srcName = ""
  let srcTicker = ""

  const formatedPath = formatPathIntoObject(path)
  const sourceId = formatedPath.sourceId // null if no source in path

  const primarySource = !R.isEmpty(rf_sources) ? rf_sources[0] : st_sources[0]
  const absoluteSource = useSelector(R.pathOr({}, ["search", "omniByIds", "results", sourceId]))

  if (!R.isEmpty(primarySource) && sourceId === "stsrc0") {
    srcName = primarySource.name
    srcTicker = primarySource.ticker
  } else {
    srcName = absoluteSource.verbose_name
    srcTicker = absoluteSource.bloomberg_code
  }

  return { ...formatedPath, srcName, srcTicker, path, primarySource }
}
