// @ts-nocheck
import {
  sales_fields,
  orders_fields,
  backlog_fields,
  profit_fields,
  is_fields,
  cf_fields,
  bs_fields,
} from "./fromBack/reports"

const createSharedFields = (name, fields) => [
  { type: "title1", text: `Main ${name}` },
  {
    type: "forEach",
    path: ["splits", "slug:main", "segs"],
    filter: { path: ["active", "upper"], value: null },
    rowDefs: [
      { type: "title2", text: `[#{name}] ${name}` },
      ...fields.map(row => ({
        ...row,
        type: "data",
        path: ["slug:#{slug}", row.name],
        text: `[#{name}] ${row.name}`,
      })),
    ],
  },
  { type: "title1", text: `Secondary ${name}` },
  {
    type: "forEach",
    path: ["splits", "slug:secondary", "segs"],
    filter: { path: ["active", "upper"], value: null },
    rowDefs: [
      { type: "title2", text: `[#{name}] ${name}` },
      ...fields.map(row => ({
        ...row,
        type: "data",
        path: ["slug:#{slug}", ...row.name],
        text: `[#{name}] ${row.name}`,
      })),
    ],
  },
]

export const allEntryFields = [
  { type: "title1", text: "Group Sales" },
  ...sales_fields.map(row => ({
    ...row,
    type: "data",
    text: `[Group] ${row.name}`,
    path: [row.name],
  })),
  ...createSharedFields("sales", sales_fields),

  { type: "title1", text: "Orders" },
  ...orders_fields.map(row => ({
    ...row,
    type: "data",
    text: `[Group] ${row.name}`,
    path: [row.name],
  })),
  ...createSharedFields("orders", orders_fields),

  { type: "title1", text: "Profit" },
  ...profit_fields.map(row => ({
    ...row,
    type: "data",
    text: `Group ${row.name}`,
    path: [row.name],
  })),
  ...createSharedFields("profit", profit_fields),

  { type: "title1", text: "Backlog" },
  ...backlog_fields.map(row => ({
    ...row,
    type: "data",
    text: `Group ${row.name}`,
    path: [row.name],
  })),
  ...createSharedFields("backlog", backlog_fields),

  { type: "title1", text: "Fields" },
  {
    type: "forEach",
    path: ["fields"],
    filter: { path: ["active", "upper"], value: null },
    rowDefs: [{ type: "data", text: `[FLD] #{name}`, path: ["slug:#{slug}", "value"] }],
  },

  { type: "title1", text: "IncomeStatement" },
  ...is_fields.map(row => ({
    ...row,
    type: "data",
    text: `[Group] ${row.name}`,
    path: [row.name],
  })),

  { type: "title1", text: "Balancesheet" },
  ...bs_fields.map(row => ({
    ...row,
    type: "data",
    text: `[Group] ${row.name}`,
    path: [row.name],
  })),

  { type: "title1", text: "Cashflow" },
  ...cf_fields.map(row => ({
    ...row,
    type: "data",
    text: `[Group] ${row.name}`,
    path: [row.name],
  })),
]
