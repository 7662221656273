// @ts-nocheck
import autodux from "autodux"
import { append, assoc, compose, dissocPath, isEmpty, lensPath, over } from "ramda"

export const initialFilters = {
  search: "",
  sectors: [],
  geos: [],
  eco_only: true,
  hide_daily: true,
  hide_bbg: false,
  ordering: {
    1: "-last_release",
    2: "name",
    3: "-last_value",
  },
}

const dataSetSearchDuck = autodux({
  slice: "dataSetSearch",
  initial: {
    ...initialFilters,
    searching: false,
    response: {},
  },
  actions: {
    searchDataSet: (state, params) => {
      if (isEmpty(params) || params.ordering === "") {
        return {
          ...state,
          searching: true,
          ...params,
          ordering: {
            1: "-last_release",
            2: "name",
            3: "-last_value",
          },
        }
      }
      return {
        ...state,
        searching: true,
        ...params,
        ordering: {
          ...state.ordering,
          ...params.ordering,
        },
      }
    },
    searchDataSetOk: (state, response) => ({
      ...state,
      searching: false,
      response,
    }),
    searchDataSetError: (state, error) => ({
      ...state,
      searching: false,
      error,
    }),
    clearDataSetSearch: () => ({ ...dataSetSearchDuck.initial }),
    createChangeDS: (state, { sourceIndex }) => {
      const sourceLens = lensPath(["response", "results", sourceIndex])
      return over(sourceLens, assoc("loading", true), state)
    },
    createChangeDSOk: (state, { sourceIndex, chg_output }) => {
      const chg_outputsLens = lensPath(["response", "results", sourceIndex, "chg_outputs"])
      return compose(
        dissocPath(["response", "results", sourceIndex, "loading"]),
        over(chg_outputsLens, append(chg_output)),
      )(state)
    },
    createMavDSOk: (state, { sourceIndex, mav_output }) => {
      const mav_outputsLens = lensPath(["response", "results", sourceIndex, "mav_outputs"])
      return compose(
        dissocPath(["response", "results", sourceIndex, "loading"]),
        over(mav_outputsLens, append(mav_output)),
      )(state)
    },
  },
})

export default dataSetSearchDuck
