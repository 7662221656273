// @ts-nocheck
import { of } from "rxjs"
import { switchMap, mergeMap, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"
import { filter } from "ramda"

import monitorsDuck from "../."
import desksDuck from "../../desks/index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { deleteMonitor, deleteMonitorOk, deleteMonitorError },
} = monitorsDuck

const deleteMonitorEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(deleteMonitor.type),
    switchMap(action => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/desks/monitors/${action.payload.id}`
      return ajax({
        url,
        method: "DELETE",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        mergeMap(() => {
          const { id, desk } = action.payload
          return [
            deleteMonitorOk(id),
            desksDuck.actions.updateDesk({
              ...desk,
              layout: filter(e => parseInt(e.i) !== id, desk.layout),
            }),
          ]
        }),
        catchError(error => of(deleteMonitorError(error.xhr.response))),
      )
    }),
  )

export default deleteMonitorEpic
