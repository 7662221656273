// @ts-nocheck
import React from "react"
import { useDispatch } from "react-redux"

import profileDuck from "../../../../../../state/modules/profile"
import AddEditDialog from "./AddEditDialog"
import { defaultInitial } from "."
export const AddDialog = ({ stockSlug, tabId, addModal, setAddModal }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    setAddModal(false)
  }
  const handleSubmit = row => {
    dispatch(
      profileDuck.actions.addLine({
        slug: stockSlug,
        tabId,
        index: addModal.rowIndex,
        row,
      }),
    )
    setAddModal(false)
  }

  return (
    <AddEditDialog
      kind="Add"
      stockSlug={stockSlug}
      possibleData={addModal}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initial={defaultInitial}
    />
  )
}
