// @ts-nocheck
import autodux from "autodux"

const sectorsDuck = autodux({
  slice: "sectors",
  initial: {
    fetching: false,
    error: false,
    sectors: [],
    sector: "",
  },
  actions: {
    fetchSectors: state => ({
      ...state,
      fetching: true,
    }),
    fetchSectorsOk: (state, sectors) => ({
      ...state,
      fetching: false,
      sectors,
    }),
    fetchSectorsError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),
    selectSector: (state, sector) => ({
      ...state,
      sector,
    }),
  },
})

export default sectorsDuck
