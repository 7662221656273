import { Icon, InputGroup } from "@blueprintjs/core"
import { Field, useField, useFormikContext } from "formik"
import { useEffect } from "react"

interface IFormikInputProps {
  fieldName: string
}

const FormikInput = ({ fieldName }: IFormikInputProps) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(fieldName)

  /**
   * Remove the value when we don't render input.
   * Input must be empty string and not undefined to avoid switch from uncontrolled to controlled
   */
  useEffect(() => {
    return () => {
      setFieldValue(fieldName, "")
    }
  }, [fieldName, setFieldValue])

  return (
    <Field>
      {() => (
        <InputGroup
          type="text"
          large
          leftElement={<Icon icon="person" />}
          onChange={event => setFieldValue(fieldName, event.target.value)}
          placeholder="Contact"
          value={field.value ?? ""}
        />
      )}
    </Field>
  )
}

export default FormikInput
