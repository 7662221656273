// @ts-nocheck
import * as R from "ramda"

const searchOmniByIdsReducers = {
  searchOmniByIds: R.assocPath(["omniByIds", "searching"], true),
  searchOmniByIdsOk: (state, { payload: { results } }) =>
    R.over(
      R.lensProp("omniByIds"),
      R.compose(
        R.assoc("searching", false),
        R.assoc("results", R.indexBy(R.prop("kind_id"), results)),
      ),
      state,
    ),
  searchOmniByIdsError: (state, { payload: { error } }) =>
    R.set(R.lensProp("omniByIds"), { searching: false, error: error, results: {} }, state),
  clearOmniByIds: state => R.set(R.lensProp("omniByIds"), { searching: false, results: {} }, state),
  addSingleItem: (state, { payload: { item } }) =>
    R.assocPath(["omniByIds", "results", item.kind_id], item, state),
}

export default searchOmniByIdsReducers
