// @ts-nocheck
import React from "react"
import { Callout as BP5Callout, H4 } from "@blueprintjs/core"

export const Callout = ({ title, body, ...props }) => (
  <BP5Callout {...props}>
    <H4>{title}</H4>
    {body}
  </BP5Callout>
)

export default Callout
