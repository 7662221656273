// @ts-nocheck
import { find, propEq } from "ramda"

import { getColTitle } from "./getTitle"

// To get the formula title from the colId
const getExitingFormulaName = ({ formulaId, cols, fieldList, factorList }) => {
  const source = find(propEq("colId", formulaId))(cols)
  const formulaName = getColTitle(source, fieldList, factorList)
  return formulaName
}

// To get the col name from the colId
const getExitingColName = ({ colId, cols, fieldList, factorList }) => {
  const colDef = find(propEq("colId", colId))(cols)
  const colName = getColTitle(colDef, fieldList, factorList)
  return colName
}

export const getNiceFormulaItem = ({ item, cols, fieldList, factorList }) => {
  const regexCol = /#(\w+)/gi //matched the sourceId
  const regexFormula = /@(\w+)/gi //matched the rowId
  if (regexFormula.test(item)) {
    const newFormula = item.replace(regexFormula, match => {
      const formulaId = match.substring(1, 22) //will removed the '@'
      return getExitingFormulaName({ formulaId, cols, fieldList, factorList })
    })
    return newFormula
  }

  if (regexCol.test(item)) {
    const newFormula = item.replace(regexCol, match => {
      const colId = match.substring(1, 22) //will removed the '#'
      return getExitingColName({ colId, cols, fieldList, factorList })
    })
    return newFormula
  }
  return item
}
