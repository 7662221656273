import styled from "@emotion/styled"
import { FlexContainer, FlexContainerProps } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

type SummaryContainerProps = {
  switchLabelColor: string
  numberOfRows: number | undefined
} & FlexContainerProps

export const SummaryContainer = styled(FlexContainer)<SummaryContainerProps>(props => ({
  border: `1px solid ${SCIENT_COLORS.gray1}`,
  background: SCIENT_COLORS.darkGray4,
  // The size of the summary container is set to 80px (size of container header and column header)
  // plus 25px for each row of data
  height: props.numberOfRows ? `${80 + props.numberOfRows * 25}px` : "25%",
  maxHeight: "25%",
  ".bp5-control": {
    margin: 0,
  },
  ".bp5-switch": {
    fontWeight: "bold",
    color: props.switchLabelColor,
  },
}))
