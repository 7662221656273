import { Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { capitalizeFirstLetter } from "../../../../../utils"
import { useGlobalState } from "../../../../hooks/useGlobalState"
import { ConversationMessage } from "../../types/business"
import { getDateDistance } from "../../utils"
import {
  BodyContainer,
  ConnectedUserBodyContainer,
  DateDistance,
  MessageBody,
  MessageBubbleContainer,
  UpdateMessageIconContainer,
  UserInfo,
} from "./MessageBubble.styled"
import UpdateMessage from "./UpdateMessage"
import { useAuth } from "../../../../hooks"

interface IMessageBubble {
  message: ConversationMessage
}

const MessageBubble = ({ message }: IMessageBubble) => {
  const { profile } = useAuth()
  const { user, created_at } = message
  const messageUserId = user.id

  const userId = profile?.id
  /**
   *  helper to get time ago from date
   */
  const dateDistance = getDateDistance(created_at)

  const [isUpdating, setIsUpdating] = useState(false)
  const [isMessageHovered, setIsMessageHovered] = useState(false)
  /**
   * Cleanup logic to make sure that we are neither updating
   * nor hovering when rendering.
   */
  useEffect(() => {
    return () => {
      setIsUpdating(false)
      setIsMessageHovered(false)
    }
  }, [])

  return (
    <MessageBubbleContainer
      flexDirection="column"
      onMouseEnter={() => setIsMessageHovered(true)}
      onMouseLeave={() => setIsMessageHovered(false)}
    >
      <FlexContainer justifyContent="space-between" flexDirection="row-reverse">
        <DateDistance alignItems="center" justifyContent="center">
          {dateDistance}
        </DateDistance>
        {userId !== messageUserId && <UserInfo>{capitalizeFirstLetter(user.username)}</UserInfo>}
      </FlexContainer>

      {userId === messageUserId ? (
        <FlexContainer flexDirection="row-reverse">
          {isUpdating ? (
            <UpdateMessage
              message={message}
              onExit={() => {
                setIsUpdating(false)
                setIsMessageHovered(false)
              }}
            />
          ) : (
            <ConnectedUserBodyContainer justifyContent="space-between" flexDirection="row-reverse">
              {isMessageHovered && (
                <UpdateMessageIconContainer
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setIsUpdating(true)}
                >
                  <Icon size={16} icon="edit" color={SCIENT_COLORS.gray2} />
                </UpdateMessageIconContainer>
              )}
              <MessageBody>{message.content}</MessageBody>
            </ConnectedUserBodyContainer>
          )}
        </FlexContainer>
      ) : (
        <FlexContainer flexDirection="row">
          <BodyContainer justifyContent="space-between" flexDirection="row">
            <MessageBody>{message.content}</MessageBody>
          </BodyContainer>
        </FlexContainer>
      )}
    </MessageBubbleContainer>
  )
}

export default MessageBubble
