// @ts-nocheck
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Card, H3, Button, Collapse, Switch } from "@blueprintjs/core"

import dsForecastsDuck from "../../../../../../state/modules/dsForecasts"
import { Row, Col } from "../../../../../components"

import Manual from "./Manual"
import Aggregated from "./Aggregated"
import Manage from "./Manage"

const Item = props => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const toggleShow = () => setShow(!show)

  const { dataSet, index, dsforecast, profile } = props
  const { id, name, owner, aggregated } = dsforecast

  if (!profile) {
    return <p>Loading</p>
  }
  const { username, is_superuser, groups } = profile

  const fetchForecast = params => dispatch(dsForecastsDuck.actions.fetchForecast(params))
  const deleteForecast = params => dispatch(dsForecastsDuck.actions.deleteForecast(params))
  const updateForecast = params => dispatch(dsForecastsDuck.actions.updateForecast(params))
  const isOwnerOrAdmin = is_superuser || dsforecast.owner === username

  const handleAggregated = () =>
    updateForecast({
      index,
      form: { dataset: dataSet.id, id, aggregated: !aggregated },
    })

  return (
    <Row data-cy="DSForecast">
      <Col xs={12}>
        <Card>
          <H3 style={{ textAlign: "center" }}>
            {name} <small>{owner}</small>{" "}
            <Button
              small
              data-cy="DSFShow"
              icon={show ? "caret-up" : "caret-down"}
              intent="primary"
              onClick={toggleShow}
            />
          </H3>
          <Collapse isOpen={show}>
            <div style={{ textAlign: "center" }}>
              <Switch
                data-cy="DSFSwitch"
                large
                innerLabelChecked="aggregated"
                innerLabel="manual"
                checked={dsforecast.aggregated}
                onChange={handleAggregated}
              />
            </div>
            {dsforecast.aggregated ? (
              <Aggregated dsforecast={dsforecast} dataSet={dataSet} index={index} />
            ) : (
              <Manual {...props} />
            )}
            <Manage
              dataSet={dataSet}
              index={index}
              id={id}
              isOwnerOrAdmin={isOwnerOrAdmin}
              fetchForecast={fetchForecast}
              updateForecast={updateForecast}
              deleteForecast={deleteForecast}
              dsforecast={dsforecast}
              groups={groups}
              name={name}
            />
          </Collapse>
        </Card>
      </Col>
    </Row>
  )
}

export default Item
