// @ts-nocheck
import React from "react"

import { Field } from "formik"
import { Switch } from "@blueprintjs/core"

/**
 * Value received by FormikSwitch must be booleans.
 */
export const FormikSwitch = ({ name, label, ...props }) => {
  return (
    <Field name={name}>
      {({ field: { name, value, onChange } }) => {
        /**
         * If label, we display it near the Switch, except if label="" where we don't want any label.
         * For exemple, label can be "Hide line". If the Switch is ON, "Hide line" applies. And contrary.
         * If no label we diplay "Off" is the Switch is OFF and "On" if the Switch is ON.
         */
        const _label = label === "" ? "" : label ? label : value ? "On" : "Off"

        const handleChange = params => {
          const {
            target: { checked },
          } = params
          onChange({ target: { name, checked, type: "checkbox" } })
        }

        return (
          <Switch name={name} label={_label} checked={value} onChange={handleChange} {...props} />
        )
      }}
    </Field>
  )
}
