// @ts-nocheck
import * as R from "ramda"
import React, { useState } from "react"
import { Button, ButtonGroup, Card, Classes, Dialog } from "@blueprintjs/core"
import { Col, Grid, Row } from "."
import { useGlobalState } from "../hooks/useGlobalState"
import { useAuth } from "../hooks"

const SharingDialog = ({ toggleDialog, instanceGroups, handleSubmit }) => {
  const { groups: allGroups } = useGlobalState()
  const { profile } = useAuth()

  const [groups, setGroups] = useState(instanceGroups)
  const userGroups = R.map(
    name => R.find(R.propEq("name", name))(R.values(allGroups)),
    profile.groups,
  )

  const indexAndStatus = myGroup => {
    const index = R.findIndex(R.propEq("group_id", myGroup.id))(groups)
    const readGroup = R.find(R.propEq("group_id", myGroup.id))(groups)
    if (readGroup) {
      if (readGroup.write) {
        return [index, "write"]
      }
      return [index, "read"]
    }
    return [index, "notShared"]
  }

  return (
    <Dialog
      title="Sharing"
      className="bp5-dark"
      isOpen={true}
      onClose={toggleDialog}
      style={{ width: "600px" }}
    >
      <div className={Classes.DIALOG_BODY}>
        <Grid>
          {userGroups.map(userGroup => {
            const [index, status] = indexAndStatus(userGroup)

            const unshare = () =>
              status !== "notShared" &&
              setGroups(R.reject(group => group.group_id === userGroup.id, groups))

            const setRead = () => {
              if (status !== "read") {
                if (index === -1) {
                  const newGroups = R.compose(
                    R.sort(R.ascend(R.prop("group_id"))),
                    R.append({ group_id: userGroup.id, write: false }),
                  )(groups)

                  setGroups(newGroups)
                } else {
                  const newGroups = R.assocPath([index, "write"], false, groups)
                  setGroups(newGroups)
                }
              }
            }

            const setWrite = () => {
              if (status !== "write") {
                if (index === -1) {
                  const newGroups = R.compose(
                    R.sort(R.ascend(R.prop("group_id"))),
                    R.append({ group_id: userGroup.id, write: true }),
                  )(groups)

                  setGroups(newGroups)
                } else {
                  const newGroups = R.assocPath([index, "write"], true, groups)
                  setGroups(newGroups)
                }
              }
            }

            return (
              <Card key={userGroup.id}>
                <Row>
                  <Col xs={6}>{userGroup.name}</Col>
                  <Col xs={6}>
                    <ButtonGroup fill>
                      <Button active={status === "notShared"} text="Not shared" onClick={unshare} />
                      <Button active={status === "read"} text="Read" onClick={setRead} />
                      <Button active={status === "write"} text="Write" onClick={setWrite} />
                    </ButtonGroup>
                  </Col>
                </Row>
              </Card>
            )
          })}
        </Grid>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={toggleDialog} text="Cancel" />
          <Button onClick={() => handleSubmit(groups)} intent="primary" text="Save permissions" />
        </div>
      </div>
    </Dialog>
  )
}

export const SharingButton = ({ instance, onSubmit }) => {
  const [showDialog, setOpen] = useState(false)
  const toggleDialog = () => setOpen(!showDialog)

  const handleSubmit = groups => {
    onSubmit(groups)
    toggleDialog()
  }

  return (
    <>
      <Button icon="follower" onClick={toggleDialog} />
      {showDialog && (
        <SharingDialog
          toggleDialog={toggleDialog}
          handleSubmit={handleSubmit}
          instanceGroups={instance.groups}
        />
      )}
    </>
  )
}
