import axios from "axios"
import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import api, { IScientApi } from "../api"
import { REACT_APP_API_DOMAIN } from "../config/constants"

/**
 * Sets axios Client globally
 */
const axiosClient = axios.create({
  baseURL: `${REACT_APP_API_DOMAIN}/api/v1/`,
  responseType: "json",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
})

/**
 * API context
 */
export interface IAPIContext {
  scientApi: IScientApi
  registerAuthorizationHeader?: (accessToken?: string) => void
  unregisterAuthorizationHeader?: () => void
  apiAuthenticated: boolean
  api2FAVerified: boolean
  setApi2FAVerified?: React.Dispatch<React.SetStateAction<boolean>>
  setApiAuthenticated?: React.Dispatch<React.SetStateAction<boolean>>
}

export const APIContext = createContext<IAPIContext>({
  scientApi: api(axiosClient),
  registerAuthorizationHeader: undefined,
  unregisterAuthorizationHeader: undefined,
  apiAuthenticated: false,
  api2FAVerified: false,
  setApi2FAVerified: undefined,
  setApiAuthenticated: undefined,
})

/**
 * Provides API to children
 * Provides ability to set Authorization Header
 */
const APIProvider = ({ children }: { children: ReactNode }) => {
  /**
   * State to track if Authorization Header is set
   * Provided in the context in order
   * for children to set Authorization Header
   * when authentified
   */
  const [authorizationHeader, setAuthorizationHeader] = useState<string | undefined>(undefined)

  /**
   * Tracks if api is authenticated
   */
  const [apiAuthenticated, setApiAuthenticated] = useState<boolean>(false)

  /**
   * Tracks if api is 2FA verified (with TOTP)
   * We set api2FAVerified also if the user is not 2FA enabled (meaning did not generate any 2FA device yet)
   * In case REACT_APP_ENFORCE_TWO_FACTOR_AUTH is set to true, the user will be forced to enable 2FA
   */
  const [api2FAVerified, setApi2FAVerified] = useState<boolean>(false)

  /**
   * Axios Instance
   */
  useEffect(() => {
    if (authorizationHeader) {
      axiosClient.defaults.headers.common["Authorization"] = authorizationHeader
      setApiAuthenticated(true)
    }
  }, [authorizationHeader])

  const registerAuthorizationHeader = useCallback(
    (accessToken: string) => {
      setAuthorizationHeader(`Bearer ${accessToken}`)
    },
    [setAuthorizationHeader],
  )

  const unregisterAuthorizationHeader = useCallback(() => {
    setAuthorizationHeader(undefined)
    setApiAuthenticated(false)
    setApi2FAVerified(false)
  }, [])

  /**
   * Memoized state for context change
   * and children prop propagation
   */
  const state = useMemo(
    () => ({
      scientApi: api(axiosClient),
      registerAuthorizationHeader,
      unregisterAuthorizationHeader,
      apiAuthenticated,
      api2FAVerified,
      setApi2FAVerified,
      setApiAuthenticated,
    }),
    [
      registerAuthorizationHeader,
      unregisterAuthorizationHeader,
      apiAuthenticated,
      setApiAuthenticated,
      api2FAVerified,
      setApi2FAVerified,
    ],
  )

  return <APIContext.Provider value={state}>{children}</APIContext.Provider>
}

export default APIProvider
