// @ts-nocheck
import {
  append,
  compose,
  dissocPath,
  equals,
  findIndex,
  head,
  lensPath,
  path,
  set,
  update,
  view,
  without,
} from "ramda"
import camelcase from "camelcase"

import { getAllTabs } from "../../../../views/pages/Stocks/utils"
import { activeTabLens, availableTabsLens, consensusFieldsLens } from "./utils"
import { createStockRows } from "../../../../utils"

export const stockSetActiveTab = (state, { slug, activeTab }) =>
  set(activeTabLens(slug), activeTab, state)

export const stockSetAvailableTabs = (state, { slug, availableTabs }) =>
  set(availableTabsLens(slug), availableTabs, state)

export const stockCreateTab = (
  state,
  { slug, title, randomId, sourceTab, replaceSourceTab, stock },
) => {
  const id = `${camelcase(title)}_${randomId}`
  let rowDefs = []
  let availableTabs = path(["userstockconfigs", slug, "availableTabs"], state)
  if (sourceTab) {
    const allTabs = getAllTabs(slug, state)
    rowDefs = allTabs[sourceTab].rowDefs
    const sourceTabIndex = findIndex(equals(sourceTab), availableTabs)
    if (replaceSourceTab && sourceTabIndex !== -1) {
      availableTabs = update(sourceTabIndex, id, availableTabs)
    } else {
      availableTabs = append(id, availableTabs)
    }
  } else {
    availableTabs = append(id, availableTabs)
  }
  return compose(
    set(availableTabsLens(slug), availableTabs),
    set(lensPath(["userstockconfigs", slug, "customTabs", id]), {
      id,
      title,
      rowDefs: createStockRows({
        rowDefs,
        entries: [],
        stock,
        options: { showEmpty: true },
      }),
    }),
  )(state)
}

export const stockDeleteTab = (state, { slug, tabId }) => {
  const nextAvailableTabs = without(tabId, view(availableTabsLens(slug), state))
  const prevActiveTab = view(activeTabLens(slug), state)
  const nextActiveTab = prevActiveTab === tabId ? head(nextAvailableTabs) : prevActiveTab
  const prevConsensusFields = view(consensusFieldsLens(slug), state)
  const nextConsensusFields = prevConsensusFields === tabId ? "sales" : prevConsensusFields

  return compose(
    set(activeTabLens(slug), nextActiveTab),
    set(availableTabsLens(slug), nextAvailableTabs),
    set(consensusFieldsLens(slug), nextConsensusFields),
    dissocPath(["userstockconfigs", slug, "customTabs", tabId]),
  )(state)
}

export const stockRenameTab = (state, { slug, tabId, name }) =>
  set(lensPath(["userstockconfigs", slug, "customTabs", tabId, "title"]), name, state)
