import { AxiosInstance } from "axios"
import { PasswordResetConfirmForm } from "../views/pages/PasswordReset/PasswordResetConfirm"

export interface IAuthApi {
  login: ({ username, password }: { username: string; password: string }) => Promise<{
    access_token: string
    refresh_token: string
  }>
  verify: ({ token }: { token: string }) => Promise<Record<string, never>>
  refresh: ({ refresh }: { refresh: string }) => Promise<{
    access_token: string
    refresh_token: string
  }>
  passwordReset: (email: string) => Promise<string>
  passwordResetConfirm: (values: PasswordResetConfirmForm) => Promise<Record<string, never>>
}

/**
 * Api routes for /api/v1/auth/
 */
const auth = (axiosClient: AxiosInstance): IAuthApi => ({
  /**
   * POST /api/v1/auth/token/
   */
  login: async ({ username, password }: { username: string; password: string }) => {
    const { data } = await axiosClient.post<{ access_token: string; refresh_token: string }>(
      "/auth/token/",
      { username, password },
    )
    return data
  },

  /**
   * POST /api/v1/auth/token/verify/
   */
  verify: async ({ token }: { token: string }) => {
    const { data } = await axiosClient.post<Record<string, never>>("/auth/token/verify/", { token })
    return data
  },

  /**
   * POST /api/v1/auth/token/refresh/
   */
  refresh: async ({ refresh }: { refresh: string }) => {
    const { data } = await axiosClient.post<{ access_token: string; refresh_token: string }>(
      "/auth/token/refresh/",
      { refresh },
    )
    return data
  },

  /**
   * POST /api/v1/auth/password/reset/
   */
  passwordReset: async (email: string) => {
    const { data } = await axiosClient.post<string>("/auth/password/reset/", { email: email })
    return data
  },

  /**
   * POST /api/v1/auth/password/reset/confirm/
   */
  passwordResetConfirm: async (values: PasswordResetConfirmForm) => {
    const { data } = await axiosClient.post<Record<string, never>>(
      "/auth/password/reset/confirm/",
      values,
    )
    return data
  },
})

export default auth
