// @ts-nocheck
import { FormGroup, H4, H5, H6, TextArea } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import dsForecastsDuck from "../../../../../../../state/modules/dsForecasts"
import { Button, Col, Grid, HelpTooltip, NumericGroup, Row } from "../../../../../../components"
import Callouts from "./Callouts"
import DSFLinkModal from "./DSFLinkModal"
import DSFLinkRow from "./DSFLinkRow"
import Results from "./Results"

const Explanation = () => (
  <div>
    <H5>Examples to write a formula</H5>
    <H6>{"(D1 + F2) / 2"}</H6>
    <ol>
      <li>
        <code>D1</code> and <code>F2</code> are related to the source rows you imported.
        <code>D1</code> is the DataSet with ref <code>1</code>
        <br />
        <code>F2</code> is the Forecast (+ related DataSet) with ref <code>2</code>
        <br />
        <strong>Must be in UPPERCASE</strong>.
      </li>
      <li>
        You can add any simple mathematical expressions like <code>()</code> or <code>/ 2</code>.
      </li>
    </ol>
    <H6>{"(D1 - D1{y, -3}) / D1"}</H6>
    <ol>
      <li>
        <code>D1</code> is the DataSet with ref <code>1</code>
        <br />
        <strong>Must be UPPERCASE</strong>.
      </li>
      <li>
        <code>{"{y, -3}"}</code> is a lag of 3 years.
        <br />
        Other choices are <code>d</code>, <code>w</code>, <code>m</code>, <code>q</code>,{" "}
        <code>y</code>.
        <br />
        <strong>Must be lowercase</strong>.
        <br />
      </li>
    </ol>
  </div>
)

const Aggregated = ({ dsforecast, dataSet, index }) => {
  const allForecasts = useSelector(({ dsForecasts }) => dsForecasts.all.results)
  const [formula, setFormula] = useState(dsforecast.formula)
  const [recursionPeriods, setRecursionPeriods] = useState(dsforecast.recursion_periods)
  const [dsflinks, setDSFLinks] = useState(dsforecast.dsflinks)

  useEffect(() => {
    setDSFLinks(dsforecast.dsflinks)
  }, [dsforecast.dsflinks])

  const [canSave, setCanSave] = useState(false)
  const [modal, setModal] = useState(null)

  const addDSFLink = () => setDSFLinks([...dsflinks, {}])

  const handleFormulaInput = ({ target: { value } }) => {
    setCanSave(true)
    setFormula(value)
  }
  const handleRecursionPeriods = value => {
    setCanSave(true)
    setRecursionPeriods(value)
  }

  const dispatch = useDispatch()
  const handleSave = () => {
    const form = {
      dataset: dataSet.id,
      id: dsforecast.id,
      formula,
      recursion_periods: recursionPeriods,
      process_agg_output: true,
    }

    dispatch(dsForecastsDuck.actions.updateForecast({ index, form }))
  }

  return (
    <>
      <Grid fluid>
        {dsforecast.agg_output && <Results dataSet={dataSet} dsforecast={dsforecast} />}
        <Callouts dsforecast={dsforecast} />
        <Row>
          <Col xs={12}>
            <FormGroup
              label="Formula"
              labelFor="text-input"
              helperText={<HelpTooltip content={<Explanation />} />}
            >
              <TextArea
                autoResize
                data-cy="DSFFormula"
                style={{ width: "100%" }}
                placeholder="Enter Formula"
                large={true}
                fill
                onChange={handleFormulaInput}
                value={formula || ""}
              />
            </FormGroup>
            <NumericGroup
              labelProps={{ label: "Recursion periods" }}
              inputProps={{
                value: recursionPeriods,
                onValueChange: handleRecursionPeriods,
                placeholder: "Recursion periods",
              }}
            />
          </Col>
          <Col xs={12}>
            <H4 style={{ fontWeight: "normal" }}>
              Sources <Button data-cy="DSFAddRow" icon="add" text="Add row" onClick={addDSFLink} />
            </H4>
            <Grid>
              <Row>
                <Col xs={1} />
                <Col xs={8}>
                  <h4>Name</h4>
                </Col>
                <Col xs={1}>
                  <h4>Ref</h4>
                </Col>
                <Col xs={1}>
                  <h4>Periodicity</h4>
                </Col>
                <Col xs={1}>
                  <h4>Forecast periods</h4>
                </Col>
              </Row>

              {dsflinks.map((dsflink, index) => (
                <DSFLinkRow
                  key={index}
                  index={index}
                  dsflink={dsflink}
                  allForecasts={allForecasts}
                  setModal={setModal}
                />
              ))}
            </Grid>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              large
              fill
              icon="floppy-disk"
              text="Save aggregated forecast"
              intent="primary"
              onClick={handleSave}
              disabled={!canSave}
            />
          </Col>
        </Row>
      </Grid>
      {modal && (
        <DSFLinkModal modal={modal} setModal={setModal} dsforecast={dsforecast} index={index} />
      )}
    </>
  )
}

export default Aggregated
