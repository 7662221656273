// @ts-nocheck
import { getTime } from "date-fns"
import * as R from "ramda"

export const formatDataForQuickChart = (colTitles, row, rowDefs, name) => {
  const pct = R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.pct
    ? R.find(R.pathEq(["rowId"], row.extra.rowId), rowDefs).frontOpts.pct
    : false
  return [
    {
      name,
      pct,

      data: R.compose(
        R.sortBy(R.prop(0)),
        R.values(),
        R.mapObjIndexed((obj, key) => [
          /**
           * From string, we generate Unix time in ms with getTime().
           * Unix time is used by HighCharts.
           *  */
          getTime(new Date(R.find(R.propEq("field", key), colTitles).end.valueOf())),
          pct ? Number(R.propOr(null, "value", obj)) * 100 : Number(R.propOr(null, "value", obj)),
        ]),
        R.omit(["extra"]),
      )(row),
      dashStyle: "solid",
      tooltip: {
        headerFormat: "{point.x:%d-%m-%Y}",
        pointFormat: pct ? "{series.name}: {point.y}%" : "{series.name}: {point.y}",
        valueDecimals: pct ? 2 : 0,
      },
    },
  ]
}
