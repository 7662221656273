import { Icon, Intent } from "@blueprintjs/core"
import { useEffect } from "react"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { SmallText } from "../../../styled/text.styled"

interface PasswordRequirementsProps {
  password: string
  setMetRequirements: (metRequirements: boolean) => void
}

interface Requirement {
  text: string
  isMet: boolean
}

const getPasswordRequirements = (password: string): Requirement[] => [
  { text: "Small letters", isMet: /[a-z]/.test(password) },
  { text: "Capital letters", isMet: /[A-Z]/.test(password) },
  { text: "Numbers", isMet: /[0-9]/.test(password) },
  { text: "Special character", isMet: /[!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/]/.test(password) },
  { text: "At least 9 characters", isMet: password.length >= 9 },
]

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  password,
  setMetRequirements,
}) => {
  const requirements = getPasswordRequirements(password)

  useEffect(() => {
    setMetRequirements(requirements.every(requirement => requirement.isMet))
  }, [password, setMetRequirements, requirements])

  return (
    <FlexContainer flexDirection="column" gap="10px">
      {requirements.map((requirement, index) => (
        <FlexContainer key={index} flexDirection="row" gap="5px">
          <Icon
            icon={requirement.isMet ? "tick-circle" : "cross"}
            intent={requirement.isMet ? Intent.SUCCESS : Intent.DANGER}
          />
          <SmallText color="#106BA3">{requirement.text}</SmallText>
        </FlexContainer>
      ))}
    </FlexContainer>
  )
}

export default PasswordRequirements
