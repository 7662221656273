import { H1 } from "@blueprintjs/core"
import styled from "@emotion/styled"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const NameContainer = styled(FlexContainer)({
  marginTop: "10px",
})

export const FieldsContainer = styled(FlexContainer)({
  height: "100%",
  marginTop: "50px",
  overflow: "hidden",
})

type StockNameProps = {
  isDirty: boolean
}

/**
 * Highlight in orange StockName if user has modified it.
 */
export const StockName = styled(H1, {
  shouldForwardProp: prop => prop !== "isDirty",
})<StockNameProps>(props => ({
  color: props.isDirty ? `${SCIENT_COLORS.orange5} !important` : "",
  margin: "0 0 0 55px",
}))
