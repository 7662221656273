// @ts-nocheck
import { of } from "rxjs"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import authDuck from "../index"
import { REACT_APP_API_DOMAIN } from "../../../../config/constants"

const {
  actions: { verifyToken, verifyTokenOk, verifyTokenError },
} = authDuck

const verifyTokenEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(verifyToken.type),
    mergeMap(action => {
      const url = `${REACT_APP_API_DOMAIN}/api/v1/token/verify/`
      return ajax({
        method: "POST",
        url,
        crossDomain: true,
        body: { token: action.payload },
      }).pipe(
        map(() => auth.decodeToken(action.payload)),
        map(user => {
          /**
           * In production, CloudFront consumes token via Cookie in order
           * to authenticate images on s3
           */
          if (process.env.NODE_ENV === "production") {
            const expireDate = new Date()

            /**
             * Expires in 100 days
             */
            const expireTime = expireDate.getTime() + 100 * 24 * 3600 * 1000
            expireDate.setTime(expireTime)
            document.cookie = `token=${
              action.payload
            };domain=scient.io;path=/;expires=${expireDate.toUTCString()}; Secure`
          }
          return verifyTokenOk(user)
        }),
        catchError(error => {
          /**
           * delete token only on 4xx that explicitely says that token is not valid
           */
          if (error?.status === 401) {
            auth.deleteToken()
            return of(verifyTokenError(error))
          }
        }),
      )
    }),
  )

export default verifyTokenEpic
