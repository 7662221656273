// @ts-nocheck
import { Divider, H4 } from "@blueprintjs/core"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { MediumText } from "../../../../styled/text.styled"

const Infos = ({ monitor, deskOwnerName }) => {
  return (
    <FlexContainer flexDirection="column" gap="5px">
      <H4>{monitor.name}</H4>
      <Divider />
      <MediumText>
        <strong>ID</strong> {monitor.id}
      </MediumText>
      <MediumText>
        <strong>Owner</strong> {deskOwnerName}
      </MediumText>
    </FlexContainer>
  )
}
export default Infos
