// @ts-nocheck
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { compose, dissoc, map, includes, path, prepend, prop, values } from "ramda"
import { Callout, Classes, Button, Dialog, H5 } from "@blueprintjs/core"
import { randomid } from "../../../../../../utils"

import profileDuck from "../../../../../../state/modules/profile"
import { Select, Switch, InputGroup } from "../../../../../components"
import { getAllTabs } from "../../../utils"

const NewTab = ({ stockSlug, dialog, setDialog }) => {
  const [sourceTab, setSourceTab] = useState("")
  const [replaceSourceTab, setReplaceSourceTab] = useState(true)
  const [title, setTitle] = useState("")
  const dispatch = useDispatch()
  const profile = useSelector(prop("profile"))

  const stock = useSelector(path(["stockDetail", stockSlug, "stockData"]))

  const allTabs = dissoc("consensus", getAllTabs(stockSlug, profile))

  const options = compose(
    prepend({ label: "Don't copy, create an empty tab", value: "" }),
    map(x => ({
      value: x.id,
      label: x.title,
    })),
    values(),
  )(allTabs)

  const sourceTabInAvailableTabs = compose(
    includes(sourceTab),
    path(["userstockconfigs", stockSlug, "availableTabs"]),
  )(profile)

  const handleSelectExisting = ({ target: { value } }) => {
    if (replaceSourceTab && value !== "") {
      setTitle(allTabs[value].title)
    }
    setSourceTab(value)
  }
  const handleToggleReplaceExisting = () => {
    if (replaceSourceTab) {
      setReplaceSourceTab(false)
      setTitle("")
    } else {
      setReplaceSourceTab(true)
      setTitle(allTabs[sourceTab].title)
    }
  }
  const handleChangeName = ({ target: { value } }) => setTitle(value)

  const handleCloseDialog = () => {
    setSourceTab("")
    setReplaceSourceTab(true)
    setTitle("")
    setDialog(false)
  }

  const handleSubmit = () => {
    const payload = {
      slug: stockSlug,
      sourceTab,
      randomId: randomid(),
      title,
      replaceSourceTab,
      stock,
    }
    dispatch(profileDuck.actions.stockCreateTab(payload))
    handleCloseDialog()
  }

  return (
    <Dialog
      className="bp5-dark"
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      icon="plus"
      title="Create new tab"
      intent="primary"
      isOpen={dialog}
      onClose={handleCloseDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Select
          labelProps={{
            label: "Copy existing tab",
          }}
          selectProps={{
            fill: true,
            options,
            value: sourceTab,
            onChange: handleSelectExisting,
          }}
        />
        {sourceTabInAvailableTabs && (
          <Callout>
            <H5>This existing tab is displayed for this stock.</H5>
            <p>Do you want to replace it or keep both?</p>
            <Switch
              checked={replaceSourceTab}
              onChange={handleToggleReplaceExisting}
              label="Replace existing"
            />
          </Callout>
        )}
        {(!sourceTabInAvailableTabs || !replaceSourceTab) && (
          <InputGroup
            labelProps={{ label: "New tab name" }}
            inputProps={{
              placeholder: "New name…",
              value: title,
              onChange: handleChangeName,
              onEnter: handleSubmit,
              onEsc: handleCloseDialog,
            }}
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button intent="danger" icon="remove" text="Cancel" onClick={handleCloseDialog} />
          <Button intent="primary" icon="floppy-disk" text="Create tab" onClick={handleSubmit} />
        </div>
      </div>
    </Dialog>
  )
}
export default NewTab
