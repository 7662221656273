import { Card } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { css } from "@emotion/react"

export const IdeaTradesInsightsWidgetContainer = styled(Card)({
  width: "450px",
  maxHeight: "50%",
  margin: "20px",
})

export const InsightsContainer = styled(FlexContainer)({
  height: "100%",
})

export const GlobalMetricsContainer = styled(FlexContainer)({
  width: "100%",
  marginLeft: "50px",
})

export const UserMetricsContainer = styled(FlexContainer)({
  width: "100%",
  marginLeft: "50px",
  overflow: "hidden",
})

export const dividerCss = css({
  width: "100%",
})
