import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../../styled/flexbox.styled"

export const RiskZoomDialogCss = css`
  width: 90vw;
  height: 90vh;
  .bp5-dialog-header {
    gap: 10px;
  }
  .bp5-icon > svg {
    color: white;
  }
`

export const RiskZoomContainer = styled(FlexContainer)({
  height: "100%",
  padding: "5px",
})

export const RiskSpinnerContainer = styled(FlexContainer)({
  height: "100%",
})
