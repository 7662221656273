// @ts-nocheck
import React from "react"

const Consensus = () => {
  return (
    <>
      <p>Consensus WIP</p>
    </>
  )
}

export default Consensus
