// @ts-nocheck
import autodux from "autodux"

const consensusDuck = autodux({
  slice: "consensus",
  initial: {
    query: "",
    searching: false,
    results: {},
  },
  actions: {
    searchStock: (state, query) => ({
      ...state,
      searching: true,
      query,
    }),
    searchStockOk: (state, results) => ({
      ...state,
      searching: false,
      results,
    }),
  },
})

export default consensusDuck
