// @ts-nocheck
import autodux from "autodux"

import * as actions from "./actions"

const desksDuck = autodux({
  slice: "desks",
  initial: {},
  actions,
})

export default desksDuck
