import styled from "@emotion/styled"
import {
  FlexContainer,
  FlexContainerAndItem,
  flexContainerCss,
  FlexContainerProps,
  flexItemCss,
} from "../../../../../styled/flexbox.styled"
import { noSelect } from "../../../../../styled/layout.styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"
import { IdeaType } from "../../types/business"

type IdeaCardContainerProps = {
  failure: boolean
  selected: boolean
  ideaType: IdeaType
}

export const IdeaCardContainer = styled(FlexContainer)<FlexContainerProps & IdeaCardContainerProps>`
  padding: 15px 10px;
  border-bottom: 1.5px solid #232e37;
  cursor: pointer;
  &:hover {
    background-color: ${props =>
      props.failure
        ? SCIENT_COLORS.red3
        : props.selected
          ? props.ideaType === IdeaType.NOTE
            ? SCIENT_COLORS.blueHoverSelected
            : SCIENT_COLORS.darkGray2
          : props.ideaType === IdeaType.NOTE
            ? SCIENT_COLORS.blueHover
            : SCIENT_COLORS.darkGray3};
  }
  &:active {
    background-color: ${props =>
      props.failure
        ? SCIENT_COLORS.red3
        : props.ideaType === IdeaType.NOTE
          ? SCIENT_COLORS.blueDefault
          : SCIENT_COLORS.darkGray4};
  }
  background-color: ${props =>
    props.failure
      ? SCIENT_COLORS.red3
      : props.selected
        ? props.ideaType === IdeaType.NOTE
          ? SCIENT_COLORS.blueSelected
          : SCIENT_COLORS.darkGray1
        : props.ideaType === IdeaType.NOTE
          ? SCIENT_COLORS.blueDefault
          : undefined};
  ${noSelect};
`

export const TopCardContainer = styled(FlexContainer)({})

export const Conviction = styled.p`
  ${flexContainerCss}
  margin: 0;
  font-size: 16px;
`

export const DateDistance = styled(FlexContainer)({
  fontSize: "13px",
  color: "#f2f2f2",
  margin: 0,
  width: "60px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})

export const UserInfo = styled.p({
  margin: 0,
  fontWeight: "bold",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
})

export const BottomCardContainer = styled(FlexContainer)({})

export const NotificationIconContainer = styled(FlexContainer)({
  width: "15px",
})

export const InfoContainer = styled(FlexContainerAndItem)({
  maxWidth: "95%",
  overflow: "hidden",
})

export const TypeInfo = styled.p`
  margin: 0;
  width: 160px;
  font-size: 12px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${flexItemCss}
`

export const SourceInfo = styled.p`
  width: 30%;
  margin: 0;
  padding-right: 5px;
  font-size: 12px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${flexItemCss}
`
