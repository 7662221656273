// @ts-nocheck
import { lensPath } from "ramda"

export const activeTabLens = slug => lensPath(["userstockconfigs", slug, "activeTab"])
export const availableTabsLens = slug => lensPath(["userstockconfigs", slug, "availableTabs"])
export const consensusFieldsLens = slug => lensPath(["userstockconfigs", slug, "consensusFields"])

export const rowDefsLens = (slug, tabId) =>
  lensPath(["userstockconfigs", slug, "customTabs", tabId, "rowDefs"])

export const rowLens = (slug, tabId, index) =>
  lensPath(["userstockconfigs", slug, "customTabs", tabId, "rowDefs", index])
