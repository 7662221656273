// @ts-nocheck
import autodux from "autodux"
import { assoc, assocPath, pipe } from "ramda"

const dataSetsDuck = autodux({
  slice: "dataSets",
  initial: {
    fetching: true,
    fetchingDetail: false,
    error: false,
    dataset: undefined,
    // dataset: {
    //   id: 14995,
    //   name: "AHRI USA Residential HVAC (%, YoY)",
    //   periodicity: "M",
    //   bloomberg_code: null,
    //   category: "Industry Data",
    //   source: "human",
    //   comment: "",
    //   last_release: null,
    //   last_value: null,
    //   sector_1: "SXOP",
    //   chg_1w: null,
    //   chg_1m: null,
    //   chg_1y: null,
    //   ma_3m_12m: null,
    // },
    dataSets: [],
    dataSetsLastFetch: undefined,
    page: undefined,
    sector_id: undefined,
    geo_id: undefined,
  },
  actions: {
    setDataSet: (state, payload) => ({ ...state, dataSet: payload }),

    fetchDataSet: state => ({ ...state, fetching: true }),
    fetchDataSetOk: (state, dataSet) => ({ ...state, dataSet, fetching: false }),
    fetchDataSetError: (state, error) => ({ ...state, error, fetching: false }),

    updateDataSet: state => assoc("fetchingDetail", true)(state),
    updateDataSetOk: (state, dataSet) =>
      pipe(assoc("dataSet", dataSet), assoc("fetchingDetail", false))(state),
    updateDataSetError: (state, { error }) =>
      pipe(assoc("fetchingDetail", false), assocPath(["dataSet", "error"], error))(state),

    fetchDataSets: (state, payload) => {
      if (state.page === undefined) {
        return {
          ...state,
          fetching: true,
          ...payload,
        }
      }
      if (payload.sector_id !== state.sector_id && payload.geo_id === state.geo_id) {
        return {
          ...state,
          fetching: true,
          page: undefined,
          sector_id: payload.sector_id,
        }
      }
      if (payload.geo_id !== state.geo_id && payload.sector_id === state.sector_id) {
        return {
          ...state,
          fetching: true,
          page: undefined,
          geo_id: payload.geo_id,
        }
      }
      if (payload.page && payload.geo_id && payload.sector_id) {
        return { ...state, ...payload }
      }
      return {
        ...state,
        fetching: true,
        ...payload,
        page: undefined,
      }
    },
    fetchDataSetsOk: (state, dataSets) => ({
      ...state,
      fetching: false,
      dataSets,
      dataSetsLastFetch: new Date().toISOString(),
    }),
    fetchDataSetsError: (state, error) => ({
      ...state,
      fetching: false,
      error,
    }),
  },
})

export default dataSetsDuck
