/** @jsxImportSource @emotion/react */
import { Divider, Spinner } from "@blueprintjs/core"
import { debounce } from "lodash"

import TinyMCEFullscreenIcon from "../../../../icons/TinuMCEFullScreenIcon"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { LargeText } from "../../../../styled/text.styled"
import { NoDataMessage } from "../Portfolio/Orders.styled"
import {
  ExposuresContainer,
  ExposuresTablesContainer,
  FullScreenHeader,
  fullScreenDividerCss,
} from "./Exposures.styled"
import RiskGrid from "./components/RiskGrid"
import { Exposure } from "./types"
import { IAccountBookPairs } from "../types"

interface IExposuresProps {
  fullScreen: boolean
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>
  exposures?: Exposure[]
  isLoading?: boolean
  selectedFilters: IAccountBookPairs
}

const Exposures = ({
  fullScreen,
  setFullScreen,
  exposures,
  isLoading,
  selectedFilters,
}: IExposuresProps) => {
  return (
    <ExposuresContainer flexDirection="column" fullScreen={fullScreen}>
      {fullScreen && (
        <>
          <FullScreenHeader gap="10px">
            <LargeText fontWeight="bold">Exposures</LargeText>
            <TinyMCEFullscreenIcon
              onClick={debounce(() => {
                setFullScreen(!fullScreen)
              }, 300)} // Adjust the debounce delay
              fullscreen={fullScreen}
              fill={SCIENT_COLORS.white}
            />
          </FullScreenHeader>
          <Divider css={fullScreenDividerCss} />
        </>
      )}
      <ExposuresTablesContainer flexDirection="column" gap="10px" fullScreen={fullScreen}>
        {exposures?.length ? (
          exposures.map(exposure => (
            <RiskGrid
              key={exposure.type}
              columns={exposure?.dataframe?.columns}
              rows={exposure?.dataframe?.rows}
              pinnedBottomRows={exposure?.dataframe?.pinnedBottomRows}
              riskType={exposure.type}
              selectedFilters={selectedFilters}
            />
          ))
        ) : isLoading ? (
          <Spinner />
        ) : (
          <NoDataMessage>
            No Risk data available, please select an account and/or a book
          </NoDataMessage>
        )}
      </ExposuresTablesContainer>
    </ExposuresContainer>
  )
}
export default Exposures
