/** @jsxImportSource @emotion/react */
import { Validate } from "react-hook-form"

import { FlexContainerFullHeight } from "./Components.styled"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"
import { WaveInputs } from "../types/form"
import { IBaseInputProps } from "../types/common"

interface IIntegerInputProps<TFieldValues> extends IBaseInputProps {
  min?: {
    value: number
    message: string
  }
  max?: {
    value: number
    message: string
  }
  minOfInput?: number
  validate?: Validate<number, TFieldValues> | Record<string, Validate<number, TFieldValues>>
  onChange?: () => void
}

/**
 * Component to use with React Hook Form for integer input
 */

export const IntegerInput = ({
  name,
  register,
  min,
  max,
  minOfInput,
  customCss,
  error,
  validate,
  onChange,
}: IIntegerInputProps<WaveInputs>) => (
  <FlexContainerFullHeight alignItems="center">
    <input
      type="number"
      {...register(name, {
        min: min,
        max: max,
        validate: validate,
        /**
         * Set value in the form state with numeric value or null if value is NaN
         */
        setValueAs: (value: string) => {
          const parsedValue = parseFloat(value)
          return isNaN(parsedValue) ? null : parsedValue
        },
        onChange: onChange, // need to be after setValueAs
      })}
      onKeyDown={event => {
        const allowedCharacters =
          /[0-9]|Tab|Backspace|Delete|ArrowLeft|ArrowRight|ArrowDown|ArrowUp/
        if (!allowedCharacters.test(event.key)) {
          event.preventDefault()
        }
      }}
      step={1}
      min={minOfInput}
      css={customCss}
    />
    {error && <ErrorIconWithTooltip errorMessage={error.message} />}
  </FlexContainerFullHeight>
)
