// @ts-nocheck
import React from "react"
import { all, equals, isEmpty, isNil, map, or, sum } from "ramda"
import { Tag, H6 } from "@blueprintjs/core"

import { Row, Col } from "../../../../../../components"
import { roundNumber } from "../../../../../../_shared"

export const ControlRow = ({ title, sumList, expected }) => {
  const noSumEl = all(or(isEmpty, isNil, equals(NaN)), sumList)
  const noExpected = isEmpty(expected) || isNil(expected)
  const intentText = () => {
    if (!noSumEl && !noExpected) {
      const summedRaw = sum(map(Number, sumList))
      // const summed = summedRaw.toLocaleString("en-UK", {
      //   maximumFractionDigits: 6,
      // })
      const diff = Number(Number(summedRaw) - Number(expected)).toLocaleString("en-UK", {
        maximumFractionDigits: 6,
      })

      if (equals(roundNumber(summedRaw, 2), roundNumber(expected, 2))) {
        return { intent: "success", icon: "tick" }
      }
      return {
        intent: "warning",
        icon: "warning-sign",
        text: `Δ ${diff}`,
      }
    }
    return {}
  }
  const { intent, icon, text } = intentText()
  return (
    <Row>
      <Col xsOffset={1} xs={3}>
        <H6>{title}</H6>
      </Col>
      <Col xsOffset={4} xs={3}>
        <Tag intent={intent} icon={icon} large fill minimal style={{ textAlign: "right" }}>
          {text}
        </Tag>
      </Col>
    </Row>
  )
}
