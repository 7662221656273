// @ts-nocheck
import { Button, ButtonGroup, Divider, H4, Tooltip } from "@blueprintjs/core"
import { formatDistanceStrict } from "date-fns"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { MediumText } from "../../../../../styled/text.styled"
import { InfoButton } from "../../../../components"
import { useAuth } from "../../../../hooks/"
import { useGetDesk, useGetDeskOwnerName, useGetWritePermission } from "../../utils"
import { CloneSheet } from "./CloneSheet"
import { RefreshButton } from "./RefreshButton"

export const SharedHeader = ({ monitor, sheet, setEditRowItems }) => {
  const { profile } = useAuth()
  const deskId = monitor.desk_id
  const desk = useGetDesk(deskId)
  const { kind } = sheet

  // Variables for permissions
  const userId = profile.id
  const deskOwnerId = desk.owner_id
  const deskOwnerName = useGetDeskOwnerName(desk)
  const hasWritePermission = useGetWritePermission(desk)
  // end Variables for permissions

  return (
    <ButtonGroup>
      <RefreshButton sheet={sheet} />
      <InfoButton
        content={
          <FlexContainer flexDirection="column" gap="5px">
            <H4>{sheet.name}</H4>
            <Divider />
            <MediumText>
              <strong>ID</strong> {sheet.id}
              <br />
              <br />
              <strong>Type</strong> {sheet.kind}
              <br />
              <br />
              <strong>Owner</strong> {deskOwnerName}
              <br />
              <br />
              {sheet.date_updated && (
                <>
                  <strong>Updated</strong>
                  {formatDistanceStrict(new Date(sheet.date_updated), Date.now(), {
                    addSuffix: true,
                  })}
                  <br />
                  <br />
                </>
              )}
              <strong>Refresh mode: </strong> {sheet.last_refresh_mode}
            </MediumText>
          </FlexContainer>
        }
      />
      {/* CloneSheet works only for TF and MSC sheets. */}
      {["tf", "msc"].includes(kind) && <CloneSheet desk={desk} monitor={monitor} sheet={sheet} />}
      {kind === "ng-tf" && (userId === deskOwnerId || hasWritePermission) && (
        <Tooltip content="Edit rows" placement="bottom" openOnTargetFocus={false} compact>
          <Button
            icon="edit"
            onClick={() => setEditRowItems({ rowId: -1, show: true, add: false })}
          />
        </Tooltip>
      )}
    </ButtonGroup>
  )
}
