// @ts-nocheck
import { of } from "rxjs"
import { switchMap, map, catchError } from "rxjs/operators"
import { ofType } from "redux-observable"

import dsForecastDuck from "../../."
import { REACT_APP_API_DOMAIN } from "../../../../../config/constants"

const {
  actions: { deleteForecast, deleteForecastOk, deleteForecastError },
} = dsForecastDuck

const deleteForecastEpic = (action$, state$, { ajax, auth }) =>
  action$.pipe(
    ofType(deleteForecast.type),
    switchMap(action => {
      const { index, dataset, id } = action.payload
      const url = `${REACT_APP_API_DOMAIN}/api/v1/datasets/${dataset}/dsforecasts/${id}/`
      return ajax({
        url,
        method: "DELETE",
        crossDomain: true,
        responseType: "json",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      }).pipe(
        map(() => deleteForecastOk({ index })),
        catchError(error => of(deleteForecastError({ index, error: error.xhr.response }))),
      )
    }),
  )
export default deleteForecastEpic
